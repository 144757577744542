export function naturalSort<T extends Array<unknown>>(
  arr: T,
  getter: (item: T[0]) => number | string,
): T {
  let a;
  let b;
  let a1;
  let b1;
  const rx = /(\d+)|(\D+)/g;
  const rd = /\d+/;

  return arr.sort(function (as, bs) {
    a = String(getter ? getter(as) : as)
      .toLowerCase()
      .match(rx);
    b = String(getter ? getter(bs) : bs)
      .toLowerCase()
      .match(rx);

    while (a.length && b.length) {
      a1 = a.shift();
      b1 = b.shift();
      if (rd.test(a1) || rd.test(b1)) {
        if (!rd.test(a1)) return 1;
        if (!rd.test(b1)) return -1;
        if (a1 != b1) return a1 - b1;
      } else if (a1 != b1) return a1 > b1 ? 1 : -1;
    }
    return a.length - b.length;
  });
}
