import React from 'react';
import classNames from 'classnames';
import s from './SingleNotificationWrapper.module.scss';

export type TSingleNotificationWrapperProps = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
};

export const SingleNotificationWrapper: React.FC<TSingleNotificationWrapperProps> = ({
  children,
  className,
  style,
  onClick,
}) => (
  <div
    className={classNames(s.scmSingleSimulationNotification, className)}
    style={style}
    onClick={onClick}>
    {children}
  </div>
);
