import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import { selectIsModalOpened, selectModalContent } from 'src/redux/modals/modals.selectors';

import { BaseGenericModal } from 'src/components/BaseGenericModal';
import { BaseIcon } from 'src/components/BaseIcon';
import { BaseLink } from 'src/components/BaseLink';
import { BaseProgress } from 'src/components/BaseProgress';
import classnames from 'classnames';
import s from './ModalDeployProcess.module.scss';
import { useAppDispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';

export const ModalDeployProcess: React.FC = () => {
  const dispatch = useAppDispatch();
  const modalRef = useRef<HTMLDivElement>(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const isModalDeployOpened = useSelector(
    selectIsModalOpened(EPredefinedModalIds.MODAL_DEPLOY_PROCESS),
  );
  const modalContent = useSelector(selectModalContent());
  const { title, subTitle, icon } = modalContent;

  const isUploadFailedErrorModal = useMemo(() => title === 'Upload Failed', [title]);

  useEffect(() => {
    if (!isModalDeployOpened && uploadProgress !== 0) {
      setUploadProgress(0);
    }
    if (isModalDeployOpened) {
      const interval = setInterval(() => {
        setUploadProgress((time) => {
          if (time > 100) return 100;
          else return time + 5;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [isModalDeployOpened, uploadProgress]);

  //TODO: It Should be deleted, it has been added for development purposes
  // useEffect(() => {
  //   dispatch(openModal(EPredefinedModalIds.MODAL_DEPLOY_PROCESS));
  // }, [dispatch]);

  const closeDeployProcessModal = () => {
    dispatch(closeModal(EPredefinedModalIds.MODAL_DEPLOY_PROCESS));
  };

  const handleFooterLink = () => {
    closeDeployProcessModal();
    if (isUploadFailedErrorModal) {
      dispatch(openModal(EPredefinedModalIds.MODAL_SCM_UPLOAD_DATA_SHEET));
    }
  };

  if (!isModalDeployOpened) return null;

  return (
    <>
      <div className={s.background} onClick={closeDeployProcessModal} />
      <BaseGenericModal
        size={EModalSize.XL550}
        modalId={EPredefinedModalIds.MODAL_DEPLOY_PROCESS}
        className={s.modal}
        modalRef={modalRef}>
        <div className={classnames(s.modalContentWrapper, s.modalDeployProcess)}>
          {isUploadFailedErrorModal ? (
            <div className={s.errorIconContainer}>
              <BaseIcon icon={icon} size={30} />
            </div>
          ) : (
            <BaseIcon icon={icon} className={s.canaryBox} size={48} />
          )}
          <h2 className={s.modalTitle}>{title || 'Deploying your community'}</h2>
          <p>{subTitle || 'Scanning community members'}</p>
          {!isUploadFailedErrorModal && (
            <BaseProgress className={s.deployProcessProgressBar} progress={uploadProgress} />
          )}
          <BaseLink type="button" onClick={handleFooterLink} className={s.plainLink}>
            {isUploadFailedErrorModal ? 'Start Again' : 'Cancel'}
          </BaseLink>
        </div>
      </BaseGenericModal>
    </>
  );
};
