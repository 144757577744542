//create a react hook for handling the screenMode state
//if the screenMode state changes, the hook will re-render the component
//if the screenWidth is less than 768px, the screenMode will be set to 'mobile'
//if the screenWidth is greater than 768px, the screenMode will be set to 'desktop'

import { EAppMode, getAppMode } from 'src/utils/appMode';
import { setScreenMode, setScreenOrientation } from 'src/redux/application/application.slice';
import { useEffect, useState } from 'react';

import { EScreenMode } from 'src/typings/configuration.types';
import { calculateScreenOrientation } from 'src/utils/calculateScreenOrientation';
import { selectScreenMode } from 'src/redux/application/application.selectors';
import { useAppDispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';

export const useScreenMode = (): void => {
  const dispatch = useAppDispatch();
  const screenMode = useSelector(selectScreenMode);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update the screen mode when the window is resized
    const handleResize = () => setScreenWidth(window.innerWidth);

    const appMode = getAppMode();

    window.addEventListener('resize', handleResize);

    dispatch(setScreenMode(appMode === EAppMode.Mobile ? EScreenMode.Mobile : EScreenMode.Desktop));

    if (appMode === EAppMode.Mobile) {
      dispatch(
        setScreenOrientation(calculateScreenOrientation(window.innerWidth, window.innerHeight)),
      );
    }

    return () => window.removeEventListener('resize', handleResize);
  }, [screenWidth, screenMode, dispatch]);

  useEffect(() => {
    // Update the screen orientation when the screen orientation changes
    const handleOrientationChange = () =>
      dispatch(
        setScreenOrientation(calculateScreenOrientation(window.innerWidth, window.innerHeight)),
      );

    handleOrientationChange();
    window.addEventListener('orientationchange', handleOrientationChange);

    return () => window.removeEventListener('orientationchange', handleOrientationChange);
  }, [dispatch]);
};
