// eslint-disable-next-line no-restricted-imports
import moment, {
  DurationInputArg2,
  Moment,
  MomentFormatSpecification,
  MomentInput,
} from 'moment-timezone';

/*
 *
 * The purpose of this file is to force using UTC time everywhere.
 *
 */

// Ensures that Monday is the first day of the week
const LOCALE = 'en-gb';
moment.locale(LOCALE);
moment.tz.setDefault('Etc/UTC'); // This settings has impact on chart.js time axis.

const { utc } = moment;

const UNIX_FORMAT = 'X';

export enum BACKEND_DATE_FORMATS {
  COLLAB_START_END = 'YYYY-MM-DD[T]HH:mm',
  CURRENT_MARKET = 'YYYY-MM-DD-[T]HH:mm',
  SETTINGS_DATA = 'YYYY-MM-DD',
  ENERGY_TRADE_PROFILE = 'MMMM DD YYYY[,] HH:mm [h]',
  AREA_THROUGHPUT = 'YYYY-MM-DD[T]HH:mm',
  PRICE_ENERGY_DAY = 'YYYY-MM-DD[T]HH:mm',
  BIDS_OFFERS_TRADES = 'YYYY-MM-DD[T]HH:mm:ss',
  DOWNLOAD_SIM_RESULTS = 'YYYY-MM-DD[T]HH:mm',
  DEVICE_STATISTICS = 'YYYY-MM-DD[T]HH:mm',
  SIMULATION_RESULTS_START_END_TIME = 'YYYY-MM-DD[T]HH:mm:ss',
  KPI_SAVINGS = 'MMMM DD YYYY[,] HH:mm [h]',
  SETTINGS_DATA_END_DATE_TIME = 'YYYY-MM-DD[T]HH:mm:ss',
}

export enum FRONTEND_DATE_FORMATS {
  COMMUNITY_DATE = 'MMMM D YYYY[,] H:mm',
  TOOLTIP_CHART = 'MMM DD YYYY, h:mm A',
  TOOLTIP_CHART_PRECISE = 'MMM DD YYYY, h:mm:ss A',
  USER_CREATION_DATE = 'DD, MMMM YYYY',
  TOOLTIP_TIMELINE_PRECISE = 'h:mm a',
}

export const UTCMoment = Object.freeze({
  UNIX_FORMAT,

  LOCALE,

  utc(date?: MomentInput, format?: MomentFormatSpecification) {
    return utc(date, format);
  },

  // To get Unix Timestamp (seconds), use .unix() method: https://momentjs.com/docs/#/displaying/unix-timestamp/
  // To get Unix Timestamp (milliseconds), use .valueOf() method: https://momentjs.com/docs/#/displaying/unix-timestamp-milliseconds/
  fromBackend(date: MomentInput, format?: BACKEND_DATE_FORMATS) {
    return utc(date, format);
  },

  fromUnix(timestamp: number | string) {
    if (String(timestamp).length !== 10) {
      console.error('TIMESTAMP MUST HAVE 10 DIGITS! - UTCMoment.ts');
    }

    return utc(timestamp, UNIX_FORMAT);
  },

  dateDifference(
    date: MomentInput,
    count: number,
    type?: DurationInputArg2,
    format?: BACKEND_DATE_FORMATS,
  ) {
    return moment(date).add(count, type).format(format);
  },

  dateDifferenceMove(
    date: MomentInput,
    count: number,
    type?: DurationInputArg2,
    format?: BACKEND_DATE_FORMATS,
    move?: string,
  ) {
    if (move === 'previous') {
      return moment(date).subtract(count, type).format(format);
    } else if (move === 'next') {
      return moment(date).add(count, type).format(format);
    }
  },
});

export type TMomentInput = MomentInput;
export type TMoment = Moment;
