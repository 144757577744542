import { ContextMenu, TContextMenuProps } from 'src/components/ContextMenu';
import { UserSimpleOutput, useSetUserRoleMutation } from 'src/graphql';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';

import { BaseIcon } from 'src/components/BaseIcon';
import { BaseSelect } from 'src/components/BaseSelect';
import React from 'react';
import classNames from 'classnames';
import { getFrontendUserRoleName } from 'src/utils/getFrontendUserRoleName';
import { openToast } from 'src/redux/toast/toast.slice';
import s from './DashboardUsers.module.scss';
import { selectAvailableUserRoles } from 'src/redux/application/application.selectors';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { useAppDispatch } from 'src/redux/store';
import { useMergedState } from 'src/hooks/useMergedState';
import { useModal } from 'src/hooks/useModal';
import { useSelector } from 'react-redux';

export const DashboardUsersGridItem: React.FC<{
  className: string;
  data: UserSimpleOutput;
  updateGridData(): void;
  onClick(): void;
}> = ({ data, className, updateGridData, onClick }) => {
  const userRoles = useSelector(selectAvailableUserRoles);
  const dispatch = useAppDispatch();
  const { id: contextMenuModalId } = useModal();
  const isContextMenuOpened = useSelector(selectIsModalOpened(contextMenuModalId));
  const [contextMenuState, setContextMenuState] = useMergedState<{
    modalRef: React.RefObject<HTMLElement | null>;
    userEmail: UserSimpleOutput['email'] | undefined;
  }>({ modalRef: { current: null }, userEmail: '' });

  const [setUserRole] = useSetUserRoleMutation({
    onCompleted: () => {
      dispatch(
        openToast({
          message: 'User Role Updated',
          type: 'success',
        }),
      );
      updateGridData();
    },
    onError: (err) => {
      const msg = JSON.parse(err.message).set_user_role;
      dispatch(
        openToast({
          message: msg || 'Something went wrong',
          type: 'error',
        }),
      );
    },
  });

  const getUserRoleNameId = (name: string) => {
    return userRoles.find((item) => item.roleName === getFrontendUserRoleName(name))?.value;
  };

  const updateUserRole = ({ value }, event) => {
    if (data.email && data.username) {
      setUserRole({
        variables: {
          username: data.username,
          email: data.email,
          role: value,
        },
      });
      // updateGridData();
      event.stopPropagation();
    }
  };

  const closeContextMenu = () => dispatch(closeModal(contextMenuModalId));

  const onThreeDotsClick = (event, { target, user }) => {
    if (isContextMenuOpened) {
      closeContextMenu();
    } else {
      setContextMenuState({
        modalRef: { current: target as HTMLElement },
        userEmail: (user as UserSimpleOutput).email,
      });
      dispatch(openModal(contextMenuModalId));
    }
    event.stopPropagation();
  };

  const contextMenuItems: TContextMenuProps['items'] = [
    {
      title: 'Assign Canary',
      icon: 'canary',
      onClick: () => {
        if (contextMenuState.userEmail) {
          closeContextMenu();
        }
      },
    },
    {
      title: 'Delete',
      icon: 'custom-trash',
      onClick: async () => {
        if (contextMenuState.userEmail) {
          closeContextMenu();
        }
      },
    },
  ];

  return (
    <>
      <div className={classNames(className, s.gridItem)} onClick={onClick}>
        <button
          onClick={(e) => onThreeDotsClick(e, { target: e.target as HTMLElement, user: data })}
          className="moreMenu"
          type="button"
          title="More">
          <BaseIcon icon="more-horizontal" size={10} />
        </button>
        <div className="content">
          {data.profilePicture ? (
            <div
              className="avatar"
              style={{ backgroundImage: `url(${data.profilePicture})` }}></div>
          ) : (
            <div className="avatar isLetter">{data.email?.slice(0, 1)}</div>
          )}
          <h6>{data.email?.split('@')[0]}</h6>
          <BaseSelect
            className="roleSelector"
            theme="filled-white"
            name="userRole"
            value={getUserRoleNameId(data.userRole as string)}
            options={userRoles.map((item) => ({ label: item.roleName, value: item.value }))}
            onChange={updateUserRole}
          />
          {data.requestStatus === 'RECEIVED' ? (
            <div className="buildCanary">⚡ Build Canary</div>
          ) : (
            <span>{data.email}</span>
          )}
        </div>
        <div className="numbers">
          <div>
            <BaseIcon icon="simulation" size={12} />
            <span>{data.numberOfSimulations}</span>
          </div>
          <div>
            <BaseIcon icon="canary" size={12} />
            <span>{data.numberOfCanaryNetworks}</span>
          </div>
          <div>
            <BaseIcon icon="book" size={12} />
            <span>{data.numberOfLibraries}</span>
          </div>
        </div>
      </div>
      {isContextMenuOpened && (
        <ContextMenu
          relativeElement={contextMenuState.modalRef}
          modalId={contextMenuModalId}
          position={{
            side: 'bottom',
            anchorPosition: 50,
          }}
          items={contextMenuItems}
        />
      )}
    </>
  );
};
