import { DocumentNode, useApolloClient } from '@apollo/client';
import { omit, sortBy, throttle } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { SimulationResultsData } from 'src/graphql';
import { useCurrentRefs } from 'src/hooks/useCurrentRefs';
import { v4 } from 'uuid';

type TNextSubscriptionData = {
  subscriptionData:
    | {
        data: {
          simulationAreaResultsPartial: Array<SimulationResultsData>;
        };
      }
    | undefined;
};

type TUseSubscribeToQueryProps<TQueryResult, TVars> = {
  queryDoc: DocumentNode;
  subscriptionDoc: DocumentNode;
  variables: TVars;
  avoidLiveSubscriptionData?: boolean;
  onQueryCompleted?(payload: TQueryResult): void;
  updateQuery(prev: TQueryResult, next: TNextSubscriptionData): TQueryResult;
};
const fetchPolicy = 'no-cache';

const ACTIVE_QUERIES = {};

const EVENT_NAME = 'cache-updated' + v4();

const CACHE = new Proxy(
  {},
  {
    set: function (obj, cacheID, value) {
      obj[cacheID] = value;
      window.dispatchEvent(new CustomEvent(EVENT_NAME, { detail: { cacheID } }));
      return true;
    },
  },
);

export function useSubscribeToQuery<TQueryResult = unknown, TVars = unknown>({
  queryDoc,
  subscriptionDoc,
  variables,
  avoidLiveSubscriptionData,
  onQueryCompleted,
  updateQuery,
}: TUseSubscribeToQueryProps<TQueryResult, TVars>): {
  data: TQueryResult;
  loading: boolean;
  error: string;
} {
  const variablesID = sortBy(Object.entries(variables), 0)
    .map((p) => p[1])
    .join(':');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cacheID = `${(queryDoc.definitions[0] as any).name!
    .value!}:${variablesID}:${avoidLiveSubscriptionData}`;
  const client = useApolloClient();
  const [data, setData] = useState<TQueryResult>(CACHE[cacheID]);
  const [loading, setLoading] = useState(!data);
  const [error, setError] = useState('');
  const currentRefs = useCurrentRefs({
    client,
    variables,
    updateQuery,
    onQueryCompleted,
    queryDoc,
    cacheID,
    subscriptionDoc,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setDataThrottled = useCallback(
    throttle(
      (newData) => {
        setData(newData);
      },
      2000, // One update per 2 sec is enough 👍
    ),
    [],
  );

  const subscribe = useCallback(
    ({
      subscriptionDoc,
      variables,
      cacheID,
    }: Pick<TUseSubscribeToQueryProps<TQueryResult, TVars>, 'subscriptionDoc' | 'variables'> & {
      cacheID: string;
    }) => {
      const { client, updateQuery } = currentRefs.current;

      const subscription = client
        .subscribe<unknown, TVars>({
          query: subscriptionDoc,
          variables,
          fetchPolicy,
        })
        .subscribe({
          next(subscriptionData) {
            const prev = CACHE[cacheID];
            CACHE[cacheID] = updateQuery(prev || {}, { subscriptionData } as TNextSubscriptionData);
            setDataThrottled(CACHE[cacheID]);
          },
          error(err) {
            // eslint-disable-next-line no-console
            console.error('Error happened inside a file: useSubscribeToQuery.ts\n\n\n' + err);
          },
          complete() {
            //
          },
        });

      return () => {
        subscription.unsubscribe();
      };
    },
    [currentRefs, setDataThrottled],
  );

  useEffect(() => {
    function handler({ detail }) {
      const { cacheID } = currentRefs.current;

      if (detail.cacheID === cacheID) {
        setData(CACHE[cacheID]);
        setLoading(false);
        setError('');
      }
    }

    // @ts-ignore
    window.addEventListener(EVENT_NAME, handler);

    return () => {
      // @ts-ignore
      window.removeEventListener(EVENT_NAME, handler);
    };
  }, [currentRefs]);

  const query = useCallback(async () => {
    const { client, variables, queryDoc, onQueryCompleted } = currentRefs.current;
    const { data } = await client.query({ query: queryDoc, variables, fetchPolicy });

    onQueryCompleted?.(data);

    CACHE[cacheID] = data;
    setData(CACHE[cacheID]);
  }, [cacheID, currentRefs]);

  useEffect(() => {
    async function initFetch() {
      const { variables, cacheID, subscriptionDoc } = currentRefs.current;
      const isEveryVarTruthy = Object.values(variables).every(Boolean);

      setData(CACHE[cacheID]);

      if (!isEveryVarTruthy) {
        setLoading(false);
        return;
      }

      if (ACTIVE_QUERIES[cacheID]?.count) {
        ACTIVE_QUERIES[cacheID].count += 1;
        return;
      }

      setError('');
      setLoading(true);

      try {
        ACTIVE_QUERIES[cacheID] = { count: 1 };

        await query();

        if (!avoidLiveSubscriptionData) {
          // @ts-ignore
          const subVars = omit(variables, 'endTime');
          const unsubscribe = subscribe({
            subscriptionDoc,
            // @ts-ignore
            variables: subVars,
            cacheID,
          });

          ACTIVE_QUERIES[cacheID].unsubscribe = unsubscribe;
        }
      } catch (error) {
        CACHE[cacheID] = undefined;
        setData(CACHE[cacheID]);
        // setError(error);
      }

      setLoading(false);
    }

    initFetch();
  }, [currentRefs, subscribe, cacheID, avoidLiveSubscriptionData, query]);

  useEffect(
    () => () => {
      if (!ACTIVE_QUERIES[cacheID]) return;

      ACTIVE_QUERIES[cacheID].count -= 1;

      if (!ACTIVE_QUERIES[cacheID].count) {
        ACTIVE_QUERIES[cacheID].unsubscribe?.();
        setTimeout(() => {
          ACTIVE_QUERIES[cacheID]?.unsubscribe?.();
          delete ACTIVE_QUERIES[cacheID];
        }, 10);
      }
    },
    [cacheID],
  );

  return {
    data,
    loading,
    error,
  };
}
