import { DashboardUserCollapsedListProps, TDashboardUserProps } from './DashboardUser.types';
import React, { useEffect, useMemo, useState } from 'react';
import {
  useListAdminUserDetailsQuery,
  useListConfigurationsQuery,
  useListLibrariesWithoutScenarioQuery,
  useSetUserRoleMutation,
} from 'src/graphql';

import { BaseButtonSquare } from 'src/components/BaseButtonSquare';
import { BaseIcon } from 'src/components/BaseIcon';
import { BaseSelect } from 'src/components/BaseSelect';
import { DashboardUserCollapsedList } from 'src/components/AdminDashboards/DashboardUser/DashboardUserCollapsedList';
import { getFrontendUserRoleName } from 'src/utils/getFrontendUserRoleName';
import { openToast } from 'src/redux/toast/toast.slice';
import s from './DashboardUser.module.scss';
import { selectAvailableUserRoles } from 'src/redux/application/application.selectors';
import { useAppDispatch } from 'src/redux/store';
import useDownloadAPI from 'src/hooks/useDownloadAPI';
import { useSelector } from 'react-redux';

export const DashboardUser: React.FC<TDashboardUserProps> = ({ userName, onBackToDashboard }) => {
  const dispatch = useAppDispatch();
  const userRoles = useSelector(selectAvailableUserRoles);
  //const isSuperUser = useSelector(selectTokenAuth)?.isSuperuser;
  const [currentUserRole, setCurrentUserRole] = useState<number | undefined>();

  const userProfileDownloadAPI = useDownloadAPI({
    type: 'user-profile',
    username: userName,
    isLibrary: false,
  });

  const { data: users, loading: usersLoading, error: usersError } = useListAdminUserDetailsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      search: userName,
    },
  });

  const { data: libraries, loading: librariesLoading } = useListLibrariesWithoutScenarioQuery({
    fetchPolicy: 'no-cache',
    variables: {
      search: userName,
    },
  });
  const { data: configurations, loading: configurationsLoading } = useListConfigurationsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      search: userName,
    },
  });

  const [setUserRole] = useSetUserRoleMutation({
    onCompleted: (data) => {
      setCurrentUserRole(data?.setUserRole?.value as number);
      dispatch(
        openToast({
          message: 'User Role Updated',
          type: 'success',
        }),
      );
    },
    onError: (err) => {
      const msg = JSON.parse(err.message).set_user_role;
      dispatch(
        openToast({
          message: msg || 'Something went wrong',
          type: 'error',
        }),
      );
    },
  });

  const user = useMemo(() => {
    return users?.listAdminUserDetails?.userList?.[0];
  }, [users?.listAdminUserDetails?.userList]);

  const getDevicesCount = (deviceCount: string) => {
    const data = JSON.parse(deviceCount);
    let count = 0;
    for (const [, value] of Object.entries<number>(data)) {
      count += value;
    }

    return `${count}`;
  };

  const configurationsList = useMemo<DashboardUserCollapsedListProps['items']>(() => {
    return (
      configurations?.listConfigurations?.configurations?.map((item) => ({
        uuid: item!.uuid!,
        title: item!.name!,
        subtitles: [getDevicesCount(item!.scenarioData!.deviceCount!) + ' Assets'],
        icon: 'community-3d',
        description: item!.description!,
      })) || []
    );
  }, [configurations]);

  const librariesList = useMemo<DashboardUserCollapsedListProps['items']>(() => {
    return (
      libraries?.listLibraries?.libraries?.map((item) => ({
        uuid: item!.uuid!,
        title: item!.name!,
        subtitles: [''],
        icon: 'community-3d',
        description: item!.description!,
      })) || []
    );
  }, [libraries?.listLibraries?.libraries]);

  const getUserRoleNameId = (name: string) => {
    return userRoles.find((item) => item.roleName === getFrontendUserRoleName(name))?.value;
  };

  const updateUserRole = ({ value }) => {
    if (user && user.email && user.username) {
      setUserRole({
        variables: {
          email: user.email,
          username: user.username,
          role: value,
        },
      });
    }
  };

  useEffect(() => {
    if (user && user.userRole) {
      const role = getUserRoleNameId(user.userRole);
      setCurrentUserRole(role as number);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const showSpinner = !!usersLoading || !!usersError || !user;

  return (
    <div className={s.container}>
      {showSpinner && <BaseIcon icon="spinner" size={40} />}
      {/* Have to check user cause TS assume user might be undefined */}
      {!showSpinner && user && (
        <>
          <header>
            <button type="button" className={s.back} onClick={onBackToDashboard}>
              <BaseIcon icon="back-arrow" size={15} />
            </button>
            <p>{user.email}</p>
            <BaseButtonSquare
              className={s.button}
              icon="download"
              size="1"
              disabled={userProfileDownloadAPI.loading}
              onClick={async () => {
                userProfileDownloadAPI.handleDownload();
              }}
            />
          </header>
          <div className={s.profile}>
            {user.profilePicture ? (
              <div
                className={s.avatar}
                style={{ backgroundImage: `url(${user.profilePicture})` }}
              />
            ) : (
              <div className={s.avatarLetter}>{user.email?.slice(0, 1)}</div>
            )}

            <h3>{user.email}</h3>
            <BaseSelect
              className={s.roleSelector}
              theme="filled-white"
              name="userRole"
              inputHeight="2"
              value={currentUserRole}
              options={userRoles.map((item) => ({ label: item.roleName, value: item.value }))}
              onChange={updateUserRole}
            />
            <p>{user.email}</p>
          </div>
          {librariesLoading || configurationsLoading ? (
            <BaseIcon icon="spinner" size={30} />
          ) : (
            <>
              <DashboardUserCollapsedList title="Communities Created" items={configurationsList} />
              <DashboardUserCollapsedList title="Assets created" items={librariesList} />
            </>
          )}
        </>
      )}
    </div>
  );
};
