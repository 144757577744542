import { BACKEND_DATE_FORMATS, UTCMoment } from 'src/utils/UTCMoment';
import {
  TChartFourOptions,
  TChartTwoOptions,
  TDataByTimestamp,
  TUseDeviceStatisticsDataProps,
  TValuesByDate,
} from './ChartDeviceProfile.types';

import { ScatterDataPoint } from 'chart.js';
import { config } from 'src/components/_charts/ChartDeviceProfile/config';
import { findIfShowChartFour } from 'src/components/_charts/ChartDeviceProfile/helpers';
import { formatter } from 'src/utils/formatter';
import { useMemo } from 'react';

function getTimestamp(date: string) {
  return UTCMoment.fromBackend(date, BACKEND_DATE_FORMATS.DEVICE_STATISTICS).valueOf();
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function produceChartOneData(dataOne: TValuesByDate) {
  if (!dataOne) return null;

  const result = Object.entries(dataOne).reduce((acc, [date, val]) => {
    const timestamp = getTimestamp(date);

    acc[timestamp] = { x: timestamp, y: formatter.toOptionalFixed(Number(val), 6) };

    return acc;
  }, {} as TDataByTimestamp<ScatterDataPoint>);

  return result;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function produceChartTwoData(
  dataTwo: TValuesByDate | null,
  min: TValuesByDate,
  max: TValuesByDate,
  dataOne: TValuesByDate,
  options = {},
) {
  const { absEnergyVal } = options as TChartTwoOptions;

  const result = Object.entries(dataTwo || {}).reduce(
    (acc, [date, val]) => {
      const timestamp = getTimestamp(date);
      const energy = formatter.toOptionalFixed(
        absEnergyVal ? Math.abs(Number(val)) : Number(val),
        6,
      );
      const deviceProfile = dataOne ? formatter.toOptionalFixed(Number(dataOne[date]), 6) : null;

      acc.energy[timestamp] = {
        x: timestamp,
        y: energy,
      };

      if (typeof deviceProfile === 'number') {
        acc.deviceProfile[timestamp] = {
          x: timestamp,
          y: deviceProfile,
        };
      }

      return acc;
    },
    {
      energy: {} as TDataByTimestamp<ScatterDataPoint>,
      deviceProfile: {} as TDataByTimestamp<ScatterDataPoint>,
    },
  );

  return result;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function produceChartFourData(
  dataFour: TValuesByDate,
  dataOne: TValuesByDate,
  options = {},
) {
  if (!dataFour) return null;

  const { absEnergyVal } = options as TChartFourOptions;

  const result = Object.entries(dataFour || {}).reduce(
    (acc, [date, val]) => {
      const timestamp = getTimestamp(date);
      const energy = formatter.toOptionalFixed(
        absEnergyVal ? Math.abs(Number(val)) : Number(val),
        6,
      );
      const deviceProfile = dataOne ? formatter.toOptionalFixed(Number(dataOne[date]), 6) : null;

      acc.energy[timestamp] = {
        x: timestamp,
        y: energy,
      };

      if (typeof deviceProfile === 'number') {
        acc.deviceProfile[timestamp] = {
          x: timestamp,
          y: deviceProfile,
        };
      }

      return acc;
    },
    {
      energy: {} as TDataByTimestamp<ScatterDataPoint>,
      deviceProfile: {} as TDataByTimestamp<ScatterDataPoint>,
    },
  );

  return result;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function produceChartThreeData(
  dataThree: TValuesByDate,
  min: TValuesByDate,
  max: TValuesByDate,
  dataTwo: TValuesByDate,
) {
  if (!dataThree || !min || !max) return null;
  const domain = [0, 0];

  const result = Object.entries(dataThree).reduce((acc, [date, val], index) => {
    const timestamp = getTimestamp(date);

    // Don't show bars if there is no energy trade for device
    const hideTradeEnergyForDevices =
      dataTwo &&
      Object.entries(dataTwo)[index] &&
      ['-0.00', '0.00'].includes(Object.entries(dataTwo)[index][1] as string);

    if (val && !hideTradeEnergyForDevices) {
      const minVal = Number(min[date]);
      const maxVal = Number(max[date]);

      if (minVal < domain[0]) {
        domain[0] = minVal;
      }

      if (maxVal > domain[1]) {
        domain[1] = maxVal;
      }

      acc[timestamp] = [minVal, maxVal];
    }

    return acc;
  }, {} as TDataByTimestamp<number[]>);

  return {
    domain,
    data: result,
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useDeviceStatisticsData({
  data,
  deviceType,
  forTimestamps,
}: TUseDeviceStatisticsDataProps) {
  const dataByTimestamp = useMemo(() => {
    if (!data || !deviceType) return null;

    const {
      [`${config.dataOneKey[deviceType]}` as const]: dataOne,
      [`${config.dataTwoKey[deviceType]}` as const]: dataTwo,
      [`min_${config.dataTwoKey[deviceType]}` as const]: minDataTwo,
      [`max_${config.dataTwoKey[deviceType]}` as const]: maxDataTwo,
      bought_trade_energy_kWh: dataFour,
      trade_price_eur: dataThree,
      min_trade_price_eur: minDataThree,
      max_trade_price_eur: maxDataThree,
      storage_temp_C: tankTemperatureData,
    } = data;

    const chartOneData = produceChartOneData(dataOne);

    const chartTwoData = produceChartTwoData(dataTwo, minDataTwo, maxDataTwo, dataOne, {
      absEnergyVal: [
        'PV',
        'CommercialProducer',
        'FiniteDieselGenerator',
        'MarketMaker',
        'InfiniteBus',
      ].includes(deviceType),
      deviceType,
    });

    const chartFourData = findIfShowChartFour(deviceType)
      ? produceChartFourData(dataFour, dataOne, {
          absEnergyVal: [
            'PV',
            'CommercialProducer',
            'FiniteDieselGenerator',
            'MarketMaker',
            'InfiniteBus',
          ].includes(deviceType),
          deviceType,
        })
      : null;

    const chartTankTemperature = tankTemperatureData
      ? produceChartFourData(tankTemperatureData, {})
      : null;

    const chartThreeData = produceChartThreeData(dataThree, minDataThree, maxDataThree, dataTwo);

    return {
      chartOneData,
      chartTwoData,
      chartFourData: chartTankTemperature ? chartTankTemperature : chartFourData,
      chartThreeData,
    };
  }, [data, deviceType]);

  return useMemo(() => {
    if (!dataByTimestamp) return null;

    const { chartOneData, chartTwoData, chartFourData, chartThreeData } = dataByTimestamp;

    const result = forTimestamps.reduce(
      (acc, timestamp) => {
        const fallbackPoint = { x: timestamp, y: null };

        // One
        if (chartOneData) {
          acc.chartOneData.push(chartOneData[timestamp] || fallbackPoint);
        }

        // Two
        acc.chartTwoData.energy.push(chartTwoData.energy[timestamp] || fallbackPoint);
        acc.chartTwoData.deviceProfile.push(chartTwoData.deviceProfile[timestamp] || fallbackPoint);

        // Four
        if (chartFourData) {
          acc.chartFourData.energy.push(chartFourData.energy[timestamp] || fallbackPoint);
          acc.chartFourData.deviceProfile.push(
            chartFourData.deviceProfile[timestamp] || fallbackPoint,
          );
        }

        // Three
        if (chartThreeData) {
          const dataItem = chartThreeData.data[timestamp] || null;
          acc.chartThreeData.data.push(dataItem);

          if (dataItem) {
            acc.chartThreeData.noData = false;
          }
        }

        return acc;
      },
      {
        chartOneData: [] as ScatterDataPoint[],
        chartTwoData: { energy: [] as ScatterDataPoint[], deviceProfile: [] as ScatterDataPoint[] },
        chartFourData: {
          energy: [] as ScatterDataPoint[],
          deviceProfile: [] as ScatterDataPoint[],
        },
        chartThreeData: {
          data: [] as number[][],
          domain: chartThreeData?.domain || [0, 0],
          noData: true,
        },
      },
    );

    return result;
  }, [dataByTimestamp, forTimestamps]);
}
