import { BaseInput } from 'src/components/BaseInput';
import { FieldContainer } from 'src/components/FormFieldsGenerator/components/FieldContainer';
import React from 'react';
import { TFormFieldsGeneratorProps } from 'src/components/FormFieldsGenerator';
import classnames from 'classnames';
import s from '../ModalAssetsManager.module.scss';

type GridMarketFormNameInputProps = {
  inputValue: string;
  onChange: TFormFieldsGeneratorProps['onChange'];
  onBlur: TFormFieldsGeneratorProps['onBlur'];
  error?: string | null;
};

export const GridMarketFormNameInput: React.FC<GridMarketFormNameInputProps> = ({
  inputValue,
  onChange,
  onBlur,
  error,
}) => {
  return (
    <div className={classnames(s.paddingLR)}>
      <FieldContainer
        className={classnames(s.inputField, {
          [s.marginTopSmall]: true,
        })}>
        <BaseInput
          name="name"
          type="text"
          theme={'line-light'}
          value={inputValue}
          errorWidth={'100%'}
          autoComplete="off"
          onBlur={onBlur}
          onChange={onChange}
          error={error}
        />
      </FieldContainer>
    </div>
  );
};
