import { mapSourceHighlightBuildings } from './map.sources';

export const mapLayer3DBuildings: mapboxgl.FillExtrusionLayer = {
  id: '3d-buildings',
  source: 'composite',
  'source-layer': 'building',
  filter: ['==', 'extrude', 'true'],
  type: 'fill-extrusion',
  minzoom: 16,
  paint: {
    'fill-extrusion-color': '#aaa',

    // use an 'interpolate' expression to add a smooth transition effect to the
    // buildings as the user zooms in
    'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 16, 0, 16.05, ['get', 'height']],
    'fill-extrusion-base': [
      'interpolate',
      ['linear'],
      ['zoom'],
      16,
      0,
      16.05,
      ['get', 'min_height'],
    ],
    'fill-extrusion-opacity': 0.6,
  },
};

export const mapLayerHighlightBuildings: mapboxgl.FillExtrusionLayer = {
  id: 'highlight-buildings-layer',
  source: mapSourceHighlightBuildings.id,
  filter: ['==', 'extrude', 'true'],
  type: 'fill-extrusion',
  minzoom: 16,
  paint: {
    'fill-extrusion-color': '#00f',

    // use an 'interpolate' expression to add a smooth transition effect to the
    // buildings as the user zooms in
    'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 16, 0, 16.05, ['get', 'height']],
    'fill-extrusion-base': [
      'interpolate',
      ['linear'],
      ['zoom'],
      16,
      0,
      16.05,
      ['get', 'min_height'],
    ],
    'fill-extrusion-opacity': 0.6,
  },
};
