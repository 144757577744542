export type TAssetStatusTextProps = {
  className?: string;
  style?: React.CSSProperties;
  assetName: string;
};

export enum ESavingType {
  Earning = 'Earning',
  Neutral = 'Neutral',
  Saving = 'Saving',
  Losses = 'Losses',
}
