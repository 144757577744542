import React, { useMemo } from 'react';
import {
  TDisplayMeasurementNotification,
  TMeasurementNotificationListProps,
} from 'src/components/MeasurementNotificationList/MeasurementNotificationList.types';
import {
  selectAssets,
  selectAssetsValues,
  selectCommunityAsset,
} from 'src/redux/configuration/configuration.selectors';

import { EUserRoles } from 'src/typings/base-types';
import { MeasurementNotificationListItem } from 'src/components/MeasurementNotificationList/MeasurementNotificationListItem';
import { MeasurementResultListItem } from 'src/components/MeasurementNotificationList/MeasurementResultListItem';
import { UserRequestsStatus } from 'src/graphql';
import classNames from 'classnames';
import s from './MeasurementNotificationList.module.scss';
import { selectMeasurementNotificationList } from 'src/redux/notifications/notifications.selectors';
import { selectUserRole } from 'src/redux/auth/auth.selectors';
import { useSelector } from 'react-redux';

export const MeasurementNotificationList: React.FC<TMeasurementNotificationListProps> = () => {
  const measurementNotifications = useSelector(selectMeasurementNotificationList);
  const assets = useSelector(selectAssets);
  const assetsValues = useSelector(selectAssetsValues);
  const communityAsset = useSelector(selectCommunityAsset);
  const userRole = useSelector(selectUserRole);

  const parentAssetsList = useMemo(() => {
    return Object.keys(assets).filter((assetKey) => {
      const asset = assets[assetKey];
      if (communityAsset?.uuid) return asset.parentUuid === communityAsset?.uuid;
    });
  }, [assets, communityAsset?.uuid]);

  const manipulatedNotifications = useMemo(() => {
    return parentAssetsList
      .map((item) => {
        const notification = measurementNotifications.find((notification) => {
          if (notification.areaUuid === item) {
            if (userRole === EUserRoles.Admin && notification.status == UserRequestsStatus.Received)
              return notification;
            else if (userRole != EUserRoles.Admin) return notification;
          }

          return null;
        });

        if (notification && notification.areaUuid) {
          const children = Object.keys(assets)
            .filter((assetKey) => {
              const asset = assets[assetKey];
              if (asset.parentUuid === notification.areaUuid) {
                return measurementNotifications.find((notification) => {
                  if (notification.areaUuid === asset.uuid) {
                    return true;
                  }

                  return false;
                });
              }
              return false;
            })
            .map((child) => ({
              ...assetsValues[child],
            }));

          return {
            ...notification,
            asset: assetsValues[notification.areaUuid],
            children,
          };
        }
      })
      .filter((item) => item !== undefined) as Array<TDisplayMeasurementNotification>;
  }, [measurementNotifications, assets, parentAssetsList, assetsValues, userRole]);

  return (
    <div className={classNames(s.container)}>
      <ul>
        {userRole === EUserRoles.Admin &&
          manipulatedNotifications.map((notification) => (
            <MeasurementNotificationListItem
              key={`measurement-notification-${notification.areaUuid}`}
              notification={notification}
            />
          ))}
        {userRole === EUserRoles.ExchangeOperator &&
          manipulatedNotifications.map((notification) => (
            <MeasurementResultListItem
              key={`measurement-notification-${notification.areaUuid}`}
              notification={notification}
            />
          ))}
        {!manipulatedNotifications.length && (
          <div className={s.container}>
            <p>{`You don't have any notification`}</p>
          </div>
        )}
      </ul>
    </div>
  );
};
