import React, { useEffect } from 'react';

import { MapSidebar } from 'src/components/MapSidebar';
import { ModalEmbedJoin } from 'src/components/ModalEmbedJoin';
import { ModalEmbedLegends } from 'src/components/ModalEmbedLegends/ModalEmbedLegends';
import { TEmbedProps } from './Embed.types';
import { WorldMap } from 'src/components/WorldMap';
import { WorldMapContextProvider } from 'src/contexts/WorldMapContext';
import { routesConfig } from 'src/routes/routes.config';
import s from './Embed.module.scss';
import { setIsEmbed } from 'src/redux/application/application.slice';
import { useAppDispatch } from 'src/redux/store';
import { useConfigurationEffects } from 'src/hooks/useConfigurationEffects';
import { useRouteMatch } from 'react-router';

export const Embed: React.FC = () => {
  return (
    <WorldMapContextProvider>
      <EmbedContent />
    </WorldMapContextProvider>
  );
};

export const EmbedContent: React.FC<TEmbedProps> = () => {
  const dispatch = useAppDispatch();

  const isEmbed = Boolean(
    useRouteMatch<{ configurationUuid: string; assetUuid: string }>({
      path: routesConfig.embed(''),
    }),
  );

  useConfigurationEffects();

  useEffect(() => {
    dispatch(setIsEmbed(isEmbed));
  }, [dispatch, isEmbed]);

  useEffect(
    () => () => {
      dispatch(setIsEmbed(false));
    },
    [dispatch],
  );

  return (
    <>
      <MapSidebar />
      <WorldMap className={s.worldMap} />
      <ModalEmbedJoin />
      <ModalEmbedLegends />
    </>
  );
};
