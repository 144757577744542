import {
  TAssetFieldTemplatesArgs,
  TFieldsTemplateUnion,
  TSettingsData,
} from 'src/utils/assetsFields/assetsFields.types';

import { CANARY_PROPERTIES_FIELDS } from 'src/utils/assetsFields/fieldTemplates';
import { ConfigType } from 'src/graphql';
import { EFormVariant } from 'src/typings/base-types';
import { HEATPUMP_PUMP_SOURCE_TYPE } from 'src/constants/application';
import { TConfigurationCharacteristic } from 'src/typings/configuration.types';

export type THeatPumpArgs = {
  isLibrary: boolean;
  values: TAssetFieldTemplatesArgs['values'];
  configurationCharacteristic: TConfigurationCharacteristic;
  settingsData: TSettingsData;
  configType: ConfigType | undefined;
  isSCM?: boolean;
};

export const HeatPumpPhoenix = ({
  isLibrary,
  configType,
}: THeatPumpArgs): TFieldsTemplateUnion[] => {
  const evaluatedCanaryNetworkFields = CANARY_PROPERTIES_FIELDS({
    configType,
  });

  return [
    {
      name: 'name',
      type: 'text',
      label: 'Name',
      formView: EFormVariant.Express,
    },
    {
      name: 'geoTagLocation',
      type: 'location',
      label: 'Location',
      tooltipText: 'Parent market already has location',
      EXCLUDE: isLibrary,
      formView: EFormVariant.Express,
    },
    {
      name: 'sourceType',
      type: 'enum',
      label: 'Heat Pump Type',
      options: HEATPUMP_PUMP_SOURCE_TYPE,
      formView: EFormVariant.Advanced,
    },
    {
      name: 'minTempC',
      type: 'number',
      label: 'Minimum Temperature',
      formView: EFormVariant.Advanced,
      unit: 'ºC',
    },
    {
      name: 'maxTempC',
      type: 'number',
      label: 'Maximum Temperature',
      formView: EFormVariant.Advanced,
      unit: 'ºC',
    },
    {
      name: 'tankVolumeL',
      type: 'number',
      label: 'Tank Volume',
      formView: EFormVariant.Advanced,
      unit: 'L',
    },
    {
      name: 'consumptionKwhProfile',
      type: 'file',
      label: 'Consumption Profile',
      formView: EFormVariant.Advanced,
      unit: 'kWh',
    },
    {
      name: 'consumptionKwhProfileUuid',
      formView: EFormVariant.Express,
    },
    {
      name: 'externalTemperatureOption',
      type: 'enum',
      label: 'External Temperature',
      options: [
        { value: 'userConfigure', label: 'User configure profile' },
        { value: 'userUpload', label: 'User upload profile' },
      ],
      formView: EFormVariant.Advanced,
    },
    {
      name: 'initialTempC',
      type: 'number',
      label: 'Initial Temperature',
      formView: EFormVariant.Advanced,
      unit: 'ºC',
    },

    {
      name: 'sourceTempCProfile',
      type: 'file',
      label: 'External Temperature Profile',
      formView: EFormVariant.Advanced,
      unit: 'ºC',
    },
    {
      name: 'sourceTempCProfileUuid',
      formView: EFormVariant.Express,
    },
    {
      name: 'consumptionProfileOption',
      type: 'enum',
      label: 'Consumption Profile',
      options: [
        { value: 'userConfigure', label: 'User configure profile' },
        { value: 'userUpload', label: 'User upload profile' },
      ],
      formView: EFormVariant.Advanced,
    },
    {
      name: 'maximumPowerRatingKw',
      type: 'number',
      label: 'Maximum Power Rating',
      formView: EFormVariant.Advanced,
      unit: 'kW',
    },
    ...evaluatedCanaryNetworkFields,
    {
      name: 'libraryUUID',
      formView: EFormVariant.Express,
    },
    {
      name: 'initialBuyingRate',
      type: 'number',
      label: 'Initial Buying Rate',
      unit: 'cents / kWh',
      formView: EFormVariant.Advanced,
    },
    {
      name: 'useMarketMakerRate',
      type: 'enum',
      label: 'Final Buying Rate',
      options: [
        { value: true, label: 'Market maker rate' },
        { value: false, label: 'User input' },
      ],
      formView: EFormVariant.Advanced,
    },
    {
      name: 'finalBuyingRate',
      label: 'Final Buying Rate',
      type: 'number',
      unit: 'cents / kWh',
      formView: EFormVariant.Advanced,
    },
    {
      name: 'preferredBuyingRate',
      type: 'number',
      label: 'Preferred Buying Rate',
      unit: 'cents / kWh',
      formView: EFormVariant.Advanced,
    },
    {
      name: 'updateInterval',
      type: 'number',
      label: 'Preferred Buying Rate',
      unit: 'cents / kWh',
      formView: EFormVariant.Advanced,
      EXCLUDE: true,
    },
  ];
};
