import React from 'react';
import { useHistory } from 'react-router';
import { Header } from 'src/components/Header';
import s from './MailChimp.module.scss';
import { BaseButton } from 'src/components/BaseButton';
import { FadeAnimationHelper } from 'src/components/AnimationHelpers/FadeAnimationHelper/FadeAnimationHelper';
import Mailchimp from 'react-mailchimp-form';

export const MailChimp: React.FC = () => {
  const headerColorMode = 'white';
  const history = useHistory();

  const MAIL_CHIMP_U = 'c9b8a52bc86fa5ba98160fca0';
  const MAIL_CHIMP_ID = '7d7a06da9f';

  const ACTION_URL = `https://d3a.us3.list-manage.com/subscribe/post-json?u=${MAIL_CHIMP_U}&amp;id=${MAIL_CHIMP_ID}`;

  return (
    <>
      <Header isLandingPage headerColorMode={headerColorMode} />
      <div className={s.content}>
        <BaseButton
          className={s.buttonBack}
          theme="quaternary"
          icon="arrow-left-full"
          onClick={() => history.goBack()}>
          BACK
        </BaseButton>
        <FadeAnimationHelper key="privacy-policy" className={s.container} delay={0.5}>
          <div className={s.heading}>Please fill out this form to Subscribe.</div>
          <Mailchimp
            action={ACTION_URL}
            className={s.form}
            fields={[
              {
                name: 'EMAIL',
                placeholder: 'Email',
                type: 'email',
                required: true,
              },
              {
                name: 'FNAME',
                placeholder: 'First Name',
                type: 'text',
                required: true,
              },
              {
                name: 'LNAME',
                placeholder: 'Last Name',
                type: 'text',
                required: true,
              },
              {
                name: 'MMERGE6',
                placeholder: 'Company',
                type: 'text',
                required: true,
              },
            ]}
          />
        </FadeAnimationHelper>
      </div>
    </>
  );
};
