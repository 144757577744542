import { TReactionOutput, TReactionPayload } from 'src/utils/assetsFields/assetsFields.types';
import { findField, replaceField } from 'src/utils/fieldUtils';

import { ConfigType } from 'src/graphql';
import { TValuesByFieldName } from 'src/utils/assetsFields/valuesByFieldName.types';
import { fieldValues } from 'src/utils/assetsFields/fieldValues';
import { getSingleFieldTemplateWithValueForAsset } from 'src/utils/assetsFields/fieldTemplatesWithValues';

const onAllowExternalConnection = ({
  fields,
  settingsData,
  newValue,
  type,
  configType,
  isLibrary,
  configurationCharacteristic,
}: TReactionPayload): TReactionOutput => {
  let output = fields;

  if (configType === ConfigType.CanaryNetwork) {
    const newField = getSingleFieldTemplateWithValueForAsset({
      type,
      name: 'forecastStreamEnabled',
      value: false,
      disabled: !newValue,
      settingsData,
      configType,
      isLibrary,
      configurationCharacteristic,
    });

    // The field "forecastStreamEnabled" only exists on Canary Networks
    if (newField) {
      output = replaceField({
        fields: output,
        name: 'forecastStreamEnabled',
        newFields: [newField],
      });
    }
  }

  return output;
};

const onforecastStreamEnabled = ({
  fields,
  settingsData,
  newValue,
  type,
  configType,
  isLibrary,
  configurationCharacteristic,
}: TReactionPayload): TReactionOutput => {
  let output = fields;
  if (configType === ConfigType.CanaryNetwork) {
    const newField = getSingleFieldTemplateWithValueForAsset({
      type,
      name: 'allowExternalConnection',
      value: !newValue,
      settingsData,
      configType,
      isLibrary,
      configurationCharacteristic,
    });

    // The field "allowExternalConnection" only exists on Canary Networks
    if (newField) {
      output = replaceField({
        fields: output,
        name: 'allowExternalConnection',
        newFields: [newField],
      });
    }
  }

  return output;
};

/* Update energyRateDecreasePerUpdate and energyRateIncreasePerUpdate for any device
   after updateInterval has been changed. */
const updateRateDecreaseOrIncrease = ({
  fields,
  newValue,
  direction,
  type,
  variable,
  settingsData,
  isLibrary,
  configType,
  configurationCharacteristic,
}: TReactionPayload & {
  direction: 'increase' | 'decrease';
  variable: 'fitToLimit' | 'updateInterval' | 'initialSellingRate' | 'finalBuyingRate';
}): TReactionOutput => {
  let output = fields;

  const fitToLimit =
    variable === 'fitToLimit' ? newValue : (findField(output, 'fitToLimit') || {}).value;
  const fieldName =
    direction === 'decrease' ? 'energyRateDecreasePerUpdate' : 'energyRateIncreasePerUpdate';

  if (fitToLimit) {
    if (
      isLibrary &&
      !configurationCharacteristic.marketMakerRate &&
      configurationCharacteristic.marketMakerRate !== 0
    ) {
      const newField = getSingleFieldTemplateWithValueForAsset({
        type,
        name: fieldName,
        values: { fitToLimit: fitToLimit as NonNullable<TValuesByFieldName>['fitToLimit'] },
        value: '',
        settingsData,
        isLibrary,
        configType,
        configurationCharacteristic,
      });

      if (newField) {
        output = replaceField({
          fields: output,
          name: fieldName,
          newFields: [newField],
        });
      }
    } else {
      const updateInterval =
        variable === 'updateInterval'
          ? newValue
          : (findField(fields, 'updateInterval') || {}).value;
      let initialRate;
      let finalRate;

      if (direction === 'decrease') {
        finalRate = (findField(fields, 'finalSellingRate') || {}).value;
        initialRate =
          type === 'Storage'
            ? (findField(fields, 'initialSellingRate') || {}).value
            : configurationCharacteristic.marketMakerRate;
      } else {
        if (type === 'Storage') {
          finalRate = (findField(fields, 'finalBuyingRate') || {}).value;
        } else {
          /* 
            If direction of rate change is 'increase' and device is not 'Storage'
            we can assume the device is a 'Load' and has a useMarketMakerRate property
          */
          const useMarketMakerRate = (findField(fields, 'useMarketMakerRate') || {}).value;
          if (String(useMarketMakerRate) === 'true') {
            finalRate = configurationCharacteristic.marketMakerRate;
          } else {
            finalRate =
              variable === 'finalBuyingRate'
                ? newValue
                : (findField(fields, 'finalBuyingRate') || {}).value;
          }
        }
        initialRate = (findField(fields, 'initialBuyingRate') || {}).value;
      }

      if (
        typeof initialRate === 'number' &&
        typeof finalRate === 'number' &&
        typeof updateInterval === 'number'
      ) {
        let energyRateChangePerUpdate =
          (initialRate - finalRate) /
          Math.max(settingsData.slotLengthMinutes / updateInterval - 1, 1);
        energyRateChangePerUpdate = Math.round(energyRateChangePerUpdate * 100) / 100;

        const newField = getSingleFieldTemplateWithValueForAsset({
          type,
          name: fieldName,
          values: { fitToLimit: fitToLimit as NonNullable<TValuesByFieldName>['fitToLimit'] },
          value:
            direction === 'decrease' ? energyRateChangePerUpdate : energyRateChangePerUpdate * -1,
          settingsData,
          isLibrary,
          configType,
          configurationCharacteristic,
        });

        if (newField) {
          output = replaceField({
            fields: output,
            name: fieldName,
            newFields: [newField],
          });
        }
      }
    }
  }

  return output;
};

/* Update energyRateDecreasePerUpdate or energyRateIncreasePerUpdate for any device
   after selling or buying rates have changed. */
const onChangeRateDecreaseOrIncrease = ({
  fields,
  newValue,
  type,
  rate,
  settingsData,
  isLibrary,
  configType,
  configurationCharacteristic,
}: TReactionPayload & {
  rate: 'finalSellingRate' | 'initialSellingRate' | 'initialBuyingRate' | 'finalBuyingRate';
}): TReactionOutput => {
  let output = fields;
  const fitToLimit = (findField(fields, 'fitToLimit') || {}).value;

  if (fitToLimit) {
    const updateInterval = (findField(fields, 'updateInterval') || {}).value;
    const marketMakerRate =
      configurationCharacteristic.marketMakerRate || fieldValues.DEFAULT_MARKET_MAKER_RATE;
    let initialRate;
    let finalRate;
    let fieldNameToReplace:
      | 'energyRateDecreasePerUpdate'
      | 'energyRateIncreasePerUpdate'
      | undefined;

    if (rate === 'finalSellingRate') {
      finalRate = newValue;
      initialRate =
        type === 'Storage'
          ? (findField(fields, 'initialSellingRate') || {}).value
          : configurationCharacteristic.marketMakerRate;
      fieldNameToReplace = 'energyRateDecreasePerUpdate';
    } else if (rate === 'initialBuyingRate') {
      if (type === 'Storage' || fields.some((field) => field.name === 'finalBuyingRate')) {
        finalRate = (findField(fields, 'finalBuyingRate') || {}).value;
      } else {
        finalRate = marketMakerRate;
      }
      initialRate = newValue;
      fieldNameToReplace = 'energyRateIncreasePerUpdate';
    } else if (rate === 'initialSellingRate') {
      finalRate = (findField(fields, 'finalSellingRate') || {}).value;
      initialRate = newValue;
      fieldNameToReplace = 'energyRateDecreasePerUpdate';
    } else if (rate === 'finalBuyingRate') {
      finalRate = newValue;
      initialRate = (findField(fields, 'initialBuyingRate') || {}).value;
      fieldNameToReplace = 'energyRateIncreasePerUpdate';
    }

    if (
      typeof initialRate === 'number' &&
      typeof finalRate === 'number' &&
      typeof updateInterval === 'number' &&
      typeof fieldNameToReplace === 'string'
    ) {
      let energyRateChangePerUpdate =
        (initialRate - finalRate) / Math.ceil(settingsData.slotLengthMinutes / updateInterval - 1);
      energyRateChangePerUpdate = Math.round(energyRateChangePerUpdate * 100) / 100;

      const newField = getSingleFieldTemplateWithValueForAsset({
        type,
        name: fieldNameToReplace,
        values: { fitToLimit: fitToLimit as NonNullable<TValuesByFieldName>['fitToLimit'] },
        value: rate.match('Selling') ? energyRateChangePerUpdate : energyRateChangePerUpdate * -1,
        settingsData,
        isLibrary,
        configType,
        configurationCharacteristic,
      });

      if (newField) {
        output = replaceField({
          fields: output,
          name: fieldNameToReplace,
          newFields: [newField],
        });
      }
    }
  }

  return output;
};

const onStorageCapacityChange = ({
  fields,
  settingsData,
  isLibrary,
  configType,
  configurationCharacteristic,
}: TReactionPayload): TReactionOutput => {
  let output = fields;
  const batteryCapField = findField(fields, 'batteryCapacityKwh');
  const initialSocField = findField(fields, 'initialSoc');
  const initialkWhField = findField(fields, 'initialkWh');
  let initialkWhValue: number | '' = '';

  if (batteryCapField && initialSocField) {
    const capacityValue = batteryCapField.value;
    const initialSocValue = initialSocField.value;

    if (capacityValue && initialSocValue) {
      initialkWhValue = Math.round(Number(initialSocValue) * Number(capacityValue)) / 100;
    }
  }

  const newField = getSingleFieldTemplateWithValueForAsset({
    type: 'Storage',
    name: 'initialkWh',
    value: initialkWhValue,
    settingsData,
    isLibrary,
    configType,
    configurationCharacteristic,
    disabled: initialkWhField?.disabled,
  });

  if (newField) {
    output = replaceField({
      fields: output,
      name: 'initialkWh',
      newFields: [newField],
    });
  }

  return output;
};

export {
  onAllowExternalConnection,
  onforecastStreamEnabled,
  updateRateDecreaseOrIncrease,
  onChangeRateDecreaseOrIncrease,
  onStorageCapacityChange,
};
