import React, { useRef, useState } from 'react';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';

import { BaseGenericModal } from 'src/components/BaseGenericModal';
import { BaseIcon } from 'src/components/BaseIcon';
import { EModalSize } from 'src/constants/modals';
import { TMapSidebarControlsProps } from './MapSidebarControls.types';
import classnames from 'classnames';
import s from './MapSidebarControls.module.scss';
import { useAppDispatch } from 'src/redux/store';
import { useModal } from 'src/hooks/useModal';

export const MapSidebarControls: React.FC<TMapSidebarControlsProps> = ({
  className,
  buttons,
  disableControls,
}) => {
  const btnRefs = useRef<HTMLButtonElement[]>([]);
  const [hoveredButtonRef, setHoveredButtonRef] = useState<
    React.MutableRefObject<HTMLElement | null>
  >({
    current: null,
  });
  const [hoveredButtonTooltip, setHoveredButtonTooltip] = useState('');
  const { id: modalId } = useModal();
  const dispatch = useAppDispatch();

  const onMouseEnter = (index: number) => {
    if (buttons[index].disabled && buttons[index].disabledTooltip) {
      setHoveredButtonRef({ current: btnRefs.current[index] });
      setHoveredButtonTooltip(buttons[index].disabledTooltip as string);
      dispatch(openModal(modalId));
    }
  };

  const onMouseLeave = () => {
    setHoveredButtonRef({ current: null });
    setHoveredButtonTooltip('');
    dispatch(closeModal(modalId));
  };

  return (
    <div
      className={classnames(s.container, className, {
        [s.disabled]: disableControls,
      })}>
      {buttons.map((btn, index) => (
        <div
          key={index}
          onMouseEnter={() => onMouseEnter(index)}
          onMouseLeave={onMouseLeave}
          data-disabled={disableControls || btn.disabled}>
          <button
            ref={(ref) => (btnRefs.current[index] = ref as HTMLButtonElement)}
            type="button"
            onClick={btn.onClick}
            className={classnames({
              [s.active]: btn.active,
            })}
            disabled={disableControls || btn.disabled}>
            <BaseIcon icon={btn.icon} size={btn.iconSize} />
          </button>
        </div>
      ))}
      <BaseGenericModal
        className={s.tooltipModal}
        modalId={modalId}
        relativeElement={hoveredButtonRef}
        size={EModalSize.S130}
        anchorArrow
        position={{ side: 'right', anchorPosition: 50 }}>
        {hoveredButtonTooltip}
      </BaseGenericModal>
    </div>
  );
};
