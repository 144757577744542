import React from 'react';
import { TChartDataLoadingWrapperProps } from './ChartDataLoadingWrapper.types';
import { TextBrick } from 'src/components/TextBrick';
import classNames from 'classnames';
import s from './ChartDataLoadingWrapper.module.scss';

export const ChartDataLoadingWrapper: React.FC<TChartDataLoadingWrapperProps> = ({
  children,
  className,
  loading,
}) => {
  return (
    <div className={classNames(s.container, className)}>
      {children}
      {loading && (
        <div className={s.overlayer}>
          <TextBrick>Loading Data</TextBrick>
        </div>
      )}
    </div>
  );
};
