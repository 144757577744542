import 'src/assets/styles/declarations/font-faces.css';
import 'src/assets/styles/declarations/_index.module.scss';
import './languages/i18n';

import { history, persistor, store, useAppDispatch } from 'src/redux/store';

import { ApolloProvider } from '@apollo/client';
import App from './App';
import { ApplicationContextProvider } from 'src/contexts/ApplicationContext';
import { ConnectedRouter } from 'connected-react-router';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import { client } from 'src/apollo-client';
import mapboxgl from 'mapbox-gl';

// Bugfix for: https://github.com/mapbox/mapbox-gl-js/issues/10173
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-var-requires
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

function WithApollo() {
  const dispatch = useAppDispatch();

  return (
    <ApolloProvider client={client(dispatch)}>
      <ConnectedRouter history={history}>
        <ApplicationContextProvider>
          <App />
        </ApplicationContextProvider>
      </ConnectedRouter>
    </ApolloProvider>
  );
}

const GOOGLE_OAUTH_CLIENT_ID =
  '1013094782402-etppmnp8oihpiiopsj1i4gkq6ciboo8g.apps.googleusercontent.com';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
          <WithApollo />
        </GoogleOAuthProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('app-root'),
);
