import { $ElementType } from 'utility-types';
import { ConfigType } from 'src/graphql';
import { TCommunitiesState } from 'src/redux/communities/communities.slice';
import { selectCommunitiesList } from 'src/redux/communities/communities.selectors';
import { selectCommunityName } from 'src/redux/configuration/configuration.selectors';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

type TGetConfigurationArgs = {
  configurationUuidForToUse: string;
};

type TGetConfigurationtoUseReturn = {
  getTheConfigTypeOfLaunchConfiguration: (payload: TGetConfigurationArgs) => ConfigType;
  getTheNameOfLaunchConfiguration: (payload: TGetConfigurationArgs) => string;
  getConfigurationToUse: (
    payload: TGetConfigurationArgs,
  ) => $ElementType<TCommunitiesState['communitiesList'], number> | undefined;
};

export function useGetConfigurationToUse(): TGetConfigurationtoUseReturn {
  const communitiesList = useSelector(selectCommunitiesList);
  const communityName = useSelector(selectCommunityName);

  const getConfigurationToUse = useCallback<
    (
      payload: TGetConfigurationArgs,
    ) => $ElementType<TCommunitiesState['communitiesList'], number> | undefined
  >(
    (payload) => {
      return communitiesList.find((item) => {
        return item?.configurations[0]?.uuid === payload.configurationUuidForToUse;
      });
    },
    [communitiesList],
  );

  const getTheNameOfLaunchConfiguration = useCallback<(payload: TGetConfigurationArgs) => string>(
    (payload) => {
      const configurationForToUse = getConfigurationToUse(payload);

      const cName = configurationForToUse ? (configurationForToUse.name as string) : communityName;

      return cName;
    },
    [getConfigurationToUse, communityName],
  );

  const getTheConfigTypeOfLaunchConfiguration = useCallback<
    (payload: TGetConfigurationArgs) => ConfigType
  >(
    (payload) => {
      const configurationForToUse = getConfigurationToUse(payload);

      const cName =
        configurationForToUse &&
        configurationForToUse.configurations[0] &&
        configurationForToUse.configurations[0].configType
          ? (configurationForToUse.configurations[0].configType as ConfigType)
          : ConfigType.Simulation;

      return cName;
    },
    [getConfigurationToUse],
  );

  return {
    getTheConfigTypeOfLaunchConfiguration,
    getTheNameOfLaunchConfiguration,
    getConfigurationToUse,
  };
}
