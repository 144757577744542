import { random } from 'lodash';
import vars from 'src/assets/styles/utils/vars.module.scss';

type TColorPalette = string[];

const COLOR_PALETTE: TColorPalette = [
  vars['color-ace-blue'],
  vars['color-oh-so-green'],
  vars['color-singularity-red'],
  vars['color-baby-blue'],
  vars['color-purple-1'],
  '#9B6BFD',
  '#2E4FB4',
  '#44948A',
  '#DC9947',
  '#DD524B',
  '#479CD7',
  '#92AE69',
  '#9343CA',
  '#CCB959',
  '#6EA2FD',
  '#DC7649',
];

function getRandomColor(): string {
  return `rgb(${random(0, 255)}, ${random(0, 255)}, ${random(0, 255)})`;
}

const getColorForIndex = (index: number): string => COLOR_PALETTE[index] || getRandomColor();

export function getColorFromPalette(index: number, usedColors: TColorPalette = []): string {
  let _index = index;
  const _usedColors = [...usedColors, vars['color-net-energy']];
  let color = getColorForIndex(_index);

  if (_usedColors) {
    while (_usedColors.includes(color)) {
      _index++;
      color = getColorForIndex(_index);
    }
  }

  return color;
}
