export const getGradientWeight = (val: number, min: number, max: number): number => {
  return (val + Math.abs(min)) / (Math.abs(max) + Math.abs(min));
};

export const getLayer = (endAcc: number, mp: number): number => {
  let accumulator = 0;
  let layerID = 0;

  for (let i = 0; ; i++) {
    accumulator += mp * i;
    if (accumulator >= endAcc) {
      layerID = i;
      break;
    }
  }

  return layerID;
};

export const getSigma = (endIndexInclusive: number, mp: number): number => {
  let accumulator = 0;

  for (let i = 0; i <= endIndexInclusive; ++i) {
    accumulator += mp * i;
  }

  return accumulator;
};

export const devide = (num1: number, num2: number): number => {
  if (num2 === 0) return 0;

  return num1 / num2;
};
