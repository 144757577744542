import { TCustomPVProps } from 'src/components/CustomPV/CustomPV.types';

export type TOrientationProps = {
  className?: string;
  azimuthValuesForCustomPV?: TOrientationParam;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setAzimuthValuesForCustomPV?: (values: any) => void;
  theme?: TCustomPVProps['theme'];
};

export enum EOrientationView {
  BASIC = 'basic',
  ADVANCED = 'advanced',
}

export enum EOrientationParam {
  AZIMUTH = 'azimuth',
  TILT = 'tilt',
}

export type TOrientationParam = {
  [EOrientationParam.AZIMUTH]: number;
  [EOrientationParam.TILT]: number;
};
