import { TFieldsUnionWithValue } from 'src/utils/assetsFields/assetsFields.types';
import { TOrientationParam } from 'src/components/CustomPV/components/Orientation/Orientation.types';

export type TCustomPVProps = {
  className?: string;
  capacityFields: TFieldsUnionWithValue[];
  tradingFields: TFieldsUnionWithValue[];
  azimuthValuesForCustomPV?: TOrientationParam;
  setAzimuthValuesForCustomPV?: (values: TOrientationParam) => void;
  showOrientationSection?: boolean;
  theme?: 'light' | 'dark';
};

export enum EAccordionType {
  capacityAndProfile = 'Capacity and Profile',
  orientation = 'Orientation',
  tradingStrategy = 'Trading Strategy',
}
