import { useCallback, useState } from 'react';

type TSetStateMerge<T> = React.Dispatch<Partial<T> | ((prevTtate: T) => Partial<T>)>;

export function useMergedState<T>(initialState: T | (() => T)): [T, TSetStateMerge<T>] {
  const [state, setState] = useState<T>(initialState);
  type TPrevStateFunc = (prevState: Partial<T>) => Partial<T>;

  const setStateMerge: TSetStateMerge<T> = useCallback((arg) => {
    if (typeof arg === 'function') {
      setState((curr) => {
        const newVal = (arg as TPrevStateFunc)(curr);
        return { ...curr, ...newVal };
      });
    } else {
      setState((curr) => ({ ...curr, ...arg }));
    }
  }, []);

  return [state, setStateMerge];
}
