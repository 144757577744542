import {
  EConfigurationFlowState,
  useConfigurationFlowState,
} from 'src/hooks/useConfigurationFlowState';
import React, { useContext, useMemo, useState } from 'react';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import {
  selectActiveConfigurationUuid,
  selectSimulationStatus,
} from 'src/redux/configuration/configuration.selectors';
import { selectActiveDashboard, selectIsEmbed } from 'src/redux/application/application.selectors';

import { BaseIcon } from 'src/components/BaseIcon';
import { EPredefinedModalIds } from 'src/constants/modals';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';
import { TWorldMapNavigationProps } from './WorldMapNavigation.types';
import WorldMapContext from 'src/contexts/WorldMapContext';
import classnames from 'classnames';
import s from './WorldMapNavigation.module.scss';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { selectTutorialStep } from 'src/redux/application/application.selectors';
import { selectUserToAdminRequests } from 'src/redux/communities/communities.selectors';
import { useAppDispatch } from 'src/redux/store';
import { useAppFlow } from 'src/hooks/useAppFlow';
import { useSelector } from 'react-redux';

export const WorldMapNavigation = ({ className }: TWorldMapNavigationProps): JSX.Element => {
  const { mapService } = useContext(WorldMapContext);
  const [is3DView, setIs3DView] = useState(mapService?.is3DView());
  const isEmbed = useSelector(selectIsEmbed);
  const dispatch = useAppDispatch();
  const isModalAddMoreOpened = useSelector(selectIsModalOpened(EPredefinedModalIds.MODAL_ADD_MORE));
  const activeDashboard = useSelector(selectActiveDashboard);
  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
  const userToAdminRequests = useSelector(selectUserToAdminRequests);
  const { isSCMFlow } = useAppFlow();
  const isRequestedForCn = useMemo(() => {
    return (
      activeConfigurationUuid &&
      userToAdminRequests[activeConfigurationUuid]?.find(
        (item) => item.type === 'CONVERT_SIM_TO_CN' && item.status === 'RECEIVED',
      )
    );
  }, [activeConfigurationUuid, userToAdminRequests]);

  const isModalOnboardingOpened = useSelector(
    selectIsModalOpened(EPredefinedModalIds.MODAL_ONBOARDING),
  );
  const isCommunityAssetsOpened = useSelector(
    selectIsModalOpened(EPredefinedModalIds.MODAL_COMMUNITY_ASSETS),
  );
  const isMapAlertModalOpened = useSelector(
    selectIsModalOpened(EPredefinedModalIds.MODAL_MAP_ALERT),
  );
  const { modalAddMoreShow } = useConfigurationFlowState();

  const simulationStatus = useSelector(selectSimulationStatus);
  const tutorialStep = useSelector(selectTutorialStep);
  const { configurationFlowState } = useConfigurationFlowState();

  const buttons = {
    alone: [
      {
        icon: 'compass',
        iconSize: 20,
        onClick: () => mapService?.map.rotateTo(0, { duration: 500 }),
        className: s.compassButton,
        zIndex: 100,
      },
      {
        icon: 'plus-thick',
        iconSize: 14.25,
        onClick: () => mapService?.zoomIn(),
        className: s.geolocateButton,
        zIndex: tutorialStep === 3 ? 999999 : 100,
      },
      {
        icon: 'minus-thick',
        iconSize: 14.25,
        onClick: () => mapService?.zoomOut(),
        className: s.geolocateButton,
        zIndex: tutorialStep === 3 ? 999999 : 100,
      },
      {
        icon: 'location',
        iconSize: 17.4,
        onClick: () => {
          mapService?.geolocate();
        },
        className: s.geolocateButton,
        zIndex: 100,
      },
    ],
    pitch: [
      {
        icon: 'map',
        iconSize: 12,
        onClick: () => {
          setIs3DView(false);
          mapService?.set3DView(false);
        },
        isActive: !is3DView,
      },
      {
        icon: 'cube',
        iconSize: 18,
        onClick: () => {
          setIs3DView(true);
          mapService?.set3DView(true);
        },
        isActive: is3DView,
      },
    ],
  };

  return (
    <div className={classnames(s.container, className, { [s.containerEmbed]: isEmbed })}>
      {!activeDashboard && !isSCMFlow && (
        <div className={s.buttonAddMore}>
          <button
            disabled={simulationStatus == 'running'}
            className={classnames(s.aloneButton, s.onboardingButton, {
              [s.active]: isModalOnboardingOpened,
            })}
            type="button"
            onClick={() => {
              if (!isModalOnboardingOpened) {
                dispatch(openModal(EPredefinedModalIds.MODAL_ONBOARDING));
              } else {
                dispatch(closeModal(EPredefinedModalIds.MODAL_ONBOARDING));
              }
            }}>
            <BaseIcon icon="question-mark" size={19} />
          </button>
        </div>
      )}
      {buttons.alone.map((item, index) => (
        <button
          key={index}
          style={{ zIndex: item.zIndex }}
          className={classnames(s.aloneButton, item.className)}
          type="button"
          onClick={item.onClick}>
          <BaseIcon icon={item.icon as TIconNames} size={item.iconSize} />
        </button>
      ))}

      <div
        className={classnames(s.buttonGroup, { [s.active3d]: is3DView })}
        style={{ zIndex: tutorialStep === 4 ? 999999 : 100 }}>
        <div>
          <div
            className={s.btnBackground}
            style={{
              transform: `translateY(${is3DView ? 42 : 0}px)`,
            }}
          />
          {buttons.pitch.map((item, index) => (
            <React.Fragment key={index}>
              <button
                type="button"
                className={classnames(s.buttonInsideGroup, {
                  [s.buttonInsideGroupActive]: item.isActive,
                })}
                onClick={item.onClick}>
                <BaseIcon icon={item.icon as TIconNames} size={item.iconSize} />
              </button>
              {index < buttons.pitch.length - 1 && <div className={s.separator} />}
            </React.Fragment>
          ))}
        </div>
      </div>
      {modalAddMoreShow && (
        <div className={s.buttonAddMore}>
          <button
            className={classnames(s.aloneButton, {
              [s.active]: isModalAddMoreOpened,
            })}
            type="button"
            onClick={() => {
              if (!isModalAddMoreOpened) {
                dispatch(openModal(EPredefinedModalIds.MODAL_ADD_MORE));
              } else {
                dispatch(closeModal(EPredefinedModalIds.MODAL_ADD_MORE));
              }
            }}>
            <BaseIcon icon="arrow-loading" size={19} />
          </button>
        </div>
      )}
      {/* {modalCommunityAssetsShow && ( */}
      {(configurationFlowState == EConfigurationFlowState.OnlineBuildMode ||
        configurationFlowState == EConfigurationFlowState.SimulationEdit) && (
        <div className={s.buttonAddMore}>
          <button
            className={classnames(s.aloneButton, {
              [s.active]: isCommunityAssetsOpened,
            })}
            type="button"
            onClick={() => {
              if (!isCommunityAssetsOpened) {
                dispatch(openModal(EPredefinedModalIds.MODAL_COMMUNITY_ASSETS));
              } else {
                dispatch(closeModal(EPredefinedModalIds.MODAL_COMMUNITY_ASSETS));
              }
            }}>
            <BaseIcon icon="list" size={19} />
          </button>
        </div>
      )}
      {!isRequestedForCn && simulationStatus === 'finished' && (
        <div className={s.buttonAddMore}>
          <button
            className={classnames(s.aloneButton, {
              [s.active]: isMapAlertModalOpened,
            })}
            type="button"
            onClick={() => {
              if (!isMapAlertModalOpened) {
                dispatch(openModal(EPredefinedModalIds.MODAL_MAP_ALERT));
              } else {
                dispatch(closeModal(EPredefinedModalIds.MODAL_MAP_ALERT));
              }
            }}>
            <BaseIcon icon="canary" size={19} />
          </button>
        </div>
      )}
    </div>
  );
};
