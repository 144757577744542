import { PrivateRoute } from 'src/routes/PrivateRoute';
import { PublicRoute } from 'src/routes/PublicRoute';
import React from 'react';
import { Switch } from 'react-router';
import { TMobileViewProps } from './MobileView.types';
import { mobileRoutes as routes } from 'src/routes/routes.config';
import s from './MobileView.module.scss';

export const MobileView: React.FC<TMobileViewProps> = () => {
  return (
    <div className={s.container}>
      <Switch>
        {routes.map((item, index) => {
          if (item.private) {
            return <PrivateRoute key={index} {...item} />;
          }
          return <PublicRoute key={index} {...item} />;
        })}
      </Switch>
    </div>
  );
};
