import React from 'react';
import classnames from 'classnames';
import { TAvatarProps } from './Avatar.types';

import s from './Avatar.module.scss';

export const Avatar: React.FC<TAvatarProps> = ({
  className,
  url = 'http://www.gravatar.com/avatar/?d=mp',
  outline = false,
}) => {
  return (
    <div className={classnames(s.container, className, outline ? '' : s.outlineNone)}>
      <img src={url} className={s.avatar} alt="" />
    </div>
  );
};
