import { ELsKey, ls } from 'src/utils/localStorage';
import React, { useCallback, useEffect } from 'react';
import { batch, useSelector } from 'react-redux';
import {
  selectIsCommunityCreatedInDB,
  selectSimulationStatus,
} from 'src/redux/configuration/configuration.selectors';
import { setActiveSCMStep } from 'src/redux/scm/scm.slice';

import { BaseButtonRound } from 'src/components/BaseButtonRound';
import { BaseIcon } from 'src/components/BaseIcon';
import { EButtonType } from './SCMHeaderSteps.types';
import { EPredefinedModalIds } from 'src/constants/modals';
import { EUserRoles } from 'src/typings/base-types';
import classnames from 'classnames';
import { openModal } from 'src/redux/modals/modals.slice';
import s from './SCMHeaderSteps.module.scss';
import { selectActiveSCMStep } from 'src/redux/scm/scm.selectors';
import { selectUserRole } from 'src/redux/auth/auth.selectors';
import { useAppDispatch } from 'src/redux/store';

const SCM_STEPS = [
  'Community Builder',
  'Members Management',
  'Market Settings',
  'Visualise and Assess',
  'Operate Your Community',
];

export const SCMHeaderSteps: React.FC = () => {
  const dispatch = useAppDispatch();
  const userRole = useSelector(selectUserRole);
  const activeStep = useSelector(selectActiveSCMStep);
  const isCommunityCreatedInDB = useSelector(selectIsCommunityCreatedInDB);
  const simulationStatus = useSelector(selectSimulationStatus);
  const isDisabledArea = ['running', 'paused', 'queued', 'started', 'initializing'].includes(
    simulationStatus as string,
  );
  useEffect(() => {
    const scmStep = ls.get(ELsKey.SCM_STEP);
    if (scmStep && isCommunityCreatedInDB) {
      dispatch(setActiveSCMStep(Number(scmStep)));
    }
  }, [dispatch, isCommunityCreatedInDB]);

  const handleArrowButtonClick = useCallback<(buttonType: EButtonType) => void>(
    (buttonType) => {
      if (buttonType === EButtonType.next && activeStep + 1 < SCM_STEPS.length) {
        dispatch(setActiveSCMStep(activeStep + 1));
        return;
      }

      if (buttonType === EButtonType.prev && activeStep >= 0) {
        dispatch(setActiveSCMStep(activeStep - 1));
      }
    },
    [activeStep, dispatch],
  );

  if (userRole === EUserRoles.Aggregator || userRole === EUserRoles.Researcher) {
    return null;
  }

  const handleStepClick = (value: number) => {
    dispatch(setActiveSCMStep(value));
    switch (value) {
      case 0:
        return dispatch(openModal(EPredefinedModalIds.MODAL_SCM_BUILD_COMMUNITY));
      case 1:
        return batch(() => {
          // dispatch(openModal(EPredefinedModalIds.MODAL_SCM_UPLOAD_DATA_SHEET));
        });

      case 2:
        return batch(() => {
          dispatch(openModal(EPredefinedModalIds.MODAL_MAP_SIDEBAR));
        });
      default:
        return;
    }
  };

  return (
    <div
      className={s.container}
      style={{
        pointerEvents: isDisabledArea ? 'none' : 'auto',
      }}>
      <BaseButtonRound
        className={s.arrowButton}
        theme="border"
        disabled={activeStep === 0}
        onClick={() => handleArrowButtonClick(EButtonType.prev)}>
        <BaseIcon icon="arrow-modern-prev" size={10} />
      </BaseButtonRound>

      {SCM_STEPS.map((step, index) => {
        return (
          <button
            type="button"
            disabled={index === 4}
            key={step}
            className={classnames(s.stepContainer, {
              [s.active]: step === SCM_STEPS[activeStep],
              [s.covered]: index <= activeStep,
            })}
            onClick={() => handleStepClick(index)}>
            {index < activeStep && <BaseIcon icon="check-mark" size={16} />}
            <div className={s.textTab}>{step}</div>
          </button>
        );
      })}

      <BaseButtonRound
        className={s.arrowButton}
        theme="border"
        disabled={activeStep === SCM_STEPS.length - 1}
        onClick={() => handleArrowButtonClick(EButtonType.next)}>
        <BaseIcon icon="arrow-modern-next" size={10} />
      </BaseButtonRound>
    </div>
  );
};
