// Position in an array reflects a chart number, One, Two, Three or Four.
export const config = {
  dataOneKey: {
    Load: 'load_profile_kWh' as const,
    HeatPump: 'energy_consumption_kWh' as const,
    PV: 'pv_production_kWh' as const,
    Storage: 'soc_history_%' as const,
    FiniteDieselGenerator: 'production_kWh' as const,
    MarketMaker: undefined,
    InfiniteBus: undefined,
  },
  dataTwoKey: {
    Load: 'trade_energy_kWh' as const,
    HeatPump: 'trade_energy_kWh' as const,
    PV: 'trade_energy_kWh' as const,
    Storage: 'trade_energy_kWh' as const,
    FiniteDieselGenerator: 'sold_trade_energy_kWh' as const,
    MarketMaker: 'sold_trade_energy_kWh' as const,
    InfiniteBus: 'sold_trade_energy_kWh' as const,
  },
  YAxis: {
    Load: {
      label: ['Load Profile', 'Energy Bought (kWh)', 'Energy Price'],
    },
    HeatPump: {
      label: [
        'HeatPump Consumption Profile',
        'Energy Bought (kWh)',
        'Energy Price',
        'Tank Temperature (ºC)',
      ],
    },
    PV: {
      label: ['PV Profile', 'Energy Sold (kWh)', 'Energy Price'],
    },
    Storage: {
      label: ['State of Charge', 'Energy Traded', 'Energy Price'],
    },
    CommercialProducer: {
      label: [null, 'Energy Sold (kWh)', 'Energy Price'],
    },
    FiniteDieselGenerator: {
      label: ['Energy Profile', 'Energy Sold (kWh)', 'Energy Price'],
    },
    MarketMaker: {
      label: [null, 'Energy Sold (kWh)', 'Energy Price', 'Energy Bought (kWh)'],
    },
    InfiniteBus: {
      label: [null, 'Energy Sold (kWh)', 'Energy Price', 'Energy Bought (kWh)'],
    },
  },
  tooltipLabels: {
    Load: ['Load Profile', 'Energy Bought', 'Load Profile'],
    HeatPump: ['Consumption', 'Energy Bought', 'HeatPump Profile', 'Tank Temperature'],
    PV: ['PV Profile', 'Energy Sold', 'PV Profile'],
    Storage: ['State of Charge', 'Energy Traded'],
    CommercialProducer: ['', 'Energy Sold'],
    FiniteDieselGenerator: ['Energy Profile', 'Energy Sold', 'Energy Profile'],
    MarketMaker: ['', 'Energy Sold', '', 'Energy Bought'],
    InfiniteBus: ['', 'Energy Sold', '', 'Energy Bought'],
  },
  tooltipUnits: {
    Load: ['kWh', 'kWh', 'kWh'],
    HeatPump: ['kWh', 'kWh', 'kWh', 'C'],
    PV: ['kWh', 'kWh', 'kWh'],
    Storage: ['%', 'kWh'],
    CommercialProducer: ['', 'kWh'],
    FiniteDieselGenerator: ['kWh', 'kWh', 'kWh'],
    MarketMaker: ['', 'kWh', '', 'kWh'],
    InfiniteBus: ['', 'kWh', '', 'kWh'],
  },
};
