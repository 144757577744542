import { WebGLRenderer } from 'src/three';

import Stats from 'three/examples/jsm/libs/stats.module';

class CanvasStats {
  private stats: Stats; // Stats for FPS, allocated memory, and milliseconds for one frame rendering
  private gui: HTMLDivElement; // GUI for render info
  private container: HTMLElement; // Container div

  constructor(container: HTMLElement) {
    this.container = container;
    this.stats = new Stats();
    this.gui = document.createElement('div');

    this.init();
  }

  /**
   * Initialize
   */
  init = (): void => {
    this.stats.dom.style.position = 'absolute';
    this.stats.dom.style.top = 'auto';
    this.stats.dom.style.bottom = '30px';
    this.stats.dom.style.left = 'auto';
    this.stats.dom.style.right = '0';
    this.gui.setAttribute(
      'style',
      'position: absolute; bottom: 78px; right: 0; display: flex; flex-direction: column; z-index: 1000; padding: 20px; background: rgba(0, 0, 255, 0.8); color: white; border-radius: 5px;',
    );

    this.container.appendChild(this.stats.dom);
    this.container.appendChild(this.gui);
  };

  /**
   * Tick
   */
  tick = (renderer: WebGLRenderer): void => {
    if (!renderer) return;
    const { info: renderInfo } = renderer;

    this.stats.update();
    this.gui.innerHTML = [
      '<span><i>Geometries</i>: ' + renderInfo.memory.geometries + '</span>',
      '<span><i>Textures</i>: ' + renderInfo.memory.textures + '</span>',
      '<span><i>Draw calls</i>: ' + renderInfo.render.calls + '</span>',
      '<span><i>Triangles </i>: ' + renderInfo.render.triangles + '</span>',
      '<span><i>Points </i>: ' + renderInfo.render.points + '</span>',
      '<span><i>Lines </i>: ' + renderInfo.render.lines + '</span>',
      '<span><i>Frame </i>: ' + renderInfo.render.frame + '</span>',
    ].join('<br/>');
  };
}

export default CanvasStats;
