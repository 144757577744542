import {
  TAssetFieldTemplatesArgs,
  TFieldsTemplateUnion,
  TNumberFieldTemplate,
  TSettingsData,
  TTextFieldTemplate,
} from 'src/utils/assetsFields/assetsFields.types';

import { CANARY_PROPERTIES_FIELDS } from 'src/utils/assetsFields/fieldTemplates';
import { CLOUD_COVERAGE_OPTIONS } from 'src/constants/application';
import { ConfigType } from 'src/graphql';
import { EFormVariant } from 'src/typings/base-types';
import { TConfigurationCharacteristic } from 'src/typings/configuration.types';

export type TPVSCMArgs = {
  isLibrary: boolean;
  values: TAssetFieldTemplatesArgs['values'];
  configurationCharacteristic: TConfigurationCharacteristic;
  settingsData: TSettingsData;
  configType: ConfigType | undefined;
};

export const PVPhoenix = ({
  isLibrary,
  values,
  configurationCharacteristic,
  settingsData,
  configType,
}: TPVSCMArgs): TFieldsTemplateUnion[] => {
  const evaluatedCanaryNetworkFields = CANARY_PROPERTIES_FIELDS({
    configType,
  });

  return [
    // ...evaluatedDeviceKpisFields,
    {
      name: 'name',
      type: 'text',
      label: 'Name',
      formView: EFormVariant.Express,
    },
    {
      name: 'geoTagLocation',
      type: 'location',
      label: 'Location',
      tooltipText: 'Parent market already has location',
      EXCLUDE: isLibrary,
      formView: EFormVariant.Express,
    },
    // {
    //   name: 'count',
    //   type: 'counter',
    //   label: 'Multiply same unit',
    //   min: 1,
    //   formView: EFormVariant.Express,
    // },
    {
      name: 'capacityKw',
      type: 'number',
      label: 'Capacity',
      formView: EFormVariant.Advanced,
      unit: 'kWp',
    },
    {
      name: 'cloudCoverage',
      type: 'enum',
      label: 'Profile',
      options: CLOUD_COVERAGE_OPTIONS,
      formView: EFormVariant.Advanced,
      showTooltip: true,
      tooltipText: 'To get correct results, please make sure that all entries in the date column match the selected market time slots'
    },
    {
      name: 'powerProfile',
      type: 'file',
      label: 'Override Profile',
      formView: EFormVariant.Advanced,
    },
    ...evaluatedCanaryNetworkFields,
    {
      name: 'powerProfileUuid',
      formView: EFormVariant.Express,
    },
    {
      name: 'libraryUUID',
      formView: EFormVariant.Express,
    },
    {
      name: 'useMarketMakerRate',
      type: 'enum',
      label: 'Initial Selling Rate',
      options: [
        { value: true, label: 'Market maker rate' },
        { value: false, label: 'User input' },
      ],
      formView: EFormVariant.Advanced,
    },
    {
      name: 'initialSellingRate',
      type: 'number',
      unit: 'cents / kWh',
      formView: EFormVariant.Advanced,
      EXCLUDE: values?.useMarketMakerRate,
    },
    {
      name: 'finalSellingRate',
      type: 'number',
      label: 'Final Selling Rate',
      unit: 'cents / kWh',
      formView: EFormVariant.Advanced,
    },
    {
      name: 'energyRateDecreasePerUpdate',
      type:
        configurationCharacteristic.gridMakerHasUploadedProfile && values?.fitToLimit
          ? 'text'
          : 'number',
      label: 'Rate Decrease',
      unit: 'cents / kWh per update',
      disabled: configurationCharacteristic.gridMakerHasUploadedProfile && values?.fitToLimit,
      formView: EFormVariant.Advanced,
    } as TNumberFieldTemplate | TTextFieldTemplate,
    {
      name: 'fitToLimit',
      type: 'checkbox',
      label: 'Linear Pricing',
      formView: EFormVariant.Advanced,
    },
    {
      name: 'updateInterval',
      type: 'slider',
      label: 'Update Interval',
      unit: 'min',
      showUnit: true,
      step: 1,
      // labelStep: settingsData.slotLengthMinutes - 2,
      minVal: 1,
      maxVal: settingsData.slotLengthMinutes - 1,
      formView: EFormVariant.Advanced,
    },
  ];
};
