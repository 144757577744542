import { ESavingType, TAssetStatusTextProps } from './AssetStatusText.types';
import React, { useMemo, useState, useEffect } from 'react';
import {
  selectSettingsData,
  selectSimulationResults,
} from 'src/redux/configuration/configuration.selectors';
import { getSavingTypeAdvanced } from 'src/components/ChartSavings';

import classnames from 'classnames';
import { formatter } from 'src/utils/formatter';
import s from './AssetStatusText.module.scss';
import { useSelector } from 'react-redux';

const ProfitInfoTag = ({ latestSavingsKpi, currencySymbol, assetName }) => {
  const { saving_absolute } = latestSavingsKpi;
  const result = getSavingTypeAdvanced(latestSavingsKpi) || {};

  const showProfit = saving_absolute > 0 && result[ESavingType.Earning] > 0;
  const showSaving = saving_absolute > 0 && result[ESavingType.Saving] > 0;

  return saving_absolute !== null ? (
    <>
      {showProfit && (
        <div className={classnames(s.differenceInfoTag, s.mb)}>
          <span className={s.icon}>🚀</span>
          <p>
            Yay, {assetName} had &nbsp;
            <span className={s.earning}>
              +{result[ESavingType.Earning].toFixed(2)}
              {currencySymbol} Profit !
            </span>
          </p>
        </div>
      )}
      {showSaving && (
        <div className={classnames(s.differenceInfoTag, s.mb)}>
          <span className={s.icon}>🌞</span>
          <p>
            Yay, {assetName} Saved &nbsp;
            <span className={s.saving}>
              +{result[ESavingType.Saving].toFixed(2)}
              {currencySymbol}!
            </span>
          </p>
        </div>
      )}
      {!showProfit && !showSaving && saving_absolute < 0 && (
        <div className={classnames(s.differenceInfoTag, s.mb)}>
          <span className={s.icon}>🚨</span>
          <p>
            Be careful, {assetName} LOST &nbsp;
            <span className={s.losses}>
              {result[ESavingType.Losses].toFixed(2)}
              {currencySymbol}
            </span>
          </p>
        </div>
      )}
      {!showProfit && !showSaving && saving_absolute === 0 && (
        <div className={classnames(s.differenceInfoTag, s.mb)}>
          <span className={s.icon}>😕</span>
          <p>
            {assetName} stayed neutral &nbsp;
            <span className={s.neutral}>0{currencySymbol} savings</span>
          </p>
        </div>
      )}
    </>
  ) : null;
};

export const AssetStatusText: React.FC<TAssetStatusTextProps> = ({ assetName }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const simulationResults = useSelector(selectSimulationResults);
  const settingsData = useSelector(selectSettingsData);
  const currencySymbol = formatter.getCurrencySymbol(settingsData?.currency);
  const { latestSavingsKpi, kpi } = simulationResults || {};

  const KpiResultsCombined = useMemo(() => {
    const output = latestSavingsKpi ? { ...latestSavingsKpi } : undefined;

    function currencyFormatter(value) {
      return Math.round(value + Number.EPSILON) / 100;
    }

    function savingFormatter(value) {
      return Math.round(Math.trunc(value) + Number.EPSILON) / 100;
    }

    if (kpi && latestSavingsKpi && output) {
      const keys = Object.keys(latestSavingsKpi || {});
      keys.forEach((key) => {
        output[key] = kpi[key]
          ? key === 'saving_absolute'
            ? savingFormatter(kpi[key])
            : currencyFormatter(Number(kpi[key]))
          : 0;
      });
    }
    return output;
  }, [latestSavingsKpi, kpi]);

  useEffect(() => {
    // hack for hiding the text when the home changes
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 4000); // 4 seconds delay
    return () => {
      // Warning Fix - Can't perform a React state update on an unmounted component
      setIsLoading(false);
    };
  }, [assetName]);

  return (
    <div className={s.container}>
      {!isLoading && latestSavingsKpi && latestSavingsKpi.saving_absolute !== null ? (
        <ProfitInfoTag
          latestSavingsKpi={KpiResultsCombined}
          currencySymbol={currencySymbol}
          assetName={assetName}
        />
      ) : (
        <div className={s.loadingWrapper} />
      )}
    </div>
  );
};
