import React from 'react';
import { TDownloadResultsAlertProps } from './DownloadResultsAlert.types';
import classnames from 'classnames';
import s from './DownloadResultsAlert.module.scss';

export const DownloadResultsAlert: React.FC<TDownloadResultsAlertProps> = ({ className }) => {
  return (
    <div className={classnames(s.box, className)}>
      <span className={s.alertHeading}>⚠️ Alert</span>
      <div className={s.alertText}>
        Your simulation results will be available for viewing and download for 90 days from the
        simulation launch.
      </div>
    </div>
  );
};
