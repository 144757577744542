import { BaseBreadcrumbs, TBreadcrumbsItem } from 'src/components/BaseBreadcrumbs';
import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import React, { useCallback, useContext } from 'react';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';

import { BaseGenericModal } from 'src/components/BaseGenericModal';
import { MapSidebarControls } from 'src/components/MapSidebar/components/MapSidebarControls';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';
import { TMapSidebarNavigationBarProps } from './MapSidebarNavigationBar.types';
import { TMapboxLocation } from 'src/services/api/mapbox.api';
import WorldMapContext from 'src/contexts/WorldMapContext';
import s from './MapSidebarNavigationBar.module.scss';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { selectVisibleLocation } from 'src/redux/map/map.selectors';
import { useAppDispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { selectIsEmbed } from 'src/redux/application/application.selectors';
import { setModalMapHeroDismissed } from 'src/redux/application/application.slice';
import {
  selectModalMapHeroDismissed,
  selectTutorialStep,
} from 'src/redux/application/application.selectors';

const createLocationBreadcrumbs = (location: TMapboxLocation[], zoom: number) => {
  let zoomLevelBreadcrumbs: string[] = [];

  if (zoom > 15) {
    zoomLevelBreadcrumbs = ['country', 'place', 'postcode'];
  } else if (zoom <= 15 && zoom > 5) {
    zoomLevelBreadcrumbs = ['country', 'place'];
  } else if (zoom <= 5 && zoom > 2) {
    zoomLevelBreadcrumbs = ['country'];
  } else {
    return [{ name: 'Earth' }];
  }

  const result = location
    .filter((item) => zoomLevelBreadcrumbs.includes(item.place_type[0]))
    .map((item) => ({ name: item.text }));

  if (result.length === 0) {
    return [{ name: 'Earth' }];
  }
  return result;
};

export const MapSidebarNavigationBar: React.FC<TMapSidebarNavigationBarProps> = () => {
  const dispatch = useAppDispatch();
  const { mapService } = useContext(WorldMapContext);
  const visibleLocation = useSelector(selectVisibleLocation);
  const isNavigationBarOpen = useSelector(
    selectIsModalOpened(EPredefinedModalIds.MODAL_MAP_SIDEBAR_NAVIGATION_BAR),
  );
  const isEmbed = useSelector(selectIsEmbed);
  const breadcrumbs = createLocationBreadcrumbs(
    visibleLocation,
    mapService?.map.getZoom() as number,
  ).reverse();
  const modalMapHeroDismissed = useSelector(selectModalMapHeroDismissed);
  const tutorialStep = useSelector(selectTutorialStep);

  const onBreadcrumbSelect = (val: TBreadcrumbsItem) => {
    const location = visibleLocation.find((item) => item.text === val.name);

    if (location) {
      mapService?.flyTo(location);
    }
  };

  const toggleModal = useCallback(
    (value: boolean) => {
      if (value) {
        dispatch(openModal(EPredefinedModalIds.MODAL_MAP_SIDEBAR_NAVIGATION_BAR));
      } else {
        dispatch(closeModal(EPredefinedModalIds.MODAL_MAP_SIDEBAR_NAVIGATION_BAR));
        if (!modalMapHeroDismissed) {
          dispatch(setModalMapHeroDismissed(true));
        }
      }
    },
    [dispatch, modalMapHeroDismissed],
  );

  const controlButtons = [
    {
      icon: 'arrow-left' as TIconNames,
      iconSize: 12,
      onClick: () => toggleModal(!isNavigationBarOpen),
    },
  ];

  return (
    <BaseGenericModal
      className={classnames(s.container, { [s.containerEmbed]: isEmbed })}
      closedClassName={s.closed}
      size={EModalSize.L370}
      style={{ zIndex: tutorialStep === 2 ? 99999 : 101 }}
      modalId={EPredefinedModalIds.MODAL_MAP_SIDEBAR_NAVIGATION_BAR}
      initialOpen>
      {breadcrumbs.length > 0 && (
        <BaseBreadcrumbs items={breadcrumbs} onSelect={onBreadcrumbSelect} />
      )}
      <MapSidebarControls className={s.controls} buttons={controlButtons} />
    </BaseGenericModal>
  );
};
