export type TTimelineFiltersProps = {
  className?: string;
  style?: React.CSSProperties;
  filter: number[];
  setFilter: (val: number[]) => void;
  durationFilter: string;
  setDurationFilter: (val: string) => void;
};

export enum EDurationType {
  All = 'All',
  OneMonth = '1M',
  OneDay = '1D',
  OneWeek = '1W',
}
