import { TAllFieldNames, TFieldsUnionWithValue } from 'src/utils/assetsFields/assetsFields.types';

import { TUsedNames } from 'src/typings/configuration.types';
import { findField } from 'src/utils/fieldUtils';

/*
  Types
*/
export type TFieldName = string;
export type TValidationVerdict = boolean;
export type TFormData = {
  [fieldName in TFieldName]: unknown;
};
export type TValidationObject<TNewValue = unknown> = {
  r({ newValue }: { newValue: TNewValue }): TValidationVerdict;
  m: string;
};
export type TValidators<TFieldName> = {
  // @ts-ignore
  [key in TFieldName]?: TValidationObject[];
};
export type TValidationErrors = { [key: string]: string } | null;

/*
  Helpers
*/
export const isGeoTagSet = (location: unknown): TValidationVerdict =>
  Array.isArray(location) && location[0] != null && location[1] != null;

/*
  Shared validation rules 
*/
export const rules = {
  email: (name: TFieldName): TValidationObject => ({
    r: ({ newValue }) =>
      typeof newValue === 'string'
        ? !!newValue.match('[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}')
        : false,
    m: `${name} must be valid address.`,
  }),

  required: (name: TFieldName): TValidationObject => ({
    r: ({ newValue }) => !([null, '', undefined] as unknown[]).includes(newValue),
    m: `${name} is required.`,
  }),

  minLength: (name: TFieldName, minLength: number): TValidationObject => ({
    r: ({ newValue }) => typeof newValue === 'string' && newValue.length >= minLength,
    m: `${name} must have at least ${minLength} characters.`,
  }),

  maxLength: (name: TFieldName, maxLength: number): TValidationObject => ({
    r: ({ newValue }) => typeof newValue === 'string' && newValue.length <= maxLength,
    m: `${name} can have at most ${maxLength} characters.`,
  }),

  integer: (name: TFieldName): TValidationObject => ({
    r: ({ newValue }) => Number.isInteger(newValue),
    m: `${name} must be an integer.`,
  }),

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isGeoTagSet: (name: TFieldName): TValidationObject => ({
    r: ({ newValue }) => isGeoTagSet(newValue),
    m: `Location geo-tag must be set.`,
  }),

  range: (name: TFieldName, min: number, max: number): TValidationObject => ({
    r: ({ newValue }) => {
      let tempValue = 0;
      if (typeof newValue !== 'number' && typeof newValue !== 'string') return false;

      if (typeof newValue === 'number') {
        tempValue = newValue;
      }

      if (typeof newValue === 'string') {
        if (!newValue.match(/^-?\d+$/) && !newValue.match(/^\d+\.\d+$/)) return false;

        tempValue = parseFloat(newValue);
      }

      return tempValue >= min && tempValue <= max;
    },
    m: `${name} must be between ${min} and ${max}.`,
  }),

  float: (name: TFieldName): TValidationObject => ({
    r: ({ newValue }) => {
      if (typeof newValue === 'number') {
        return Number.isInteger(newValue) || newValue % 1 !== 0;
      }

      return false;
    },
    m: `${name} must be a number.`,
  }),

  floatString: (name: TFieldName): TValidationObject => ({
    r: ({ newValue }) => {
      if (typeof newValue === 'number') {
        return Number.isInteger(newValue) || newValue % 1 !== 0;
      }
      if (typeof newValue === 'string') {
        if (!newValue.match(/^-?\d+$/) && !newValue.match(/^\d+\.\d+$/)) return false;

        const tempValue = parseFloat(newValue);
        if (isNaN(tempValue)) return false;

        return Number.isInteger(tempValue) || tempValue % 1 !== 0;
      }

      return false;
    },
    m: `${name} must be a number.`,
  }),

  positiveNumber: (name: TFieldName): TValidationObject => ({
    r: ({ newValue }) => {
      if (typeof newValue === 'number') {
        return newValue > 0;
      }

      return false;
    },
    m: `${name} must be a positive number.`,
  }),

  noForwardSlash: (name: TFieldName): TValidationObject => ({
    r: ({ newValue }) => (typeof newValue === 'string' ? !newValue.match(/\//g) : false),
    m: `${name} may not include '/'`,
  }),

  isNameUnique: (usedNames: TUsedNames, currentName: string): TValidationObject => ({
    r: ({ newValue }: { newValue: string }) =>
      newValue === currentName || usedNames.indexOf(newValue) === -1,
    m: `This name is unavailable.`,
  }),

  passwordMatch: (passwordToCompare: string): TValidationObject => ({
    r: ({ newValue }) => newValue === passwordToCompare,
    m: `Passwords do not match.`,
  }),
  maxFileSize: (name: TFieldName, size: number): TValidationObject => ({
    r: ({ newValue }: { newValue: { size: number } }) => {
      if (typeof newValue === 'undefined') return true;
      return newValue && newValue?.size <= size * 1024 * 1024 ? true : false;
    },
    m: `File size should be less than or equal to ${size}MB.`,
  }),
};

/*
  Methods
*/
export function validateFields({
  validators,
  formData,
  fields,
}: {
  validators: TValidators<string>;
  formData?: TFormData;
  fields?: TFieldsUnionWithValue[];
}): { errors: TValidationErrors } {
  const output = { errors: null as TValidationErrors };
  const validatorsEntries = Object.entries(validators);

  for (let i = 0; i < validatorsEntries.length; i++) {
    const [fieldName, rules] = validatorsEntries[i];

    if (rules) {
      for (let j = 0; j < rules.length; j++) {
        const { r, m } = rules[j];
        let isValid = true;

        if (formData) {
          isValid = r({ newValue: formData[fieldName] });
        } else if (fields) {
          const field = findField(fields, fieldName as TAllFieldNames);
          isValid = field && !field.EXCLUDE ? r({ newValue: field.value }) : true;
        } else {
          console.error(
            'Either "formData" or "fields" are required arguments\n\nIn file: "src/utils/formValidation.ts"',
          );
        }

        if (!isValid) {
          if (!output.errors) {
            output.errors = {};
          }

          if (['dailyLoadProfile', 'powerProfile', 'smartMeterProfile'].includes(fieldName)) {
            output.errors[
              fieldName
            ] = `${m} Note: To get correct results, please make sure that all entries in the date column match the selected market time slots`;
            break;
          }
          output.errors[fieldName] = m;
          break;
        }
      }
    }
  }

  return output;
}

/*
  Comparing 2 given objects
*/
export const compareOrdinaryJSONObjects = (a: unknown, b: unknown): boolean => {
  return JSON.stringify(a) === JSON.stringify(b);
};
