import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { BaseIcon } from 'src/components/BaseIcon';
import { DropdownMenuFilterLabels } from 'src/components/MapSidebar/components/MapSidebarCommunitiesList/MapSidebarCommunitiesConstants';
import {
  EDropdownMenuFilter,
  MapSidebarCommunitiesHeaderProps,
} from 'src/components/MapSidebar/components/MapSidebarCommunitiesList/MapSidebarCommunitiesList.types';
import { MapSidebarDropdownMenu } from 'src/components/MapSidebar/components/MapSidebarDropdownMenu';
import { EModalSize } from 'src/constants/modals';
import { useModal } from 'src/hooks/useModal';
import { selectIsLoggedIn } from 'src/redux/auth/auth.selectors';
import { selectCommunitiesCount } from 'src/redux/communities/communities.selectors';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import { useAppDispatch } from 'src/redux/store';
import { formatter } from 'src/utils/formatter';
import s from './MapSidebarCommunitiesList.module.scss';

export const MapSidebarCommunitiesHeader: React.FC<MapSidebarCommunitiesHeaderProps> = ({
  activeDropdownFilter,
  setActiveDropdownFilter,
}) => {
  const communitiesCount = useSelector(selectCommunitiesCount);
  const dropdownButtonRef = useRef<HTMLButtonElement | null>(null);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const dispatch = useAppDispatch();
  const { id: sidebarDropdownModalId } = useModal();
  const isSidebarDropdownModalOpened = useSelector(selectIsModalOpened(sidebarDropdownModalId));

  const onButtonClick = () => {
    if (isSidebarDropdownModalOpened) {
      dispatch(closeModal(sidebarDropdownModalId));
    } else {
      dispatch(openModal(sidebarDropdownModalId));
    }
  };

  const pluralize = (word, count) => {
    return count > 1 ? `${word}s` : word
  }

  return (
    <>
      <div className={s.header}>
        <h2>{formatter.prefixWithZeros(communitiesCount)}_</h2>
        <h3>
          {!isLoggedIn
            ? pluralize(DropdownMenuFilterLabels[EDropdownMenuFilter.Simulations], communitiesCount)
            : pluralize(DropdownMenuFilterLabels[activeDropdownFilter], communitiesCount)}
          {isLoggedIn && (
            <span>
              <button type="button" ref={dropdownButtonRef} onClick={onButtonClick}>
                <BaseIcon icon="arrow" size={16} />
              </button>
            </span>
          )}
        </h3>
        <h3>worldwide</h3>
      </div>
      {isLoggedIn && (
        <MapSidebarDropdownMenu
          relativeElement={dropdownButtonRef}
          modalId={sidebarDropdownModalId}
          size={EModalSize.M235}
          activeDropdownFilter={activeDropdownFilter}
          setActiveDropdownFilter={setActiveDropdownFilter}
        />
      )}
    </>
  );
};
