export enum EChartTooltips {
  TOOLTIP_DAY_PROFILE = 'tooltip-day-profile',
  TOOLTIP_SELF_CONSUMED = 'tooltip-self-consumed',
  TOOLTIP_TOTAL_DEMANDED = 'tooltip-total-demanded',
  TOOLTIP_TOTAL_PRODUCED = 'tooltip-total-produced',
  TOOLTIP_PRICES = ' tooltip-prices',
  TOOLTIP_ENERGY_BILLS = 'tooltip-energy-bills',
  TOOLTIP_ENERGY_TRADE_PROFILE = 'tooltip-energy-trade-profile',
  TOOLTIP_TRADES = 'tooltip-trades',
  TOOLTIP_MARKET_PROFILE = 'tooltip-market-profile',
  DEVICE_PROFILE_Load = 'device-stats-load',
  DEVICE_PROFILE_PV = 'device-stats-pv',
  DEVICE_PROFILE_Storage = 'device-stats-storage',
  DEVICE_PROFILE_CommercialProducer = 'device-stats-commercial-producer',
  DEVICE_PROFILE_FiniteDieselGenerator = 'device-stats-finite-diesel-generator',
  DEVICE_PROFILE_MarketMaker = 'device-stats-market-maker',
  DEVICE_PROFILE_InfiniteBus = 'device-stats-infinite-bus',
  SAVINGS = 'savings',
}

export function getChartTooltip(chartType: EChartTooltips, currency?: string): string {
  switch (chartType) {
    case EChartTooltips.TOOLTIP_DAY_PROFILE:
      return `This market summary shows the net energy and volume in kWh for each 15 minute market slot. The average energy price and grid fees, if applied to this market are also shown in ${currency}/kWh.`;
    case EChartTooltips.TOOLTIP_ENERGY_BILLS:
      return `The bought, sold, and total energy bills are shown in ${currency} for each home as well as the bought, sold, and total energy traded in kWh. Each value is the total traded in this market for the length of the simulation.`;
    case EChartTooltips.TOOLTIP_ENERGY_TRADE_PROFILE:
      return `The trade profile shows the energy traded in kWh in this market per 15 minute market slot for the duration of the simulation. The profiles can be filtered to view individual homes and to view the net energy flow in the grid. The peak analysis is an advanced tool that compares the current energy peak to a baseline configured in the market settings.`;
    case EChartTooltips.TOOLTIP_PRICES:
      return `The price graph shows the min, max, and average price of energy in ${currency}/kWh for each 15 minute market slot in this market. The grid fee is also shown if applied.`;
    case EChartTooltips.TOOLTIP_SELF_CONSUMED:
      return `The self-consumed energy is the energy in kWh that the community or house consumed from it’s own production.`;
    case EChartTooltips.TOOLTIP_TOTAL_DEMANDED:
      return `The total demanded energy is the energy in kWh that the community or house wanted to consume.`;
    case EChartTooltips.TOOLTIP_TOTAL_PRODUCED:
      return `The total produced energy is the energy in kWh that the community or house produced by itself.`;
    case EChartTooltips.TOOLTIP_TRADES:
      return `The cumulative trades in kWh are shown in this chart between all of the homes and communities participating in this market. The trades can be filtered by clicking to view an individual home in the chart.`;
    case EChartTooltips.TOOLTIP_MARKET_PROFILE:
      return `PLACEHOLDER FOR TOOLTIP FOR MARKET PROFILE`;
    case EChartTooltips.DEVICE_PROFILE_Load:
      return 'Load profile, energy bought, and energy price';
    case EChartTooltips.DEVICE_PROFILE_PV:
      return 'PV profile, energy sold, and energy price';
    case EChartTooltips.DEVICE_PROFILE_Storage:
      return 'State of charge, energy traded, and energy price';
    case EChartTooltips.DEVICE_PROFILE_CommercialProducer:
      return 'Energy sold and energy price';
    case EChartTooltips.DEVICE_PROFILE_FiniteDieselGenerator:
      return 'Energy profile, energy sold, and energy price';
    case EChartTooltips.DEVICE_PROFILE_MarketMaker:
      return 'Energy sold, energy bought, and energy price';
    case EChartTooltips.DEVICE_PROFILE_InfiniteBus:
      return 'Energy sold, energy bought, and energy price';
    case EChartTooltips.SAVINGS:
      return 'Daily savings, losses or profits for this home compared to the Utility Bill';
  }
}
