import people1 from 'src/assets/images/Scm/People/people1.png';
import people10 from 'src/assets/images/Scm/People/people10.png';
import people11 from 'src/assets/images/Scm/People/people11.png';
import people2 from 'src/assets/images/Scm/People/people2.png';
import people3 from 'src/assets/images/Scm/People/people3.png';
import people4 from 'src/assets/images/Scm/People/people4.png';
import people5 from 'src/assets/images/Scm/People/people5.png';
import people6 from 'src/assets/images/Scm/People/people6.png';
import people7 from 'src/assets/images/Scm/People/people7.png';
import people8 from 'src/assets/images/Scm/People/people8.png';
import people9 from 'src/assets/images/Scm/People/people9.png';

export const data = {
  nodes: [
    {
      image: people7,
      id: 'Oleg Firer',
      level: '1',
      group: 'Manager',
    },
    {
      image: people1,
      group: 'people',
      id: 'Victor Wolf',
      level: '2',
    },
    {
      image: people2,
      group: 'people',
      id: 'Natalia Wolf',
      level: '2',
    },
    {
      image: '',
      group: 'business',
      id: 'social community manager',
      level: '3',
    },
    {
      image: '',
      group: 'business',
      id: 'Star Capital Fund',
      level: '3',
    },
    {
      image: '',
      group: 'business',
      id: 'Merchant Processing Services',
      level: '3',
    },
    {
      image: '',
      group: 'business',
      id: 'Net Element',
      level: '3',
    },
    {
      image: '',
      group: 'business',
      id: 'Unified Payments',
      level: '3',
    },
    {
      image: people3,
      group: 'people',
      id: 'Stanislav Pavlenko',
      level: '2',
    },
    {
      image: people4,
      group: 'people',
      id: 'Albert Takhalov',
      level: '2',
    },
    {
      image: people5,
      group: 'people',
      id: 'Michael Wachs',
      level: '2',
    },
    {
      image: people6,
      group: 'people',
      id: 'Felix Filenger',
      level: '2',
    },

    {
      image: people8,
      group: 'people',
      id: 'Shirin',
      level: '2',
    },
    {
      image: people9,
      group: 'people',
      id: 'Mustafa',
      level: '2',
    },
    {
      image: people10,
      group: 'people',
      id: 'Rafeal',
      level: '3',
    },
  ],
  links: [
    {
      source: 'Oleg Firer',
      target: 'social community manager',
    },
    {
      source: 'Oleg Firer',
      target: 'Star Capital Fund',
    },
    {
      source: 'Oleg Firer',
      target: 'Merchant Processing Services',
    },
    {
      source: 'Oleg Firer',
      target: 'Victor Wolf',
    },
    {
      source: 'Oleg Firer',
      target: 'Net Element',
    },
    {
      source: 'Oleg Firer',
      target: 'Felix Filenger',
    },
    {
      source: 'Net Element',
      target: 'Michael Wachs',
    },
    {
      source: 'Net Element',
      target: 'Unified Payments',
    },
    {
      source: 'Unified Payments',
      target: 'Albert Takhalov',
    },
    {
      source: 'social community manager',
      target: 'Natalia Wolf',
    },
    {
      source: 'Natalia Wolf',
      target: 'Victor Wolf',
    },
    {
      source: 'Merchant Processing Services',
      target: 'Stanislav Pavlenko',
    },
    {
      source: 'Star Capital Fund',
      target: 'Stanislav Pavlenko',
    },
    {
      source: 'Stanislav Pavlenko',
      target: 'Albert Takhalov',
    },
    {
      source: 'Stanislav Pavlenko',
      target: 'Mustafa',
    },
    {
      source: 'Star Capital Fund',
      target: 'Shirin',
    },
    {
      source: 'Unified Payments',
      target: 'Rafeal',
    },
  ],
};

export const engagementData = [
  {
    name: 'Jonas Vogel',
    image: people1,
    email: 'adelheid79@web.de',
    asset: 'home1',
  },
  {
    name: 'Adele Krüger',
    image: people2,
    email: 'Adele@mail.de',
    asset: 'home3',
  },
  {
    name: 'Denise Pellegrino',
    image: people3,
    email: 'ulongo@libero.it',
    asset: 'home1',
  },
  {
    name: 'Samuele Bianchi',
    image: people4,
    email: 'ogatti@guerra.org',
    asset: 'home4',
  },
];
export const engagementData2 = [
  {
    name: 'Zohara Lal',
    image: people5,
    email: 'Zohara@outlook.com',
    asset: 'home5',
  },
  {
    name: 'Anna Mary',
    image: people2,
    email: 'mary.anna@gmail.com',
    asset: 'home6',
  },
  {
    name: 'Hennes',
    image: people6,
    email: 'hannes@gmail.com',
    asset: 'home7',
  },
  {
    name: 'Maria Simmon',
    image: people7,
    email: 'Maria@outlook.com',
    asset: 'home8',
  },
];

export const listOfTradingEmails = [
  {
    name: 'Ernestine Schulze',
    image: people8,
    email: 'valerie.schumann@posteo.de',
  },
  {
    name: 'Liza Jones',
    image: people9,
    email: 'lisalisa@gmail.com',
  },
  {
    name: 'Laura Schmidt',
    image: people11,
    email: 'hreichel@graf.de',
  },
  {
    name: 'Pascal Möller',
    image: people7,
    email: 'wolfram.gabriel@t-online.de',
  },
];
