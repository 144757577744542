import { EAppMode, getAppMode } from 'src/utils/appMode';

import { MobileHeader } from 'src/components/MobileView/components/MobileHeader';
import { MobileWarning } from 'src/pages/MobileWarning';
import { MyCommunities as MyCommunitiesContent } from 'src/components/MyCommunities';
import React from 'react';
import { TMyCommunitiesProps } from './MyCommunities.types';
import s from './MyCommunities.module.scss';

export const MyCommunities: React.FC<TMyCommunitiesProps> = () => {
  const mode = getAppMode();

  if (mode === EAppMode.Mobile) return <MobileWarning />;

  return (
    <div className={s.container}>
      <MobileHeader headerTitle="My Communities" />
      <MyCommunitiesContent />
    </div>
  );
};
