import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  TCanaryRegistryAssetTileProps,
  TCanaryRegistryAssetsListProps,
  TCanaryRegistryListFilters,
  TCanaryRegistryListProps,
  TCanaryRegistryTabs,
  TCanaryRegistryUsersListProps,
} from 'src/components/MapSidebar/components/MapSidebarCanary/components/CanaryRegistryList';
import {
  selectActiveConfigurationUuid,
  selectAssetsTreeRelations,
} from 'src/redux/configuration/configuration.selectors';
import { selectUserRole, selectUsername } from 'src/redux/auth/auth.selectors';

import { AreaUuidApplyMapping } from 'src/graphql';
import { CanaryNetworkServiceContext } from 'src/components/CanaryNetworkServiceProvider/CanaryNetworkServiceProvider';
import { CanaryRegistryAssetsList } from 'src/components/MapSidebar/components/MapSidebarCanary/components/CanaryRegistryList/CanaryRegistryAssetsList';
import { CanaryRegistryListHeader } from 'src/components/MapSidebar/components/MapSidebarCanary/components/CanaryRegistryList/CanaryRegistryListHeader';
import { CanaryRegistryUsersList } from 'src/components/MapSidebar/components/MapSidebarCanary/components/CanaryRegistryList/CanaryRegistryUsersList';
import { EUserRoles } from 'src/typings/base-types';
import s from './CanaryRegistryList.module.scss';
import { useSelector } from 'react-redux';

export const CanaryRegistryList: React.FC<TCanaryRegistryListProps> = ({
  assetsData,
  usersData,
  usersToAssetRelations,
}) => {
  const canaryNetworkServiceContext = useContext(CanaryNetworkServiceContext);
  const configUuid = useSelector(selectActiveConfigurationUuid);
  const userName = useSelector(selectUsername);
  const userRole = useSelector(selectUserRole);
  const assetsTree = useSelector(selectAssetsTreeRelations);

  const [activeTab, setActiveTab] = useState<TCanaryRegistryTabs>('Users');
  const [activeFilter, setActiveFilter] = useState<TCanaryRegistryListFilters>('All');
  const [searchValue, setSearchValue] = useState('');
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [isSelectAllTriggered, setIsSelectAllTriggered] = useState<boolean>(false);

  const filteredAssetsData = useMemo(() => {
    const aggregators = usersData.reduce((acc: string[], user) => {
      if (user.isAggregator) {
        acc.push(user.name);
      }

      return acc;
    }, []);
    const aggregatorsToAssetRelations = Object.entries(usersToAssetRelations).reduce(
      (acc: typeof usersToAssetRelations, [user, assets]) => {
        if (aggregators.includes(user)) {
          acc[user] = assets;
        }
        return acc;
      },
      {},
    );

    switch (activeFilter) {
      case 'Unregistered': {
        const assetsToOmit: string[] = [];

        Object.values(aggregatorsToAssetRelations).forEach((item) => {
          Object.entries(item).forEach(([assetUuid]) => {
            assetsToOmit.push(assetUuid);
          });
        });

        return assetsData.filter(({ uuid }) => !assetsToOmit.includes(uuid));
      }
      case 'Pending': {
        const assetsToInclude: string[] = [];

        Object.values(aggregatorsToAssetRelations).forEach((item) => {
          Object.entries(item).forEach(([assetUuid, status]) => {
            if (status === 'Applied') {
              assetsToInclude.push(assetUuid);
            }
          });
        });

        return assetsData.filter(({ uuid }) => assetsToInclude.includes(uuid));
      }
      case 'Registered': {
        const assetsToInclude: string[] = [];

        Object.values(aggregatorsToAssetRelations).forEach((item) => {
          Object.entries(item).forEach(([assetUuid, status]) => {
            if (status === 'Approved') {
              assetsToInclude.push(assetUuid);
            }
          });
        });

        return assetsData.filter(({ uuid }) => assetsToInclude.includes(uuid));
      }

      default:
        return assetsData;
    }
  }, [activeFilter, assetsData, usersData, usersToAssetRelations]);

  const showApplyButton = useMemo(() => {
    const availableAreSelected = selectedItems.filter((uuid) => {
      // Check if user already applied or approved for selected asset
      if (usersToAssetRelations[userName] && usersToAssetRelations[userName][uuid]) {
        return !['Applied', 'Approved'].includes(usersToAssetRelations[userName][uuid]);
      }
      return true;
    });
    return (
      activeTab === 'Community Assets' &&
      userRole === EUserRoles.Aggregator &&
      availableAreSelected.length > 0
    );
  }, [activeTab, selectedItems, userName, userRole, usersToAssetRelations]);

  const toggleTileSelection = (value: string) => {
    if (selectedItems.includes(value)) {
      setSelectedItems([...selectedItems].filter((name) => name !== value));
    } else {
      setSelectedItems([...selectedItems, value]);
    }
  };

  const selectAllItems = () => {
    if (activeTab === 'Users') {
      if (!isSelectAllTriggered) {
        setSelectedItems(usersData.map(({ name }) => name));
        setIsSelectAllTriggered(true);
      } else {
        setSelectedItems([]);
        setIsSelectAllTriggered(false);
      }
    } else {
      if (!isSelectAllTriggered) {
        setSelectedItems(filteredAssetsData.map(({ uuid }) => uuid));
        setIsSelectAllTriggered(true);
      } else {
        setSelectedItems([]);
        setIsSelectAllTriggered(false);
      }
    }
  };

  const onUserItemClick: TCanaryRegistryUsersListProps['onItemClick'] = (data) => {
    toggleTileSelection(data.name);
  };

  const onAssetItemClick: TCanaryRegistryAssetsListProps['onItemClick'] = (data) => {
    toggleTileSelection(data.uuid);
  };

  const getParentUuid = useCallback(
    (assetUuid: string) => {
      return Object.keys(assetsTree).find((key) => assetsTree[key].includes(assetUuid));
    },
    [assetsTree],
  );

  const getOwners = useCallback(
    (assetUuid: string) =>
      Object.keys(usersToAssetRelations).reduce<TCanaryRegistryAssetTileProps['data']['owners']>(
        (acc, key) => {
          if (usersToAssetRelations[key][assetUuid] === 'Approved') {
            const owner = usersData.find((item) => item.name === key);
            if (owner) {
              acc.push({
                avatarUrl: owner.avatarUrl,
                id: owner.uuid,
                name: owner.name,
                isAggregator: owner.isAggregator,
                isGridOperator: owner.isGridOperator,
              });
            }
          }
          return acc;
        },
        [],
      ),
    [usersData, usersToAssetRelations],
  );

  const getAppliedUser = useCallback(
    (assetUuid: string) => {
      for (const userName in usersToAssetRelations) {
        if (usersToAssetRelations[userName][assetUuid] === 'Applied') {
          return {
            userName,
            avatar: usersData.find((item) => item.name === userName)?.avatarUrl,
          };
        }
      }
    },
    [usersData, usersToAssetRelations],
  );

  useEffect(() => {
    setSelectedItems([]);
  }, [activeTab]);

  return (
    <div className={s.container}>
      <CanaryRegistryListHeader
        activeTab={activeTab}
        setActiveTab={(val) => {
          setActiveTab(val);
          setIsSelectAllTriggered(false);
        }}
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        selectAllItems={selectAllItems}
        isSelectAllTriggered={isSelectAllTriggered}
        showApplyButton={showApplyButton}
        assetsCount={filteredAssetsData.length}
        onClickApplyButton={() => {
          const applicationMapping: AreaUuidApplyMapping[] = selectedItems
            .filter((uuid) => {
              if (usersToAssetRelations[userName] && usersToAssetRelations[userName][uuid]) {
                return !['Applied', 'Approved'].includes(usersToAssetRelations[userName][uuid]);
              }
              return true;
            })
            .map((uuid) => ({
              areaUuid: uuid,
              apply: true,
            }));

          if (configUuid)
            canaryNetworkServiceContext.applyForExternalConnection(applicationMapping, configUuid);
        }}
      />
      <div>
        {activeTab === 'Users' && (
          <CanaryRegistryUsersList
            assetsData={filteredAssetsData}
            usersData={usersData}
            usersToAssetRelations={usersToAssetRelations}
            selectedItems={selectedItems}
            searchValue={searchValue}
            onItemClick={onUserItemClick}
            getParentUuid={getParentUuid}
            getOwners={getOwners}
            getAppliedUser={getAppliedUser}
          />
        )}
        {activeTab === 'Community Assets' && (
          <CanaryRegistryAssetsList
            assetsData={filteredAssetsData}
            usersData={usersData}
            usersToAssetRelations={usersToAssetRelations}
            selectedItems={selectedItems}
            searchValue={searchValue}
            onItemClick={onAssetItemClick}
            getParentUuid={getParentUuid}
            getOwners={getOwners}
            getAppliedUser={getAppliedUser}
          />
        )}
      </div>
    </div>
  );
};
