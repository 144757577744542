import React, { useCallback, useEffect, useState } from 'react';
import { backRoutePath, menuItems } from 'src/templates/TemplateLandingPage/routes.config';
import { useHistory, useParams } from 'react-router-dom';

import { BaseLink } from 'src/components/BaseLink';
import { EParams } from 'src/templates/TemplateLandingPage/TemplateLandingPage.types';
import Fade from 'react-reveal/Fade';
import { TMainMenuProps } from 'src/components/LandingPage/MainMenu/MainMenu.types';
import approachImage from 'src/assets/images/LandingPage/bg-our-approach.png';
import classnames from 'classnames';
import { routesConfig } from 'src/routes/routes.config';
import s from './MainMenu.module.scss';

export const MainMenu: React.FC<TMainMenuProps> = ({ items, cbRoute, menuType }) => {
  const initialRoute = useParams<{
    page: string;
  }>()?.page;
  const [routeName, setRouteName] = useState<string | null>(initialRoute);
  const history = useHistory();
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (routeName) {
      cbRoute(routeName);
    }
  }, [routeName, cbRoute]);

  const redirectRoute = useCallback(
    (ref: string) => {
      const redirect = items?.find((e) => e.route === ref)?.route || null;
      if (redirect) {
        history.replace(backRoutePath(redirect)?.[menuType]);
      }
    },
    [history, items, menuType],
  );

  useEffect(() => {
    if (initialRoute && initialRoute.length > 0) {
      setRouteName(initialRoute);
    } else {
      const startWithRoute =
        menuItems?.[menuType]?.find((e) => e.initial) || menuItems?.[menuType]?.[0];
      setRouteName(startWithRoute?.route);
      redirectRoute(startWithRoute?.route);
    }
  }, [initialRoute, setRouteName, redirectRoute, menuType]);

  return (
    <div id="menuLandingPage" className={s.content}>
      <div className={s.menuBox}>
        {items?.map((e, key) => (
          <span
            key={key.toString()}
            className={e.route === routeName ? s.itemMenuActive : s.itemMenu}
            onClick={() => redirectRoute(e.route)}>
            {e.title}
          </span>
        ))}
      </div>

      <Fade opposite when={routeName === EParams.OUR_APPROACH}>
        <img src={approachImage} />
      </Fade>

      <div className={s.copyrightContainer}>
        <div className={s.copyrightText}>Copyright © {currentYear} Grid Singularity Pte Ltd.</div>
        <BaseLink
          to={routesConfig.privacyPolicy}
          className={classnames(s.privacyPolicyLink, 'click-area')}
          theme="3">
          Privacy Policy
        </BaseLink>
        <BaseLink
          to={routesConfig.termsOfService}
          className={classnames(s.termsOfService, 'click-area')}
          theme="3">
          Terms of Service
        </BaseLink>
      </div>
    </div>
  );
};
