import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import React, { useRef } from 'react';

import { BaseGenericModal } from 'src/components/BaseGenericModal';
import { BaseIcon } from 'src/components/BaseIcon';
import s from './ModalMoveToCollabs.module.scss';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { useSelector } from 'react-redux';

export const ModalMoveToCollabs: React.FC = () => {
  const isModalDeployOpened = useSelector(
    selectIsModalOpened(EPredefinedModalIds.MODAL_MOVETOCOLLABS),
  );

  const modalRef = useRef<HTMLDivElement>(null);

  if (!isModalDeployOpened) return null;

  return (
    <>
      <div className={s.background} />
      <BaseGenericModal
        size={EModalSize.XL500}
        modalId={EPredefinedModalIds.MODAL_MOVETOCOLLABS}
        className={s.modal}
        modalRef={modalRef}>
        <div className={s.modalContentWrapper}>
          <BaseIcon icon="move-to-collaboration" size={40} />
          <h3>Setting up your collaboration</h3>
          <p>
            Once you start your collaboration you will need to set it up and invite the participants
          </p>
        </div>
      </BaseGenericModal>
    </>
  );
};
