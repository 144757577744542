import { TModalContent, TModalsState } from 'src/redux/modals/modals.slice';

import { ArrayElement } from 'src/typings/base-types';
import { RootState } from 'src/redux/store';
import { TUseModalData } from 'src/hooks/useModal';

export const selectActiveModals = (state: RootState): TModalsState['activeModals'] =>
  state.modals.activeModals;

export const selectIsModalOpened = (modalId: TUseModalData['id']) => (state: RootState): boolean =>
  state.modals.activeModals.includes(modalId);

export const selectModalContent = () => (state: RootState): TModalContent =>
  state.modals.modalContent;

export const selectPositionBGM = (state: RootState): TModalsState['positionBGM'] =>
  state.modals.positionBGM;

export const selectPositionBGMById = (modalId: TUseModalData['id']) => (
  state: RootState,
): ArrayElement<TModalsState['positionBGM']> | undefined =>
  state.modals.positionBGM.find((item) => item.modalId === modalId);
