import { AssetsInside, TAssetInside } from 'src/components/AssetsInside';
import { ContextMenu, TContextMenuProps } from 'src/components/ContextMenu';
import {
  ECommunityControlLabels,
  TCommunityAssetsControl,
  TCommunityAssetsProps,
} from './CommunityAssets.types';
import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import React, { useContext, useEffect, useState } from 'react';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import {
  selectCommunityAsset,
  selectGridMarketAsset,
  selectReadOnly,
} from 'src/redux/configuration/configuration.selectors';

import { ApplicationContext } from 'src/contexts/ApplicationContext';
import { BaseGenericModal } from 'src/components/BaseGenericModal';
import { BaseIcon } from 'src/components/BaseIcon';
import { InViewPositioner } from 'src/components/InViewPositioner';
import { LIBRARY_FILTERS_MAPPING } from 'src/constants/application';
import { TAsset } from 'src/typings/configuration.types';
import { TAssetsListWithSearchProps } from 'src/components/AssetsListWithSearch';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';
import classNames from 'classnames';
import s from './CommunityAssets.module.scss';
import { selectIsEmbed } from 'src/redux/application/application.selectors';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { setSelectedAssetUuid } from 'src/redux/configuration/configuration.slice';
import { useAppDispatch } from 'src/redux/store';
import { useConfigurationFlowState } from 'src/hooks/useConfigurationFlowState';
import { useMergedState } from 'src/hooks/useMergedState';
import { useModal } from 'src/hooks/useModal';
import { useSelector } from 'react-redux';

export const CommunityAssets: React.FC<TCommunityAssetsProps> = React.memo(
  function CommunityAssets({ onAssetRemove }: TCommunityAssetsProps) {
    const dispatch = useAppDispatch();
    const { modalAddMoreShow } = useConfigurationFlowState();
    // redux slices
    const gridMarketAsset = useSelector(selectGridMarketAsset);
    const communityAsset = useSelector(selectCommunityAsset);
    const isSummaryOfCommunity = communityAsset?.uuid === gridMarketAsset?.uuid;
    const isEmbed = useSelector(selectIsEmbed);
    const readOnly = useSelector(selectReadOnly);
    const { triggerResultsLossAlert } = useContext(ApplicationContext);

    // internal states
    const [activeFilter, setActiveFilter] = useState<TAssetsListWithSearchProps['activeFilter']>(
      !isSummaryOfCommunity ? LIBRARY_FILTERS_MAPPING.PV : LIBRARY_FILTERS_MAPPING.Area,
    );
    const [expanded, setExpanded] = useState<boolean>(false);
    const { id: contextMenuModalId } = useModal();
    const isContextMenuOpened = useSelector(selectIsModalOpened(contextMenuModalId));
    const [contextMenuState, setContextMenuState] = useMergedState<{
      modalRef: React.RefObject<HTMLElement | null>;
      assetUuid: TAsset['uuid'] | undefined;
    }>({ modalRef: { current: null }, assetUuid: '' });
    useEffect(() => {
      if (modalAddMoreShow) {
        dispatch(openModal(EPredefinedModalIds.MODAL_COMMUNITY_ASSETS));
      } else {
        dispatch(closeModal(EPredefinedModalIds.MODAL_COMMUNITY_ASSETS));
      }
    }, [dispatch, communityAsset, modalAddMoreShow]);

    const closeContextMenu = () => {
      dispatch(closeModal(contextMenuModalId));
    };

    function startAssetEdition(assetUuid: TAsset['uuid']) {
      dispatch(setSelectedAssetUuid(assetUuid));
      closeContextMenu();
    }

    // handlers
    const handleControlClicked = (control: TCommunityAssetsControl) => {
      switch (control.label) {
        case ECommunityControlLabels.ToggleExpand:
          return setExpanded(!expanded);
        case ECommunityControlLabels.Close:
          return dispatch(closeModal(EPredefinedModalIds.MODAL_COMMUNITY_ASSETS));
      }
    };

    const renderControls = () => {
      const iconForControl = (label: ECommunityControlLabels): TIconNames => {
        switch (label) {
          case ECommunityControlLabels.ToggleExpand:
            return expanded ? 'minus-thick' : 'expand';
          case ECommunityControlLabels.Close:
            return 'close';
        }
      };

      const communityControls: TCommunityAssetsControl[] = [
        {
          label: ECommunityControlLabels.ToggleExpand,
          icon: iconForControl(ECommunityControlLabels.ToggleExpand),
        },
        {
          label: ECommunityControlLabels.Close,
          icon: iconForControl(ECommunityControlLabels.Close),
        },
      ];

      return (
        <div className={s.viewActionsWrapper}>
          {communityControls.map((control) => (
            <button type="button" key={control.label} onClick={() => handleControlClicked(control)}>
              <BaseIcon key={control.label} icon={control.icon} size={10} />
            </button>
          ))}
        </div>
      );
    };

    const contextMenuItems: TContextMenuProps['items'] = [
      {
        title: !readOnly ? 'Edit' : 'View settings',
        icon: !readOnly ? 'pencil-create' : 'settings',
        onClick: () => {
          if (contextMenuState.assetUuid) {
            startAssetEdition(contextMenuState.assetUuid);
          }
        },
      },
    ];
    if (!readOnly && contextMenuState.assetUuid !== communityAsset?.uuid) {
      contextMenuItems.push({
        title: 'Delete',
        icon: 'custom-trash',
        onClick: async () => {
          if (!contextMenuState.assetUuid) return;
          closeContextMenu();
          await triggerResultsLossAlert();
          onAssetRemove?.({ assetUuid: contextMenuState.assetUuid });
        },
      });
    }

    return communityAsset ? (
      <InViewPositioner recalculateKey="community-assets-modal">
        <BaseGenericModal
          className={s.modal}
          inline
          size={EModalSize.M255}
          modalId={EPredefinedModalIds.MODAL_COMMUNITY_ASSETS}>
          <div className={s.container}>
            <header className={classNames(s.communityAssetsHeader, s.paddingLR)}>
              <h3>Community Assets</h3>
              {renderControls()}
            </header>
            {expanded && (
              <AssetsInside
                showAssetsCount
                showEnergyStatus
                theme="light"
                paddingClassName={s.paddingLR}
                parentUuid={communityAsset?.uuid}
                onThreeDotsClick={({ target, asset }) => {
                  if (isContextMenuOpened) {
                    closeContextMenu();
                  } else {
                    setContextMenuState({
                      modalRef: { current: target as HTMLElement },
                      assetUuid: (asset as TAssetInside).uuid,
                    });
                    dispatch(openModal(contextMenuModalId));
                  }
                }}
                onAssetChoose={(data) => {
                  dispatch(setSelectedAssetUuid(undefined)); // first unset before setting it;
                  if (data) {
                    dispatch(setSelectedAssetUuid(data.uuid));
                  }
                }}
                onFilterChange={(v) => setActiveFilter(v.value)}
                activeFilter={activeFilter}
              />
            )}
            {!isEmbed && (
              <ContextMenu
                relativeElement={contextMenuState.modalRef}
                modalId={contextMenuModalId}
                position={{
                  side: 'bottom',
                  anchorPosition: 90,
                }}
                items={contextMenuItems}
              />
            )}
          </div>
        </BaseGenericModal>
      </InViewPositioner>
    ) : (
      <></>
    );
  },
);
