import { BLOCKS, INLINES } from '@contentful/rich-text-types';

import { BaseButton } from 'src/components/BaseButton';
import React from 'react';
import { TProps } from 'src/components/LandingPage/NewsDetails';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import s from './NewsDetails.module.scss';

export const NewsDetails: React.FC<TProps> = ({ back, data }) => {
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_, children) => <p>{children}</p>,
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        const referencedEntry = getEntryWithId(node.data.target.sys.id);
        return (
          <a href={`/pages/${referencedEntry.fields.slug}`} rel="noreferrer" target="_blank">
            {children}
          </a>
        );
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a href={`${node.data.uri}`} rel="noreferrer" target="_blank">
            {children}
          </a>
        );
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        // render the EMBEDDED_ASSET as you need
        return (
          <img
            src={`https://${node.data.target.fields.file.url}`}
            height={node.data.target.fields.file.details.image.height}
            width={node.data.target.fields.file.details.image.width}
            alt={node.data.target.fields.description}
          />
        );
      },
    },
  };

  function getEntryWithId(entryId) {
    const mockEntry = {
      fields: {
        slug: entryId,
      },
    };

    return mockEntry;
  }

  return (
    <div className={s.content}>
      <BaseButton
        className={s.buttonBack}
        theme="quaternary"
        icon="arrow-left-full"
        onClick={() => back()}>
        BACK
      </BaseButton>
      <div className={s.headerNews}>
        <h1>{data.title}</h1>
      </div>
      <div className={s.textNews}>
        {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          documentToReactComponents(data?.description as any, options)
        }
      </div>
    </div>
  );
};
