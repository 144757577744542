import React, { useMemo, useState } from 'react';
import { backRoutePath, menuItems } from 'src/templates/TemplateLandingPage/routes.config';

import { BaseIcon } from 'src/components/BaseIcon/BaseIcon';
import { ContentfulContextProvider } from 'src/contexts/ContentfulContext';
import { EMenuType } from 'src/components/LandingPage/MainMenu/MainMenu.types';
import { Header } from 'src/components/Header';
import { MainMenu } from 'src/components/LandingPage/MainMenu/MainMenu';
import { NavLink } from 'react-router-dom';
import { TTemplateLandingPageProps } from './TemplateLandingPage.types';
import classnames from 'classnames';
import s from './TemplateLandingPage.module.scss';

export const TemplateLandingPage: React.FC<TTemplateLandingPageProps> = ({
  className,
  headerColorMode,
  children,
  rightBgColor,
  menuType = EMenuType.DEFAULT,
}) => {
  const [currentRoute, setCurrentRoute] = useState<string | null>(null);
  const currentMenuByType = useMemo(() => menuItems?.[menuType], [menuType]);
  const currentMenu = useMemo(() => currentMenuByType.filter((x) => !x.hidden), [
    currentMenuByType,
  ]);

  function nextRoute(route: string | null) {
    const indexCurrentMenu = currentMenu?.findIndex((e) => e.route === route);

    if (indexCurrentMenu === currentMenu?.length - 1) {
      return {
        backTitle: currentMenu?.[0]?.title,
        backRoute: currentMenu?.[0]?.route,
      };
    } else {
      return {
        backTitle: currentMenu?.[indexCurrentMenu + 1]?.title,
        backRoute: currentMenu?.[indexCurrentMenu + 1]?.route,
      };
    }
  }

  const { backTitle, backRoute } = nextRoute(currentRoute);

  return (
    <div className={classnames(s.container, className)}>
      <Header isLandingPage headerColorMode={headerColorMode} />
      <div className={s.containerWrapper}>
        <div className={s.left}>
          <div className={s.bgLeftHeader}></div>
          <MainMenu items={currentMenu} menuType={menuType} cbRoute={setCurrentRoute} />
        </div>
        <div
          id="right"
          className={s.right}
          style={rightBgColor ? { backgroundColor: rightBgColor } : {}}>
          <div
            className={`${s.bgRightHeader} ${
              menuType === EMenuType.RESOURCES ? s.bgDark : ''
            }`}></div>
          <ContentfulContextProvider>{children}</ContentfulContextProvider>
          <NavLink exact to={backRoutePath(backRoute)[menuType]}>
            <div className={s.backAction}>
              <span>{backTitle}</span>
              <BaseIcon icon="arrow-right-full" size={24} />
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};
