import { EcoSystem } from '../../../../components/LandingPage/MarketDesign/EcoSystem';
import { Fade } from 'react-reveal';
import React from 'react';
import { TProps } from './EcoSystem.types';
import classnames from 'classnames';
import s from './EcoSystem.module.scss';

export const EcoSystemMain: React.FC<TProps> = ({
  show,
  isEcoSystemChildView,
  setIsEcoSystemChildView,
}) => {
  return (
    <Fade opposite collapse={!show} when={show} delay={1000}>
      <div className={s.ecosystemWrapper}>
        <div
          className={classnames(s.ecosystemContainer, {
            [s.ecosystemContainerMobile]: isEcoSystemChildView,
          })}>
          <EcoSystem
            isEcoSystemChildView={isEcoSystemChildView}
            setIsEcoSystemChildView={setIsEcoSystemChildView}
          />
        </div>
      </div>
    </Fade>
  );
};
