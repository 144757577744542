import { EParams, TMenu } from 'src/templates/TemplateLandingPage/TemplateLandingPage.types';

import { EMenuType } from 'src/components/LandingPage/MainMenu/MainMenu.types';

export const menuItems: TMenu = {
  default: [
    {
      route: EParams.OUR_APPROACH,
      title: 'Our Approach',
      initial: true,
    },
    {
      route: EParams.OUR_PRINCIPLES,
      title: 'Our Principles',
    },
    {
      route: EParams.OUR_TEAM,
      title: 'Our Team',
    },
    {
      route: EParams.JOIN_US,
      title: 'Join Us',
    },
    {
      route: EParams.GEP,
      title: 'Gender Equality Plan',
      hidden: true,
    },
    {
      route: EParams.BIOS,
      title: 'BIOS',
      hidden: true,
    },
  ],
  resources: [
    {
      route: EParams.TRENDING,
      title: 'Trending',
      initial: true,
    },
    // {
    //   route: EParams.NEWS,
    //   title: 'News',
    // },
    {
      route: EParams.READ,
      title: 'Read',
    },
    {
      route: EParams.WATCH,
      title: 'Watch',
    },
    {
      route: EParams.TALK_TO_US,
      title: 'Talk to us',
    },
  ],
};

type TBackRoutePathReturn = {
  default: string;
  resources: string;
};

export const backRoutePath = (page: string): TBackRoutePathReturn => {
  const routesBack = {
    [EMenuType.DEFAULT]: `/company/${page}`,
    [EMenuType.RESOURCES]: `/resources/${page}`,
  };

  return routesBack;
};
