import React from 'react';
import { THoverBgCardPageProps } from './HoverBgCard.types';
import classnames from 'classnames';
import s from './HoverBgCard.module.scss';

export const HoverBgCard: React.FC<THoverBgCardPageProps> = ({ className, data, isClickAble }) => {
  const { title, description, introImage } = data;

  return (
    <>
      <div
        className={classnames(s.content, className, {
          [s.hoverCard]: isClickAble,
        })}>
        <div className={s.title}>{title}</div>
        <div className={s.descContainer}>
          <div className={s.image}>{introImage && <img src={introImage} />}</div>
          <div className={s.description}>{description}</div>
        </div>
        <div className={classnames(s.learnMoreLink, 'click-area')}>Learn More</div>
      </div>
    </>
  );
};
