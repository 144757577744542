import {
  TAssetFieldTemplatesArgs,
  TFieldsTemplateUnion,
} from 'src/utils/assetsFields/assetsFields.types';

import { EFormVariant } from 'src/typings/base-types';
import { SWITCHER_ICON_SIZES } from 'src/constants/application';

export type TAreaPhoenixArgs = {
  isLibrary: boolean;
  values: TAssetFieldTemplatesArgs['values'];
};

export const AreaPhoenix = ({ isLibrary, values }: TAreaPhoenixArgs): TFieldsTemplateUnion[] => {
  return [
    {
      name: 'libraryUUID',
      formView: EFormVariant.Express,
    },
    // ...evaluatedMarketKpisFields,
    {
      name: 'name',
      type: 'text',
      label: 'Name',
      formView: EFormVariant.Express,
    },
    // TODO: REFACTOR IN PROGRESS, fitAreaBoundary must be added at transformToAreaInput step
    {
      name: 'geoTagLocation',
      type: 'location',
      label: 'Location',
      tooltipText: 'Parent market already has location',
      EXCLUDE: isLibrary,
      formView: EFormVariant.Express,
    },
    // TODO: EXCLUDE coefficientPercentage from all the forms
    {
      name: 'coefficientPercentage',
      type: 'number',
      label: 'Coefficient Percentage',
      EXCLUDE: true,
      formView: EFormVariant.Advanced,
    },
    // TODO:  It will not be implemented in v1 of Phoenix but we will need it later.
    // TODO: https://gridsingularity.atlassian.net/jira/software/projects/PH/boards/41?label=Airnauts&selectedIssue=PH-124
    // {
    //   name: 'count',
    //   type: 'counter',
    //   label: 'Multiply same unit',
    //   min: 1,
    //   formView: EFormVariant.Express,
    // },
    /*
    TODO: Do we need geoTagType in phoenix?
    If yes, fix the issue: Area -> advanced -> change geo-tag-type to location -> switch between express and advanced. 
  */
    // {
    //   name: 'geoTagType',
    //   type: 'enum',
    //   label: 'Geo-tag Type',
    //   options: [
    //     { value: 'area', label: 'Area' },
    //     { value: 'location', label: 'Location' },
    //   ],
    //   tooltipText: 'Parent market already has location',
    //   EXCLUDE: isLibrary,
    //   formView: EFormVariant.Advanced,
    // },
    {
      name: 'exchangeInformation',
      type: 'enum',
      label: 'Exchange Information',
      options: [{ value: 'spot', label: 'Spot' }],
      disabled: true,
      EXCLUDE: isLibrary,
      formView: EFormVariant.Advanced,
      tooltipText: 'Specify the market type of this exchange. Only Spot is available at the moment',
      showTooltip: true,
    },
    {
      name: 'gridFeeEnabled',
      type: 'switcher',
      options: [
        { icon: 'close', iconSize: SWITCHER_ICON_SIZES.close, value: false },
        { icon: 'check-mark', iconSize: SWITCHER_ICON_SIZES.tick, value: true },
      ],
      label: 'Grid Fees',
      EXCLUDE: isLibrary,
      formView: EFormVariant.Advanced,
      tooltipText: 'Specify the grid fees charged to trade in this market',
      showTooltip: true,
    },
    {
      name: 'gridFeeConstant',
      type: 'number',
      label: 'Grid Fee Value',
      unit: 'cents / kWh',
      EXCLUDE: isLibrary || !values?.gridFeeEnabled,
      formView: EFormVariant.Advanced,
    },
    {
      name: 'transformerCapacityEnabled',
      type: 'switcher',
      options: [
        { icon: 'close', iconSize: SWITCHER_ICON_SIZES.close, value: false },
        { icon: 'check-mark', iconSize: SWITCHER_ICON_SIZES.tick, value: true },
      ],
      label: 'Transformer Capacity',
      formView: EFormVariant.Advanced,
      tooltipText: 'Set the import and export capacities for this transformer',
      showTooltip: true,
    },
    {
      name: 'importCapacityKva',
      type: 'number',
      label: 'Import',
      unit: 'kVA',
      inlineAlign: 'left',
      EXCLUDE: !values?.transformerCapacityEnabled,
      formView: EFormVariant.Advanced,
    },
    {
      name: 'exportCapacityKva',
      type: 'number',
      label: 'Export',
      unit: 'kVA',
      inlineAlign: 'right',
      EXCLUDE: !values?.transformerCapacityEnabled,
      formView: EFormVariant.Advanced,
    },
    {
      name: 'baselinePeakEnergyEnabled',
      type: 'switcher',
      options: [
        { icon: 'close', iconSize: SWITCHER_ICON_SIZES.close, value: false },
        { icon: 'check-mark', iconSize: SWITCHER_ICON_SIZES.tick, value: true },
      ],
      label: 'Energy Peak Percentage',
      EXCLUDE: isLibrary,
      formView: EFormVariant.Advanced,
      tooltipText:
        'Specify baseline import and export values to calculate the percent change in energy peak in this simulation compared to the baseline',
      showTooltip: true,
    },
    {
      name: 'baselinePeakEnergyImportKwh',
      type: 'number',
      label: 'Baseline Peak Import',
      unit: 'kWh',
      inlineAlign: 'left',
      EXCLUDE: isLibrary || !values?.baselinePeakEnergyEnabled,
      formView: EFormVariant.Advanced,
    },
    {
      name: 'baselinePeakEnergyExportKwh',
      type: 'number',
      label: 'Baseline Peak Export',
      unit: 'kWh',
      inlineAlign: 'right',
      EXCLUDE: isLibrary || !values?.baselinePeakEnergyEnabled,
      formView: EFormVariant.Advanced,
    },
    {
      name: 'fixedMonthlyFee',
      type: 'number',
      label: 'Fixed Charges',
      unit: '€ / month',
      formView: EFormVariant.GridMarketEnergy,
    },
    {
      name: 'marketplaceMonthlyFee',
      type: 'number',
      label: 'Participation Fee',
      unit: '€ / month',
      formView: EFormVariant.GridMarketEnergy,
    },
    {
      name: 'taxesSurcharges',
      type: 'number',
      label: 'Taxes and Surcharges',
      unit: '€ / kWh',
      formView: EFormVariant.GridMarketEnergy,
    },
  ];
};
