import React, { useMemo } from 'react';

import { BaseButtonRound } from '../BaseButtonRound';
import { BaseErrorMessage } from 'src/components/BaseErrorMessage';
import { TBaseSliderProps } from './BaseSlider.types';
import classnames from 'classnames';
import s from './BaseSlider.module.scss';

export const BaseSlider: React.FC<TBaseSliderProps> = function BaseSlider({
  className,
  name,
  min,
  max,
  step,
  value,
  onChange,
  showButtons = true,
  error,
  label,
  disabled,
  theme = 'green',
}) {
  const firstValue = Array.isArray(value) ? value[0] : value;
  const secondValue = Array.isArray(value) ? value[1] : undefined;

  const { leftPosition, rightPosition } = useMemo(() => {
    let leftPosition;
    let rightPosition;

    if (typeof firstValue !== 'undefined') {
      leftPosition = `${((firstValue - min) / (max - min)) * 100}%`;
    }

    if (typeof secondValue !== 'undefined') {
      rightPosition = `${100 - ((secondValue - min) / (max - min)) * 100}%`;
    }

    return { leftPosition, rightPosition };
  }, [firstValue, secondValue, max, min]);

  const handleInputChange = (type: 'first' | 'second') => (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    if (disabled) return;

    const newValue = Number(e.target.value);

    if (Array.isArray(value)) {
      let newArrayValue: [number, number] = [min, max];

      if (type === 'first') {
        newArrayValue = [Math.min(newValue, value[1] - 1), value[1]];
      }

      if (type === 'second') {
        newArrayValue = [value[0], Math.max(newValue, value[0] + 1)];
      }

      onChange({ name, value: newArrayValue });
    } else {
      onChange({ name, value: newValue });
    }
  };

  const handleButtonClick = (newValue: TBaseSliderProps['value']) => {
    if (disabled) return;
    onChange({ name, value: newValue });
  };

  return (
    <div
      className={classnames(s.container, className, {
        [s.disabled]: disabled,
        [s.themeBlack]: theme === 'black',
        [s.themeGreen]: theme === 'green',
      })}
      style={{
        ['--left-position' as string]: leftPosition,
        ['--right-position' as string]: rightPosition,
      }}>
      {label && <label className={s.label}>{label}</label>}

      <div className={s.sliderWrapper}>
        <div className={s.slider}>
          <input
            className={s.input}
            type="range"
            min={min}
            max={max}
            step={step}
            value={firstValue}
            onChange={handleInputChange('first')}
            disabled={disabled}
          />
          {typeof secondValue !== 'undefined' && (
            <input
              className={s.input}
              type="range"
              min={min}
              max={max}
              step={step}
              value={secondValue}
              onChange={handleInputChange('second')}
              disabled={disabled}
            />
          )}

          <div className={s.track}></div>
          {secondValue && <div className={s.range}></div>}
          <div className={classnames(s.thumb, s.thumbLeft)}></div>
          {secondValue && <div className={classnames(s.thumb, s.thumbRight)}></div>}
        </div>

        {showButtons && !Array.isArray(value) && (
          <>
            <BaseButtonRound
              className={classnames(s.button, s.buttonLeft)}
              icon="back-arrow"
              theme="gradient-node-card"
              onClick={() => {
                const newValue = Math.max(value - 1, min);
                handleButtonClick(newValue);
              }}
            />
            <BaseButtonRound
              className={classnames(s.button, s.buttonRight)}
              icon="back-arrow"
              theme="gradient-node-card"
              onClick={() => {
                const newValue = Math.min(value + 1, max);
                handleButtonClick(newValue);
              }}
              mirrorContent
            />
          </>
        )}
      </div>

      <BaseErrorMessage>{error}</BaseErrorMessage>
    </div>
  );
};
