import { BaseIcon } from 'src/components/BaseIcon';
import React from 'react';
import { TBaseButtonSquareProps } from './BaseButtonSquare.types';
import classnames from 'classnames';
import s from './BaseButtonSquare.module.scss';

export const BaseButtonSquare: React.FC<TBaseButtonSquareProps> = ({
  style,
  className,
  onClick,
  disabled,
  theme,
  size = '1',
  svgSize = '1',
  icon,
  iconSize,
}) => (
  <button
    type="button"
    style={style}
    onClick={onClick}
    disabled={disabled}
    className={classnames(
      s.container,
      s.className,
      className,
      s[`size-${size}`],
      s[`svg-size-${svgSize}`],
      {
        [s.inactive]: disabled || !theme || theme === 'inactive',
        [s['flat-green']]: !disabled && theme === 'flat-green',
        [s['flat-dark']]: !disabled && theme === 'flat-dark',
        [s['flat-dark-soft']]: !disabled && theme === 'flat-dark-soft',
        [s['flat-blue']]: !disabled && theme === 'flat-blue',
        [s['flat-gray']]: !disabled && theme === 'flat-gray',
        [s['flat-gray-light']]: !disabled && theme === 'flat-gray-light',
        [s['flat-gray-dark']]: !disabled && theme === 'flat-gray-dark',
        [s['flat-red']]: !disabled && theme === 'flat-red',
        [s['flat-ashy']]: !disabled && theme === 'flat-ashy',
        [s.gradient]: !disabled && theme === 'gradient',
      },
    )}>
    <BaseIcon icon={icon} size={iconSize} />
  </button>
);
