import { TMoment } from 'src/utils/UTCMoment';

type TCreateWeekObjectData = {
  weekNum?: number;
  isCurrent?: boolean;
};

type TCreateWeekObjectResult = {
  title: string;
  startUtc: TMoment;
  endUtc: TMoment;
};

export const createWeekObject = (
  startTimeUtc: TMoment,
  endTimeUtc: TMoment,
  { weekNum, isCurrent }: TCreateWeekObjectData,
): TCreateWeekObjectResult => {
  const startFormatted = startTimeUtc.format('MMMM D');
  const endFormatted = endTimeUtc.format('MMMM D');

  return {
    title:
      (isCurrent ? 'Current - ' : 'Week ' + weekNum + ' - ') +
      startFormatted +
      ' to ' +
      endFormatted,
    startUtc: startTimeUtc,
    endUtc: endTimeUtc,
  };
};
