export type TSimulationProgressModalExpandedProps = {
  className?: string;
  style?: React.CSSProperties;

  handleArrowButtonClick: (val: string) => void;
};

export enum EButtonType {
  next = 'next',
  prev = 'previous',
}
