import { EChartTooltips, getChartTooltip } from 'src/constants/chartTooltips';
import React, { useMemo, useState } from 'react';

import { BaseSwitch } from 'src/components/BaseSwitch';
import { ChartBidsAndOffers } from 'src/components/_charts/ChartBidsAndOffers';
import { ChartChordDiagram } from 'src/components/_charts/ChartChordDiagram';
import { ChartWrapper } from 'src/components/ChartWrapper';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';
import { TTradesBidsAndOffersProps } from './TradesBidsAndOffers.type';
import classnames from 'classnames';
import { formatter } from 'src/utils/formatter';
import s from './TradesBidsAndOffers.module.scss';
import { selectSettingsData } from 'src/redux/configuration/configuration.selectors';
import { selectSidebarExpanded } from 'src/redux/application/application.selectors';
import { useSelector } from 'react-redux';

export const TradesBidsAndOffers: React.FC<TTradesBidsAndOffersProps> = ({
  cumulativeGridTrades,
  jobId,
  assetUuid,
  currentMarket,
}) => {
  const sidebarExpanded = useSelector(selectSidebarExpanded);
  const [selectedScoreboardValueIndex, setSelectedScoreboardValueIndex] = useState(0);
  const { currency } = useSelector(selectSettingsData);
  const scoreboardValues: { value: string; icon: TIconNames }[] = useMemo(
    () => [
      { value: 'energy', icon: 'refresh-ccw', info: 'Cumulative' },
      { value: 'money', icon: 'arrows-horz-bltr', info: 'Market Slot' },
    ],
    [],
  );

  return (
    <ChartWrapper
      title="Trades"
      info={getChartTooltip(EChartTooltips.TOOLTIP_TRADES, formatter.getCurrencySymbol(currency))}>
      <div
        className={classnames(s.container, {
          [s.graphInRow]: sidebarExpanded,
        })}>
        <div
          className={classnames(s.tradeContainer, {
            [s.graphInRow]: sidebarExpanded,
          })}>
          <div className={classnames(s.header, s.withPadding)}>
            <BaseSwitch
              name="trading-type"
              value={scoreboardValues[selectedScoreboardValueIndex].value}
              options={scoreboardValues}
              theme="gray-custom"
              variant="horizontal-small"
              onChange={({ value }) =>
                setSelectedScoreboardValueIndex(
                  scoreboardValues.findIndex((item) => item.value === value),
                )
              }
            />
          </div>
          <ChartChordDiagram cumulativeGridTrades={cumulativeGridTrades} />
        </div>
        {selectedScoreboardValueIndex === 1 && jobId && assetUuid && (
          <>
            <ChartBidsAndOffers jobId={jobId} assetUuid={assetUuid} currentMarket={currentMarket} />
          </>
        )}
      </div>
    </ChartWrapper>
  );
};
