import { saveAs } from 'file-saver';
import { selectTokenAuth } from 'src/redux/auth/auth.selectors';
import { useSelector } from 'react-redux';
import { useState } from 'react';

type TError = string | null;
type TDownloadSimResults = {
  type: 'simulation-results';
  resultsUuid: string | undefined;
  startDate: string; // eg: 2021-07-06T00:00
  endDate: string; // eg: 2021-07-10T00:00
};
type TDownloadUserProfile = {
  type: 'user-profile';
  username: string | undefined; // eg: "admin-canary@gridsingularity.com"
  isLibrary: boolean;
};
type TDownloadUploadedFileGetByUuid = {
  type: 'uploaded-file';
  fileUuid: string | undefined;
  configUuid: string | undefined;
  isLibrary: boolean;
  filename?: string;
};
type TDownloadUploadedFileGetByName = {
  type: 'uploaded-file';
  filename: string | undefined;
  configUuid: string | undefined;
  isLibrary: boolean;
};
type TDownloadApiSummary = {
  type: 'api-summary';
  configUuid: string | undefined;
};

type TDownloadFileAttachment = {
  type: 'file-attachment';
  url: string | undefined;
  name: string | undefined;
  uuid: string | undefined;
};
type TUseDownloadAPIProps =
  | TDownloadSimResults
  | TDownloadUserProfile
  | TDownloadUploadedFileGetByUuid
  | TDownloadUploadedFileGetByName
  | TDownloadApiSummary
  | TDownloadFileAttachment;

const DEFAULT_SUCCESS_MESSAGE = `Your download is ready. Thank you for downloading!`;
const DEFAULT_ERROR_MESSAGE = `An error occurred while downloading this file.`;

type TUseDownloadAPIResult = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleDownload: (response?: any) => void;
  loading: boolean;
  error: TError;
};

export default function useDownloadAPI(props: TUseDownloadAPIProps): TUseDownloadAPIResult {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<TError>(null);
  const tokenAuth = useSelector(selectTokenAuth);
  const token = tokenAuth?.token;

  const downloadFile = (url, filename) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const fileUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = filename;
        link.target = '_blank';
        link.click();
        URL.revokeObjectURL(fileUrl);
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
      });
  };

  function getDownloadVars(): { url: string; filename: string } | null {
    const BASE = process.env.REACT_APP_D3A_WEB_API_URL;

    switch (props.type) {
      case 'simulation-results': {
        if (!props.resultsUuid) return null;

        return {
          url: `${BASE}downloads/download-simulation-results-zip/${props.resultsUuid}/${props.startDate}/${props.endDate}`, // prettier-ignore
          filename: `simulation-${props.resultsUuid}.zip`,
        };
      }

      case 'user-profile': {
        if (!props.username) return null;

        return {
          url: `${BASE}downloads/download-${props.isLibrary ? 'lib' : 'config'}-uploaded-files-zip/${props.username}`, // prettier-ignore
          filename: `${props.username}${props.isLibrary ? 'library' : ''}-archive.zip`,
        };
      }

      case 'uploaded-file': {
        if ('fileUuid' in props) {
          if (!props.fileUuid || !props.configUuid) return null;

          return {
            url:`${BASE}downloads/download-${props.isLibrary ? 'lib' : 'config'}-uploaded-file/${props.configUuid}/${props.fileUuid}`, // prettier-ignore
            filename: props.filename || props.fileUuid,
          };
        } else if (props.filename) {
          if (!props.configUuid) return null;

          return {
            // FIXME: This endpoint is broken? Be aware.
            url: `${BASE}downloads/download-${props.isLibrary ? 'lib' : 'config'}-uploaded-file/${props.configUuid}/${props.filename}`, // prettier-ignore
            filename: props.filename,
          };
        }

        return null;
      }

      case 'api-summary': {
        if (!props.configUuid) return null;

        return {
          url: `${BASE}downloads/download-user-configuration-registration-overview/${props.configUuid}`, // prettier-ignore
          filename: `api-summary-${props.configUuid}.json`,
        };
      }

      case 'file-attachment': {
        if (!props.uuid) return null;
        return {
          url: `${BASE}simulation/scm/notification-file/${props.uuid}`, // prettier-ignore
          filename: `${props.name}`,
        };
      }

      default:
        return null;
    }
  }

  async function handleDownload(responseCallback) {
    if (!token) {
      return console.error('User must be logged in!');
    }

    const downloadVars = getDownloadVars();

    if (!downloadVars) return;

    const headers = new Headers();
    headers.append('Authorization', `JWT ${token}`);
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(downloadVars.url, { headers });
      // user profile download file
      if (props.type === 'user-profile') {
        const blobA = await response.blob();
        const blobB = new Blob([blobA.slice(0, blobA.size)], {
          type: 'application/x-zip',
        });
        if (response.status === 200) {
          saveAs(blobB, downloadVars.filename);
        }
      } else if (props.type === 'file-attachment') {
        const jsonBody = await response.json();
        if (response.status === 200 && jsonBody.s3_url) {
          // window.open(jsonBody.s3_url, '_blank');
          downloadFile(jsonBody.s3_url, downloadVars.filename);
        }
      } else {
        const jsonBody = await response.json();

        if (response.status !== 200) {
          throw jsonBody;
        }

        if (responseCallback && response.status === 200) {
          const { message } = jsonBody;
          responseCallback(message === 'job_ready' ? DEFAULT_SUCCESS_MESSAGE : jsonBody.message);
        }

        if (jsonBody?.download_url) {
          window.open(jsonBody.download_url, '_blank');
        } else if (!jsonBody.message) {
          const blobA = await response.blob();
          const blobB = new Blob([blobA.slice(0, blobA.size)], {
            type: 'text/csv;charset=UTF-8',
          });

          saveAs(blobB, downloadVars.filename);
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // setError(error?.message ?? DEFAULT_ERROR_MESSAGE);
      responseCallback?.(error?.message ?? DEFAULT_ERROR_MESSAGE);
    } finally {
      setLoading(false);
    }
  }

  return { handleDownload, error, loading };
}
