import 'react-perfect-scrollbar/dist/css/styles.css';

import PerfectScrollbar from 'react-perfect-scrollbar';
import React from 'react';
import { TCustomScrollbarProps } from './CustomScrollbar.types';
import classnames from 'classnames';

export const CustomScrollbar: React.FC<TCustomScrollbarProps> = ({
  className,
  children,
  component,
  containerRef,
  onScroll,
  onScrollY,
}) => {
  return (
    <PerfectScrollbar
      className={classnames('js-custom-scrollbar', className)}
      component={component}
      containerRef={containerRef}
      onScroll={onScroll}
      onScrollY={onScrollY}>
      {children}
    </PerfectScrollbar>
  );
};
