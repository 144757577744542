import { LIBRARY_FILTERS_MAPPING } from 'src/constants/application';
import { TFilterItem } from 'src/components/AssetsListWithSearch/AssetsListWithSearch.types';
import { AssetsListActiveFilter } from './AssetsListWithSearch.types';

export const MAX_ASSETS_LOGGED_IN = 1000;
export const MAX_ASSETS_GUEST = 50;

export const FILTER_BUTTONS: Array<TFilterItem> = [
  {
    label: 'All',
    value: LIBRARY_FILTERS_MAPPING.All,
  },
  {
    icon: 'house',
    label: 'Home',
    value: LIBRARY_FILTERS_MAPPING.Area,
  },
  {
    icon: 'pv',
    label: 'Solar Panels (PVs)',
    value: LIBRARY_FILTERS_MAPPING.PV,
  },
  {
    icon: 'plug',
    label: 'Consumption',
    value: LIBRARY_FILTERS_MAPPING.Load,
  },
  {
    icon: 'battery',
    label: 'Battery',
    value: LIBRARY_FILTERS_MAPPING.Storage,
  },
  {
    icon: 'heatpump',
    label: 'Heat Pump',
    value: LIBRARY_FILTERS_MAPPING.HeatPump,
  },
  {
    icon: 'wind-turbine',
    label: 'Wind Turbine',
    value: LIBRARY_FILTERS_MAPPING.WindTurbine,
  },
  {
    icon: 'smart-meter',
    label: 'Smart Meter',
    value: LIBRARY_FILTERS_MAPPING.SmartMeter,
  },
];

export const ASSET_TO_DESCRIPTION = {
  [AssetsListActiveFilter.HOME]: {
    title: 'Home',
    description: 'The main building you consume energy in',
  },
  [AssetsListActiveFilter.PV]: {
    title: 'Solar Panels (PVs)',
    description: '',
  },
  [AssetsListActiveFilter.LOAD]: {
    title: 'Consumption',
    description: 'Source of energy consumption or load',
  },
  [AssetsListActiveFilter.STORAGE]: {
    title: 'Battery',
    description: 'Energy storage device that saves excess energy produced for use at a later time',
  },
  [AssetsListActiveFilter.HEATPUMP]: {
    title: 'Heat Pump',
    description: null,
  },
  [AssetsListActiveFilter.WINDTURBINE]: {
    title: 'Wind Turbine',
    description: null,
  },
  [AssetsListActiveFilter.SMARTMETER]: {
    title: 'Smart Meter',
    description: null,
  },
};
