import * as H from 'history';

import React from 'react';
import { TAppRouteProps } from 'src/routes/routes.config';
import { useContext } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TAppLocationContextValue = H.Location<any> & { routeName: TAppRouteProps['routeName'] };

// @ts-ignore
const AppLocationContext = React.createContext<TAppLocationContextValue>(null);

export const AppLocationContextProvider = AppLocationContext.Provider;

export function useAppLocation(): TAppLocationContextValue {
  return useContext(AppLocationContext);
}
