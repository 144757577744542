import { ELsKey, ls } from 'src/utils/localStorage';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { TokenAuthMutation } from 'src/graphql';

export type TAuthState = {
  tokenAuth: TokenAuthMutation['tokenAuth'] | null;
  initialCommunity: string | null;
};

export const initialAuthState: TAuthState = {
  tokenAuth: ls.get(ELsKey.TOKEN_AUTH) || null,
  initialCommunity: ls.get(ELsKey.INITIAL_COMMUNITY) || null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    setTokenAuth(state, action: PayloadAction<TAuthState['tokenAuth']>) {
      state.tokenAuth = action.payload;
    },
    setInitialCommunity(state, action: PayloadAction<TAuthState['initialCommunity']>) {
      state.initialCommunity = action.payload;
    },
  },
});

// Actions
export const { setTokenAuth, setInitialCommunity } = authSlice.actions;

// Reducer
export const authReducer = authSlice.reducer;
