import React from 'react';
import { TModalEmbedLegendsProps } from './ModalEmbedLegends.types';
import classnames from 'classnames';
import s from './ModalEmbedLegends.module.scss';

export const ModalEmbedLegends: React.FC<TModalEmbedLegendsProps> = () => {
  //const isSCMFlow = useSelector(selectSCMFlow);
  const legends = ['Exporting Energy', 'Neutral', 'Importing Energy'];
  //const scmLegends = ['Importing Energy', 'Neutral', 'Exporting Energy', 'Markets'];

  return (
    <>
      {/* {isSCMFlow ? (
        <div className={s.scmMapLegends}>
          {scmLegends.map((legend, index) => (
            <div className={classnames(s[legend.replace(/\s/g, '')], s.legend)} key={index}>
              {legend}
            </div>
          ))}
        </div>
      ) : ( */}
      <ul className={s.mapLegends}>
        {legends.map((legend, index) => (
          <li className={classnames(s[legend.replace(/\s/g, '')], s.legend)} key={index}>
            {legend}
          </li>
        ))}
      </ul>
      {/* )} */}
    </>
  );
};
