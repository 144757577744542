import { TFieldsTemplateUnion, TSettingsData } from 'src/utils/assetsFields/assetsFields.types';

import { EFormVariant } from 'src/typings/base-types';
import { SWITCHER_ICON_SIZES } from 'src/constants/application';

export type TSmartMeterPhoenixArgs = {
  isLibrary: boolean;
  settingsData: TSettingsData;
};

export const SmartMeterPhoenix = ({
  isLibrary,
  settingsData,
}: TSmartMeterPhoenixArgs): TFieldsTemplateUnion[] => {
  return [
    {
      name: 'libraryUUID',
      formView: EFormVariant.Express,
    },
    {
      name: 'name',
      type: 'text',
      label: 'Name',
      formView: EFormVariant.Express,
    },
    {
      name: 'geoTagLocation',
      type: 'location',
      label: 'Location',
      tooltipText: 'Parent market already has location',
      EXCLUDE: isLibrary,
      formView: EFormVariant.Express,
    },
    {
      name: 'capacityKw',
      type: 'number',
      label: 'Capacity',
      formView: EFormVariant.Advanced,
      unit: 'kWp',
    },
    {
      name: 'initialSellingRate',
      type: 'number',
      label: 'Initial Selling Rate',
      unit: 'cents / kWh',
      formView: EFormVariant.Advanced,
    },
    {
      name: 'finalSellingRate',
      type: 'number',
      label: 'Final Selling Rate',
      unit: 'cents / kWh',
      formView: EFormVariant.Advanced,
    },
    {
      name: 'initialBuyingRate',
      type: 'number',
      label: 'Initial Buying Rate',
      unit: 'cents / kWh',
      formView: EFormVariant.Advanced,
    },
    {
      name: 'finalBuyingRate',
      label: 'Final Buying Rate',
      type: 'number',
      unit: 'cents / kWh',
      formView: EFormVariant.Advanced,
    },
    {
      name: 'energyRateDecreasePerUpdate',
      type: 'number',
      label: 'Rate Decrease',
      unit: 'cents / kWh per update',
      formView: EFormVariant.Advanced,
    },
    {
      name: 'energyRateIncreasePerUpdate',
      type: 'number',
      label: 'Rate Decrease',
      unit: 'cents / kWh per update',
      formView: EFormVariant.Advanced,
    },
    {
      name: 'useMarketMakerRate',
      type: 'enum',
      label: 'Final Buying Rate',
      options: [
        { value: true, label: 'Market maker rate' },
        { value: false, label: 'User input' },
      ],
      formView: EFormVariant.Advanced,
    },
    {
      name: 'smartMeterProfile',
      type: 'file',
      label: 'Upload Profile',
      tooltipText: 'Please upload smart meter profile here',
      formView: EFormVariant.Advanced,
      EXCLUDE: false,
    },
    {
      name: 'smartMeterProfileUuid',
      formView: EFormVariant.Advanced,
    },
    {
      name: 'fitToLimit',
      type: 'checkbox',
      label: 'Linear Pricing',
      formView: EFormVariant.Advanced,
    },
    {
      name: 'updateInterval',
      type: 'slider',
      label: 'Update Interval',
      unit: 'min',
      showUnit: true,
      step: 1,
      minVal: 1,
      maxVal: settingsData.slotLengthMinutes - 1,
      formView: EFormVariant.Advanced,
    },
    {
      name: 'allowExternalConnection',
      type: 'switcher',
      options: [
        { icon: 'close', iconSize: SWITCHER_ICON_SIZES.close, value: false },
        { icon: 'check-mark', iconSize: SWITCHER_ICON_SIZES.tick, value: true },
      ],
      label: 'API open',
      formView: EFormVariant.CanaryNetwork,
      disabled: true,
    },
  ];
};
