import React, { useMemo } from 'react';

import { BaseIcon } from 'src/components/BaseIcon';
import { TSCMAssetPopupProps } from 'src/components/SCMAssetPopup/SCMAssetPopup.types';
import classNames from 'classnames';
import { formatter } from 'src/utils/formatter';
import s from './SCMAssetPopup.module.scss';
import { selectAssetsValues } from 'src/redux/configuration/configuration.selectors';
import { selectSCMHomeDetails } from 'src/redux/scm/scm.selectors';
import { useAssetsInside } from 'src/hooks/useAssetsInside';
import { useSelector } from 'react-redux';

export const SCMAssetPopup: React.FC<TSCMAssetPopupProps> = ({ assetUuid, showMemberAddress }) => {
  const assetValues = useSelector(selectAssetsValues);
  const scmMembers = useSelector(selectSCMHomeDetails);

  const data = useMemo(() => {
    return assetValues[assetUuid];
  }, [assetValues, assetUuid]);

  const { assetsInside } = useAssetsInside({ hostAssetUuid: assetUuid });

  const currentMember = useMemo(() => scmMembers.find((item) => item.name === data?.name), [
    data?.name,
    scmMembers,
  ]);

  return (
    <div className={classNames(s.paddingAround, s.background, s.wrapper, s.modalTooltip)}>
      <header className={s.header}>
        <div className={s.typeIcon}>
          <BaseIcon icon={'house'} size={12} />
        </div>
        <div className={s.headerTextWrapper}>
          <h3 className={s.title}>{assetValues[assetUuid].name}</h3>
          {showMemberAddress && <span className={s.address}>{currentMember?.address}</span>}
        </div>
      </header>
      <ul className={s.assetsList}>
        {assetsInside.map((asset, index) => (
          <li key={index}>
            <div className={s.assetSquareBtn}>
              <BaseIcon icon={asset.icon} size={16.25} />
              <label className={s.assetsAmount}>{formatter.prefixWithZeros(asset.amount)}</label>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
