import { EScreenMode } from 'src/typings/configuration.types';
import { Header } from 'src/components/Header';
import React from 'react';
import { SCMHeaderSteps } from 'src/components/SCMHeaderSteps';
import { TTemplateDefaultProps } from './TemplateDefault.types';
import classnames from 'classnames';
import { matchPath } from 'react-router-dom';
import { routesConfig } from 'src/routes/routes.config';
import s from './TemplateDefault.module.scss';
import { selectCommunityUser } from 'src/redux/configuration/configuration.selectors';
import { selectSCMFlow } from 'src/redux/scm/scm.selectors';
import { selectScreenMode } from 'src/redux/application/application.selectors';
import { selectUsername } from 'src/redux/auth/auth.selectors';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import { useSelector } from 'react-redux';

export const TemplateDefault: React.FC<TTemplateDefaultProps> = ({ className, children }) => {
  const isSCMFlow = useSelector(selectSCMFlow);
  const showSCMHeader =
    isSCMFlow &&
    !!matchPath(location.pathname, {
      path: [
        routesConfig.singularityMapCreate(),
        routesConfig.singularityMapResults(),
        routesConfig.scmMapResults(),
      ],
    });
  const communityUser = useSelector(selectCommunityUser);

  const userName = useSelector(selectUsername);

  const { isUserACommunityMember } = useIsUserACommunityMember();

  const screenMode = useSelector(selectScreenMode);

  return (
    <div className={classnames(s.container, className)}>
      <Header />
      {showSCMHeader &&
        !isUserACommunityMember &&
        (userName === communityUser || communityUser == '') &&
        screenMode !== EScreenMode.Mobile && <SCMHeaderSteps />}
      {children}
    </div>
  );
};
