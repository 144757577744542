import { TUsedNames, TConfigurationCharacteristic } from 'src/typings/configuration.types';
import { TFieldValuesByName } from 'src/utils/assetsFields/valuesByFieldName.types';
import { findField, isSingleLibraryDevice } from 'src/utils/fieldUtils';
import { TFieldsUnionWithValue } from 'src/utils/assetsFields/assetsFields.types';
import { MAX_ENERGY_RATE_CHANGE_PER_UPDATE } from 'src/constants/application';

import { rules, TValidationObject } from 'src/utils/fieldValidation';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const nameValidator = (
  name: string,
  usedAssetsNames: TUsedNames,
  currentValueName?: string,
) => [
  rules.required(name),
  rules.noForwardSlash(name),
  rules.isNameUnique(usedAssetsNames, currentValueName || ''),
];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const rangeValidator = (name: string, min: number, max: number) => [
  ...((n) => [rules.required(n), rules.float(n), rules.range(n, min, max)])(name),
];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const buyingRateValidator = (name: string, fields: TFieldsUnionWithValue[]) => [
  rules.required(name),
  rules.float(name),
  rules.range(name, 0, 10000),
  {
    r: ({ newValue }) => {
      const energyRateField = findField(fields, 'energyRate');
      const energyRateProfileField = findField(fields, 'energyRateProfile');
      if (
        !energyRateField ||
        energyRateProfileField ||
        !newValue ||
        typeof energyRateField.value !== 'number'
      ) {
        return true;
      }
      return newValue <= energyRateField.value;
    },
    m: `${name} has to be lower than or equal to selling rate.`,
  } as TValidationObject<TFieldValuesByName<'InfiniteBus'>['energyBuyRate']>,
];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const finalSellingRateValidator = (
  name: string,
  isLibrary: boolean,
  configurationCharacteristic: TConfigurationCharacteristic | undefined,
  fields: TFieldsUnionWithValue[],
) => [
  rules.required(name),
  rules.float(name),
  rules.range(name, 0, 10000),
  {
    r: ({ newValue }) => {
      if (
        configurationCharacteristic &&
        isSingleLibraryDevice({ isLibrary, configurationCharacteristic })
      ) {
        return true;
      }

      if (typeof newValue !== 'number') return true;

      const initialSellingRate = findField(fields, 'initialSellingRate');

      if (!initialSellingRate) {
        if (configurationCharacteristic?.gridMakerHasUploadedProfile) {
          return true;
        }
        if (configurationCharacteristic?.marketMakerRate) {
          return newValue <= configurationCharacteristic.marketMakerRate;
        }

        return true;
      } else if (initialSellingRate.value) {
        return newValue <= Number(initialSellingRate.value);
      }

      return true;
    },
    m: `${name} has to be lower than or equal to initial selling rate.`,
  } as TValidationObject,
];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const energyRateDecreasePerUpdateValidator = (
  name: string,
  isLibrary: boolean,
  configurationCharacteristic: TConfigurationCharacteristic | undefined,
  fields: TFieldsUnionWithValue[],
) => {
  if (configurationCharacteristic?.gridMakerHasUploadedProfile) {
    return [];
  }

  return [
    rules.required(name),
    rules.float(name),
    rules.range(name, 0, MAX_ENERGY_RATE_CHANGE_PER_UPDATE),
    {
      r: ({ newValue }) => {
        if (isLibrary) {
          const fitToLimit = fields.find((field) => field.name === 'fitToLimit');
          if (fitToLimit && !fitToLimit.value && !(newValue || newValue === 0)) {
            return false;
          }
        }
        return true;
      },
      m: `${name} must be between 0 and ${MAX_ENERGY_RATE_CHANGE_PER_UPDATE}.`,
    } as TValidationObject,
  ];
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const energyRateIncreaseValidator = energyRateDecreasePerUpdateValidator;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const finalBuyingRateValidator = (fields: TFieldsUnionWithValue[]) => [
  rules.required('Final Buying Rate'),
  rules.float('Final Buying Rate'),
  rules.range('Final Buying Rate', 0, 10000),
  {
    r: ({ newValue }) => {
      const initialBuyingRate = findField(fields, 'initialBuyingRate');
      if (!initialBuyingRate || !newValue || !initialBuyingRate.value) return true;
      return (newValue as number) >= Number(initialBuyingRate.value);
    },
    m: 'Final buying rate has to be more than or equal to initial buying rate.',
  } as TValidationObject,
  {
    r: ({ newValue }) => {
      const finalSellingRate = findField(fields, 'finalSellingRate');
      if (!finalSellingRate || !newValue || !finalSellingRate.value) return true;
      return (newValue as number) < Number(finalSellingRate.value);
    },
    m: 'Final buying rate has to be lower than final selling rate.',
  } as TValidationObject,
];
