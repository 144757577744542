import * as Api from 'src/components/LandingPage/MarketDesign/ApiLayer';
import * as Deployment from 'src/components/LandingPage/MarketDesign/Deployment';
import * as EcoSystem from 'src/components/LandingPage/MarketDesign/EcoSystem';
import * as Exchanges from 'src/components/LandingPage/MarketDesign/Exchanges';
import * as Initial from 'src/components/LandingPage/MarketDesign/InitialLayer';
import * as Market from 'src/components/LandingPage/MarketDesign/Market';
import * as Simulation from 'src/components/LandingPage/MarketDesign/Simulation';

import { ELayersGroup, TLayers } from 'src/pages/MarketDesign';

import s from './MarketDesign.module.scss';

/**
 *  LAYERS
 *  --------------------
 *  Object containing:
 *  1 - Component = Component to render
 *  2 - config = configurations
 *    if to be sticky then set stycky configurations ps.:
 *    sticky: { start: INITIAL_LAYER, end: END_LAUYER }
 *
 *    if to be a normal layer then use offset as a layer number
 *
 *  3 - props = if necessary there is a props available called withLayers
 *      that we can set an array of layers which will be available that layer
 */

export const InitialLayer: TLayers[] = [
  {
    Component: Initial.InitialLayer,
    config: { offset: 0, className: s.parallaxLayer },
  },
  {
    Component: Initial.MainTitle,
    config: { sticky: { start: 0, end: 18 }, className: s.layerTitle },
  },
];

export const BlueBoxLayer: TLayers[] = [
  {
    Component: Exchanges.BlueBoxOne,
    config: { sticky: { start: 1, end: 1 } },
  },
  {
    Component: Exchanges.BlueBoxTwo,
    config: { sticky: { start: 2, end: 2 } },
  },
  {
    Component: Exchanges.BlueBoxThree,
    config: { sticky: { start: 3, end: 3 } },
  },
  {
    Component: Exchanges.BlueBoxFour,
    config: { sticky: { start: 4, end: 4 } },
  },
  {
    Component: Market.BlueBoxOne,
    config: { sticky: { start: 5, end: 5 } },
  },
  {
    Component: Market.BlueBoxTwo,
    config: { sticky: { start: 6, end: 6 } },
  },
  {
    Component: Market.BlueBoxThree,
    config: { sticky: { start: 7, end: 7 } },
  },
  {
    Component: Market.BlueBoxFour,
    config: { sticky: { start: 8, end: 8 } },
  },
  {
    Component: Api.BlueBoxOne,
    config: { sticky: { start: 9, end: 9 } },
  },
  {
    Component: Api.BlueBoxTwo,
    config: { sticky: { start: 10, end: 10 } },
  },
  {
    Component: Simulation.BlueBoxOne,
    config: { sticky: { start: 11, end: 11 } },
  },
  {
    Component: Simulation.BlueBoxThree,
    config: { sticky: { start: 13, end: 13 } },
  },
  {
    Component: Simulation.BlueBoxFour,
    config: { sticky: { start: 14, end: 14 } },
  },
  {
    Component: Deployment.BlueBoxOne,
    config: { sticky: { start: 15, end: 15 } },
  },
];

export const ExchangeLayer: TLayers[] = [
  {
    Component: Exchanges.LightBarBlue,
    customClass: 'mainLayerExchange',
    config: { sticky: { start: 1, end: 2 } },
  },
  {
    Component: Exchanges.ShadowBlue,
    customClass: 'mainLayerExchange',
    config: { sticky: { start: 1, end: 2 } },
  },
  {
    Component: Exchanges.WaveBg,
    customClass: 'mainLayerExchange',
    config: { sticky: { start: 1, end: 4 } },
  },
  {
    Component: Exchanges.TooltipHouseBlue,
    customClass: 'mainLayerExchange',
    config: { sticky: { start: 1, end: 1 } },
  },
  {
    Component: Exchanges.HouseBlue,
    customClass: 'mainLayerExchange',
    config: { sticky: { start: 1, end: 2 } },
  },
  {
    Component: Exchanges.SolarPanel,
    customClass: 'mainLayerExchange',
    config: { sticky: { start: 1, end: 2 } },
  },
  {
    Component: Exchanges.Car,
    customClass: 'mainLayerExchange',
    config: { sticky: { start: 1, end: 2 } },
  },
  {
    Component: Exchanges.Battery,
    customClass: 'mainLayerExchange',
    config: { sticky: { start: 1, end: 2 } },
  },
  {
    Component: Exchanges.Consumption,
    customClass: 'mainLayerExchange',
    config: { sticky: { start: 1, end: 2 } },
  },
  {
    Component: Exchanges.TooltipDiamond,
    customClass: 'mainLayerExchange',
    config: { sticky: { start: 2, end: 2 } },
  },
  {
    Component: Exchanges.Diamond,
    customClass: 'mainLayerExchange',
    config: { sticky: { start: 2, end: 2 } },
  },
  {
    Component: Exchanges.SingleCommunity,
    customClass: 'mainLayerExchange',
    config: { sticky: { start: 3, end: 4 } },
  },
  {
    Component: Exchanges.TooltipDiamondCommunity,
    customClass: 'mainLayerExchange',
    config: { sticky: { start: 3, end: 3 } },
  },
  {
    Component: Exchanges.LineDiamondCommunity,
    customClass: 'mainLayerExchange',
    config: { sticky: { start: 3, end: 3 } },
  },
  {
    Component: Exchanges.TooltipCommunityAppears,
    customClass: 'mainLayerExchange',
    config: { sticky: { start: 4, end: 4 } },
  },
  {
    Component: Exchanges.CommunityAppears,
    customClass: 'mainLayerExchange',
    config: { sticky: { start: 4, end: 4 } },
  },
  {
    Component: Exchanges.Subtitle,
    config: { sticky: { start: 1, end: 4 } },
  },
];

export const MarketLayer: TLayers[] = [
  {
    Component: Market.MarketOne,
    customClass: 'mainLayer',
    config: { sticky: { start: 5, end: 5 } },
  },
  {
    Component: Market.Voltage,
    config: { sticky: { start: 5, end: 8 } },
  },
  {
    Component: Market.TooltipMarketTwo,
    customClass: 'mainLayer',
    config: { sticky: { start: 6, end: 6 } },
  },
  {
    Component: Market.MarketTwo,
    customClass: 'mainLayer',
    config: { sticky: { start: 6, end: 6 } },
  },
  {
    Component: Market.GrayBox,
    customClass: 'mainLayer',
    config: { sticky: { start: 5, end: 5 } },
  },
  {
    Component: Market.TooltipMarketThree,
    customClass: 'mainLayer',
    config: { sticky: { start: 7, end: 7 } },
  },
  {
    Component: Market.MarketThree,
    customClass: 'mainLayer',
    config: { sticky: { start: 7, end: 7 } },
  },
  {
    Component: Market.MarketNextLevel,
    customClass: 'mainLayer',
    config: { sticky: { start: 8, end: 8 } },
  },
  {
    Component: Market.TooltipNextTooltip,
    customClass: 'mainLayer',
    config: { sticky: { start: 8, end: 8 } },
  },
];

export const ApiLayer: TLayers[] = [
  {
    Component: Api.ApiOne,
    customClass: 'mainLayer',
    config: { sticky: { start: 9, end: 9 } },
  },
  {
    Component: Api.SubtitleApiOne,
    config: { sticky: { start: 9, end: 9 } },
  },
  {
    Component: Exchanges.WaveBg,
    customClass: 'mainLayer',
    config: { sticky: { start: 10, end: 10 } },
  },
  {
    Component: Api.TooltipApiTwo,
    customClass: 'mainLayer',
    config: { sticky: { start: 10, end: 10 } },
  },
  {
    Component: Api.SubtitleApiTwo,
    config: { sticky: { start: 10, end: 10 } },
  },
  {
    Component: Api.ApiTwo,
    customClass: 'mainLayer',
    config: { sticky: { start: 10, end: 10 } },
  },
];

export const SimulationLayer: TLayers[] = [
  {
    Component: Exchanges.WaveBg,
    customClass: 'mainLayer',
    config: { sticky: { start: 11, end: 14 } },
  },
  {
    Component: Simulation.GrayBoxOne,
    customClass: 'mainLayer',
    config: { sticky: { start: 11, end: 11 } },
  },
  {
    Component: Simulation.SimulationOne,
    customClass: 'mainLayer',
    config: { sticky: { start: 11, end: 11 } },
  },
  {
    Component: Simulation.SimulationBox,
    customClass: 'mainLayer',
    config: { sticky: { start: 11, end: 14 } },
  },
  {
    Component: Simulation.BlueBoxTwo,
    config: { sticky: { start: 12, end: 12 } },
  },
  {
    Component: Simulation.GrayBoxTwo,
    config: { sticky: { start: 12, end: 12 } },
  },
  {
    Component: Simulation.SimulationTwo,
    customClass: 'mainLayer',
    config: { sticky: { start: 12, end: 12 } },
  },
  {
    Component: Simulation.GrayBoxThree,
    config: { sticky: { start: 13, end: 13 } },
  },
  {
    Component: Simulation.SimulationThree,
    customClass: 'mainLayer',
    config: { sticky: { start: 13, end: 13 } },
  },
  {
    Component: Simulation.GrayBoxFour,
    config: { sticky: { start: 14, end: 14 } },
  },
  {
    Component: Simulation.SimulationFour,
    customClass: 'mainLayer',
    config: { sticky: { start: 14, end: 14 } },
  },
];

export const DeploymentLayer: TLayers[] = [
  {
    Component: Simulation.SimulationBox,
    customClass: 'mainLayer',
    config: { sticky: { start: 15, end: 15 } },
  },
  {
    Component: Deployment.City,
    customClass: 'mainLayer',
    config: { sticky: { start: 15, end: 15 } },
  },
  {
    Component: Deployment.Ring,
    customClass: 'mainLayer',
    config: { sticky: { start: 15, end: 15 } },
  },
  {
    Component: Deployment.GrayBox,
    customClass: 'mainLayer',
    config: { sticky: { start: 15, end: 15 } },
  },
];

export const EcosystemLayer: TLayers[] = [
  {
    Component: EcoSystem.EcoSystemMain,
    config: { sticky: { start: 16, end: 16 } },
  },
];

function normalizeLayerValue(layer: TLayers, where = 'start') {
  if (layer?.config?.sticky?.[where] === 0) {
    return 0;
  }
  return layer?.config?.sticky?.[where] || layer?.config?.offset;
}

const range = (min, max) => Array.from({ length: max - min + 1 }, (_, i) => min + i);

export const layerPositions = {
  [ELayersGroup.INITIAL]: normalizeLayerValue(InitialLayer?.[0]),
  [ELayersGroup.EXCHANGES]: normalizeLayerValue(ExchangeLayer?.[0]),
  [ELayersGroup.MARKETS]: normalizeLayerValue(MarketLayer?.[0]),
  [ELayersGroup.APIS]: normalizeLayerValue(ApiLayer?.[0]),
  [ELayersGroup.SIMULATION]: normalizeLayerValue(SimulationLayer?.[0]),
  [ELayersGroup.DEPLOYMENT]: normalizeLayerValue(DeploymentLayer?.[0]),
  [ELayersGroup.ECOSYSTEM]: normalizeLayerValue(EcosystemLayer?.[0]),
};

export const totalLayersInEachGroup = {
  [ELayersGroup.INITIAL]: normalizeLayerValue(InitialLayer?.[0]),
  [ELayersGroup.EXCHANGES]: range(
    normalizeLayerValue(ExchangeLayer?.[0]),
    normalizeLayerValue(ExchangeLayer?.[ExchangeLayer.length - 1], 'end'),
  ),
  [ELayersGroup.MARKETS]: range(
    normalizeLayerValue(MarketLayer?.[0]),
    normalizeLayerValue(MarketLayer?.[MarketLayer.length - 1], 'end'),
  ),
  [ELayersGroup.APIS]: range(
    normalizeLayerValue(ApiLayer?.[0]),
    normalizeLayerValue(ApiLayer?.[ApiLayer.length - 1], 'end'),
  ),
  [ELayersGroup.SIMULATION]: range(
    normalizeLayerValue(SimulationLayer?.[0]),
    normalizeLayerValue(SimulationLayer?.[SimulationLayer.length - 1], 'end'),
  ),
  [ELayersGroup.DEPLOYMENT]: range(
    normalizeLayerValue(DeploymentLayer?.[0]),
    normalizeLayerValue(DeploymentLayer?.[DeploymentLayer.length - 1], 'end'),
  ),
  [ELayersGroup.ECOSYSTEM]: range(
    normalizeLayerValue(EcosystemLayer?.[0]),
    normalizeLayerValue(EcosystemLayer?.[EcosystemLayer.length - 1], 'end'),
  ),
};

const allLayers = [
  ...InitialLayer,
  ...ExchangeLayer,
  ...MarketLayer,
  ...ApiLayer,
  ...SimulationLayer,
  ...DeploymentLayer,
  ...EcosystemLayer,
];

// show the numbers of layers
export const totalPages = normalizeLayerValue(allLayers?.[allLayers?.length - 1], 'end');
