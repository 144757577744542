export type TAssetsEnergyExportIndicatorProps = {
  className?: string;
  style?: React.CSSProperties;
  exportStatus: EExportStatus;
};

export enum EExportStatus {
  Exporter = 'Exporter',
  Importer = 'Importer',
  Default = 'Default',
}
