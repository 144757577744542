import { TOptionsType } from 'src/components/CustomPV/components/OrientationBasic/OrientationBasic.types';

export const getOrientationBasicOptions: ({ isWhite }: { isWhite?: boolean }) => TOptionsType[] = ({
  isWhite = false,
}): TOptionsType[] => [
  {
    icon: isWhite ? 'flat-pv-white' : 'flat-pv',
    label: 'Flat',
    value: 0,
    description: 'No inclination',
  },
  {
    icon: isWhite ? 'inclinated-pv-white' : 'inclinated-pv',
    label: 'Tilted',
    value: 45,
    description: '45 degrees',
  },
  {
    icon: isWhite ? 'facade-pv-white' : 'facade-pv',
    label: 'Facade',
    value: 90,
    description: '90 degrees',
  },
];
