import React, { useRef } from 'react';
import { TModalMarketDesignProps } from './ModalMarketDesignOverlay.types';
import { BaseGenericModal } from '../BaseGenericModal';
import s from './ModalMarketDesignOverlay.module.scss';
import { closeModal } from 'src/redux/modals/modals.slice';
import { useAppDispatch } from 'src/redux/store';
import { BaseClose } from '../BaseClose';

export const ModalMarketDesignOverlay: React.FC<TModalMarketDesignProps> = ({
  modalId,
  children,
}) => {
  const dispatch = useAppDispatch();
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    dispatch(closeModal(modalId));
  };

  return (
    <BaseGenericModal size={'auto'} modalId={modalId} className={s.modal} modalRef={modalRef}>
      <BaseClose onClick={handleClose} className={s.closeBtn} />
      <div>{children}</div>
    </BaseGenericModal>
  );
};
