import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import React, { useContext } from 'react';

import { BaseButton } from 'src/components/BaseButton';
import ContentfulContext from 'src/contexts/ContentfulContext';
import { ContentfulContextProvider } from 'src/contexts/ContentfulContext';
import { FadeAnimationHelper } from 'src/components/AnimationHelpers/FadeAnimationHelper/FadeAnimationHelper';
import { Header } from 'src/components/Header';
import classNames from 'classnames';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import s from './ScmFAQs.module.scss';
import { useHistory } from 'react-router';

export const ScmFAQs: React.FC = () => {
  const headerColorMode = 'white';
  const history = useHistory();

  const Bold = ({ children }) => <span className={s.bold}>{children}</span>;

  const Text = ({ children }) => <span className={s.faqText}>{children}</span>;

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      [BLOCKS.UL_LIST]: (node, children) => <p className={s.listHeading}>{children}</p>,
      [BLOCKS.OL_LIST]: (node, children) => {
        return (
          <p
            className={classNames(s.mainHeading, {
              [s.noMargin]: node.content.length > 1, // ordered list which is not a heading
            })}>
            {children}
          </p>
        );
      },
      [INLINES.HYPERLINK]: (node) => {
        return (
          <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
            {node.content[0].value}
          </a>
        );
      },
    },
  };

  const RenderText = () => {
    const { scmFAQs } = useContext(ContentfulContext);
    return (
      <>
        <div className={s.text}>
          {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            documentToReactComponents(scmFAQs?.faqs as any, options)
          }
        </div>
      </>
    );
  };

  return (
    <ContentfulContextProvider>
      <Header isLandingPage headerColorMode={headerColorMode} />
      <div className={s.content}>
        <BaseButton
          className={s.buttonBack}
          theme="quaternary"
          icon="arrow-left-full"
          onClick={() => history.goBack()}>
          BACK
        </BaseButton>
        <FadeAnimationHelper key="privacy-policy" className={s.container} delay={0.5}>
          <div className={s.heading}>
            Social Community Manager: Frequently Asked Questions (FAQ)
          </div>
          <RenderText />
        </FadeAnimationHelper>
      </div>
    </ContentfulContextProvider>
  );
};
