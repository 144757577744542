import React, { useState } from 'react';
import { TValidationObject, rules, validateFields } from 'src/utils/fieldValidation';

import { BaseButton } from 'src/components/BaseButton';
import { BaseInput } from 'src/components/BaseInput';
import { TBaseErrorMessageProps } from 'src/components/BaseErrorMessage';
import { TFormForgotPassword } from './FormForgotPassword.types';
import s from './FormForgotPassword.module.scss';
import { usePasswordResetLazyQuery } from 'src/graphql';

const initFormData: { email: string } = { email: '' };

export const FormForgotPassword: React.FC<TFormForgotPassword> = () => {
  const validators = () => ({
    email: [...((n) => [rules.required(n), rules.email(n)])('Email')],
  });

  const [validationErrors, setValidationErrors] = useState<
    {
      [key in keyof typeof initFormData]?: TBaseErrorMessageProps['children'];
    }
  >({});

  const [formData, setFormData] = useState<{ email: string }>({ email: '' });
  const [loading, setLoading] = useState<boolean>(false);
  const [requestResult, setRequestResult] = useState<{
    type: 'error' | 'success';
    message: string;
  }>({ type: 'success', message: '' });

  // helpers
  const handleFieldBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    validationsArr: TValidationObject[],
  ) => {
    setRequestResult({ ...requestResult, message: '' });
    const { name } = e.target;
    const { errors } = validateFields({
      validators: {
        [name]: validationsArr,
      },
      formData,
    });

    if (errors) {
      if (errors[name]) {
        setValidationErrors((prevState) => ({
          ...prevState,
          [name]: errors[name],
        }));
      }
    } else {
      const newState = { ...validationErrors };
      delete newState[name];
      setValidationErrors(newState);
    }
  };
  const parseError = (errorJson: string): string => {
    const fallbackErrorMessage = `Something doesn't seem right, Please try again!`;
    if (!errorJson) {
      return fallbackErrorMessage;
    }
    const errField = JSON.parse(errorJson);
    if (errField['email'] && errField['email'].length) {
      return errField['email'][0];
    } else {
      return fallbackErrorMessage;
    }
  };

  // handlers
  const handleFieldChange = ({ value, name }) => {
    setFormData({ ...formData, [name]: value });
  };

  const [resetHandler] = usePasswordResetLazyQuery({
    onCompleted: () => {
      setLoading(false);
      setRequestResult({
        type: 'success',
        message: 'A reset link has been sent to your email address!',
      });
    },
    onError: ({ message }) => {
      setLoading(false);
      if (message) {
        setRequestResult({ type: 'error', message: parseError(message) });
      }
    },
    fetchPolicy: 'no-cache',
  });

  const handleSubmit = (e) => {
    setRequestResult({ ...requestResult, message: '' });
    e.preventDefault();
    setLoading(true);
    resetHandler({ variables: { email: formData.email } });
  };

  return (
    <form onSubmit={handleSubmit}>
      <BaseInput
        type="email"
        name="email"
        value={formData.email}
        onChange={handleFieldChange}
        label="Your email"
        placeholder="Type the email we should send the recovery link"
        theme="line-light"
        className={s.inputEmail}
        error={validationErrors.email}
        disabled={loading}
        onBlur={(e) => handleFieldBlur(e, validators().email)}
      />
      {requestResult.message && (
        <p className={requestResult.type === 'success' ? s.successMessage : s.errorMessage}>
          {requestResult.message}
        </p>
      )}
      <BaseButton disabled={loading} type="submit" className={s.buttonReset}>
        Send recovery link
      </BaseButton>
    </form>
  );
};
