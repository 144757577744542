import { AnyAction, combineReducers } from 'redux';
import { TApplicationState, applicationReducer } from 'src/redux/application/application.slice';
import { TAuthState, authReducer } from 'src/redux/auth/auth.slice';

import { History } from 'history';
import { Reducer } from 'redux';
import { canaryNetworkReducer } from 'src/redux/canaryNetwork/canaryNetwork.slice';
import { communitiesReducer } from 'src/redux/communities/communities.slice';
import { configurationReducer } from 'src/redux/configuration/configuration.slice';
import { connectRouter } from 'connected-react-router';
import { mapReducer } from 'src/redux/map/map.slice';
import { modalsReducer } from 'src/redux/modals/modals.slice';
import { notificationsReducer } from 'src/redux/notifications/notificaitons.slice';
import { persistReducer } from 'redux-persist';
import { scmReducer } from 'src/redux/scm/scm.slice';
import storage from 'redux-persist/lib/storage';
import { toastReducer } from 'src/redux/toast/toast.slice';

function withPersist<T>(key: string, reducer: Reducer<T, AnyAction>, config = {}) {
  return persistReducer({ ...config, key, storage }, reducer);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    application: withPersist<TApplicationState>('application', applicationReducer, {
      whitelist: [
        'modalAssetManagerShowGuide',
        'canaryWaitlistDismissed',
        'modalMapAlertDismissed',
        'userCreatedCommunityBefore',
      ],
    }),
    auth: withPersist<TAuthState>('auth', authReducer),
    modals: modalsReducer, // I think we don't need to persist opened modals, but if we need that MODAL_AUTH shouldn't be persisted in order to avoid displaying both ModalAuth and ModalChangePassword at resetPassword page
    // map: withPersist<TMapState>('map', mapReducer, {
    //   whitelist: ['selectedLocation'],
    // }),
    map: mapReducer,
    configuration: configurationReducer,
    communities: communitiesReducer,
    toast: toastReducer,
    notifications: notificationsReducer,
    canaryNetwork: canaryNetworkReducer,
    scm: scmReducer,
  });
