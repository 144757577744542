import { IParallax, Parallax, ParallaxLayer } from '@react-spring/parallax';
import React, { useContext, useEffect } from 'react';
import { useRef, useState } from 'react';

import { BaseIcon } from 'src/components/BaseIcon';
import ContentfulContext from 'src/contexts/ContentfulContext';
import { PrincipleItems } from '../../components/LandingPage/PrincipleItems';
import { StatusBar } from '../../components/LandingPage/StatusBar';
import { config } from 'react-spring';
import s from './OurPrinciple.module.scss';

export const OurPrinciple: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<number | null>(null);
  const [totalPages, setTotalPages] = useState<number | null>(null);
  const parallax = useRef<IParallax>(null);
  const { principlesEntry } = useContext(ContentfulContext);

  useEffect(() => {
    if (principlesEntry?.length > 0) {
      setTotalPages(principlesEntry.length + 1);
    }
  }, [principlesEntry, setTotalPages]);

  const scroll = (to: number) => {
    if (parallax.current) {
      parallax.current.scrollTo(to);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setCurrentPage(0);
    }, 500);
  }, [setCurrentPage]);

  function selectedBackground(currentPage: number) {
    switch (currentPage) {
      default:
      case 0:
        return s.bg_default;
      case 1:
        return s.bg_default;
      case 2:
        return s.bg_she1;
      case 3:
        return s.bg_she2;
      case 4:
        return s.bg_he2;
      case 5:
        return s.bg_he1;
    }
  }

  function renderContent(currentPage: number) {
    const defaultValue = {
      title: '',
      description: '',
    };
    const content = currentPage > 0 ? principlesEntry?.[currentPage - 1] : defaultValue;

    return content;
  }

  function renderImage(page: number) {
    return <div className={`${s.bg} ${selectedBackground(page)}`}></div>;
  }

  function renderView(page: number) {
    const { title, description } = renderContent(page);

    if (page === 0) {
      return (
        <div className={s.boxInfo}>
          <div className={s.links}>
            {principlesEntry?.map((principle, key) => (
              <span key={key.toString()} onClick={() => scroll(key + 1)}>
                {principle.title}
              </span>
            ))}
          </div>
          <BaseIcon
            onClick={() => scroll(page + 1)}
            icon="arrow-left-full"
            className={s.arrowDown}
            size={30}
          />
        </div>
      );
    }

    return (
      <div className={s.boxInfo}>
        {totalPages && page === totalPages - 1 && (
          <BaseIcon
            onClick={() => scroll(0)}
            icon="arrow-left-full"
            className={s.arrowUp}
            size={30}
          />
        )}

        <div className={s.info}>
          <h3>{title}</h3>
          <p>{description}</p>
        </div>

        {totalPages && page !== totalPages - 1 && (
          <BaseIcon
            onClick={() => scroll(page + 1)}
            icon="arrow-left-full"
            className={s.arrowDown}
            size={30}
          />
        )}
      </div>
    );
  }

  return (
    <div className={s.content}>
      {currentPage !== null && totalPages && (
        <StatusBar page={currentPage} totalPages={totalPages} />
      )}
      {currentPage !== null && totalPages && (
        <Parallax
          ref={parallax}
          pages={totalPages}
          style={{ top: '0', left: '0' }}
          className={s.paralax}
          config={config.wobbly}>
          <ParallaxLayer
            speed={0.8}
            sticky={{ start: currentPage, end: totalPages }}
            style={{ zIndex: 9 }}>
            {renderImage(currentPage)}
          </ParallaxLayer>

          <ParallaxLayer
            sticky={{ start: currentPage, end: totalPages }}
            speed={0.8}
            style={{ zIndex: 10 }}>
            {renderView(currentPage)}
          </ParallaxLayer>

          {[...new Array(totalPages)]?.map((_, key) => {
            return (
              <PrincipleItems
                factor={1}
                speed={-0.5}
                key={key.toString()}
                offset={key}
                callbackPage={setCurrentPage}>
                <></>
              </PrincipleItems>
            );
          })}
        </Parallax>
      )}
    </div>
  );
};
