import { TDashboardAssetProps } from './DashboardAsset.types';
import React, { useEffect, useState } from 'react';
import s from './DashboardAsset.module.scss';

import { DashboardAssetPreview } from 'src/components/AdminDashboards/DashboardAsset/DashboardAssetPreview';
import { BaseIcon } from 'src/components/BaseIcon';
import { BaseButtonSquare } from 'src/components/BaseButtonSquare';
import { DashboardAssetCreateNew } from 'src/components/AdminDashboards/DashboardAsset/DashboardAssetCreateNew';
import { LibraryOutput, LibraryTypesGraphql, useReadLibraryLazyQuery } from 'src/graphql';
import { selectAddNewLibrary } from 'src/redux/application/application.selectors';
import { useSelector } from 'react-redux';

export const DashboardAsset: React.FC<TDashboardAssetProps> = ({
  data,
  librariesRefetch,
  onBackToDashboard,
  changeTab,
}) => {
  const addNewLibrary = useSelector(selectAddNewLibrary);
  const [libraryData, setLibraryData] = useState(data);
  const [mode, setMode] = useState<'assetPreview' | 'addNewAsset'>(
    addNewLibrary ? 'addNewAsset' : 'assetPreview',
  );
  const [selectedAsset, setSelectedAsset] = useState<string>();
  const [readLibrary] = useReadLibraryLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (result) => {
      if (result.readLibrary) setLibraryData(result.readLibrary);
      setSelectedAsset(undefined);
      setMode('assetPreview');
    },
  });

  let content: JSX.Element | null = null;

  const onUpdate = () => {
    if (addNewLibrary) {
      changeTab(LibraryTypesGraphql.MyLibrary);
    } else {
      if (!libraryData?.uuid) return;
      readLibrary({
        variables: {
          uuid: libraryData?.uuid,
        },
      });
    }
  };

  const backToDashboard = () => {
    setLibraryData(undefined);
    onBackToDashboard();
  };

  const onPreviewAction = async (actionType: 'delete' | 'duplicate', data: LibraryOutput) => {
    switch (actionType) {
      case 'delete':
        backToDashboard();
        librariesRefetch();
        break;
      case 'duplicate':
        await librariesRefetch();
        setLibraryData(data);
        onUpdate();
        break;
      default:
        break;
    }
  };

  if (addNewLibrary) {
    content = (
      <DashboardAssetCreateNew
        data={{ name: 'create new Lib' }}
        selectedAsset={selectedAsset}
        setMode={setMode}
        setSelectedAsset={setSelectedAsset}
        onUpdate={onUpdate}
      />
    );
  } else if (libraryData) {
    if (mode === 'assetPreview') {
      content = (
        <DashboardAssetPreview
          data={libraryData}
          selectedAsset={selectedAsset}
          setMode={setMode}
          setSelectedAsset={setSelectedAsset}
          onPreviewAction={onPreviewAction}
        />
      );
    } else if (mode === 'addNewAsset') {
      content = (
        <DashboardAssetCreateNew
          data={libraryData}
          selectedAsset={selectedAsset}
          setMode={setMode}
          setSelectedAsset={setSelectedAsset}
          onUpdate={onUpdate}
        />
      );
    }
  }

  useEffect(() => {
    if (libraryData && data && data.uuid !== libraryData.uuid) {
      setLibraryData(data);
    }
  }, [data, libraryData]);

  return (
    <div className={s.container}>
      <header className={s.header}>
        <h3 onClick={() => setMode('assetPreview')}>{libraryData?.name}</h3>
        <div>
          <button type="button">
            <BaseIcon icon="more-horizontal" size={15} />
          </button>
          <BaseButtonSquare icon="close" theme="flat-gray-light" onClick={backToDashboard} />
        </div>
      </header>
      {content}
    </div>
  );
};
