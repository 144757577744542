import { EModalSize, EPredefinedModalIds } from '../../constants/modals';
import { LocationSearch, TLocationSearchChangeResult } from 'src/components/LocationSearch';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';

import { BaseButton } from '../BaseButton';
import { BaseClose } from '../BaseClose';
import { BaseGenericModal } from '../BaseGenericModal';
import { BaseIcon } from 'src/components/BaseIcon';
import { EDomIds } from 'src/constants/domSelectors';
import { TModalSearchLocationProps } from './ModalSearchLocation.types';
import WorldMapContext from 'src/contexts/WorldMapContext';
import s from './ModalSearchLocation.module.scss';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { selectSelectedLocation } from 'src/redux/map/map.selectors';
import { useAppDispatch } from 'src/redux/store';
import { useConfigurationFlowState } from 'src/hooks/useConfigurationFlowState';
import { useSelector } from 'react-redux';

export const ModalSearchLocation: React.FC<TModalSearchLocationProps> = () => {
  const dispatch = useAppDispatch();
  const modalRef = useRef<HTMLDivElement>(null);
  const { mapService } = useContext(WorldMapContext);
  const selectedLocation = useSelector(selectSelectedLocation);
  const { modalSearchLocationShow } = useConfigurationFlowState();

  const [collapsed, setCollapsed] = useState(!!selectedLocation || false);
  const [location, setLocation] = useState<TLocationSearchChangeResult>();
  const isCommunitiesListOpen = useSelector(
    selectIsModalOpened(EPredefinedModalIds.MODAL_MAP_SIDEBAR),
  );

  const onPointerOver = () => {
    if (collapsed && isCommunitiesListOpen) {
      setCollapsed(false);
    }
  };

  const flyToLocation = () => {
    if (location) {
      mapService?.flyTo(location, { specificZoom: 18 });
    }
  };

  useEffect(() => {
    if (modalSearchLocationShow) {
      dispatch(openModal(EPredefinedModalIds.MODAL_SEARCH_LOCATION));
    } else {
      dispatch(closeModal(EPredefinedModalIds.MODAL_SEARCH_LOCATION));
    }
  }, [dispatch, modalSearchLocationShow]);

  return (
    <BaseGenericModal
      className={s.modal}
      modalId={EPredefinedModalIds.MODAL_SEARCH_LOCATION}
      size={EModalSize.XL480}
      modalRef={modalRef}
      id={EDomIds.MODAL_SEARCH_LOCATION}>
      <>
        <div className={s.clickAnywhereTooltip} onPointerOver={onPointerOver}>
          <BaseIcon icon="arrow-loading" size={28} />
          <p>Click anywhere in the map to create your community</p>
        </div>
        {!collapsed && (
          <div className={s.modalLocationSearch}>
            <BaseClose className={s.closeBtn} onClick={() => setCollapsed(true)} />
            <p className={s.header}>Build your community</p>
            <p className={s.title}>Explore the map, build or join a community.</p>
            <LocationSearch
              name="location-search"
              className={s.search}
              onChange={setLocation}
              flyAfterSelect
            />
            <BaseButton theme="primary" size="normal" onClick={flyToLocation}>
              GO
            </BaseButton>
          </div>
        )}
      </>
    </BaseGenericModal>
  );
};
