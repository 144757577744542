import { useMemo } from 'react';

type TUseAllTimestampsProps = {
  startTimestamp?: number;
  endTimestamp?: number;
  interval?: number;
};

export type TUseAllTimestampsOutput = number[];

export function getAllTimestamps({
  startTimestamp,
  endTimestamp,
  interval, // (minutes)
}: TUseAllTimestampsProps): TUseAllTimestampsOutput {
  const res: TUseAllTimestampsOutput = [];

  if (!startTimestamp || !endTimestamp || !interval) return res;

  let currTime = startTimestamp;

  while (endTimestamp - currTime > 0) {
    res.push(currTime);

    currTime += interval * 60 * 1000;
  }

  return res;
}

export default function useAllTimestamps({
  startTimestamp,
  endTimestamp,
  interval, // (minutes)
}: TUseAllTimestampsProps): TUseAllTimestampsOutput {
  return useMemo(() => getAllTimestamps({ startTimestamp, endTimestamp, interval }), [
    startTimestamp,
    endTimestamp,
    interval,
  ]);
}
