import React, { useRef } from 'react';
import { TModalAlertProps } from './ModalAlert.types';
import { BaseGenericModal } from '../BaseGenericModal';
import { EModalSize } from 'src/constants/modals';
import s from './ModalAlert.module.scss';
import { closeModal } from 'src/redux/modals/modals.slice';
import { useAppDispatch } from 'src/redux/store';
import { BaseClose } from '../BaseClose';
import { BaseButton } from '../BaseButton';
import classnames from 'classnames';

export const ModalAlert: React.FC<TModalAlertProps> = ({
  text,
  onContinue,
  showActions = true,
  modalId,
}) => {
  const dispatch = useAppDispatch();
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    dispatch(closeModal(modalId));
  };

  const handleContinue = () => {
    onContinue();
    dispatch(closeModal(modalId));
  };

  return (
    <BaseGenericModal
      size={EModalSize.M280}
      modalId={modalId}
      className={classnames(s.modal, {
        [s.centeredModal]: !showActions,
      })}
      modalRef={modalRef}>
      <BaseClose onClick={handleClose} className={s.closeBtn} />
      <p>{text}</p>
      <div className={s.buttonContainer}>
        {showActions && (
          <>
            <BaseButton size="normal" theme="grey" onClick={handleClose}>
              Cancel
            </BaseButton>
            <BaseButton size="normal" theme="primary" onClick={handleContinue}>
              Continue
            </BaseButton>
          </>
        )}
      </div>
    </BaseGenericModal>
  );
};
