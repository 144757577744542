import { BaseSearch, TBaseSearchProps } from 'src/components/BaseSearch';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import { convertLngLat, getPlaceNameFromCoords } from 'src/utils/worldMap/helpers';

import { Result } from '@mapbox/mapbox-gl-geocoder';
import { TLngLatArray } from 'src/typings/base-types';
import { TLocationSearchProps } from './LocationSearch.types';
import { TViewport } from 'src/services/map/map.service';
import WorldMapContext from 'src/contexts/WorldMapContext';
import { locationsCache } from 'src/cache/locations';
import { selectVisibleLocation } from 'src/redux/map/map.selectors';
import { throttle } from 'lodash';
import { useAppDispatch } from 'src/redux/store';
import { useModal } from 'src/hooks/useModal';
import { useSelector } from 'react-redux';

export const LocationSearch: React.FC<TLocationSearchProps> = ({
  name,
  value = '',
  onChange,
  className,
  dropdownClassName,
  dropdownPosition,
  theme = 'filled-white',
  iconRight = 'location',
  iconLeft = 'search',
  iconRightSize,
  iconLeftColor,
  iconRightColor,
  flyAfterSelect = false,
  elevateLabel = false,
  disabled,
  error,
  label = 'Type a location',
  flyZoom,
}) => {
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState<string>(
    typeof value === 'string' || !value
      ? value || ''
      : locationsCache.get(String(value))?.place_name || '',
  );
  const [resultItems, setResultItems] = useState<Result[]>([]);
  const [selectedPlace, setSelectedPlace] = useState<Result>();
  const { mapService } = useContext(WorldMapContext);
  const visibleLocation = useSelector(selectVisibleLocation);
  const mapboxMap = mapService?.map;
  const { id: searchDropdownId } = useModal();

  const forwardGeocoder = useRef<MapboxGeocoder>();
  if (!forwardGeocoder.current && mapService) {
    const center = mapboxMap?.getCenter();
    forwardGeocoder.current = mapService?.createGeocoder({
      types: 'place, address',
      proximity: {
        longitude: visibleLocation[0]?.lng || center?.lng || 0,
        latitude: visibleLocation[0]?.lat || center?.lat || 0,
      },
    });
  }

  const searchDropdownItems = useMemo(
    () =>
      resultItems.map((item) => ({
        label: item.place_name,
        value: item.id,
      })),
    [resultItems],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const openCloseSearchDropdown = useCallback(
    throttle((value: string) => {
      if (value) {
        dispatch(openModal(searchDropdownId));
      } else {
        dispatch(closeModal(searchDropdownId));
      }
    }, 1000),
    [dispatch, searchDropdownId],
  );

  const onSearchChange: TBaseSearchProps['onChange'] = ({ value }) => {
    setSearchValue(value);

    openCloseSearchDropdown(value);
  };

  const onDropdownItemSelect: TBaseSearchProps['onDropdownItemSelect'] = (item) => {
    const selected = resultItems.find(({ id }) => id === item.value);

    if (selected) {
      setSelectedPlace(selected);
      setSearchValue(selected?.place_name);
      setResultItems([]);
      locationsCache.set(locationsCache.convertToKey(selected.center as TLngLatArray), selected);

      if (flyAfterSelect) {
        if (selected.place_type.includes('place')) {
          mapService?.map.fitBounds(
            [
              [selected.bbox[0], selected.bbox[1]],
              [selected.bbox[2], selected.bbox[3]],
            ],
            {
              speed: 1.5,
            },
          );
        } else {
          mapService?.map.flyTo({
            center: selected.center as [number, number],
            speed: 1.5,
            zoom: flyZoom || 18,
          });
        }
      }

      onChange?.({
        ...convertLngLat(selected.center as TLngLatArray),
        ...selected,
        name,
      });
    }
  };

  const onForwardResults = (event: { features: Result[] }) => {
    setResultItems(event.features);
  };

  const handleIconClick = () => {
    if (selectedPlace) {
      mapService?.flyTo(selectedPlace);
    }
  };

  useEffect(() => {
    if (forwardGeocoder.current && mapboxMap) {
      const center = mapboxMap?.getCenter();
      forwardGeocoder.current.setProximity({
        longitude: visibleLocation[0]?.lng || center?.lng || 0,
        latitude: visibleLocation[0]?.lat || center?.lat || 0,
      });
    }
  }, [mapboxMap, visibleLocation]);

  useEffect(
    function initGeocoders() {
      if (mapboxMap) {
        if (forwardGeocoder.current) {
          forwardGeocoder.current.onAdd(mapboxMap);
          forwardGeocoder.current.on('results', onForwardResults);
        }
      }
    },
    [mapboxMap],
  );

  useEffect(() => {
    if (forwardGeocoder.current) {
      if (selectedPlace?.place_name !== searchValue) {
        forwardGeocoder.current.setInput(searchValue);
        if (searchValue.length === 0) {
          setResultItems([]);
        }
      }
      if (selectedPlace && searchValue.length === 0) {
        setSelectedPlace(undefined);
      }
    }
    return () => setSelectedPlace(undefined);
  }, [searchValue, selectedPlace]);

  useEffect(
    function onValueChange() {
      if (typeof value === 'string' || !value) {
        setSearchValue(value || '');
      } else {
        getPlaceNameFromCoords(value).then((v) => {
          setSearchValue(v?.place_name || '');
          setSelectedPlace(v);
        });
      }
    },
    [value],
  );

  useEffect(() => {
    const listener = (viewport: TViewport) => {
      forwardGeocoder.current?.setProximity({ latitude: viewport.lat, longitude: viewport.lng });
    };
    mapService?.addListener<TViewport>('moveend', listener);
    return () => {
      mapService?.removeListener<TViewport>('moveend', listener);
    };
  }, [mapService]);

  return (
    <BaseSearch
      className={className}
      dropdownClassName={dropdownClassName}
      dropdownPosition={dropdownPosition}
      name={name}
      theme={theme}
      label={label}
      value={searchValue}
      onChange={onSearchChange}
      onDropdownItemSelect={onDropdownItemSelect}
      iconRight={iconRight ? iconRight : undefined}
      iconLeft={iconLeft ? iconLeft : undefined}
      iconLeftColor={iconLeftColor}
      iconRightSize={iconRightSize}
      iconRightColor={iconRightColor}
      iconRightOnClick={handleIconClick}
      options={searchDropdownItems}
      elevateLabel={elevateLabel}
      withDropdown
      dropdownId={searchDropdownId}
      disabled={disabled}
      error={error}
    />
  );
};
