export enum EModalSize {
  S100 = '100',
  S115 = '115',
  S130 = '130',
  S160 = '160',
  S175 = '175',
  M235 = '235',
  M240 = '240',
  M245 = '245',
  M250 = '250',
  M255 = '255',
  M280 = '280',
  L330 = '330',
  L370 = '370',
  L425 = '425',
  L395 = '395',
  XL400 = '400',
  XL420 = '420',
  XL465 = '465',
  XL480 = '480',
  XL500 = '500',
  XL550 = '550',
  XL650 = '650',
  XL795 = '795',
  XL820 = '820',
}

/*
 *
 * !Important: Use EPredefinedModalIds if you need to open a modal from various places in the app,
 * otherwise use a hook: useModal which provides unique id.
 *
 */
export enum EPredefinedModalIds {
  MODAL_AUTH_LOGIN = 'modal-auth-login',
  MODAL_AUTH_REGISTER = 'modal-auth-register',
  MODAL_SEARCH_LOCATION = 'modal-search-location',
  MODAL_MAP_SIDEBAR_NAVIGATION_BAR = 'modal-map-sidebar-navigation-bar',
  MODAL_MAP_SIDEBAR = 'modal-map-sidebar',
  MODAL_RUN_SIMULATION_CTA = 'modal-run-simulation-cta',
  MODAL_MAP_HERO = 'modal-map-hero',
  MODAL_ADD_MORE = 'modal-add-more',
  MODAL_CANARY_WAITLIST = 'modal-canary-waitlist',
  MODAL_ALERT = 'modal-alert-community-change',
  MODAL_COMMUNITY_ASSETS = 'modal-community-assets',
  MODAL_SHARE = 'modal-share',
  MODAL_COMMUNITY_ASSET_MANAGER_FORM = 'modal-community-asset-manager-form',
  MODAL_MAP_ALERT = 'modal-map-alert',
  MODAL_NETWORK_CANARY = 'modal-network-canary',
  MODAL_CHANGE_PASSWORD = 'modal-change-password',
  MODAL_TERMS_AND_CONDITIONS = 'modal-terms-and-conditions',
  MODAL_ONBOARDING = 'modal-onboarding',
  MODAL_EMBED_JOIN = 'modal-embed-join',
  MODAL_EMBED_LEGENDS = 'modal-embed-legends',
  MODAL_PROFILE_PICTURE_CROPPER = 'modal-profile-picture-cropper',
  MODAL_MARKET_DESIGN = 'modal-market-design',
  MODAL_MARKET_DESIGN_OVERLAY = 'modal-market-design-overlay',
  MODAL_USERS = 'modal-users',
  MODAL_ADD_USER = 'modal-add-user',
  MODAL_CANARY_REGISTRY_SELECTBOX = 'modal-canary-registry-selectbox',
  MODAL_CANARY_REQUEST = 'modal-canary-request',
  MODAL_COLLABORATION_DEPLOY = 'modal-collaboration-deploy',
  MODAL_DEPLOY_PROCESS = 'modal-deploy-process',
  MODAL_SCM_UPLOAD_DATA_SHEET = 'modal-scm-upload-data-sheet',
  MODAL_SCM_BUILD_COMMUNITY = 'modal-scm-build-community',
  MODAL_MOVETOCOLLABS = 'modal-movetocollabs',
  MODAL_MOBILE_WARNING = 'modal-mobile-warning',
  MODAL_OPERATE_PROCESS = 'modal-operate-process',
  MODAL_VALIDATE_DATA = 'modal-validate-data',
}
