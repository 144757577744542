import { BaseInput, TBaseInputProps } from 'src/components/BaseInput';
import React, { useState } from 'react';

import { BaseButton } from 'src/components/BaseButton';
import { BaseIcon } from 'src/components/BaseIcon';
import { BaseTextarea } from 'src/components/BaseTextarea';
import { BaseTooltipTrigger } from 'src/components/BaseTooltip';
import s from './TalkToUs.module.scss';
import { useUserContactFormMutation } from 'src/graphql';

const initFormData = {
  firstName: '',
  lastName: '',
  email: '',
  message: '',
};

export const TalkToUs: React.FC = () => {
  const [contactMutation] = useUserContactFormMutation();
  const [formData, setFormData] = useState(initFormData);

  const handleFieldChange: TBaseInputProps['onChange'] = ({ value, name }) => {
    setFormData((curr) => ({
      ...curr,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const { data } = await contactMutation({
        variables: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          message: formData.message,
        },
      });

      if (data) {
        alert('Form submitted successfully');
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <div className={s.content}>
      <div className={s.heading}>Email us here:</div>
      <form className={s.inputTalkToUs} onSubmit={handleSubmit}>
        <div className={s.inputHalf}>
          <BaseInput
            required
            type="text"
            className={s.inputField}
            onChange={handleFieldChange}
            name="firstName"
            placeholder="First name"
            theme="outline-gray"
            inputHeight="3"
          />
          <BaseInput
            required
            type="text"
            className={s.inputField}
            onChange={handleFieldChange}
            name="lastName"
            placeholder="Last name"
            theme="outline-gray"
            inputHeight="3"
          />
        </div>
        <BaseInput
          required
          type="email"
          className={s.inputField}
          onChange={handleFieldChange}
          name="email"
          placeholder="Email address"
          theme="outline-gray"
          inputHeight="3"
        />
        <BaseTextarea
          onChange={handleFieldChange}
          name="message"
          value={formData.message}
          placeholder="Message"
          theme="outline-gray"
        />
        <div className={s.actions}>
          <BaseButton type="submit" theme="primary">
            Send
          </BaseButton>
        </div>
        <div className={s.titleCommunity}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://gsycommunity.slack.com/join/shared_invite/zt-dpfxe2gk-hKFccsrr68g982odCJlrIg#/shared-invite/email">
            <span>Join our Slack Community</span>
          </a>
          <a target="_blank" rel="noreferrer" href="https://x.com/gridsingularity">
            <BaseTooltipTrigger
              triggerClassName={''}
              tooltipClassName={s.helperTooltip}
              position="bottom"
              tooltipChildren={'Grid Singularity Email'}
              wordWrap={true}
              offset={20}>
              <BaseIcon className={s.icon} icon="x" size={18} />
            </BaseTooltipTrigger>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/company/grid-singularity/mycompany/">
            <BaseTooltipTrigger
              triggerClassName={''}
              tooltipClassName={s.helperTooltip}
              position="bottom"
              tooltipChildren={'Grid Singularity Email'}
              wordWrap={true}
              offset={20}>
              <BaseIcon className={s.icon} icon="linkedin" size={18} />
            </BaseTooltipTrigger>
          </a>
        </div>
      </form>
    </div>
  );
};
