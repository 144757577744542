import React, { useMemo } from 'react';

import { BaseGenericModal } from 'src/components/BaseGenericModal';
import { BaseIcon } from 'src/components/BaseIcon';
import { TBaseMultiselectProps } from './BaseMultiselect.types';
import classNames from 'classnames';
import s from './BaseMultiselect.module.scss';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { toggleModal } from 'src/redux/modals/modals.slice';
import { useAppDispatch } from 'src/redux/store';
import { useModal } from 'src/hooks/useModal';
import { useSelector } from 'react-redux';

export const BaseMultiselect: React.FC<TBaseMultiselectProps> = ({
  theme = 'white',
  size = 's',
  className,
  style,
  label,
  options,
  selectedOptions,
  onItemClick,
}) => {
  const { id: modalId } = useModal();
  const dropdownIsOpen = useSelector(selectIsModalOpened(modalId));
  const dispatch = useAppDispatch();

  const sizeClass = useMemo(() => {
    return s['size-' + size];
  }, [size]);

  const themeClass = useMemo(() => {
    return s['theme-' + theme];
  }, [theme]);

  return (
    <div
      className={classNames(s.container, themeClass, sizeClass, className, {
        [s.dropdownIsOpen]: dropdownIsOpen,
      })}
      style={style}>
      <button
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          dispatch(toggleModal(modalId));
        }}>
        <span>{label}</span>
        <BaseIcon icon="arrow" size={7} />
      </button>

      <BaseGenericModal
        size="auto"
        modalId={modalId}
        singleActionModal
        inline={true}
        className={s.dropdown}>
        {options.map((item) => {
          const checked = selectedOptions.includes(item.value);
          return (
            <button
              type="button"
              key={item.value}
              className={classNames({
                [s.checked]: checked,
              })}
              onClick={() => onItemClick?.(item)}>
              <div>{checked && <BaseIcon icon="check-mark" size={6} />}</div>
              <span>{item.label}</span>
            </button>
          );
        })}
      </BaseGenericModal>
    </div>
  );
};
