import { UserToAdminRequestsOutput } from 'src/graphql';
import { TNotificationModalItem } from 'src/typings/base-types';

export const convertUserToAdminRequestOnNotification = (
  data: Pick<
    UserToAdminRequestsOutput,
    | 'id'
    | 'username'
    | 'requestType'
    | 'status'
    | 'configUuid'
    | 'profilePicture'
    | 'profileThumbnail'
  >,
): TNotificationModalItem => {
  return {
    uuid: data.id!,
    profilePicture: data.profilePicture!,
    profileThumbnail: data.profileThumbnail!,
    name: data.username!,
    description: 'Requested a Canary Network',
  };
};
