export type TSource = {
  id: string;
  source: mapboxgl.AnySourceData;
};

export const mapSourceHighlightBuildings: TSource = {
  id: 'highlight-buildings-source',
  source: {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  },
};
