import { BaseButtonSquare, TBaseButtonSquareProps } from 'src/components/BaseButtonSquare';
import { BaseIcon, TBaseIconProps } from 'src/components/BaseIcon';
import { ConfigType, useMoveCollaborationToCanaryNetworkMutation } from 'src/graphql';
import { DEFAULT_COMMUNITY_NAME, START_LOCATION } from 'src/constants/application';
import { EDashboard, EUserRoles } from '../../typings/base-types';
import { EMenuColor, THeaderProps } from './Header.types';
import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import { Link, useHistory } from 'react-router-dom';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { clearMeasurementNotificationList } from 'src/redux/notifications/notificaitons.slice';
import { batch, useSelector } from 'react-redux';

import { closeModal, openModal, toggleModal } from 'src/redux/modals/modals.slice';
import { routesConfig, routesTitles } from 'src/routes/routes.config';
import {
  selectActiveConfigurationJobUuid,
  selectActiveConfigurationUuid,
  selectCommunityAsset,
  selectCommunityName,
  selectConfigType,
  selectReadOnly,
  selectSimulationStatus,
} from 'src/redux/configuration/configuration.selectors';
import {
  selectActiveDashboard,
  selectAppMode,
  selectIsEmbed,
  selectTutorialStep,
} from 'src/redux/application/application.selectors';
import {
  selectGlobalNotificationsList,
  selectMeasurementNotificationList,
  selectSingleNotificationByNotificationPlace,
} from 'src/redux/notifications/notifications.selectors';
import { selectIsAdmin, selectIsLoggedIn, selectUserRole } from 'src/redux/auth/auth.selectors';
import {
  setAddNewLibrary,
  setGuideStep,
  setModalAssetManagerActiveView,
  setModalCommunitySummaryView,
  setModalMapHeroDismissed,
  setOnBoardingStep,
} from 'src/redux/application/application.slice';

import { BaseButton } from 'src/components/BaseButton';
import { BaseHamburger } from 'src/components/BaseHamburger';
import { BaseLink } from 'src/components/BaseLink';
import { BaseOverflowMenu } from 'src/components/BaseOverflowMenu';
import { BaseTooltipTrigger } from 'src/components/BaseTooltip';
import { BurguerButton } from 'src/components/LandingPage/BurguerButton';
import { CanaryNetworkServiceContext } from '../CanaryNetworkServiceProvider/CanaryNetworkServiceProvider';
import { EAppMode } from 'src/utils/appMode';
import { EDomIds } from 'src/constants/domSelectors';
import { EModalAssetsManagerView } from 'src/components/ModalAssetsManager';
import { ENotificationPlace } from 'src/redux/notifications/notifications.types';
import { MeasurementNotificationList } from 'src/components/MeasurementNotificationList';
import { ModalBurgerNavigation } from '../ModalBurgerNavigation';
import { NAV_LINKS } from './constants';
import { NotificationsModalList } from 'src/components/MapSidebar/components/MapSidebarResults/components/NotificationsModalList';
import { RunSimulationNotification } from 'src/components/SingleNotifications/RunSimulationNotification';
import { ShareSimulation } from 'src/components/ShareSimulation';
import { SimulationRunButton } from 'src/components/Header/SimulationRunButton';
import { UserAvatar } from 'src/components/UserAvatar';
import WorldMapContext from 'src/contexts/WorldMapContext';
import classnames from 'classnames';
import { convertUserToAdminRequestOnNotification } from 'src/utils/notification';
import s from './Header.module.scss';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { setSelectedAssetUuid } from 'src/redux/configuration/configuration.slice';
import { useAppDispatch } from 'src/redux/store';
import { useAppLocation } from 'src/hooks/useAppLocation';
import { useConfigurationFlowState } from 'src/hooks/useConfigurationFlowState';
import { useConfigurationUtils } from 'src/hooks/useConfigurationUtils';
import { useIsAdminInspecting } from 'src/hooks/useIsAdminInspecting';
import { useModal } from 'src/hooks/useModal';
import { useSimulationButtons } from 'src/hooks/useSimulationButtons';
import { useTranslation } from 'react-i18next';

export enum EHeaderMode {
  Default = 'DEFAULT',
  Community = 'COMMUNITY',
}

const selectColorMenuLandingPage = {
  [EMenuColor.AlternativeOne]: s.menuColorLandingPageAlt_1,
  [EMenuColor.AlternativeTwo]: s.menuColorLandingPageAlt_2,
};

const selectBgBlueLandingPage = {
  [EMenuColor.AlternativeOne]: s.blurAlt_1,
  [EMenuColor.AlternativeTwo]: s.blurAlt_2,
};

export const IconsItems = (): JSX.Element => {
  return (
    <>
      <BaseLink
        theme="3"
        target="_blank"
        to="https://gridsingularity.github.io/gsy-e/documentation/">
        <BaseTooltipTrigger
          triggerClassName={''}
          tooltipClassName={s.helperTooltip}
          position="bottom"
          tooltipChildren={'Grid Singularity Documentation'}
          wordWrap={true}
          offset={20}>
          <BaseIcon className={s.icons} icon="wiki" size={22} />
        </BaseTooltipTrigger>
      </BaseLink>
      <BaseLink theme="3" target="_blank" to="https://gridsingularity.medium.com/">
        <BaseTooltipTrigger
          triggerClassName={''}
          tooltipClassName={s.helperTooltip}
          position="bottom"
          tooltipChildren={'Grid Singularity Medium Blog'}
          wordWrap={true}
          offset={20}>
          <BaseIcon className={s.icons} icon="medium" size={18} />
        </BaseTooltipTrigger>
      </BaseLink>
      <BaseLink theme="3" target="_blank" to="https://x.com/gridsingularity">
        <BaseTooltipTrigger
          triggerClassName={''}
          tooltipClassName={s.helperTooltip}
          position="bottom"
          tooltipChildren={'Grid Singularity X Handle'}
          wordWrap={true}
          offset={20}>
          <BaseIcon className={s.icons} icon="x" size={18} />
        </BaseTooltipTrigger>
      </BaseLink>
      <BaseLink theme="3" target="_blank" to="https://gsycommunity.slack.com/">
        <BaseTooltipTrigger
          triggerClassName={''}
          tooltipClassName={s.helperTooltip}
          position="bottom"
          tooltipChildren={'Grid Singularity Slack Channel'}
          wordWrap={true}
          offset={20}>
          <BaseIcon className={s.icons} icon="slack" size={18} />
        </BaseTooltipTrigger>
      </BaseLink>
      <BaseLink theme="3" target="_blank" to="https://www.linkedin.com/company/grid-singularity">
        <BaseTooltipTrigger
          triggerClassName={''}
          tooltipClassName={s.helperTooltip}
          position="bottom"
          tooltipChildren={'Grid Singularity LinkedIn Profile'}
          wordWrap={true}
          offset={20}>
          <BaseIcon className={s.icons} icon="linkedin" size={18} />
        </BaseTooltipTrigger>
      </BaseLink>
      <BaseLink theme="3" target="_blank" to="https://github.com/gridsingularity">
        <BaseTooltipTrigger
          triggerClassName={''}
          tooltipClassName={s.helperTooltip}
          position="bottom"
          tooltipChildren={'Grid Singularity GitHub'}
          wordWrap={true}
          offset={20}>
          <BaseIcon className={s.icons} icon="github" size={18} />
        </BaseTooltipTrigger>
      </BaseLink>
      <BaseLink theme="3" to="mailto:contact@gridsingularity.com">
        <BaseTooltipTrigger
          triggerClassName={''}
          tooltipClassName={s.helperTooltip}
          position="bottom"
          tooltipChildren={'Grid Singularity Email'}
          wordWrap={true}
          offset={20}>
          <BaseIcon className={classnames(s.icons, s.mailIcon)} icon="email" size={22} />
        </BaseTooltipTrigger>
      </BaseLink>
    </>
  );
};

export const Header: React.FC<THeaderProps> = ({
  isLandingPage,
  headerColorMode,
  textColorLandingpage,
}) => {
  const { t } = useTranslation();

  const isAdmin = useSelector(selectIsAdmin);
  const CanaryNetworkContext = useContext(CanaryNetworkServiceContext);
  const location = useAppLocation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isEmbed = useSelector(selectIsEmbed);
  const simulationStatus = useSelector(selectSimulationStatus);
  const communityAsset = useSelector(selectCommunityAsset);
  const configType = useSelector(selectConfigType);
  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
  const activeConfigurationJobUuid = useSelector(selectActiveConfigurationJobUuid);
  const RunPauseBtnRef = useRef<HTMLElement>(null);
  const { discardCurrentConfiguration } = useConfigurationUtils();
  const { headerMode, communityIsReadyToSave } = useConfigurationFlowState();
  const [openButtonMenu, setOpenButtonMenu] = useState(false);
  const { runButtonOptions, runButtonState, stopButtonOptions } = useSimulationButtons({
    jobUuid: activeConfigurationJobUuid,
    configurationUuid: activeConfigurationUuid,
  });
  const { mapService } = useContext(WorldMapContext);
  const appMode = useSelector(selectAppMode);
  const readOnly = useSelector(selectReadOnly);
  const userRole = useSelector(selectUserRole);
  const appLocation = useAppLocation();
  const { isAdminInspecting } = useIsAdminInspecting();

  const {
    loading: moveCollaborationToCanaryNetworkLoading,
  } = useMoveCollaborationToCanaryNetworkMutation()[1];

  const isCreateUrl = useMemo(() => appLocation.pathname === routesConfig.singularityMapCreate(), [
    appLocation,
  ]);

  const isBackBtnDisable = false;
  const communityName = useSelector(selectCommunityName);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isCN = configType === ConfigType.CanaryNetwork;
  const isOperationalCommunity = isCN;

  const title =
    headerMode === EHeaderMode.Community ? communityName : routesTitles[location.routeName];

  const { id: burgerMenuModalId } = useModal();
  const { id: shareMenuModalId } = useModal();
  const { id: notificationsModalUuid } = useModal();
  const isBurgerMenuOpened = useSelector(selectIsModalOpened(burgerMenuModalId));
  const isShareMenuOpened = useSelector(selectIsModalOpened(shareMenuModalId));
  const modalRefShare = useRef<HTMLButtonElement>(null);
  const notificationsBellButtonRef = useRef<HTMLButtonElement>(null);
  const tutorialStep = useSelector(selectTutorialStep);
  const notificationsList = useSelector(selectGlobalNotificationsList);
  const activeDashboard = useSelector(selectActiveDashboard);
  const runSimulationNotification = useSelector(
    selectSingleNotificationByNotificationPlace(ENotificationPlace.HEADER_RUN_SIMULATION),
  );

  const measurementNotifications = useSelector(selectMeasurementNotificationList);
  const simulationStatusRef = useRef(simulationStatus);

  useEffect(() => {
    if (simulationStatusRef.current !== simulationStatus) {
      simulationStatusRef.current = simulationStatus;
    }
  }, [dispatch, simulationStatus]);

  useEffect(() => {
    if (measurementNotifications.length > 0) {
      dispatch(toggleModal(notificationsModalUuid));
    }
  }, [dispatch, measurementNotifications, notificationsModalUuid]);

  const handleAddNewUser = () => {
    batch(() => {
      dispatch(openModal(EPredefinedModalIds.MODAL_ADD_USER));
    });
  };

  // show the ModalOperateProcess modal when moveCollaborationToCanaryNetworkLoading is true
  useEffect(() => {
    if (moveCollaborationToCanaryNetworkLoading) {
      dispatch(openModal(EPredefinedModalIds.MODAL_OPERATE_PROCESS));
    } else {
      dispatch(closeModal(EPredefinedModalIds.MODAL_OPERATE_PROCESS));
    }
  }, [dispatch, moveCollaborationToCanaryNetworkLoading]);

  function handleAddNewLibraryClick() {
    dispatch(setAddNewLibrary(true));
  }

  const memoizedNotifications = useMemo(() => {
    if (notificationsList.length !== 0) {
      return notificationsList.map(
        // @ts-ignore
        (item) => item && convertUserToAdminRequestOnNotification(item),
      );
    }
  }, [notificationsList]);

  function handleHamburgerClick() {
    if (isBurgerMenuOpened) {
      dispatch(closeModal(burgerMenuModalId));
    } else {
      dispatch(openModal(burgerMenuModalId));
    }
  }

  const handleLogoRedirection = () => {
    if (discardCurrentConfiguration) discardCurrentConfiguration({ zoomOut: true });
  };

  const handleCreateCommunityClick = () => {
    dispatch(setModalAssetManagerActiveView(EModalAssetsManagerView.AddCommunity));
    dispatch(setOnBoardingStep(0));
    dispatch(setGuideStep(1));
    dispatch(openModal(EPredefinedModalIds.MODAL_ONBOARDING));
    discardCurrentConfiguration();
    dispatch(setModalMapHeroDismissed(true));

    history.push(routesConfig.singularityMapCreate());
    mapService?.fitBounds(START_LOCATION);
  };

  const handleCommunityDiscard = () => {
    dispatch(setModalAssetManagerActiveView(EModalAssetsManagerView.AddCommunity));
    dispatch(setOnBoardingStep(0));
    dispatch(setGuideStep(0));
    dispatch(closeModal(EPredefinedModalIds.MODAL_ONBOARDING));
    discardCurrentConfiguration({ zoomOut: true });
    dispatch(clearMeasurementNotificationList());
  };
  const closeShareSimulationModal = () => {
    dispatch(closeModal(shareMenuModalId));
  };

  const titleSideIcon:
    | { icon: TBaseIconProps['icon']; theme: TBaseButtonSquareProps['theme'] }
    | undefined = (() => {
    switch (location.routeName) {
      case 'singularityMap': {
        if (headerMode === EHeaderMode.Community) {
          if (configType === ConfigType.CanaryNetwork) {
            return {
              icon: 'canary' as const,
              theme: 'flat-blue' as const,
            };
          } else {
            return {
              icon: 'simulation' as const,
              theme: 'flat-blue' as const,
            };
          }
        }

        return {
          icon: 'globe' as const,
          theme: 'flat-green' as const,
        };
      }

      default:
        return;
    }
  })();

  const titleSideButtons: JSX.Element | null = (() => {
    const defaultBtns = (
      <>
        <span className={s.vertLineB}></span>
        <button title="Share" type="button" className="click-area">
          <BaseIcon icon="share-arrow" className={s.aloneIcon} />
        </button>
      </>
    );

    switch (location.routeName) {
      case 'singularityMap': {
        if (headerMode === EHeaderMode.Community) {
          return (
            <>
              {!isCN && (
                <button
                  ref={modalRefShare}
                  type="button"
                  className="click-area"
                  onClick={() => {
                    dispatch(
                      isShareMenuOpened
                        ? closeModal(shareMenuModalId)
                        : openModal(shareMenuModalId),
                    );
                  }}>
                  <BaseIcon
                    icon="share"
                    className={classnames(s.aloneIcon, isShareMenuOpened ? s.greenMenu : '')}
                  />
                </button>
              )}
            </>
          );
        }
        return defaultBtns;
      }

      default:
        return defaultBtns;
    }
  })();

  const LoggedComponent = (
    <header id={EDomIds.HEADER} className={classnames(s.container, s.itemsCenter)}>
      <nav className={classnames(s.nav_con, s.itemsCenter)}>
        <BaseHamburger
          isOpened={isBurgerMenuOpened}
          onClick={handleHamburgerClick}
          className={s.hamburger}
        />
        <ModalBurgerNavigation modalId={burgerMenuModalId} />
        <BaseLink
          isNavLink={true}
          onClick={handleLogoRedirection}
          to={routesConfig.home()}
          className={s.logoLink}
          activeStyle={false}>
          <BaseIcon icon="gs-logo" />
        </BaseLink>

        <span className={s.vertLineA}></span>

        {
          <>
            {headerMode === EHeaderMode.Community && (
              <button
                type="button"
                className={classnames(s.backIcon, 'click-area', {
                  [s.disabled]: isBackBtnDisable,
                })}
                onClick={handleCommunityDiscard}
                disabled={isBackBtnDisable}>
                <BaseIcon icon="arrow-left-full" size={17} />
              </button>
            )}

            {titleSideIcon && (
              <div className={s.titleSideIcon}>
                <BaseButtonSquare
                  icon={titleSideIcon.icon}
                  theme={titleSideIcon.theme}
                  size="2"
                  svgSize="2"
                />
              </div>
            )}

            <span className={s.title}>{title}</span>

            {titleSideButtons}
          </>
        }
      </nav>

      <nav className={classnames(s.nav_con, s.mla, s.itemsCenter)}>
        {!isEmbed &&
          (activeDashboard === EDashboard.Libraries ? (
            <BaseButton
              className={classnames(s.buildCommunityBtn, s.ml25)}
              onClick={handleAddNewLibraryClick}>
              <BaseIcon icon={'plus'} size={10} className={s.socialIcon} />
              Add new library item
            </BaseButton>
          ) : headerMode === EHeaderMode.Community ? (
            <div>
              {!stopButtonOptions.disabled && (
                <button
                  type="button"
                  className={classnames(s.abortBtn, s.centerAll)}
                  onClick={() => stopButtonOptions.onClick && stopButtonOptions.onClick()}
                  disabled={stopButtonOptions.disabled}>
                  <BaseIcon icon={stopButtonOptions.icon} size={19.5} />
                </button>
              )}
              {activeConfigurationUuid ? (
                !readOnly && !isOperationalCommunity && !isAdminInspecting ? (
                  <SimulationRunButton
                    runButtonOptions={runButtonOptions}
                    runButtonState={runButtonState}
                    runPauseBtnRef={RunPauseBtnRef}
                  />
                ) : null
              ) : communityIsReadyToSave ? (
                <BaseButton
                  type="submit"
                  form={EPredefinedModalIds.MODAL_COMMUNITY_ASSET_MANAGER_FORM}
                  className={s.buildCommunityBtn}
                  onClick={() => {
                    dispatch(setSelectedAssetUuid(communityAsset?.uuid));
                    dispatch(setModalCommunitySummaryView(EModalAssetsManagerView.Form));
                  }}>
                  Save Community
                </BaseButton>
              ) : null}
            </div>
          ) : (
            // the below condition will ensure no build button on user dashboard
            activeDashboard !== EDashboard.Users &&
            // PH-1283-Hide build community for GSY user
            userRole !== EUserRoles.Aggregator &&
            userRole !== EUserRoles.Researcher && (
              <BaseButton
                className={classnames(s.buildCommunityBtn, s.ml5)}
                onClick={handleCreateCommunityClick}>
                Build Community
              </BaseButton>
            )
          ))}
        {isAdmin && activeDashboard === EDashboard.Users && (
          <BaseButton
            icon={'plus-thick'}
            className={classnames(s.buildCommunityBtn, s.ml25)}
            onClick={handleAddNewUser}>
            Add new user
          </BaseButton>
        )}
        {userRole && [EUserRoles.Admin, EUserRoles.ExchangeOperator].includes(userRole) && (
          <>
            <button
              className={s.notificationsButton}
              type="button"
              title="Notificatons"
              ref={notificationsBellButtonRef}
              onClick={() => {
                dispatch(toggleModal(notificationsModalUuid));
              }}>
              <BaseIcon icon="bell" size={22} />
              {memoizedNotifications && memoizedNotifications.length !== 0 && (
                <div className={s.notificationsBubble}>
                  <span className={s.notificationsBubbleText}>{memoizedNotifications.length}</span>
                </div>
              )}
            </button>

            {runSimulationNotification && (
              <RunSimulationNotification
                singleNotification={runSimulationNotification}
                runPauseBtnRef={RunPauseBtnRef}
              />
            )}
            {!runSimulationNotification && isOperationalCommunity && (
              <BaseOverflowMenu
                relativeElement={notificationsBellButtonRef}
                modalId={notificationsModalUuid}
                position={{
                  side: 'bottom',
                  anchorPosition: 90,
                }}
                size={EModalSize.L330}
                className={s.notificationsModal}>
                <MeasurementNotificationList />
              </BaseOverflowMenu>
            )}
            {!runSimulationNotification && !isOperationalCommunity && (
              <BaseOverflowMenu
                relativeElement={notificationsBellButtonRef}
                modalId={notificationsModalUuid}
                position={{
                  side: 'bottom',
                  anchorPosition: 90,
                }}
                size={EModalSize.L330}
                className={s.notificationsModal}>
                <NotificationsModalList
                  items={memoizedNotifications ? memoizedNotifications : []}
                  onChange={() => {
                    if (activeConfigurationUuid) {
                      CanaryNetworkContext.fetchGlobalListUserToAdminRequests();
                    }
                  }}
                  className={s.notificationsModalList}
                />
              </BaseOverflowMenu>
            )}
          </>
        )}
      </nav>
      <UserAvatar />
      {/* User menu */}
      {!isCN && (
        <BaseOverflowMenu
          relativeElement={modalRefShare}
          modalId={shareMenuModalId}
          position={{
            side: 'bottom',
            anchorPosition: 6,
          }}
          size={EModalSize.M280}
          className={s.shareMenu}>
          <ShareSimulation close={closeShareSimulationModal} />
        </BaseOverflowMenu>
      )}
    </header>
  );

  const NotLoggedComponent = (
    <header
      className={classnames(s.notLoggedContainer, s.itemsCenter)}
      style={{
        zIndex: tutorialStep === 5 ? 99999 : 100,
        pointerEvents: tutorialStep === 5 ? 'none' : 'auto',
      }}>
      <nav className={classnames(s.nav_con, s.itemsCenter)}>
        <BaseLink
          isNavLink={true}
          to={routesConfig.home()}
          className={classnames(s.logoLink, s.logoLinkNotLogged)}
          activeStyle={false}>
          <BaseIcon icon="gs-logo" />
        </BaseLink>

        {NAV_LINKS.map((link) => {
          return (
            <BaseLink
              key={link.route}
              isNavLink={location?.routeName === link.route}
              to={routesConfig[link.route]()}
              className={classnames(s.pageNavLink, 'click-area')}>
              {link.title}
            </BaseLink>
          );
        })}
      </nav>

      {
        <nav className={classnames(s.nav_con, s.itemsCenter, s.mla)}>
          <button
            type="button"
            className={s.loginLink}
            onClick={() => dispatch(openModal(EPredefinedModalIds.MODAL_AUTH_LOGIN))}>
            {t('LOGIN')}
          </button>
          <span className={s.vertLineD}></span>
          <button
            type="button"
            className={s.registerLink}
            onClick={() => dispatch(openModal(EPredefinedModalIds.MODAL_AUTH_REGISTER))}>
            Register
          </button>
          <span className={s.vertLineD}></span>
          {!isEmbed &&
            (headerMode === EHeaderMode.Community ? (
              communityIsReadyToSave ? (
                <>
                  <BaseButton
                    type="submit"
                    form={EPredefinedModalIds.MODAL_COMMUNITY_ASSET_MANAGER_FORM}
                    className={classnames(s.buildCommunityBtn, s.ml25)}
                    onClick={() => {
                      if (!isLoggedIn) {
                        dispatch(openModal(EPredefinedModalIds.MODAL_AUTH_REGISTER));
                      }
                      dispatch(setSelectedAssetUuid(communityAsset?.uuid));
                      if (communityName === DEFAULT_COMMUNITY_NAME) {
                        dispatch(setModalCommunitySummaryView(EModalAssetsManagerView.Form));
                      } else {
                        dispatch(setModalCommunitySummaryView(EModalAssetsManagerView.Summary));
                      }
                    }}>
                    {communityName === DEFAULT_COMMUNITY_NAME ? 'Save Community' : 'Run Simulation'}
                  </BaseButton>
                  <BaseButton
                    className={s.cancelBtn}
                    icon="close"
                    theme="grey3"
                    onClick={handleCommunityDiscard}>
                    Cancel
                  </BaseButton>
                </>
              ) : isLoggedIn || isCreateUrl ? (
                <BaseButton
                  className={s.cancelBtn}
                  icon="close"
                  theme="grey3"
                  onClick={handleCommunityDiscard}>
                  Cancel
                </BaseButton>
              ) : (
                <BaseButton
                  className={classnames(s.buildCommunityBtn, s.ml25)}
                  onClick={handleCreateCommunityClick}>
                  Build Community
                </BaseButton>
              )
            ) : (
              <BaseButton
                className={classnames(s.buildCommunityBtn, s.ml25)}
                onClick={handleCreateCommunityClick}>
                Build Community
              </BaseButton>
            ))}
        </nav>
      }
    </header>
  );

  const headerColorModes = {
    white: s.white,
    dark: s.dark,
  };

  const LandingPageMenuItem = () => {
    return (
      <>
        {NAV_LINKS.map((link) => {
          return (
            <BaseLink
              key={link.route}
              isNavLink={location?.routeName === link.route}
              to={routesConfig[link.route]()}
              className={classnames(
                s.pageNavLink,
                'click-area',
                textColorLandingpage ? selectColorMenuLandingPage?.[textColorLandingpage] : '',
              )}
              theme="3">
              {link.title}
            </BaseLink>
          );
        })}
      </>
    );
  };

  const selectLogoLandingPage = () => {
    if (textColorLandingpage === EMenuColor.AlternativeOne) {
      return <BaseIcon icon="landing-logo-white" />;
    }

    if (headerColorMode === 'dark') return <BaseIcon icon="landing-logo-white" />;
    if (headerColorMode === 'white') return <BaseIcon icon="landing-logo-dark" />;
  };

  const LandingPageComponent = (
    <>
      <header
        className={classnames(
          s.landingPageContainer,
          s.itemsCenter,
          textColorLandingpage ? selectBgBlueLandingPage?.[textColorLandingpage] : s.blur,
        )}>
        <nav
          className={classnames(
            s.nav_con,
            s.itemsCenter,
            s.left,
            headerColorModes[headerColorMode || 'dark'],
          )}>
          <BaseLink
            isNavLink={true}
            to={routesConfig.home()}
            className={classnames(s.logo, s.itemsCenter)}
            activeStyle={false}>
            {selectLogoLandingPage()}
          </BaseLink>

          <div className={s.landingPageMenu}>{LandingPageMenuItem()}</div>
        </nav>

        <div className={s.rightItems}>
          <div className={s.burgerButton}>
            <BurguerButton open={openButtonMenu} onClick={setOpenButtonMenu} />
          </div>
          <nav
            className={classnames(
              s.itemsCenter,
              s.nav_con,
              s.right,
              headerColorModes[headerColorMode || 'dark'],
            )}>
            {isLandingPage && <div className={s.boxIcons}>{IconsItems()}</div>}

            <Link
              to={
                window.location.href.endsWith('/scm')
                  ? routesConfig.scmMap()
                  : routesConfig.singularityMap()
              }
              className={classnames(s.startJourney)}>
              {appMode === EAppMode.Mobile ? 'Start now' : 'Start your journey'}
            </Link>
          </nav>
        </div>
      </header>
      <div className={`${s.responsiveMenu} ${openButtonMenu ? s.open : {}}`}>
        <div className={s.landingPageMenuResponsive}>{LandingPageMenuItem()}</div>
        <div className={s.socialIconsResponsive}>{IconsItems()}</div>
      </div>
    </>
  );

  if (isLandingPage) {
    return LandingPageComponent;
  } else if (isLoggedIn) {
    return LoggedComponent;
  } else {
    return NotLoggedComponent;
  }
};
