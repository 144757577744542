export const termsAndConditions = `<p>Effective date: 12 August 2022 (original effective date: 19 June 2019)</p>
<p>Updated: 12 August 2022</p>
<br/>
<p>These Terms of Service and our <u><a href="hhttps://gridsingularity.com/privacy-policy" target="_blank">Privacy Policy</a></u> describe who we are, under what terms and circumstances you can interact with us through the Grid Singularity user interface, and how we interact with you. </p>

<h3>A. Definitions</h3>
<ol>
<li>
The “Agreement” refers, collectively, to all the terms, conditions, notices contained or referenced in this document (the “Terms of Service” or the "Terms") and all other operating rules and policies available at the <u><a href="https://github.com/gridsingularity/gsy-e" target="_blank">Grid Singularity GitHub repository</a></u> and procedures that we may publish from time to time on the Website.
</li>
<li>
The “Service” refers to the applications, software, products, and services provided by Grid Singularity Pte Ltd. and its subsidiaries Grid Singularity GmbH and Grid Singularity Unipessoal, Lda., including Grid Singularity’s user interface, available at <u><a href="hhttps://gridsingularity.com" target="_blank">www.gridsingularity.com</a></u> and <u><a href="https://www.d3a.io" target="_blank">www.d3a.io</a></u> which enable energy market simulation, as well as other tools to optimise and enable energy trading, as further documented in the <u><a href="https://gridsingularity.github.io/gsy-e/documentation/" target="_blank">Grid Singularity Wiki</a></u>.
</li>
<li>
The “Website” refers to the Grid Singularity website located at <u><a href="hhttps://gridsingularity.com" target="_blank">www.gridsingularity.com</a></u> and the previous D3A website located at <u><a href="https://www.d3a.io" target="_blank">www.d3a.io</a></u> and all content, services, and products provided by Grid Singularity through the Website.
</li>
<li>
“The User,” “You,” and “Your” refer to the individual person, company, organisation that has visited or is using the Website or Service; that accessesor uses any part of the Account; or that directs the use of the Account in the performance of its functions. A User must be at least 13 years of age. Special terms may apply for other Accounts that will be provided in the next versions of the software.
</li>
<li>
“Grid Singularity,” “We,” and “Us” refer to Grid Singularity Pte Ltd including its subsidiaries Grid Singularity GmbH and Grid Singularity Unipessoal, Lda., as well as our affiliates, directors, subsidiaries, contractors, licensors, officers, agents, and employees.
</li>
<li>
“Content” refers to content featured or displayed through the Website, including without limitation text, data, articles, images, photographs, graphics, software, applications, designs, features, and other materials that are available on the Website or otherwise available through the Service. "Content" also includes Services. “User-Generated Content” is Content, written or otherwise, created or uploaded by our Users. "Your Content" is Content that you create or own.
</li>
<li>
An "Account" represents your legal relationship with Grid Singularity. A “User Account” represents an individual User’s authorization to log in to and use the Service and serves as a User’s identity.
</li>
</ol>

<h3>B. Account Terms</h3>
<ol>
<li>
<strong>Account Controls</strong><br/>
<ul>
<li>
Users. Subject to these Terms, you retain ultimate administrative control over your User Account and the Content within it.
</li>
<li>
Organisations. The "owner" of an Organisation that was created under these Terms has ultimate administrative control over that Organisation and the Content within it. Within the Service, an owner can manage User access to the Organisation’s data and projects. An Organisation may have multiple owners, but there must be at least one User Account designated as an owner of an Organisation. We will consider the first registered user from the organisation as a designated owner. If you are the owner of an Organisation under these Terms, we consider you responsible for the actions that are performed on or through that Organisation.
</li>
</ul>
</li>
<li>
<strong>Required Information</strong><br/>
You must provide a real name, organisation with which you are affiliated, and a valid email address in order to complete the signup process. Additional information may be required for the new version of the Website.
</li>
<li>
<strong>Account Requirements</strong><br/>
There are the following requirements for Grid Singularity User Accounts (at <u><a href="hhttps://gridsingularity.com" target="_blank">gridsingularity.com</a></u> and <u><a href="https://www.d3a.io" target="_blank">d3a.io</a></u>):
<ul>
<li>
You must be a human to create an Account. Accounts registered by "bots" or other automated methods are not permitted. We do not permit machine accounts.
</li>
<li>
One person or legal entity may maintain no more than one free Account.
</li>
<li>
You must be 13 or older, depending on your country’s minimum age; you are solely responsible for complying with your country’s laws.
</li>
<li>
Your login may only be used by one person — i.e. a single login may not be shared by multiple people unless they are part of a single Organisation and using the services as the Organisation. We reserve the right to limit the use of the Grid Singularity Service to a set number of accounts.
</li>
<li>
You may not use Grid Singularity Service in violation of export control or sanctions laws of any applicable jurisdiction.
</li>
</ul>
</li>
<li>
<strong>User Account Security</strong><br/>
You are responsible for keeping your Account secure while you use our Service.
<ul>
<li>
You are responsible for all content posted and activity that occurs under your Account (even when content is posted by others who have access to your Account).
</li>
<li>
You are responsible for maintaining the security of your Account and password. Grid Singularity cannot and will not be liable for any loss or damage from your failure to comply with this security obligation.
</li>
<li>
You will promptly notify us at contact@gridsingularity.com if you become aware of any unauthorised use of, or access to, our Service through your Account, including any unauthorised use of your password or Account.
</li>
</ul>
</li>
<li>
<strong>Additional Terms</strong><br/>
In some situations, third parties' terms may apply to your use of Grid Singularity Service. For example, you may be a member of an organisation with its own terms or licence agreements; you may download an application that integrates with Grid Singularity Service; or you may use Grid Singularity Service to authenticate to another service. Please be aware that while these Terms are our full agreement with you, other parties' terms govern their relationships with you.
</li>
</ol>

<h3>C. Acceptable Use</h3>
<ol>
<li>
<strong>Compliance with Laws and Regulations</strong><br/>
Your use of the Website and Service must not violate any applicable laws, including copyright or trademark laws, export control or sanctions laws, or other laws in your jurisdiction. You are responsible for making sure that your use of the Service is in compliance with laws and any applicable regulations.
</li>
<li>
<strong>Content Restrictions</strong><br/>
You agree that you will not under any circumstances upload, post, host, or transmit any content that:
<ul>
<li>
is unlawful or promotes unlawful activities;
</li>
<li>
is or contains sexually obscene content;
</li>
<li>
is libellous, defamatory, or fraudulent;
</li>
<li>
is discriminatory or abusive toward any individual or group;
</li>
<li>
gratuitously depicts or glorifies violence, including violent images;
</li>
<li>
contains or installs any active malware or exploits, or uses our platform for exploit delivery (such as part of a command and control system); or
</li>
<li>
infringes on any proprietary right of any party, including patent, trademark, trade secret, copyright, right of publicity, or other rights.
</li>
</ul>
</li>
<li>
<strong>Conduct Restrictions</strong><br/>
While using Grid Singularity Service, you agree that you will not under any circumstances:
<ul>
<li>
harass, abuse, threaten, or incite violence towards any individual or group, including Grid Singularity employees, officers, and agents, or other Grid Singularity Users;
</li>
<li>
use our servers for any form of excessive automated bulk activity (for example, spamming), or relay any other form of unsolicited advertising or solicitation through our servers, such as get-rich-quick schemes;
</li>
<li>
attempt to disrupt or tamper with Grid Singularity's servers in ways that could harm our Website or Service, to place undue burden on Grid Singularity's servers through automated means, or to access Grid Singularity's Service in ways that exceed your authorization;
</li>
<li>
impersonate any person or entity, including any of our employees or representatives, including through false association with Grid Singularity, or by fraudulently misrepresenting your identity or site's purpose; or
</li>
<li>
violate the privacy of any third party, such as by posting another person's personal information without consent.
</li>
</ul>
</li>
<li>
<strong>Services Usage Limits</strong><br/>
You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service without Grid Singularity's express written permission.
</li>
<li>
<strong>Scraping</strong><br/>
Scraping refers to extracting data from our Website via an automated process, such as a bot or webcrawler. It does not refer to the collection of information through Grid Singularity's API. You may scrape the website for the following reasons:
<ul>
<li>
Researchers may scrape public, non-personal information from Grid Singularity Service for research purposes, only if any publications resulting from that research are open access.
</li>
<li>
Archivists may scrape Grid Singularity Service for public data for archival purposes.
</li>
</ul>
You may not scrape Grid Singularity Service for spamming purposes, including for the purposes of selling Grid Singularity users' personal information, such as to recruiters, headhunters, and job boards.
<br/>
All use of data gathered through Website scraping must comply with the <u><a href="https://gridsingularity.com/privacy-policy" target="_blank">Grid Singularity Privacy Policy</a></u>.
</li>
<li>
<strong>Privacy</strong><br/>
Misuse of Grid Singularity Users' Personal Information is prohibited.
<br/<
Any person, entity, or service collecting data from Grid Singularity must comply with the Grid Singularity Privacy Policy, particularly in regard to the collection of our Users' Personal Information (as defined in the <u><a href="https://gridsingularity.com/privacy-policy" target="_blank">Grid Singularity Privacy Policy</a></u>). If you collect any Grid Singularity User's Personal Information, you agree that you will only use the Personal Information you gather for the purpose for which our User has authorised it. You agree that you will reasonably secure any Personal Information you have gathered from Grid Singularity Service, and you will respond promptly to complaints, removal requests, and "do not contact" requests from Grid Singularity Users.
</li>
<li>
<strong>Excessive Bandwidth Use</strong><br/>
If we determine your bandwidth usage to be significantly excessive in relation to other Grid Singularity users, we reserve the right to suspend your Account or limit your ability to run simulations or otherwise take up unreasonable proportions of Grid Singularity user interface bandwidth until you reduce your bandwidth consumption.
</li>
<li>
<strong>User Protection</strong><br/>
You agree not to engage in activity that significantly harms our Users. We will resolve disputes in favour of protecting our Users as a whole.
</li>
</ol>

<h3>D. User-Generated Content</h3>
<ol>
<li>
<strong>Responsibility for User-Generated Content</strong><br/>
You may create or upload User-Generated Content while using the Service. You are solely responsible for the content of, and for any harm resulting from, any User-Generated Content that you post, upload, link to or otherwise make available via the Service, regardless of the form of that Content. We are not responsible for any public display or misuse of your User-Generated Content.
</li>
<li>
<strong>Grid Singularity May Remove Content</strong><br/>
We do not pre-screen User-Generated Content, but we have the right (though not the obligation) to refuse or remove any User-Generated Content that, in our sole discretion, violates any Grid Singularity terms or policies.
</li>
<li>
<strong>Ownership of Content, Right to Post, and Licence Grants</strong><br/>
You retain ownership of and responsibility for Your Content. If you're posting anything you did not create yourself or do not own the rights to, you agree that you are responsible for any Content you post; that you will only submit Content that you have the right to post; and that you will fully comply with any third party licences relating to Content you post.
<br/>
Because you retain ownership of and responsibility for Your Content, we need you to grant us — and other Grid Singularity Service Users — certain legal permissions, listed in Sections D.4 — D.7. These licence grants apply to Your Content. If you upload Content that already comes with a licence granting Grid Singularity the permissions we need to run our Service, no additional licence is required. You understand that you will not receive any payment for any of the rights granted in Sections D.4 — D.7. The licences you grant to us will end when you remove Your Content from our servers, unless other Users have forked it.
</li>
<li>
<strong>Licence Grant to Us</strong><br/>
We need the legal right to do things like host Your Content, analyse it, and use it to improve our service. You grant us and our legal successors the right to store, parse, and display Your Content, and make incidental copies as necessary to render the Website and provide the Service. This includes the right to do things like copy it to our database and make backups; show it to you and parse it into a search index or otherwise analyse it on our servers, as well as share it with other users in full compliance of <u><a href="https://gridsingularity.com/privacy-policy" target="_blank">Grid Singularity’s Privacy Policy</a></u>.
<br/>
This licence does not grant Grid Singularity the right to sell Your Content or otherwise distribute or use it outside of our provision of the Service.
</li>
<li>
<strong>Licence Grant to Other Users</strong><br/>
Any User-Generated Content you post publicly, including issues, comments, and contributions to the <u><a href="https://github.com/gridsingularity/gsy-e" target="_blank">Grid Singularity Github repository</a></u>, may be viewed by others, in compliance with the Github Terms of Service.
</li>
<li>
<strong>Contributions Under Repository Licence</strong><br/>
Whenever you make a contribution to <u><a href="https://github.com/gridsingularity/gsy-e" target="_blank">Grid Singularity Github repository</a></u>, you are bound by the terms of the <u><a href="https://cla-assistant.io/gridsingularity/gsy-e" target="_blank">Grid Singularity Contributor’s Licence Agreement</a></u>.
</li>
<li>
<strong>Moral Rights</strong><br/>
You retain all moral rights to Your Content that you upload, publish, or submit to any part of the Service, including the rights of integrity and attribution. However, youwaive these rights and agree not to assert them against us, to enable us to reasonably exercise the rights granted in Section D.4, but not otherwise.
<br/>
To the extent this agreement is not enforceable by applicable law, you grant Grid Singularity the rights we need to use Your Content without attribution and to make reasonable adaptations of Your Content as necessary to render the Website and provide the Service.
</li>
</ol>

<h3>E. Private Libraries</h3>
<ol>
<li>
<strong>Control of Private Libraries.</strong><br/>
Some Accounts may have private libraries, which allow the User to control access to Content.
</li>
<li>
<strong>Confidentiality of Private Libraries.</strong><br/>
Grid Singularity considers the contents of private libraries to be confidential to you. Grid Singularity will protect the contents of private repositories from unauthorised use, access, or disclosure in the same manner that we would use to protect our own confidential information of a similar nature and in no event with less than a reasonable degree of care.
</li>
<li>
<strong>Access.</strong><br/>
Grid Singularity employees may only access the content of your private libraries in the following situations:
<ul>
<li>
With your consent and knowledge, for support reasons. If Grid Singularity accesses a private library for support reasons, we will only do so with the owner’s consent and knowledge.
</li>
<li>
When access is required for security reasons, including when access is required to maintain ongoing confidentiality, integrity, availability and resilience of Grid Singularity's systems and Service.
</li>
<li>
To analyse and use anonymized data uploaded by the user in order to improve the Grid Singularity Service, for instance by building template libraries, pursuant to the <u><a href="https://gridsingularity.com/privacy-policy" target="_blank">Grid Singularity Privacy Policy</a></u>.
</li>
</ul>
</li>
<li>
<strong>Exclusions.</strong><br/>
If we have reason to believe the contents of a private library are in violation of the law or of these Terms, we have the right to access, review, and remove them. Additionally, we may be compelled by law to disclose the contents of your private libraries.
</li>
</ol>

<h3>F. Copyright Infringement</h3>
If you believe that content on our website violates your copyright, please contact us by emailing contact@gridsingularity.com. There may be legal consequences for sending a false or frivolous takedown notice. Before sending a takedown request, you must consider legal uses such as fair use and licensed uses.  We will terminate the Accounts of repeat infringers of this policy.
<br/>
<br/>
<h3>G. Intellectual Property Notice</h3>
<ol>
<li>
<strong>Grid Singularity's Rights to Content</strong><br/>
Grid Singularity and our licensors, vendors, agents, and/or our content providers retain ownership of all intellectual property rights of any kind related to the Website and Service. We reserve all rights that are not expressly granted to you under this Agreement,  the <u><a href="https://github.com/gridsingularity/gsy-e/blob/master/LICENSE" target="_blank">GNU General Public Licence v3.0</a></u>, or by law. The look and feel of the Website and Service is copyright © Grid Singularity Pte. Ltd. All rights reserved. You may not duplicate, copy, or reuse any portion of the HTML/CSS, Javascript, or visual design elements or concepts without express written permission from Grid Singularity.
</li>
<li>
<strong>Grid Singularity and D3A Trademarks and Logos</strong><br/>
If you’d like to use Grid Singularity’s trademarks, you must follow all of our trademark guidelines, defined as follows:
<br/>
- You may use Grid Singularity’s logo to link to the relevant Website and/or <u><a href="https://github.com/gridsingularity/gsy-e" target="_blank">GitHub repository</a></u> and/or to note that you are using the Grid Singularity Service.
<br/>
- You may <u>not</u> use Grid Singularity’s and/or D3A logo for your own products and/or services. No adaptation or use of any kind of any of our registered trademarks or copyrights, or any other contents of this Website, is allowed without the express written permission of Grid Singularity Pte. Ltd.
</li>
</ol>

<h3>H. API Terms</h3>
No Abuse or Overuse of the application interface (API)
<br/>
Abuse or excessively frequent requests to Grid Singularity team via the API or via email may result in the temporary or permanent suspension of your Account's access to the API. Grid Singularity, in our sole discretion, will determine abuse or excessive usage of the API. We will make a reasonable attempt to warn you via email prior to suspension.
<br/>
You may not share API tokens to exceed Grid Singularity 's rate limitations.
<br/>
You may not use the API to download data, including personal data such as email addresses, or Content from Grid Singularity for advertising or spamming purposes, including for the purposes of selling Grid Singularity users' personal information, such as to recruiters, headhunters, and job boards. All use of the Grid Singularity API is subject to these Terms of Service and the <u><a href="https://gridsingularity.com/privacy-policy" target="_blank">Grid Singularity Privacy Policy</a></u>.
<br/>
Grid Singularity may offer subscription-based access to our Grid Singularity API for those Users who require high-throughput access or access that would result in resale of Grid Singularity’s Service. APIs and support of API services are subject to change at any time without notice.
<br/>

<h3>I. Payment</h3>
Our pricing and payment terms will be defined for different versions of the Grid Singularity Service. The use of the current Grid Singularity simulation version is free of charge subject to these terms, while other tools such as the Social Community Manager are charged. You may write to us at contact@gridsingularity.com for more complex service offerings based on interest and availability of both parties.
<br/>

<h3>J. Cancellation and Termination</h3>
<ol>
<li>
<strong>Account Cancellation</strong><br/>
It is your responsibility to properly cancel your Account with Grid Singularity Service. You can cancel your Account at any time by sending an email to contact@gridsingularity.com.
</li>
<li>
<strong>Upon Cancellation</strong><br/>
We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements, but barring legal requirements, we will delete your full profile and the Content of your repositories within 90 days of cancellation or termination (though some information may remain in encrypted backups). This information cannot be recovered once your Account is cancelled.  We will not delete Content that you have contributed pursuant to <u><a href="https://cla-assistant.io/gridsingularity/gsy-e" target="_blank">Contributor’s Licence Agreement</a></u> and these terms.
</li>
<li>
<strong>Website and/or Grid Singularity Service May Terminate</strong><br/>
Grid Singularity has the right to suspend or terminate your access to all or any part of the Website at any time, with or without cause, with or without notice, effective immediately. Grid Singularity reserves the right to refuse service to anyone for any reason at any time.
</li>
<li>
<strong>Survival</strong><br/>
All provisions of this Agreement which, by their nature, should survive termination will survive termination — including, without limitation: ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
</li>
</ol>

<h3>K. Communications with Grid Singularity</h3>
<ol>
<li>
<strong>Electronic Communication Required</strong><br/>
For contractual purposes, you (1) consent to receive communications from us in an electronic form via the email address you have submitted; and (2) agree that all Terms of Service, agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that those communications would satisfy if they were on paper. This section does not affect your non-waivable rights.
</li>
<li>
<strong>Legal Notice to Grid Singularity Must Be in Writing</strong><br/>
Communications made through email or Grid Singularity Support's messaging system will not constitute legal notice to Grid Singularity or any of its officers, employees, agents or representatives in any situation where notice to Grid Singularity is required by contract or any law or regulation. Legal notice to Grid Singularity must be in writing and sent as a signed electronic document to contact@gridsingularity.com.
</li>
<li>
<strong>No Phone Support</strong><br/>
Grid Singularity offers support via email, <u><a href="https://gsycommunity.slack.com/join/shared_invite/zt-dpfxe2gk-hKFccsrr68g982odCJlrIg#/shared-invite/email" target="_blank">Grid Singularity Community Slack</a></u> and provides additional information on the <u><a href="https://github.com/gridsingularity/gsy-e" target="_blank">Grid Singularity GitHub Repository</a></u>. We do not offer telephone support.
</li>
</ol>

<h3>L. Disclaimer of Warranties</h3>
Grid Singularity provides the Website and the Service “as is” and “as available,” without warranty of any kind. Without limiting this, we expressly disclaim all warranties, whether express, implied or statutory, regarding the Website and the Service including without limitation any warranty of merchantability, fitness for a particular purpose, title, security, accuracy and non-infringement.
<br/>
Grid Singularity does not warrant that the Service will meet your requirements; that the Service will be uninterrupted, timely, secure, or error-free; that the information provided through the Service is accurate, reliable or correct; that any defects or errors will be corrected; that the Service will be available at any particular time or location; or that the Service is free of viruses or other harmful components. You assume full responsibility and risk of loss resulting from your downloading and/or use of files, information, content or other material obtained from the Service.
<br/>

<h3>M. Limitation of Liability</h3>
You understand and agree that we will not be liable to you or any third party for any loss of profits, use, goodwill, or data, or for any incidental, indirect, special, consequential or exemplary damages, however arising, that result from
<ul>
<li>
the use, disclosure, or display of your User-Generated Content;
</li>
<li>
your use or inability to use the Service;
</li>
<li>
any modification, price change, suspension or discontinuance of the Service;
</li>
<li>
the Service generally or the software or systems that make the Service available;
</li>
<li>
unauthorised access to or alterations of your transmissions or data;
</li>
<li>
statements or conduct of any third party on the Service;
</li>
<li>
any other user interactions that you input or receive through your use of the Service; or
</li>
<li>
any other matter relating to the Service.
</li>
</ul>
Our liability is limited whether or not we have been informed of the possibility of such damages, and even if a remedy set forth in this Agreement is found to have failed of its essential purpose. We will have no liability for any failure or delay due to matters beyond our reasonable control.
<br/>

<h3>N. Release and Indemnification</h3>
If you have a dispute with one or more Users, you agree to release Grid Singularity from any and all claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes.
<br/>
You agree to indemnify us, defend us, and hold us harmless from and against any and all claims, liabilities, and expenses, including attorneys’ fees, arising out of your use of the Website and the Service, including but not limited to your violation of this Agreement, provided that Grid Singularity (1) promptly gives you written notice of the claim, demand, suit or proceeding; (2) gives you sole control of the defence and settlement of the claim, demand, suit or proceeding (provided that you may not settle any claim, demand, suit or proceeding unless the settlement unconditionally releases Grid Singularity of all liability); and (3) provides to you all reasonable assistance, at your expense.
<br/>

<h3>O. Changes to These Terms</h3>
We reserve the right, at our sole discretion, to amend these Terms of Service at any time and will update these Terms of Service in the event of any such amendments. We will notify our Users of material changes to this Agreement, such as price changes, at least 30 days prior to the change taking effect by posting a notice on our Website. For non-material modifications, your continued use of the Website constitutes agreement to our revisions of these Terms of Service.We reserve the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Website (or any part of it) with or without notice.
<br/>

<h3>P. Miscellaneous</h3>
<ol>
<li>
<strong>Governing Law</strong><br/>
Except to the extent applicable law provides otherwise, this Agreement between you and Grid Singularity and any access to or use of the Website or the Service are governed by the laws of Singapore, without regard to conflict of law provisions. You and Grid Singularity agree to submit to the exclusive jurisdiction and venue of the courts located in Singapore.
</li>
<li>
<strong>Non-Assignability</strong><br/>
Grid Singularity may assign or delegate these Terms of Service and/or the <u><a href="https://gridsingularity.com/privacy-policy" target="_blank">Grid Singularity Privacy Policy</a></u>, in whole or in part, to any person or entity at any time with or without your consent, including the licence grant in Section D.4. You may not assign or delegate any rights or obligations under the Grid Singularity Terms of Service or the <u><a href="https://gridsingularity.com/privacy-policy" target="_blank">Grid Singularity Privacy Policy</a></u> without our prior written consent, and any unauthorised assignment and delegation by you is void.
</li>
<li>
<strong>Section Headings and Summaries</strong><br/>
Throughout this Agreement, each section includes titles and brief summaries of the following terms and conditions. These section titles and brief summaries are not legally binding.
</li>
<li>
<strong>Severability, No Waiver, and Survival</strong><br/>
If any part of this Agreement is held invalid or unenforceable, that portion of the Agreement will be construed to reflect the parties’ original intent. The remaining portions will remain in full force and effect. Any failure on the part of Grid Singularity to enforce any provision of this Agreement will not be considered a waiver of our right to enforce such provision. Our rights under this Agreement will survive any termination of this Agreement.
</li>
<li>
<strong>Amendments; Complete Agreement</strong><br/>
This Agreement may only be modified by a written amendment signed by an authorised representative of Grid Singularity, or by the posting by Grid Singularity of a revised version. These Terms of Service, together with the <u><a href="https://gridsingularity.com/privacy-policy" target="_blank">Grid Singularity Privacy Policy</a></u>, represent the complete and exclusive statement of the agreement between you and us. This Agreement supersedes any proposal or prior agreement oral or written, and any other communications between you and Grid Singularity relating to the subject matter of these terms including any confidentiality or nondisclosure agreements.
</li>
<li>
<strong>Questions</strong><br/>
For any questions about the Terms of Service, please email us at contact@gridsingularity.com.
</li>
</ol>

`;
