import { ContextMenu, TContextMenuProps } from 'src/components/ContextMenu';
import React, { useState } from 'react';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';

import { ArrayElement } from 'src/typings/base-types';
import { BaseIcon } from 'src/components/BaseIcon';
import { DashboardUserCollapsedListProps } from 'src/components/AdminDashboards/DashboardUser';
import { InfoHelper } from 'src/components/InfoHelper';
import { TAsset } from 'src/typings/configuration.types';
import { TAssetInside } from 'src/components/AssetsInside';
import classNames from 'classnames';
import s from './DashboardUser.module.scss';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { useAppDispatch } from 'src/redux/store';
import { useMergedState } from 'src/hooks/useMergedState';
import { useModal } from 'src/hooks/useModal';
import { useSelector } from 'react-redux';

const ListItem = (data: ArrayElement<DashboardUserCollapsedListProps['items']>) => {
  const dispatch = useAppDispatch();
  const isAsset = data.assetType === 'Assets';
  const { id: contextMenuModalId } = useModal();
  const isContextMenuOpened = useSelector(selectIsModalOpened(contextMenuModalId));
  const [contextMenuState, setContextMenuState] = useMergedState<{
    modalRef: React.RefObject<HTMLElement | null>;
    assetUuid: TAsset['uuid'] | undefined;
  }>({ modalRef: { current: null }, assetUuid: '' });

  const closeContextMenu = () => dispatch(closeModal(contextMenuModalId));

  const onThreeDotsClick = ({ target, asset }) => {
    if (isContextMenuOpened) {
      closeContextMenu();
    } else {
      setContextMenuState({
        modalRef: { current: target as HTMLElement },
        assetUuid: (asset as TAssetInside).uuid,
      });
      dispatch(openModal(contextMenuModalId));
    }
  };

  const contextMenuItems: TContextMenuProps['items'] = [
    {
      title: 'Launch to Canary',
      icon: 'canary',
      onClick: () => {
        if (contextMenuState.assetUuid) {
          closeContextMenu();
        }
      },
    },
  ];

  return (
    <>
      <div className={s.listItem}>
        <BaseIcon icon={data.icon} size={70} />
        <h5>{data.title}</h5>
        {!isAsset && (
          <button
            onClick={(e) => onThreeDotsClick({ target: e.target as HTMLElement, asset: data })}
            type="button"
            className={s.threeDots}>
            <BaseIcon icon="more-horizontal" size={10} />
          </button>
        )}

        {data?.description && (
          <InfoHelper className={s.infoButton} size={8} info={data?.description} />
        )}
        <div>
          {data.subtitles.map((subtitle, i) => (
            <span key={i}>{subtitle}</span>
          ))}
        </div>
      </div>
      {isContextMenuOpened && (
        <ContextMenu
          relativeElement={contextMenuState.modalRef}
          modalId={contextMenuModalId}
          position={{
            side: 'bottom',
            anchorPosition: 50,
          }}
          items={contextMenuItems}
        />
      )}
    </>
  );
};

export const DashboardUserCollapsedList: React.FC<DashboardUserCollapsedListProps> = ({
  className,
  title,
  items,
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const assetType = title.split(' ')[0];

  return (
    <div
      className={classNames(s.collapsedList, className, {
        [s.collapsed]: collapsed,
      })}>
      <header onClick={() => setCollapsed(!collapsed)}>
        <h3>
          {title} ({items.length})
        </h3>
        <BaseIcon className={s.collapseArrow} icon="arrow" size={15} />
      </header>
      {!collapsed && (
        <div className={s.list}>
          {items.map((item, i) => (
            <ListItem key={i} {...item} assetType={assetType} />
          ))}
        </div>
      )}
    </div>
  );
};
