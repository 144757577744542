import { Fade, Zoom } from 'react-reveal';

import { BlueBox } from 'src/components/LandingPage/BlueBox';
import React from 'react';
import { TProps } from './Market.types';
import { ToolTip } from 'src/pages/MarketDesign/components/ToolTip';
import basePlaqueOneMarket from 'src/assets/images/LandingPage/marketDesign/layerTwo_markets/base-plaque-one.png';
import basePlaqueTwoMarket from 'src/assets/images/LandingPage/marketDesign/layerTwo_markets/base-plaque-two.png';
import classnames from 'classnames';
import linesPlaqueMarket from 'src/assets/images/LandingPage/marketDesign/layerTwo_markets/lines-plaque.png';
import linksPlaqueMarket from 'src/assets/images/LandingPage/marketDesign/layerTwo_markets/links-plaque.png';
import marketIconMarket from 'src/assets/images/LandingPage/marketDesign/layerTwo_markets/market.png';
import middlePlaqueMarket from 'src/assets/images/LandingPage/marketDesign/layerTwo_markets/middle-plaque.png';
import s from './Market.module.scss';
import topPlaqueMarket from 'src/assets/images/LandingPage/marketDesign/layerTwo_markets/top-plaque.png';

export const BlueBoxOne: React.FC<TProps> = ({ show }) => {
  return (
    <BlueBox
      title="Bottom-up Market Design"
      description="Decentralised, asset-based market design enables trading at local levels, scaling up to
            higher municipal and national levels by connecting markets into a transactive grid."
      show={show}
    />
  );
};

export const BlueBoxTwo: React.FC<TProps> = ({ show }) => {
  return (
    <BlueBox
      title="Local First"
      description="Locally generated energy is prioritised in this decentralised market structure,
    increasing resilience and sustainability for the grid and the communities."
      show={show}
    />
  );
};

export const BlueBoxThree: React.FC<TProps> = ({ show }) => {
  return (
    <BlueBox
      title="Grid-aware, advanced system"
      description="Markets incorporate grid topology to enable granular, dynamic grid accounting and
    integrated auxiliary services."
      show={show}
    />
  );
};

export const BlueBoxFour: React.FC<TProps> = ({ show }) => {
  return (
    <BlueBox
      title="New Degrees of Freedom"
      description="Trades take place across local, municipal and national markets, creating new
    possibilities and expanding your choices of energy source, type, trading partner..."
      show={show}
    />
  );
};

export const MarketOne: React.FC<TProps> = ({ show }) => {
  return (
    <>
      <Zoom opposite collapse={!show} when={show} duration={1000}>
        <img src={basePlaqueOneMarket} className={classnames(s.imageFloating, s.basePlaqueOne)} />
      </Zoom>
      <Fade opposite collapse={!show} when={show} delay={1000}>
        <img src={linesPlaqueMarket} className={classnames(s.imageFloating, s.linesPlaque)} />
      </Fade>
      <Fade top opposite collapse={!show} when={show} delay={1500} duration={1000}>
        <img src={middlePlaqueMarket} className={classnames(s.imageFloating, s.middlePlaque)} />
      </Fade>
      <Fade opposite collapse={!show} when={show} delay={2000}>
        <img src={linksPlaqueMarket} className={classnames(s.imageFloating, s.linksPlaque)} />
      </Fade>
      <Fade top opposite collapse={!show} when={show} delay={2500} duration={1000}>
        <img
          src={topPlaqueMarket}
          className={classnames(s.imageFloating, s.topPlaque)}
          alt="fractuals"
        />
      </Fade>
    </>
  );
};

export const Voltage: React.FC<TProps> = ({ show, scrollTo, currentLayer, scrolling }) => {
  return (
    <>
      <Fade opposite collapse={!show} when={show} duration={2000}>
        <div className={s.boxVoltage}>
          <span>Voltage Level</span>
          <div className={s.voltage}>
            <button
              type="button"
              className={`${currentLayer === 6 ? s.actived : ''}`}
              disabled={scrolling}
              onClick={() => scrollTo(6)}>
              LOW VOLTAGE
            </button>
            <button
              type="button"
              className={`${currentLayer === 7 ? s.actived : ''}`}
              disabled={scrolling}
              onClick={() => scrollTo(7)}>
              MEDIUM VOLTAGE
            </button>
            <button
              type="button"
              className={`${currentLayer === 8 ? s.actived : ''}`}
              disabled={scrolling}
              onClick={() => scrollTo(8)}>
              HIGH VOLTAGE
            </button>
          </div>
        </div>
      </Fade>
    </>
  );
};

export const TooltipMarketTwo: React.FC<TProps> = ({ show }) => {
  return (
    <Fade top opposite collapse={!show} when={show} duration={1000}>
      <div className={s.marketTwoTooltip}>
        <ToolTip
          inline={true}
          position="top"
          title={
            <div style={{ width: '100%', display: 'flex', flex: 1, flexDirection: 'column' }}>
              <div>
                <img src={marketIconMarket} />
                <span>Second Level Market</span>
              </div>
              <div className={s.descriptionTooltip}>Neighbourhood / Town</div>
            </div>
          }
        />
      </div>
    </Fade>
  );
};

export const MarketTwo: React.FC<TProps> = ({ show }) => {
  return (
    <>
      {show && (
        <img src={basePlaqueTwoMarket} className={classnames(s.imageFloating, s.plaqueMarketTwo)} />
      )}
    </>
  );
};

export const GrayBox: React.FC<TProps> = ({ show }) => {
  return (
    <>
      <Fade opposite collapse={!show} when={show} duration={1000}>
        <div className={s.grayBox}>
          <div className={s.grayBoxText}>
            <p>
              Markets may contain multiple exchanges like spot or balancing. See {''}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://gridsingularity.com/resources/trending">
                Resources
              </a>{' '}
              to learn more.
            </p>
          </div>
        </div>
      </Fade>
    </>
  );
};

export const TooltipMarketThree: React.FC<TProps> = ({ show }) => {
  return (
    <Fade top opposite collapse={!show} when={show} duration={1000} delay={2000}>
      <div className={s.marketThreeTooltip}>
        <ToolTip
          inline={true}
          position="top"
          title={
            <>
              <div>
                <img src={marketIconMarket} />
                <span>Third Level Market</span>
              </div>
              <br />
              <div className={s.descriptionTooltip}>City and Intercity Markets</div>
            </>
          }
        />
      </div>
    </Fade>
  );
};

export const MarketThree: React.FC<TProps> = ({ show }) => {
  return (
    <>
      {show && (
        <img
          src={basePlaqueTwoMarket}
          className={classnames(s.imageFloating, s.basePlaqueMarket)}
        />
      )}
      <Fade opposite collapse={!show} when={show} delay={500}>
        <img src={linesPlaqueMarket} className={classnames(s.imageFloating, s.linesMarketThree)} />
      </Fade>
      <Fade top opposite collapse={!show} when={show} delay={1000}>
        <img
          src={middlePlaqueMarket}
          className={classnames(s.imageFloating, s.topPlaqueMarketThree)}
        />
      </Fade>
    </>
  );
};

export const TooltipNextTooltip: React.FC<TProps> = ({ show }) => {
  return (
    <Fade top opposite collapse={!show} when={show} duration={1000} delay={3000}>
      <div className={s.marketNextTooltip}>
        <ToolTip
          inline={true}
          position="top"
          title={
            <>
              <div>
                <img src={marketIconMarket} />
                <span>Next Levels</span>
              </div>
              <br />
              <div className={s.descriptionTooltip}>Region, Country, Global Markets</div>
            </>
          }
        />
      </div>
    </Fade>
  );
};

export const MarketNextLevel: React.FC<TProps> = ({ show }) => {
  return (
    <>
      {show && (
        <img src={basePlaqueTwoMarket} className={classnames(s.imageFloating, s.basePlaqueNext)} />
      )}
      {show && (
        <img src={linesPlaqueMarket} className={classnames(s.imageFloating, s.linesPlaqueNext)} />
      )}
      {show && (
        <img src={middlePlaqueMarket} className={classnames(s.imageFloating, s.middlePlaqueNext)} />
      )}
      <Fade opposite collapse={!show} when={show} delay={500}>
        <img src={linksPlaqueMarket} className={classnames(s.imageFloating, s.linksPlaqueNext)} />
      </Fade>
      <Fade top opposite collapse={!show} when={show} delay={1000}>
        <img
          src={topPlaqueMarket}
          className={classnames(s.imageFloating, s.topPlaque)}
          alt="fractuals"
        />
      </Fade>
    </>
  );
};
