import { BaseButtonOrLink } from 'src/components/BaseButtonOrLink';
import React from 'react';
import { TBaseLinkProps } from './BaseLink.types';
import classnames from 'classnames';
import s from './BaseLink.module.scss';

export const BaseLink: React.FC<TBaseLinkProps> = ({
  children,
  className,
  activeStyle = 'underlined',
  theme = '1',
  isActive,
  ...rest
}) => {
  return (
    <BaseButtonOrLink
      className={classnames(s.container, className, {
        [s[`active-style-${activeStyle}`]]: activeStyle,
        [s[`theme-${theme}`]]: theme,
        active: isActive,
      })}
      {...rest}>
      {children}
    </BaseButtonOrLink>
  );
};
