import { MutableRefObject, useRef } from 'react';

type TBuildings = {
  buildings: MutableRefObject<Array<GeoJSON.Feature>>;
  findByUuid(uuid: string): boolean;
  add(selectedBuildings: Array<GeoJSON.Feature>): void;
  remove(uuid: string): Array<GeoJSON.Feature>;
  reset(): void;
};

export const useBuildings = (): TBuildings => {
  const buildings = useRef<Array<GeoJSON.Feature>>([]);

  // Check if building is already exist by uuid property
  const findByUuid = (uuid: string): boolean => {
    return buildings.current.find((building) => building['uuid'] === uuid) !== undefined;
  };

  // Add new buildings
  const add = (newBuildings: Array<GeoJSON.Feature>): void => {
    buildings.current = [...buildings.current, ...newBuildings];
  };

  // Remove buildings by uuid
  const remove = (uuid: string): Array<GeoJSON.Feature> => {
    buildings.current = buildings.current.filter((building) => building['uuid'] !== uuid);
    return buildings.current;
  };

  // Remove all buildings
  const reset = (): void => {
    buildings.current = [];
  };

  return { buildings, findByUuid, add, remove, reset };
};
