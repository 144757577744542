import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { isEqual, unionWith } from 'lodash';

import { LocationBoundaryOutput } from 'src/graphql';
import { TMapboxLocation } from 'src/services/api/mapbox.api';

export type TMapState = {
  is3DView: boolean;
  visibleLocation: TMapboxLocation[];
  selectedLocation: Partial<TMapboxLocation> | null;
  globalSimulationLocations: LocationBoundaryOutput[];
};

const initialState: TMapState = {
  is3DView: false,
  visibleLocation: [],
  selectedLocation: null,
  globalSimulationLocations: [],
};

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    update3DView(state, action: PayloadAction<TMapState['is3DView']>) {
      state.is3DView = action.payload;
    },
    updateVisibleLocation(state, action: PayloadAction<TMapboxLocation[]>) {
      state.visibleLocation = action.payload;
    },
    updateSelectedLocation(state, action: PayloadAction<TMapState['selectedLocation']>) {
      state.selectedLocation = action.payload;
    },
    updateSimulations(state, action: PayloadAction<LocationBoundaryOutput[]>) {
      return {
        ...state,
        globalSimulationLocations: unionWith(
          state.globalSimulationLocations,
          action.payload,
          isEqual,
        ),
      };
    },
  },
});

// Actions
export const {
  updateSelectedLocation,
  update3DView,
  updateSimulations,
  updateVisibleLocation,
} = mapSlice.actions;

// Reducer
export const mapReducer = mapSlice.reducer;
