import React, { useEffect } from 'react';
import { closeModal, openModal } from '../../redux/modals/modals.slice';
import {
  selectActiveConfigurationJobUuid,
  selectActiveConfigurationUuid,
} from 'src/redux/configuration/configuration.selectors';

import { EPredefinedModalIds } from '../../constants/modals';
import { TModalRunSimCtaProps } from './ModalRunSimCta.types';
import { useAppDispatch } from 'src/redux/store';
import { useConfigurationFlowState } from 'src/hooks/useConfigurationFlowState';
import { useSelector } from 'react-redux';
import { useSimulationButtons } from 'src/hooks/useSimulationButtons';

export const ModalRunSimCta: React.FC<TModalRunSimCtaProps> = () => {
  const { modalRunSimulationShow } = useConfigurationFlowState();
  const dispatch = useAppDispatch();
  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
  const activeConfigurationJobUuid = useSelector(selectActiveConfigurationJobUuid);

  const { runButtonState } = useSimulationButtons({
    jobUuid: activeConfigurationJobUuid,
    configurationUuid: activeConfigurationUuid,
  });

  /*const handleClick = async () => {
    if (!store.getState().auth.tokenAuth) {
      dispatch(openModal(EPredefinedModalIds.MODAL_AUTH_LOGIN));
    } else if (activeConfigurationUuid) {
      if (runButtonOptions.onClick) runButtonOptions.onClick();
    }
  };*/

  useEffect(() => {
    if (modalRunSimulationShow && runButtonState === 'run') {
      dispatch(openModal(EPredefinedModalIds.MODAL_RUN_SIMULATION_CTA));
    } else {
      dispatch(closeModal(EPredefinedModalIds.MODAL_RUN_SIMULATION_CTA));
    }
  }, [dispatch, modalRunSimulationShow, runButtonState]);

  return null;
  // <BaseGenericModal
  //   id={EDomIds.MODAL_RUN_SIMULATION_CTA}
  //   className={classnames(s.modal, { [s.sidebarOpen]: sidebarOpen })}
  //   modalId={EPredefinedModalIds.MODAL_RUN_SIMULATION_CTA}
  //   size={EModalSize.XL480}
  //   modalRef={modalRef}>
  //   <div className={s.modalWrapper}>
  //     <div className={s.darkWrapper}>
  //       <img className={s.image} src={GraphImage} alt="" />
  //       <div className={s.info}>
  //         <h3 className={s.title}>Run Simulation</h3>
  //         <p className={s.description}>Know consumption, volume and more.</p>
  //         <ButtonRunSimulation
  //           onClick={handleClick}
  //           disabled={runButtonOptions.disabled}
  //           theme="3"
  //         />
  //       </div>
  //     </div>
  //   </div>
  // </BaseGenericModal>
};
