import { Threebox } from 'threebox-plugin';

import {
  TextureLoader,
  PlaneBufferGeometry,
  Color,
  CircleBufferGeometry,
  MeshBasicMaterial,
  Mesh,
  Object3D,
  BoxGeometry,
  RepeatWrapping,
  ShaderMaterial,
  Vector2,
  DoubleSide,
  LatheGeometry,
  Material,
  Texture,
  Camera,
  Vector3,
} from 'src/three';

import assetPinBase from '../assets/textures/asset_pin_base.png';
import pixelSorting from '../assets/textures/pixel_sorting.jpg';
import times from 'lodash/times';

// Shader for pin tower's transparent gradient material
export const PIN_TOWER_GRADIENT_VERT_SHADER = `
varying vec2 vUv;

void main() {
  vUv = uv;
  gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
}
`;

export const PIN_TOWER_GRADIENT_TEXTURE_FRAG_SHADER = `
uniform vec3 color1;
uniform vec3 color2;
uniform float forceAlpha;
uniform vec2 uvOffset;
uniform sampler2D texture;

varying vec2 vUv;

void main() {
  vec4 baseColor = texture2D(texture, vUv + uvOffset);

  // y < 0 = transparent, > 1 = opaque
  float alpha = (1.0 - smoothstep(0.0, 1.0, vUv.y)) * forceAlpha;

  // y < 1 = color1, > 2 = color2
  float colorMix = smoothstep(0.0, 1.0, vUv.y * 2.0);

  if(vUv.y <= 0.01) {
    alpha = 1.0;
    gl_FragColor = vec4(color1, alpha);
  } else {
    gl_FragColor = vec4((baseColor + vec4(mix(color1, color2, colorMix), 1)).rgb, alpha);
  }
}
`;

export const PIN_TOWER_GRADIENT_NON_TEXTURE_FRAG_SHADER = `
uniform vec3 color1;
uniform vec3 color2;
uniform float forceAlpha;

varying vec2 vUv;

void main() {
  // y < 0 = transparent, > 1 = opaque
  float alpha = (1.0 - smoothstep(0.0, 1.0, vUv.y)) * forceAlpha;

  // y < 1 = color1, > 2 = color2
  float colorMix = smoothstep(0.0, 1.0, vUv.y * 2.0);

  if(vUv.y <= 0.01) {
    alpha = 1.0;
    gl_FragColor = vec4(color1, alpha);
  } else {
    gl_FragColor = vec4(mix(color1, color2, colorMix), alpha);
  }
}
`;

// Shader for asset pin tower's transparent gradient material
export const ASSET_PIN_TOWER_GRADIENT_TEXTURE_FRAG_SHADER = `
uniform vec3 color1;
uniform vec3 color2;
uniform float forceAlpha;
uniform vec2 uvOffset;
uniform sampler2D texture;

varying vec2 vUv;

void main() {
  vec4 baseColor = texture2D(texture, vUv + uvOffset);

  // y < 0 = transparent, > 1 = opaque
  float alpha = (1.0 - smoothstep(0.0, 1.0, vUv.y)) * forceAlpha;

  // y < 1 = color1, > 2 = color2
  float colorMix = smoothstep(0.0, 1.0, vUv.y * 2.0);

  gl_FragColor = vec4((baseColor + vec4(mix(color1, color2, colorMix), 1)).rgb, alpha);
}
`;

export const ASSET_PIN_TOWER_GRADIENT_NON_TEXTURE_FRAG_SHADER = `
uniform vec3 color1;
uniform vec3 color2;
uniform float forceAlpha;

varying vec2 vUv;

void main() {
  // y < 0 = transparent, > 1 = opaque
  float alpha = (1.0 - smoothstep(0.0, 1.0, vUv.y)) * forceAlpha;

  // y < 1 = color1, > 2 = color2
  float colorMix = smoothstep(0.0, 1.0, vUv.y * 2.0);

  gl_FragColor = vec4(mix(color1, color2, colorMix), alpha);
}
`;

// Shader for ringbase gradient material
export const RINGBASE_VERT_SHADER = `
uniform vec3 bboxMin;
uniform vec3 bboxMax;

varying vec2 vUv;

void main() {
  vUv.x = (position.x - bboxMin.x) / (bboxMax.x - bboxMin.x);
  vUv.y = (position.y - bboxMin.y) / (bboxMax.y - bboxMin.y);

  gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
}
`;

export const RINGBASE_FRAG_SHADER = `
uniform vec3 color1;
uniform vec3 color2;

varying vec2 vUv;

void main() {
  float alpha = 0.4;

  if(vUv.y >= 0.8) {
    alpha = 1.0;
    gl_FragColor = vec4(mix(color1, color2, vUv.x), alpha);
  } else {
    gl_FragColor = vec4(color2, alpha);
  }
}
`;

// Shader for gradient material based on y axis
export const COORD_Y_GRADIENT_VERT_SHADER = `
uniform vec3 bboxMin;
uniform vec3 bboxMax;

varying vec2 vUv;

void main() {
  vUv.y = (position.y - bboxMin.y) / (bboxMax.y - bboxMin.y);
  gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
}
`;

export const COORD_Y_GRADIENT_FRAG_SHADER = `
uniform vec3 color1;
uniform vec3 color2;
uniform float colorMixFactor;

varying vec2 vUv;

void main() {
  gl_FragColor = vec4(colorMixFactor * mix(color1, color2, vUv.y), 1.0);
}
`;

// Type of gradient step
export type TGradientColorStepProps = {
  stop: number;
  color: Color;
};

// Type of tb circle
export type TTBCircleProps = {
  tb: Threebox;
  color: Color;
};

// Type of tb pin
export type TTBPinProps = {
  tb: Threebox;
  pinGradientStartColor: Color;
  pinGradientEndColor: Color;
  baseGradientStartColor?: Color;
  baseGradientEndColor?: Color;
  positive?: boolean | null;
};

// Type of tb pin
export type TTBRingBaseProps = {
  tb: Threebox;
  gradientStartColor: Color;
  gradientEndColor: Color;
  radius: number;
  height: number;
  thickness: number;
};

// Type of ring base
export type TRingBaseProps = {
  outRadius: number;
  height: number;
  gradientStartColor: Color;
  gradientEndColor: Color;
};

// Type of ring lathe
export type TRingLatheProps = {
  R: number;
  r?: number;
  h: number;
};

export const assetPinBaseTexture = new TextureLoader().load(assetPinBase);

export const pixelSortingTexture = new TextureLoader().load(pixelSorting);
pixelSortingTexture.wrapS = RepeatWrapping;
pixelSortingTexture.wrapT = RepeatWrapping;
pixelSortingTexture.repeat.set(1, 1);

/**
 * Generate tb circle plot
 * @param params
 * @returns tb circle
 */
export const generateTBCircle = (params: TTBCircleProps): Threebox.Object3D => {
  const { tb, color } = params;

  const radius = 1;
  const geometry = new CircleBufferGeometry(radius, 16);
  const material = new MeshBasicMaterial({ color });
  let circle = new Mesh(geometry, material);
  circle.position.x -= radius;
  circle.position.y -= radius;
  circle.position.z += Math.floor(Math.random() * 10000) * 0.0001;
  circle = tb.Object3D({ obj: circle, bbox: false, tooltip: false });

  return circle;
};

/**
 * Generate pin
 * @param params
 * @returns tb pin
 */
export const generateTBPin = (params: TTBPinProps): Threebox.Object3D => {
  const {
    tb,
    pinGradientStartColor,
    pinGradientEndColor,
    baseGradientStartColor,
    baseGradientEndColor,
    positive,
  } = params;

  const ringOutRadius = 100;
  const ringHeight = 30;
  let pin = new Object3D();
  const ringBase = generateRingBase({
    outRadius: ringOutRadius,
    height: ringHeight,
    gradientStartColor: baseGradientStartColor,
    gradientEndColor: baseGradientEndColor,
  });

  const hiddenTowerBaseGeo = new CircleBufferGeometry(ringOutRadius, 16);
  const hiddenTowerBaseMat = new MeshBasicMaterial();

  const hiddenTowerMesh = new Mesh(hiddenTowerBaseGeo, hiddenTowerBaseMat);
  hiddenTowerMesh.position.y -= ringHeight / 2;
  hiddenTowerMesh.rotateX(Math.PI / 2);
  hiddenTowerMesh.visible = false;
  ringBase.add(hiddenTowerMesh);

  const tower = new Object3D();

  // Add tower
  const towerWidth = 70;
  const towerHeight = 1000;
  const towerDepth = 10;

  const towerGeo = new BoxGeometry(towerWidth, towerHeight, towerDepth, 1);
  // Remove top&bottom face
  towerGeo?.index?.array?.fill(0, 12, 17);
  towerGeo?.index?.array?.fill(0, 18, 23);

  const towerMat = new ShaderMaterial({
    uniforms: {
      color1: {
        value: pinGradientStartColor,
      },
      color2: {
        value: pinGradientEndColor,
      },
      forceAlpha: {
        value: 0.8,
      },
      texture: { type: 't', value: pixelSortingTexture },
      uvOffset: { type: 'v', value: new Vector2(0, 0) },
    },
    vertexShader: PIN_TOWER_GRADIENT_VERT_SHADER,
    fragmentShader:
      positive !== null
        ? PIN_TOWER_GRADIENT_TEXTURE_FRAG_SHADER
        : PIN_TOWER_GRADIENT_NON_TEXTURE_FRAG_SHADER,
    transparent: true,
  });
  const hTower = new Mesh(towerGeo, towerMat);
  hTower.position.z += towerHeight / 2;
  hTower.rotateX(Math.PI / 2);
  hTower.name = 'pin-tower';

  tower.add(hTower);
  if (positive) {
    const vTower = hTower.clone();
    vTower.rotateY(Math.PI / 2);
    tower.add(vTower);
  } else if (positive === null) {
    hTower.scale.set(0.5, 1, 3.5);
  }

  pin.add(ringBase);
  pin.add(tower);

  pin.position.x -= ringOutRadius;
  pin.position.y -= ringOutRadius;
  pin = tb.Object3D({ obj: pin, bbox: false, tooltip: false });
  pin.setAnchor('center');

  return pin;
};

/**
 * Generate asset pin
 * @param params
 * @returns tb pin
 */
export const generateTBAssetPin = (params: TTBPinProps): Threebox.Object3D => {
  const { tb, pinGradientStartColor, pinGradientEndColor, positive } = params;

  const baseWidth = 300;
  let assetPin = new Object3D();

  // Add base
  const bigBaseGeo = new PlaneBufferGeometry(baseWidth, baseWidth);
  const baseMat = new MeshBasicMaterial({
    map: assetPinBaseTexture,
    transparent: true,
    depthWrite: false,
  });
  const baseMesh = new Mesh(bigBaseGeo, baseMat);

  // Add tower
  const towerWidth = 50;
  const towerHeight = 1000;
  const towerGeo = new BoxGeometry(towerWidth, towerHeight, towerWidth, 1);
  // Remove top&bottom face
  towerGeo?.index?.array?.fill(0, 12, 17);
  towerGeo?.index?.array?.fill(0, 18, 23);

  const towerMat = new ShaderMaterial({
    uniforms: {
      color1: {
        value: pinGradientStartColor,
      },
      color2: {
        value: pinGradientEndColor,
      },
      forceAlpha: {
        value: 0.4,
      },
      texture: { type: 't', value: pixelSortingTexture },
      uvOffset: { type: 'v', value: new Vector2(0, 0) },
    },
    vertexShader: PIN_TOWER_GRADIENT_VERT_SHADER,
    fragmentShader:
      positive !== null
        ? ASSET_PIN_TOWER_GRADIENT_TEXTURE_FRAG_SHADER
        : ASSET_PIN_TOWER_GRADIENT_NON_TEXTURE_FRAG_SHADER,
    transparent: true,
    depthWrite: false,
  });

  const towerMesh = new Mesh(towerGeo, towerMat);
  towerMesh.position.z += towerHeight / 2;
  towerMesh.rotateX(Math.PI / 2);
  towerMesh.name = 'pin-tower';

  assetPin.add(baseMesh);
  assetPin.add(towerMesh);

  assetPin.position.x -= baseWidth / 2;
  assetPin.position.y -= baseWidth / 2;
  assetPin = tb.Object3D({ obj: assetPin, bbox: false, tooltip: false });
  assetPin.setAnchor('center');

  return assetPin;
};

/**
 * Generate tb ringbase
 * @param params
 * @returns tb ring (used for community ring)
 */
export const generateTBRingBase = (params: TTBRingBaseProps): Threebox.Object3D => {
  const { tb, gradientStartColor, gradientEndColor, radius, height } = params;

  const ringOutRadius = radius;
  const ringHeight = height;
  let ringBaseContainer = new Object3D();
  const ringBase = generateRingBase({
    outRadius: ringOutRadius,
    height: ringHeight,
    gradientStartColor,
    gradientEndColor,
  });

  ringBaseContainer.add(ringBase);
  ringBaseContainer.position.x -= ringOutRadius;
  ringBaseContainer.position.y -= ringOutRadius;

  ringBaseContainer = tb.Object3D({ obj: ringBaseContainer, bbox: false, tooltip: false });
  ringBaseContainer.setAnchor('center');

  return ringBaseContainer;
};

/**
 * Generate THREE ringbase
 * @param params
 * @returns THREE ringbase object
 */
export const generateRingBase = (params: TRingBaseProps): Object3D => {
  const { outRadius, height, gradientStartColor, gradientEndColor } = params;

  const ringAltitude = 0;

  const ringBaseGeo = generateRing({ R: outRadius, h: height });
  ringBaseGeo.computeBoundingBox();

  const ringBaseMat = new ShaderMaterial({
    uniforms: {
      color1: {
        value: gradientStartColor,
      },
      color2: {
        value: gradientEndColor,
      },
      bboxMin: {
        value: ringBaseGeo.boundingBox.min,
      },
      bboxMax: {
        value: ringBaseGeo.boundingBox.max,
      },
    },
    vertexShader: RINGBASE_VERT_SHADER,
    fragmentShader: RINGBASE_FRAG_SHADER,
    transparent: true,
    side: DoubleSide,
  });

  const ringBaseMesh = new Mesh(ringBaseGeo, ringBaseMat);
  ringBaseMesh.position.z = height / 2 + ringAltitude;
  ringBaseMesh.rotateX(-Math.PI / 2);

  return ringBaseMesh;
};

/**
 * Generate ring geometry
 * @param R Outer ring radius
 * @param r Inner ring radius
 * @param h Ring height
 * @returns ring geometry
 */
export const generateRing = (params: TRingLatheProps): LatheGeometry => {
  const { R, r, h } = params;

  const halfH = h * 0.5;
  const points = r
    ? [
        new Vector2(r, -halfH),
        new Vector2(R, -halfH),
        new Vector2(R, halfH),
        new Vector2(r, halfH),
        new Vector2(r, -halfH),
      ]
    : [new Vector2(R, -halfH), new Vector2(R, halfH)];
  const segments = 48;

  const g = new LatheGeometry(points, segments);
  return g;
};

/**
 * Generate cylinder line geometry
 * @param param {segCount, segHeight, origin}
 * @returns cylinder geometry
 */
export const cylinderLineGeo = (param: {
  segCount: number;
  segHeight: number;
  origin: number[];
}): number[][] => {
  let lineGeometry: number[][] = [];

  times(param.segCount + 1, (i) => {
    const delta = [0, 0, i * param.segHeight * 10000];

    const newCoordinate = param.origin.map((d, index) => {
      return d + delta[index];
    });

    lineGeometry = [newCoordinate, ...lineGeometry];
  });

  return lineGeometry;
};

/**
 * Cacluate weight of gradient
 * @param val current value
 * @param min min value
 * @param max max value
 * @returns weight of gradient
 */
export const getGradientWeight = (val: number, min: number, max: number): number => {
  return (val + Math.abs(min)) / (Math.abs(max) + Math.abs(min));
};

/**
 * Pick hex color with gradient weight
 * @param color1 Left color
 * @param color2 Right color
 * @param weight Weight
 * @returns Hex gradient color
 */
export const pickHex = (color1: Color, color2: Color, weight = 1): Color => {
  const w1 = weight;
  const w2 = 1 - w1;
  const rgb = new Color(
    color1.r * w1 + color2.r * w2,
    color1.g * w1 + color2.g * w2,
    color1.b * w1 + color2.b * w2,
  );

  return rgb;
};

/**
 * Generate randomized offset value. Used for GPU happiness
 * @param value Base value
 * @returns Randomized offset value
 */
export const randomOffsetVal = (value: number): number => {
  return value + Math.floor(Math.random() * 10000) * 0.000001;
};

/**
 * Get canvas relative position
 * @param e Event
 * @param canvas Canvas dom
 * @param width Canvas width
 * @param height Canvas height
 * @returns screen position
 */
export const getCanvasRelativePosition = (
  e: MouseEvent,
  canvas: HTMLCanvasElement,
  width: number,
  height: number,
): { x: number; y: number } => {
  const rect = canvas.getBoundingClientRect();

  return {
    x: ((e.clientX - rect.left) * width) / rect.width,
    y: ((e.clientY - rect.top) * height) / rect.height,
  };
};

/**
 * Dipose object
 * @param object Three.js object3d
 * @returns
 */
export const disposeObject = (object: Object3D): void => {
  if (!object) return;

  object.traverse((child) => {
    if (child.isMesh) {
      child.geometry?.dispose();
      disposeMaterial(child.material);
    }
  });
};

/**
 * Dispose material
 * @param material Material three.js object
 * @returns
 */
export const disposeMaterial = (material: Material | Material[]): void => {
  if (!material) return;

  let materialArr: Material[] = [];

  if (Array.isArray(material)) {
    materialArr = material;
  } else {
    materialArr[0] = material;
  }

  materialArr.forEach((material) => {
    const {
      alphaMap,
      displacementMap,
      emissiveMap,
      envMap,
      lightMap,
      map,
      bumpMap,
      aoMap,
      metalnessMap,
      roughnessMap,
      normalMap,
    } = material;

    disposeTexture(alphaMap);
    disposeTexture(displacementMap);
    disposeTexture(emissiveMap);
    disposeTexture(envMap);
    disposeTexture(lightMap);
    disposeTexture(map);
    disposeTexture(bumpMap);
    disposeTexture(aoMap);
    disposeTexture(metalnessMap);
    disposeTexture(roughnessMap);
    disposeTexture(normalMap);
    material?.dispose();
  });
};

/**
 * Dispose texture
 * @param texture Texture three.js object
 */
export const disposeTexture = (texture: Texture): void => {
  texture?.dispose();
};

/**
 * Remove children from parent
 * @param parent Three.js object3d
 * @returns
 */
export const removeChildren = (parent: Object3D): void => {
  disposeObject(parent);

  for (let i = parent.children.length - 1; i >= 0; i--) {
    parent.remove(parent.children[i]);
  }
};

/**
 * 2D position on screen
 * @param obj
 * @param camera
 * @param width
 * @param height
 * @returns
 */
export const toScreenPosition = (
  obj: Object3D,
  camera: Camera,
  width: number,
  height: number,
): { x: number; y: number } => {
  const vector = new Vector3();

  const widthHalf = 0.5 * width;
  const heightHalf = 0.5 * height;

  obj.updateMatrixWorld();
  vector.setFromMatrixPosition(obj.matrixWorld);
  vector.project(camera);

  vector.x = vector.x * widthHalf + widthHalf;
  vector.y = -(vector.y * heightHalf) + heightHalf;

  return {
    x: vector.x,
    y: vector.y,
  };
};

/**
 * Calculate distance between 2 geo coordinates
 * @param lat1
 * @param lon1
 * @param lat2
 * @param lon2
 * @returns
 */
export const calcCrow = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
  const R = 6371; // Radius of earth in km
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const fLat = toRad(lat1);
  const sLat = toRad(lat2);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(fLat) * Math.cos(sLat);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
};

/**
 * Get radian value for lat
 * @param Value
 * @returns
 */
export const toRad = (Value: number): number => {
  return (Value * Math.PI) / 180;
};

// Load texture
export const loadTexture = (url: string): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    new TextureLoader().load(
      url,
      (data) => {
        resolve(data);
      },
      null,
      reject,
    );
  });
};
