import {
  ADD_ASSET_LAT_ADJUST,
  DEFAULT_COMMUNITY_NAME,
  LIBRARY_FILTERS_MAPPING,
  ZOOM,
} from 'src/constants/application';
import { AssetsInside, TAssetInside } from 'src/components/AssetsInside';
import { BACKEND_DATE_FORMATS, UTCMoment } from 'src/utils/UTCMoment';
import { ContextMenu, TContextMenuProps } from 'src/components/ContextMenu';
import {
  EConfigurationFlowState,
  useConfigurationFlowState,
} from 'src/hooks/useConfigurationFlowState';
import { EFormVariant, TAssetType } from 'src/typings/base-types';
import { EModalAssetsManagerView, TModalAssetsManagerProps } from './ModalAssetsManager.types';
import {
  EOrientationParam,
  TOrientationParam,
} from 'src/components/CustomPV/components/Orientation/Orientation.types';
import { FormAssetsParams, TFormAssetsParamsProps } from 'src/components/FormAssetsParams';
import { FormSettingsData, TFormSettingsDataProps } from 'src/components/FormSettingsData';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { TLibrary, TLibraryChooserProps } from 'src/components/LibraryChooser';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import { last, random } from 'lodash';
import {
  selectActiveConfigurationJobUuid,
  selectActiveConfigurationUuid,
  selectAllAssetLoadingInfo,
  selectAssets,
  selectAssetsValues,
  selectCommunityAsset,
  selectCommunityName,
  selectConfigType,
  selectConfigurationCharacteristic,
  selectHasUserAllowedResultsLoss,
  selectLastAddedAssetUuid,
  selectReadOnly,
  selectResultsEndTime,
  selectResultsStartTime,
  selectSettingsData,
  selectSimulationResults,
  selectSimulationStatus,
  selectUsedAssetsNames,
} from 'src/redux/configuration/configuration.selectors';
import {
  selectGuideStep,
  selectIsCustomHouseAddingContinuation,
  selectIsEmbed,
} from 'src/redux/application/application.selectors';
import {
  setGuideStep,
  setIsCustomHouseAddingContinuation,
  setModalAssetManagerActiveView,
  setModalCommunitySummaryView,
  setNewCommunity,
} from 'src/redux/application/application.slice';
import {
  setLastAddedAssetUuid,
  setSelectedAssetUuid,
} from 'src/redux/configuration/configuration.slice';

import { AddToLibraryForm } from 'src/components/ModalAssetsManager/AddToLibrary/AddToLibraryForm';
import { ApplicationContext } from 'src/contexts/ApplicationContext';
import { AssetListItem } from 'src/components/AssetListItem';
import { AssetStatusText } from 'src/components/AssetStatusText';
import { BaseButton } from 'src/components/BaseButton';
import { BaseClose } from 'src/components/BaseClose';
import { BaseIcon } from 'src/components/BaseIcon';
import { ChartHouseSummary } from 'src/components/_charts/ChartHouseSummary';
import { ConfigType } from 'src/graphql';
import { CustomScrollbar } from 'src/components/CustomScrollbar';
import { DownloadResultsAlert } from 'src/components/DownloadResultsAlert';
import { EPredefinedModalIds } from 'src/constants/modals';
import { GridMarketForm } from 'src/components/ModalAssetsManager/GridMarketAndAgent/GridMarketForm';
import { InViewPositioner } from 'src/components/InViewPositioner';
import { LibraryChooser } from 'src/components/LibraryChooser/LibraryChooser';
import { Loader } from 'src/components/LoadingWrapper';
import { NN } from 'src/typings/helpers';
import { ModalAssetManagerSubmitButton as SubmitButton } from './ModalAssetManagerSubmitButton';
import { TAsset } from 'src/typings/configuration.types';
import { TAssetsListWithSearchProps } from 'src/components/AssetsListWithSearch';
import { TabNav } from 'src/components/TabNav';
import WorldMapContext from 'src/contexts/WorldMapContext';
import classnames from 'classnames';
import { convertLngLat } from 'src/utils/worldMap/helpers';
import { destructureConfigTree } from 'src/utils/configuration/destructureConfigTree';
import { formatter } from 'src/utils/formatter';
import { getLibraryBadgeColor } from 'src/utils/getLibraryBadgeColor';
import { getUniqueAssetName } from 'src/utils/fieldUtils';
import { nonNullable } from 'src/utils/ts-utils';
import s from './ModalAssetsManager.module.scss';
import { selectActiveModals } from 'src/redux/modals/modals.selectors';
import { selectIsLoggedIn } from 'src/redux/auth/auth.selectors';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { selectSelectedLocation } from 'src/redux/map/map.selectors';
import { updateSelectedLocation } from 'src/redux/map/map.slice';
import { useAppDispatch } from 'src/redux/store';
import { useAssetsInside } from 'src/hooks/useAssetsInside';
import { useCurrentRefs } from 'src/hooks/useCurrentRefs';
import useDownloadAPI from 'src/hooks/useDownloadAPI';
import { useHouseSummaryResults } from 'src/hooks/useHouseSummaryResults';
import { useMergedState } from 'src/hooks/useMergedState';
import { useModal } from 'src/hooks/useModal';
import { useSelector } from 'react-redux';
import { useSimulationButtons } from 'src/hooks/useSimulationButtons';
import { v4 } from 'uuid';

enum EFormModes {
  NewChosenLibraryConfiguration = 'NewChosenLibraryConfiguration',
  NewCustomHouseConfiguration = 'NewCustomHouseConfiguration',
  NewCustomPVConfiguration = 'NewCustomPVConfiguration',
  NewCustomHeatPumpConfiguration = 'NewCustomHeatPumpConfiguration',
  NewCustomWindTurbineConfiguration = 'NewCustomWindTurbineConfiguration',
  NewCustomSmartMeterConfiguration = 'NewCustomSmartMeterConfiguration',
  NewCommunityConfiguration = 'CreateNewCommunity',
  EditCommunity = 'EditCommunity',
  EditAsset = 'EditAsset',
}

let azimuthValuesForCustomPV: TOrientationParam = {
  [EOrientationParam.AZIMUTH]: 0,
  [EOrientationParam.TILT]: 0,
};

// used in case of creating new custom PV
const azimuthValuesForCustomPVInitialState: TOrientationParam = azimuthValuesForCustomPV;

const setAzimuthValuesForCustomPV = (value: TOrientationParam) => {
  azimuthValuesForCustomPV = {
    ...azimuthValuesForCustomPV,
    ...value,
  };
};

export const ModalAssetsManager: React.FC<TModalAssetsManagerProps> = React.memo(
  function ModalAssetsManager({
    placeName,
    summaryIcon = 'house',
    hostAssetUuid,
    coords,
    view: viewFromProps,
    formVariant: formVariantFromProps = EFormVariant.Express,
    /*
     * viewOnDestroy - We need to restore view to initial state in order to tell the application that a user is not in create or edit house flow (see: selectIsCreateOrEditHouseFlow)
     */
    viewOnDestroy,
    disallowReturnToSummary,
    onViewChange,
    onFormVariantChange,
    onAddNewAsset,
    onAddNewCustomHouse,
    onAssetValuesSave,
    onSettingsDataSave,
    onAssetRemove,
    onCommunityRemove,
    onAddAssetUnderUuid,
    onAddNewCustomPV,
    onAssetValuesSavePromise,
    rootElRefProp,
    addSaveKey,
  }: TModalAssetsManagerProps) {
    const hasUserAllowedResultsLoss = useSelector(selectHasUserAllowedResultsLoss);
    const activeModals = useSelector(selectActiveModals);
    const step = useSelector(selectGuideStep);
    const communityAsset = useSelector(selectCommunityAsset);
    const isCommunityManager = communityAsset?.uuid === hostAssetUuid;
    const dispatch = useAppDispatch();
    const { mapService } = useContext(WorldMapContext);
    const { triggerResultsLossAlert, triggerCommunityDeleteAlert } = useContext(ApplicationContext);
    const formHasErrorsRef = useRef(false);
    const rootElRef = useRef<HTMLDivElement>(rootElRefProp?.current || null);
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const formId = useRef(
      isCommunityManager ? EPredefinedModalIds.MODAL_COMMUNITY_ASSET_MANAGER_FORM : v4(),
    );
    const isEmbed = useSelector(selectIsEmbed);
    const simulationStatus = useSelector(selectSimulationStatus);
    const assets = useSelector(selectAssets);
    const assetsValues = useSelector(selectAssetsValues);
    const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
    const activeConfigurationJobUuid = useSelector(selectActiveConfigurationJobUuid);
    const configurationCharacteristic = useSelector(selectConfigurationCharacteristic);
    const communityName = useSelector(selectCommunityName);
    const settingsData = useSelector(selectSettingsData);
    const resultStartTime = useSelector(selectResultsStartTime);
    const resultsEndTime = useSelector(selectResultsEndTime);

    const isCustomHouseAddingContinuation = useSelector(selectIsCustomHouseAddingContinuation);
    const [view, setView] = useState<EModalAssetsManagerView>(viewFromProps);
    const [stickyElementsHeight, setStickyElementsHeight] = useState(0);
    const viewHistory = useRef<EModalAssetsManagerView[]>([view]);
    const [formVariant, __setFormVariant] = useState<EFormVariant>(formVariantFromProps);
    const [chosenLibrary, setChosenLibrary] = useState<TLibrary | undefined>();
    const usedAssetsNames = useSelector(selectUsedAssetsNames);
    const readOnly = useSelector(selectReadOnly);
    const [editingAssetUuid, setEditingAssetUuid] = useState<TAsset['uuid'] | undefined>(
      hostAssetUuid,
    );
    const lastAddedAssetUuid = useSelector(selectLastAddedAssetUuid);

    const configType = useSelector(selectConfigType);
    const isCN = configType === ConfigType.CanaryNetwork;
    const [showDownloadAlert, setShowDownloadAlert] = useState<boolean>(false);
    const mostBeginingView = hostAssetUuid
      ? EModalAssetsManagerView.SaveOrSettings
      : EModalAssetsManagerView.AddHouse;
    const isHostedByCommunity = communityAsset?.uuid === hostAssetUuid;
    const addingInsideHouseNotCommunity = Boolean(hostAssetUuid && !isHostedByCommunity);

    const selectedLocation = useSelector(selectSelectedLocation);

    const loadingInfoCheckKeyRef = useRef<string | undefined>(v4());

    const allloadingInfo = useSelector(selectAllAssetLoadingInfo);

    const assetLoadingInfo =
      allloadingInfo &&
      loadingInfoCheckKeyRef.current &&
      allloadingInfo[loadingInfoCheckKeyRef.current]
        ? allloadingInfo[loadingInfoCheckKeyRef.current]
        : false;

    const assetLoadingInfoRef = useRef<boolean>(assetLoadingInfo);
    const [activeFilter, setActiveFilter] = useState<TAssetsListWithSearchProps['activeFilter']>(
      addingInsideHouseNotCommunity ? LIBRARY_FILTERS_MAPPING.PV : LIBRARY_FILTERS_MAPPING.Area,
    );
    const [userSawAdvancedFormVariant, setUserSawAdvancedFormVariant] = useState(false);
    const simulationResults = useSelector(selectSimulationResults);
    const summaryResults = useHouseSummaryResults({ assetUuid: hostAssetUuid });
    const resultsDownloadAPI = useDownloadAPI({
      type: 'simulation-results',
      resultsUuid: activeConfigurationJobUuid,
      startDate: UTCMoment.fromBackend(
        settingsData.startDate,
        BACKEND_DATE_FORMATS.SETTINGS_DATA,
      ).format(BACKEND_DATE_FORMATS.DOWNLOAD_SIM_RESULTS),
      endDate: UTCMoment.fromBackend(
        settingsData.endDate,
        BACKEND_DATE_FORMATS.SETTINGS_DATA,
      ).format(BACKEND_DATE_FORMATS.DOWNLOAD_SIM_RESULTS),
    });

    const showSimulationActionButtons =
      editingAssetUuid === communityAsset?.uuid && !isEmbed && !readOnly;
    const currRefs = useCurrentRefs({
      onViewChange,
      onFormVariantChange,
      viewOnDestroy,
      activeFilter,
      addingInsideHouseNotCommunity,
      dispatch,
      mostBeginingView,
      setChosenLibrary,
      hostAssetUuid,
    });

    const { runButtonOptions, runButtonState, stopButtonOptions } = useSimulationButtons({
      jobUuid: activeConfigurationJobUuid,
      configurationUuid: activeConfigurationUuid,
    });

    const chosenLibraryCurrentValues: TFormAssetsParamsProps['currentValues'] = useMemo(() => {
      const serialized = chosenLibrary?.scenarioData?.representation?.serialized;
      let geoTagLocation;

      if (hostAssetUuid) {
        if (isHostedByCommunity) {
          const lngArr: number[] = [];
          const latArr: number[] = [];
          // Get random location inside community
          Object.values(assetsValues).forEach(({ geoTagLocation }) => {
            if (geoTagLocation) {
              lngArr.push(geoTagLocation[0]);
              latArr.push(geoTagLocation[1]);
            }
          });

          const lng = random(Math.min(...lngArr), Math.max(...lngArr), true);
          const lat = random(Math.min(...latArr), Math.max(...latArr), true);

          geoTagLocation = [lng, lat];
        } else {
          geoTagLocation = assetsValues[hostAssetUuid].geoTagLocation;
        }
      } else {
        geoTagLocation = coords;
      }

      const getNameValue = (
        name: string | undefined,
        assetType: TAssetType | undefined,
      ): string => {
        if (name) {
          const translations = {
            Storage: 'Battery',
            Area: 'Home',
          };

          return getUniqueAssetName({
            newName: assetType && assetType in translations ? translations[assetType] : name,
            usedAssetsNames,
          });
        }

        return '';
      };

      if (serialized) {
        const destructuredTree = destructureConfigTree(serialized, {
          onlyRootAsset: true,
        });

        if (destructuredTree.rootAssetUuid) {
          const { type } = destructuredTree.assets[destructuredTree.rootAssetUuid];
          const values = destructuredTree.assetsValues[destructuredTree.rootAssetUuid];

          return {
            ...destructuredTree.assetsValues[destructuredTree.rootAssetUuid],
            name: getNameValue(values.name, type),
            geoTagLocation,
          };
        }
      } else {
        return {
          name: getNameValue(chosenLibrary?.title, chosenLibrary?.type),
          geoTagLocation,
        };
      }
    }, [assetsValues, chosenLibrary, hostAssetUuid, usedAssetsNames, coords, isHostedByCommunity]);

    const [contextMenuState, setContextMenuState] = useMergedState<{
      modalRef: React.RefObject<HTMLElement | null>;
      assetUuid: TAsset['uuid'] | undefined;
    }>({ modalRef: { current: null }, assetUuid: hostAssetUuid });

    const { id: contextMenuModalId } = useModal();
    const isContextMenuOpened = useSelector(selectIsModalOpened(contextMenuModalId));

    const currentFormMode = useMemo(() => {
      if (chosenLibrary) {
        if (
          !chosenLibrary.isCustomHouse &&
          !chosenLibrary.isCustomPV &&
          !chosenLibrary.isCustomHeatPump
        ) {
          return EFormModes.NewChosenLibraryConfiguration;
        }

        if (chosenLibrary.isCustomPV) {
          return EFormModes.NewCustomPVConfiguration;
        }

        if (chosenLibrary.isCustomHeatPump) {
          return EFormModes.NewCustomHeatPumpConfiguration;
        }

        if (chosenLibrary.isCustomWindTurbine) {
          return EFormModes.NewCustomWindTurbineConfiguration;
        }

        if (chosenLibrary.isCustomSmartMeter) {
          return EFormModes.NewCustomSmartMeterConfiguration;
        }

        return EFormModes.NewCustomHouseConfiguration;
      }

      if (editingAssetUuid === communityAsset?.uuid && !activeConfigurationUuid) {
        dispatch(setNewCommunity(false));

        return EFormModes.NewCommunityConfiguration;
      }

      if (editingAssetUuid === communityAsset?.uuid) {
        return EFormModes.EditCommunity;
      }

      return EFormModes.EditAsset;
    }, [dispatch, activeConfigurationUuid, chosenLibrary, communityAsset?.uuid, editingAssetUuid]);

    const isAllButtonRequiredInFilter =
      currentFormMode === EFormModes.NewChosenLibraryConfiguration ||
      currentFormMode === EFormModes.NewCustomHouseConfiguration ||
      currentFormMode === EFormModes.NewCustomPVConfiguration ||
      currentFormMode === EFormModes.NewCustomHeatPumpConfiguration ||
      currentFormMode === EFormModes.NewCustomWindTurbineConfiguration ||
      currentFormMode === EFormModes.NewCustomSmartMeterConfiguration ||
      view === EModalAssetsManagerView.ChooseAsset;

    const closeContextMenu = useCallback(() => {
      dispatch(closeModal(contextMenuModalId));
    }, [contextMenuModalId, dispatch]);

    const startAssetEdition = useCallback(
      (assetUuid: TAsset['uuid']) => {
        setEditingAssetUuid(assetUuid);
        setView(EModalAssetsManagerView.Form);
        closeContextMenu();
        dispatch(setModalAssetManagerActiveView(EModalAssetsManagerView.Form));
        dispatch(setSelectedAssetUuid(assetUuid));
      },
      [setView, setEditingAssetUuid, dispatch, closeContextMenu],
    );

    const contextMenuItems = useMemo(() => {
      const contextMenuItems: TContextMenuProps['items'] = [];
      //TODO re-enable later
      // if (onAssetDuplicate && contextMenuState.assetUuid !== communityAsset?.uuid) {
      //   contextMenuItems.push({
      //     title: 'Duplicate',
      //     icon: 'duplicate',
      //     onClick: () => {
      //       onAssetDuplicate?.({ assetUuid: contextMenuState.assetUuid });
      //       closeContextMenu();
      //     },
      //   });
      // }

      if (
        view === EModalAssetsManagerView.AssetsInside ||
        view === EModalAssetsManagerView.Summary
      ) {
        contextMenuItems.push({
          title: !readOnly ? 'Edit' : 'View settings',
          icon: !readOnly ? 'pencil-create' : 'settings',
          onClick: () => {
            if (contextMenuState.assetUuid) {
              startAssetEdition(contextMenuState.assetUuid);
            }
          },
        });
      }

      if (contextMenuState.assetUuid === communityAsset?.uuid && activeConfigurationJobUuid) {
        contextMenuItems.push({
          title: 'Download',
          icon: 'download',
          loading: resultsDownloadAPI.loading,
          disabled: simulationStatus !== 'finished',
          onClick: async () => {
            await resultsDownloadAPI.handleDownload(closeContextMenu);
          },
        });
      }

      if (!readOnly && onAssetRemove && contextMenuState.assetUuid !== communityAsset?.uuid) {
        contextMenuItems.push({
          title: 'Delete',
          icon: 'custom-trash',
          onClick: async () => {
            if (!contextMenuState.assetUuid) return;
            closeContextMenu();

            await triggerResultsLossAlert();

            onAssetRemove?.({ assetUuid: contextMenuState.assetUuid });
          },
        });
      }

      if (!readOnly && onCommunityRemove && contextMenuState.assetUuid === communityAsset?.uuid) {
        contextMenuItems.push({
          title: 'Delete community',
          icon: 'custom-trash',
          onClick: async () => {
            if (!contextMenuState.assetUuid) return;
            closeContextMenu();

            await triggerCommunityDeleteAlert();

            onCommunityRemove?.({ projectUuid: configurationCharacteristic.projectUuid });
          },
        });
      }
      return contextMenuItems;
    }, [
      activeConfigurationJobUuid,
      communityAsset?.uuid,
      configurationCharacteristic.projectUuid,
      contextMenuState.assetUuid,
      onAssetRemove,
      closeContextMenu,
      onCommunityRemove,
      readOnly,
      resultsDownloadAPI,
      simulationStatus,
      triggerCommunityDeleteAlert,
      triggerResultsLossAlert,
      view,
      startAssetEdition,
    ]);

    //    useEffect(() => {
    //        dispatch(setCurrentLoadingCheckInfo(loadingInfoCheckKeyRef.current));
    //    }, [dispatch]);

    const runSimulationButton = () => {
      if (isLoggedIn) {
        const downloadAlertStates = ['run', 'resume', 'rerun'];
        if (downloadAlertStates.includes(runButtonState)) {
          if (!showDownloadAlert) {
            setShowDownloadAlert(true);
            return;
          } else {
            setShowDownloadAlert(false);
          }
        }
        if (runButtonOptions.onClick) {
          runButtonOptions.onClick();
        }
      } else {
        dispatch(openModal(EPredefinedModalIds.MODAL_AUTH_REGISTER));
      }
    };

    // Sync currentFormMode >>>
    useEffect(() => {
      if (
        currentFormMode == EFormModes.NewCustomHouseConfiguration ||
        currentFormMode == EFormModes.NewChosenLibraryConfiguration
      ) {
        dispatch(setNewCommunity(true));
      } else {
        dispatch(setNewCommunity(false));
      }
    }, [dispatch, view, currentFormMode]);

    // Sync currentFormMode >>>
    //useEffect(() => {
    //  if (!userSawAdvancedFormVariant) {
    //    // dispatch(setIsNewCustomHouse(true));
    //  } else {
    //    // dispatch(setIsNewCustomHouse(false));
    //  }
    //}, [dispatch, userSawAdvancedFormVariant]);

    // const cf = useSelector(selectConfigurationCharacteristic);
    // useEffect(() => {
    //   if (cf.projectUuid != '') {
    //     dispatch(setModalAssetManagerActiveView(EModalAssetsManagerView.AddHouse));
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [cf.projectUuid]);

    //  }, [dispatch,view,currentFormMode]);

    //      // Sync currentFormMode >>>
    // useEffect(() => {
    //   // if (!userSawAdvancedFormVariant  ){
    //   //   dispatch(setIsNewCustomHouse(true))
    //   // }else{
    //   //   dispatch(setIsNewCustomHouse(false))
    //   // }

    //  }, [dispatch, userSawAdvancedFormVariant]);

    // Sync view >>>
    useEffect(() => {
      setView(viewFromProps);
    }, [viewFromProps]);
    useEffect(() => {
      if (step == 90) {
        setView(EModalAssetsManagerView.AddHouse);
      }
    }, [step]);
    const { configurationFlowState } = useConfigurationFlowState();
    useEffect(() => {
      if (configurationFlowState == EConfigurationFlowState.OnlineBuildMode) {
        if (!editingAssetUuid) {
          dispatch(setModalAssetManagerActiveView(EModalAssetsManagerView.AddHouse));
        }
        dispatch(setModalCommunitySummaryView(EModalAssetsManagerView.Summary));
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configurationFlowState, editingAssetUuid]);

    useEffect(() => {
      currRefs.current.onViewChange?.(view);
    }, [currRefs, view]);
    // <<< Sync view

    useEffect(() => {
      if (!editingAssetUuid && lastAddedAssetUuid) {
        setEditingAssetUuid(lastAddedAssetUuid);
        dispatch(setLastAddedAssetUuid(undefined));
      }
    }, [editingAssetUuid, lastAddedAssetUuid, dispatch]);

    // Sync formVariant >>>
    useEffect(() => {
      __setFormVariant(formVariantFromProps);
    }, [formVariantFromProps]);

    useEffect(() => {
      currRefs.current.onFormVariantChange?.(formVariant);
    }, [currRefs, formVariant]);
    // <<< Sync formVariant

    useEffect(
      () =>
        function onDestroy() {
          currRefs.current.onViewChange?.(currRefs.current.viewOnDestroy);
          currRefs.current.onFormVariantChange?.(EFormVariant.Express);
        },
      [currRefs],
    );

    const initMount = useRef(true);
    useEffect(() => {
      if (initMount.current) {
        initMount.current = false;
        return; // Dont run this effect on init mount!
      }

      if (view === currRefs.current.mostBeginingView) {
        viewHistory.current = [currRefs.current.mostBeginingView];

        if (
          currRefs.current.activeFilter === LIBRARY_FILTERS_MAPPING.Area &&
          currRefs.current.addingInsideHouseNotCommunity
        ) {
          setActiveFilter(LIBRARY_FILTERS_MAPPING.PV);
        }
      } else if (last(viewHistory.current) !== view) {
        viewHistory.current.push(view);
      }

      setUserSawAdvancedFormVariant(false);
      __setFormVariant(EFormVariant.Express);
      currRefs.current.dispatch(setIsCustomHouseAddingContinuation(false));

      if (view !== EModalAssetsManagerView.Form) {
        currRefs.current.setChosenLibrary(undefined);
        setEditingAssetUuid(currRefs.current.hostAssetUuid);
      }
    }, [currRefs, view]);

    const inViewPositionerRecalculateKey = String(view + formVariant + currentFormMode);
    useEffect(() => {
      if (!rootElRef.current) return;

      const formEl = rootElRef.current.querySelector('.js-custom-scrollbar');

      if (!formEl) return;

      const height =
        rootElRef.current.getBoundingClientRect().height - formEl.getBoundingClientRect().height;

      setStickyElementsHeight(height);
    }, [inViewPositionerRecalculateKey]);

    const { assetsInside } = useAssetsInside({ hostAssetUuid });

    const saveAction = async (func: () => void, key: string) => {
      if (!hasUserAllowedResultsLoss) {
        await triggerResultsLossAlert({ type: 'add-asset' });
      }

      addSaveKey?.(key);
      func();
    };

    const handleAddNewAsset: NN<TModalAssetsManagerProps['onAddNewAsset']> = async (payload) => {
      saveAction(() => onAddNewAsset?.(payload), loadingInfoCheckKeyRef.current || '');
    };

    const handleAddNewCustomHouse: NN<TModalAssetsManagerProps['onAddNewCustomHouse']> = async (
      payload,
    ) => {
      saveAction(() => onAddNewCustomHouse?.(payload), loadingInfoCheckKeyRef.current || '');
    };

    const handleAddNewCustomPV: NN<TModalAssetsManagerProps['onAddNewCustomPV']> = async (
      payload,
    ) => {
      saveAction(() => onAddNewCustomPV?.(payload), loadingInfoCheckKeyRef.current || '');
    };

    const handleAddAssetUnderUuid: NN<TModalAssetsManagerProps['onAddAssetUnderUuid']> = async (
      payload,
    ) => {
      saveAction(() => onAddAssetUnderUuid?.(payload), loadingInfoCheckKeyRef.current || '');
    };

    //const handleAssetValuesSave: TFormAssetsParamsProps['onSubmit'] = async (payload) => {
    //  saveAction(() => onAssetValuesSave?.(payload), loadingInfoCheckKeyRef.current || '');
    //};

    const handleAssetValuesSavePromise: TFormAssetsParamsProps['onSubmitPromise'] = async (
      payload,
    ) => {
      await triggerResultsLossAlert();

      addSaveKey?.(loadingInfoCheckKeyRef.current || '');

      return onAssetValuesSavePromise
        ? onAssetValuesSavePromise(payload)
        : new Promise((resolve) => resolve(false));
    };

    const handleCustomPvUpdate: TFormAssetsParamsProps['onSubmit'] = async (payload) => {
      await triggerResultsLossAlert();

      const updatedPayload = {
        ...payload,
        values: {
          ...payload.values,
          [EOrientationParam.AZIMUTH]: azimuthValuesForCustomPV[EOrientationParam.AZIMUTH],
          [EOrientationParam.TILT]: azimuthValuesForCustomPV[EOrientationParam.TILT],
        },
      };
      onAssetValuesSave?.(updatedPayload);

      // changeViewAfterSuccessfullSave();
    };

    const handleSettingsDataSave: TFormSettingsDataProps['onSubmit'] = async (payload) => {
      await triggerResultsLossAlert();

      onSettingsDataSave?.(payload);
      // changeViewAfterSuccessfullSave();
    };

    type TSuccess = boolean;
    function switchToAnotherFormVariant(variant: EFormVariant): TSuccess {
      if (formHasErrorsRef.current) {
        return false;
      }

      __setFormVariant(variant);

      if (variant === EFormVariant.Advanced) {
        setUserSawAdvancedFormVariant(true);
      }

      return true;
    }

    const closeSummaryView = () => {
      if (communityAsset?.uuid) {
        dispatch(setSelectedAssetUuid(undefined));
      }
      dispatch(setModalCommunitySummaryView(EModalAssetsManagerView.SaveOrSettings));
      dispatch(setModalAssetManagerActiveView(EModalAssetsManagerView.AddHouse));
    };

    const handleLibraryChoose: TLibraryChooserProps['onLibraryChoose'] = (library) => {
      setView(EModalAssetsManagerView.Form);
      setChosenLibrary(library);
    };

    function handleAssetInsideClick({ type }: { type: TAssetType }) {
      setView(EModalAssetsManagerView.AssetsInside);
      setActiveFilter(LIBRARY_FILTERS_MAPPING[type]);
    }

    function handleAddToLibraryButton() {
      setView(EModalAssetsManagerView.SaveToLibrary);
    }
    function handleGeoTagLocationChange() {
      dispatch(updateSelectedLocation(null));
    }

    const goBack = useCallback(
      ({ until }: { until?: EModalAssetsManagerView } = {}) => {
        if (!until) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const currView = viewHistory.current.pop();
          const prevView = viewHistory.current.pop();

          setView(prevView || mostBeginingView);
        } else {
          let prevView = mostBeginingView;

          while (prevView !== until || !viewHistory.current.length) {
            const poppedView = viewHistory.current.pop();
            if (poppedView) {
              prevView = poppedView;
            }
          }

          setView(prevView);
        }
      },
      [mostBeginingView],
    );

    const changeViewAfterSuccessfullSave = useCallback(() => {
      const addedAssetInside = viewHistory.current.includes(EModalAssetsManagerView.AssetsInside);
      const editedAssetInside = hostAssetUuid !== contextMenuState.assetUuid;
      //const editedSingleDevice =
      //  hostAssetUuid && ['Load', 'PV', 'Storage'].includes(assets[hostAssetUuid].type);
      const editedGridMarket =
        hostAssetUuid && !['Load', 'PV', 'Storage'].includes(assets[hostAssetUuid].type);

      if (isCustomHouseAddingContinuation) {
        setView(EModalAssetsManagerView.ChooseAsset);
        dispatch(setIsCustomHouseAddingContinuation(false));
      } else if (addedAssetInside) {
        goBack({ until: EModalAssetsManagerView.AssetsInside });
      } else if (editedAssetInside) {
        goBack();
      } else if (editedGridMarket) {
        dispatch(setSelectedAssetUuid());
      } else {
        // dispatch(setIsCustomHouseAddingContinuation(false));
        //  dispatch(setModalAssetManagerActiveView(EModalAssetsManagerView.Summary));
        setView(EModalAssetsManagerView.Summary);
      }
    }, [
      assets,
      contextMenuState.assetUuid,
      dispatch,
      goBack,
      hostAssetUuid,
      isCustomHouseAddingContinuation,
    ]);

    useEffect(() => {
      if (assetLoadingInfoRef.current === assetLoadingInfo) return;
      assetLoadingInfoRef.current = assetLoadingInfo;
      if (assetLoadingInfo) {
        setView(EModalAssetsManagerView.AssetLoading);
        return;
      }
      if (lastAddedAssetUuid) {
        setEditingAssetUuid(lastAddedAssetUuid);
        setContextMenuState((prev) => ({
          ...prev,
          assetUuid: lastAddedAssetUuid,
        }));
        dispatch(setLastAddedAssetUuid(undefined));
      }
      changeViewAfterSuccessfullSave();
    }, [
      assetLoadingInfo,
      lastAddedAssetUuid,
      dispatch,
      setContextMenuState,
      changeViewAfterSuccessfullSave,
    ]);

    function handleModalInfoClick() {
      dispatch(setSelectedAssetUuid(communityAsset?.uuid));
      if (selectedLocation && selectedLocation?.lat && selectedLocation?.lng) {
        handleGeoTagLocationChange();
      }
      if (communityName === DEFAULT_COMMUNITY_NAME) {
        setView(EModalAssetsManagerView.Form);
        dispatch(setModalCommunitySummaryView(EModalAssetsManagerView.Form));
        dispatch(setModalAssetManagerActiveView(EModalAssetsManagerView.Form));
      } else {
        setView(EModalAssetsManagerView.Summary);
        dispatch(setModalCommunitySummaryView(EModalAssetsManagerView.Summary));
        dispatch(setModalAssetManagerActiveView(EModalAssetsManagerView.Summary));
      }
    }

    const assetType =
      editingAssetUuid && assets[editingAssetUuid] ? assets[editingAssetUuid].type : undefined;
    const assetName =
      editingAssetUuid && assetsValues[editingAssetUuid]
        ? assetsValues[editingAssetUuid].name
        : undefined;
    let formTitle = '';

    if (view === EModalAssetsManagerView.AssetsInside) {
      formTitle = `Inside ${isHostedByCommunity ? configurationCharacteristic.name : assetName}`;
    } else if (isAllButtonRequiredInFilter) {
      formTitle = 'Add an Asset';
    } else if (currentFormMode === EFormModes.NewCommunityConfiguration) {
      formTitle = 'Save your community';
    } else if (
      (currentFormMode === EFormModes.EditAsset || currentFormMode === EFormModes.EditCommunity) &&
      assetName
    ) {
      formTitle = `Edit ${assetName}`;
    }

    function BaseCloseWrapped() {
      if (disallowReturnToSummary === 'hide-button') return null;
      return (
        <BaseClose
          className={s.mla}
          disabled={disallowReturnToSummary && typeof disallowReturnToSummary !== 'function'}
          onClick={() => {
            if (typeof disallowReturnToSummary === 'function') {
              disallowReturnToSummary();
              // PH-697: allows to select houses after selecting a community
              dispatch(setModalAssetManagerActiveView(EModalAssetsManagerView.AddHouse));
            } else {
              goBack();
            }
          }}
        />
      );
    }

    function Tabs() {
      return (
        <div>
          <TabNav
            nav={[
              {
                label: 'Express',
                onClick() {
                  if (view === EModalAssetsManagerView.ChooseAsset) return;
                  switchToAnotherFormVariant(EFormVariant.Express);
                },
                isActive:
                  (view === EModalAssetsManagerView.Form && formVariant === EFormVariant.Express) ||
                  view === EModalAssetsManagerView.ChooseAsset,
              },
              {
                label: 'Advanced',
                onClick() {
                  switchToAnotherFormVariant(EFormVariant.Advanced);
                },
                isActive:
                  view === EModalAssetsManagerView.Form && formVariant === EFormVariant.Advanced,
                disabled: view === EModalAssetsManagerView.ChooseAsset,
              },
            ]}
          />
        </div>
      );
    }

    function HeaderWithTabs({ className }: { className?: string }) {
      return (
        <div className={className}>
          <header className={s.header}>
            <h3 className={s.title}>{formTitle}</h3>
            <BaseCloseWrapped />
          </header>

          <Tabs />
        </div>
      );
    }

    /*const centerMap = () => {
      const currZoom = mapService?.map.getZoom();
      const specificZoom = currZoom && currZoom < ZOOM.ADD_ASSET ? ZOOM.ADD_ASSET : undefined;
      if (selectedLocation && selectedLocation?.lat && selectedLocation?.lng) {
        mapService?.flyTo(
          { lng: selectedLocation?.lng, lat: selectedLocation?.lat + ADD_ASSET_LAT_ADJUST },
          { specificZoom },
        );
      }
    };*/

    const LoadingView = () => (
      <div className={s.loadingAroundWrapper}>
        <Loader />
      </div>
    );

    const AddHome = () => {
      return (
        <div className={s.addHouseContainer}>
          <div className={s.address}>{placeName || 'Unknown location'}</div>
          <BaseButton
            className={s.addHouseBtn}
            icon="plus-circle"
            size="small"
            disabled={!placeName}
            onClick={() => {
              setView(EModalAssetsManagerView.ChooseAsset);

              const currZoom = mapService?.map.getZoom();
              const specificZoom =
                currZoom && currZoom < ZOOM.ADD_ASSET ? ZOOM.ADD_ASSET : undefined;
              if (coords) {
                const lngLat = convertLngLat(coords);

                mapService?.flyTo(
                  { lng: lngLat.lng, lat: lngLat.lat + ADD_ASSET_LAT_ADJUST },
                  { specificZoom },
                );
              }
            }}>
            Add a Home
          </BaseButton>
        </div>
      );
    };

    const stopRunningAndReopenToAdd = () => {
      if (stopButtonOptions.onClick) stopButtonOptions.onClick();
      setView(EModalAssetsManagerView.ChooseAsset);
    };

    const handleAddAssetButton = () => {
      if (simulationStatus !== 'running' || isCN) {
        setView(EModalAssetsManagerView.ChooseAsset);
      } else {
        stopRunningAndReopenToAdd();
      }
    };

    const startUnix = useMemo(() => {
      return UTCMoment.fromBackend(resultStartTime).startOf('day').unix();
    }, [resultStartTime]);

    const endUnix = useMemo(() => {
      return UTCMoment.fromBackend(resultsEndTime).endOf('day').unix();
    }, [resultsEndTime]);

    return (
      <>
        <InViewPositioner
          className={s.modal}
          innerClassName={classnames(s.modalInner, 'gradient-dark')}
          recalculateKey={inViewPositionerRecalculateKey}
          reservedHeight={stickyElementsHeight}
          rootElRef={rootElRef}>
          {(() => {
            switch (view) {
              case EModalAssetsManagerView.AssetLoading:
                return <LoadingView />;
              case EModalAssetsManagerView.SaveOrSettings: {
                if (activeModals.includes('modal-onboarding') && (step == 1 || step == 2)) {
                  return null;
                }
                return (
                  <div className={s.saveOrSettings} onClick={handleModalInfoClick}>
                    {communityName === DEFAULT_COMMUNITY_NAME
                      ? 'Click here to save community'
                      : 'Community Settings'}
                  </div>
                );
              }
              case EModalAssetsManagerView.AddHouse: {
                return <AddHome />;
              }
              case EModalAssetsManagerView.AddCommunity: {
                return <AddHome />;
              }
              case EModalAssetsManagerView.Summary:
                return (
                  <div className={s.paddingAround}>
                    {/* view {JSON.stringify(view)} */}
                    <header className={s.header}>
                      <div className={s.typeIcon}>
                        <BaseIcon icon={summaryIcon} size={12} />
                      </div>

                      <h3 className={s.title}>
                        {isHostedByCommunity ? configurationCharacteristic.name : assetName}
                      </h3>

                      {contextMenuItems.length > 1 ? (
                        <>
                          <button
                            type="button"
                            className={s.mla}
                            onClick={(e) => {
                              if (isContextMenuOpened) {
                                closeContextMenu();
                              } else {
                                setContextMenuState({
                                  modalRef: { current: e.target as HTMLElement },
                                  assetUuid: editingAssetUuid,
                                });
                                dispatch(openModal(contextMenuModalId));
                              }
                            }}>
                            {!isEmbed && <BaseIcon icon="more-horizontal" size={20} />}
                          </button>
                          <button
                            type="button"
                            className={s.mlaClose}
                            onClick={() => closeSummaryView()}>
                            <BaseIcon icon="close" size={12} />
                          </button>
                        </>
                      ) : (
                        <button
                          type="button"
                          className={s.mla}
                          onClick={() => {
                            if (contextMenuState.assetUuid) {
                              startAssetEdition(contextMenuState.assetUuid);
                              return;
                            }
                            if (editingAssetUuid) {
                              startAssetEdition(editingAssetUuid);
                            }
                          }}>
                          {!isEmbed && (
                            <BaseIcon icon={readOnly ? 'settings' : 'pencil-edit'} size={14} />
                          )}
                        </button>
                      )}
                    </header>

                    {/* Show Asset Status on home asssets only */}
                    {(!showSimulationActionButtons && assetType === 'Area') || (
                      <AssetStatusText assetName={assetName || ''} />
                    )}

                    {/*
                      We want to show ChartHouseSummary even if simulation results fetching is still in progress.
                      We do it in order to get rid of jumpy UI.
                    */}

                    <ChartHouseSummary
                      className={s.chart}
                      simulationStartDate={settingsData.startDate}
                      netEnergyData={summaryResults.netEnergyData}
                      pricesData={summaryResults.pricesData}
                      currentMarket={summaryResults.currentMarket}
                      isLoading={summaryResults.loading}
                      startUnix={startUnix}
                      endUnix={endUnix}
                    />

                    <ul className={s.assetsList}>
                      {assetsInside.map((asset, index) => (
                        <li key={index}>
                          <button
                            type="button"
                            className={s.assetSquareBtn}
                            onClick={() => handleAssetInsideClick({ type: asset.type })}
                            disabled={isEmbed}>
                            <BaseIcon icon={asset.icon} size={16.25} />
                            <label className={s.assetsAmount}>
                              {formatter.prefixWithZeros(asset.amount)}
                            </label>
                          </button>
                        </li>
                      ))}

                      {onAddAssetUnderUuid && !isEmbed && !readOnly && (
                        <li>
                          <button
                            type="button"
                            className={s.addAssetBtn}
                            onClick={() => {
                              handleAddAssetButton();
                            }}>
                            <BaseIcon icon="plus-circle" size={17.6} />
                          </button>
                        </li>
                      )}
                    </ul>

                    {showDownloadAlert && <DownloadResultsAlert />}

                    {showSimulationActionButtons && (
                      <div className={s.buttonContainer}>
                        {!stopButtonOptions.disabled && (
                          <button
                            type="button"
                            className={classnames(s.abortBtn)}
                            onClick={() => stopButtonOptions.onClick && stopButtonOptions.onClick()}
                            disabled={stopButtonOptions.disabled}>
                            <BaseIcon icon={stopButtonOptions.icon} size={19.5} />
                          </button>
                        )}
                        <BaseButton
                          className={s.runSimBtn}
                          theme={runButtonState === 'pause' ? 'secondary' : undefined}
                          onClick={runSimulationButton}
                          icon={showDownloadAlert ? null : runButtonOptions.icon}
                          disabled={runButtonOptions.disabled}>
                          {showDownloadAlert ? 'OK' : runButtonOptions.title}
                        </BaseButton>
                      </div>
                    )}
                  </div>
                );
              case EModalAssetsManagerView.Form:
                return (
                  <>
                    <div className={classnames(s.paddingLR, s.paddingT)}>
                      <header className={s.header}>
                        <h3 className={s.title}>{formTitle}</h3>
                        {!readOnly && !isAllButtonRequiredInFilter && (
                          <>
                            {/* not show on Grid Market Settings modal */}
                            {contextMenuItems.length >= 1 && (
                              <button
                                type="button"
                                className={s.mla}
                                onClick={async (e) => {
                                  if (isContextMenuOpened) {
                                    closeContextMenu();
                                  } else {
                                    setContextMenuState({
                                      modalRef: { current: e.target as HTMLElement },
                                      assetUuid: editingAssetUuid,
                                    });
                                    dispatch(openModal(contextMenuModalId));
                                  }
                                }}>
                                {!isEmbed && <BaseIcon icon="more-horizontal" size={20} />}
                              </button>
                            )}
                            <button
                              type="button"
                              className={classnames(s.mlaClose, {
                                [s.autoLeft]: contextMenuItems.length === 0,
                              })}
                              onClick={() => closeSummaryView()}>
                              <BaseIcon icon="close" size={12} />
                            </button>
                          </>
                        )}
                        {(readOnly || isAllButtonRequiredInFilter) && <BaseCloseWrapped />}
                      </header>
                      <Tabs />
                    </div>

                    {(() => {
                      switch (currentFormMode) {
                        case EFormModes.NewChosenLibraryConfiguration:
                          if (!chosenLibrary) return null;
                          return (
                            <>
                              <div className={s.paddingLR}>
                                <AssetListItem
                                  className={s.chosenAsset}
                                  icon={chosenLibrary.icon}
                                  title={chosenLibrary.title}
                                  subtitle={chosenLibrary.subtitle}
                                  onCloseClick={() => goBack()}
                                  badgeColor={getLibraryBadgeColor(chosenLibrary!)}
                                />
                              </div>

                              <CustomScrollbar className={classnames(s.paddingLR, s.formMaxHeight)}>
                                <FormAssetsParams
                                  hasErrorsRef={formHasErrorsRef}
                                  id={formId.current}
                                  formVariant={formVariant}
                                  onSubmit={async ({ values }) => {
                                    if (!hostAssetUuid) {
                                      handleAddNewAsset({
                                        values,
                                        chosenAsset: chosenLibrary,
                                      });
                                    } else {
                                      handleAddAssetUnderUuid({
                                        addUnderUuid: hostAssetUuid,
                                        values,
                                        chosenAsset: chosenLibrary,
                                      });
                                      setView(EModalAssetsManagerView.AssetsInside);
                                      setActiveFilter(LIBRARY_FILTERS_MAPPING['All']);
                                    }
                                  }}
                                  onLocationChange={handleGeoTagLocationChange}
                                  flyAfterLocationSelect={true}
                                  disableLocationField={addingInsideHouseNotCommunity}
                                  assetUuid={chosenLibrary.uuid || ''}
                                  assetType={chosenLibrary.type}
                                  currentValues={chosenLibraryCurrentValues}
                                  isCustomLoad={chosenLibrary.isCustomLoad}
                                />
                              </CustomScrollbar>

                              <SubmitButton formId={formId.current} />
                            </>
                          );

                        case EFormModes.NewCustomHouseConfiguration:
                          if (!chosenLibrary) return null;
                          return (
                            <>
                              <div className={s.paddingLR}>
                                <AssetListItem
                                  className={s.chosenAsset}
                                  icon={chosenLibrary.icon}
                                  title={chosenLibrary.title}
                                  subtitle={chosenLibrary.subtitle}
                                  onCloseClick={() => goBack()}
                                  badgeColor={getLibraryBadgeColor(chosenLibrary!)}
                                />

                                <div className={s.noAssetsBadge}>No assets</div>
                              </div>

                              <CustomScrollbar className={classnames(s.paddingLR, s.formMaxHeight)}>
                                <FormAssetsParams
                                  hasErrorsRef={formHasErrorsRef}
                                  id={formId.current}
                                  formVariant={formVariant}
                                  onSubmit={async (payload) => {
                                    handleAddNewCustomHouse({
                                      addUnderUuid: hostAssetUuid,
                                      values: payload.values,
                                      chosenAsset: chosenLibrary,
                                      nextView: !userSawAdvancedFormVariant
                                        ? EModalAssetsManagerView.Form
                                        : EModalAssetsManagerView.ChooseAsset,
                                    });
                                  }}
                                  onLocationChange={handleGeoTagLocationChange}
                                  flyAfterLocationSelect={true}
                                  disableLocationField={addingInsideHouseNotCommunity}
                                  assetUuid={chosenLibrary.uuid || ''}
                                  assetType={chosenLibrary.type}
                                  currentValues={chosenLibraryCurrentValues}
                                />
                              </CustomScrollbar>

                              <SubmitButton formId={formId.current}>
                                {!userSawAdvancedFormVariant
                                  ? 'Save and continue'
                                  : 'Save and add assets'}
                              </SubmitButton>
                            </>
                          );
                        case EFormModes.NewCustomHeatPumpConfiguration:
                          if (!chosenLibrary) return null;
                          return (
                            <>
                              <div className={s.paddingLR}>
                                <AssetListItem
                                  className={s.chosenAsset}
                                  icon={chosenLibrary.icon}
                                  title={chosenLibrary.title}
                                  subtitle={chosenLibrary.subtitle}
                                  onCloseClick={() => goBack()}
                                  badgeColor={getLibraryBadgeColor(chosenLibrary!)}
                                />
                              </div>

                              <CustomScrollbar className={classnames(s.paddingLR, s.formMaxHeight)}>
                                <FormAssetsParams
                                  hasErrorsRef={formHasErrorsRef}
                                  id={formId.current}
                                  formVariant={formVariant}
                                  onSubmit={async (payload) => {
                                    const { values } = payload;
                                    if (!hostAssetUuid) {
                                      handleAddNewCustomPV({
                                        addUnderUuid: hostAssetUuid,
                                        values: payload.values,
                                        chosenAsset: chosenLibrary,
                                        nextView: !userSawAdvancedFormVariant
                                          ? EModalAssetsManagerView.Form
                                          : EModalAssetsManagerView.ChooseAsset,
                                      });
                                    } else {
                                      handleAddAssetUnderUuid({
                                        addUnderUuid: hostAssetUuid,
                                        values,
                                        chosenAsset: chosenLibrary,
                                      });
                                      setView(EModalAssetsManagerView.AssetsInside);
                                      setActiveFilter(LIBRARY_FILTERS_MAPPING['All']);
                                    }
                                  }}
                                  onLocationChange={handleGeoTagLocationChange}
                                  flyAfterLocationSelect={true}
                                  disableLocationField={addingInsideHouseNotCommunity}
                                  assetUuid={chosenLibrary.uuid || ''}
                                  assetType={chosenLibrary.type}
                                  currentValues={chosenLibraryCurrentValues}
                                  isCustomHeatPump={true}
                                  isCustomPV={false}
                                />
                                <SubmitButton formId={formId.current} />
                              </CustomScrollbar>

                              {/* <SubmitButton>
                                {!userSawAdvancedFormVariant
                                  ? 'Save and continue'
                                  : 'Save and add assets'}
                              </SubmitButton> */}
                            </>
                          );

                        case EFormModes.NewCustomWindTurbineConfiguration:
                          if (!chosenLibrary) return null;
                          return (
                            <>
                              <div className={s.paddingLR}>
                                <AssetListItem
                                  className={s.chosenAsset}
                                  icon={chosenLibrary.icon}
                                  title={chosenLibrary.title}
                                  subtitle={chosenLibrary.subtitle}
                                  onCloseClick={() => goBack()}
                                  badgeColor={getLibraryBadgeColor(chosenLibrary!)}
                                />
                              </div>

                              <CustomScrollbar className={classnames(s.paddingLR, s.formMaxHeight)}>
                                <FormAssetsParams
                                  hasErrorsRef={formHasErrorsRef}
                                  id={formId.current}
                                  formVariant={formVariant}
                                  onSubmit={async (payload) => {
                                    const { values } = payload;

                                    if (!hostAssetUuid) {
                                      handleAddNewCustomPV({
                                        addUnderUuid: hostAssetUuid,
                                        values: payload.values,
                                        chosenAsset: chosenLibrary,
                                        nextView: !userSawAdvancedFormVariant
                                          ? EModalAssetsManagerView.Form
                                          : EModalAssetsManagerView.ChooseAsset,
                                      });
                                    } else {
                                      handleAddAssetUnderUuid({
                                        addUnderUuid: hostAssetUuid,
                                        values,
                                        chosenAsset: chosenLibrary,
                                      });
                                      setView(EModalAssetsManagerView.AssetsInside);
                                      setActiveFilter(LIBRARY_FILTERS_MAPPING['All']);
                                    }
                                  }}
                                  onLocationChange={handleGeoTagLocationChange}
                                  flyAfterLocationSelect={true}
                                  disableLocationField={addingInsideHouseNotCommunity}
                                  assetUuid={chosenLibrary.uuid || ''}
                                  assetType={chosenLibrary.type}
                                  currentValues={chosenLibraryCurrentValues}
                                  isCustomHeatPump={false}
                                  isCustomWindTurbine={true}
                                  isCustomSmartMeter={false}
                                  isCustomPV={false}
                                />
                                <SubmitButton formId={formId.current} />
                              </CustomScrollbar>

                              {/* <SubmitButton>
                                {!userSawAdvancedFormVariant
                                  ? 'Save and continue'
                                  : 'Save and add assets'}
                              </SubmitButton> */}
                            </>
                          );

                        case EFormModes.NewCustomPVConfiguration:
                          if (!chosenLibrary) return null;
                          return (
                            <>
                              <div className={s.paddingLR}>
                                <AssetListItem
                                  className={s.chosenAsset}
                                  icon={chosenLibrary.icon}
                                  title={chosenLibrary.title}
                                  subtitle={chosenLibrary.subtitle}
                                  onCloseClick={() => goBack()}
                                  badgeColor={getLibraryBadgeColor(chosenLibrary!)}
                                />
                              </div>

                              <CustomScrollbar className={classnames(s.paddingLR, s.formMaxHeight)}>
                                <FormAssetsParams
                                  hasErrorsRef={formHasErrorsRef}
                                  id={formId.current}
                                  formVariant={formVariant}
                                  onSubmit={async (payload) => {
                                    const { values } = payload;
                                    values[EOrientationParam.AZIMUTH] =
                                      azimuthValuesForCustomPV[EOrientationParam.AZIMUTH];
                                    values[EOrientationParam.TILT] =
                                      azimuthValuesForCustomPV[EOrientationParam.TILT];

                                    if (!hostAssetUuid) {
                                      handleAddNewCustomPV({
                                        addUnderUuid: hostAssetUuid,
                                        values: payload.values,
                                        chosenAsset: chosenLibrary,
                                        nextView: !userSawAdvancedFormVariant
                                          ? EModalAssetsManagerView.Form
                                          : EModalAssetsManagerView.ChooseAsset,
                                      });
                                    } else {
                                      handleAddAssetUnderUuid({
                                        addUnderUuid: hostAssetUuid,
                                        values,
                                        chosenAsset: chosenLibrary,
                                      });
                                      setView(EModalAssetsManagerView.AssetsInside);
                                      setActiveFilter(LIBRARY_FILTERS_MAPPING['All']);
                                    }
                                  }}
                                  onLocationChange={handleGeoTagLocationChange}
                                  flyAfterLocationSelect={true}
                                  disableLocationField={addingInsideHouseNotCommunity}
                                  assetUuid={chosenLibrary.uuid || ''}
                                  assetType={chosenLibrary.type}
                                  currentValues={chosenLibraryCurrentValues}
                                  isCustomPV={true}
                                  isCustomHeatPump={false}
                                  azimuthValuesForCustomPV={azimuthValuesForCustomPVInitialState}
                                  setAzimuthValuesForCustomPV={setAzimuthValuesForCustomPV}
                                />
                                <SubmitButton formId={formId.current} />
                              </CustomScrollbar>

                              {/* <SubmitButton>
                                {!userSawAdvancedFormVariant
                                  ? 'Save and continue'
                                  : 'Save and add assets'}
                              </SubmitButton> */}
                            </>
                          );

                        case EFormModes.NewCustomWindTurbineConfiguration:
                          if (!chosenLibrary) return null;
                          return (
                            <>
                              <div className={s.paddingLR}>
                                <AssetListItem
                                  className={s.chosenAsset}
                                  icon={chosenLibrary.icon}
                                  title={chosenLibrary.title}
                                  subtitle={chosenLibrary.subtitle}
                                  onCloseClick={() => goBack()}
                                  badgeColor={getLibraryBadgeColor(chosenLibrary!)}
                                />
                              </div>

                              <CustomScrollbar className={classnames(s.paddingLR, s.formMaxHeight)}>
                                <FormAssetsParams
                                  hasErrorsRef={formHasErrorsRef}
                                  id={formId.current}
                                  formVariant={formVariant}
                                  onSubmit={async (payload) => {
                                    const { values } = payload;

                                    if (!hostAssetUuid) {
                                      handleAddNewCustomPV({
                                        addUnderUuid: hostAssetUuid,
                                        values: payload.values,
                                        chosenAsset: chosenLibrary,
                                        nextView: !userSawAdvancedFormVariant
                                          ? EModalAssetsManagerView.Form
                                          : EModalAssetsManagerView.ChooseAsset,
                                      });
                                    } else {
                                      handleAddAssetUnderUuid({
                                        addUnderUuid: hostAssetUuid,
                                        values,
                                        chosenAsset: chosenLibrary,
                                      });
                                      setView(EModalAssetsManagerView.AssetsInside);
                                      setActiveFilter(LIBRARY_FILTERS_MAPPING['All']);
                                    }
                                  }}
                                  onLocationChange={handleGeoTagLocationChange}
                                  flyAfterLocationSelect={true}
                                  disableLocationField={addingInsideHouseNotCommunity}
                                  assetUuid={chosenLibrary.uuid || ''}
                                  assetType={chosenLibrary.type}
                                  currentValues={chosenLibraryCurrentValues}
                                  isCustomHeatPump={false}
                                  isCustomWindTurbine={true}
                                  isCustomSmartMeter={false}
                                  isCustomPV={false}
                                />
                                <SubmitButton formId={formId.current} />
                              </CustomScrollbar>
                            </>
                          );

                        case EFormModes.NewCustomSmartMeterConfiguration:
                          if (!chosenLibrary) return null;
                          return (
                            <>
                              <div className={s.paddingLR}>
                                <AssetListItem
                                  className={s.chosenAsset}
                                  icon={chosenLibrary.icon}
                                  title={chosenLibrary.title}
                                  subtitle={chosenLibrary.subtitle}
                                  onCloseClick={() => goBack()}
                                  badgeColor={getLibraryBadgeColor(chosenLibrary!)}
                                />
                              </div>

                              <CustomScrollbar className={classnames(s.paddingLR, s.formMaxHeight)}>
                                <FormAssetsParams
                                  hasErrorsRef={formHasErrorsRef}
                                  id={formId.current}
                                  formVariant={formVariant}
                                  onSubmit={async (payload) => {
                                    const { values } = payload;

                                    if (!hostAssetUuid) {
                                      handleAddNewCustomPV({
                                        addUnderUuid: hostAssetUuid,
                                        values: payload.values,
                                        chosenAsset: chosenLibrary,
                                        nextView: !userSawAdvancedFormVariant
                                          ? EModalAssetsManagerView.Form
                                          : EModalAssetsManagerView.ChooseAsset,
                                      });
                                    } else {
                                      handleAddAssetUnderUuid({
                                        addUnderUuid: hostAssetUuid,
                                        values,
                                        chosenAsset: chosenLibrary,
                                      });
                                      setView(EModalAssetsManagerView.AssetsInside);
                                      setActiveFilter(LIBRARY_FILTERS_MAPPING['All']);
                                    }
                                  }}
                                  onLocationChange={handleGeoTagLocationChange}
                                  flyAfterLocationSelect={true}
                                  disableLocationField={addingInsideHouseNotCommunity}
                                  assetUuid={chosenLibrary.uuid || ''}
                                  assetType={chosenLibrary.type}
                                  currentValues={chosenLibraryCurrentValues}
                                  isCustomPV={false}
                                  isCustomHeatPump={false}
                                  isCustomSmartMeter={true}
                                  azimuthValuesForCustomPV={azimuthValuesForCustomPVInitialState}
                                  setAzimuthValuesForCustomPV={setAzimuthValuesForCustomPV}
                                />
                                <SubmitButton formId={formId.current} />
                              </CustomScrollbar>

                              {/* <SubmitButton>
                                {!userSawAdvancedFormVariant
                                  ? 'Save and continue'
                                  : 'Save and add assets'}
                              </SubmitButton> */}
                            </>
                          );

                        case EFormModes.EditCommunity:
                        case EFormModes.NewCommunityConfiguration:
                          return (
                            <>
                              <CustomScrollbar className={classnames(s.paddingLR, s.formMaxHeight)}>
                                <FormSettingsData
                                  id={formId.current}
                                  // hasErrorsRef={formHasErrorsRef}
                                  formVariant={formVariant}
                                  onSubmit={handleSettingsDataSave}
                                  disableLocationField={false}
                                />
                              </CustomScrollbar>
                              {!simulationResults && (
                                <div className={s.paddingLR}>
                                  <BaseButton
                                    theme="grey2"
                                    className={s.saveBtn}
                                    onClick={() => {
                                      // goBack()
                                      dispatch(setGuideStep(2));
                                      dispatch(
                                        setModalAssetManagerActiveView(
                                          EModalAssetsManagerView.AddHouse,
                                        ),
                                      );
                                      dispatch(
                                        setModalCommunitySummaryView(
                                          EModalAssetsManagerView.SaveOrSettings,
                                        ),
                                      );
                                    }}>
                                    Keep adding homes
                                  </BaseButton>
                                </div>
                              )}
                              {isLoggedIn ? (
                                <SubmitButton formId={formId.current} />
                              ) : (
                                <div className={s.paddingLR} style={{ paddingBottom: 12 }}>
                                  <BaseButton
                                    // theme="grey2"
                                    className={s.saveBtn}
                                    onClick={() => {
                                      dispatch(openModal(EPredefinedModalIds.MODAL_AUTH_LOGIN));
                                    }}>
                                    Login/Register to Save
                                  </BaseButton>
                                </div>
                              )}
                            </>
                          );

                        case EFormModes.EditAsset:
                          if (!editingAssetUuid) return null;

                          const { azimuth, tilt, tankVolumeL, sourceType } = assetsValues[
                            editingAssetUuid
                          ];
                          const isCustomPV = Boolean(nonNullable(azimuth) && nonNullable(tilt));
                          const isCustomHeatPump = Boolean(
                            nonNullable(tankVolumeL) && nonNullable(sourceType),
                          );
                          if (isCustomPV) {
                            setAzimuthValuesForCustomPV({
                              azimuth: azimuth ? azimuth : 0,
                              tilt: tilt ? tilt : 0,
                            });
                          }

                          return (
                            <>
                              <CustomScrollbar className={classnames(s.paddingLR, s.formMaxHeight)}>
                                <FormAssetsParams
                                  hasErrorsRef={formHasErrorsRef}
                                  id={formId.current}
                                  assetUuid={editingAssetUuid}
                                  formVariant={formVariant}
                                  onSubmit={
                                    isCustomPV ? handleCustomPvUpdate : handleAssetValuesSavePromise
                                  }
                                  currentValues={assetsValues[editingAssetUuid]}
                                  disableLocationField={addingInsideHouseNotCommunity}
                                  isCustomPV={isCustomPV}
                                  isCustomHeatPump={isCustomHeatPump}
                                  azimuthValuesForCustomPV={azimuthValuesForCustomPV}
                                  setAzimuthValuesForCustomPV={setAzimuthValuesForCustomPV}
                                  isEdit={true}
                                />
                              </CustomScrollbar>

                              <SubmitButton
                                formId={formId.current}
                                addToLibrary={true}
                                handleAddToLibrary={handleAddToLibraryButton}>
                                {isCustomHouseAddingContinuation
                                  ? 'Save and add assets'
                                  : undefined}
                              </SubmitButton>
                            </>
                          );

                        default:
                          return null;
                      }
                    })()}
                  </>
                );

              case EModalAssetsManagerView.ChooseAsset:
                return (
                  <>
                    <HeaderWithTabs className={classnames(s.paddingLR, s.paddingT)} />

                    <LibraryChooser
                      paddingClassName={s.paddingLR}
                      disabledFilters={
                        addingInsideHouseNotCommunity ? [LIBRARY_FILTERS_MAPPING.Area] : undefined
                      }
                      isAllButtonRequiredInFilter={isAllButtonRequiredInFilter}
                      onLibraryChoose={handleLibraryChoose}
                      activeFilter={activeFilter}
                      onFilterChange={(v) => setActiveFilter(v.value)}
                    />
                  </>
                );

              case EModalAssetsManagerView.AssetsInside:
                if (!hostAssetUuid) {
                  console.error('"hostAssetUuid" is undefined');
                  return null;
                }

                return (
                  <>
                    <header className={classnames(s.paddingLR, s.paddingT, s.header)}>
                      <h3 className={s.title}>{formTitle}</h3>

                      <BaseCloseWrapped />
                    </header>

                    <AssetsInside
                      parentUuid={hostAssetUuid}
                      activeFilter={activeFilter}
                      onFilterChange={(v) => setActiveFilter(v.value)}
                      paddingClassName={s.paddingLR}
                      onThreeDotsClick={({ target, asset }) => {
                        if (isContextMenuOpened) {
                          closeContextMenu();
                        } else {
                          setContextMenuState({
                            modalRef: { current: target as HTMLElement },
                            assetUuid: (asset as TAssetInside).uuid
                              ? (asset as TAssetInside).uuid
                              : editingAssetUuid,
                          });
                          dispatch(openModal(contextMenuModalId));
                        }
                      }}
                    />

                    {(addingInsideHouseNotCommunity &&
                      activeFilter === LIBRARY_FILTERS_MAPPING.Area) ||
                    readOnly ? null : (
                      <div className={classnames(s.paddingLR, s.paddingB)}>
                        <BaseButton
                          className={s.addNewAssetBtn}
                          size="small"
                          icon="plus-circle"
                          onClick={() => {
                            setView(EModalAssetsManagerView.ChooseAsset);
                          }}>
                          Add New Asset
                        </BaseButton>
                      </div>
                    )}
                  </>
                );

              case EModalAssetsManagerView.GridMarketAndAgent:
                return (
                  <GridMarketForm
                    handleAssetValuesSavePromise={handleAssetValuesSavePromise}
                    hostAssetUuid={hostAssetUuid ?? ''}
                  />
                );
              case EModalAssetsManagerView.SaveToLibrary:
                return <AddToLibraryForm setView={setView} />;
              default:
                return null;
            }
          })()}
          {/* TODO: fix styles for this tooltip */}
          {/* {showGuide && view === EModalAssetsManagerView.ChooseAsset && (
            <BaseTooltip
              theme="2"
              className={s.hintTooltip}
              tickPosition="right"
              onClick={() => dispatch(setModalAssetManagerShowGuide(false))}>
              {'Click here to add a home'}
            </BaseTooltip>
          )} */}
        </InViewPositioner>

        {!isEmbed && (
          <ContextMenu
            relativeElement={contextMenuState.modalRef}
            modalId={contextMenuModalId}
            position={{
              side: 'bottom',
              anchorPosition: 90,
            }}
            items={contextMenuItems}
          />
        )}
      </>
    );
  },
);
