import { ApolloError, useSubscription } from '@apollo/client';

import { SimulationStatusSubscription } from 'src/graphql/subscriptions/simulationStatus';
import { TSimResultsStatus } from 'src/typings/base-types';
import { useState } from 'react'; // eslint-disable-line no-unused-vars

type TUseSimulationStatusResult = {
  statusError: ApolloError | undefined;
  statusLoading: boolean;
  simulationStatus: TSimResultsStatus | undefined;
  etaSeconds: number;
  etaStartSeconds: number;
};

export const useSimulationStatus = function (
  jobId?: string | null,
  forceSkip?: boolean,
  uuid?: string,
): TUseSimulationStatusResult {
  const [simulationStatus, setSimulationStatus] = useState<TSimResultsStatus>();
  const [etaSeconds, setEtaSeconds] = useState(0);
  const [etaStartSeconds, setEtaStartSeconds] = useState(0);

  const { error: statusError, loading: statusLoading } = useSubscription(
    SimulationStatusSubscription,
    {
      variables: { jobId, uuid },
      skip: !jobId || !uuid || forceSkip,
      onSubscriptionComplete: () => {
        //
      },
      onSubscriptionData: (data) => {
        const { status, progressInfo } = data.subscriptionData.data.simulationAreaResultsPartial[0];

        setSimulationStatus(status);
        if (progressInfo) {
          setEtaSeconds(progressInfo.etaSeconds);
          setEtaStartSeconds(progressInfo.elapsedTimeSeconds);
        }
      },
    },
  );

  return {
    statusError,
    statusLoading,
    simulationStatus,
    etaSeconds,
    etaStartSeconds,
  };
};
