import gql from 'graphql-tag';

export const SimulationAreaResultsPartial = gql`
  subscription simulationAreaResultsPartial(
    $jobId: String!
    $uuid: String!
    $timeResolutionHrs: Int
    $startTime: DateTime
  ) {
    simulationAreaResultsPartial(
      jobId: $jobId
      areaUUID: $uuid
      timeResolutionHrs: $timeResolutionHrs
      startTime: $startTime
    ) {
      areaUuid
      cumulativeBills
      cumulativeGridTrades
      currentMarket
      cumulativeNetEnergyFlow
      deviceStatistics
      tradeProfileAreaThroughput
      kpi
      marketSummary {
        externalTradedVolume
        averageEnergyRate
        timestamp
        tradedVolume
      }
      bills
      priceEnergyDay
      latestSavingsKpi
      savingsKpiProfile(resolution: DAILY)
      assetsInfo
      progressInfo {
        percentageCompleted
        etaSeconds
        elapsedTimeSeconds
      }
      status
    }
  }
`;
