import { Fade } from 'react-reveal';
import React from 'react';
import { TProps } from './BlueBox.types';
import s from './BlueBox.module.scss';

export const BlueBox: React.FC<TProps> = ({ title, description, show, link }) => {
  const striptHTMLtagsFromString = {
    dangerouslySetInnerHTML: { __html: description },
  };
  return (
    <Fade opposite collapse={!show} when={show} duration={2000}>
      <div className={s.boxContent}>
        <div className={s.boxText}>
          <h3>{title}</h3>
          <p {...striptHTMLtagsFromString}></p>
          {link && (
            <>
              <br />
              <a target="_blank" rel="noreferrer" href={link?.href}>
                {link?.title}
              </a>
            </>
          )}
        </div>
      </div>
    </Fade>
  );
};
