import { BaseGenericModal } from '../BaseGenericModal';
import { BaseIcon } from '../BaseIcon';
import { BaseLink } from '../BaseLink';
import { EModalSize } from '../../constants/modals';
import { NAV_LINKS } from 'src/components/Header/constants';
import React from 'react';
import { TModalBurgerNavigationProps } from './ModalBurgerNavigation.types';
import { routesConfig } from '../../routes/routes.config';
import s from './ModalBurgerNavigation.module.scss';
import { useAppLocation } from 'src/hooks/useAppLocation';

export const ModalBurgerNavigation: React.FC<TModalBurgerNavigationProps> = ({ modalId }) => {
  const location = useAppLocation();
  const headerNavLinks = NAV_LINKS;

  return (
    <BaseGenericModal
      className={s.container}
      closedClassName={s.closed}
      size={EModalSize.L370}
      modalId={modalId}>
      <div className={s.burgerLinks}>
        {headerNavLinks.map((link) => {
          return (
            <BaseLink
              key={link.route}
              isNavLink={location?.routeName === link.route}
              to={routesConfig[link.route]()}
              className={s.burgerNavLink}
              theme="4">
              {link.title}
            </BaseLink>
          );
        })}

        {/*<BaseLink isNavLink={true} to={routesConfig.about()} className={s.burgerNavLink} theme="4">*/}
        {/*  {routesTitles.about}*/}
        {/*</BaseLink>*/}

        {/*<BaseLink*/}
        {/*  isNavLink={true}*/}
        {/*  to={routesConfig.resources()}*/}
        {/*  className={s.burgerNavLink}*/}
        {/*  theme="4">*/}
        {/*  {routesTitles.resources}*/}
        {/*</BaseLink>*/}
      </div>
      <div className={s.burgerLinks}>
        <h3 className={s.titleSocialMedia}>Engage with us! </h3>

        <BaseLink to="https://gsycommunity.slack.com/" className={s.socialLink} theme="3">
          <BaseIcon size={18} className={s.socialIcon} icon="slack" />
          Slack
        </BaseLink>
        <BaseLink to="https://x.com/gridsingularity" className={s.socialLink} theme="static">
          <BaseIcon size={18} className={s.socialIcon} icon="x" />X
        </BaseLink>
        <BaseLink to="https://gridsingularity.medium.com/" className={s.socialLink} theme="static">
          <BaseIcon size={18} className={s.socialIcon} icon="medium" />
          Medium
        </BaseLink>
        <BaseLink
          to="https://www.linkedin.com/company/grid-singularity"
          className={s.socialLink}
          theme="static">
          <BaseIcon size={18} className={s.socialIcon} icon="linkedin" />
          Linkedin
        </BaseLink>
        <BaseLink to="https://github.com/gridsingularity" className={s.socialLink} theme="static">
          <BaseIcon size={18} className={s.socialIcon} icon="github" />
          Github
        </BaseLink>
        <BaseLink
          to="https://gridsingularity.github.io/gsy-e/documentation/"
          className={s.socialLink}
          theme="static">
          <BaseIcon size={18} className={s.socialIcon} icon="wiki" />
          Documentation
        </BaseLink>
      </div>
    </BaseGenericModal>
  );
};
