import { ELayersGroup, EMenus, TMenuLayers, layerPositions } from 'src/pages/MarketDesignMobile';

export const menuPartOne: TMenuLayers[] = [
  {
    title: 'Exchanges',
    layer: layerPositions[ELayersGroup.EXCHANGES],
    icon: 'exchanges',
  },
  {
    title: 'Markets',
    layer: layerPositions[ELayersGroup.MARKETS],
    icon: 'markets',
  },
  {
    title: 'APIs',
    layer: layerPositions[ELayersGroup.APIS],
    icon: 'apis',
  },
];

export const menuPartTwo: TMenuLayers[] = [
  {
    title: 'Simulation',
    layer: layerPositions[ELayersGroup.SIMULATION],
    icon: 'simulation',
  },
  {
    title: 'Deployment',
    layer: layerPositions[ELayersGroup.DEPLOYMENT],
    icon: 'deployment',
  },
];

export const menus = {
  [EMenus.FIRST]: menuPartOne,
  [EMenus.SECOND]: menuPartTwo,
};
