import { ELayersGroup, layerPositions, totalLayersInEachGroup } from 'src/pages/MarketDesign';
import { IProps, IPropsFullBar } from './StatusBar.types';

import React from 'react';
import styles from './StatusBar.module.scss';

export const StatusBar: React.FC<IProps> = ({ page, active, totalPages }) => {
  const indicator = 100 / totalPages;

  return (
    <div className={styles.container}>
      <div className={styles.section} style={active && totalPages > 1 ? { height: '75px' } : {}}>
        {active && (
          <div
            className={styles.activeBar}
            style={{
              height: `${totalPages === 1 ? '100' : indicator}%`,
              top: `${page * indicator}%`,
            }}
          />
        )}
      </div>
    </div>
  );
};

export const FullBar: React.FC<IPropsFullBar> = ({ page }) => {
  const firstSectionBar = [
    ...totalLayersInEachGroup[ELayersGroup.EXCHANGES],
    ...totalLayersInEachGroup[ELayersGroup.MARKETS],
    ...totalLayersInEachGroup[ELayersGroup.APIS],
  ];

  const secondSectionBar = [
    ...totalLayersInEachGroup[ELayersGroup.SIMULATION],
    ...totalLayersInEachGroup[ELayersGroup.DEPLOYMENT],
  ];

  return (
    <div className={styles.statusBarContent}>
      <StatusBar
        page={firstSectionBar.findIndex((e) => e === page)}
        active={
          page >= layerPositions[ELayersGroup.EXCHANGES] &&
          page < layerPositions[ELayersGroup.SIMULATION]
        }
        totalPages={firstSectionBar?.length}
      />
      <StatusBar
        page={secondSectionBar.findIndex((e) => e === page)}
        active={
          page >= layerPositions[ELayersGroup.SIMULATION] &&
          page < layerPositions[ELayersGroup.ECOSYSTEM]
        }
        totalPages={secondSectionBar?.length}
      />
      <StatusBar
        page={totalLayersInEachGroup[ELayersGroup.ECOSYSTEM].findIndex((e) => e === page)}
        active={page >= layerPositions[ELayersGroup.ECOSYSTEM]}
        totalPages={totalLayersInEachGroup[ELayersGroup.ECOSYSTEM]?.length}
      />
    </div>
  );
};
