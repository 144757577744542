/* eslint-disable no-var */
import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import { LocationSearch, TLocationSearchChangeResult } from 'src/components/LocationSearch';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import {
  selectActiveConfigurationJobUuid,
  selectActiveConfigurationUuid,
  selectSimulationStatus,
} from 'src/redux/configuration/configuration.selectors';
import {
  selectGuideStep,
  selectIsCustomHouseAddingContinuation,
  selectModalAssetManagerActiveView,
  selectModalCommunitySummaryView,
  selectModalHouseSummaryView,
} from 'src/redux/application/application.selectors';
import {
  setGuideStep,
  setModalAssetManagerActiveView,
  setModalCommunitySummaryView,
  setModalMapHeroDismissed,
  setNewCommunity,
  setUserCreatedCommunityBefore,
} from 'src/redux/application/application.slice';

import { BaseButton } from 'src/components/BaseButton';
import { BaseGenericModal } from 'src/components/BaseGenericModal';
import { BaseIcon } from 'src/components/BaseIcon';
import { BaseProgress } from 'src/components/BaseProgress';
import { EConfigurationFlowState } from 'src/hooks/useConfigurationFlowState';
import { EModalAssetsManagerView } from 'src/components/ModalAssetsManager/ModalAssetsManager.types';
import { TLngLat } from 'src/typings/base-types';
import { TModalOnboardingProps } from './ModalOnboarding.types';
import WorldMapContext from 'src/contexts/WorldMapContext';
import classnames from 'classnames';
import { getLocationDetails } from 'src/services/api/mapbox.api';
import { getLocationDetailsByPriority } from 'src/utils/worldMap/helpers';
import { locationsCache } from 'src/cache/locations';
import { routesConfig } from 'src/routes/routes.config';
import s from './ModalOnboarding.module.scss';
import { selectAssets } from '../../redux/configuration/configuration.selectors';
import { selectIsLoggedIn } from 'src/redux/auth/auth.selectors';
import { selectSelectedLocation } from 'src/redux/map/map.selectors';
import { store } from 'src/redux/store';
import { updateSelectedLocation } from 'src/redux/map/map.slice';
import { useAppDispatch } from 'src/redux/store';
import { useConfigurationFlowState } from 'src/hooks/useConfigurationFlowState';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSimulationButtons } from 'src/hooks/useSimulationButtons';
import watch from 'redux-watch';

const steps = [1, 2, 3, 4];

export const ModalOnboarding: React.FC<TModalOnboardingProps> = () => {
  const isCustomHouseAddingContinuation = useSelector(selectIsCustomHouseAddingContinuation);
  const modalAssetManagerActiveView = useSelector(selectModalAssetManagerActiveView);
  const activeModals = store.getState().modals.activeModals;
  const [MAMAV, setMAMAV] = useState<string>('');

  const [readyToStep2, setReadyToStep2] = useState(false);
  const [readyToStep3, setReadyToStep3] = useState(false);
  const [readyToStep4, setReadyToStep4] = useState(false);

  const w = watch(store.getState, 'application.modalAssetManagerActiveView');
  store.subscribe(
    w((newVal, oldVal) => {
      const modalAssetManagerActiveView_Status = oldVal + '_' + newVal;
      if (modalAssetManagerActiveView_Status != MAMAV) {
        setMAMAV(modalAssetManagerActiveView_Status);
      } else {
        return false;
      }
    }),
  );
  const history = useHistory();

  const simulationStatus = useSelector(selectSimulationStatus);
  const selectedLocation = useSelector(selectSelectedLocation);
  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
  const activeConfigurationJobUuid = useSelector(selectActiveConfigurationJobUuid);
  const { runButtonOptions, runButtonState, stopButtonOptions } = useSimulationButtons({
    jobUuid: activeConfigurationJobUuid,
    configurationUuid: activeConfigurationUuid,
  });

  const { communityIsReadyToSave } = useConfigurationFlowState();

  // const handleClick = async () => {
  //   if (!store.getState().auth.tokenAuth) {
  //     dispatch(openModal(EPredefinedModalIds.MODAL_AUTH_LOGIN));
  //   } else if (activeConfigurationUuid) {
  //     if (runButtonOptions.onClick) runButtonOptions.onClick();
  //   }
  // };
  //const userCreatedCommunityBefore = useSelector(selectUserCreatedCommunityBefore);
  const modalCommunitySummaryView = useSelector(selectModalCommunitySummaryView);
  const dispatch = useAppDispatch();
  const modalRef = useRef<HTMLDivElement>(null);
  // const { modalOnboarding } = useConfigurationFlowState();
  const [location, setLocation] = useState<TLocationSearchChangeResult>();
  //const [secondLocation, setSecondLocation] = useState<TLocationSearchChangeResult>();
  const [nextBtn, setNextBtn] = useState(true);
  const [, setPrevBtn] = useState(true);
  const [clickNextOrPrev, setClickNextOrPrev] = useState(0);
  const { mapService } = useContext(WorldMapContext);
  //const assetsValues = useSelector(selectAssetsValues);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const modalHouseSummaryView = useSelector(selectModalHouseSummaryView);
  const step = useSelector(selectGuideStep);
  const [, setSaveCommunityFirstTime] = useState(0);
  const configurationCharacteristic = store.getState().configuration;
  // const [items] = React.useState(getItems);

  function AfterNextOrPrev(newStep) {
    dispatch(setGuideStep(newStep));
    setClickNextOrPrev(newStep);
  }

  const handleCloseClick = useCallback(() => {
    dispatch(closeModal(EPredefinedModalIds.MODAL_ONBOARDING));
  }, [dispatch]);

  useEffect(() => {
    if (simulationStatus == 'running') {
      handleCloseClick();
    }
  }, [simulationStatus, handleCloseClick]);

  // Sync view >>>
  useEffect(() => {
    dispatch(setModalCommunitySummaryView(modalCommunitySummaryView));
  }, [dispatch, modalCommunitySummaryView]);

  const { configurationFlowState } = useConfigurationFlowState();
  const assetsMap = useSelector(selectAssets);
  useEffect(() => {
    const assetKeys = Object.keys(assetsMap);
    let numberOfHouses = 0;

    let rootAssetId = '';
    let communityAssetId = '';
    if (assetKeys) {
      assetKeys.forEach((key) => {
        const asset = assetsMap[key];
        if (asset.type === 'Area' && !asset.parentUuid) {
          rootAssetId = asset.uuid;
        }
        if (asset.type === 'Area' && rootAssetId !== '' && rootAssetId === asset.parentUuid) {
          communityAssetId = asset.uuid;
        }
      });
      numberOfHouses = assetKeys.filter(
        (key) => assetsMap[key].type === 'Area' && assetsMap[key].parentUuid === communityAssetId,
      ).length;
    }

    switch (numberOfHouses) {
      case 0:
        setOnce1(false);
        setOnce2(false);
        setOnce3(false);
        setOnce4(false);
        setReadyToStep2(false);
        setReadyToStep3(false);
        setReadyToStep4(false);
        dispatch(setGuideStep(1));
        return;
      case 1:
        setOnce1(true);
        setOnce2(false);
        setOnce3(false);
        setOnce4(false);
        setReadyToStep2(true);
        setReadyToStep3(false);
        setReadyToStep4(false);
        dispatch(setGuideStep(2));
        return;
      case 2:
        if (configurationFlowState !== EConfigurationFlowState.OnlineBuildMode) {
          setOnce1(true);
          setOnce2(true);
          setOnce3(true);
          setOnce4(false);
          setReadyToStep2(true);
          setReadyToStep3(true);
          setReadyToStep4(false);
        }
        return;
    }

    if (configurationFlowState === EConfigurationFlowState.OnlineBuildMode) {
      setOnce1(true);
      setOnce2(true);
      setOnce3(true);
      setOnce4(true);
      setReadyToStep2(true);
      setReadyToStep3(true);
      setReadyToStep4(true);
      dispatch(setGuideStep(4));
    }
    if (
      modalAssetManagerActiveView === EModalAssetsManagerView.AddCommunity &&
      numberOfHouses < 3
    ) {
      setOnce1(false);
      setOnce2(false);
      setOnce3(false);
      setOnce4(false);
      setReadyToStep2(false);
      setReadyToStep3(false);
      setReadyToStep4(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configurationFlowState, assetsMap]);

  const setSelectedLocation = useCallback(
    async (lngLat: TLngLat) => {
      dispatch(updateSelectedLocation({ ...lngLat }));

      const details = await getLocationDetails(lngLat);
      if (details) {
        const locationDetails = getLocationDetailsByPriority(details.features);
        locationsCache.set(locationsCache.convertToKey(lngLat), locationDetails);

        dispatch(
          updateSelectedLocation({
            ...lngLat,
            place_name: locationDetails?.place_name,
            place_type: locationDetails?.place_type,
            text: locationDetails?.text,
            bbox: locationDetails?.bbox,
          }),
        );
      }

      mapService?.flyTo(
        { lng: lngLat?.lng, lat: (lngLat?.lat as number) + 0 },
        { specificZoom: 17, speed: 1 },
      );
    },
    [dispatch, mapService],
  );

  useEffect(() => {
    if (location) {
      mapService?.flyTo(
        { lng: location?.lng, lat: (location?.lat as number) + 0 },
        { specificZoom: 17, speed: 1 },
      );
      try {
        dispatch(
          updateSelectedLocation({
            lng: location?.lng,
            lat: location?.lat,
            place_name: location?.place_name,
            place_type: location?.place_type,
            text: location?.text,
            bbox: location?.bbox,
          }),
        );
      } catch (error) {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location]);

  const setSecondLocationCall = async (data) => {
    setSelectedLocation({ lng: data.lng, lat: data.lat });
  };

  // useEffect(() => {
  //   if (modalOnboarding) {
  //     dispatch(openModal(EPredefinedModalIds.MODAL_ONBOARDING));
  //   } else {
  //     dispatch(closeModal(EPredefinedModalIds.MODAL_ONBOARDING));
  //   }
  // }, [dispatch, modalOnboarding]);

  const [once1, setOnce1] = useState<boolean>(false);
  const [once2, setOnce2] = useState<boolean>(false);
  const [once3, setOnce3] = useState<boolean>(false);
  const [once4, setOnce4] = useState<boolean>(false);
  const [progressBar, setProgressBar] = useState<number>(0);

  useEffect(() => {
    //start>> if the wizard process was ended and we use the next and prev buttons to navigate between steps
    if (clickNextOrPrev == 1) {
      setClickNextOrPrev(0);
      dispatch(setModalAssetManagerActiveView(EModalAssetsManagerView.AddHouse));
      dispatch(setModalCommunitySummaryView(EModalAssetsManagerView.SaveOrSettings));
    }
    if (clickNextOrPrev == 2) {
      dispatch(setModalAssetManagerActiveView(EModalAssetsManagerView.AddHouse));
      dispatch(setModalCommunitySummaryView(EModalAssetsManagerView.SaveOrSettings));
      // setOnce2(true);
      setClickNextOrPrev(0);
    }

    if (clickNextOrPrev == 3) {
      setSaveCommunityFirstTime(2);
      dispatch(setNewCommunity(true));
      dispatch(setModalCommunitySummaryView(EModalAssetsManagerView.Form));
      dispatch(setModalAssetManagerActiveView(EModalAssetsManagerView.AddHouse));

      setClickNextOrPrev(0);
    }

    if (clickNextOrPrev == 4) {
      // dispatch(setModalCommunitySummaryView(EModalAssetsManagerView.Summary));

      setClickNextOrPrev(0);
    }
    //end >> if the wizard process was ended and we use the next and prev buttons to navigate between steps

    // start >> If we use the automatic mode to move between the steps automatically

    if (step == 1) {
      setPrevBtn(true);
      dispatch(setNewCommunity(false));
      if (once1) {
        if (activeModals.includes('modal-onboarding')) {
          // dispatch(setModalAssetManagerActiveView(EModalAssetsManagerView.Summary));
        }
        setProgressBar(1);
        setNextBtn(false);
      } else {
        setProgressBar(1);
        setNextBtn(true);
      }

      if (MAMAV == 'FORM_ADD_HOUSE') {
        if (once1 == false) {
          setProgressBar(1);
          dispatch(setGuideStep(2));
          setReadyToStep2(true);
          setOnce1(true);
          setNextBtn(false);
        }
      }
    }

    if (step == 2) {
      // if (!isCustomHouseAddingContinuation) {
      // }

      if (readyToStep3) {
        setProgressBar(2);
      }

      setPrevBtn(false);
      dispatch(setNewCommunity(false));
      if (activeModals.includes('modal-onboarding')) {
        dispatch(setModalCommunitySummaryView(EModalAssetsManagerView.SaveOrSettings));
      } else {
      }
      if (once2) {
        setReadyToStep3(true);
      } else {
        if (
          (communityIsReadyToSave == true || configurationCharacteristic.projectUuid != '') &&
          MAMAV == 'FORM_ADD_HOUSE' &&
          modalHouseSummaryView == 'SUMMARY'
        ) {
          // setNextBtn(false);
          setOnce2(true);

          if (!activeModals.includes('modal-onboarding')) {
            dispatch(setGuideStep(3));
          }
        }
        // setNextBtn(true);
      }
    }

    if (step == 3) {
      // if (isLoggedIn) {
      // if (communityIsReadyToSave == true) {
      //   // setNextBtn(true);
      // }
      if (readyToStep4) {
        setProgressBar(3);
      }

      if (once3 == false) {
        // dispatch(setModalAssetManagerActiveView(EModalAssetsManagerView.AddHouse));
        // if (
        //   modalCommunitySummaryView == EModalAssetsManagerView.Summary &&
        //   modalHouseSummaryView == EModalAssetsManagerView.Summary
        // ) {
        if (configurationCharacteristic.projectUuid != '') {
          dispatch(setGuideStep(4));
          setOnce3(true);
          setReadyToStep4(true);
          setProgressBar(3);
        }
      } else {
        setReadyToStep4(true);
        setProgressBar(3);
      }
    }

    if (step == 4) {
      if (!once4) {
        if (configurationCharacteristic.projectUuid) {
          if (activeModals.includes('modal-onboarding')) {
            dispatch(setModalCommunitySummaryView(EModalAssetsManagerView.Summary));
          }

          setOnce4(true);
          dispatch(openModal(EPredefinedModalIds.MODAL_MAP_SIDEBAR));

          dispatch(setUserCreatedCommunityBefore(true));
        }
      }

      if (simulationStatus == 'running') {
        dispatch(setModalCommunitySummaryView(EModalAssetsManagerView.Summary));
      }

      if (readyToStep2 && readyToStep3 && readyToStep4) {
        setProgressBar(4);
      }
    }

    // end >> If we use the automatic mode to move between the steps automatically
  }, [
    readyToStep2,
    readyToStep3,
    isCustomHouseAddingContinuation,
    activeModals,
    configurationCharacteristic.projectUuid,
    configurationCharacteristic.name,
    readyToStep4,
    once3,
    simulationStatus,
    MAMAV,
    modalAssetManagerActiveView,
    clickNextOrPrev,
    once1,
    once2,
    once4,
    modalCommunitySummaryView,
    isLoggedIn,
    dispatch,
    modalHouseSummaryView,
    step,
    communityIsReadyToSave,
  ]);

  useEffect(() => {
    if (!once4 && step == 3) {
      dispatch(setGuideStep(4));
      // setOnce4(true);
      setReadyToStep4(true);
      setProgressBar(3);
      if (activeModals.includes('modal-onboarding')) {
        dispatch(setModalCommunitySummaryView(EModalAssetsManagerView.SaveOrSettings));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configurationCharacteristic.name]);

  const StepLocked = () => (
    <>
      <h6 style={{ textAlign: 'center', padding: 20, backgroundColor: '#2C2A2E' }}>
        🔒 Step Locked
      </h6>
    </>
  );

  const Step1 = () => {
    const onChange = (e) => {
      dispatch(setModalMapHeroDismissed(true));
      history.push(routesConfig.singularityMapCreate());
      setLocation(e);
    };
    return (
      <>
        <div className={s.container} style={{ width: '100%' }}></div>
        <div className={s.stepStateContainer}>
          <br />
          <div className={s.stepState}>{step}</div>
          <h4>🏠 Add your first home</h4>
        </div>
        <h6>
          Start building your community by adding a home and the energy assets you are interested
          in.
        </h6>
        <div className={s.info}>
          <LocationSearch
            label="Type the location here"
            dropdownPosition="bottom"
            className={s.searchInput}
            name="type-location"
            iconLeft="search"
            value={selectedLocation?.place_name}
            onChange={onChange}
          />
        </div>
      </>
    );
  };

  const Step2 = () => (
    <>
      <div className={s.stepStateContainer}>
        <div className={s.stepState}>{step}</div>
        <h4>🗣️ Add a neighbour or friend</h4>
      </div>
      {readyToStep2 ? (
        <>
          {readyToStep3 ? (
            <div className={s.addingFriends}>
              <p>👇 Keep adding friends or go to the next step.</p>
            </div>
          ) : (
            <h6>Add more homes by clicking on the map or searching their address.</h6>
          )}

          <div className={s.info}>
            <LocationSearch
              label="Type the location here"
              dropdownPosition="bottom"
              className={s.searchInput}
              name="type-location"
              iconLeft="search"
              flyAfterSelect={true}
              value={selectedLocation?.place_name}
              onChange={setSecondLocationCall}
            />
          </div>
        </>
      ) : (
        <StepLocked />
      )}
    </>
  );

  const Step3 = () => (
    <>
      <div className={s.stepStateContainer}>
        <div className={s.stepState}>{step}</div>
        <h4>️ 💾 Save your community</h4>
      </div>

      {readyToStep3 ? (
        <>
          {isLoggedIn ? (
            <h6>
              {readyToStep4 && `Go to the next step to see how to run the simulation.`}
              {!readyToStep4 && `Save your community to run your simulation.`}
            </h6>
          ) : (
            <>
              <h6>In order to run your simulation you need to sign in.</h6>
              <div className={s.info}>
                <BaseButton
                  theme="grey"
                  className={s.authButton}
                  onClick={() => dispatch(openModal(EPredefinedModalIds.MODAL_AUTH_LOGIN))}>
                  Login
                </BaseButton>
                <BaseButton
                  className={s.authButton}
                  onClick={() => dispatch(openModal(EPredefinedModalIds.MODAL_AUTH_REGISTER))}>
                  Register
                </BaseButton>
              </div>
            </>
          )}
        </>
      ) : (
        <StepLocked />
      )}
    </>
  );

  const Step4 = () => (
    <>
      <div className={s.stepStateContainer}>
        <div className={s.stepState}>{step}</div>
        <h4> 📈 Run your Simulation</h4>
      </div>
      {/* <div className={s.info}> */}
      {readyToStep4 ? (
        <>
          <h6>And learn about your consumption optimisation, trading volume and much more.</h6>
          <div>
            {!stopButtonOptions.disabled && (
              <button
                type="button"
                className={classnames(s.abortBtn, s.centerAll, s.WZrunPauseBtn)}
                onClick={() => stopButtonOptions.onClick && stopButtonOptions.onClick()}
                disabled={stopButtonOptions.disabled}>
                {/* <BaseIcon icon={stopButtonOptions.icon} size={19.5} /> */}
              </button>
            )}
            {activeConfigurationUuid ? (
              <BaseButton
                className={classnames(s.runPauseBtn, s.WZrunPauseBtn)}
                theme={runButtonState === 'pause' ? 'secondary' : undefined}
                onClick={() => runButtonOptions.onClick && runButtonOptions.onClick()}
                icon={runButtonOptions.icon}
                disabled={runButtonOptions.disabled}>
                {runButtonOptions.title}
              </BaseButton>
            ) : (
              <div className={s.container} style={{ width: '100%' }}>
                <BaseButton isLoading={true} className={s.loading} size={'normal'}>
                  Saving
                </BaseButton>
              </div>
            )}
          </div>
        </>
      ) : (
        <StepLocked />
      )}
    </>
  );

  const renderSteps = (value) => {
    switch (value) {
      case 1:
        return <Step1 />;
      case 2:
        return <Step2 />;
      case 3:
        return <Step3 />;
      case 4:
        return <Step4 />;
      default:
        return null;
    }
  };

  const numberOfSteps = 4;
  const percentageTotalDividedBySteps = 100 / numberOfSteps;

  if (step == 0) return null;

  // if (step == 1 || step == 2 || step == 3 || step == 4) return <HelpCenter />;
  return (
    <BaseGenericModal
      inline={true}
      size={EModalSize.M255}
      modalId={EPredefinedModalIds.MODAL_ONBOARDING}
      className={s.modal}
      modalRef={modalRef}>
      <button type="button" className={s.cancelButton} onClick={handleCloseClick}>
        Cancel
        {/* <BaseIcon icon="close" size={9} /> */}
      </button>
      <div className={s.section}>
        <h4>Welcome onboard!</h4>
        <p>Follow the steps to create your first community</p>
        <BaseProgress progress={progressBar * percentageTotalDividedBySteps} />
      </div>
      {step == 0 ? null : (
        <div className={s.section}>
          <div className={s.lightContainer}>{renderSteps(step)}</div>

          <div className={s.pagination}>
            {step != 1 ? (
              <BaseButton
                disabled={step === 1}
                className={s.prevStepButton}
                onClick={() => {
                  AfterNextOrPrev(step - 1);
                }}>
                <BaseIcon
                  // style={{ marginLeft: '-9', marginRight: '5' }}
                  icon="arrow-left-full"
                  size={13}
                />
                {/* Prev */}
              </BaseButton>
            ) : null}

            <div className={s.stepsContainer}>
              {steps.map((d) => (
                <BaseButton
                  className={classnames(s.stepButton, {
                    [s.activeStep]: d === step,
                  })}
                  onClick={() => {
                    // dispatch(setGuideStep(d));
                    AfterNextOrPrev(d);
                  }}
                  key={d}>
                  {d}
                </BaseButton>
              ))}
            </div>
            {step == 4 ? (
              <BaseButton
                disabled={nextBtn}
                className={s.nextStepButton}
                onClick={() => {
                  dispatch(closeModal(EPredefinedModalIds.MODAL_ONBOARDING));
                }}>
                Close
                <BaseIcon icon="close" size={13} />
              </BaseButton>
            ) : (
              <BaseButton
                disabled={false}
                className={s.nextStepButton}
                onClick={() => {
                  dispatch(setGuideStep(step + 1));
                  AfterNextOrPrev(step + 1);
                }}>
                Next
                <BaseIcon
                  className={s.nextIcon}
                  // style={{
                  //   marginLeft: '-9',
                  //   marginRight: '5',
                  // }}
                  icon="arrow-left-full"
                  size={13}
                />
              </BaseButton>
            )}
          </div>
        </div>
      )}
    </BaseGenericModal>
  );
};
