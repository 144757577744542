import { About } from 'src/pages/About';
import { AccountActivated } from 'src/pages/AccountActivated';
import { Company } from 'src/pages/Company';
import { Contact } from 'src/pages/Contact';
import { DesktopLogin } from 'src/pages/DesktopLogin';
import { Embed } from 'src/pages/Embed';
import { Login } from 'src/pages/Login';
import { MailChimp } from 'src/pages/MailChimp';
import { MarketDesign } from 'src/pages/MarketDesign';
import { MarketDesign as MarketDesignMobile } from 'src/pages/MarketDesignMobile';
import { MyCommunities } from 'src/pages/MyCommunities';
import { PageNotFound } from 'src/pages/PageNotFound';
import { PrivacyPolicy } from 'src/pages/PrivacyPolicy';
import { ResetPassword } from 'src/pages/ResetPassword';
import { Resources } from 'src/pages/Resources';
import { RouteProps } from 'react-router-dom';
import { Scm } from 'src/pages/Scm';
import { ScmFAQs } from 'src/pages/ScmFAQs';
import { ScmInvitation } from 'src/pages/ScmInvitation';
import { ScmReset } from 'src/pages/ScmReset';
import { SingularityMap } from 'src/pages/SingularityMap';
import { TermsofService } from 'src/pages/TermsofService';
import { TestPage } from 'src/pages/TestPage';
import { YouAreEnergy } from 'src/pages/YouAreEnergy';

export const routesConfig = {
  home: (): string => '/',
  marketDesign: (): string => '/',
  mailChimp: (): string => '/mail-chimp',
  privacyPolicy: (): string => '/privacy-policy',
  termsOfService: (): string => '/terms-of-service',
  company: (): string => '/company',
  testPage: (): string => '/test-page',
  degreesOfFreedom: (): string => '/degrees-of-freedom',
  singularityMap: (): string => '/singularity-map',
  scmMap: (): string => '/scm-map',
  singularityMapCreate: (): string => '/singularity-map/create',
  scmSingularityMapResults: (configurationUuid?: string): string =>
    `/scm/singularity-map/results/${configurationUuid || ':configurationUuid'}`,
  singularityMapResults: (configurationUuid?: string, assetUuid?: string): string =>
    `/singularity-map/results/${configurationUuid || ':configurationUuid'}${
      typeof assetUuid === 'string' ? `/${assetUuid}` : '/:assetUuid'
    }`,
  scmMapResults: (configurationUuid?: string, assetUuid?: string): string =>
    `/scm-map/results/${configurationUuid || ':configurationUuid'}${
      typeof assetUuid === 'string' ? `/${assetUuid}` : '/:assetUuid'
    }`,
  embed: (configurationUuid?: string): string =>
    `/embed/${configurationUuid || ':configurationUuid'}`,
  youAreEnergy: (): string => '/you-are-energy',
  about: (): string => '/about',
  resources: (): string => '/resources',
  contact: (): string => '/contact',
  myCommunities: (): string => '/my-communities',
  confirmEmail: (): string => '/confirm-email/:token',
  accountActivated: (): string => '/account_activated',
  resetPassword: (): string => '/reset/:resetId/:resetKey',
  notFound: (): string => '/404',
  login: (): string => '/login',
  scmReset: (): string => '/scm/reset/:resetId/:resetKey',
  scm: (): string => '/scm',
  scmFaq: (): string => '/scm-faq',
};

export const routesTitles: {
  [route in keyof typeof routesConfig]: string;
} = {
  home: '',
  marketDesign: 'Market Design',
  mailChimp: 'Mail Chimp',
  privacyPolicy: 'Privacy Policy',
  termsOfService: 'Terms of Service',
  testPage: 'Test Page',
  company: 'Company',
  degreesOfFreedom: 'Degrees of Freedom',
  singularityMap: 'Singularity Map',
  scmMap: 'SCM Map',
  singularityMapCreate: 'Singularity Map',
  singularityMapResults: 'Singularity Map',
  scmMapResults: 'SCM Map Results',
  scmSingularityMapResults: 'SCM invitation',
  youAreEnergy: 'You Are Energy',
  about: 'About',
  resources: 'Resources',
  contact: 'Contact',
  myCommunities: 'My Communities',
  notFound: 'Page Not Found',
  confirmEmail: 'Confirm Email',
  accountActivated: 'Account Activated',
  resetPassword: 'Reset Password',
  login: 'Login',
  embed: 'Singularity Map',
  scm: 'SCM',
  scmFaq: 'SCM FAQs',
  scmReset: 'SCM Reset Password',
};

export type TRouteNames = keyof typeof routesConfig;

export type TAppRouteProps = {
  private?: boolean;
  routeName: TRouteNames;
} & RouteProps;

export const routes: TAppRouteProps[] = [
  {
    routeName: 'testPage',
    path: routesConfig.testPage(),
    component: TestPage,
    private: true,
  },
  {
    routeName: 'scmSingularityMapResults',
    path: routesConfig.scmSingularityMapResults(),
    component: ScmInvitation,
    exact: true,
  },
  {
    routeName: 'home',
    path: routesConfig.marketDesign(),
    component: MarketDesign,
    exact: true,
  },
  {
    routeName: 'mailChimp',
    path: routesConfig.mailChimp(),
    component: MailChimp,
    exact: true,
  },
  {
    routeName: 'privacyPolicy',
    path: routesConfig.privacyPolicy(),
    component: PrivacyPolicy,
    exact: true,
  },
  {
    routeName: 'termsOfService',
    path: routesConfig.termsOfService(),
    component: TermsofService,
    exact: true,
  },
  {
    routeName: 'degreesOfFreedom',
    path: routesConfig.degreesOfFreedom(),
    // component: DegreesOfFreedom,
    component: Contact, // Temporary
    exact: true,
  },
  {
    routeName: 'singularityMap',
    path: [routesConfig.singularityMap(), routesConfig.scmMap()],
    component: SingularityMap,
  },
  {
    routeName: 'youAreEnergy',
    path: routesConfig.youAreEnergy(),
    component: YouAreEnergy,
    exact: true,
  },
  {
    routeName: 'about',
    path: routesConfig.about(),
    component: About,
    exact: true,
  },
  {
    routeName: 'company',
    path: `${routesConfig.company()}/:page?/:memberId?`,
    component: Company,
    exact: true,
  },
  {
    routeName: 'resources',
    path: `${routesConfig.resources()}/:page?/:slug?`,
    component: Resources,
    exact: true,
  },
  {
    routeName: 'contact',
    path: routesConfig.contact(),
    component: Contact,
    exact: true,
  },
  {
    routeName: 'resetPassword',
    path: routesConfig.resetPassword(),
    component: ResetPassword,
    exact: true,
  },
  {
    routeName: 'confirmEmail',
    path: routesConfig.confirmEmail(),
    component: ({ match }: { match: { params: { token: string } } }): null => {
      const endpoint = 'rest-accounts/registration/account-confirm-email/';
      window.location.href = process.env.REACT_APP_D3A_WEB_API_URL + endpoint + match.params.token;
      return null;
    },
    exact: true,
  },
  {
    routeName: 'accountActivated',
    path: routesConfig.accountActivated(),
    component: AccountActivated,
    exact: true,
  },

  {
    routeName: 'embed',
    path: routesConfig.embed(),
    component: Embed,
    exact: true,
  },
  {
    routeName: 'scm',
    path: routesConfig.scm(),
    component: Scm,
    exact: true,
  },
  {
    routeName: 'scmFaq',
    path: routesConfig.scmFaq(),
    component: ScmFAQs,
    exact: true,
  },
  {
    routeName: 'login',
    path: routesConfig.login(),
    component: DesktopLogin,
    exact: true,
  },
  {
    routeName: 'scmReset',
    path: routesConfig.scmReset(),
    component: ScmReset,
  },
  {
    routeName: 'notFound',
    path: '*',
    component: PageNotFound,
  },
];

export const mobileRoutes: TAppRouteProps[] = [
  {
    routeName: 'home',
    path: routesConfig.marketDesign(),
    component: MarketDesignMobile,
    exact: true,
  },
  {
    routeName: 'scmSingularityMapResults',
    path: routesConfig.scmSingularityMapResults(),
    component: ScmInvitation,
    exact: true,
  },
  {
    routeName: 'mailChimp',
    path: routesConfig.mailChimp(),
    component: MailChimp,
    exact: true,
  },
  {
    routeName: 'privacyPolicy',
    path: routesConfig.privacyPolicy(),
    component: PrivacyPolicy,
    exact: true,
  },
  {
    routeName: 'termsOfService',
    path: routesConfig.termsOfService(),
    component: TermsofService,
    exact: true,
  },
  {
    routeName: 'myCommunities',
    path: routesConfig.myCommunities(),
    component: MyCommunities,
    exact: true,
    private: true,
  },
  {
    routeName: 'singularityMap',
    path: [routesConfig.singularityMap(), routesConfig.scmMap()],
    component: SingularityMap,
    private: true,
  },
  {
    routeName: 'scm',
    path: routesConfig.scm(),
    component: Scm,
  },
  {
    routeName: 'scmFaq',
    path: routesConfig.scmFaq(),
    component: ScmFAQs,
  },
  {
    routeName: 'scmReset',
    path: routesConfig.scmReset(),
    component: ScmReset,
  },
  {
    routeName: 'login',
    path: routesConfig.login(),
    component: Login,
  },
  {
    routeName: 'company',
    path: `${routesConfig.company()}/:page?/:memberId?`,
    component: Company,
    exact: true,
  },
  {
    routeName: 'resources',
    path: `${routesConfig.resources()}/:page?`,
    component: Resources,
    exact: true,
  },
  {
    routeName: 'marketDesign',
    path: routesConfig.marketDesign(),
    component: MarketDesignMobile,
    exact: true,
  },
  {
    routeName: 'resetPassword',
    path: routesConfig.resetPassword(),
    component: ResetPassword,
    exact: true,
  },
  {
    routeName: 'notFound',
    path: '*',
    component: PageNotFound,
  },
];
