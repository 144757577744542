import { SettingsInput, SpotMarketType } from 'src/graphql';

import { TSettingsData } from 'src/utils/assetsFields/assetsFields.types';

export function prepareSettingsInput(settingsData: TSettingsData): SettingsInput {
  const {
    slotLengthMinutes,
    tickLengthSeconds,
    marketCount,
    slotLengthRealtimeSeconds,
    startDate,
    endDate,
    spotMarketType,
    bidOfferMatchAlgo,
    currency,
  } = settingsData;

  const output: SettingsInput = {
    slotLengthMinutes,
    tickLengthSeconds,
    marketCount,
    slotLengthRealtimeSeconds,
    startDate,
    endDate,
    spotMarketType,
    ...(spotMarketType === SpotMarketType.TwoSided && { bidOfferMatchAlgo }),
    currency,
  };

  return output;
}
