import React, { useMemo } from 'react';
import {
  TCanaryAssetsTree,
  TCanaryRegistryAssetsListProps,
} from 'src/components/MapSidebar/components/MapSidebarCanary/components/CanaryRegistryList';

import { CanaryRegistryAssetTile } from 'src/components/MapSidebar/components/MapSidebarCanary/components/CanaryRegistryList/CanaryRegistryAssetTile';
import { TCanaryAssetState } from 'src/components/MapSidebar/components/MapSidebarCanary';
import s from './CanaryRegistryList.module.scss';
import { selectUsername } from 'src/redux/auth/auth.selectors';
import { useSelector } from 'react-redux';
import { get2dAssetIcon } from 'src/utils/assetIcons';
import { TCanaryOrderedAssetTree } from 'src/components/MapSidebar/components/MapSidebarCanary/components/CanaryRegistryList/CanaryRegistryList.types';

export const CanaryRegistryAssetsList: React.FC<TCanaryRegistryAssetsListProps> = ({
  assetsData,
  usersData,
  usersToAssetRelations,
  selectedItems,
  searchValue,
  onItemClick,
  getParentUuid,
  getOwners,
  getAppliedUser,
}) => {
  const userName = useSelector(selectUsername);

  const assetsTreeData = useMemo<TCanaryAssetsTree>(
    () =>
      assetsData.reduce<TCanaryAssetsTree>((acc, asset) => {
        const isSearched =
          searchValue.length > 0
            ? asset.name.toLowerCase().includes(searchValue.toLowerCase())
            : true;

        if (!isSearched) return acc;

        const parentUuid = getParentUuid(asset.uuid);
        const owners = getOwners(asset.uuid);
        const appliedUser = getAppliedUser(asset.uuid);
        const assetIcon = get2dAssetIcon(asset.type);

        const data = {
          uuid: asset.uuid,
          name: asset.name,
          icon: assetIcon,
          type: asset.type,
          owners: owners,
          status: (appliedUser ? 'Applied' : usersToAssetRelations[userName]) as TCanaryAssetState,
          appliedUserName: appliedUser?.userName,
          appliedUserAvatar: appliedUser?.avatar,
          parentUuid: parentUuid || null,
        };

        if (asset.type === 'Area' || asset.type === 'InfiniteBus') {
          acc[asset.uuid] = { data, children: [] };
        } else {
          if (parentUuid && acc[parentUuid]) {
            acc[parentUuid].children.push(data);
          } else {
            acc[asset.uuid] = { data, children: [] };
          }
        }

        return acc;
      }, {}),
    [
      assetsData,
      getAppliedUser,
      getOwners,
      getParentUuid,
      searchValue,
      userName,
      usersToAssetRelations,
    ],
  );

  const orderedAssetTree = useMemo<TCanaryOrderedAssetTree>(() => {
    const assetsNamesAndKeys = Object.keys(assetsTreeData).map((item) => ({
      name: assetsTreeData[item].data.name,
      item: assetsTreeData[item],
    }));
    const orderedAssetNames = assetsNamesAndKeys.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    );

    const staticItemNames = ['Community', 'Grid Market'];

    const staticItems = orderedAssetNames.filter((item) => staticItemNames.includes(item.name));

    const orderedList = [
      ...staticItems,
      ...orderedAssetNames.filter((item) => !staticItemNames.includes(item.name)),
    ];

    return orderedList;
  }, [assetsTreeData]);

  const selectboxUsersList = useMemo(
    () =>
      usersData
        .filter((user) => !user.isAggregator && !user.isGridOperator)
        .map(({ name, uuid }) => ({ name, uuid })),
    [usersData],
  );

  return (
    <div className={s.assetsList}>
      {orderedAssetTree.map((assetTree, i) => {
        const asset = assetTree.item;
        const isSelected = selectedItems.includes(asset.data.uuid);
        const isChildSelected = asset.children.some((item) => selectedItems.includes(item.uuid));

        return (
          <React.Fragment key={i}>
            <CanaryRegistryAssetTile
              data={asset.data}
              childrenCount={asset.children.length}
              usersList={selectboxUsersList}
              selected={isSelected}
              onClick={onItemClick}
            />
            {(isSelected || isChildSelected) &&
              asset.children.map((childAsset) => {
                const selected = selectedItems.includes(childAsset.uuid);
                return (
                  <CanaryRegistryAssetTile
                    key={childAsset.uuid}
                    data={childAsset}
                    isChildren
                    usersList={selectboxUsersList}
                    selected={selected}
                    onClick={onItemClick}
                  />
                );
              })}
          </React.Fragment>
        );
      })}
    </div>
  );
};
