import React, { useMemo, useState } from 'react';

import { AssetBlock } from '../AssetBlock';
import { BaseButton } from '../BaseButton';
import { TAssetCountProps } from './AssetCount.types';
import { TBaseIconProps } from '../BaseIcon';
import classnames from 'classnames';
import { formatter } from '../../utils/formatter';
import s from './AssetCount.module.scss';
import { selectSidebarExpanded } from 'src/redux/application/application.selectors';
import { useSelector } from 'react-redux';

export const AssetCount: React.FC<TAssetCountProps> = ({ assetsInfo: assetsInfo }) => {
  const sidebarExpanded = useSelector(selectSidebarExpanded);
  const [showAdditionalAssets, setShowAdditionalAssets] = useState(false);

  const assets = useMemo(() => {
    if (!assetsInfo) return [];

    return [
      {
        title: `${assetsInfo.number_of_house_type} Homes`,
        description: `Avg Asset per Home: ${formatter.toOptionalFixed(
          assetsInfo.avg_assets_per_house,
          0,
        )}`,
        icon: 'house-3d',
      },
      {
        title: `${assetsInfo.number_of_load_type} Loads`,
        description: `Total Demand: ${formatter.toOptionalFixed(
          assetsInfo.total_energy_demand_kwh,
          0,
        )} kWh`,
        icon: 'plug-3d',
      },
      {
        title: `${assetsInfo.number_of_pv_type} PV`,
        description: `Total Generated: ${formatter.toOptionalFixed(
          assetsInfo.total_energy_generated_kwh,
          0,
        )} kWh`,
        icon: 'solar-3d',
      },
      {
        title: `${assetsInfo.number_of_storage_type} Batteries`,
        description: `Total Capacity: ${formatter.toOptionalFixed(
          assetsInfo.total_energy_capacity_kwh,
          0,
        )} kWh`,
        icon: 'battery-3d',
      },
      {
        title: `${assetsInfo.number_of_power_plant_type} Power Plant`,
        description: `Maximum Power: ${formatter.toOptionalFixed(
          assetsInfo.max_power_plant_power_kw,
          0,
        )} kW`,
        icon: 'power-plant-3d',
      },
      {
        title: `${assetsInfo.number_of_heatpump_type} Heat Pumps`,
        description: '',
        icon: 'heatpump-3d',
      },
    ];
  }, [assetsInfo]);

  const [items, moreItems] = useMemo(() => {
    if (sidebarExpanded) {
      return [assets, []];
    }

    // "false" value is used to create empty .arrayWrapper to align items like in first row
    // Should be modified every time "assets" length will changes
    return [assets.slice(0, 3), [...assets.slice(3), false]];
  }, [assets, sidebarExpanded]);

  return (
    <div className={s.assetCounterWrapper}>
      <div className={classnames(s.assets, s.expanded)}>
        {items.map((item, index) => (
          <div className={s.assetWrapper} key={index}>
            <AssetBlock
              className={s.singleAsset}
              icon={item.icon as TBaseIconProps['icon']}
              title={item.title}
              subtitle={item.description}
              aspectRatio={133}
              theme="light"
            />
          </div>
        ))}
      </div>
      {!sidebarExpanded && (
        <>
          {!showAdditionalAssets ? (
            <BaseButton
              className={s.buttonExpand}
              theme="grey"
              size="small"
              onClick={() => setShowAdditionalAssets(true)}>
              More
            </BaseButton>
          ) : null}
          <div className={classnames(s.assets, s.expanded)}>
            {showAdditionalAssets &&
              moreItems
                .filter((item) => typeof item !== 'boolean')
                .map(
                  (item, index) =>
                    typeof item !== 'boolean' && (
                      <div className={s.assetWrapper} key={index}>
                        <AssetBlock
                          className={s.singleAsset}
                          icon={item.icon as TBaseIconProps['icon']}
                          title={item.title}
                          subtitle={item.description}
                          aspectRatio={133}
                          theme="light"
                        />
                      </div>
                    ),
                )}
          </div>
          {showAdditionalAssets ? (
            <BaseButton
              className={s.buttonExpand}
              theme="grey"
              size="small"
              onClick={() => setShowAdditionalAssets(false)}>
              Less
            </BaseButton>
          ) : null}
        </>
      )}
    </div>
  );
};
