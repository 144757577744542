import { RootState } from 'src/redux/store';
import { TMapState } from 'src/redux/map/map.slice';

export const selectSelectedLocation = (state: RootState): TMapState['selectedLocation'] =>
  state.map.selectedLocation;

export const selectVisibleLocation = (state: RootState): TMapState['visibleLocation'] =>
  state.map.visibleLocation;

export const selectIs3DView = (state: RootState): TMapState['is3DView'] => state.map.is3DView;

export const selectGlobalSimulationLocations = (
  state: RootState,
): TMapState['globalSimulationLocations'] => state.map.globalSimulationLocations;
