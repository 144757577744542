import React from 'react';
import s from './Tooltip.module.scss';
import { TToolTipProps } from './Tooltip.types';
import classnames from 'classnames';

export const ToolTip: React.FC<TToolTipProps> = ({
  children,
  title,
  className,
  position,
  inline,
}) => {
  return (
    <div
      className={classnames(className, {
        [s.tooltipTop]: position === 'top',
        [s.tooltipBottom]: position === 'bottom',
        [s.tooltipRight]: position === 'right',
        [s.tooltipLeft]: position === 'left',
        [s.inline]: inline,
      })}>
      {children}
      <span className={classnames(s.tooltiptext)}>{title}</span>
    </div>
  );
};
