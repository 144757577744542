// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import Fade from 'react-reveal/Fade';
import feature1 from 'src/assets/images/Scm/feature1.png';
import feature2 from 'src/assets/images/Scm/feature2.png';
import feature3 from 'src/assets/images/Scm/feature3.png';
import feature4 from 'src/assets/images/Scm/feature4.png';
import featureImg1 from 'src/assets/images/Scm/icon-analytics.svg';
import featureImg4 from 'src/assets/images/Scm/icon-billing.svg';
import featureImg3 from 'src/assets/images/Scm/icon-collective.svg';
import featureImg2 from 'src/assets/images/Scm/icon-energy.svg';
import { BaseButton } from 'src/components/BaseButton';
import { BaseIcon } from 'src/components/BaseIcon';
import { BaseLink } from 'src/components/BaseLink';
import { Header } from 'src/components/Header';
import { LandingPageFooter } from 'src/components/LandingPage/LandingPageFooter';
import { NetworkGraph } from 'src/components/NetworkGraph';
import { EPredefinedModalIds } from 'src/constants/modals';
import ContentfulContext, { ContentfulContextProvider } from 'src/contexts/ContentfulContext';
import { APP_FLOW, useAppFlow } from 'src/hooks/useAppFlow';
import { closeModal } from 'src/redux/modals/modals.slice';
import { setSCMFlow } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { routesConfig } from 'src/routes/routes.config';
import { getAppMode } from 'src/utils/appMode';

import s from './Scm.module.scss';

export const Scm: React.FC = () => {
  const dispatch = useAppDispatch();
  const { updateAppFlow, resetSCMSteps } = useAppFlow();
  const mode = getAppMode();

  const isMobile = mode === 'mobile';
  const headerColorMode = 'white';
  const containerRef = useRef<HTMLDivElement>(null);
  const featureSection1 = useRef<HTMLDivElement>(null);
  const featureSection2 = useRef<HTMLDivElement>(null);
  const featureSection3 = useRef<HTMLDivElement>(null);
  const featureSection4 = useRef<HTMLDivElement>(null);
  const [isShowingArrow, setShowingArrow] = useState(true);

  const sectionIcons = {
    section0: featureImg1,
    section1: featureImg2,
    section2: featureImg3,
    section3: featureImg4,
  };

  const [y, setY] = useState(0);

  const handleNavigation = useCallback(
    (e) => {
      if (!isMobile) {
        const scrollVal = e.target.scrollTop;
        if (y < scrollVal) {
          setShowingArrow(false);
        } else if (scrollVal == 0) {
          setShowingArrow(true);
        }
        setY(scrollVal);
      } else {
        setShowingArrow(true);
      }
    },
    [y, isMobile],
  );

  useEffect(() => {
    resetSCMSteps();
    updateAppFlow(APP_FLOW.SCM);
    dispatch(setSCMFlow(true));
  }, [dispatch, updateAppFlow, resetSCMSteps]);

  useEffect(() => {
    dispatch(closeModal(EPredefinedModalIds.MODAL_AUTH_LOGIN));
  }, [dispatch]);

  const RenderFeatureContent = () => {
    const { scmFeatures } = useContext(ContentfulContext);

    return (
      <>
        {scmFeatures &&
          scmFeatures.length > 0 &&
          scmFeatures?.map((item, key) => (
            <div key={key.toString()} className={s.col4}>
              <div className={s.features}>
                <img src={sectionIcons['section' + key]} />

                <h4>{item.title}</h4>
                <p>{item.subContent}</p>
              </div>
            </div>
          ))}
      </>
    );
  };

  const RenderFeatureItem = (props) => {
    const { scmFeatures } = useContext(ContentfulContext);

    const contentForFeature = scmFeatures[props.itemNumber];
    const listOfFeature = isEmpty(contentForFeature) ? [] : contentForFeature.listOfFeature;
    if (isEmpty(contentForFeature)) {
      return <></>;
    } else {
      return (
        <div className={s.featureItem}>
          <img src={sectionIcons['section' + props.itemNumber]} />
          <h2>{contentForFeature?.subtitle} </h2>
          <ul>
            {listOfFeature &&
              listOfFeature.length > 0 &&
              listOfFeature.map((item, index) => (
                <li key={index} className={s.customList}>
                  <div className={s.customCheck}>
                    <BaseIcon icon="check-mark" size={20} className={s.arrow} />
                  </div>
                  <div>
                    <p>{item}</p>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      );
    }
  };

  return (
    <div className={s.scmlandingpage}>
      <ContentfulContextProvider>
        <Header isLandingPage headerColorMode={headerColorMode} />
        <div className={s.content} ref={containerRef} onScroll={handleNavigation}>
          <div className={s.container}>
            <div className={s.bannerwrapper}>
              <div className={s.section}>
                <div className={s.col1}>
                  <div className={s.banner}>
                    <h1 className={s.title}>
                      <span>Social Community</span> Manager
                    </h1>
                    <h4 className={s.subtitle}>
                      Commercial application for energy community operation and growth
                    </h4>
                    <BaseLink
                      theme="4"
                      to="mailto:contact@gridsingularity.com?subject=Requesting a Demo from SCM&body=Hi, %0D%0A %0D%0A I would like to get a demo of the social community manager.%0D%0A Please let me know what times are available this week.%0D%0A %0D%0A Best regards,%0D%0A [insert name]">
                      <BaseButton className={s.primary} theme="tertiary">
                        Request a Demo
                      </BaseButton>
                    </BaseLink>
                  </div>
                </div>
                <div className={classnames(s.col2, s.colHideXS)}>
                  <NetworkGraph />
                </div>
                <div className={s.bottomToTopFade}>
                  <Fade top>
                    <BaseButton
                      theme="tertiary"
                      className={`${s.scrollBtn} ${!isShowingArrow ? s.scrollBtnDisabled : {}}`}>
                      <BaseIcon className={s.buttonScroll} icon="arrow-down" size={24} />
                    </BaseButton>
                  </Fade>
                </div>
              </div>
            </div>

            <div className={classnames(s.section)}>
              <RenderFeatureContent />
            </div>

            <div className={s.section} ref={featureSection1}>
              <div className={s.col2}>
                <RenderFeatureItem itemNumber="0" />
              </div>
              <div className={s.col2}>
                <div className={s.featureImage}>
                  <img src={feature1} />
                </div>
              </div>
            </div>

            <div className={classnames(s.section, s.rowReverse)} ref={featureSection2}>
              <div className={s.col2}>
                <RenderFeatureItem itemNumber="1" />
              </div>
              <div className={s.col2}>
                <div className={s.featureImage}>
                  <img src={feature2} />
                </div>
              </div>
            </div>

            <div className={s.section} ref={featureSection3}>
              <div className={s.col2}>
                <RenderFeatureItem itemNumber="2" />
              </div>
              <div className={s.col2}>
                <div className={s.featureImage}>
                  <img src={feature3} />
                </div>
              </div>
            </div>

            <div className={classnames(s.section, s.sectionReverse)} ref={featureSection4}>
              <div className={s.col2}>
                <div className={s.featureImage}>
                  <img src={feature4} />
                </div>
              </div>
              <div className={s.col2}>
                <RenderFeatureItem itemNumber="3" />
              </div>
            </div>

            <div className={classnames(s.section, s.footer)}>
              <BaseLink
                theme="4"
                to="mailto:contact@gridsingularity.com?subject=Requesting a Demo from SCM&body=Hi, %0D%0A %0D%0A I would like to get a demo of the social community manager.%0D%0A Please let me know what times are available this week.%0D%0A %0D%0A Best regards,%0D%0A [insert name]">
                <BaseButton className={s.button} theme="tertiary">
                  Request a Demo
                </BaseButton>
              </BaseLink>
              <h4 className={s.title}>
                For a review of frequently asked questions and answers, please see{' '}
                <a href={routesConfig.scmFaq()}>here</a>.
              </h4>
            </div>
            <LandingPageFooter variant="scm" />
          </div>
        </div>
      </ContentfulContextProvider>
    </div>
  );
};
