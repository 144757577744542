import gql from 'graphql-tag';

export const SimulationStatusSubscription = gql`
  subscription simulationStatus($jobId: String!, $uuid: String!) {
    simulationAreaResultsPartial(jobId: $jobId, areaUUID: $uuid) {
      status
      progressInfo {
        percentageCompleted
        etaSeconds
        elapsedTimeSeconds
      }
    }
  }
`;
