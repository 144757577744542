import { Result } from '@mapbox/mapbox-gl-geocoder';
import { TLngLat } from 'src/typings/base-types';

export async function mapboxGetCall<P>(uri: string, params?: RequestInit): Promise<P | null> {
  try {
    const url = new URL(uri);
    const searchParams = new URLSearchParams();
    searchParams.set('access_token', process.env.REACT_APP_D3A_MAPBOX_KEY as string);
    url.search = searchParams.toString();
    const response = await fetch(url.toString(), {
      ...params,
      method: 'GET',
    });
    if (response.status !== 200 || !response.ok) throw new Error('Mapbox request error');
    return response.json();
  } catch (err) {
    console.error(err);
    return null;
  }
}

export type TMapboxLocation = TLngLat & {
  place_name: string;
  place_type: string[];
  text: string;
  bbox?: [number, number, number, number];
  id?: string;
};

type TMapboxLocationCollection = {
  features: Array<Result>;
};

export const getLocationDetails = async ({
  lng,
  lat,
}: TLngLat): Promise<TMapboxLocationCollection | null> =>
  await mapboxGetCall<TMapboxLocationCollection>(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json`,
  );
