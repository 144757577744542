import MapboxGeocoder, { Result } from '@mapbox/mapbox-gl-geocoder';
import { TLngLat, TLngLatArray } from 'src/typings/base-types';
import { TSource, mapSourceHighlightBuildings } from 'src/services/map/map.sources';
import { mapLayer3DBuildings, mapLayerHighlightBuildings } from 'src/services/map/map.layers';
import mapboxgl, { LngLatBoundsLike, MapboxOptions } from 'mapbox-gl';

import { ThreeboxController } from 'src/components/WorldMap/components/ThreeboxController';

const GEOCODER_OPTIONS: MapboxGeocoder.GeocoderOptions = {
  accessToken: process.env.REACT_APP_D3A_MAPBOX_KEY as string,
};

export type TViewport = {
  width: number;
  height: number;
  lat: number;
  lng: number;
  zoom: number;
  bearing: number;
  pitch: number;
  altitude?: number;
  maxZoom?: number;
  minZoom?: number;
  maxPitch?: number;
  minPitch?: number;
};

type TServiceListeners =
  | 'load'
  | 'render'
  | 'click'
  | 'mousemove'
  | 'moveend'
  | 'zoom'
  | 'wheel'
  | 'styleLoad'
  | 'geolocate';

export type TFlyToOptions = {
  specificZoom?: number;
  fitBoundsOffset?: [number, number];
  speed?: 1 | 2.5;
  pitch?: 45 | 0;
};

type BboxTuple = [number, number, number, number];

export class WorldMapService {
  threeboxController: ThreeboxController;
  private _map: mapboxgl.Map;
  private _geolocateControl: mapboxgl.GeolocateControl;
  private _subscribedListeners: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key in TServiceListeners]?: ((e: any) => void)[];
  } = {};

  get map(): mapboxgl.Map {
    return this._map;
  }

  constructor(container: HTMLDivElement, settings: MapboxOptions) {
    this._map = this._createMap(container, settings);

    // Create Controls
    this._geolocateControl = new mapboxgl.GeolocateControl({
      showAccuracyCircle: false,
      showUserLocation: false,
    });

    // Setup Threebox
    this.threeboxController = new ThreeboxController(this._map);

    // Add Controls
    this._map.addControl(this._geolocateControl);

    // Subscribe Initial Events
    this._subscribeEvents();

    // Add sources
    this.addSources([mapSourceHighlightBuildings]);

    // Add layers
    this.addLayers([
      mapLayer3DBuildings,
      mapLayerHighlightBuildings,
      this.threeboxController.getLayer(),
    ]);
  }

  // renew ThreeboxController
  public renewThreeboxController(): void {
    this.threeboxController = new ThreeboxController(this._map);
  }

  private _createMap(container: HTMLDivElement, settings: MapboxOptions): mapboxgl.Map {
    if (!mapboxgl.supported()) {
      alert('Your browser does not support Mapbox GL');
    }

    return new mapboxgl.Map({
      ...settings,
      // zoom: 13.4,
      container: container,
    });
  }

  private _subscribeEvents(): void {
    this._map.on('render', (e) => {
      this._subscribedListeners.render?.forEach((listener) => listener(e));
    });
    this._map.on('click', (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
      this._subscribedListeners.click?.forEach((listener) => listener(e));
    });
    this._map.on('mousemove', (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
      this._subscribedListeners.mousemove?.forEach((listener) => listener(e));
    });
    this._map.on('moveend', () => {
      const viewport = this.getViewport();
      this._subscribedListeners.moveend?.forEach((listener) => listener(viewport));
    });
    this._map.on('zoom', () => {
      const viewport = this.getViewport();
      this._subscribedListeners.zoom?.forEach((listener) => listener(viewport));
    });
    this._map.on('wheel', () => {
      const viewport = this.getViewport();
      this._subscribedListeners.wheel?.forEach((listener) => listener(viewport));
    });
    this._map.on('load', () => {
      const viewport = this.getViewport();
      this._subscribedListeners.load?.forEach((listener) => listener(viewport));
    });
    this._map.on('style.load', () => {
      const viewport = this.getViewport();
      this._subscribedListeners.styleLoad?.forEach((listener) => listener(viewport));
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this._geolocateControl.on('geolocate', (e: any) => {
      this._subscribedListeners.geolocate?.forEach((listener) => listener(e));
    });
  }

  addListener<T>(event: TServiceListeners, listener: (e: T) => void): void {
    if (!this._subscribedListeners[event]) {
      this._subscribedListeners[event] = [];
    }
    this._subscribedListeners[event]?.push(listener);
  }

  removeListener<T>(event: TServiceListeners, listener: (e: T) => void): void {
    this._subscribedListeners[event] = this._subscribedListeners[event]?.filter(
      (item) => item !== listener,
    );
  }

  addLayers(layers: mapboxgl.AnyLayer[]): void {
    this._map.on('load', () => {
      layers.forEach((layer) => {
        this._map.addLayer(layer);
      });
    });
  }

  addSources(sources: TSource[]): void {
    this._map.on('load', () => {
      sources.forEach((source) => {
        this._map.addSource(source.id, source.source);
      });
    });
  }

  getViewport(): TViewport {
    const { width, height } = this._map.getContainer().getBoundingClientRect();
    const center = this._map.getCenter();
    const zoom = this._map.getZoom();
    const bearing = this._map.getBearing();
    const pitch = this._map.getPitch();
    return {
      width,
      height,
      bearing,
      pitch,
      zoom,
      ...center,
    };
  }

  zoomIn(): void {
    this._map.zoomIn();
  }

  zoomOut(): void {
    this._map.zoomOut();
  }

  fitBounds(bounds: LngLatBoundsLike): void {
    this._map.fitBounds(bounds);
  }

  geolocate(): void {
    this._geolocateControl.trigger();
  }

  set3DView(value: boolean): void {
    this._map.easeTo({ pitch: value ? 45 : 0 });
  }

  is3DView(): boolean {
    return this._map.getPitch() > 0;
  }

  createGeocoder(options?: Partial<MapboxGeocoder.GeocoderOptions>): MapboxGeocoder {
    return new MapboxGeocoder({
      ...GEOCODER_OPTIONS,
      ...options,
      mapboxgl: this._map as mapboxgl.Map,
    });
  }

  minMaxArea(value: number): number {
    return Math.min(Math.max(value, -90), 90);
  }

  bboxCalculate(bboxValues: BboxTuple): mapboxgl.LngLatBoundsLike {
    return bboxValues.map((item) => this.minMaxArea(item)) as BboxTuple;
  }

  flyTo(location: Partial<TLngLat> & Partial<Result>, options: TFlyToOptions = {}): void {
    const { specificZoom, speed = 1, pitch = this._map.getPitch() } = options;
    //const { width } = this._map.getContainer().getBoundingClientRect();

    const opts: { [key: string]: number } = {
      speed,
      pitch,
    };
    if (specificZoom) {
      opts.zoom = specificZoom;
    } else {
      // opts.padding = Math.round(width / 3);
    }
    if (location.bbox) {
      // this._map.fitBounds(this.bboxCalculate(location.bbox), opts);
      const locations: [number, number, number, number] = location.bbox;
      // min and max lat and long for bounding
      // southwestern and  northeastern corner of the bounds
      // [180, -90], [-180, 90]
      this._map.fitBounds(
        [
          [locations[0] < 180 ? locations[0] : 180, locations[1] > -90 ? locations[1] : -90],
          [locations[2] > -180 ? locations[2] : -180, locations[3] < 90 ? locations[3] : 90],
        ],
        opts,
      );
    } else if (location.center || (location.lat && location.lng)) {
      this._map.flyTo({
        center: location.center
          ? [location.center[0], location.center[1]]
          : { lat: location.lat as number, lng: location.lng as number },
        ...opts,
      });
    }
  }

  selected3DBuildings(lnglat: TLngLatArray | undefined | null): Array<GeoJSON.Feature> | undefined {
    if (!lnglat || !this._map.getLayer('building')) return;

    const point = this._map.project(lnglat);
    if (!point) return;

    const bFeatures = this._map.queryRenderedFeatures([point.x, point.y], {
      layers: ['building'],
    });

    const relatedFeatures = bFeatures
      .map((bFeature) => {
        return this._map.querySourceFeatures('composite', {
          sourceLayer: 'building',
          filter: ['all', ['==', ['id'], bFeature.id ? bFeature.id : -1]],
        });
      })
      .flat(1);

    return relatedFeatures;
  }

  highlight3DBuildings(features: Array<GeoJSON.Feature>): void {
    if (!this._map.getLayer('3d-buildings')) return;

    const highlightBuildingSource = this._map.getSource(
      mapSourceHighlightBuildings.id,
    ) as mapboxgl.GeoJSONSource;

    // Disable original buildings
    const filter = features.reduce(
      (memo, feature) => {
        if (feature.id) {
          memo = [...memo, ['!=', ['id'], feature.id]];
        }
        return memo;
      },
      ['all', ['!=', ['id'], -1]],
    );
    this._map.setFilter('3d-buildings', filter);

    // Highlight buildings
    if (highlightBuildingSource)
      highlightBuildingSource.setData({
        type: 'FeatureCollection',
        features,
      });
  }
}
