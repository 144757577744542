import { BaseButton } from 'src/components/BaseButton';
import React from 'react';
import classnames from 'classnames';
import s from './ModalAssetsManager.module.scss';
import { selectReadOnly } from 'src/redux/configuration/configuration.selectors';
import { useSelector } from 'react-redux';

type ModalAssetManagerSubmitButtonProps = {
  children?: React.ReactNode;
  formId: string;
  addToLibrary?: boolean;
  handleAddToLibrary?: () => void;
};
export const ModalAssetManagerSubmitButton: React.FC<ModalAssetManagerSubmitButtonProps> = ({
  children = 'Save',
  addToLibrary = false,
  formId,
  handleAddToLibrary,
}) => {
  const readOnly = useSelector(selectReadOnly);

  if (readOnly) return <div className={s.paddingB} />;

  return (
    <div className={classnames(s.paddingLR, s.paddingB)}>
      <BaseButton type="submit" className={s.saveBtn} form={formId}>
        {children}
      </BaseButton>
      {addToLibrary && (
        <BaseButton
          type="button"
          className={s.saveBtn}
          onClick={() => {
            handleAddToLibrary?.();
          }}>
          Add to Library
        </BaseButton>
      )}
    </div>
  );
};
