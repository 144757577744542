export const NAV_LINKS = [
  {
    route: 'home',
    title: 'Home',
  },
  {
    route: 'singularityMap',
    title: 'Singularity Map',
  },
  {
    route: 'scm',
    title: 'Community Builder',
  },
  {
    route: 'company',
    title: 'Company',
  },
  {
    route: 'resources',
    title: 'Resources',
  },
];
