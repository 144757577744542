import React from 'react';

import { TAxisXCustomProps } from 'src/components/_charts/AxisXCustom/AxisXCustom.types';
import { UTCMoment } from 'src/utils/UTCMoment';
import classNames from 'classnames';
import s from './AxisXCustom.module.scss';

export const AxisXCustom: React.FC<TAxisXCustomProps> = React.memo(
  ({ style, className, startUnix, endUnix }) => {
    if (!endUnix || !startUnix) return <div className={classNames(s.container, className)} />;

    const hours = UTCMoment.fromUnix(endUnix)
      .endOf('day')
      .diff(UTCMoment.fromUnix(startUnix).startOf('day'), 'hours');

    const ticks: JSX.Element[] = [];

    if (hours <= 24) {
      for (let i = 0; i <= hours; i++) {
        if (!(i % 4) && i !== 24) {
          ticks.push(<div key={i}>{i}h</div>);
        } else {
          ticks.push(<div key={i} />);
        }
      }
    } else {
      for (let i = 0; i <= hours; i++) {
        const tickUtc = UTCMoment.fromUnix(startUnix + i * 60 * 60);
        const isDayStart = !tickUtc.diff(tickUtc.clone().startOf('day'));
        const isDayMiddle = !tickUtc.diff(tickUtc.clone().hour(12).minute(0));

        if (isDayStart && i !== 0) {
          ticks.push(<div key={i}>|</div>);
        } else if (isDayMiddle) {
          ticks.push(<div key={i}>{tickUtc.format('MMM D')}</div>);
        }
      }
    }

    return (
      <div className={classNames(s.container, className)} style={style}>
        <div />
        {ticks}
        <div />
      </div>
    );
  },
);
