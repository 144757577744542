import { Fade } from 'react-reveal';
import React from 'react';
import { TProps } from './Deployment.types';
import cityDeployment from 'src/assets/images/LandingPage/marketDesign/layer_deployment/city.png';
import classnames from 'classnames';
import greenRingDeployment from 'src/assets/images/LandingPage/marketDesign/layer_deployment/green-ring.png';
import redRingDeployment from 'src/assets/images/LandingPage/marketDesign/layer_deployment/red-ring.png';
import s from './Deployment.module.scss';

export const BlueBoxOne: React.FC<TProps> = ({ show }) => {
  return (
    <Fade opposite collapse={!show} when={show} duration={2000}>
      <div className={s.boxContent}>
        <div className={s.boxText}>
          <h3>Test and Deploy your Community</h3>
          <p>
            Connect your digital energy community (live data stream) to Grid Singularity test
            network for a technical proof-of-concept through an aggregator / energy service provider
            in your area. The final step to deploy depends on local regulation.
            <br />
            <a target="_blank" rel="noreferrer" href="https://gridsingularity.com/singularity-map">
              See Canary Test Network example here.
            </a>
          </p>
        </div>
      </div>
    </Fade>
  );
};

export const City: React.FC<TProps> = ({ show }) => {
  return (
    <>
      <Fade opposite collapse={!show} when={show} duration={1000}>
        <img src={cityDeployment} className={classnames(s.imageFloating, s.city)} />
      </Fade>
    </>
  );
};

export const Ring: React.FC<TProps> = ({ show }) => {
  return (
    <>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={1000}>
        <img src={redRingDeployment} className={classnames(s.imageFloating, s.ringOne)} />
      </Fade>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={1000}>
        <img src={redRingDeployment} className={classnames(s.imageFloating, s.ringTwo)} />
      </Fade>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={1500}>
        <img src={greenRingDeployment} className={classnames(s.imageFloating, s.ringThree)} />
      </Fade>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={1500}>
        <img src={greenRingDeployment} className={classnames(s.imageFloating, s.ringFour)} />
      </Fade>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={1500}>
        <img src={greenRingDeployment} className={classnames(s.imageFloating, s.ringFive)} />
      </Fade>
    </>
  );
};

export const GrayBox: React.FC<TProps> = ({ show }) => {
  return (
    <>
      <Fade opposite collapse={!show} when={show} duration={1000}>
        <div className={s.grayBox}>
          <div className={s.grayBoxText}>
            <p>
              All energy trades here are simulated, meaning that no financial transactions take
              place. The data requirements and other specifications can be found on the {''}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://gridsingularity.github.io/gsy-e/documentation/">
                Grid Singularity wiki
              </a>
            </p>
          </div>
        </div>
      </Fade>
    </>
  );
};
