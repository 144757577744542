import { RootState } from 'src/redux/store';
import { TCommunitiesState } from 'src/redux/communities/communities.slice';

export const selectCommunitiesList = (state: RootState): TCommunitiesState['communitiesList'] =>
  state.communities.communitiesList;

export const selectCommunityToCompare = (
  state: RootState,
): TCommunitiesState['communityToCompare'] => state.communities.communityToCompare;

export const selectSelectedTimeInterval = (
  state: RootState,
): TCommunitiesState['selectedTimelineInterval'] => state.communities.selectedTimelineInterval;

export const selectCommunitiesCount = (state: RootState): number =>
  state.communities.communitiesList.length;

export const selectUserToAdminRequests = (
  state: RootState,
): TCommunitiesState['userToAdminRequests'] => state.communities.userToAdminRequests;
