import { ActiveElement, Chart, ChartOptions, ScatterDataPoint } from 'chart.js';
import { EChartTooltips, getChartTooltip } from 'src/constants/chartTooltips';
import React, { useEffect, useMemo, useRef } from 'react';
import {
  findIfPrintDeviceProfile,
  findIfShowChartFour,
} from 'src/components/_charts/ChartDeviceProfile/helpers';

import { AxisXCustom } from 'src/components/_charts/AxisXCustom/AxisXCustom';
import { ChartDataLoadingWrapper } from 'src/components/ChartDataLoadingWrapper';
import { ChartWrapper } from 'src/components/ChartWrapper';
import { EChartName } from 'src/components/_charts/chartsData';
import { TChartDeviceProfileProps } from './ChartDeviceProfile.types';
import { config } from 'src/components/_charts/ChartDeviceProfile/config';
import { formatter } from 'src/utils/formatter';
import s from './ChartDeviceProfile.module.scss';
import { selectSettingsData } from 'src/redux/configuration/configuration.selectors';
import { useChartJS } from 'src/hooks/useChartJS';
import { useCurrentRefs } from 'src/hooks/useCurrentRefs';
import useDeviceStatisticsData from 'src/components/_charts/ChartDeviceProfile/useDeviceStatisticsData';
import { useSelector } from 'react-redux';
import vars from 'src/assets/styles/utils/vars.module.scss';

export const ChartDeviceProfile: React.FC<TChartDeviceProfileProps> = ({
  deviceStatics,
  startUnix,
  endUnix,
  deviceType,
  forTimestamps,
}) => {
  const parsedData = useDeviceStatisticsData({ data: deviceStatics, deviceType, forTimestamps });
  const height = 115;
  const cursorRef = useRef<HTMLDivElement | null>(null);
  const canvasOneRef = useRef<HTMLCanvasElement | null>(null);
  const chartOneRef = useRef<Chart | null>(null);
  const canvasTwoRef = useRef<HTMLCanvasElement | null>(null);
  const chartTwoRef = useRef<Chart | null>(null);
  const canvasThreeRef = useRef<HTMLCanvasElement | null>(null);
  const chartThreeRef = useRef<Chart | null>(null);
  const canvasFourRef = useRef<HTMLCanvasElement | null>(null);
  const chartFourRef = useRef<Chart | null>(null);
  const allChartsRef = useRef([chartOneRef, chartTwoRef, chartThreeRef, chartFourRef]);
  const { currency } = useSelector(selectSettingsData);
  const showChartFour = findIfShowChartFour(deviceType) || deviceType === 'HeatPump';
  const showChartOne = !showChartFour || deviceType === 'HeatPump';
  const chartOneBg = deviceType === 'HeatPump' ? undefined : vars['color-athens-gray'];
  const currentRefs = useCurrentRefs({ forTimestamps });

  const datasetsOne = useMemo(() => {
    return [
      {
        data: parsedData?.chartOneData || [],
        borderColor: vars['color-oh-so-green'],
        borderWidth: 1,
      },
    ];
  }, [parsedData?.chartOneData]);

  const datasetsTwo = useMemo(() => {
    const output = [
      {
        data: parsedData?.chartTwoData.energy || [],
        backgroundColor: vars['color-singularity-red'],
      },
    ];

    if (findIfPrintDeviceProfile(deviceType)) {
      output.push({
        data: parsedData?.chartTwoData.deviceProfile || [],
        backgroundColor: vars['color-oh-so-green'],
      });
    }

    return output;
  }, [deviceType, parsedData?.chartTwoData.deviceProfile, parsedData?.chartTwoData.energy]);

  type TDataSetFour = {
    data: ScatterDataPoint[];
    backgroundColor: string;
    borderColor?: string;
    borderWidth?: number;
  }[];

  const datasetsFour: TDataSetFour = useMemo(() => {
    const output: TDataSetFour = [
      {
        data: parsedData?.chartFourData.energy || [],
        backgroundColor: vars['color-singularity-red'],
        borderColor: deviceType === 'HeatPump' ? vars['color-singularity-red'] : undefined,
        borderWidth: deviceType === 'HeatPump' ? 1 : undefined,
      },
    ];

    if (findIfPrintDeviceProfile(deviceType)) {
      output.push({
        data: parsedData?.chartFourData.deviceProfile || [],
        backgroundColor: vars['color-oh-so-green'],
      });
    }

    return output;
  }, [deviceType, parsedData?.chartFourData.deviceProfile, parsedData?.chartFourData.energy]);

  const datasetsThree = useMemo(() => {
    return [
      {
        data: parsedData?.chartThreeData.data || [],
        backgroundColor: '#1A48ED',
        minBarLength: 2,
      },
    ];
  }, [parsedData?.chartThreeData]);

  const chartOptions: ChartOptions = useMemo(() => {
    return {
      onHover(context, t, chart) {
        if (!chart || typeof context.x !== 'number') {
          if (cursorRef.current) {
            cursorRef.current.style.opacity = '0';
          }

          return;
        }

        if (cursorRef.current) {
          Object.assign(cursorRef.current.style, {
            transform: `translateX(${context.x}px`,
            opacity: '1',
          });
        }

        // @ts-ignore
        const activeElements: ActiveElement[] = chart.tooltip?.getActiveElements?.();
        let index = 0;

        if (activeElements.length) {
          index = activeElements[0].index;
        } else {
          index = +(
            ((context.x - chart.chartArea.left) * currentRefs.current.forTimestamps.length) /
            // @ts-ignore
            chart.chartArea.width
          ).toFixed(0);
        }

        allChartsRef.current.forEach((ref) => {
          const chart = ref.current;
          if (!chart) return;

          const newActiveElements = chart.data.datasets.reduce((acc, dataset, datasetIndex) => {
            if (index in dataset.data) {
              acc.push({
                datasetIndex,
                index,
              });
            }

            return acc;
          }, [] as Array<{ datasetIndex: number; index: number }>);

          if (newActiveElements.length) {
            // @ts-ignore
            chart.tooltip.setActiveElements?.(newActiveElements);

            chart.update();
          }
        });
      },
      scales: {
        y: {
          ticks: {
            display: true,
            font: {
              size: 11,
              weight: 'bold',
            },
            color: '#34363f',
          },
        },
      },
    };
  }, [currentRefs]);

  useChartJS(
    EChartName.DeviceProfileOne,
    canvasOneRef,
    chartOneRef,
    {
      datasets: datasetsOne,
    },
    { chartOptions },
  );

  useChartJS(
    EChartName.DeviceProfileTwo,
    canvasTwoRef,
    chartTwoRef,
    {
      datasets: datasetsTwo,
    },
    { chartOptions, datasetsLengthWillChange: true },
  );

  useChartJS(
    EChartName.DeviceProfileThree,
    canvasThreeRef,
    chartThreeRef,
    {
      labels: forTimestamps,
      // @ts-ignore
      datasets: datasetsThree,
    },
    { chartOptions },
  );

  useChartJS(
    deviceType === 'HeatPump' ? EChartName.DeviceProfileTank : EChartName.DeviceProfileFour,
    canvasFourRef,
    chartFourRef,
    {
      datasets: datasetsFour,
    },
    { chartOptions, datasetsLengthWillChange: true },
  );

  // Controls tooltip/axis labels
  useEffect(() => {
    if (chartOneRef.current) {
      chartOneRef.current.config.data.datasets[0].label = JSON.stringify({
        title: config.tooltipLabels[deviceType][0],
        unit: config.tooltipUnits[deviceType][0],
      });
      // @ts-ignore
      chartOneRef.current.options.scales!.y!.title!.text = config.YAxis[deviceType].label[0];
      // @ts-ignore
      chartOneRef.current.options.scales!.y!.title.font!.size = 11;
      // @ts-ignore
      chartOneRef.current.options.scales!.y!.title.font!.weight = 'bold';
      // @ts-ignore
      chartOneRef.current.options.scales!.y!.title.color = '#34363f';
      chartOneRef.current.update();
    }

    if (chartTwoRef.current) {
      const [energyDataset, deviceProfileDataset] = chartTwoRef.current.config.data.datasets;

      energyDataset.label = JSON.stringify({
        title: config.tooltipLabels[deviceType][1],
        unit: config.tooltipUnits[deviceType][1],
      });

      if (deviceProfileDataset) {
        deviceProfileDataset.label = JSON.stringify({
          title: config.tooltipLabels[deviceType][0],
          unit: config.tooltipUnits[deviceType][0],
        });
      }
      // @ts-ignore
      chartTwoRef.current.options.scales!.y!.title!.text = config.YAxis[deviceType].label[1];
      // @ts-ignore
      chartTwoRef.current.options.scales!.y!.title.font!.size = 11;
      // @ts-ignore
      chartTwoRef.current.options.scales!.y!.title.font!.weight = 'bold';
      // @ts-ignore
      chartTwoRef.current.options.scales!.y!.title.color = '#34363f';

      chartTwoRef.current.update();
    }

    if (chartThreeRef.current) {
      const unit = `${formatter.getCurrencySymbol(currency)}/kWh`;
      chartThreeRef.current.config.data.datasets[0].label = JSON.stringify({
        title: ['Min', 'Max'],
        unit: [unit, unit],
      });
      // @ts-ignore
      chartThreeRef.current.options.scales!.y!.title!.text = config.YAxis[deviceType].label[2];
      // @ts-ignore
      chartThreeRef.current.options.scales!.y!.title.font!.size = 11;
      // @ts-ignore
      chartThreeRef.current.options.scales!.y!.title.font!.weight = 'bold';
      // @ts-ignore
      chartThreeRef.current.options.scales!.y!.title.color = '#34363f';

      if (parsedData?.chartThreeData.domain) {
        const [min, max] = parsedData.chartThreeData.domain;
        if (typeof min === 'number' && typeof max === 'number') {
          // Sometimes bars are not plotted, changing domain for yAxis fixes this
          chartThreeRef.current.options.scales!.y!.min = min * 1.5;
          chartThreeRef.current.options.scales!.y!.max = max * 1.5;
        }
      }

      chartThreeRef.current.update();
    }

    if (chartFourRef.current) {
      const [energyDataset, deviceProfileDataset] = chartFourRef.current.config.data.datasets;

      energyDataset.label = JSON.stringify({
        title: config.tooltipLabels[deviceType][3],
        unit: config.tooltipUnits[deviceType][3],
      });

      if (deviceProfileDataset) {
        deviceProfileDataset.label = JSON.stringify({
          title: config.tooltipLabels[deviceType][1],
          unit: config.tooltipUnits[deviceType][1],
        });
      }
      // @ts-ignore
      chartFourRef.current.options.scales!.y!.title!.text = config.YAxis[deviceType].label[3];
      // @ts-ignore
      chartFourRef.current.options.scales!.y!.title.font!.size = 11;
      // @ts-ignore
      chartFourRef.current.options.scales!.y!.title.font!.weight = 'bold';
      // @ts-ignore
      chartFourRef.current.options.scales!.y!.title.color = '#34363f';
      chartFourRef.current.update();
    }
  }, [currency, deviceType, parsedData?.chartThreeData.domain]);

  const AxisX = (
    <AxisXCustom
      className={s.axisX}
      startUnix={startUnix}
      endUnix={endUnix}
      style={{ marginLeft: 35 }}
    />
  );

  const chartList = useMemo(() => {
    const chartOneInfo = {
      canvas: canvasOneRef,
      style: { background: chartOneBg, display: showChartOne ? undefined : 'none' },
    };

    const chartTwoInfo = {
      canvas: canvasTwoRef,
      style: { background: showChartFour ? vars['color-athens-gray'] : undefined },
    };

    const chartThreeInfo = {
      canvas: canvasThreeRef,
      style: {
        background: deviceType === 'HeatPump' ? 'none' : vars['color-athens-gray'],
        display: deviceType === 'Storage' && parsedData?.chartThreeData.noData ? 'none' : undefined,
      },
    };

    const chartFourInfo = {
      canvas: canvasFourRef,
      style: {
        background: deviceType === 'HeatPump' ? vars['color-athens-gray'] : 'none',
        display: !showChartFour ? 'none' : undefined,
      },
    };

    if (deviceType === 'HeatPump')
      return [chartOneInfo, chartTwoInfo, chartThreeInfo, chartFourInfo];

    return [chartOneInfo, chartTwoInfo, chartFourInfo, chartThreeInfo];
  }, [
    canvasFourRef,
    canvasOneRef,
    canvasThreeRef,
    canvasTwoRef,
    deviceType,
    parsedData?.chartThreeData.noData,
    showChartFour,
    showChartOne,
    chartOneBg,
  ]);

  return (
    <ChartWrapper
      title="Profile Over Time"
      info={getChartTooltip(
        EChartTooltips[`DEVICE_PROFILE_${deviceType}`],
        formatter.getCurrencySymbol(currency),
      )}>
      <ChartDataLoadingWrapper loading={!deviceStatics}>
        {AxisX}

        <div className={s.chartsWrapper}>
          <div
            className={s.chartsInnerWrapper}
            onMouseLeave={() => {
              allChartsRef.current.forEach((item) => {
                const chart = item.current;
                setTimeout(() => {
                  if (!chart) return;
                  // @ts-ignore
                  chart.tooltip.setActiveElements?.([]);

                  if (cursorRef.current) {
                    cursorRef.current.style.opacity = '0';
                  }

                  chart.update();
                }, 50);
              });
            }}>
            <div className={s.cursor} ref={cursorRef} />

            {chartList.map(({ canvas, style }, index) => {
              return (
                <canvas
                  key={`chart${index}${deviceType}${forTimestamps.length}`}
                  ref={canvas}
                  height={height}
                  style={style}
                />
              );
            })}
          </div>
        </div>

        {AxisX}
      </ChartDataLoadingWrapper>
    </ChartWrapper>
  );
};
