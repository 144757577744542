import { TFieldsUnionWithValue } from 'src/utils/assetsFields/assetsFields.types';

export type TCustomHeatPumpProps = {
  className?: string;
  capacityFields: TFieldsUnionWithValue[];
  tradingFields: TFieldsUnionWithValue[];
  theme?: 'light' | 'dark';
};

export enum EAccordionType {
  capacityAndProfile = 'Capacity and Profile',
  orientation = 'Orientation',
  tradingStrategy = 'Trading Strategy',
}
