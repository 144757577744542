import { ESavingType, EViewType, TChartSavingsProps } from './KeyResults.types';
import React, { useState } from 'react';

import { BaseIcon } from 'src/components/BaseIcon';
import { Fade } from 'react-reveal';
import { TabNav } from 'src/components/TabNav';
import classnames from 'classnames';
import s from './KeyResults.module.scss';

function getSavingType(
  trades: NonNullable<TChartSavingsProps['latestSavingsKpi']>,
): ESavingType | undefined {
  if (trades.saving_absolute === null) return;
  if (trades.saving_absolute === 0) return ESavingType.Neutral;
  if (trades.gsy_e_cost === 0 && trades.saving_absolute > 0) return ESavingType.Earning;
  if (trades.saving_absolute > 0) return ESavingType.Saving;
  else return ESavingType.Losses;
}

const DifferenceInfoTag: React.FC<{
  latestSavingsKpi: NonNullable<TChartSavingsProps['latestSavingsKpi']>;
}> = ({ latestSavingsKpi }) => {
  const type = getSavingType(latestSavingsKpi);
  let title: string | undefined = undefined;

  if (!type) return null;

  switch (type) {
    case ESavingType.Earning:
      title = 'You earned';
      break;
    case ESavingType.Neutral:
      title = 'You stay neutral';
      break;
    case ESavingType.Saving:
      title = 'You saved';
      break;
    case ESavingType.Losses:
      title = 'You lose';
      break;
  }

  return (
    <div className={classnames(s.differenceInfoTag)}>
      <p>{title}</p>
    </div>
  );
};

export const KeyResults: React.FC<TChartSavingsProps> = ({ latestSavingsKpi, kpi }) => {
  const [selectedTab, setSelectedTab] = useState(EViewType.SelfSufficiency);
  const [isVisible, setIsVisible] = useState(true);
  // const currencySymbol = formatter.getCurrencySymbol(currency);

  const toggleVisible = () => setIsVisible((prevState) => !prevState);
  const visibilityIcon = isVisible ? 'eye-no' : 'eye';

  // commenting out below method as per discussion with @rafael

  // const renderIcon = (latestSavingsKpi) => {
  //   const type = getSavingType(latestSavingsKpi);
  //   let iconName: TIconNames | undefined = undefined;

  //   if (!type) return null;

  //   switch (type) {
  //     case ESavingType.Earning:
  //       iconName = 'arrow-feature';
  //       break;
  //     case ESavingType.Neutral:
  //       iconName = 'arrow-feature';
  //       break;
  //     case ESavingType.Saving:
  //       iconName = 'arrow-feature';
  //       break;
  //     case ESavingType.Losses:
  //       iconName = 'arrow-feature';
  //       break;
  //   }

  //   return (
  //     <span className={s.savingsPercentage}>
  //       {type === ESavingType.Neutral ? (
  //         '-'
  //       ) : (
  //         <>
  //           <BaseIcon icon={iconName} className={s[type.toLowerCase()]} size={14} />
  //           <label>10%</label>
  //         </>
  //       )}
  //     </span>
  //   );
  // };

  const renderValue = (kpi) => {
    function normalizePercentage(value = 0) {
      return value.toFixed(1);
    }

    switch (selectedTab) {
      // case EViewType.Savings:
      //   return "";
      case EViewType.SelfSufficiency:
        return `${normalizePercentage(kpi?.self_sufficiency)}%`;
      case EViewType.SelfConsumption:
        return `${normalizePercentage(kpi?.self_consumption)}%`;
        break;
    }
  };

  const renderDetails = (tab) => {
    switch (tab) {
      case EViewType.Savings:
        return 'By activating peer-to-peer energy trading under these simulated conditions, compared to buying from your local energy provider.';
      case EViewType.SelfSufficiency:
        return 'This is the share of community electricity consumption that is supplied by its own renewable energy assets, reducing reliance on the electricity grid.';
      case EViewType.SelfConsumption:
        return 'This is the share of community electricity production that is consumed by the community. This rate also represents the minimum share of renewable energy in your community energy mix.';
      default:
        return '';
    }
  };

  return (
    <div className={s.container}>
      <div className={s.header}>
        <div className={s.title}>⚡ Key Results</div>
        <button type="button" onClick={toggleVisible} className={s.toggle}>
          <span>{isVisible ? 'Hide' : 'Show'}</span>
          <BaseIcon icon={visibilityIcon} size={11} />
        </button>
      </div>
      <Fade collapse={!isVisible} when={isVisible} duration={1000}>
        {isVisible && (
          <>
            <TabNav
              className={s.keyResultsTabs}
              theme="1"
              nav={[
                // {
                //   label: 'Savings',
                //   onClick() {
                //     setSelectedTab(EViewType.Savings);
                //   },
                //   isActive: selectedTab === EViewType.Savings,
                // },
                {
                  label: 'Self-Sufficiency',
                  onClick() {
                    setSelectedTab(EViewType.SelfSufficiency);
                  },
                  isActive: selectedTab === EViewType.SelfSufficiency,
                },
                {
                  label: 'Self-Consumption',
                  onClick() {
                    setSelectedTab(EViewType.SelfConsumption);
                  },
                  isActive: selectedTab === EViewType.SelfConsumption,
                },
              ]}
            />
            {latestSavingsKpi && latestSavingsKpi.saving_absolute !== null && (
              <div className={classnames(s.assetInfo, s.block)}>
                <header>
                  <div className={s.earningStatus}>
                    {selectedTab === EViewType.Savings ? (
                      <DifferenceInfoTag latestSavingsKpi={latestSavingsKpi} />
                    ) : (
                      'Your rate'
                    )}
                  </div>
                  {/* <div className={s.monthlyTag}>monthly</div> */}
                </header>
                <div className={s.cost}>
                  <span>{renderValue(kpi)}</span>
                  {/* {renderIcon(latestSavingsKpi)} */}
                </div>
                <footer>
                  <p className={s.footerText}>{renderDetails(selectedTab)}</p>
                </footer>
              </div>
            )}
          </>
        )}
      </Fade>
    </div>
  );
};
