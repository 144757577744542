import { TFileIdentifiers } from 'src/typings/base-types';

const cache: { [key in string]?: File } = {};

const genKey = (genKeyArgs: TFileIdentifiers) => {
  return `${String(genKeyArgs.name)}__${String(genKeyArgs.lastModified)}`;
};

function set(file: File): void {
  cache[genKey(file)] = file;
}

function get(genKeyArgs: TFileIdentifiers): File | undefined {
  return cache[genKey(genKeyArgs)];
}

export const uploadedFilesCache = { set, get };
