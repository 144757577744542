import React, { useContext } from 'react';

import ContentfulContext from 'src/contexts/ContentfulContext';
import { routesConfig } from 'src/routes/routes.config';
import s from './TeamBios.module.scss';
import { useHistory } from 'react-router';

export const TeamBios: React.FC = () => {
  const { teamMembersEntry } = useContext(ContentfulContext);
  const history = useHistory();

  const orderMembers = () => {
    const membersOrdered = teamMembersEntry.sort((a, b) =>
      a?.order ? (b?.order && a.order - b.order) || 0 : 0,
    );

    const membersWithOrderField = membersOrdered.filter((e) => e?.order);
    const membersWithNoOrderField = membersOrdered.filter((e) => !e?.order);

    const lastNameAlphabetic = membersWithNoOrderField.sort((a, b) => {
      const lastNameA = a.name.trim().split(' ').reverse()[0];
      const lastNameB = b.name.trim().split(' ').reverse()[0];

      if (lastNameA < lastNameB) {
        return -1;
      }
      if (lastNameA > lastNameB) {
        return 1;
      }
      return 0;
    });

    return [...membersWithOrderField, ...lastNameAlphabetic];
  };

  const membersOrdered = orderMembers();

  return (
    <div id="content" className={s.content}>
      <div className={s.wrapperPeople}>
        <div className={s.boxPeople}>
          {membersOrdered?.map((member, key) => (
            <div
              key={key.toString()}
              className={s.photo}
              onClick={() => {
                history.push(routesConfig.company() + `/our-team/${member.id}`);
              }}>
              <div
                className={s.boxPhoto}
                style={{ backgroundImage: `url(${member.avatar})` }}></div>
              <span>{member.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
