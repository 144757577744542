import React, { useCallback, useEffect, useState } from 'react';
import s from './doc.module.scss';
import { layerPositions } from 'src/pages/MarketDesignMobile';
import { TProps } from './doc.types';

export const Doc: React.FC<TProps> = (props) => {
  const { children, currentLayer, onChange } = props;
  const [showContent, setShowContent] = useState(true);

  const triggerDocOnLayerChange = useCallback(() => {
    if (
      currentLayer === layerPositions.exchanges ||
      currentLayer === layerPositions.simulation ||
      currentLayer === layerPositions.ecosystem
    ) {
      setShowContent(true);
    } else {
      setShowContent(false);
    }
  }, [currentLayer]);

  useEffect(() => {
    if (onChange) {
      onChange(showContent);
    }
  }, [showContent, currentLayer, onChange]);

  useEffect(() => {
    // setTimeout(() => toggleContent(), 3000);
    triggerDocOnLayerChange();
  }, [triggerDocOnLayerChange]);

  const toggleContent = () => setShowContent((prevState) => !prevState);

  const handleClick = () => {
    toggleContent();
    setTimeout(() => toggleContent(), 3000);
  };

  return (
    <div className={s.container}>
      {!showContent && <div className={s.doc} onClick={handleClick}></div>}
      {showContent && <div className={s.content}>{children}</div>}
    </div>
  );
};
