import { Bounce, Fade, Zoom } from 'react-reveal';

import React from 'react';
import { BaseButtonSquare } from 'src/components/BaseButtonSquare';
import { TProps } from './Exchanges.types';
import { ToolTip } from 'src/pages/MarketDesign/components/ToolTip';
import barExchanges from 'src/assets/images/LandingPage/marketDesign/layerOne_exchanges/bar.png';
import batteryExchanges from 'src/assets/images/LandingPage/marketDesign/layerOne_exchanges/battery.png';
import carExchanges from 'src/assets/images/LandingPage/marketDesign/layerOne_exchanges/car.png';
import chartDayExchanges from 'src/assets/images/LandingPage/marketDesign/layerOne_exchanges/chart-day.png';
import chartTradesExchanges from 'src/assets/images/LandingPage/marketDesign/layerOne_exchanges/chart-trades.png';
import classnames from 'classnames';
import consumptionExchanges from 'src/assets/images/LandingPage/marketDesign/layerOne_exchanges/consumption.png';
import diamondExchange from 'src/assets/images/LandingPage/marketDesign/layerTwo_markets/diamond.png';
import houseExchanges from 'src/assets/images/LandingPage/marketDesign/layerOne_exchanges/house.png';
import lineDiamondsExchanges from 'src/assets/images/LandingPage/marketDesign/layerOne_exchanges/line-diamonds.png';
import linksExchanges from 'src/assets/images/LandingPage/marketDesign/layerOne_exchanges/links.png';
import marketsExchanges from 'src/assets/images/LandingPage/marketDesign/layerOne_exchanges/markets.png';
import ringExchanges from 'src/assets/images/LandingPage/marketDesign/layerOne_exchanges/ring.png';
import s from './Exchanges.module.scss';
import shadownExchanges from 'src/assets/images/LandingPage/marketDesign/layerOne_exchanges/shadown.png';
import singleCommnunityExchanges from 'src/assets/images/LandingPage/marketDesign/layerOne_exchanges/single-community.png';
import singleDiamondExchanges from 'src/assets/images/LandingPage/marketDesign/layerOne_exchanges/single-diamond.png';
import smallDiamondExchanges from 'src/assets/images/LandingPage/marketDesign/layerOne_exchanges/small-diamond.svg';
import solarPanelExchanges from 'src/assets/images/LandingPage/marketDesign/layerOne_exchanges/solar-panel.png';

import { useAppDispatch } from 'src/redux/store';
import { EPredefinedModalIds } from 'src/constants/modals';
import { closeModal } from 'src/redux/modals/modals.slice';

export const BlueBoxOne: React.FC<TProps> = ({ show }) => {
  return (
    <Fade bottom collapse={!show} when={show} duration={2000} delay={3000}>
      <div className={s.boxContent}>
        <div className={s.boxText}>
          <h3>Take control of your energy!</h3>
          <p>
            Grid Singularity exchange lets your energy assets trade directly. Your home becomes an
            energy trader in an inclusive, fair market, unlocking your economic, social and
            environmental benefits.
          </p>
        </div>
      </div>
    </Fade>
  );
};

export const BlueBoxTwo: React.FC<TProps> = ({ show }) => {
  return (
    <Fade bottom collapse={!show} when={show} duration={2000} delay={3000}>
      <div className={s.boxContent}>
        <div className={s.boxText}>
          <h3>Set your energy preferences</h3>
          <p>
            Your digital agents powered by AI, carry out trades on your behalf, reflecting your
            choices and generating benefits.
          </p>
        </div>
      </div>
    </Fade>
  );
};

export const BlueBoxThree: React.FC<TProps> = ({ show }) => {
  return (
    <Fade bottom collapse={!show} when={show} duration={2000} delay={3000}>
      <div className={s.boxContent}>
        <div className={s.boxText}>
          <h3>Build your energy community</h3>
          <p>
            Add a new dimension to your social interaction by choosing your energy trading partners.
            Buy, sell, store, gift, share.
          </p>
        </div>
      </div>
    </Fade>
  );
};

export const BlueBoxFour: React.FC<TProps> = ({ show }) => {
  return (
    <Fade bottom collapse={!show} when={show} duration={2000} delay={3000}>
      <div className={s.boxContent}>
        <div className={s.boxText}>
          <h3>Reach sustainability</h3>
          <p>
            Understand energy flows, advance your community and actively contribute to the energy
            transition.
          </p>
        </div>
      </div>
    </Fade>
  );
};

export const BlueBox: React.FC<TProps> = ({ currentLayer }) => {
  const dispatch = useAppDispatch();

  const ExchangeContent = () => (
    <div className={s.boxText}>
      <h3>From Basic To Complex</h3>
      <p>
        We turn to market fundamentals, energy assets, and with this bottom-up approach enable
        modular, parallel, hierarchical exchanges to operate in each market, reducing the entry
        barriers and expanding choices for a large number of traders, particularly individual
        households.
      </p>
    </div>
  );

  const MarketContent = () => (
    <div className={s.boxText}>
      <h3>Stacking Markets</h3>
      <p>
        By providing the option to stack markets on top of or next to each other, we mimic the grid
        topography, creating grid-aware local energy markets that account for granular grid costs,
        including different nodal or tariff zones.
      </p>
    </div>
  );

  const APIContent = () => (
    <div className={s.boxText}>
      <h3>From Basic To Complex</h3>
      <p>
        We turn to market fundamentals, energy assets, and with this bottom-up approach enable
        modular, parallel, hierarchical exchanges to operate in each market, reducing the entry
        barriers and expanding choices for a large number of traders, particularly individual
        households.
      </p>
    </div>
  );

  const SimulationContent = () => (
    <div className={s.boxText}>
      <h3>Simulate in few steps</h3>
      <p>
        Create a digital representation of your home and community assets using historical data or
        approximate, template data, depending on availability.
      </p>
    </div>
  );

  const renderContent = (value) => {
    if (value < 5) {
      return <ExchangeContent />;
    } else if (value >= 5 && value < 9) {
      return <MarketContent />;
    } else if (value >= 9 && value < 12) {
      return <APIContent />;
    } else if (value >= 12) {
      return <SimulationContent />;
    }
  };

  return (
    <Fade opposite duration={1000} delay={500}>
      <div className={s.boxContent}>
        <div className={s.closeBtn}>
          <BaseButtonSquare
            size="3"
            theme="flat-dark"
            icon="close"
            svgSize="1"
            onClick={() => dispatch(closeModal(EPredefinedModalIds.MODAL_MARKET_DESIGN))}
          />
        </div>
        {renderContent(currentLayer)}
      </div>
    </Fade>
  );
};

export const LightBarBlue: React.FC<TProps> = ({ show }) => {
  return (
    <Fade opposite collapse={!show} when={show} duration={1000} delay={1500}>
      <img src={barExchanges} className={classnames(s.imageFloating, s.lightBarBlue)} />
    </Fade>
  );
};

export const ShadowBlue: React.FC<TProps> = ({ show }) => {
  return (
    <Fade opposite collapse={!show} when={show} duration={1000} delay={1500}>
      <img src={shadownExchanges} className={classnames(s.imageFloating, s.shadowBlue)} />
    </Fade>
  );
};

export const TooltipHouseBlue: React.FC<TProps> = ({ show }) => {
  return (
    <Fade top collapse={!show} when={show} duration={1000} delay={2500}>
      <div className={s.houseBlueTooltip}>
        <ToolTip position="top" title="Home Energy Assets" />
      </div>
    </Fade>
  );
};

export const HouseBlue: React.FC<TProps> = ({ show }) => {
  return (
    <Zoom opposite collapse={!show} when={show} delay={1500}>
      <img src={houseExchanges} className={classnames(s.imageFloating, s.houseBlue)} />
    </Zoom>
  );
};

export const SolarPanel: React.FC<TProps> = ({ show }) => {
  return (
    <Bounce opposite collapse={!show} when={show} delay={2100}>
      <img src={solarPanelExchanges} className={classnames(s.imageFloating, s.solarPanel)} />
    </Bounce>
  );
};

export const Car: React.FC<TProps> = ({ show }) => {
  return (
    <Bounce opposite collapse={!show} when={show} delay={2100}>
      <img src={carExchanges} className={classnames(s.imageFloating, s.car)} />
    </Bounce>
  );
};

export const Battery: React.FC<TProps> = ({ show }) => {
  return (
    <Bounce opposite collapse={!show} when={show} delay={2100}>
      <img src={batteryExchanges} className={classnames(s.imageFloating, s.battery)} />
    </Bounce>
  );
};

export const Consumption: React.FC<TProps> = ({ show }) => {
  return (
    <Bounce opposite collapse={!show} when={show} delay={2100}>
      <img src={consumptionExchanges} className={classnames(s.imageFloating, s.consumption)} />
    </Bounce>
  );
};

export const TooltipDiamond: React.FC<TProps> = ({ show }) => {
  return (
    <Fade opposite collapse={!show} when={show} duration={1000} delay={1000}>
      <div className={s.diamondTooltip}>
        <ToolTip
          inline={true}
          position="left"
          title={
            <>
              <div>
                <img src={smallDiamondExchanges} />
                <span>Digital Twin</span>
              </div>
              <br />
              <div className={s.descriptionTooltip}>
                Digital representation of
                <br /> your home/community
                <br /> energy assets
              </div>
            </>
          }
        />
      </div>
    </Fade>
  );
};

export const Diamond: React.FC<TProps> = ({ show }) => {
  return (
    <Fade top collapse={!show} when={show} duration={1000}>
      <img src={diamondExchange} className={classnames(s.imageFloating, s.diamond)} />
    </Fade>
  );
};

export const SingleCommunity: React.FC<TProps> = ({ show }) => (
  <>
    <Zoom opposite collapse={!show} when={show} duration={1000}>
      <img
        src={singleCommnunityExchanges}
        className={classnames(s.imageFloating, s.singleCommunity)}
      />
    </Zoom>
    <Zoom opposite collapse={!show} when={show} duration={1000} delay={500}>
      <img src={linksExchanges} className={classnames(s.imageFloating, s.linksCommunity)} />
    </Zoom>
  </>
);

export const TooltipDiamondCommunity: React.FC<TProps> = ({ show }) => {
  return (
    <Fade top opposite collapse={!show} when={show} duration={1000} delay={1500}>
      <div className={s.lineDiamondTooltip}>
        <ToolTip position="top" title="Community Members" />
      </div>
    </Fade>
  );
};

export const LineDiamondCommunity: React.FC<TProps> = ({ show }) => {
  return (
    <>
      <Zoom opposite collapse={!show} when={show} delay={1000}>
        <img
          src={singleDiamondExchanges}
          className={classnames(s.imageFloating, s.singleDiamondOne)}
        />
      </Zoom>
      <Zoom opposite collapse={!show} when={show} delay={1000}>
        <img
          src={singleDiamondExchanges}
          className={classnames(s.imageFloating, s.singleDiamondTwo)}
        />
      </Zoom>
      <Zoom opposite collapse={!show} when={show} delay={1000}>
        <img
          src={singleDiamondExchanges}
          className={classnames(s.imageFloating, s.singleDiamondThree)}
        />
      </Zoom>
      <Zoom opposite collapse={!show} when={show} delay={1000}>
        <img
          src={singleDiamondExchanges}
          className={classnames(s.imageFloating, s.singleDiamondFour)}
        />
      </Zoom>
      <Zoom opposite collapse={!show} when={show} delay={1000}>
        <img
          src={singleDiamondExchanges}
          className={classnames(s.imageFloating, s.singleDiamondFive)}
        />
      </Zoom>
      <Zoom opposite collapse={!show} when={show} delay={1000}>
        <img
          src={singleDiamondExchanges}
          className={classnames(s.imageFloating, s.singleDiamondSix)}
        />
      </Zoom>
      <Zoom opposite collapse={!show} when={show} delay={1000}>
        <img
          src={singleDiamondExchanges}
          className={classnames(s.imageFloating, s.singleDiamondSeven)}
        />
      </Zoom>
      <Zoom opposite collapse={!show} when={show} delay={1000}>
        <img
          src={singleDiamondExchanges}
          className={classnames(s.imageFloating, s.singleDiamondEight)}
        />
      </Zoom>
      <Zoom collapse={!show} when={show} opposite delay={1000}>
        <img
          src={singleDiamondExchanges}
          className={classnames(s.imageFloating, s.singleDiamondNine)}
        />
      </Zoom>
      <Zoom opposite collapse={!show} when={show} delay={1500}>
        <img src={lineDiamondsExchanges} className={classnames(s.imageFloating, s.lineDiamond)} />
      </Zoom>
    </>
  );
};

export const TooltipCommunityAppears: React.FC<TProps> = ({ show }) => {
  return (
    <Fade top opposite collapse={!show} when={show} duration={1000} delay={1000}>
      <div className={s.ringTooltip}>
        <ToolTip
          inline={true}
          position="top"
          title={
            <>
              <img src={singleDiamondExchanges} />
              Local Energy Market
              <br />
              <div className={s.descriptionTooltip}>Homes/assets trade</div>
            </>
          }
        />
      </div>
    </Fade>
  );
};

export const CommunityAppears: React.FC<TProps> = ({ show }) => {
  return (
    <>
      <Fade opposite collapse={!show} when={show}>
        <img src={ringExchanges} className={classnames(s.imageFloating, s.ring)} />
      </Fade>
      <Fade opposite collapse={!show} when={show} delay={1500}>
        <img src={chartDayExchanges} className={classnames(s.imageFloating, s.chartDay)} />
      </Fade>
      <Fade opposite collapse={!show} when={show} delay={2000}>
        <img src={chartTradesExchanges} className={classnames(s.imageFloating, s.chartTrades)} />
      </Fade>
    </>
  );
};

export const Subtitle: React.FC<TProps> = ({ show }) => {
  return show ? (
    <>
      <div className={classnames(s.imageFloating, s.subtitle, s.s_agents)}>
        <img src={smallDiamondExchanges} />
        <span>Agents (Digital Twins)</span>
      </div>
      <div className={classnames(s.imageFloating, s.subtitle, s.s_market)}>
        <img src={marketsExchanges} />
        <span>Markets (Exchange)</span>
      </div>
    </>
  ) : (
    <></>
  );
};
