import gql from 'graphql-tag';

export const HouseSummaryResultsPartial = gql`
  subscription houseSummaryResultsPartial(
    $jobId: String!
    $assetUuid: String!
    $startTime: DateTime
    $timeResolutionHrs: Int
  ) {
    simulationAreaResultsPartial(
      jobId: $jobId
      areaUUID: $assetUuid
      timeResolutionHrs: $timeResolutionHrs
      startTime: $startTime
    ) {
      currentMarket
      areaThroughput
      marketSummary {
        averageEnergyRate
        timestamp
      }
      status
    }
  }
`;
