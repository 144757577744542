import { EFormVariant, TAssetType } from 'src/typings/base-types';
import { SpotMarketType } from 'src/graphql';
import {
  TFieldsUnionWithValue,
  TReactionByFieldName,
  TReactionOutput,
  TReactionPayload,
} from 'src/utils/assetsFields/assetsFields.types';
import { addFields, isSingleLibraryDevice, removeFields, replaceField } from 'src/utils/fieldUtils';
import {
  onAllowExternalConnection,
  onChangeRateDecreaseOrIncrease,
  onStorageCapacityChange,
  onforecastStreamEnabled,
  updateRateDecreaseOrIncrease,
} from 'src/utils/assetsFields/sharedSiblingFieldsReactions';

import { TFieldValuesByName } from 'src/utils/assetsFields/valuesByFieldName.types';
import { getSingleFieldTemplateWithValueForAsset } from 'src/utils/assetsFields/fieldTemplatesWithValues';

const gridMarketReactions = {
  energyRateType: ({
    fields,
    newValue,
    settingsData,
    isLibrary,
    configType,
    configurationCharacteristic,
  }: TReactionPayload): TReactionOutput => {
    let output = fields;

    if (newValue == 0) {
      output = removeFields({
        fields: output,
        names: ['energyRateProfile', 'energyRateProfileUuid'],
      });

      if (!output.some((field) => field.name === 'energyRate')) {
        const newField = getSingleFieldTemplateWithValueForAsset({
          type: 'MarketMaker',
          name: 'energyRate',
          value: null,
          settingsData,
          isLibrary,
          configType,
          configurationCharacteristic,
        });

        if (newField) {
          output = addFields({
            fields: output,
            newFields: [newField],
            appendAfterName: 'energyRateType',
          });
        }
      }
    } else if (newValue == 1) {
      const newField = getSingleFieldTemplateWithValueForAsset({
        type: 'MarketMaker',
        name: 'energyRateProfile',
        value: null,
        settingsData,
        isLibrary,
        configType,
        configurationCharacteristic,
      });

      if (newField) {
        output = replaceField({
          fields: output,
          name: 'energyRate',
          newFields: [newField],
        });
      }
    }

    return output;
  },
  energyRateProfile: ({ fields }: TReactionPayload): TReactionOutput => {
    let output = fields;

    output = removeFields({ fields: output, names: ['libraryUUID'] });

    return output;
  },
  buyingRateType: ({
    fields,
    newValue,
    settingsData,
    isLibrary,
    configType,
    configurationCharacteristic,
  }: TReactionPayload): TReactionOutput => {
    let output = fields;

    if (newValue == 0) {
      output = removeFields({
        fields: output,
        names: ['buyingRateProfile', 'buyingRateProfileUuid'],
      });

      if (!output.some((field) => field.name === 'energyBuyRate')) {
        const newField = getSingleFieldTemplateWithValueForAsset({
          type: 'InfiniteBus',
          name: 'energyBuyRate',
          value: null,
          settingsData,
          isLibrary,
          configType,
          configurationCharacteristic,
        });

        if (newField) {
          output = addFields({
            fields: output,
            newFields: [newField],
            appendAfterName: 'buyingRateType',
          });
        }
      }
    } else if (newValue == 1) {
      const newField = getSingleFieldTemplateWithValueForAsset({
        type: 'InfiniteBus',
        name: 'buyingRateProfile',
        value: null,
        settingsData,
        isLibrary,
        configType,
        configurationCharacteristic,
      });

      if (newField) {
        output = replaceField({
          fields: output,
          name: 'energyBuyRate',
          newFields: [newField],
        });
      }
    }

    return output;
  },
  buyingRateProfile: ({ fields }: TReactionPayload): TReactionOutput => {
    let output = fields;

    output = removeFields({ fields: output, names: ['libraryUUID'] });

    return output;
  },
};

const gridFeeEnabledReaction = ({
  type,
  fields,
  settingsData,
  newValue,
  isLibrary,
  configType,
  configurationCharacteristic,
}: TReactionPayload): TReactionOutput => {
  let output = fields;

  if (!newValue) {
    output = removeFields({
      fields: output,
      names: ['gridFeeConstant'],
    });

    return output;
  } else {
    const newField = getSingleFieldTemplateWithValueForAsset({
      type: 'Area',
      name: 'gridFeeConstant',
      value: 0,
      values: {
        gridFeeEnabled: true,
      },
      settingsData,
      isLibrary,
      configType,
      configurationCharacteristic,
    });

    if (newField) {
      if (type === 'InfiniteBus') newField.formView = EFormVariant.GridMarket;
      output = addFields({
        fields: output,
        newFields: [newField],
        appendAfterName: 'gridFeeEnabled',
      });
    }
  }

  return output;
};

export const siblingFieldsReactions: {
  [key in TAssetType]: TReactionByFieldName;
} = {
  /* Area */
  Area: {
    // TODO: Do we need geoTagType in phoenix?
    // geoTagType: ({
    //   fields,
    //   settingsData,
    //   newValue,
    //   prevValue,
    //   isLibrary,
    //   configType,
    // }: TReactionPayload): TReactionOutput => {
    //   let output = fields;
    //   if (prevValue === 'location') {
    //     output = removeFields({ fields, names: ['geoTagLocation'] });
    //   }

    //   if (newValue === 'location') {
    //     const newField = getSingleFieldTemplateWithValueForAsset({
    //       type: 'Area',
    //       name: 'geoTagLocation',
    //       value: null,
    //       settingsData,
    //       isLibrary,
    //       configType,
    //     });

    //     if (newField) {
    //       output = addFields({
    //         fields: output,
    //         newFields: [newField],
    //         appendAfterName: 'geoTagType',
    //       });
    //     }
    //   }

    //   return output;
    // },
    gridFeeEnabled: gridFeeEnabledReaction,
    transformerCapacityEnabled: ({
      fields,
      settingsData,
      newValue,
      isLibrary,
      configType,
      configurationCharacteristic,
    }: TReactionPayload): TReactionOutput => {
      let output = fields;

      if (!newValue) {
        output = removeFields({
          fields: output,
          names: ['importCapacityKva', 'exportCapacityKva'],
        });

        return output;
      } else {
        const payload = {
          type: 'Area' as const,
          value: 0,
          values: {
            transformerCapacityEnabled: true,
          },
          settingsData,
          isLibrary,
          configType,
          configurationCharacteristic,
        };

        const newFieldA = getSingleFieldTemplateWithValueForAsset({
          name: 'importCapacityKva',
          ...payload,
        });

        const newFieldB = getSingleFieldTemplateWithValueForAsset({
          name: 'exportCapacityKva',
          ...payload,
        });

        if (newFieldA && newFieldB) {
          output = addFields({
            fields: output,
            newFields: [newFieldA, newFieldB],
            appendAfterName: 'transformerCapacityEnabled',
          });
        }
      }

      return output;
    },
    baselinePeakEnergyEnabled: ({
      fields,
      newValue,
      settingsData,
      isLibrary,
      configType,
      configurationCharacteristic,
    }: TReactionPayload): TReactionOutput => {
      let output = fields;

      if (!newValue) {
        output = removeFields({
          fields: output,
          names: ['baselinePeakEnergyImportKwh', 'baselinePeakEnergyExportKwh'],
        });

        return output;
      } else {
        const payload = {
          type: 'Area' as const,
          value: 0,
          settingsData,
          values: {
            baselinePeakEnergyEnabled: true,
          },
          isLibrary,
          configType,
          configurationCharacteristic,
        };

        const newFieldA = getSingleFieldTemplateWithValueForAsset({
          name: 'baselinePeakEnergyImportKwh',
          ...payload,
        });

        const newFieldB = getSingleFieldTemplateWithValueForAsset({
          name: 'baselinePeakEnergyExportKwh',
          ...payload,
        });

        if (newFieldA && newFieldB) {
          output = addFields({
            fields: output,
            newFields: [newFieldA, newFieldB],
            appendAfterName: 'baselinePeakEnergyEnabled',
          });
        }
      }

      return output;
    },
  },

  /* PV */
  PV: {
    // allowExternalConnection: (args: TReactionPayload): TReactionOutput => {
    //   return onAllowExternalConnection(args);
    // },
    forecastStreamEnabled: (args: TReactionPayload): TReactionOutput => {
      return onforecastStreamEnabled(args);
    },
    energyRateDecreasePerUpdate: ({
      fields,
      settingsData,
      isLibrary,
      configType,
      configurationCharacteristic,
    }: TReactionPayload): TReactionOutput => {
      let output = fields;

      const newField = getSingleFieldTemplateWithValueForAsset({
        type: 'PV',
        name: 'fitToLimit',
        value: false,
        settingsData,
        isLibrary,
        configType,
        configurationCharacteristic,
      });

      if (newField) {
        output = replaceField({
          fields: output,
          name: 'fitToLimit',
          newFields: [newField],
        });
      }

      return output;
    },
    cloudCoverage: ({
      fields,
      newValue,
      settingsData,
      isLibrary,
      configType,
      configurationCharacteristic,
    }: TReactionPayload): TReactionOutput => {
      let output = fields;

      if (newValue != 4) {
        output = removeFields({
          fields: output,
          names: ['powerProfile', 'powerProfileUuid'],
        });
      }
      if (newValue == 4) {
        output = removeFields({
          fields: output,
          names: ['capacityKw'],
        });

        const newField = getSingleFieldTemplateWithValueForAsset({
          type: 'PV',
          name: 'powerProfile',
          value: null,
          settingsData,
          isLibrary,
          configType,
          configurationCharacteristic,
        });

        if (newField) {
          output = addFields({
            fields: output,
            newFields: [newField],
            appendAfterName: 'cloudCoverage',
          });
        }
      } else {
        output = removeFields({
          fields: output,
          names: ['powerProfile'],
        });
      }

      return output;
    },
    powerProfile: ({ fields }: TReactionPayload): TReactionOutput => {
      let output = fields;

      output = removeFields({ fields: output, names: ['powerProfileUuid'] });

      return output;
    },
    fitToLimit: ({
      settingsData,
      newValue,
      fields,
      isLibrary,
      configType,
      prevValue,
      configurationCharacteristic,
    }: TReactionPayload): TReactionOutput => {
      let output = fields;
      if (!configurationCharacteristic.gridMakerHasUploadedProfile) {
        output = updateRateDecreaseOrIncrease({
          newValue,
          fields: output,
          type: 'PV',
          direction: 'decrease',
          variable: 'fitToLimit',
          settingsData,
          isLibrary,
          configType,
          prevValue,
          configurationCharacteristic,
        });
      } else {
        const newField = getSingleFieldTemplateWithValueForAsset({
          type: 'PV',
          values: { fitToLimit: newValue as TFieldValuesByName<'PV'>['fitToLimit'] },
          name: 'energyRateDecreasePerUpdate',
          value: newValue ? 'Varying rate' : 1,
          settingsData,
          isLibrary,
          configType,
          configurationCharacteristic,
        });

        if (newField) {
          output = replaceField({
            fields: output,
            name: 'energyRateDecreasePerUpdate',
            newFields: [newField],
          });
        }
      }

      return output;
    },
    useMarketMakerRate: ({
      fields,
      settingsData,
      newValue,
      prevValue,
      isLibrary,
      configType,
      configurationCharacteristic,
    }: TReactionPayload): TReactionOutput => {
      let output = fields;

      if (String(newValue) === 'true') {
        output = removeFields({ fields: output, names: ['initialSellingRate'] });

        output = updateRateDecreaseOrIncrease({
          fields: output,
          newValue: configurationCharacteristic.marketMakerRate,
          prevValue,
          direction: 'decrease',
          type: 'PV',
          variable: 'initialSellingRate',
          settingsData,
          isLibrary,
          configType,
          configurationCharacteristic,
        });
      } else {
        const newField = getSingleFieldTemplateWithValueForAsset({
          type: 'PV',
          name: 'initialSellingRate',
          value: configurationCharacteristic.marketMakerRate,
          settingsData,
          isLibrary,
          configType,
          configurationCharacteristic,
        });

        if (newField) {
          output = addFields({
            fields: output,
            newFields: [newField],
            appendAfterName: 'useMarketMakerRate',
          });
        }
      }

      return output;
    },
    finalSellingRate: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      if (!isSingleLibraryDevice(args)) {
        output = onChangeRateDecreaseOrIncrease({
          ...args,
          fields: output,
          type: 'PV',
          rate: 'finalSellingRate',
        });
      }

      return output;
    },
    initialSellingRate: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      if (!isSingleLibraryDevice(args)) {
        output = onChangeRateDecreaseOrIncrease({
          ...args,
          fields: output,
          type: 'PV',
          rate: 'initialSellingRate',
        });
      }

      return output;
    },
    updateInterval: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      if (!args.configurationCharacteristic.gridMakerHasUploadedProfile) {
        output = updateRateDecreaseOrIncrease({
          ...args,
          fields: output,
          type: 'PV',
          direction: 'decrease',
          variable: 'updateInterval',
        });
      }

      return output;
    },
  },

  /* Storage */
  Storage: {
    allowExternalConnection: (args: TReactionPayload): TReactionOutput => {
      return onAllowExternalConnection(args);
    },
    initialSellingRate: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      output = onChangeRateDecreaseOrIncrease({
        ...args,
        fields: output,
        type: 'Storage',
        rate: 'initialSellingRate',
      });

      return output;
    },
    batteryCapacityKwh: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      output = onStorageCapacityChange(args);

      return output;
    },
    initialSoc: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      output = onStorageCapacityChange(args);

      return output;
    },
    finalSellingRate: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      output = onChangeRateDecreaseOrIncrease({
        ...args,
        fields: output,
        type: 'Storage',
        rate: 'finalSellingRate',
      });

      return output;
    },
    energyRateDecreasePerUpdate: ({
      fields,
      settingsData,
      isLibrary,
      configType,
      configurationCharacteristic,
    }: TReactionPayload): TReactionOutput => {
      let output = fields;

      const newField = getSingleFieldTemplateWithValueForAsset({
        type: 'Storage',
        name: 'fitToLimit',
        value: false,
        settingsData,
        isLibrary,
        configType,
        configurationCharacteristic,
      });

      if (newField) {
        output = replaceField({
          fields: output,
          name: 'fitToLimit',
          newFields: [newField],
        });
      }

      return output;
    },
    initialBuyingRate: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      output = onChangeRateDecreaseOrIncrease({
        ...args,
        fields: output,
        type: 'Storage',
        rate: 'initialBuyingRate',
      });

      return output;
    },
    finalBuyingRate: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      output = onChangeRateDecreaseOrIncrease({
        ...args,
        fields: output,
        type: 'Storage',
        rate: 'finalBuyingRate',
      });

      return output;
    },
    energyRateIncreasePerUpdate: ({
      fields,
      settingsData,
      isLibrary,
      configType,
      configurationCharacteristic,
    }: TReactionPayload): TReactionOutput => {
      let output = fields;

      const newField = getSingleFieldTemplateWithValueForAsset({
        type: 'Storage',
        name: 'fitToLimit',
        value: false,
        settingsData,
        isLibrary,
        configType,
        configurationCharacteristic,
      });

      if (newField) {
        output = replaceField({
          fields: output,
          name: 'fitToLimit',
          newFields: [newField],
        });
      }

      return output;
    },
    fitToLimit: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      output = updateRateDecreaseOrIncrease({
        ...args,
        fields: output,
        type: 'Storage',
        direction: 'decrease',
        variable: 'fitToLimit',
      });

      output = updateRateDecreaseOrIncrease({
        ...args,
        fields: output,
        type: 'Storage',
        direction: 'increase',
        variable: 'fitToLimit',
      });

      return output;
    },
    updateInterval: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      output = updateRateDecreaseOrIncrease({
        ...args,
        fields: output,
        type: 'Storage',
        direction: 'decrease',
        variable: 'updateInterval',
      });

      output = updateRateDecreaseOrIncrease({
        ...args,
        fields: output,
        type: 'Storage',
        direction: 'increase',
        variable: 'updateInterval',
      });

      return output;
    },
  },

  /* Load */
  Load: {
    // allowExternalConnection: (args: TReactionPayload): TReactionOutput => {
    //   return onAllowExternalConnection(args);
    // },
    forecastStreamEnabled: (args: TReactionPayload): TReactionOutput => {
      return onforecastStreamEnabled(args);
    },
    loadProfileOption: ({
      fields,
      newValue,
      settingsData,
      isLibrary,
      configType,
      configurationCharacteristic,
    }: TReactionPayload): TReactionOutput => {
      let output = fields;

      if (newValue === 'userConfigure') {
        const index = output.findIndex((f) => f.name === 'dailyLoadProfile');

        if (index !== -1) {
          const newFields = [
            getSingleFieldTemplateWithValueForAsset({
              type: 'Load',
              name: 'avgPowerW',
              value: 100,
              settingsData,
              isLibrary,
              configType,
              configurationCharacteristic,
            }),
            /*getSingleFieldTemplateWithValueForAsset({
              type: 'Load',
              name: 'hrsPerDay',
              value: 9,
              settingsData,
              isLibrary,
              configType,
              configurationCharacteristic,
            }),
            getSingleFieldTemplateWithValueForAsset({
              type: 'Load',
              name: 'hrsOfDay',
              value: [8, 17],
              settingsData,
              isLibrary,
              configType,
              configurationCharacteristic,
            }),*/
          ];

          output = replaceField({
            fields: output,
            name: 'dailyLoadProfile',
            newFields: newFields.filter(Boolean) as TFieldsUnionWithValue[],
          });

          output = removeFields({
            fields: output,
            names: ['dailyLoadProfileUuid'],
          });
        }
      } else if (newValue === 'userUpload') {
        const newField = getSingleFieldTemplateWithValueForAsset({
          type: 'Load',
          name: 'dailyLoadProfile',
          value: '',
          settingsData,
          isLibrary,
          configType,
          configurationCharacteristic,
        });

        if (newField) {
          output = replaceField({
            fields: output,
            name: 'avgPowerW',
            deleteCount: 3,
            newFields: [newField],
          });
        }
      }

      return output;
    },
    energyRateIncreasePerUpdate: ({
      fields,
      settingsData,
      isLibrary,
      configType,
      configurationCharacteristic,
    }: TReactionPayload): TReactionOutput => {
      let output = fields;
      const newField = getSingleFieldTemplateWithValueForAsset({
        type: 'Load',
        name: 'fitToLimit',
        value: false,
        settingsData,
        isLibrary,
        configType,
        configurationCharacteristic,
      });

      if (newField) {
        output = replaceField({
          fields: output,
          name: 'fitToLimit',
          newFields: [newField],
        });
      }

      return output;
    },
    fitToLimit: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      if (!args.configurationCharacteristic.gridMakerHasUploadedProfile) {
        output = updateRateDecreaseOrIncrease({
          ...args,
          fields: output,
          type: 'Load',
          direction: 'increase',
          variable: 'fitToLimit',
        });
      } else {
        const newField = getSingleFieldTemplateWithValueForAsset({
          ...args,
          type: 'Load',
          values: { fitToLimit: args.newValue as TFieldValuesByName<'Load'>['fitToLimit'] },
          name: 'energyRateIncreasePerUpdate',
          value: args.newValue ? 'Varying rate' : 1,
        });

        if (newField) {
          output = replaceField({
            fields: output,
            name: 'energyRateIncreasePerUpdate',
            newFields: [newField],
          });
        }
      }

      return output;
    },
    initialBuyingRate: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      output = onChangeRateDecreaseOrIncrease({
        ...args,
        fields: output,
        type: 'Load',
        rate: 'initialBuyingRate',
      });

      return output;
    },
    useMarketMakerRate: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      if (String(args.newValue) === 'true') {
        output = removeFields({ fields: output, names: ['finalBuyingRate'] });

        if (args.settingsData.spotMarketType !== SpotMarketType.OneSided) {
          output = updateRateDecreaseOrIncrease({
            ...args,
            fields: output,
            newValue: args.configurationCharacteristic.marketMakerRate,
            direction: 'increase',
            type: 'Load',
            variable: 'finalBuyingRate',
          });
        }
      } else {
        const newField = getSingleFieldTemplateWithValueForAsset({
          ...args,
          type: 'Load',
          name: 'finalBuyingRate',
          value: args.configurationCharacteristic.marketMakerRate,
        });

        if (newField) {
          output = addFields({
            fields: output,
            newFields: [newField],
            appendAfterName: 'useMarketMakerRate',
          });
        }
      }

      return output;
    },
    finalBuyingRate: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      if (args.settingsData.spotMarketType !== SpotMarketType.OneSided) {
        output = onChangeRateDecreaseOrIncrease({
          ...args,
          fields: output,
          type: 'Load',
          rate: 'finalBuyingRate',
        });
      }

      return output;
    },
    updateInterval: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      if (!args.configurationCharacteristic.gridMakerHasUploadedProfile) {
        output = updateRateDecreaseOrIncrease({
          ...args,
          fields: output,
          type: 'Load',
          direction: 'increase',
          variable: 'updateInterval',
        });
      }

      return output;
    },
  },

  /* WindTurbine */
  WindTurbine: {
    allowExternalConnection: (args: TReactionPayload): TReactionOutput => {
      return onAllowExternalConnection(args);
    },
    initialSellingRate: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      output = onChangeRateDecreaseOrIncrease({
        ...args,
        fields: output,
        type: 'Storage',
        rate: 'initialSellingRate',
      });

      return output;
    },
    batteryCapacityKwh: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      output = onStorageCapacityChange(args);

      return output;
    },
    initialSoc: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      output = onStorageCapacityChange(args);

      return output;
    },
    finalSellingRate: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      output = onChangeRateDecreaseOrIncrease({
        ...args,
        fields: output,
        type: 'Storage',
        rate: 'finalSellingRate',
      });

      return output;
    },
    energyRateDecreasePerUpdate: ({
      fields,
      settingsData,
      isLibrary,
      configType,
      configurationCharacteristic,
    }: TReactionPayload): TReactionOutput => {
      let output = fields;

      const newField = getSingleFieldTemplateWithValueForAsset({
        type: 'Storage',
        name: 'fitToLimit',
        value: false,
        settingsData,
        isLibrary,
        configType,
        configurationCharacteristic,
      });

      if (newField) {
        output = replaceField({
          fields: output,
          name: 'fitToLimit',
          newFields: [newField],
        });
      }

      return output;
    },
    initialBuyingRate: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      output = onChangeRateDecreaseOrIncrease({
        ...args,
        fields: output,
        type: 'Storage',
        rate: 'initialBuyingRate',
      });

      return output;
    },
    finalBuyingRate: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      output = onChangeRateDecreaseOrIncrease({
        ...args,
        fields: output,
        type: 'Storage',
        rate: 'finalBuyingRate',
      });

      return output;
    },
    energyRateIncreasePerUpdate: ({
      fields,
      settingsData,
      isLibrary,
      configType,
      configurationCharacteristic,
    }: TReactionPayload): TReactionOutput => {
      let output = fields;

      const newField = getSingleFieldTemplateWithValueForAsset({
        type: 'Storage',
        name: 'fitToLimit',
        value: false,
        settingsData,
        isLibrary,
        configType,
        configurationCharacteristic,
      });

      if (newField) {
        output = replaceField({
          fields: output,
          name: 'fitToLimit',
          newFields: [newField],
        });
      }

      return output;
    },
    fitToLimit: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      output = updateRateDecreaseOrIncrease({
        ...args,
        fields: output,
        type: 'Storage',
        direction: 'decrease',
        variable: 'fitToLimit',
      });

      output = updateRateDecreaseOrIncrease({
        ...args,
        fields: output,
        type: 'Storage',
        direction: 'increase',
        variable: 'fitToLimit',
      });

      return output;
    },
    updateInterval: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      output = updateRateDecreaseOrIncrease({
        ...args,
        fields: output,
        type: 'Storage',
        direction: 'decrease',
        variable: 'updateInterval',
      });

      output = updateRateDecreaseOrIncrease({
        ...args,
        fields: output,
        type: 'Storage',
        direction: 'increase',
        variable: 'updateInterval',
      });

      return output;
    },
  },

  /* SmartMeter */
  SmartMeter: {
    allowExternalConnection: (args: TReactionPayload): TReactionOutput => {
      return onAllowExternalConnection(args);
    },
    initialSellingRate: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      output = onChangeRateDecreaseOrIncrease({
        ...args,
        fields: output,
        type: 'Storage',
        rate: 'initialSellingRate',
      });

      return output;
    },
    batteryCapacityKwh: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      output = onStorageCapacityChange(args);

      return output;
    },
    initialSoc: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      output = onStorageCapacityChange(args);

      return output;
    },
    finalSellingRate: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      output = onChangeRateDecreaseOrIncrease({
        ...args,
        fields: output,
        type: 'Storage',
        rate: 'finalSellingRate',
      });

      return output;
    },
    energyRateDecreasePerUpdate: ({
      fields,
      settingsData,
      isLibrary,
      configType,
      configurationCharacteristic,
    }: TReactionPayload): TReactionOutput => {
      let output = fields;

      const newField = getSingleFieldTemplateWithValueForAsset({
        type: 'Storage',
        name: 'fitToLimit',
        value: false,
        settingsData,
        isLibrary,
        configType,
        configurationCharacteristic,
      });

      if (newField) {
        output = replaceField({
          fields: output,
          name: 'fitToLimit',
          newFields: [newField],
        });
      }

      return output;
    },
    initialBuyingRate: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      output = onChangeRateDecreaseOrIncrease({
        ...args,
        fields: output,
        type: 'Storage',
        rate: 'initialBuyingRate',
      });

      return output;
    },
    finalBuyingRate: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      output = onChangeRateDecreaseOrIncrease({
        ...args,
        fields: output,
        type: 'Storage',
        rate: 'finalBuyingRate',
      });

      return output;
    },
    energyRateIncreasePerUpdate: ({
      fields,
      settingsData,
      isLibrary,
      configType,
      configurationCharacteristic,
    }: TReactionPayload): TReactionOutput => {
      let output = fields;

      const newField = getSingleFieldTemplateWithValueForAsset({
        type: 'Storage',
        name: 'fitToLimit',
        value: false,
        settingsData,
        isLibrary,
        configType,
        configurationCharacteristic,
      });

      if (newField) {
        output = replaceField({
          fields: output,
          name: 'fitToLimit',
          newFields: [newField],
        });
      }

      return output;
    },
    fitToLimit: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      output = updateRateDecreaseOrIncrease({
        ...args,
        fields: output,
        type: 'Storage',
        direction: 'decrease',
        variable: 'fitToLimit',
      });

      output = updateRateDecreaseOrIncrease({
        ...args,
        fields: output,
        type: 'Storage',
        direction: 'increase',
        variable: 'fitToLimit',
      });

      return output;
    },
    updateInterval: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      output = updateRateDecreaseOrIncrease({
        ...args,
        fields: output,
        type: 'Storage',
        direction: 'decrease',
        variable: 'updateInterval',
      });

      output = updateRateDecreaseOrIncrease({
        ...args,
        fields: output,
        type: 'Storage',
        direction: 'increase',
        variable: 'updateInterval',
      });

      return output;
    },
  },

  /* HeatPump */
  HeatPump: {
    consumptionKwhProfile: ({ fields }: TReactionPayload): TReactionOutput => {
      let output = fields;

      output = removeFields({ fields: output, names: ['consumptionKwhProfileUuid'] });

      return output;
    },
    sourceTempCProfile: ({ fields }: TReactionPayload): TReactionOutput => {
      let output = fields;

      output = removeFields({ fields: output, names: ['sourceTempCProfileUuid'] });

      return output;
    },
    // externalTemperatureOption: ({
    //   fields,
    //   newValue,
    //   settingsData,
    //   isLibrary,
    //   configType,
    //   configurationCharacteristic,
    // }: TReactionPayload): TReactionOutput => {
    //   let output = fields;
    //   if (newValue === 'userConfigure') {
    //     const newField = getSingleFieldTemplateWithValueForAsset({
    //       type: 'HeatPump',
    //       name: 'initialTempC',
    //       value: 1,
    //       settingsData,
    //       isLibrary,
    //       configType,
    //       configurationCharacteristic,
    //     });

    //     if (newField) {
    //       output = replaceField({
    //         fields: output,
    //         name: 'sourceTempCProfile',
    //         newFields: [newField],
    //       });
    //       output = removeFields({
    //         fields: output,
    //         names: ['sourceTempCProfileUuid'],
    //       });
    //     }
    //   } else if (newValue === 'userUpload') {
    //     const newField = getSingleFieldTemplateWithValueForAsset({
    //       type: 'HeatPump',
    //       name: 'sourceTempCProfile',
    //       value: '',
    //       settingsData,
    //       isLibrary,
    //       configType,
    //       configurationCharacteristic,
    //     });

    //     if (newField) {
    //       output = replaceField({
    //         fields: output,
    //         name: 'initialTempC',
    //         // deleteCount: 3,
    //         newFields: [newField],
    //       });
    //     }

    //     // output = removeFields({
    //     //   fields: output,
    //     //   names: ['initialTempC'],
    //     // });
    //   }
    //   return output;
    // },
    initialBuyingRate: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      output = onChangeRateDecreaseOrIncrease({
        ...args,
        fields: output,
        type: 'Load',
        rate: 'initialBuyingRate',
      });

      return output;
    },
    finalBuyingRate: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      if (args.settingsData.spotMarketType !== SpotMarketType.OneSided) {
        output = onChangeRateDecreaseOrIncrease({
          ...args,
          fields: output,
          type: 'Load',
          rate: 'finalBuyingRate',
        });
      }

      return output;
    },
    updateInterval: (args: TReactionPayload): TReactionOutput => {
      let output = args.fields;

      if (!args.configurationCharacteristic.gridMakerHasUploadedProfile) {
        output = updateRateDecreaseOrIncrease({
          ...args,
          fields: output,
          type: 'Load',
          direction: 'increase',
          variable: 'updateInterval',
        });
      }

      return output;
    },
  },

  /* Market Maker */
  MarketMaker: gridMarketReactions,

  /* Infinite Bus */
  InfiniteBus: { ...gridMarketReactions, gridFeeEnabled: gridFeeEnabledReaction },

  FiniteDieselGenerator: {},
};
