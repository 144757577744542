import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import React, { useEffect, useRef, useState } from 'react';

import { BaseGenericModal } from 'src/components/BaseGenericModal';
import { BaseIcon } from 'src/components/BaseIcon';
import { BaseProgress } from 'src/components/BaseProgress';
import classnames from 'classnames';
import { closeModal } from 'src/redux/modals/modals.slice';
import s from './ModalOperateProcess.module.scss';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { useAppDispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';

export const ModalOperateProcess: React.FC = () => {
  const dispatch = useAppDispatch();
  const modalRef = useRef<HTMLDivElement>(null);
  const [operateProgress, setOperateProgress] = useState(0);

  const isModalDeployOpened = useSelector(
    selectIsModalOpened(EPredefinedModalIds.MODAL_OPERATE_PROCESS),
  );

  useEffect(() => {
    if (!isModalDeployOpened && operateProgress !== 0) {
      setOperateProgress(0);
    }
    if (isModalDeployOpened) {
      const interval = setInterval(() => {
        setOperateProgress((time) => time + 10);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [isModalDeployOpened, operateProgress]);

  const closeDeployProcessModal = () => {
    dispatch(closeModal(EPredefinedModalIds.MODAL_OPERATE_PROCESS));
  };

  if (!isModalDeployOpened) return null;

  return (
    <>
      <div className={s.background} onClick={closeDeployProcessModal} />
      <BaseGenericModal
        size={EModalSize.XL550}
        modalId={EPredefinedModalIds.MODAL_OPERATE_PROCESS}
        className={s.modal}
        modalRef={modalRef}>
        <div className={classnames(s.modalContentWrapper, s.modalDeployProcess)}>
          <BaseIcon icon={'canary'} className={s.canaryBox} size={48} />
          <h2 className={s.modalTitle}>{'Operate Community'}</h2>
          <p>{'Processing community configuration'}</p>
          <BaseProgress className={s.deployProcessProgressBar} progress={operateProgress} />
        </div>
      </BaseGenericModal>
    </>
  );
};
