import { Maybe } from 'src/graphql';

type TSortValue = 'asc' | 'desc';

export enum ESortOptions {
  asc = 'asc',
  desc = 'desc',
}

export const sortByCreateDate = (
  data:
    | Array<{
        joinedDate?: string;
      }>
    | undefined,
  value: TSortValue,
): Array<unknown> | undefined => {
  if (!data) return undefined;
  let tempData;
  if (value === ESortOptions.asc && data) {
    tempData = data.sort((a, b) =>
      a.joinedDate && b.joinedDate
        ? new Date(a.joinedDate).valueOf() - new Date(b.joinedDate).valueOf()
        : 1,
    );
  } else {
    tempData = data.sort((a, b) =>
      a.joinedDate && b.joinedDate
        ? new Date(b.joinedDate).valueOf() - new Date(a.joinedDate).valueOf()
        : 1,
    );
  }
  return tempData;
};

export const sortByProjects = (
  data: Array<{ numberOfSimulations?: Maybe<number> }> | undefined,
  value: TSortValue,
): Array<unknown> | undefined => {
  if (!data) return undefined;
  let tempData;
  if (value === ESortOptions.asc) {
    tempData = data.sort((a, b) =>
      a.numberOfSimulations && b.numberOfSimulations
        ? a.numberOfSimulations - b.numberOfSimulations
        : 1,
    );
  } else {
    tempData = data.sort((a, b) =>
      a.numberOfSimulations && b.numberOfSimulations
        ? b.numberOfSimulations - a.numberOfSimulations
        : 1,
    );
  }
  return tempData;
};

export const sortByString = (
  data: Array<Record<string, unknown>> | undefined,
  value: TSortValue,
  sortKey: string,
): Array<unknown> | undefined => {
  if (!data) return undefined;
  let tempData;
  if (value === ESortOptions.asc) {
    tempData = data.sort((a, b) => (a[sortKey] as string).localeCompare(b[sortKey] as string));
  } else {
    tempData = data.sort((a, b) => (b[sortKey] as string).localeCompare(a[sortKey] as string));
  }
  return tempData;
};
