import { TIconNames } from 'src/components/BaseIcon/IconNames.types';

export type TCommunityAssetsProps = {
  className?: string;
  style?: React.CSSProperties;
};

export type TCommunityAssetsControl = {
  label: ECommunityControlLabels;
  icon: TIconNames;
};

export enum ECommunityControlLabels {
  ToggleExpand = 'toggle-expand',
  Close = 'close',
}
