import {
  AreaInput,
  useCreateConfigurationAreaMutation,
  useCreateConfigurationMutation,
  useCreateProjectMutation,
  useDeleteConfigurationMutation,
  useDeleteProjectMutation,
  useRemoveConfigurationAreaMutation,
  useSetGlobalSimulationLocationMutation,
  useUpdateConfigurationAreaMutation,
  useUpdateConfigurationMutation,
  useUpdateProjectMutation,
} from 'src/graphql';
import { TAsset, TAssetArea, TConfigTree, TTreeBranch } from 'src/typings/configuration.types';
import {
  TChildrenPayload,
  TConfigurationManagementCentralProps,
} from 'src/components/ConfigurationManagementCentral';
import {
  TConfigurationState,
  addAssets,
  appendBranch,
  removeAssetByUuid,
  removeAssetByUuidWithDelete,
  resetSimulationResults,
  setActiveConfigurationUuid,
  setAssetLoadingInfo,
  setDescription,
  setLastAddedAssetUuid,
  setLocationVisible,
  setName,
  setProjectUuid,
  setResultsEndTime,
  setResultsStartTime,
  setRootAsset,
  setSelectedAssetUuid,
  setSettingsData,
  updateAssetTypeForUuid,
  updateAssetValuesForUuid,
  updateReadOnly,
} from 'src/redux/configuration/configuration.slice';
import {
  TDeviceTypesInput,
  prepareAreaInput,
  prepareSettingsInput,
} from 'src/utils/mutationsGates';
import { batch, useSelector } from 'react-redux';
import {
  getAllFieldTemplatesWithValuesForAsset,
  getAssetValues,
} from 'src/utils/assetsFields/fieldTemplatesWithValues';
import {
  initialApplicationState,
  setIsCustomHouseAddingContinuation,
  setModalCommunitySummaryView,
} from 'src/redux/application/application.slice';
import {
  selectActiveConfigurationUuid,
  selectAssets,
  selectAssetsAmountBelowCommunity,
  selectAssetsTreeRelations,
  selectAssetsValues,
  selectCommunityAsset,
  selectConfigType,
  selectConfigurationCenter,
  selectConfigurationCharacteristic,
  selectIsCommunityCreatedInDB,
  selectRootAssetUuid,
  selectSettingsData,
  selectSimulationStatus,
} from 'src/redux/configuration/configuration.selectors';
import {
  selectIsCustomHouseAddingContinuation,
  selectModalHouseSummaryView,
} from 'src/redux/application/application.selectors';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { EModalAssetsManagerView } from 'src/components/ModalAssetsManager';
import { EPredefinedModalIds } from 'src/constants/modals';
import { GRID_MARKET_ASSET_TYPES } from 'src/constants/application';
import { TAssetType } from 'src/typings/base-types';
import { TAssetsSaveProps } from 'src/components/FormAssetsParams';
import { THandleAssetValuesParameters } from 'src/components/ConfigurationManagementCentral/ConfigurationManagementCentral.types';
import _ from 'lodash';
import { cacheUpdate } from 'src/utils/cacheUpdate';
import { isLibrary } from 'src/mocks/configurationSettings';
import { openModal } from 'src/redux/modals/modals.slice';
import { openToast } from 'src/redux/toast/toast.slice';
import { selectIsLoggedIn } from 'src/redux/auth/auth.selectors';
import { selectSelectedLocation } from 'src/redux/map/map.selectors';
import { updateSelectedLocation } from 'src/redux/map/map.slice';
import { useAppDispatch } from 'src/redux/store';
import useBuildSavingTreeBranch from 'src/hooks/useBuildSavingTreeBranch';
import { useConfigurationUtils } from 'src/hooks/useConfigurationUtils';
import { useCurrentRefs } from 'src/hooks/useCurrentRefs';
import { useSyncConfigurationWithBE } from 'src/hooks/useSyncConfigurationForEvents';
import { v4 } from 'uuid';

export function ConfigurationManagementCentral({
  children,
}: TConfigurationManagementCentralProps): ReturnType<
  TConfigurationManagementCentralProps['children']
> {
  const dispatch = useAppDispatch();
  const assets = useSelector(selectAssets);
  const communityAsset = useSelector(selectCommunityAsset);
  const isConfigEmpty = Object.keys(assets).length === 0;
  const assetsTreeRelations = useSelector(selectAssetsTreeRelations);
  const assetsAmountBelowCommunity = useSelector(selectAssetsAmountBelowCommunity);
  const { syncConfigurationWithBE } = useSyncConfigurationWithBE();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const settingsData = useSelector(selectSettingsData);
  const configurationCharacteristic = useSelector(selectConfigurationCharacteristic);
  const configType = useSelector(selectConfigType);
  const rootAssetUuid = useSelector(selectRootAssetUuid);
  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
  const assetsValues = useSelector(selectAssetsValues);
  const isCommunityCreatedInDB = useSelector(selectIsCommunityCreatedInDB);
  const configurationCenter = useSelector(selectConfigurationCenter);
  const isCustomHouseAddingContinuation = useSelector(selectIsCustomHouseAddingContinuation);
  const modalHouseSummaryView = useSelector(selectModalHouseSummaryView);
  const selectedLocation = useSelector(selectSelectedLocation);
  const { discardCurrentConfiguration, zoomIntoConfiguration } = useConfigurationUtils();
  const [doSyncWithBackend, setDoSyncWithBackend] = useState<boolean>(false);
  const [doZoomIntoConfiguration, setDoZoomIntoConfiguration] = useState<boolean>(false);
  const saveKeyRef = useRef<string | null>(null);
  // Mutations
  const [createConfiguration] = useCreateConfigurationMutation({ errorPolicy: 'all' });
  const [updateConfiguration] = useUpdateConfigurationMutation({ errorPolicy: 'all' });
  const [createProject] = useCreateProjectMutation({ errorPolicy: 'all' });
  const [updateProject] = useUpdateProjectMutation({ errorPolicy: 'all' });
  const [deleteProject] = useDeleteProjectMutation({ errorPolicy: 'all' });
  const [createConfigurationArea] = useCreateConfigurationAreaMutation({
    errorPolicy: 'all',
  });
  const [removeConfigurationArea] = useRemoveConfigurationAreaMutation({
    onError: (err) => {
      const msg = JSON.parse(err.message).remove_configuration_area;
      dispatch(
        openToast({
          message: msg || 'Something went wrong',
          type: 'error',
        }),
      );
    },
  });
  const [updateConfigurationArea] = useUpdateConfigurationAreaMutation({
    errorPolicy: 'all',
  });
  const [deleteConfiguration] = useDeleteConfigurationMutation({ errorPolicy: 'all' });

  const [setGlobalSimulationLocation] = useSetGlobalSimulationLocationMutation({
    errorPolicy: 'all',
  });
  const {
    createAreaInputFromSavingTreeBranch,
    createSavingTreeBranch,
    createAreaInputFromSaveProps,
  } = useBuildSavingTreeBranch();

  const isCN = configType === 'CANARY_NETWORK';
  const centreCoordinates = useMemo(() => {
    return {
      longitude: configurationCenter?.lng,
      latitude: configurationCenter?.lat,
    };
  }, [configurationCenter?.lat, configurationCenter?.lng]);

  const handleCommunityRemove = useCallback(
    async ({
      projectUuid,
    }: Parameters<NonNullable<TChildrenPayload['handleCommunityRemove']>>[0]) => {
      await deleteProject({
        variables: { projectUuid },
        update(cache) {
          cacheUpdate.deleteProject(cache, { deletedProjectUuid: projectUuid });
        },
      }).then((result) => {
        if (result.errors) {
          const msg = (result.errors && result.errors[0]?.message) || 'Something went wrong';
          dispatch(
            openToast({
              message: msg || 'Something went wrong',
              type: 'error',
            }),
          );
        }
      });

      if (configurationCharacteristic.projectUuid === projectUuid) {
        discardCurrentConfiguration({ zoomOut: true });
      }
    },
    [configurationCharacteristic.projectUuid, dispatch, deleteProject, discardCurrentConfiguration],
  );

  const handleCNCommunityRemove = useCallback(
    async ({
      projectUuid,
      isCollaboration = false,
    }: {
      projectUuid: NonNullable<TConfigurationState['projectUuid']>;
      isCollaboration: boolean;
    }) => {
      const configurationUuid = projectUuid;
      await deleteConfiguration({
        variables: { configurationUuid },
        update(cache) {
          const deleteAction = isCollaboration
            ? cacheUpdate.deleteCollaboration
            : cacheUpdate.deleteConfiguration;
          deleteAction(cache, { deletedProjectUuid: projectUuid });
        },
      });

      if (configurationCharacteristic.projectUuid === projectUuid) {
        discardCurrentConfiguration({ zoomOut: true });
      }
    },
    [
      configurationCharacteristic.projectUuid,
      // deleteProject,
      discardCurrentConfiguration,
      deleteConfiguration,
    ],
  );

  const handleCreateNewCommunity = useCallback(async () => {
    try {
      const { locationVisible, name, description, timezone } = configurationCharacteristic;
      const { data: dataA, errors: errorsA } = await createProject({
        variables: {
          locationVisible,
          private: !locationVisible,
          centreCoordinates,
          name,
          description,
        },
      });

      const projectUuid = dataA?.createProject?.uuid;
      if (errorsA) throw errorsA[0].message;

      if (!projectUuid) {
        throw '"projectUuid" is required!';
      }

      const areaInput = prepareAreaInput({
        rootAssetUuid,
        assets,
        assetsTreeRelations,
        assetsValues,
        settingsData,
        configurationCharacteristic,
      });

      const { data: dataB, errors: errorsB } = await createConfiguration({
        variables: {
          name,
          description,
          projectUuid,
          settingsData: prepareSettingsInput(settingsData),
          scenarioData: {
            representation: areaInput,
          },
          centreCoordinates,
          timezone,
        },
        update(cache, results) {
          cacheUpdate.createConfiguration(cache, results, {
            latitude: centreCoordinates.latitude,
            longitude: centreCoordinates.longitude,
            locationVisible: locationVisible,
          });
        },
      });
      if (errorsB) {
        handleCommunityRemove({ projectUuid });
        throw errorsB;
      }

      const configurationUuid = dataB?.createConfiguration?.uuid;

      if (!configurationUuid) {
        throw '"configurationUuid" is required!';
      }

      batch(() => {
        dispatch(setProjectUuid(projectUuid));
        dispatch(setActiveConfigurationUuid(configurationUuid));
      });
      // eslint-disable-next-line
    } catch (errors: any) {
      // TODO: Display error on a toast
      const msg = (errors && errors[0]?.message) || 'Something went wrong';
      dispatch(
        openToast({
          message: msg || 'Something went wrong',
          type: 'error',
        }),
      );
    }
  }, [
    assets,
    assetsTreeRelations,
    assetsValues,
    centreCoordinates,
    configurationCharacteristic,
    createConfiguration,
    createProject,
    dispatch,
    handleCommunityRemove,
    rootAssetUuid,
    settingsData,
  ]);

  const handleUpdateCommunity = useCallback(async () => {
    if (!activeConfigurationUuid || !configurationCharacteristic.projectUuid) {
      return console.error('"activeConfigurationUuid" and "projectUuid" must be set!');
    }

    const {
      locationVisible,
      name,
      description,
      projectUuid,
      timezone,
    } = configurationCharacteristic;

    const settingsInput = prepareSettingsInput(settingsData);

    // We don't use Promise.all() because cache update has to fire after all queries passed.
    if (!isCN) {
      await updateProject({
        variables: {
          private: !locationVisible,
          locationVisible,
          centreCoordinates,
          name,
          description,
          projectUuid,
        },
      });

      await setGlobalSimulationLocation({
        variables: {
          configUuid: activeConfigurationUuid,
          centreCoordinates,
          // TODO: Backend should refactor their code and remove boundaryId I think.
          boundaryId: JSON.stringify(centreCoordinates),
          visibility: locationVisible,
        },
      });
    }

    const { data: dataA, errors: errorsA } = await updateConfiguration({
      variables: {
        private: !locationVisible || isCN,
        configurationUuid: activeConfigurationUuid,
        name,
        description,
        projectUuid,
        timezone,
        settingsData: settingsInput,
      },
      update(cache, result) {
        cacheUpdate.updateConfiguration(cache, result, {
          projectUuid,
          name,
          latitude: centreCoordinates.latitude,
          longitude: centreCoordinates.longitude,
          locationVisible,
        });
      },
    });
    if (errorsA) {
      const msg = (errorsA && errorsA[0]?.message) || 'Something went wrong';
      dispatch(
        openToast({
          message: msg,
          type: 'error',
        }),
      );
    } else if (dataA && dataA.updateConfiguration) {
      dispatch(
        openToast({
          message: 'Community updated successfully.',
          type: 'success',
        }),
      );
    }
  }, [
    dispatch,
    activeConfigurationUuid,
    configurationCharacteristic,
    settingsData,
    isCN,
    updateConfiguration,
    updateProject,
    centreCoordinates,
    setGlobalSimulationLocation,
  ]);

  const currentRefs = useCurrentRefs({
    isCommunityCreatedInDB,
    assetsAmountBelowCommunity,
    handleCreateNewCommunity,
    handleUpdateCommunity,
    zoomIntoConfiguration,
    dispatch,
  });

  const simulationStatus = useSelector(selectSimulationStatus);

  const addSaveKey = (key) => {
    saveKeyRef.current = key;
  };

  const saveWithoutDB = useCallback<
    (
      savingTreeBranch: TTreeBranch,
      connectorAssetUuid: string,
      gridMarketInTreeBranch: TAsset | undefined,
    ) => void
  >(
    (savingTreeBranch, connectorAssetUuid, gridMarketInTreeBranch) => {
      const areaResult = prepareAreaInput(
        gridMarketInTreeBranch
          ? {
              rootAssetUuid: savingTreeBranch.rootAssetUuid,
              assets: _.omit(savingTreeBranch.assets, [gridMarketInTreeBranch.uuid]),
              assetsTreeRelations: {
                ..._.omit(savingTreeBranch.assetsTreeRelations, [gridMarketInTreeBranch.uuid]),
                [savingTreeBranch.rootAssetUuid]: savingTreeBranch.assetsTreeRelations[
                  savingTreeBranch.rootAssetUuid
                ].filter((item) => item !== gridMarketInTreeBranch.uuid),
              },
              assetsValues: _.omit(savingTreeBranch.assetsValues, [gridMarketInTreeBranch.uuid]),
              settingsData,
              configurationCharacteristic,
            }
          : { ...savingTreeBranch, settingsData, configurationCharacteristic },
      );
      const loadingUuid =
        gridMarketInTreeBranch && areaResult ? areaResult.uuid : connectorAssetUuid;

      dispatch(setAssetLoadingInfo({ uuid: loadingUuid, isLoading: true }));
      setTimeout(() => dispatch(setAssetLoadingInfo({ uuid: loadingUuid, isLoading: false })), 100);
    },
    [configurationCharacteristic, settingsData, dispatch],
  );

  const saveNewAssetByMutation = useCallback<
    (
      areaResult: AreaInput,
      connectorAssetUuid: string,
      gridMarketInTreeBranch: TAsset | undefined,
    ) => Promise<boolean>
  >(
    (areaResult, connectorAssetUuid) => {
      //send request to BE
      return new Promise((resolve, reject) => {
        if (areaResult && activeConfigurationUuid) {
          if (saveKeyRef.current)
            dispatch(setAssetLoadingInfo({ uuid: saveKeyRef.current, isLoading: true }));
          return createConfigurationArea({
            variables: {
              areaRepresentation: { ...areaResult } as AreaInput,
              parentUuid: connectorAssetUuid,
              configurationUuid: activeConfigurationUuid,
            },
          })
            .then(async (data) => {
              if (data.errors && data.errors.length > 0) {
                const msg = data.errors[0].message;
                dispatch(
                  openToast({
                    message: msg || 'Something went wrong',
                    type: 'error',
                  }),
                );
                resolve(false);
              } else {
                resolve(true);
              }
              if (saveKeyRef.current)
                dispatch(setAssetLoadingInfo({ uuid: saveKeyRef.current, isLoading: false }));
              // if (data.data?.createConfigurationArea && areaResult && areaResult.uuid)
              //   dispatch(setSelectedAssetUuid(areaResult.uuid));

              setTimeout(() => {
                if (data?.data?.createConfigurationArea && areaResult && areaResult.uuid)
                  dispatch(setLastAddedAssetUuid(areaResult.uuid));
              }, 2000);
            })
            .catch((e) => reject(e));
        }
        return resolve(false);
      });
    },
    [createConfigurationArea, activeConfigurationUuid, saveKeyRef, dispatch],
  );

  const handleAddNewAsset = useCallback(
    (
      {
        values,
        chosenAsset,
        addUnderUuid,
        noStoreUpdate = false,
      }: Parameters<TChildrenPayload['handleAddNewAsset']>[0],
      options?: { stayOnSetSelectedAssetUuid: boolean },
    ) => {
      const { libraryUUID } = values;
      const { stayOnSetSelectedAssetUuid } = options || {};
      const communityAssetUuid = communityAsset?.uuid || v4();
      const connectorAssetUuid = addUnderUuid || communityAssetUuid;

      const savingTreeBranch = createSavingTreeBranch(values, connectorAssetUuid, chosenAsset);

      const gridMarketInTreeBranch = Object.values(savingTreeBranch.assets).find((asset) =>
        GRID_MARKET_ASSET_TYPES.includes(asset.type),
      );

      const addingSingleDevice =
        savingTreeBranch.assets[savingTreeBranch.rootAssetUuid].type !== 'Area';

      if (isConfigEmpty) {
        // Add a top-level "Grid", a "Grid Market", a "Community" market and the currently saving asset.
        const gridAsset: TAssetArea = {
          type: 'Area',
          uuid: v4(),
          parentUuid: undefined,
        };
        const gridAssetValues = getAssetValues(
          getAllFieldTemplatesWithValuesForAsset({
            type: 'Area',
            settingsData: settingsData,
            isLibrary,
            configType,
            values: { name: 'Grid Market' },
            configurationCharacteristic,
          }),
        );

        const gridMarketAsset: TAsset = {
          type: 'InfiniteBus',
          uuid: v4(),
          parentUuid: gridAsset.uuid,
        };
        const gridMarketAssetValues = getAssetValues(
          getAllFieldTemplatesWithValuesForAsset({
            type: 'InfiniteBus',
            settingsData: settingsData,
            isLibrary,
            configType,
            configurationCharacteristic,
          }),
        );

        const communityAsset: TAsset = {
          type: 'Area',
          uuid: communityAssetUuid,
          parentUuid: gridAsset.uuid,
        };
        const communityAssetValues = getAssetValues(
          getAllFieldTemplatesWithValuesForAsset({
            type: 'Area',
            settingsData: settingsData,
            isLibrary,
            configType,
            configurationCharacteristic,
            values: { name: 'Community' },
          }),
        );

        if (!noStoreUpdate) {
          batch(() => {
            dispatch(setRootAsset(gridAsset));
            dispatch(addAssets([gridMarketAsset, communityAsset]));
            dispatch(
              updateAssetValuesForUuid([
                {
                  values: gridAssetValues,
                  uuid: gridAsset.uuid,
                },
                {
                  values: gridMarketAssetValues,
                  uuid: gridMarketAsset.uuid,
                },
                {
                  values: communityAssetValues,
                  uuid: communityAsset.uuid,
                },
              ]),
            );
            dispatch(appendBranch(savingTreeBranch));

            if (gridMarketInTreeBranch) {
              dispatch(removeAssetByUuid(gridMarketInTreeBranch.uuid));
            }

            if (!stayOnSetSelectedAssetUuid) {
              dispatch(
                setSelectedAssetUuid(
                  addingSingleDevice ? communityAsset.uuid : savingTreeBranch.rootAssetUuid,
                ),
              );
            }

            setDoZoomIntoConfiguration(true);
          });
        } else {
          batch(() => {
            if (!stayOnSetSelectedAssetUuid) {
              dispatch(
                setSelectedAssetUuid(
                  addingSingleDevice ? communityAsset?.uuid : savingTreeBranch.rootAssetUuid,
                ),
              );
            }

            dispatch(appendBranch(savingTreeBranch));
            if (gridMarketInTreeBranch) {
              dispatch(removeAssetByUuid(gridMarketInTreeBranch.uuid));
            }
          });
        }

        dispatch(updateSelectedLocation(null));
      } else {
        batch(() => {
          if (!stayOnSetSelectedAssetUuid) {
            dispatch(
              setSelectedAssetUuid(
                addingSingleDevice ? communityAsset?.uuid : savingTreeBranch.rootAssetUuid,
              ),
            );
          }
          dispatch(appendBranch(savingTreeBranch));
          if (gridMarketInTreeBranch) {
            dispatch(removeAssetByUuid(gridMarketInTreeBranch.uuid));
          }
        });
      }

      if (savingTreeBranch) {
        if (isCommunityCreatedInDB) {
          const areaInput = createAreaInputFromSavingTreeBranch(
            savingTreeBranch,
            gridMarketInTreeBranch,
          );
          if (isCN && simulationStatus === 'running') {
            // eslint-disable-next-line
            const getAreaType = (areaType: any): TAssetType => {
              return areaType;
            };

            const areaRep: TConfigTree = {
              type: getAreaType(areaInput?.type),
              uuid: areaInput?.uuid,
              children:
                areaInput?.children &&
                (areaInput?.children.map((item) => ({
                  type: item?.type || '',
                  uuid: item?.uuid || '',
                  children: [],
                })) as TConfigTree['children']),
            };
            if (isCN && areaInput) {
              syncConfigurationWithBE({
                actionType: 'create',
                configurationUuid: activeConfigurationUuid || '',
                parentUuid: connectorAssetUuid,
                configTree: areaRep,
                assetParams: areaInput,
                library: {
                  libraryUuid: libraryUUID || '',
                  rootAreaName: areaInput?.name || '',
                  geoTagLocation: selectedLocation
                    ? [selectedLocation?.lng, selectedLocation?.lat]
                    : areaInput?.geoTagLocation,
                },
              });
            }
          } else {
            if (areaInput) {
              saveNewAssetByMutation(areaInput, connectorAssetUuid, gridMarketInTreeBranch).then(
                (data) => {
                  if (!data) dispatch(removeAssetByUuidWithDelete(savingTreeBranch.rootAssetUuid));
                },
              );
            }
          }
          //disabled after configuration Area mutations
          //setDoSyncWithBackend(true);
        } else {
          // SaveWithout DB
          saveWithoutDB(savingTreeBranch, connectorAssetUuid, gridMarketInTreeBranch);
        }
      }

      return {
        addedAssetUuid: savingTreeBranch.rootAssetUuid,
      };
    },
    [
      communityAsset?.uuid,
      isConfigEmpty,
      configType,
      isCommunityCreatedInDB,
      settingsData,
      configurationCharacteristic,
      createAreaInputFromSavingTreeBranch,
      saveNewAssetByMutation,
      dispatch,
      saveWithoutDB,
      createSavingTreeBranch,
      syncConfigurationWithBE,
      activeConfigurationUuid,
      isCN,
      simulationStatus,
      selectedLocation,
    ],
  );

  const handleAddAssetUnderUuid = useCallback(
    (payload: Parameters<NonNullable<TChildrenPayload['handleAddAssetUnderUuid']>>[0]) => {
      handleAddNewAsset(payload, { stayOnSetSelectedAssetUuid: true });
    },
    [handleAddNewAsset],
  );

  const handleAddNewCustomHouse = useCallback(
    (payload: Parameters<NonNullable<TChildrenPayload['handleAddNewCustomHouse']>>[0]) => {
      const { addedAssetUuid } = handleAddNewAsset(payload, { stayOnSetSelectedAssetUuid: true });

      /*batch(() => {
        dispatch(setModalHouseSummaryView(payload.nextView));
        if (payload.nextView === EModalAssetsManagerView.Form) {
          dispatch(setModalHouseSummaryFormVariant(EFormVariant.Advanced));
          dispatch(setIsCustomHouseAddingContinuation(true));
        }
      });*/

      // Important! setSelectedAssetUuid after setModalHouseSummaryView
      dispatch(setIsCustomHouseAddingContinuation(true));
      dispatch(setSelectedAssetUuid(addedAssetUuid));
    },
    [dispatch, handleAddNewAsset],
  );

  const handleAddNewCustomPV = useCallback(
    (payload: Parameters<NonNullable<TChildrenPayload['handleAddNewCustomPV']>>[0]) => {
      const { addedAssetUuid } = handleAddNewAsset(payload, { stayOnSetSelectedAssetUuid: true });
      dispatch(setSelectedAssetUuid(addedAssetUuid));
    },
    [dispatch, handleAddNewAsset],
  );

  const partialUpdateAssetByMutation = useCallback<(payload: TAssetsSaveProps) => Promise<boolean>>(
    (payload) => {
      return new Promise<boolean>((resolve) => {
        const areaParams: TDeviceTypesInput | undefined = createAreaInputFromSaveProps(payload);
        if (activeConfigurationUuid && areaParams) {
          if (saveKeyRef.current)
            dispatch(setAssetLoadingInfo({ uuid: saveKeyRef.current, isLoading: true }));
          return updateConfigurationArea({
            variables: {
              areaParams: areaParams,
              areaUuid: payload.assetUuid,
              configurationUuid: activeConfigurationUuid,
            },
          })
            .then((data) => {
              if (data.errors && data.errors.length > 0) {
                const parsedMessage = JSON.parse(data.errors[0].message);
                const msg = Object.values(parsedMessage).join('; ');
                dispatch(
                  openToast({
                    message: msg || 'Something went wrong',
                    type: 'error',
                  }),
                );
                resolve(false);
              }

              if (saveKeyRef.current)
                dispatch(setAssetLoadingInfo({ uuid: saveKeyRef.current, isLoading: false }));

              if (data.data?.updateConfigurationArea && payload && payload.assetUuid)
                dispatch(setLastAddedAssetUuid(payload.assetUuid));

              if (isCN && simulationStatus == 'running') {
                syncConfigurationWithBE({
                  actionType: 'singleUpdate',
                  configurationUuid: activeConfigurationUuid || '',
                  assetUuid: payload.assetUuid,
                  assetParams: {},
                });
              }
              resolve(true);
            })
            .catch(() => {
              if (saveKeyRef.current)
                dispatch(setAssetLoadingInfo({ uuid: saveKeyRef.current, isLoading: false }));
              resolve(false);
            });
        }
        resolve(false);
      });
    },
    [
      createAreaInputFromSaveProps,
      activeConfigurationUuid,
      dispatch,
      updateConfigurationArea,
      syncConfigurationWithBE,
      isCN,
      saveKeyRef,
      simulationStatus,
    ],
  );

  const dispatchUpdateAssetValues = useCallback<(payload: THandleAssetValuesParameters) => void>(
    (payload: THandleAssetValuesParameters) => {
      dispatch(updateAssetValuesForUuid({ uuid: payload.assetUuid, values: payload.values }));
      if (payload.assetType && payload.assetUuid) {
        dispatch(updateAssetTypeForUuid({ uuid: payload.assetUuid, assetType: payload.assetType }));
      }
    },
    [dispatch],
  );

  const handleAssetValuesSavePromise = useCallback<
    (payload: THandleAssetValuesParameters) => Promise<boolean>
  >(
    (payload) => {
      dispatchUpdateAssetValues(payload);

      dispatch(setSelectedAssetUuid(undefined));

      return new Promise<boolean>(async (resolve) => {
        if (isCommunityCreatedInDB) {
          const result = await partialUpdateAssetByMutation(payload);
          resolve(result);
        } else {
          resolve(false);
        }
      });
    },
    [dispatchUpdateAssetValues, isCommunityCreatedInDB, partialUpdateAssetByMutation, dispatch],
  );

  const getParentUuid = useCallback<(assetUuid: string) => string | undefined>(
    (assetUuid) => {
      const parentUuid = Object.keys(assetsTreeRelations).find((key) =>
        assetsTreeRelations[key].includes(assetUuid),
      );
      return parentUuid;
    },
    [assetsTreeRelations],
  );

  const handleAssetValuesSave = useCallback<(payload: THandleAssetValuesParameters) => void>(
    (payload) => {
      dispatchUpdateAssetValues(payload);

      if (isCommunityCreatedInDB) {
        partialUpdateAssetByMutation(payload);
      }

      const parentUuid = getParentUuid(payload.assetUuid);

      if (parentUuid && parentUuid !== communityAsset?.uuid) {
        dispatch(setSelectedAssetUuid(parentUuid));
      }
    },
    [
      dispatchUpdateAssetValues,
      isCommunityCreatedInDB,
      communityAsset,
      getParentUuid,
      dispatch,
      partialUpdateAssetByMutation,
    ],
  );

  const removeAssetByMutation = useCallback<(assetUuid: string) => void>(
    (assetUuid) => {
      if (activeConfigurationUuid && !isCN) {
        removeConfigurationArea({
          variables: {
            areaUuid: assetUuid,
            configurationUuid: activeConfigurationUuid,
          },
        }).then((data) => {
          if (data) dispatch(removeAssetByUuid(assetUuid));
        });
      }
      if (isCN) {
        syncConfigurationWithBE({
          actionType: 'delete',
          configurationUuid: activeConfigurationUuid || '',
          assetUuid,
        }).then((data) => {
          if (data) dispatch(removeAssetByUuid(assetUuid));
        });
      }
    },
    [activeConfigurationUuid, removeConfigurationArea, syncConfigurationWithBE, isCN, dispatch],
  );

  const handleAssetRemove = useCallback(
    (payload: Parameters<NonNullable<TChildrenPayload['handleAssetRemove']>>[0]) => {
      if (isCommunityCreatedInDB) {
        removeAssetByMutation(payload.assetUuid);
      }
    },
    [isCommunityCreatedInDB, removeAssetByMutation],
  );

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleAssetDuplicate = useCallback(() => {}, []);

  const handleSettingsDataSave = useCallback(
    (payload: Parameters<NonNullable<TChildrenPayload['handleSettingsDataSave']>>[0]) => {
      batch(() => {
        dispatch(setName(payload.name));
        dispatch(setDescription(payload.description));
        dispatch(setLocationVisible(payload.locationVisible));
        dispatch(updateReadOnly(true));
        const settings = {
          ...payload.settingsData,
          startDate: payload.settingsData.startDate || settingsData.startDate,
          endDate: payload.settingsData.endDate || settingsData.endDate,
          endDateTime: payload.settingsData.endDateTime || settingsData.endDateTime,
        };
        dispatch(setSettingsData(settings));

        if (communityAsset?.uuid) {
          dispatch(
            updateAssetValuesForUuid({
              values: {
                ...assetsValues[communityAsset.uuid],
                gridFeeConstant: payload.communityAssetSettings.gridFeeConstant,
                gridFeePercentage: payload.communityAssetSettings.gridFeePercentage,
                importCapacityKva: payload.settingsData?.importCapacityKva,
                exportCapacityKva: payload.settingsData?.exportCapacityKva,
                baselinePeakEnergyImportKwh: payload.settingsData?.baselinePeakEnergyImportKwh,
                baselinePeakEnergyExportKwh: payload.settingsData?.baselinePeakEnergyExportKwh,
              },
              uuid: communityAsset.uuid,
            }),
          );
          if (payload.settingsData?.endDate && payload.settingsData?.startDate) {
            dispatch(setResultsStartTime(payload.settingsData?.startDate));
            dispatch(setResultsEndTime(payload.settingsData?.endDate));
          }
        }
      });

      setDoSyncWithBackend(true);

      setTimeout(() => dispatch(updateReadOnly(false)), 3000);
    },
    [
      assetsValues,
      communityAsset?.uuid,
      dispatch,
      settingsData.endDate,
      settingsData.endDateTime,
      settingsData.startDate,
    ],
  );

  /*
   *
   *
   * Sync With Backend
   *
   */
  useEffect(
    function syncWithBackend() {
      (async () => {
        if (!doSyncWithBackend) return;

        if (!isLoggedIn) {
          if (
            currentRefs.current.assetsAmountBelowCommunity === 2 ||
            currentRefs.current.assetsAmountBelowCommunity === 50
          ) {
            currentRefs.current.dispatch(openModal(EPredefinedModalIds.MODAL_AUTH_REGISTER));
          }
          return;
        }

        if (!currentRefs.current.isCommunityCreatedInDB) {
          await currentRefs.current.handleCreateNewCommunity();
          setDoSyncWithBackend(false);
        } else {
          await currentRefs.current.handleUpdateCommunity();
          setDoSyncWithBackend(false);
        }

        if (!isCN) {
          currentRefs.current.dispatch(resetSimulationResults());
        }
      })();
    },
    [assetsValues, assets, settingsData, isLoggedIn, doSyncWithBackend, currentRefs, isCN],
  );

  /*
   *
   *
   * Zoom into newly added asset (configuration)
   *
   */
  useEffect(() => {
    if (!doZoomIntoConfiguration) return;

    currentRefs.current.zoomIntoConfiguration({ assetsValues });
    setDoZoomIntoConfiguration(false);
  }, [assetsValues, assets, settingsData, doZoomIntoConfiguration, currentRefs]);

  useEffect(() => {
    if (
      assetsAmountBelowCommunity === 2 &&
      !isCommunityCreatedInDB &&
      modalHouseSummaryView === initialApplicationState.modalHouseSummaryView
    ) {
      dispatch(setSelectedAssetUuid(communityAsset?.uuid));
      dispatch(setModalCommunitySummaryView(EModalAssetsManagerView.Form));
    }
  }, [
    assetsAmountBelowCommunity,
    communityAsset?.uuid,
    dispatch,
    isCommunityCreatedInDB,
    isCustomHouseAddingContinuation,
    modalHouseSummaryView,
  ]);

  useEffect(() => {
    if (selectedLocation) {
      dispatch(setModalCommunitySummaryView(EModalAssetsManagerView.SaveOrSettings));
    }
  }, [dispatch, selectedLocation]);

  return children({
    handleAddNewAsset,
    handleAddNewCustomHouse,
    handleAddNewCustomPV,
    handleAddAssetUnderUuid,
    handleAssetValuesSave,
    handleSettingsDataSave,
    handleAssetRemove,
    handleCommunityRemove,
    handleCNCommunityRemove,
    handleAssetDuplicate,
    handleAssetValuesSavePromise,
    addSaveKey,
  });
}
