import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  TAreaSummaryResult,
  TCanaryAssetData,
  TCanaryUserData,
  TCanaryUsersAssetsRelations,
} from 'src/components/MapSidebar/components/MapSidebarCanary';
import {
  selectActiveConfigurationUuid,
  selectAssets,
  selectAssetsTreeRelations,
  selectAssetsValues,
  selectRootAssetUuid,
} from 'src/redux/configuration/configuration.selectors';

import { CanaryNetworkServiceContext } from 'src/components/CanaryNetworkServiceProvider/CanaryNetworkServiceProvider';
import { CanaryRegistryList } from 'src/components/MapSidebar/components/MapSidebarCanary/components/CanaryRegistryList';
import {
  CanaryUserInvite,
  TCanaryUserInviteProps,
} from 'src/components/MapSidebar/components/MapSidebarCanary/components/CanaryUserInvite';
import { EUserRoles } from 'src/typings/base-types';
import s from './MapSidebarCanary.module.scss';
import { selectCanaryNetworkUsers } from 'src/redux/canaryNetwork/canaryNetwork.selectors';
import { selectUserRole } from 'src/redux/auth/auth.selectors';
import { useSelector } from 'react-redux';
import { CanaryInvitationPanel } from 'src/components/MapSidebar/components/MapSidebarCanary/components/CanaryInvitationPanel';

export const MapSidebarCanary: React.FC = () => {
  // Ctx
  const CanaryNetworkContext = useContext(CanaryNetworkServiceContext);

  // Selectors
  const userRole = useSelector(selectUserRole);
  const configUuid = useSelector(selectActiveConfigurationUuid);
  const users = useSelector(selectCanaryNetworkUsers);
  const assetsTreeRelations = useSelector(selectAssetsTreeRelations);
  const assetsValues = useSelector(selectAssetsValues);
  const assets = useSelector(selectAssets);
  const rootAssetUuid = useSelector(selectRootAssetUuid);

  // State
  const [invitationEmails, setInvitationEmails] = useState<string[]>([]);

  const areaSummary = useMemo<TAreaSummaryResult | undefined>(() => {
    const createSummaryObject = (
      uuid: string,
      children?: TAreaSummaryResult[],
    ): TAreaSummaryResult => ({
      name: assetsValues[uuid].name || '',
      type: assets[uuid].type,
      uuid: uuid,
      children,
    });

    if (rootAssetUuid) {
      const createTree = (items) => {
        return items.map((uuid) => {
          const obj = createSummaryObject(uuid);
          if (assetsTreeRelations[uuid].length > 0) {
            obj.children = createTree(assetsTreeRelations[uuid]);
          }
          return obj;
        });
      };

      return createSummaryObject(rootAssetUuid, createTree(assetsTreeRelations[rootAssetUuid]));
    }
  }, [assets, assetsTreeRelations, assetsValues, rootAssetUuid]);

  // Data
  const assetsData = useMemo(() => {
    const result: TCanaryAssetData[] = [];
    const createAsset = (item: TAreaSummaryResult): TCanaryAssetData => ({
      uuid: item.uuid,
      name: item.name === 'Grid' ? 'Grid Market' : item.name,
      type: item.type,
      icon: 'house',
    });
    const parseAssetResult = (item: TAreaSummaryResult) => {
      if (item.children) {
        if (item.type !== 'InfiniteBus') {
          result.push(createAsset(item));
        }
        item.children.forEach(parseAssetResult);
      } else {
        if (item.type !== 'InfiniteBus') {
          result.push(createAsset(item));
        }
      }
    };

    if (areaSummary) [areaSummary].forEach(parseAssetResult);

    return result;
  }, [areaSummary]);

  const usersData = useMemo<TCanaryUserData[]>(() => {
    return users.map((user) => ({
      uuid: `${user.id}`,
      name: user.email,
      subtitle: user.email,
      avatarUrl: user.profilePicture,
      requestStatus: user.requestStatus,
      canaryInvitations: user.canaryInvitations,
      isAggregator: user.userRole === 'Aggregator',
      isGridOperator: user.userRole === 'DSO',
      aggregatorInformation: user.aggregatorInformation,
    }));
  }, [users]);

  const usersToAssetRelations = useMemo<TCanaryUsersAssetsRelations>(() => {
    const relations = {};

    users.forEach((user) => {
      relations[user.email] = {};
      user.assetsConfigured.forEach((asset) => {
        relations[user.email][asset.uuid] = asset.registrationStatus;
      });
    });
    return relations;
  }, [users]);

  const onInviteClick: TCanaryUserInviteProps['onInviteClick'] = (emails) => {
    setInvitationEmails(emails);
  };

  // Effects
  useEffect(() => {
    if (configUuid) {
      CanaryNetworkContext.fetchCanaryNetworkRegistry(configUuid);
    }
  }, [CanaryNetworkContext, configUuid]);

  return (
    <div className={s.container}>
      {invitationEmails.length ? (
        <CanaryInvitationPanel emails={invitationEmails} onCancel={() => setInvitationEmails([])} />
      ) : (
        <>
          {userRole === EUserRoles.Admin && <CanaryUserInvite onInviteClick={onInviteClick} />}
          <CanaryRegistryList
            assetsData={assetsData}
            usersData={usersData}
            usersToAssetRelations={usersToAssetRelations}
          />
        </>
      )}
    </div>
  );
};
