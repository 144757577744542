import { APP_FLOW, useAppFlow } from 'src/hooks/useAppFlow';
import { EAppMode, getAppMode } from 'src/utils/appMode';
import React, { useEffect } from 'react';
import { setSCMCommunityManager, setSCMFlow } from 'src/redux/scm/scm.slice';

import { BaseIcon } from 'src/components/BaseIcon';
import { MobileWarning } from 'src/pages/MobileWarning';
import { ScmAuthRegisterForms } from 'src/components/ScmAuthRegisterForms';
import { TScmResetProps } from './ScmReset.types';
import classNames from 'classnames';
import { routesConfig } from 'src/routes/routes.config';
import s from './ScmReset.module.scss';
import { useAppDispatch } from 'src/redux/store';
import { useHistory } from 'react-router';

export const ScmReset: React.FC<TScmResetProps> = ({ match }) => {
  const history = useHistory();
  const mode = getAppMode();
  const dispatch = useAppDispatch();
  const { updateAppFlow, resetSCMSteps } = useAppFlow();
  const params = history.location.search;
  const searchParams = new URLSearchParams(params);
  const communityManager = searchParams.get('communityManager');
  useEffect(() => {
    updateAppFlow(APP_FLOW.SCM);
    dispatch(setSCMFlow(true));
    if (communityManager) dispatch(setSCMCommunityManager(communityManager));
  }, [dispatch, updateAppFlow, resetSCMSteps, communityManager]);

  const onSuccessRedirect = () => {
    return history.push(routesConfig.scmMap());
  };

  if (mode === EAppMode.Mobile) return <MobileWarning />;
  return (
    <div className={s.container}>
      <div className={s.loginContainer}>
        <div className={classNames(s.logincontainer, s.containerBg)}>
          <BaseIcon
            icon="gs-logo-white"
            size={80}
            className={s.logo}
            onClick={() => history.push(routesConfig.home())}
          />
          <ScmAuthRegisterForms match={match} onSuccess={() => onSuccessRedirect()} />
        </div>
      </div>
    </div>
  );
};
