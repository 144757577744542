import { TFieldValue, TOnChangePayload } from 'src/typings/base-types';

import { useState } from 'react';

export const interestOptions = [
  { label: 'Prosumer', value: 'Prosumer' },
  { label: 'Energy Community', value: 'Energy Community' },
  { label: 'Researcher', value: 'Researcher' },
  {
    label: 'Vendor / Home Automation / Asset Management',
    value: 'Vendor / Home Automation / Asset Management',
  },
  { label: 'Grid Operator', value: 'Grid Operator' },
  { label: 'Other', value: 'Other' },
];

export const initFormData = {
  name: '',
  email: '',
  password: '',
  interest: '',
  //if user selects other from the interest list options
  other: '',
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useSignUpFormData() {
  const [formData, setFormData] = useState(initFormData);

  const handleFieldChange = ({ value, name }: TOnChangePayload<TFieldValue, string>) => {
    setFormData((curr) => ({
      ...curr,
      [name]: value,
    }));
  };

  return { formData, handleFieldChange };
}
