import { ArrayElement, EDashboard, EUserRoles } from 'src/typings/base-types';
import React, { useEffect } from 'react';
import { selectIsLoggedIn, selectUserRole } from 'src/redux/auth/auth.selectors';

import { BaseIcon } from 'src/components/BaseIcon';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';
import { TMapLeftMenuBarProps } from './MapLeftMenuBar.types';
import classNames from 'classnames';
import s from './MapLeftMenuBar.module.scss';
import { selectActiveDashboard } from 'src/redux/application/application.selectors';
import { setActiveDashboard } from 'src/redux/application/application.slice';
import { useAppDispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';

const MENU_ITEMS: {
  title: string;
  icon: TIconNames;
  restrictRoles?: EUserRoles[];
  dashboard: EDashboard | undefined;
}[] = [
  {
    title: 'Map',
    icon: 'globe',
    dashboard: undefined,
  },
  {
    title: 'Library',
    icon: 'book',
    dashboard: EDashboard.Libraries,
    restrictRoles: [EUserRoles.Admin],
  },
  {
    title: 'Users',
    icon: 'users',
    dashboard: EDashboard.Users,
    restrictRoles: [EUserRoles.Admin],
  },
];

const EXTERNAL_LINKS: { title: string; icon: TIconNames; url: string }[] = [
  {
    title: 'Wiki',
    icon: 'wiki',
    url: 'https://gridsingularity.github.io/gsy-e/documentation/',
  },
  {
    title: 'Medium',
    icon: 'medium',
    url: 'https://gridsingularity.medium.com/',
  },
  {
    title: 'X',
    icon: 'x',
    url: 'https://x.com/gridsingularity',
  },
  {
    title: 'Slack',
    icon: 'slack',
    url: 'https://gsycommunity.slack.com/',
  },
  {
    title: 'Linkedin',
    icon: 'linkedin',
    url: 'https://www.linkedin.com/company/grid-singularity',
  },
  {
    title: 'Github',
    icon: 'github',
    url: 'https://github.com/gridsingularity',
  },
  {
    title: 'Email',
    icon: 'email',
    url: 'mailto:contact@gridsingularity.com',
  },
];

export const MapLeftMenuBar: React.FC<TMapLeftMenuBarProps> = () => {
  const dispatch = useAppDispatch();
  const activeDashboard = useSelector(selectActiveDashboard);
  const userRole = useSelector(selectUserRole);
  const isLoggedIn = useSelector(selectIsLoggedIn);

  // Fixed::PH-1160-Admin dashboard is visible to not logged in user
  useEffect(() => {
    if (!isLoggedIn && activeDashboard === EDashboard.Users) {
      dispatch(setActiveDashboard(undefined));
    }
  }, [activeDashboard, dispatch, isLoggedIn]);

  const canShowItemForUser = (item: ArrayElement<typeof MENU_ITEMS>) => {
    if (item.restrictRoles) {
      return userRole && item.restrictRoles.includes(userRole);
    }
    return true;
  };

  return (
    <div className={s.container}>
      <nav>
        {MENU_ITEMS.map(
          (item, i) =>
            canShowItemForUser(item) && (
              <button
                key={i}
                type="button"
                title={item.title}
                className={classNames({
                  [s.active]: activeDashboard === item.dashboard,
                })}
                onClick={() => {
                  dispatch(setActiveDashboard(item.dashboard));
                }}>
                <BaseIcon icon={item.icon} size={20} />
              </button>
            ),
        )}
      </nav>
      <footer>
        <ul>
          {EXTERNAL_LINKS.map((item, i) => (
            <li key={i}>
              <a href={item.url} target="_blank" rel="noreferrer" title={item.title}>
                <BaseIcon icon={item.icon} size={16} />
              </a>
            </li>
          ))}
        </ul>
        <span>V 1.2.0</span>
      </footer>
    </div>
  );
};
