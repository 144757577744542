import { ELsKey, ls } from 'src/utils/localStorage';

import { BaseButton } from 'src/components/BaseButton';
import React from 'react';
import { afterAuth } from 'src/constants/refetchQueries';
import s from './SocialLogins.module.scss';
import { setTokenAuth } from 'src/redux/auth/auth.slice';
import { useAppDispatch } from 'src/redux/store';
import { useGoogleLogin } from '@react-oauth/google';
import { useSocialAuthMutation } from 'src/graphql';

export type TGoogleAuthProps = {
  onSuccess: () => void;
};

export const GoogleAuth: React.FC<TGoogleAuthProps> = ({ onSuccess }) => {
  const dispatch = useAppDispatch();
  const [socialAuthMutation, { loading }] = useSocialAuthMutation();

  const handleGoogleLoginSucces = async (res) => {
    try {
      const { data } = await socialAuthMutation({
        variables: {
          accessToken: res?.access_token,
          provider: 'google-oauth2',
        },
        refetchQueries: ({ data }) => {
          ls.set(ELsKey.TOKEN_AUTH, data?.tokenAuth);

          return afterAuth;
        },
      });

      const token = data?.socialAuth;
      dispatch(setTokenAuth(token));
      onSuccess?.();
    } catch (err) {
      console.error('ERROR IN MUTATION!!!');
    }
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => handleGoogleLoginSucces(tokenResponse),
  });

  return (
    <div className={s.container}>
      <BaseButton
        disabled={loading}
        theme="grey"
        icon="google"
        className={s.googleButton}
        onClick={() => login()}>
        Sign up with Google
      </BaseButton>
    </div>
  );
};
