import { TAsset } from 'src/typings/configuration.types';
import { TPrevColorsByAssetUuid } from 'src/components/_charts/ChartEnergyTradeProfile';
import { getColorFromPalette } from 'src/constants/colorPalette';

const prevColorsByAssetUuid: TPrevColorsByAssetUuid = {};

export const getColor = (assetName: string, assetUuidOfResults: TAsset['uuid']): string => {
  let prevColors = prevColorsByAssetUuid[assetUuidOfResults];

  if (!prevColorsByAssetUuid[assetUuidOfResults]) {
    prevColors = {};
    prevColorsByAssetUuid[assetUuidOfResults] = prevColors;
  }

  const usedColors = Object.values(prevColors!);
  const color = prevColors![assetName];

  if (color) {
    return color;
  } else {
    const newColor = getColorFromPalette(usedColors.length, usedColors);
    prevColors![assetName] = newColor;
    return newColor;
  }
};
