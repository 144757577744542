import {
  ConfigType,
  useListCanaryNetworksLazyQuery,
  useListCollaborationsLazyQuery,
  useListCommunityInfoLazyQuery,
  useListPersonalCommunityInfoLazyQuery,
} from 'src/graphql';
import {
  EDropdownMenuFilter,
  EListFilter,
  TCommunitiesListProps,
  TListHeaderProps,
  TMapSidebarCanaryProjects,
  TMapSidebarCommunitiesListProps,
  TMapSidebarProjects,
} from './MapSidebarCommunitiesList.types';
import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import { FRONTEND_DATE_FORMATS, UTCMoment } from 'src/utils/UTCMoment';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import {
  selectCommunitiesList,
  selectUserToAdminRequests,
} from 'src/redux/communities/communities.selectors';
import {
  selectIsCNLaunching,
  selectIsCollaborationLaunching,
} from 'src/redux/configuration/configuration.selectors';
import {
  selectIsLoggedIn,
  selectTokenAuth,
  selectUserRole,
  selectUsername,
} from 'src/redux/auth/auth.selectors';

import { ApplicationContext } from 'src/contexts/ApplicationContext';
import { BaseSearch } from 'src/components/BaseSearch';
import { CommunityListItem } from 'src/components/CommunityListItem';
import { ConfigurationManagementCentral } from 'src/components/ConfigurationManagementCentral';
import { ContextMenu } from 'src/components/ContextMenu';
import { EAppMode } from 'src/utils/appMode';
import { EModalAssetsManagerView } from 'src/components/ModalAssetsManager/ModalAssetsManager.types';
import { EUserRoles } from 'src/typings/base-types';
import { FixedSizeList } from 'react-window';
import { MapSidebarCommunitiesHeader as Header } from './MapSidebarCommunitiesHeader';
import { LoadingWrapper } from 'src/components/LoadingWrapper';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';
import classnames from 'classnames';
import { convertLngLat } from 'src/utils/worldMap/helpers';
import { debounce } from 'lodash';
import { getConfigurationLocation } from 'src/utils/configuration/getConfigurationLocation';
import { openToast } from 'src/redux/toast/toast.slice';
import s from './MapSidebarCommunitiesList.module.scss';
import { selectAppMode } from 'src/redux/application/application.selectors';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { setActiveConfigurationUuid } from 'src/redux/configuration/configuration.slice';
import { setCommunitiesList } from 'src/redux/communities/communities.slice';
import { setOpenedConfigurationUuid as setGlobalOpenedConfigurationUuid } from 'src/redux/configuration/configuration.slice';
import { setModalCommunitySummaryView } from 'src/redux/application/application.slice';
import { useAppDispatch } from 'src/redux/store';
import { useDuplicateCommunity } from 'src/hooks/useDuplicateCommunity';
import { useLaunchToCanary } from 'src/hooks/useLaunchToCanary';
import { useLaunchToCollaboration } from 'src/hooks/useLaunchToCollaboration';
import { useModal } from 'src/hooks/useModal';
import { useSelector } from 'react-redux';
import vars from 'src/assets/styles/utils/vars.module.scss';

const FILTERS = [
  { name: 'All', value: EListFilter.All },
  { name: 'My Communities', value: EListFilter.MyCommunities },
];

const ListHeader: React.FC<TListHeaderProps> = ({
  activeFilter,
  onFilterChange,
  searchQuery,
  onSearchQueryChange,
  isLoggedIn,
  activeDropdownFilter,
}) => {
  return (
    <div className={s.listHeader}>
      {isLoggedIn && activeDropdownFilter === EDropdownMenuFilter.Simulations && (
        <nav>
          <ul>
            {FILTERS.map((item) => (
              <li
                key={item.value}
                className={classnames({
                  [s.active]: activeFilter === item.value,
                })}>
                <button type="button" onClick={() => onFilterChange(item.value)}>
                  {item.name}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      )}
      <BaseSearch
        name="communitiesSearch"
        theme="filled-gray"
        value={searchQuery}
        onChange={({ value }) => onSearchQueryChange(value)}
        label="Search by name"
        iconRight="search"
        iconRightColor={vars['color-black']}
      />
    </div>
  );
};

const ListItem = ({ index, style, data }) => {
  const item = data.items[index];
  const {
    timestamp,
    configurations: [configuration],
  } = item;
  const location = getConfigurationLocation(item);

  if (!location) return null;

  const locationCoords = convertLngLat(location);

  return (
    <div className={s.listItem} style={style}>
      <CommunityListItem
        className={s.listItem}
        user={item.user}
        configurationUuid={configuration.uuid}
        title={configuration.name}
        key={configuration.uuid}
        configType={configuration.configType}
        isPrivate={item.private}
        description={
          timestamp
            ? `Updated on ${UTCMoment.fromBackend(timestamp).format(
                FRONTEND_DATE_FORMATS.COMMUNITY_DATE,
              )}`
            : ''
        }
        locationCoords={locationCoords}
        onMenuClick={(menuTriggerRef, { communityUuid }) =>
          data.onMenuClick(menuTriggerRef, {
            communityUuid,
            configurationUuid: configuration.uuid,
            uuid: item.uuid,
          })
        }
      />
    </div>
  );
};

const CommunitiesList: React.FC<TCommunitiesListProps> = ({
  height,
  items,
  removeCommunity,
  updatePersonalCommunitiesList,
  onDuplicationComplete,
  activeDropdownFilter,
  setLoadingStatus,
}) => {
  const { id: modalId } = useModal();
  const dispatch = useAppDispatch();
  const { triggerCommunityDeleteAlert } = useContext(ApplicationContext);

  const modalIsOpen = useSelector(selectIsModalOpened(modalId));
  const userRole = useSelector(selectUserRole);
  const appMode = useSelector(selectAppMode);
  const userName = useSelector(selectUsername);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const userToAdminRequests = useSelector(selectUserToAdminRequests);
  const isCNLaunching = useSelector(selectIsCNLaunching);
  const isCollaborationLaunching = useSelector(selectIsCollaborationLaunching);

  const [menuModalTriggerRef, setMenuModalTriggerRef] = useState<
    React.RefObject<HTMLButtonElement | null>
  >({
    current: null,
  });
  const [openedProjectUuid, setOpenedProjectUuid] = useState<string | null>(null);
  const [openedConfigurationUuid, setOpenedConfigurationUuid] = useState<string | undefined>(
    undefined,
  );
  const [openedModalConfigUuid, setOpenedModalConfigUuid] = useState<string>();

  const {
    getDuplicationName,
    duplicateCanaryNetwork,
    duplicateCommunity,
    duplicateCollaboration,
    duplicateCollaborationLoading,
    duplicateConfigLoading,
    duplicateCNLoading,
  } = useDuplicateCommunity();

  const closeActiveModal = () => {
    setOpenedProjectUuid(null);
    setMenuModalTriggerRef({ current: null });
    dispatch(closeModal(modalId));
  };

  const onMenuClick = (menuTriggerRef, { communityUuid, configurationUuid, uuid }) => {
    setOpenedModalConfigUuid(communityUuid);
    if (modalIsOpen) {
      closeActiveModal();
    } else {
      setOpenedProjectUuid(uuid);
      setOpenedConfigurationUuid(configurationUuid);
      setMenuModalTriggerRef({ current: menuTriggerRef });
      dispatch(openModal(modalId));
    }
  };

  const openedDropdownParent = items.find(
    (item) => item.configurations[0]?.uuid === openedModalConfigUuid,
  );
  const openedDropdownParentIsCN =
    openedDropdownParent?.configurations[0]?.configType === ConfigType.CanaryNetwork;

  const openedDropdownParentIsCollaboration =
    openedDropdownParent?.configurations[0]?.configType === ConfigType.Collaboration;

  const isOwner = openedDropdownParent?.user === userName;
  const isAdmin = userRole === EUserRoles.Admin;

  const isRequestedForCn = useMemo(() => {
    return (
      openedConfigurationUuid &&
      userToAdminRequests[openedConfigurationUuid]?.find(
        (item) => item.type === 'CONVERT_SIM_TO_CN' && item.status === 'RECEIVED',
      )
    );
  }, [openedConfigurationUuid, userToAdminRequests]);

  const dropDownItems: {
    title: string;
    icon: TIconNames;
    onClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  }[] = [];

  if (isLoggedIn && (openedDropdownParentIsCN ? isOwner || isAdmin : true)) {
    dropDownItems.push({
      title: 'Duplicate',
      icon:
        duplicateCollaborationLoading || duplicateConfigLoading || duplicateCNLoading
          ? 'spinner'
          : 'duplicate',
      onClick: async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const configuration = openedDropdownParent?.configurations[0];
        if (configuration) {
          const duplicationName = getDuplicationName(configuration, items);

          try {
            if (openedDropdownParentIsCN) {
              duplicateCanaryNetwork(duplicationName, configuration.uuid).then(() => {
                onDuplicationComplete();
              });
            } else if (openedDropdownParentIsCollaboration) {
              duplicateCollaboration(duplicationName, configuration.uuid).then(() => {
                onDuplicationComplete();
              });
            } else {
              duplicateCommunity(duplicationName, configuration.uuid).then(() => {
                onDuplicationComplete();
              });
            }
          } catch (err) {
            // eslint-disable-next-line
            const msg = JSON.parse(`${(err as any).message}`).duplicate_canary_network[0];
            dispatch(
              openToast({
                message: msg,
                type: 'error',
              }),
            );
          }
          closeActiveModal();
          if (setLoadingStatus) setLoadingStatus(true);
        }
      },
    });
  }

  if (openedDropdownParent?.user === userName) {
    dropDownItems.push(
      {
        title: 'Edit',
        icon: 'pencil-create' as const,
        onClick: () => {
          if (openedModalConfigUuid) {
            dispatch(setActiveConfigurationUuid(openedModalConfigUuid));
            dispatch(setModalCommunitySummaryView(EModalAssetsManagerView.Form));
          }
        },
      },
      {
        title: 'Delete',
        icon: 'custom-trash' as const,
        onClick: async () => {
          if (openedProjectUuid) {
            await triggerCommunityDeleteAlert();

            await removeCommunity({ projectUuid: openedProjectUuid });
            updatePersonalCommunitiesList();
            closeActiveModal();
          }
        },
      },
    );
  }

  const { launchToCollaboration } = useLaunchToCollaboration();

  if (isAdmin) {
    dropDownItems.push({
      title: 'Start Collaboration',
      icon: isCollaborationLaunching ? 'spinner' : 'collaborate',
      onClick: async () => {
        launchToCollaboration({ openedConfigurationUuid });
      },
    });
  }

  const { launchToCanary } = useLaunchToCanary();
  if (!isRequestedForCn && activeDropdownFilter !== EDropdownMenuFilter.CanaryNetworks) {
    dropDownItems.push({
      title: 'Launch to Canary',
      icon: isCNLaunching ? 'spinner' : 'canary',
      onClick: async () => {
        if (!isCNLaunching && openedModalConfigUuid && openedConfigurationUuid) {
          if (userRole !== EUserRoles.Admin) {
            dispatch(setGlobalOpenedConfigurationUuid(openedConfigurationUuid));
            dispatch(openModal(EPredefinedModalIds.MODAL_CANARY_REQUEST));
            return;
          }
          launchToCanary({ openedConfigurationUuid });
        }
      },
    });
  }

  return (
    <div className={s.communitiesList}>
      <FixedSizeList
        height={height}
        itemData={{
          onMenuClick,
          items,
        }}
        itemCount={items.length}
        itemSize={appMode === EAppMode.Desktop ? 90 : 215}
        onScroll={closeActiveModal}
        width={EModalSize.L370}
        className={classnames(s.communitiesListWindow)}>
        {ListItem}
      </FixedSizeList>
      <ContextMenu
        relativeElement={menuModalTriggerRef}
        modalId={modalId}
        position={{
          side: 'right',
          anchorPosition: 50,
        }}
        items={dropDownItems}
      />
    </div>
  );
};

export const MapSidebarList: React.FC<{
  mobileListClassName?: string;
  searchQuery;
  setSearchQuery;
  activeDropdownFilter?: EDropdownMenuFilter;
}> = ({ mobileListClassName, searchQuery, setSearchQuery, activeDropdownFilter }) => {
  const dispatch = useAppDispatch();
  const [activeFilter, setActiveFilter] = useState(EListFilter.All);
  const [listHeight, setListHeight] = useState<number>();
  const communitiesList = useSelector(selectCommunitiesList);
  const token = useSelector(selectTokenAuth);
  const appMode = useSelector(selectAppMode);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [debouncedSearchQuery, __setDebouncedSearchQuery] = useState(searchQuery);
  const [isloading, setLoading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setDebouncedSearchQuery = useCallback(
    debounce((val) => {
      __setDebouncedSearchQuery(val);
    }, 400),
    [],
  );

  useEffect(() => {
    setDebouncedSearchQuery(searchQuery);
  }, [searchQuery, setDebouncedSearchQuery]);

  useEffect(() => {
    if (!isLoggedIn) {
      setActiveFilter(EListFilter.All);
    }
  }, [isLoggedIn]);

  const onListContainerLayout = useCallback(
    (elem: HTMLDivElement) => {
      if (elem && !listHeight) {
        setListHeight(elem.clientHeight);
      }
    },
    [listHeight],
  );

  const [
    fetchPersonalCommunities,
    { data: personalCommunities, loading: personalCommunitiesLoading },
  ] = useListPersonalCommunityInfoLazyQuery({ fetchPolicy: 'cache-and-network' });

  const [
    fetchCommunities,
    { data: allCommunities, loading: communitiesLoading },
  ] = useListCommunityInfoLazyQuery({ fetchPolicy: 'cache-and-network' });

  const [fetchCanaryNetworks, { data: allCanaryNetworks }] = useListCanaryNetworksLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [fetchCollaborations, { data: allCollaborations }] = useListCollaborationsLazyQuery({
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    switch (activeDropdownFilter) {
      case EDropdownMenuFilter.CanaryNetworks:
        fetchCanaryNetworks();
        return;
      case EDropdownMenuFilter.Collaborations:
        fetchCollaborations();
        return;
      default: {
        fetchCommunities();
        if (isLoggedIn) {
          fetchPersonalCommunities(); // Preload personal communities asynchronously right away if user is loggen in for better user experience
        }
      }
    }
  }, [
    activeDropdownFilter,
    activeFilter,
    fetchCanaryNetworks,
    fetchCommunities,
    fetchCollaborations,
    fetchPersonalCommunities,
    isLoggedIn,
  ]);

  const projects = useMemo(() => {
    if (
      (activeDropdownFilter === EDropdownMenuFilter.Simulations &&
        activeFilter === EListFilter.MyCommunities) ||
      appMode === EAppMode.Mobile
    ) {
      return personalCommunities?.listPersonalCommunityInfo?.projects || [];
    } else if (activeDropdownFilter === EDropdownMenuFilter.CanaryNetworks && isLoggedIn) {
      return allCanaryNetworks?.listCanaryNetworks?.configurations || [];
    } else if (activeDropdownFilter === EDropdownMenuFilter.Collaborations && isLoggedIn) {
      return allCollaborations?.listCollaborations?.configurations || [];
    } else {
      return allCommunities?.listCommunityInfo?.projects || [];
    }
  }, [
    activeDropdownFilter,
    activeFilter,
    allCanaryNetworks?.listCanaryNetworks?.configurations,
    allCollaborations?.listCollaborations?.configurations,
    allCommunities?.listCommunityInfo?.projects,
    appMode,
    isLoggedIn,
    personalCommunities?.listPersonalCommunityInfo?.projects,
  ]);

  const onDuplicationComplete = () => {
    setActiveFilter(EListFilter.MyCommunities);
    setLoading(false);
    fetchPersonalCommunities();
    if (activeDropdownFilter === EDropdownMenuFilter.CanaryNetworks) {
      fetchCanaryNetworks();
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let items: any = [];

    if (
      activeDropdownFilter &&
      [EDropdownMenuFilter.CanaryNetworks, EDropdownMenuFilter.Collaborations].includes(
        activeDropdownFilter,
      )
    ) {
      items = (projects as TMapSidebarCanaryProjects)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .reduce((acc: any, item) => {
          if (item.coordinates?.latitude && item.coordinates?.longitude) {
            acc.push({
              name: item.name || '',
              latitude: item.coordinates.latitude,
              locationVisible: true,
              longitude: item.coordinates.longitude,
              private: false,
              timestamp: item.timestamp,
              user: item.user,
              uuid: item.uuid,
              configurations: [
                {
                  configType: item.configType,
                  latitude: item.coordinates.latitude,
                  longitude: item.coordinates.longitude,
                  name: item.name,
                  uuid: item.uuid,
                },
              ],
            });
          }

          return acc;
        }, [])
        .filter((item) => {
          return item!.name!.toLowerCase().includes(debouncedSearchQuery.toLowerCase());
        })
        .sort((a, b) => +new Date(b?.timestamp) - +new Date(a?.timestamp));
    } else {
      items = (projects as TMapSidebarProjects)
        .reduce((acc: TMapSidebarProjects, item) => {
          let include = false;

          if (!item) return acc;

          const communityHasLocation = Boolean(getConfigurationLocation(item));
          if (item.configurations?.length && communityHasLocation) {
            if (activeFilter === EListFilter.MyCommunities) {
              if (token?.username === item.user) {
                include = true;
              }
            } else {
              include = true;
            }
          }

          if (include) {
            item.configurations?.forEach((config) => {
              if (config) {
                acc.push({
                  ...item,
                  name: config.name,
                  configurations: [config],
                });
              }
            });
          }

          return acc;
        }, [])
        .filter((item) => {
          return item!.name!.toLowerCase().includes(debouncedSearchQuery.toLowerCase());
        })
        .sort((a, b) => +new Date(b?.timestamp) - +new Date(a?.timestamp));
    }

    dispatch(setCommunitiesList(items));
  }, [
    activeFilter,
    dispatch,
    projects,
    debouncedSearchQuery,
    token?.username,
    activeDropdownFilter,
  ]);

  if (appMode === EAppMode.Mobile) {
    return (
      <div className={classnames(s.list, mobileListClassName)} ref={onListContainerLayout}>
        <LoadingWrapper
          loading={!listHeight || personalCommunitiesLoading || communitiesLoading || isloading}>
          {() => (
            <ConfigurationManagementCentral>
              {({ handleCommunityRemove, handleCNCommunityRemove }) => (
                <CommunitiesList
                  items={communitiesList}
                  height={listHeight!}
                  removeCommunity={
                    activeDropdownFilter === EDropdownMenuFilter.CanaryNetworks
                      ? handleCNCommunityRemove
                      : activeDropdownFilter === EDropdownMenuFilter.Collaborations
                      ? ({ projectUuid }) =>
                          handleCNCommunityRemove({ projectUuid, isCollaboration: true })
                      : handleCommunityRemove
                  }
                  updatePersonalCommunitiesList={
                    activeDropdownFilter === EDropdownMenuFilter.CanaryNetworks
                      ? fetchCanaryNetworks
                      : activeDropdownFilter === EDropdownMenuFilter.Collaborations
                      ? fetchCollaborations
                      : fetchPersonalCommunities
                  }
                  setLoadingStatus={setLoading}
                  onDuplicationComplete={onDuplicationComplete}
                  activeDropdownFilter={activeDropdownFilter}
                />
              )}
            </ConfigurationManagementCentral>
          )}
        </LoadingWrapper>
      </div>
    );
  }
  return (
    <div className={s.listContainer}>
      <ListHeader
        activeFilter={activeFilter}
        onFilterChange={setActiveFilter}
        searchQuery={searchQuery}
        onSearchQueryChange={setSearchQuery}
        isLoggedIn={isLoggedIn}
        activeDropdownFilter={activeDropdownFilter}
      />
      <div className={s.list} ref={onListContainerLayout}>
        <LoadingWrapper
          loading={!listHeight || personalCommunitiesLoading || communitiesLoading || isloading}>
          {() => (
            <ConfigurationManagementCentral>
              {({ handleCommunityRemove, handleCNCommunityRemove }) => (
                <CommunitiesList
                  items={communitiesList}
                  height={listHeight!}
                  removeCommunity={
                    activeDropdownFilter === EDropdownMenuFilter.CanaryNetworks
                      ? handleCNCommunityRemove
                      : activeDropdownFilter === EDropdownMenuFilter.Collaborations
                      ? ({ projectUuid }) =>
                          handleCNCommunityRemove({ projectUuid, isCollaboration: true })
                      : handleCommunityRemove
                  }
                  updatePersonalCommunitiesList={
                    activeDropdownFilter === EDropdownMenuFilter.CanaryNetworks
                      ? fetchCanaryNetworks
                      : activeDropdownFilter === EDropdownMenuFilter.Collaborations
                      ? fetchCollaborations
                      : fetchPersonalCommunities
                  }
                  onDuplicationComplete={onDuplicationComplete}
                  activeDropdownFilter={activeDropdownFilter}
                  setLoadingStatus={setLoading}
                />
              )}
            </ConfigurationManagementCentral>
          )}
        </LoadingWrapper>
      </div>
    </div>
  );
};

export const MapSidebarCommunitiesList: React.FC<TMapSidebarCommunitiesListProps> = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [activeDropdownFilter, setActiveDropdownFilter] = useState<EDropdownMenuFilter>(
    EDropdownMenuFilter.Simulations,
  );

  return (
    <div className={s.container}>
      <Header
        activeDropdownFilter={activeDropdownFilter}
        setActiveDropdownFilter={setActiveDropdownFilter}
      />
      <MapSidebarList
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        activeDropdownFilter={activeDropdownFilter}
      />
    </div>
  );
};
