import { BaseErrorMessage } from 'src/components/BaseErrorMessage';
import { BaseIcon } from '../BaseIcon';
import React from 'react';
import { TBaseCheckboxProps } from './BaseCheckbox.types';
import classnames from 'classnames';
import s from './BaseCheckbox.module.scss';

export const BaseCheckbox: React.FC<TBaseCheckboxProps> = function BaseCheckbox({
  className = '',
  name,
  value,
  label,
  theme = 'dark',
  disabled = false,
  onChange,
  error,
}) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { checked } = e.target;
    onChange({ name, value: checked });
  };

  return (
    <div
      className={classnames(s.checkboxWrapper, className, {
        [`${s.themeLight}`]: theme === 'light',
        [`${s.themeDark}`]: theme === 'dark',
        [`${s.themeGradientDark}`]: theme === 'gradient-dark',
        [`${s.themeGradientDarkExtended}`]: theme === 'gradient-dark-extended',
        [`${s.checked}`]: value,
        [s.disabled]: disabled,
        [s.hasError]: !!error,
      })}>
      <label className={s.label}>
        <input
          type="checkbox"
          id={`base-checkbox-${name}`}
          name={name}
          checked={value}
          disabled={disabled}
          className={s.checkboxInput}
          onChange={handleChange}
        />
        <div className={s.theBox}>
          <BaseIcon icon="check-mark" className={s.iconCheck} />
        </div>
        <span>{label}</span>
      </label>
      <BaseErrorMessage>{error}</BaseErrorMessage>
    </div>
  );
};
