import React, { useEffect, useRef, useState } from 'react';
import { TFilterDropdownOption, TFilterDropdownProps } from './ResourcesFilter.types';

import { BaseButton } from 'src/components/BaseButton';
import { BaseIcon } from 'src/components/BaseIcon';
import { BaseList } from 'src/components/BaseList';
import classnames from 'classnames';
import s from './ResourcesFilter.module.scss';
import useOutsideClick from 'src/hooks/useOutsideClick';

// Filters Interpretation
// 1 - Future Events
// 2 - Past Events
// 3 - Archived Events

const options: TFilterDropdownOption[] = [
  { label: 'Recommended', value: '1' },
  { label: 'Newest', value: '2' },
  { label: 'A-Z', value: '3' },
  { label: 'Z-A', value: '4' },
];

export const ResourcesFilter: React.FC<TFilterDropdownProps> = ({
  setFilter,
  setSort,
  filters = [],
  initialFilter = [],
}) => {
  const [selectedFilters, setSelectedFilters] = useState<number[]>([]);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const outsideClickElRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setSelectedFilters(initialFilter);
  }, [initialFilter]);

  const updateFilter = (filter: number) => {
    if (selectedFilters.includes(filter)) {
      return;
    }
    setSelectedFilters([filter]);

    if (setFilter) {
      setFilter([filter]);
    }
  };

  const IsFilterSelected = (filter: number) => selectedFilters.includes(filter);

  const handleSelect = (item: TFilterDropdownOption) => {
    if (setSort) {
      setSort(item);
    }
  };

  useOutsideClick(outsideClickElRef, (event) => {
    if (event.target !== outsideClickElRef?.current && showDropdown) {
      setShowDropdown(false);
    }
  });

  return (
    <>
      <div className={s.content}>
        <div ref={outsideClickElRef}>
          <BaseButton
            className={s.iconBtn}
            theme="primary"
            onClick={() => {
              setShowDropdown((prevState) => !prevState);
            }}>
            <BaseIcon className={s.filterIcon} icon="filter" size={18} />
          </BaseButton>
          {showDropdown && (
            <div className={s.optionsContainer}>
              <BaseList width="100%" className={s.list}>
                {options.map((item, index) => (
                  <div
                    key={index}
                    className={classnames(s.item)}
                    onClick={() => {
                      handleSelect(item);
                    }}>
                    <input type="radio" id={item.label} name="filter" value={item.value} />
                    <label htmlFor={item.label}>{item.label}</label>
                  </div>
                ))}
              </BaseList>
            </div>
          )}
        </div>
        {filters?.map((e, key) => (
          <BaseButton
            key={key.toString()}
            theme="primary"
            className={classnames(s.filterBtn, {
              [s.filterSeleted]: IsFilterSelected(e.id),
            })}
            onClick={() => updateFilter(e.id)}>
            <span className={s.label}>{e.name}</span>
          </BaseButton>
        ))}
      </div>
    </>
  );
};

export const orderByText = (textA: string, textB: string, orderBy = 'desc'): 0 | 1 | -1 => {
  const titleA = textA.toLowerCase();
  const titleB = textB.toLowerCase();

  if (orderBy === 'asc') {
    if (titleA < titleB) return -1;
    if (titleA > titleB) return 1;
    return 0;
  }
  if (titleA > titleB) return -1;
  if (titleA < titleB) return 1;
  return 0;
};

export const orderByDate = (dateA: string, dateB: string, orderBy = 'desc'): number => {
  const dateStart = (new Date(dateA) as unknown) as number;
  const dateEnd = (new Date(dateB) as unknown) as number;

  if (orderBy === 'asc') {
    return dateStart - dateEnd;
  }
  return dateEnd - dateStart;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const sortByChronoLogicalOrder = (array: any): any => {
  return array.sort((a, b) => {
    const dateStartA = a?.date
      ? ((new Date(a?.date) as unknown) as number)
      : ((new Date(a?.startDate) as unknown) as number);
    const dateStartB = b?.date
      ? ((new Date(b?.date) as unknown) as number)
      : ((new Date(b?.startDate) as unknown) as number);
    return dateStartB - dateStartA;
  });
};
