import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import { LocationSearch, TLocationSearchChangeResult } from 'src/components/LocationSearch';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import {
  selectAssetsAmountBelowCommunity,
  selectAssetsValues,
} from 'src/redux/configuration/configuration.selectors';

import { BaseGenericModal } from 'src/components/BaseGenericModal';
import { BaseIcon } from 'src/components/BaseIcon';
import { TLngLat } from 'src/typings/base-types';
import { TModalAddMoreProps } from './ModalAddMore.types';
import WorldMapContext from 'src/contexts/WorldMapContext';
import { getLocationDetails } from 'src/services/api/mapbox.api';
import { getLocationDetailsByPriority } from 'src/utils/worldMap/helpers';
import s from './ModalAddMore.module.scss';
import { selectIsLoggedIn } from 'src/redux/auth/auth.selectors';
import { updateSelectedLocation } from 'src/redux/map/map.slice';
import { useAppDispatch } from 'src/redux/store';
import { useConfigurationFlowState } from 'src/hooks/useConfigurationFlowState';

export const ModalAddMore: React.FC<TModalAddMoreProps> = ({ inline = false }) => {
  const dispatch = useAppDispatch();
  const modalRef = useRef<HTMLDivElement>(null);
  const { modalAddMoreShow } = useConfigurationFlowState();
  const [location, setLocation] = useState<TLocationSearchChangeResult>();
  const { mapService } = useContext(WorldMapContext);
  const assetsValues = useSelector(selectAssetsValues);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const assetsAmountBelowCommunity = useSelector(selectAssetsAmountBelowCommunity);

  const housesCountText = isLoggedIn
    ? `${assetsAmountBelowCommunity}/1000`
    : `${assetsAmountBelowCommunity}/50`;

  const generateRandomPoint = (center: TLngLat, radius: number): TLngLat => {
    const x0 = center.lng;
    const y0 = center.lat;
    // Convert Radius from meters to degrees.
    const rd = radius / 111300;

    const u = Math.random();
    const v = Math.random();

    const w = rd * Math.sqrt(u);
    const t = 2 * Math.PI * v;
    const x = w * Math.cos(t);
    const y = w * Math.sin(t);

    const xp = x / Math.cos(y0);

    return { lng: xp + x0, lat: y + y0 };
  };

  const allocateRandomLocation = async () => {
    const firstPoint = Object.values(assetsValues).find(
      (item) => item.exchangeInformation && item.geoTagLocation,
    );
    if (firstPoint?.geoTagLocation) {
      const center = { lng: firstPoint?.geoTagLocation[0], lat: firstPoint?.geoTagLocation[1] };
      const randomPoint = generateRandomPoint(center, 3000);
      const details = await getLocationDetails(randomPoint);
      if (details) {
        const locationDetails = getLocationDetailsByPriority(details.features);
        setLocation(locationDetails as TLocationSearchChangeResult);
        dispatch(
          updateSelectedLocation({
            lng: locationDetails?.geometry.coordinates[0],
            lat: locationDetails?.geometry.coordinates[1],
            place_name: locationDetails?.place_name,
            place_type: locationDetails?.place_type,
            text: locationDetails?.text,
            bbox: locationDetails?.bbox,
          }),
        );
      }
    }
  };

  const handleCloseClick = () => {
    dispatch(closeModal(EPredefinedModalIds.MODAL_ADD_MORE));
  };

  useEffect(() => {
    if (location) {
      mapService?.flyTo(location, { specificZoom: 17, speed: 1 });
    }
  }, [location, mapService]);

  useEffect(() => {
    if (modalAddMoreShow) {
      dispatch(openModal(EPredefinedModalIds.MODAL_ADD_MORE));
    } else {
      dispatch(closeModal(EPredefinedModalIds.MODAL_ADD_MORE));
    }
  }, [dispatch, modalAddMoreShow]);

  return (
    <BaseGenericModal
      inline={inline}
      size={EModalSize.M255}
      modalId={EPredefinedModalIds.MODAL_ADD_MORE}
      className={s.modal}
      modalRef={modalRef}>
      <button type="button" className={s.closeButton} onClick={handleCloseClick}>
        <BaseIcon icon="close" size={9} />
      </button>
      <h4>Want to add more homes?</h4>

      <p>{housesCountText}</p>
      <div className={s.info}>
        <BaseIcon icon="arrow-loading" size={28} />
        <h6>Click on the map to select the location of the second home.</h6>
      </div>
      <div className={s.separator}>
        <span className={s.separatorText}>or</span>
      </div>
      <LocationSearch
        label="Type the location here"
        dropdownPosition="bottom"
        className={s.searchInput}
        name="type-location"
        iconLeft="pin-empty"
        value={location?.place_name}
        onChange={setLocation}
      />
      <h6 className={s.question}>Don’t know the location?</h6>
      <div className={s.randomContainer} onClick={allocateRandomLocation}>
        <BaseIcon icon="refresh" size={15} />
        <h6>Allocate a random location nearby.</h6>
      </div>
    </BaseGenericModal>
  );
};
