import { TCommunityAssetsProps } from './MapAlertModal.types';
import React, { useEffect } from 'react';
import s from './MapAlertModal.module.scss';
import { useAppDispatch } from 'src/redux/store';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import { BaseGenericModal } from 'src/components/BaseGenericModal';
import { InViewPositioner } from 'src/components/InViewPositioner';
import classNames from 'classnames';
import { BaseClose } from 'src/components/BaseClose';
import { BaseButtonSquare } from 'src/components/BaseButtonSquare';
import ModalImage from 'src/assets/images/image-map-alert.gif';
import { BaseButton } from 'src/components/BaseButton';
import { useSelector } from 'react-redux';
import { selectSimulationStatus } from 'src/redux/configuration/configuration.selectors';
import { setModalMapAlertDismissed } from '../../redux/application/application.slice';
import { selectModalMapAlertDismissed } from 'src/redux/application/application.selectors';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { useLaunchToCanary } from 'src/hooks/useLaunchToCanary';

export const MapAlertModal: React.FC<TCommunityAssetsProps> = () => {
  const dispatch = useAppDispatch();
  const simulationStatus = useSelector(selectSimulationStatus);
  const modalMapAlertDismissed = useSelector(selectModalMapAlertDismissed);
  const isModalMapAlertOpened = useSelector(
    selectIsModalOpened(EPredefinedModalIds.MODAL_MAP_ALERT),
  );

  useEffect(() => {
    if (simulationStatus !== 'finished' && isModalMapAlertOpened) {
      dispatch(closeModal(EPredefinedModalIds.MODAL_MAP_ALERT));
    }
  }, [dispatch, simulationStatus, isModalMapAlertOpened]);

  useEffect(() => {
    let timerId;
    if (modalMapAlertDismissed) return;
    if (simulationStatus === 'finished') {
      timerId = setTimeout(
        () => dispatch(openModal(EPredefinedModalIds.MODAL_MAP_ALERT)),
        1000 * 60,
      );
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [dispatch, simulationStatus, modalMapAlertDismissed]);

  function closeCanaryModal() {
    dispatch(setModalMapAlertDismissed(true));
    dispatch(closeModal(EPredefinedModalIds.MODAL_MAP_ALERT));
  }

  const { launchToCanary } = useLaunchToCanary();

  if (!isModalMapAlertOpened) return null;

  return (
    <InViewPositioner recalculateKey="community-assets-modal">
      <BaseGenericModal
        className={s.modal}
        inline
        theme="dark"
        size={EModalSize.M255}
        modalId={EPredefinedModalIds.MODAL_MAP_ALERT}>
        <div className={classNames(s.container, 'gradient-dark')}>
          <header className={classNames(s.communityAssetsHeader, s.paddingLR)}>
            <BaseClose className={s.closeBtn} onClick={() => closeCanaryModal()} />
          </header>
          <div className={s.boxInfo}>
            <div className={s.iconCanary}>
              <BaseButtonSquare
                style={{ maxWidth: 32, maxHeight: 30 }}
                theme="flat-blue"
                icon="canary"
              />
            </div>
            <div className={s.info}>
              <h2>Bring your simulation closer to reality</h2>
              <p>Connect your assets, use real data and trade energy.</p>
            </div>
          </div>
          <img className={s.image} src={ModalImage} alt="" />

          <p
            className={s.askMeLater}
            onClick={() => dispatch(closeModal(EPredefinedModalIds.MODAL_MAP_ALERT))}>
            Ask me later
          </p>

          <BaseButton className={s.takeMeToTheFuture} onClick={() => launchToCanary()}>
            Take me to the future
          </BaseButton>
        </div>
      </BaseGenericModal>
    </InViewPositioner>
  );
};
