import React, { useState } from 'react';

import { WorldMapService } from 'src/services/map/map.service';

type TWorldMapContextValue = {
  mapService?: WorldMapService;
  setMapService: React.Dispatch<React.SetStateAction<WorldMapService | undefined>>;
};

const WorldMapContext = React.createContext<TWorldMapContextValue>({
  mapService: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setMapService: () => {},
});

export const WorldMapContextProvider: React.FC = ({ children }) => {
  const [mapService, setMapService] = useState<WorldMapService>();

  return (
    <WorldMapContext.Provider value={{ mapService, setMapService }}>
      {children}
    </WorldMapContext.Provider>
  );
};

export default WorldMapContext;
