import './TemplateFix.css';

import { EAppMode, getAppMode } from 'src/utils/appMode';
import React, { useEffect, useState } from 'react';

import { BrowserRouter } from 'react-router-dom';
import { DesktopView } from 'src/components/DesktopView';
import { MobileView } from 'src/components/MobileView';
import { ReactComponent as SpriteSvg } from 'src/assets/svg/sprite.svg';
import { TTemplateFrameProps } from './TemplateFrame.types';
import s from './TemplateFrame.module.scss';

export const TemplateFrame: React.FC<TTemplateFrameProps> = () => {
  const [appMode, setAppMode] = useState<EAppMode>(getAppMode);

  useEffect(() => {
    function setConfigureAppMode() {
      const mode = getAppMode();

      if (appMode !== mode) {
        setAppMode(mode);
      }
    }

    setConfigureAppMode();

    window.addEventListener('resize', setConfigureAppMode);
    return () => {
      window.removeEventListener('resize', setConfigureAppMode);
    };
  }, [appMode]);

  return (
    <div className={s.container}>
      {/* Sprite */}
      <SpriteSvg />

      {/* Router */}
      <BrowserRouter>
        {appMode === EAppMode.Desktop ? <DesktopView /> : <MobileView />}
      </BrowserRouter>
    </div>
  );
};
