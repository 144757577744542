import React, { useEffect } from 'react';

import { TemplateFrame } from 'src/templates/TemplateFrame';
import { selectIsLoggedIn } from 'src/redux/auth/auth.selectors';
import { useSelector } from 'react-redux';

const LOGGED_IN_CLASS = 'logged-in';

const App: React.FC = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);

  useEffect(function disableScrollingNumberInputs() {
    document.addEventListener('wheel', () => {
      const activeElement = document?.activeElement as HTMLInputElement | null;
      if (activeElement?.type === 'number') {
        activeElement.blur();
      }
    });
  }, []);

  useEffect(() => {
    if (isLoggedIn && !document.body.classList.contains(LOGGED_IN_CLASS)) {
      document.body.classList.add(LOGGED_IN_CLASS);
    } else if (!isLoggedIn && document.body.classList.contains(LOGGED_IN_CLASS)) {
      document.body.classList.remove(LOGGED_IN_CLASS);
    }
  }, [isLoggedIn]);

  return (
    <>
      <TemplateFrame />
    </>
  );
};

export default App;
