import { BaseLink } from 'src/components/BaseLink';
import { FormResetPassword } from 'src/components/FormResetPassword';
import React from 'react';
import { TScmAuthRegisterFormsProps } from './ScmAuthRegisterForms.types';
import classNames from 'classnames';
import s from './ScmAuthRegisterForms.module.scss';

export const ScmAuthRegisterForms: React.FC<TScmAuthRegisterFormsProps> = ({
  onSuccess,
  match,
}) => {
  return (
    <>
      <div>
        <header className={classNames(s.container)}>
          <BaseLink className={s.formSwitch} isActive={true} theme="2">
            Reset Password
          </BaseLink>
        </header>

        <FormResetPassword
          resetId={match.params.resetId}
          resetKey={match.params.resetKey}
          handleClose={onSuccess}
        />
      </div>
    </>
  );
};
