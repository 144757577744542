import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  TAsset,
  TAssetArea,
  TAssetsTreeRelations,
  TConfigurationCharacteristic,
  TTreeBranch,
} from 'src/typings/configuration.types';
import { TAssetType, TSimResultsStatus } from 'src/typings/base-types';

import { ConfigType } from 'src/graphql';
import { DEFAULT_COMMUNITY_NAME } from 'src/constants/application';
import { TMapboxLocation } from 'src/services/api/mapbox.api';
import { TSettingsData } from 'src/utils/assetsFields/assetsFields.types';
import { TSimulationResultData } from 'src/hooks/useConfigurationEffects';
import { TValuesByFieldName } from 'src/utils/assetsFields/valuesByFieldName.types';
import { destructureConfigTree } from 'src/utils/configuration/destructureConfigTree';
import { getAppendDataForSimulation } from 'src/redux/configuration/configuration.helpers';

export type TAssetsValues = Record<TAsset['uuid'], TValuesByFieldName>;
export type TAssets = Record<TAsset['uuid'], TAsset>;

export type TConfigurationState = {
  /**
   * Flat assets list
   */
  assets: TAssets;
  /**
   * Assets may be collabsed in tree view. Opened assets are here
   */
  assetsOpenedUuids: Array<TAsset['uuid']>;
  /**
   * Assets have children, children have another children. Here we have tree structure of assets configuration.
   */
  assetsTreeRelations: TAssetsTreeRelations;
  /**
   * Assets details
   */
  assetsValues: TAssetsValues;
  configType: ConfigType | undefined;
  readOnly: boolean;
  /**
   * The One - Main asset of the tree. At the top of the tree
   */
  rootAssetUuid: TAsset['uuid'] | undefined;
  /**
   *  Selected asset by user. Does not impact results
   */
  selectedAssetUuid: TAsset['uuid'] | undefined;
  settingsData: TSettingsData | undefined;
  /**
   * Root asset location
   */
  simulationLocation: TMapboxLocation | undefined;
  /**
   *  Currently viewed configuration
   */
  activeConfigurationUuid: string | undefined;
  /**
   *  The ConfigurationUuid that opened from Sidebar
   */
  openedConfigurationUuid: string | undefined;
  /**
   * isCNLaunching
   */
  isCNLaunching: boolean;
  /**
   * isCollaborationLaunching
   */
  isCollaborationLaunching: boolean;
  /**
   * Individual simulation UUID. Every simulation has different UUID
   */
  activeConfigurationJobUuid: string | undefined;

  isLibrary: boolean;
  /**
   * Simulation results data
   */
  simulationResults: TSimulationResultData | undefined;
  /**
   * It includes the raw results, without any time limit full simulation results data
   */
  rawCommunitySimulationResults: TSimulationResultData | undefined;
  /**
   * Simulation results data
   */
  communitySimulationResults: TSimulationResultData | undefined;
  /**
   * Backend state of simulation
   */
  simulationStatus: TSimResultsStatus | undefined;
  /**
   * Backend progress of simulation
   */
  simulationProgress:
    | {
        elapsedTimeSeconds: number;
        etaSeconds: number;
        percentageCompleted: number;
      }
    | undefined;
  /**
   * Frontend state of simulation
   */
  frontendSimulationStatus: TSimResultsStatus | undefined;
  /**
   *
   */
  resultsStartTime: string | undefined;
  resultsEndTime: string | undefined;
  resultPeriod: 'WEEK';
  // Asset Loading State
  assetLoadingInfo: Record<string, boolean>;
  hasUserAllowedResultsLoss: boolean;
  isModalSummaryWithList: boolean;
  lastAddedAssetUuid: string | undefined;
  createdSCMMemberUUID: string | null;
} & Pick<
  TConfigurationCharacteristic,
  'name' | 'description' | 'projectUuid' | 'locationVisible' | 'timezone' | 'user' | 'timestamp'
>;

type TValuesForUuid = { uuid: TAsset['uuid']; values: TValuesByFieldName };

export const initialState: TConfigurationState = {
  name: DEFAULT_COMMUNITY_NAME,
  description: '',
  projectUuid: '',
  timezone: '',
  user: '',
  assets: {},
  assetsOpenedUuids: [],
  assetsTreeRelations: {},
  assetsValues: {},
  configType: undefined,
  readOnly: false,
  rootAssetUuid: undefined,
  selectedAssetUuid: undefined,
  settingsData: undefined,
  simulationLocation: undefined,
  activeConfigurationUuid: undefined,
  openedConfigurationUuid: undefined,
  isCNLaunching: false,
  isCollaborationLaunching: false,
  activeConfigurationJobUuid: undefined,
  isLibrary: false,
  simulationResults: undefined,
  rawCommunitySimulationResults: undefined,
  communitySimulationResults: undefined,
  simulationStatus: undefined,
  simulationProgress: undefined,
  frontendSimulationStatus: undefined,
  locationVisible: true,
  resultsStartTime: undefined,
  resultsEndTime: undefined,
  resultPeriod: 'WEEK',
  assetLoadingInfo: {},
  hasUserAllowedResultsLoss: false,
  isModalSummaryWithList: false,
  lastAddedAssetUuid: undefined,
  createdSCMMemberUUID: null,
  timestamp: null,
};

const configurationSlice = createSlice({
  name: 'configuration',
  initialState: initialState,
  reducers: {
    configurationReset: () => initialState,

    setHasUserAllowedResultsLoss(
      state,
      action: PayloadAction<TConfigurationState['hasUserAllowedResultsLoss']>,
    ) {
      state.hasUserAllowedResultsLoss = action.payload;
    },
    setName(state, action: PayloadAction<TConfigurationState['name']>) {
      state.name = action.payload;
    },
    setCommunityUser(state, action: PayloadAction<TConfigurationState['user']>) {
      state.user = action.payload;
    },
    setDescription(state, action: PayloadAction<TConfigurationState['description']>) {
      state.description = action.payload;
    },
    setLocationVisible(state, action: PayloadAction<TConfigurationState['locationVisible']>) {
      state.locationVisible = action.payload;
    },
    setProjectUuid(state, action: PayloadAction<TConfigurationState['projectUuid']>) {
      state.projectUuid = action.payload;
    },
    setTimezone(state, action: PayloadAction<TConfigurationState['timezone']>) {
      state.timezone = action.payload;
    },
    setTimestamp(state, action: PayloadAction<TConfigurationState['timestamp']>) {
      state.timestamp = action.payload;
    },
    // Assets
    addAssets(state, action: PayloadAction<TAsset | TAsset[]>) {
      try {
        const assets = Array.isArray(action.payload) ? action.payload : [action.payload];
        for (let i = 0; i < assets.length; i++) {
          const asset = assets[i];
          if (!!state.assets[asset.uuid]) {
            throw new Error(`Asset with uuid ${asset.uuid} already exists`);
          }
          if (!asset.parentUuid) {
            throw new Error(
              `parentUuid does not exist in asset ${asset.uuid}. For rootAsset use setRootAsset`,
            );
          }
          if (!state.assetsTreeRelations[asset.parentUuid]) {
            throw new Error(`Parent asset ${asset.parentUuid} not found for asset ${asset.uuid}`);
          }
          state.assets[asset.uuid] = asset;
          state.assetsTreeRelations[asset.uuid] = [];
          state.assetsTreeRelations[asset.parentUuid]?.push(asset.uuid);
          state.assetsOpenedUuids.push(asset.uuid);
        }
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          // throw new Error(err.message);
        } else {
          // TODO: Display error modal or smth?
        }
      }
    },
    setRootAsset(state, action: PayloadAction<TAssetArea>) {
      try {
        if (!!state.assets[action.payload.uuid]) {
          throw new Error(`Asset with uuid ${action.payload.uuid} already exists`);
        }

        const children: string[] = [];
        if (state.rootAssetUuid) {
          const rootAsset = state.assets[state.rootAssetUuid];

          if (rootAsset) {
            rootAsset.parentUuid = action.payload.uuid;
            children.push(state.rootAssetUuid);
          }
        }

        state.rootAssetUuid = action.payload.uuid;
        state.assetsTreeRelations[action.payload.uuid] = children;
        state.assets[action.payload.uuid] = action.payload;
        state.assetsOpenedUuids.push(action.payload.uuid);
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          // throw new Error(err.message);
        } else {
          // TODO: Display error modal or smth?
        }
      }
    },
    removeAssetByUuidWithDelete(state, action: PayloadAction<TAsset['uuid']>) {
      if (!state.assets[action.payload]) {
        return;
      }
      const parentUuid = state.assets[action.payload].parentUuid;
      if (parentUuid) {
        const indexForDelete = state.assetsTreeRelations[parentUuid].findIndex(
          (uuid) => uuid === action.payload,
        );
        if (indexForDelete > -1) delete state.assetsTreeRelations[parentUuid][indexForDelete];
      }
    },
    // Remove assset and his child recurively
    removeAssetByUuid(state, action: PayloadAction<TAsset['uuid']>) {
      function removeAssetWithChildren(assetUuid: TAsset['uuid']) {
        if (state.assetsTreeRelations[assetUuid]) {
          state.assetsTreeRelations[assetUuid]?.forEach((id) => removeAssetWithChildren(id));
        }
        delete state.assets[assetUuid];
        delete state.assetsTreeRelations[assetUuid];
        delete state.assetsValues[assetUuid];
        state.assetsOpenedUuids = state.assetsOpenedUuids.filter((uuid) => uuid !== assetUuid);
        if (state.selectedAssetUuid === assetUuid) {
          state.selectedAssetUuid = undefined;
        }
      }

      try {
        if (!state.assets[action.payload]) {
          throw new Error(`Cannot find asset ${action.payload} to remove`);
        }

        if (state.rootAssetUuid === action.payload) {
          state.rootAssetUuid = undefined;
        }

        // Remove uuid from parent children array
        const parentUuid = state.assets[action.payload]?.parentUuid;
        if (parentUuid) {
          state.assetsTreeRelations[parentUuid] = state.assetsTreeRelations[parentUuid]?.filter(
            (uuid) => uuid !== action.payload,
          );
        }
        removeAssetWithChildren(action.payload);
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          // throw new Error(err.message);
        } else {
          // TODO: Display error modal or smth?
        }
      }
    },
    // AssetsOpenedUuids
    setAssetOpenedId(state, action: PayloadAction<TAsset['uuid']>) {
      state.assetsOpenedUuids.push(action.payload);
    },
    removeAssetOpenedId(state, action: PayloadAction<TAsset['uuid']>) {
      state.assetsOpenedUuids = state.assetsOpenedUuids.filter((uuid) => uuid !== action.payload);
    },
    // assetsTreeRelations
    updateAssetsTree(state, action: PayloadAction<TConfigurationState['assetsTreeRelations']>) {
      state.assetsTreeRelations = action.payload;
    },
    // assetsValues
    updateAssetValuesForUuid(state, action: PayloadAction<TValuesForUuid | TValuesForUuid[]>) {
      const assetsValues = Array.isArray(action.payload) ? action.payload : [action.payload];

      assetsValues.forEach((item) => {
        state.assetsValues[item.uuid] = item.values;
      });
    },
    // assetType
    updateAssetTypeForUuid(
      state,
      action: PayloadAction<{ uuid: TAsset['uuid']; assetType: TAssetType }>,
    ) {
      const { uuid, assetType } = action.payload;
      const getAsset = state.assets[uuid];
      if (getAsset) getAsset.type = assetType;
    },
    updateAssetsValues(state, action: PayloadAction<TConfigurationState['assetsValues']>) {
      state.assetsValues = action.payload;
    },
    // configType
    updateConfigType(state, action: PayloadAction<TConfigurationState['configType']>) {
      state.configType = action.payload;
    },
    // readOnly
    updateReadOnly(state, action: PayloadAction<TConfigurationState['readOnly']>) {
      state.readOnly = action.payload;
    },
    // rootAssetUuid
    updateRootAssetUuid(state, action: PayloadAction<TConfigurationState['rootAssetUuid']>) {
      state.rootAssetUuid = action.payload;
    },
    // selectedAssetUuid
    setSelectedAssetUuid(state, action: PayloadAction<TConfigurationState['selectedAssetUuid']>) {
      state.selectedAssetUuid = action.payload;
    },
    setSelectedAssetUuidIfNull(
      state,
      action: PayloadAction<TConfigurationState['selectedAssetUuid']>,
    ) {
      if (!state.selectedAssetUuid) state.selectedAssetUuid = action.payload;
    },
    // settingsData
    setSettingsData(state, action: PayloadAction<TConfigurationState['settingsData']>) {
      state.settingsData = action.payload;
    },
    // simulationLocation
    setSimulationLocation(state, action: PayloadAction<TConfigurationState['simulationLocation']>) {
      state.simulationLocation = action.payload;
    },
    // setActiveConfigurationUuid
    setActiveConfigurationUuid(
      state,
      action: PayloadAction<TConfigurationState['activeConfigurationUuid']>,
    ) {
      state.activeConfigurationUuid = action.payload;
    },
    // setOpenedConfigurationUuid
    setOpenedConfigurationUuid(
      state,
      action: PayloadAction<TConfigurationState['openedConfigurationUuid']>,
    ) {
      state.openedConfigurationUuid = action.payload;
    },
    // isCNLaunching
    setIsCNLaunching(state, action: PayloadAction<TConfigurationState['isCNLaunching']>) {
      state.isCNLaunching = action.payload;
    },
    // isCollaborationLaunching
    setIsCollaborationLaunching(
      state,
      action: PayloadAction<TConfigurationState['isCollaborationLaunching']>,
    ) {
      state.isCollaborationLaunching = action.payload;
    },
    // activeConfigurationJobUuid
    setActiveConfigurationJobUuid(
      state,
      action: PayloadAction<TConfigurationState['activeConfigurationJobUuid']>,
    ) {
      state.activeConfigurationJobUuid = action.payload;
    },
    // The "setConfiguration" action should be used only in special cases.
    // In order to manipulate the tree use actions like: "addAssets", "setRootAsset".
    setConfiguration(
      state,
      action: PayloadAction<
        ReturnType<typeof destructureConfigTree> &
          Pick<
            TConfigurationState,
            | 'settingsData'
            | 'name'
            | 'user'
            | 'description'
            | 'timezone'
            | 'projectUuid'
            | 'selectedAssetUuid'
            | 'locationVisible'
            | 'timestamp'
          >
      >,
    ) {
      state.name = action.payload.name;
      state.user = action.payload.user;
      state.description = action.payload.description;
      state.timezone = action.payload.timezone;
      state.projectUuid = action.payload.projectUuid;
      state.assets = action.payload.assets;
      state.assetsTreeRelations = action.payload.assetsTreeRelations;
      state.assetsValues = action.payload.assetsValues;
      state.rootAssetUuid = action.payload.rootAssetUuid;
      state.settingsData = action.payload.settingsData;
      state.selectedAssetUuid = action.payload.selectedAssetUuid;
      state.locationVisible = action.payload.locationVisible;
      state.timestamp = action.payload.timestamp;
    },
    // setDestructedConfigTree
    setDestructedConfigTree(
      state,
      action: PayloadAction<ReturnType<typeof destructureConfigTree>>,
    ) {
      state.assets = action.payload.assets;
      state.assetsTreeRelations = action.payload.assetsTreeRelations;
      state.assetsValues = action.payload.assetsValues;
    },

    // resetSimulationResults
    resetSimulationResults(state) {
      state.simulationResults = undefined;
      state.activeConfigurationJobUuid = undefined;
      state.simulationProgress = undefined;
      state.simulationStatus = undefined;
      state.frontendSimulationStatus = undefined;
    },
    // setSimulationResults
    setSimulationResults(
      state,
      action: PayloadAction<NonNullable<TConfigurationState['simulationResults']>>,
    ) {
      state.simulationResults = action.payload;
    },
    setRawCommunitySimulationResults(
      state,
      action: PayloadAction<NonNullable<TConfigurationState['rawCommunitySimulationResults']>>,
    ) {
      state.rawCommunitySimulationResults = action.payload;
    },
    // setAssetLoading
    setAssetLoadingInfo(
      state,
      action: PayloadAction<{ uuid: TAsset['uuid']; isLoading: boolean }>,
    ) {
      state.assetLoadingInfo[action.payload.uuid] = action.payload.isLoading;
    },
    // setCommunitySimulationResults
    setCommunitySimulationResults(
      state,
      action: PayloadAction<NonNullable<TConfigurationState['communitySimulationResults']>>,
    ) {
      state.communitySimulationResults = action.payload;
    },
    // appendSimulationResults
    appendSimulationResults(
      state,
      action: PayloadAction<
        NonNullable<
          | TConfigurationState['simulationResults']
          | TConfigurationState['communitySimulationResults']
        > & { isCommunitySimulationResult?: boolean }
      >,
    ) {
      const { data, stateKey } = getAppendDataForSimulation(state, action);
      state[stateKey] = data;
      if (action.payload.isCommunitySimulationResult) {
        state.rawCommunitySimulationResults = data;
      }
    },
    // setSimulationStatus
    setSimulationStatus(state, action: PayloadAction<TConfigurationState['simulationStatus']>) {
      state.simulationStatus = action.payload;
    },
    // setSimulationProgress
    setSimulationProgress(state, action: PayloadAction<TConfigurationState['simulationProgress']>) {
      state.simulationProgress = action.payload;
    },
    setFrontendSimulationStatus(
      state,
      action: PayloadAction<TConfigurationState['frontendSimulationStatus']>,
    ) {
      state.frontendSimulationStatus = action.payload;
    },
    appendBranch(state, action: PayloadAction<TTreeBranch>) {
      const { assets, assetsTreeRelations, rootAssetUuid, assetsValues } = action.payload;
      state.assets = { ...state.assets, ...assets };
      state.assetsTreeRelations = {
        ...state.assetsTreeRelations,
        ...assetsTreeRelations,
      };
      state.assetsTreeRelations[assets[rootAssetUuid].parentUuid!].push(rootAssetUuid);
      state.assetsValues = { ...state.assetsValues, ...assetsValues };
    },
    setResultsStartTime(state, action: PayloadAction<TConfigurationState['resultsStartTime']>) {
      state.resultsStartTime = action.payload;
    },
    setResultsEndTime(state, action: PayloadAction<TConfigurationState['resultsEndTime']>) {
      state.resultsEndTime = action.payload;
    },
    setIsModalSummaryWithList(
      state,
      action: PayloadAction<TConfigurationState['isModalSummaryWithList']>,
    ) {
      state.isModalSummaryWithList = action.payload;
    },
    setLastAddedAssetUuid(state, action: PayloadAction<TConfigurationState['lastAddedAssetUuid']>) {
      state.lastAddedAssetUuid = action.payload;
    },
    setCreatedSCMMemberUUID(
      state,
      action: PayloadAction<TConfigurationState['createdSCMMemberUUID']>,
    ) {
      state.createdSCMMemberUUID = action.payload;
    },
  },
});

// Actions
export const {
  setName,
  setCommunityUser,
  setDescription,
  setLocationVisible,
  setProjectUuid,
  setTimezone,
  setTimestamp,
  addAssets,
  configurationReset,
  removeAssetByUuid,
  removeAssetByUuidWithDelete,
  removeAssetOpenedId,
  setAssetOpenedId,
  setRootAsset,
  setSelectedAssetUuid,
  setSettingsData,
  setSimulationLocation,
  updateAssetsValues,
  updateAssetsTree,
  updateConfigType,
  updateReadOnly,
  updateRootAssetUuid,
  updateAssetValuesForUuid,
  updateAssetTypeForUuid,
  setActiveConfigurationJobUuid,
  setActiveConfigurationUuid,
  setOpenedConfigurationUuid,
  setIsCNLaunching,
  setIsCollaborationLaunching,
  setConfiguration,
  setDestructedConfigTree,
  resetSimulationResults,
  setSimulationResults,
  setRawCommunitySimulationResults,
  setCommunitySimulationResults,
  appendSimulationResults,
  setSimulationStatus,
  setSimulationProgress,
  setFrontendSimulationStatus,
  appendBranch,
  setResultsStartTime,
  setResultsEndTime,
  setAssetLoadingInfo,
  setHasUserAllowedResultsLoss,
  setIsModalSummaryWithList,
  setLastAddedAssetUuid,
  setCreatedSCMMemberUUID,
  setSelectedAssetUuidIfNull,
} = configurationSlice.actions;

// Reducer
export const configurationReducer = configurationSlice.reducer;
