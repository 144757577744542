import { BaseIcon } from 'src/components/BaseIcon';
import { EPredefinedModalIds } from 'src/constants/modals';
import React from 'react';
import { TMobileHeaderProps } from './MobileHeader.types';
import { UserAvatar } from 'src/components/UserAvatar';
import classnames from 'classnames';
import { openModal } from 'src/redux/modals/modals.slice';
import s from './MobileHeader.module.scss';
import { selectIsLoggedIn } from 'src/redux/auth/auth.selectors';
import { useAppDispatch } from 'src/redux/store';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

export const MobileHeader: React.FC<TMobileHeaderProps> = ({ headerTitle }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const handleLogoClicked = () => {
    history.push('');
  };

  return (
    <div className={s.container}>
      <BaseIcon icon="gs-logo" size={50} onClick={handleLogoClicked} />
      {headerTitle && <h2 className={s.pageTitle}>{headerTitle}</h2>}
      {isLoggedIn ? (
        <UserAvatar />
      ) : (
        <nav className={classnames(s.nav)}>
          <button
            type="button"
            onClick={() => dispatch(openModal(EPredefinedModalIds.MODAL_AUTH_LOGIN))}>
            Login
          </button>
          <button
            type="button"
            onClick={() => dispatch(openModal(EPredefinedModalIds.MODAL_AUTH_REGISTER))}>
            Register
          </button>
        </nav>
      )}
    </div>
  );
};
