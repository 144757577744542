import ContentfulContext, { ITrendingAndNewsEntry } from 'src/contexts/ContentfulContext';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ResourcesFilter, orderByDate, orderByText } from '../../components/ResourcesFilter';

import { TFilterDropdownOption } from 'src/components/ResourcesFilter/ResourcesFilter.types';
import { UTCMoment } from 'src/utils/UTCMoment';
import s from './News.module.scss';

const filters = [
  {
    id: 1,
    name: 'News',
  },
  {
    id: 2,
    name: 'Publications',
  },
  {
    id: 3,
    name: 'Awards',
  },
];

const paramsOrderBy = {
  ['Newest']: { orderBy: 'desc', field: 'createdAt' },
  ['A-Z']: { orderBy: 'asc', field: 'title' },
  ['Z-A']: { orderBy: 'desc', field: 'title' },
  ['default']: { orderBy: 'desc', field: 'date' },
};

export const News: React.FC = () => {
  const { newsEntry } = useContext(ContentfulContext);
  const [filter, setFilter] = useState<number[]>([]);
  const [sort, setSort] = useState<TFilterDropdownOption>({ label: 'default', value: 'default' });
  const [data, setData] = useState<ITrendingAndNewsEntry[]>([]);
  const filterMemo = useMemo(() => filter, [filter]);
  const params = useMemo(() => paramsOrderBy?.[sort?.label] || null, [sort]);

  function redirect(link?: string) {
    if (link) {
      window.open(link, '_blank');
    }
  }

  function sortBy(a, b, params) {
    switch (params?.field) {
      case 'title':
        return orderByText(a.title, b.title, params?.orderBy);
      case 'date':
        return orderByDate(a?.date, b?.date, params?.orderBy);
      case 'createdAt':
        return orderByDate(a.createdAt, b.createdAt, params?.orderBy);
      default:
        return 0;
    }
  }

  const applyFilter = useCallback(
    (value: number[]) => {
      let filtersData: Array<ITrendingAndNewsEntry> = [];

      value.forEach((e) => {
        const entries = newsEntry.filter(
          (entry) =>
            entry?.category?.toLowerCase() === filters.find((f) => f.id === e)?.name?.toLowerCase(),
        );

        filtersData = [...filtersData, ...entries];
      });
      setData(filtersData);
    },
    [setData, newsEntry],
  );

  // Initial filter
  useEffect(() => {
    setFilter([1]);
  }, [setFilter]);

  useEffect(() => {
    applyFilter(filterMemo);
  }, [filterMemo, applyFilter]);

  function formatDate(date: string) {
    return UTCMoment.utc(date).format('DD MMMM yyyy');
  }

  function truncateString(str, num) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  }

  function renderView(news: ITrendingAndNewsEntry) {
    return (
      <div className={s.boxHalf}>
        <div className={`${s.boxTextHalf} ${s.preview}`}>
          <h2>{truncateString(news.title, 40)}</h2>
          <span className={s.text_large}>{news?.date && formatDate(news?.date)}</span>
        </div>
        <div className={s.buttonPosition}>
          <button type="button" className={s.buttonApply} onClick={() => redirect(news?.link)}>
            <span className={s.text_uppercaseButton}>See More</span>
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={s.container}>
      <ResourcesFilter
        setFilter={setFilter}
        filters={filters}
        initialFilter={filter}
        setSort={setSort}
      />

      <div className={s.wrapNews}>
        {data
          ?.sort((a, b) => sortBy(a, b, params))
          ?.map((e) => {
            return renderView(e);
          })}
      </div>
    </div>
  );
};
