import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import { selectIsModalOpened, selectPositionBGMById } from 'src/redux/modals/modals.selectors';

import ReactDOM from 'react-dom';
import { TBaseGenericModalProps } from './BaseGenericModal.types';
import classnames from 'classnames';
import s from './BaseGenericModal.module.scss';
import { useAppDispatch } from 'src/redux/store';
import useKey from '@rooks/use-key';
import useOutsideClick from 'src/hooks/useOutsideClick';
import { useSelector } from 'react-redux';

export const BaseGenericModal: React.FC<TBaseGenericModalProps> = (props) => {
  const portalElement = document.getElementById('app-root');
  const anchorMargin = props.anchorArrow ? 8 : 0;
  const anchorSize = props.anchorArrow ? 6 : 0;
  const isOpened = useSelector(selectIsModalOpened(props.modalId));
  const positionBGM = useSelector(selectPositionBGMById(props.modalId));
  const dispatch = useAppDispatch();
  const outsideClickElRef = useRef<HTMLDivElement | null>(null);
  const rootRef = useRef<HTMLDivElement | null>(null);
  const [calcStyle, setCalcStyle] = useState<React.CSSProperties>({});
  // const screenMode = useSelector(selectScreenMode);

  if (!portalElement) {
    throw new Error('Cannot find #app-root element for React.portal');
  }

  if ((props.position && !props.relativeElement) || (props.relativeElement && !props.position)) {
    throw new Error(
      'If you need a calculated modal position, "relativeElement" and "position" needs to be passed as a prop!',
    );
  }

  useLayoutEffect(
    function calculateModalPosition() {
      function calcForPortal() {
        const rElement = props.relativeElement?.current;
        const mElement = rootRef.current;

        let px = 0;
        let py = 0;

        if (!rElement || !mElement) return;
        const targetRect = rElement.getBoundingClientRect();
        const modalRect = mElement.getBoundingClientRect();

        switch (props.position?.side) {
          case 'top':
            py = window.scrollY + targetRect.top - modalRect.height - anchorMargin;
            px =
              window.scrollX +
              targetRect.left +
              targetRect.width / 2 -
              (modalRect.width / 100) * props.position.anchorPosition;
            break;
          case 'right':
            px = window.scrollX + targetRect.left + targetRect.width + anchorMargin;
            py =
              window.scrollY +
              targetRect.top +
              targetRect.height / 2 -
              (modalRect.height / 100) * props.position.anchorPosition;
            break;
          case 'bottom':
            py = window.scrollY + targetRect.top + targetRect.height + anchorMargin;
            px =
              window.scrollX +
              targetRect.left +
              targetRect.width / 2 -
              (modalRect.width / 100) * props.position.anchorPosition;
            break;
          case 'left':
            px = window.scrollX + targetRect.left - modalRect.width - anchorMargin;
            py =
              window.scrollY +
              targetRect.top +
              targetRect.height / 2 -
              (modalRect.height / 100) * props.position.anchorPosition;
            break;
        }

        setCalcStyle({ transform: `translate3d(${px}px, ${py}px, 0px)` });
      }

      function calcForInline() {
        let px = '0px';
        let py = '0px';
        let top = 0;
        let left = 0;

        switch (props.position?.side) {
          case 'top':
            left = props.position.anchorPosition || 50;
            px = `-${left}%`;
            py = `calc(-100% - ${anchorMargin}px)`;
            break;
          case 'right':
            top = props.position.anchorPosition || 50;
            left = 100;
            px = `${anchorMargin}px`;
            py = `-${top}%`;
            break;
          case 'bottom':
            left = props.position.anchorPosition || 50;
            top = 100;
            px = `-${left}%`;
            py = `${anchorMargin}px`;
            break;
          case 'left':
            top = props.position.anchorPosition || 50;
            px = `calc(-100% - ${anchorMargin}px)`;
            py = `-${top}%`;
            break;
        }

        setCalcStyle({
          top: top + '%',
          left: left + '%',
          transform: `translate3d(${px}, ${py}, 0px)`,
        });
      }

      if (!props.position?.side) return;
      if (!props.inline) {
        calcForPortal();
      } else {
        calcForInline();
      }
    },
    [
      anchorMargin,
      props.position?.anchorPosition,
      props.position?.side,
      props.relativeElement,
      props.inline,
      props.size,
      // We need isOpened in dependencies because if modal is not opened we render "null" so rootRef.current is null
      isOpened,
    ],
  );

  const once = useRef(false);
  useEffect(() => {
    if (once.current) return;

    if (props.initialOpen && props.modalId && !isOpened) {
      dispatch(openModal(props.modalId));
    }

    once.current = true;
  }, [dispatch, props.initialOpen, props.modalId, isOpened]);

  useOutsideClick(outsideClickElRef, (event) => {
    if (event.target !== props.relativeElement?.current && isOpened) {
      dispatch(closeModal(props.modalId));
    }
  });

  useKey(['Escape'], () => {
    if (props.singleActionModal && isOpened) {
      dispatch(closeModal(props.modalId));
    }
  });

  const getContainerWidth = (props) => {
    if (props.size === 'auto') {
      return 'auto';
    } else if (props.size === 'perc') {
      return '100%';
    } else {
      return props.size + 'px';
    }
  };

  const statePosition = useMemo(() => {
    if (positionBGM && positionBGM.modalId === props.modalId) {
      const tempPosition = {};
      ['top', 'left', 'bottom', 'right'].forEach((element) => {
        if (positionBGM[element]) tempPosition[`${element}`] = positionBGM[element] + 'px';
      });
      return tempPosition;
    }
    return {};
  }, [positionBGM, props.modalId]);

  const style = {
    ...props.style,
    ...calcStyle,
    ...statePosition,
    ['--container-width' as string]: getContainerWidth(props),
    ['--anchor-width' as string]: anchorSize + 'px',
    ['--anchor-position' as string]: (props.position?.anchorPosition || 0) + '%',
  };

  const modal =
    !isOpened && !props.closedClassName ? null : (
      <div
        ref={(el) => {
          rootRef.current = el;

          if (props.singleActionModal) {
            outsideClickElRef.current = el;
          }
          if (props.modalRef) {
            props.modalRef.current = el;
          }
        }}
        className={classnames(s.container, props.className, {
          [props.closedClassName || '']: !isOpened,
          [s['modal-anchor']]: !!props.anchorArrow,
          [s['modal--top']]: props.position?.side === 'top',
          [s['modal--right']]: props.position?.side === 'right',
          [s['modal--bottom']]: props.position?.side === 'bottom',
          [s['modal--left']]: props.position?.side === 'left',
          [s['modal-theme--dark']]: !props.theme || props.theme === 'dark',
          [s['modal-theme--light']]: props.theme === 'light',
        })}
        style={style}
        id={props.id}>
        {props.children}
      </div>
    );

  if (props.inline) return modal;

  return ReactDOM.createPortal(modal, portalElement);
};
