import { BaseIcon } from 'src/components/BaseIcon';
import React from 'react';
import { TBaseCloseProps } from './BaseClose.types';
import classnames from 'classnames';
import s from './BaseClose.module.scss';

export const BaseClose: React.FC<TBaseCloseProps> = ({ className, disabled, onClick, id = '' }) => {
  return (
    <button
      id={id}
      type="button"
      className={classnames(s.button, className)}
      onClick={onClick}
      disabled={disabled}>
      <BaseIcon icon="plus" className={s.icon} size={10} />
    </button>
  );
};
