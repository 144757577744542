import { BACKEND_DATE_FORMATS, UTCMoment } from 'src/utils/UTCMoment';

export function getUnixFromDate(
  date: string,
  dateFormat: BACKEND_DATE_FORMATS = BACKEND_DATE_FORMATS.ENERGY_TRADE_PROFILE,
): number {
  const output = UTCMoment.fromBackend(date, dateFormat).valueOf();

  if (Number.isNaN(output)) {
    console.error('Error! Timestamp is NaN');
  }

  return output;
}
