import {
  ConfigType,
  useMoveCanaryNetworkToCollaborationMutation,
  useMoveConfigurationToCollaborationMutation,
} from 'src/graphql';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import {
  setActiveConfigurationUuid,
  setIsCollaborationLaunching,
} from 'src/redux/configuration/configuration.slice';

import { EPredefinedModalIds } from 'src/constants/modals';
import { openToast } from 'src/redux/toast/toast.slice';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import { useAppDispatch } from 'src/redux/store';
import { useCallback } from 'react';
import { useConfigurationUtils } from 'src/hooks/useConfigurationUtils';
import { useGetConfigurationToUse } from 'src/hooks/useGetConfigurationToUse';
import { useSelector } from 'react-redux';

type TLaunchCollaborationArgs = {
  openedConfigurationUuid?: string;
};

export type TUseLaunchToCollaborationResponse = {
  launchToCollaboration: (payload?: TLaunchCollaborationArgs) => void;
};

export function useLaunchToCollaboration(): TUseLaunchToCollaborationResponse {
  const dispatch = useAppDispatch();
  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
  const {
    getTheNameOfLaunchConfiguration,
    getTheConfigTypeOfLaunchConfiguration,
  } = useGetConfigurationToUse();
  const [moveConfigurationToCollaboration] = useMoveConfigurationToCollaborationMutation({
    errorPolicy: 'all',
  });
  const [moveCanaryNetworkToCollaboration] = useMoveCanaryNetworkToCollaborationMutation({
    errorPolicy: 'all',
  });
  const { discardCurrentConfiguration } = useConfigurationUtils();

  const setIsLunching = useCallback<(payload: boolean) => void>(
    async (payload) => {
      dispatch(setIsCollaborationLaunching(payload));
      const action = payload ? openModal : closeModal;
      dispatch(action(EPredefinedModalIds.MODAL_MOVETOCOLLABS));
    },
    [dispatch],
  );

  const launchToCollaboration = useCallback<(payload?: TLaunchCollaborationArgs) => void>(
    async (payload) => {
      setIsLunching(true);

      const configurationUuidForToUse = payload?.openedConfigurationUuid || activeConfigurationUuid;

      if (!configurationUuidForToUse) {
        setIsLunching(false);
        return;
      }

      const cName = getTheNameOfLaunchConfiguration({ configurationUuidForToUse });
      const configType = getTheConfigTypeOfLaunchConfiguration({ configurationUuidForToUse });

      try {
        let uuid: string | undefined = undefined;
        if (configType === ConfigType.Simulation) {
          const data = await moveConfigurationToCollaboration({
            variables: {
              configurationUuid: configurationUuidForToUse,
              name: cName,
            },
          });
          if (!data.errors) {
            uuid = data.data?.moveConfigurationToCollaboration?.uuid?.toString();
          }
        } else {
          const data = await moveCanaryNetworkToCollaboration({
            variables: {
              uuid: configurationUuidForToUse,
              name: cName,
            },
          });
          if (!data.errors) {
            uuid = data.data?.moveCanaryNetworkToCollaboration?.uuid?.toString();
          }
        }

        if (typeof uuid === 'string') {
          discardCurrentConfiguration();
          setTimeout(() => {
            dispatch(setActiveConfigurationUuid(uuid));
          }, 0);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        dispatch(
          openToast({
            message: err.graphQLErrors[0].message,
            type: 'error',
          }),
        );
      }
      //TODO: The logic will continue from here

      setIsLunching(false);
    },
    [
      dispatch,
      setIsLunching,
      getTheNameOfLaunchConfiguration,
      getTheConfigTypeOfLaunchConfiguration,
      activeConfigurationUuid,
      moveConfigurationToCollaboration,
      moveCanaryNetworkToCollaboration,
      discardCurrentConfiguration,
    ],
  );

  return {
    launchToCollaboration,
  };
}
