import gql from 'graphql-tag';

export const CommunitySummaryResultsPartial = gql`
  subscription communitySummaryResultsPartial(
    $jobId: String!
    $communityUuid: String!
    $startTime: DateTime
  ) {
    simulationAreaResultsPartial(
      jobId: $jobId
      areaUUID: $communityUuid
      startTime: $startTime
      timeResolutionHrs: 2
    ) {
      bills
      cumulativeNetEnergyFlow
    }
  }
`;
