import {
  InviteStatusOutputEnum,
  RegistryPageSingleUserOutput,
  UserRequestsStatusOutput,
} from 'src/graphql';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type TCanaryNetworkState = {
  assets: {
    uuid: string;
    name: string;
  }[];
  users: {
    email: string;
    userRole: string;
    requestStatus: UserRequestsStatusOutput;
    id?: string;
    name?: string;
    username?: string;
    profilePicture?: string;
    assetsConfigured: { uuid: string; registrationStatus: string }[];
    canaryInvitations: { configUuid: string; status: InviteStatusOutputEnum }[];
    aggregatorInformation?: RegistryPageSingleUserOutput;
  }[];
};

export const initialCanaryNetworkState: TCanaryNetworkState = {
  assets: [],
  users: [],
};

const canaryNetwork = createSlice({
  name: 'canaryNetwork',
  initialState: initialCanaryNetworkState,
  reducers: {
    updateCnAssets(state, action: PayloadAction<TCanaryNetworkState['assets']>) {
      state.assets = action.payload;
    },
    updateCnUsers(state, action: PayloadAction<TCanaryNetworkState['users']>) {
      state.users = action.payload;
    },
  },
});

// Actions
export const { updateCnAssets, updateCnUsers } = canaryNetwork.actions;

// Reducer
export const canaryNetworkReducer = canaryNetwork.reducer;
