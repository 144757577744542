import React, { useContext } from 'react';

import { ApplicationContext } from 'src/contexts/ApplicationContext';
import { EPredefinedModalIds } from 'src/constants/modals';
import { ModalAlert } from 'src/components/ModalAlert';
import { PrivateRoute } from 'src/routes/PrivateRoute';
import { PublicRoute } from 'src/routes/PublicRoute';
import { Switch } from 'react-router';
import { TDesktopFrameProps } from './DesktopView.types';
import { ToastList } from 'src/components/Toast';
import { routes } from 'src/routes/routes.config';
import { selectTutorialStep } from 'src/redux/application/application.selectors';
import { useSelector } from 'react-redux';
import s from './DesktopView.module.scss';

export const DesktopView: React.FC<TDesktopFrameProps> = () => {
  const { alertContinueResolver, alertProps } = useContext(ApplicationContext);
  const tutorialStep = useSelector(selectTutorialStep);

  return (
    <>
      {/* Backdrop for Tutorial / Onboarding */}
      {tutorialStep !== 0 && <div className={s.supremeContainer}> </div>}
      <div className={s.container}>
        <Switch>
          {routes.map((item, index) => {
            if (item.private) {
              return <PrivateRoute key={index} {...item} />;
            }

            return <PublicRoute key={index} {...item} />;
          })}
        </Switch>

        <ModalAlert
          {...alertProps}
          onContinue={() => {
            alertContinueResolver.current?.();
          }}
          modalId={EPredefinedModalIds.MODAL_ALERT}
        />

        {/* ToastList */}
        <ToastList />
      </div>
    </>
  );
};
