import React, { useContext, useMemo } from 'react';

import { BaseIcon } from 'src/components/BaseIcon';
import { BaseMultiselect } from 'src/components/BaseMultiselect';
import { CanaryNetworkServiceContext } from 'src/components/CanaryNetworkServiceProvider/CanaryNetworkServiceProvider';
import { EUserRoles } from 'src/typings/base-types';
import { ProfilePictureIcon } from 'src/components/UserAvatar';
import { TCanaryRegistryUserTileProps } from 'src/components/MapSidebar/components/MapSidebarCanary/components/CanaryRegistryList';
import classNames from 'classnames';
import s from './CanaryRegistryList.module.scss';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import { selectUserRole } from 'src/redux/auth/auth.selectors';
import { useSelector } from 'react-redux';

export const CanaryRegistryUserTile: React.FC<TCanaryRegistryUserTileProps> = ({
  data,
  selected,
  homesList,
  withoutSelectedUIEffect,
  onClick,
}) => {
  const configurationUuid = useSelector(selectActiveConfigurationUuid);
  const canaryNetworkServiceContext = useContext(CanaryNetworkServiceContext);
  const userRole = useSelector(selectUserRole);

  const showSelectboxes = userRole === EUserRoles.Admin;
  const firstHomeAssign = Object.keys(data.assignedAssets).length === 0;
  const assignedHomes = Object.keys(data.assignedAssets).filter(
    (key) => data.assignedAssets[key].data.status === 'Approved',
  );

  const multiselectLabel = useMemo(() => {
    if (assignedHomes.length === 0) {
      return 'Assign home';
    } else if (assignedHomes.length === 1) {
      return '1 Home';
    } else {
      return `${assignedHomes.length} Homes`;
    }
  }, [assignedHomes.length]);

  const onMultiselectItemSelect = ({ value }) => {
    const homeIsAssigned = !!assignedHomes.find((uuid) => uuid === value);
    if (homeIsAssigned) {
      canaryNetworkServiceContext.removeExternalConnection(
        configurationUuid!,
        value,
        data.name,
        data.name,
      );
    } else {
      canaryNetworkServiceContext.approveExternalConnection(
        configurationUuid!,
        value,
        data.name,
        data.name,
      );
    }
  };

  const multiselectOptions = useMemo(
    () => homesList.map((item) => ({ label: item.name, value: item.uuid })),
    [homesList],
  );

  return (
    <div
      className={classNames(s.userTile, {
        [s.selected]: !withoutSelectedUIEffect && selected,
      })}
      onClick={() => onClick(data)}>
      <ProfilePictureIcon
        avatarUrl={data.avatarUrl}
        name={data.name}
        className={{ nameInitials: s.avatar, picture: classNames(s.avatar, s.hasAvatar) }}
      />
      <p className={s.username}>{data.name.split('@')[0]}</p>
      <div className={s.actions}>
        {data.isAggregator ? (
          <div className={s.tag}>Aggregator</div>
        ) : data.isGridOperator ? (
          <div className={s.tag}>Grid Operator</div>
        ) : (
          <>
            {showSelectboxes && (
              <div
                className={classNames(s.multiselect, {
                  [s.firstHomeAssign]: firstHomeAssign,
                })}>
                <BaseMultiselect
                  className={s.selectbox}
                  label={multiselectLabel}
                  selectedOptions={assignedHomes}
                  options={multiselectOptions}
                  onItemClick={onMultiselectItemSelect}
                />
                <div
                  className={classNames(s.joinState, {
                    [s.accepted]: data.joined,
                  })}>
                  <BaseIcon icon="check-mark" size={12} />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
