import React from 'react';
import { TGenderEqualityPlanProps } from './GenderEqualityPlan.types';
import s from './GenderEqualityPlan.module.scss';

export const GenderEqualityPlan: React.FC<TGenderEqualityPlanProps> = () => {
  return (
    <div className={s.container}>
      <div className={s.equality}>
        <p>
          Grid Singularity is committed to providing a safe, equitable and inclusive working
          environment for people of all genders by implementing gender-sensitive and diversity
          promotion policies both in company operations and product development.
        </p>
        <h1>Our commitments</h1>
        <p>
          <ul>
            <li>
              The use of inclusive and gender-sensitive language in internal and external company
              communication
            </li>
            <li>
              Ensuring unconscious bias and dignity at work understanding and practice by all team
              members
            </li>
            <li>Supporting team members on parental leave and upon return from leave</li>
            <li>
              Ensuring effective implementation of the Gender Equality Plan (GEP) by having
              dedicated internal resources and expertise in gender equality, as relevant to company
              size
            </li>
            <li>
              Aligning with the UN Sustainable Development Goals, particularly SDG 5 for gender
              equality and empowerment of all women and girls in all their diversity and SDG 7 for
              access to affordable, reliable, sustainable and modern energy for all
            </li>
          </ul>
        </p>

        <h1>Our actions</h1>
        <p>
          <ul>
            <li>
              Gender-proofing and sensitising recruitment and promotional activities, striving to
              gender parity at both all team and management level
            </li>
            <li>
              Implement evidence-based awareness-raising efforts on gender equality aimed at making
              the workplace culture an inclusive and safe space for people of all genders, including
              by integrating and sharing the content of this plan with all team members via internal
              policy handbook and onboarding process
            </li>
            <li>Provide gender equality training for all team members</li>
            <li>
              Zero tolerance for gender-based violence, including sexual harassment and statements
              of an offensive nature, with due process integrated in the internal policy handbook
            </li>
            <li>Collect and publish relevant disaggregated data on the sex and/or gender</li>
            <li>
              Parental leave policies and flexible working time arrangements to support employees
              with parental/other caring responsibilities
            </li>
          </ul>
        </p>
      </div>
    </div>
  );
};
