import { TFieldsTemplateUnion, TSettingsData } from 'src/utils/assetsFields/assetsFields.types';

import { EFormVariant } from 'src/typings/base-types';
import { SWITCHER_ICON_SIZES } from 'src/constants/application';

export type TStoragePhoenixArgs = {
  isLibrary: boolean;
  settingsData: TSettingsData;
};

export const StoragePhoenix = ({
  isLibrary,
  settingsData,
}: TStoragePhoenixArgs): TFieldsTemplateUnion[] => {
  return [
    {
      name: 'libraryUUID',
      formView: EFormVariant.Express,
    },
    {
      name: 'name',
      type: 'text',
      label: 'Name',
      formView: EFormVariant.Express,
    },
    {
      name: 'geoTagLocation',
      type: 'location',
      label: 'Location',
      tooltipText: 'Parent market already has location',
      EXCLUDE: isLibrary,
      formView: EFormVariant.Advanced,
    },
    // {
    //   name: 'count',
    //   type: 'counter',
    //   label: 'Multiply same unit',
    //   min: 1,
    //   formView: EFormVariant.Express,
    // },
    // ...evaluatedDeviceKpisFields,
    {
      name: 'batteryCapacityKwh',
      type: 'number',
      label: 'Battery Capacity',
      unit: 'kWh',
      formView: EFormVariant.Advanced,
    },
    {
      name: 'initialSoc',
      type: 'number',
      label: 'Initial Capacity',
      unit: '%',
      inlineAlign: 'left',
      fullWidthErr: true,
      disabled: false,
      formView: EFormVariant.Advanced,
    },
    {
      name: 'initialkWh',
      type: 'number',
      unit: 'kWh',
      inlineAlign: 'right',
      disabled: true,
      formView: EFormVariant.Advanced,
    },
    {
      name: 'minAllowedSoc',
      type: 'number',
      label: 'Minimum State of Charge',
      unit: '%',
      formView: EFormVariant.Advanced,
    },
    {
      name: 'maxAbsBatteryPowerKw',
      type: 'number',
      label: 'Max Power Rating for Battery',
      unit: 'kW',
      formView: EFormVariant.Advanced,
    },
    {
      name: 'initialSellingRate',
      type: 'number',
      label: 'Initial Selling Rate',
      unit: 'cents / kWh',
      formView: EFormVariant.Advanced,
    },
    {
      name: 'finalSellingRate',
      type: 'number',
      label: 'Final Selling Rate',
      unit: 'cents / kWh',
      formView: EFormVariant.Advanced,
    },
    {
      name: 'energyRateDecreasePerUpdate',
      type: 'number',
      label: 'Rate Decrease',
      unit: 'cents / kWh per update',
      formView: EFormVariant.Advanced,
    },
    {
      name: 'initialBuyingRate',
      type: 'number',
      label: 'Initial Buying Rate',
      unit: 'cents / kWh',
      formView: EFormVariant.Advanced,
    },
    {
      name: 'finalBuyingRate',
      type: 'number',
      label: 'Final Buying Rate',
      unit: 'cents / kWh',
      formView: EFormVariant.Advanced,
    },
    {
      name: 'energyRateIncreasePerUpdate',
      type: 'number',
      label: 'Rate Increase',
      unit: 'cents / kWh per update',
      formView: EFormVariant.Advanced,
    },
    {
      name: 'fitToLimit',
      type: 'checkbox',
      label: 'Linear Pricing',
      formView: EFormVariant.Advanced,
    },
    {
      name: 'updateInterval',
      type: 'slider',
      label: 'Update Interval',
      unit: 'min',
      showUnit: true,
      step: 1,
      // labelStep: settingsData.slotLengthMinutes - 2,
      minVal: 1,
      maxVal: settingsData.slotLengthMinutes - 1,
      formView: EFormVariant.Advanced,
    },
    {
      name: 'capPriceStrategy',
      type: 'checkbox',
      label: 'Capacity Based Method',
      formView: EFormVariant.Advanced,
    },
    {
      name: 'allowExternalConnection',
      type: 'switcher',
      options: [
        { icon: 'close', iconSize: SWITCHER_ICON_SIZES.close, value: false },
        { icon: 'check-mark', iconSize: SWITCHER_ICON_SIZES.tick, value: true },
      ],
      label: 'API open',
      formView: EFormVariant.CanaryNetwork,
      disabled: true,
    },
  ];
};
