import { IProps } from 'src/components/LandingPage/PrincipleItems/PrincipleItems.types';
import { ParallaxLayer } from '@react-spring/parallax';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

export function PrincipleItems({
  offset,
  callbackPage,
  children,
  factor,
  speed,
}: IProps): JSX.Element {
  function currentPage() {
    callbackPage(offset);
  }

  return (
    <VisibilitySensor>
      {({ isVisible }) => {
        if (isVisible) currentPage();

        return (
          <ParallaxLayer
            speed={speed || 0}
            factor={factor || 0.5}
            offset={offset}
            style={{
              display: 'flex',
              zIndex: 4,
            }}>
            {children}
          </ParallaxLayer>
        );
      }}
    </VisibilitySensor>
  );
}

PrincipleItems.defaultProps = {
  callbackPage: () => undefined,
};
