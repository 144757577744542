import { ELsKey } from 'src/utils/localStorage';
import en from './en/EN_generated.json';
import es from './es/ES_generated.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const lsLang = localStorage.getItem(ELsKey.LANGUAGE);

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: process.env.NODE_ENV !== 'production',
    fallbackLng: 'en',
    lng: lsLang ? lsLang : window.location.href.includes('scm-map') ? 'es' : 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: en,
      },
      es: {
        translation: es,
      },
    },
  });

export default i18n;
