import { TLngLat, TLngLatArray } from 'src/typings/base-types';

import { Result } from '@mapbox/mapbox-gl-geocoder';
import { getLocationDetails } from 'src/services/api/mapbox.api';
import { locationsCache } from 'src/cache/locations';

export function getLocationDetailsByPriority(locationDetails: Result[]): Result | undefined {
  if (!locationDetails) return;
  const typesOrder = ['address', 'place', 'postcode'];
  for (let i = 0; i < typesOrder.length; i++) {
    const type = typesOrder[i];
    const result = locationDetails.find((item) => item.place_type.includes(type));
    if (result) return result;
  }
}

export function convertLngLat<T extends TLngLat | TLngLatArray>(
  input: T,
): T extends TLngLat ? TLngLatArray : TLngLat {
  if (Array.isArray(input)) {
    // @ts-ignore
    return {
      lng: input[0],
      lat: input[1],
    };
  } else {
    // @ts-ignore
    return [input.lng, input.lat];
  }
}

export function getPlaceTypeZoom(type: string): number {
  switch (type) {
    case 'street':
    case 'address':
    case 'poi':
      return 15;
    case 'locality':
    case 'postcode':
      return 14;
    case 'place':
    case 'city':
      return 13;
    case 'district':
      return 9;
    case 'region':
      return 6;
    case 'country':
    default:
      return 4;
  }
}

export const getPlaceNameFromCoords = async (coords: TLngLatArray): Promise<Result | undefined> => {
  let output;
  const cacheKey = locationsCache.convertToKey(coords);
  const cachedPlaceName = locationsCache.get(cacheKey);

  if (cachedPlaceName) {
    output = cachedPlaceName;
  } else {
    const details = await getLocationDetails(convertLngLat(coords));

    if (details) {
      const locationDetails = getLocationDetailsByPriority(details.features);
      if (locationDetails) {
        output = locationDetails;
        locationsCache.set(cacheKey, locationDetails);
      }
    }
  }
  return output;
};
