import { Currencies } from 'src/graphql';
import { TSimulationResultData } from 'src/hooks/useConfigurationEffects';
import { TValuesByFieldName } from 'src/utils/assetsFields/valuesByFieldName.types';

export type TChartSavingsProps = {
  className?: string;
  style?: React.CSSProperties;
  assetValues: TValuesByFieldName | undefined;
  childrenCount: number;
  currency: Currencies;
} & Pick<TSimulationResultData, 'latestSavingsKpi' | 'savingsKpiProfile'>;

export enum ESavingType {
  Earning = 'Earning',
  Neutral = 'Neutral',
  Saving = 'Saving',
  Losses = 'Losses',
}

export type TCustomData = {
  savingTypes: ESavingType[];
  currencySymbol: string;
};
