import { useMemo } from 'react';
import { v4 } from 'uuid';

export type TUseModalData = {
  id: string;
};

export const useModal = (): TUseModalData => {
  const modalId = useMemo<string>(() => v4(), []);

  return { id: modalId };
};
