import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import React, { useRef } from 'react';

import { BaseClose } from 'src/components/BaseClose';
import { BaseGenericModal } from 'src/components/BaseGenericModal';
import { FormChangePassword } from 'src/components/FormChangePassword';
import { TModalChangePasswordProps } from './ModalChangePassword.types';
import { closeModal } from 'src/redux/modals/modals.slice';
import s from './ModalChangePassword.module.scss';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { useAppDispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';

export const ModalChangePassword: React.FC<TModalChangePasswordProps> = ({
  resetId,
  resetKey,
  onClose,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const isModalOpened = useSelector(selectIsModalOpened(EPredefinedModalIds.MODAL_CHANGE_PASSWORD));

  function handleCloseClick() {
    dispatch(closeModal(EPredefinedModalIds.MODAL_CHANGE_PASSWORD));
    onClose?.();
  }

  return (
    <>
      {isModalOpened && <div className={s.background} />}
      <BaseGenericModal
        className={s.modal}
        modalId={EPredefinedModalIds.MODAL_CHANGE_PASSWORD}
        size={EModalSize.L395}
        modalRef={modalRef}>
        <BaseClose className={s.closeBtn} onClick={handleCloseClick} />

        <div className={s.formsWrapper}>
          <header>
            <h1 className={s.heading}>Password recovery</h1>
          </header>

          <FormChangePassword handleClose={handleCloseClick} {...{ resetId, resetKey }} />
        </div>
      </BaseGenericModal>
    </>
  );
};
