import { ChartOptions, TooltipOptions } from 'chart.js';
import {
  commonChartOptions,
  commonInteraction,
} from 'src/components/_charts/chartsData/commonOptions';

import { TooltipDefault } from 'src/components/_charts/TooltipFactory/tooltips/TooltipDefault/TooltipDefault';
import { TooltipFactory } from 'src/components/_charts/TooltipFactory/TooltipFactory';
import { isJson } from 'src/utils/isJson';
import { tooltipCallbacks } from 'src/components/_charts/chartsData/tooltipCallbacks';

const scaleX = {
  type: 'time' as const,
  ticks: {
    display: false,
  },
  grid: {
    display: false,
    drawBorder: false,
    drawTicks: false,
  },
};

const scaleY = {
  ticks: {
    display: false,
  },
  grid: {
    display: false,
    drawBorder: false,
    drawTicks: false,
  },
};

const scaleYTitle = {
  display: true,
  font: {
    size: 8,
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const tooltipDetailed: TooltipOptions<any> = {
  enabled: false,
  external: (context) =>
    TooltipFactory({ context, component: TooltipDefault, position: 'next-to-cursor' }),
  // @ts-ignore
  callbacks: {
    title: tooltipCallbacks.title,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    label: (context: any): string => {
      const isJsonLabelData = isJson(context.dataset.label);

      if (isJsonLabelData) {
        const { unit, title } = JSON.parse(context.dataset.label);

        if (
          context.parsed &&
          context.parsed.y &&
          context.parsed.y !== null &&
          context.parsed.y !== undefined
        ) {
          return `${title}: ` + context.parsed.y + ` ${unit}`;
        }

        return `${title}: ` + context.formattedValue + ` ${unit}`;
      }
      return context.formattedValue;
    },
  },
};

export const DeviceProfileOneConfig: ChartOptions = {
  ...commonChartOptions,
  parsing: undefined,
  layout: {
    padding: {
      top: 5,
      bottom: 5,
    },
  },
  interaction: commonInteraction,
  scales: {
    x: scaleX,
    y: {
      ...scaleY,
      title: {
        ...scaleYTitle,
      },
    },
  },
  elements: {
    point: {
      radius: 0,
      hitRadius: 1,
    },
  },
  plugins: {
    tooltip: tooltipDetailed,
  },
};

export const DeviceProfileTwoConfig: ChartOptions = {
  ...commonChartOptions,
  parsing: undefined,
  interaction: commonInteraction,
  scales: {
    x: { ...scaleX, stacked: true },
    y: {
      ...scaleY,
      stacked: false,
      title: {
        ...scaleYTitle,
      },
    },
  },
  plugins: {
    tooltip: tooltipDetailed,
  },
};

export const DeviceProfileThreeConfig: ChartOptions = {
  ...commonChartOptions,
  parsing: undefined,
  interaction: commonInteraction,
  scales: {
    x: scaleX,
    y: {
      ...scaleY,
      title: {
        ...scaleYTitle,
      },
    },
  },
  plugins: {
    tooltip: tooltipDetailed,
  },
};

export const DeviceProfileFourConfig: ChartOptions = {
  ...commonChartOptions,
  parsing: undefined,
  interaction: commonInteraction,
  scales: {
    x: { ...scaleX, stacked: true },
    y: {
      ...scaleY,
      stacked: false,
      title: {
        ...scaleYTitle,
      },
    },
  },
  plugins: {
    tooltip: tooltipDetailed,
  },
};

export const DeviceProfileTankConfig: ChartOptions = {
  ...commonChartOptions,
  parsing: undefined,
  layout: {
    padding: {
      top: 5,
      bottom: 5,
    },
  },
  interaction: commonInteraction,
  scales: {
    x: scaleX,
    y: {
      ...scaleY,
      title: {
        ...scaleYTitle,
      },
    },
  },
  elements: {
    point: {
      radius: 0,
      hitRadius: 1,
    },
  },
  plugins: {
    tooltip: tooltipDetailed,
  },
};
