import { EDurationType, TTimelineFiltersProps } from './TimelineFilters.types';

import { BaseButton } from 'src/components/BaseButton';
import { BaseTags } from 'src/components/BaseTags';
import { ChartDayProfileColors } from 'src/constants/chart';
import React from 'react';
import classnames from 'classnames';
import s from './TimelineFilters.module.scss';

export const TimelineFilters: React.FC<TTimelineFiltersProps> = ({
  filter,
  setFilter,
  durationFilter,
  setDurationFilter,
}) => {
  const IsDurationSelected = (filter: string) => durationFilter === filter;

  const chartFilterItems = [
    { name: 'Prices', color: ChartDayProfileColors.pricesLine },
    { name: 'Volume', color: ChartDayProfileColors.tradesLine },
  ];

  const handleDurationChange = (duration: EDurationType) => {
    if (duration !== durationFilter) {
      setDurationFilter(duration);
    } else {
      setDurationFilter('');
    }
  };

  return (
    <div className={s.container}>
      <BaseTags
        items={chartFilterItems}
        name="baseTagsTimeline"
        value={filter}
        onChange={({ value }) => {
          setFilter(value);
        }}
      />
      <BaseButton
        theme="grey"
        className={classnames(s.dateButton, {
          [s.selected]: IsDurationSelected(EDurationType.OneDay),
        })}
        onClick={() => handleDurationChange(EDurationType.OneDay)}>
        {EDurationType.OneDay}
      </BaseButton>
      <BaseButton
        theme="grey"
        className={classnames(s.dateButton, {
          [s.selected]: IsDurationSelected(EDurationType.OneWeek),
        })}
        onClick={() => handleDurationChange(EDurationType.OneWeek)}>
        {EDurationType.OneWeek}
      </BaseButton>
      <BaseButton
        theme="grey"
        className={classnames(s.dateButton, {
          [s.selected]: IsDurationSelected(EDurationType.OneMonth),
        })}
        onClick={() => handleDurationChange(EDurationType.OneMonth)}>
        {EDurationType.OneMonth}
      </BaseButton>
      <BaseButton
        theme="grey"
        className={classnames(s.dateButton, {
          [s.selected]: IsDurationSelected(EDurationType.All),
        })}
        onClick={() => handleDurationChange(EDurationType.All)}>
        {EDurationType.All}
      </BaseButton>
    </div>
  );
};
