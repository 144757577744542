import { BaseGenericModal } from 'src/components/BaseGenericModal';
import { BaseList } from 'src/components/BaseList';
import { EModalSize } from 'src/constants/modals';
import React from 'react';
import {
  TMapSidebarDropdownMenuProps,
  TMapSidebarFilterList,
} from './MapSidebarDropdownMenu.types';
import classnames from 'classnames';
import { closeModal } from 'src/redux/modals/modals.slice';
import s from './MapSidebarDropdownMenu.module.scss';
import { useAppDispatch } from 'src/redux/store';
import { EDropdownMenuFilter } from '../MapSidebarCommunitiesList';

export const MapSidebarDropdownMenu: React.FC<TMapSidebarDropdownMenuProps> = ({
  relativeElement,
  modalId,
  size,
  activeDropdownFilter,
  setActiveDropdownFilter,
}) => {
  const dispatch = useAppDispatch();

  const FILTERS: TMapSidebarFilterList = [
    { name: 'Simulations', value: EDropdownMenuFilter.Simulations },
    { name: 'Canary Networks', value: EDropdownMenuFilter.CanaryNetworks },
    { name: 'Collaborations', value: EDropdownMenuFilter.Collaborations },
  ];

  const onItemSelect = (item) => {
    dispatch(closeModal(modalId));
    setActiveDropdownFilter(item);
  };

  return (
    <BaseGenericModal
      relativeElement={relativeElement}
      size={size}
      modalId={modalId}
      position={{
        side: 'bottom',
        anchorPosition: 37,
      }}
      singleActionModal>
      <div
        className={classnames(s.container, {
          [s.smallDropdown]: size === EModalSize.S175,
          [s.bigDropdown]: size === EModalSize.M235,
        })}
        style={{ ['--container-width' as string]: size + 'px' }}>
        <BaseList width="100%" className={s.list}>
          {FILTERS.map((item, index) => (
            <div
              key={index}
              className={classnames(s.item, {
                [s.itemActive]: activeDropdownFilter === item.value,
              })}
              onClick={() => onItemSelect(item.value)}>
              <button type="button">{item.name}</button>
            </div>
          ))}
        </BaseList>
      </div>
    </BaseGenericModal>
  );
};
