import { BACKEND_DATE_FORMATS, UTCMoment } from 'src/utils/UTCMoment';
import {
  ReadConfigurationDocument,
  ReadConfigurationQuery,
  ReadConfigurationQueryResult,
  ReadConfigurationQueryVariables,
  ReadProjectLocationVisibilityDocument,
  ReadProjectLocationVisibilityQuery,
  ReadProjectLocationVisibilityQueryVariables,
  ReadSimulationResultsDocument,
  ReadSimulationResultsQuery,
  ReadSimulationResultsQueryVariables,
  SimulationResultsData,
  SpotMarketType,
  useReadSimulationResultsLazyQuery,
} from 'src/graphql';
import {
  appendSimulationResults,
  configurationReset,
  setActiveConfigurationJobUuid,
  setActiveConfigurationUuid,
  setCommunitySimulationResults,
  setConfiguration,
  setRawCommunitySimulationResults,
  setResultsEndTime,
  setResultsStartTime,
  setSimulationProgress,
  setSimulationResults,
  setSimulationStatus,
  updateConfigType,
  updateReadOnly,
} from 'src/redux/configuration/configuration.slice';
import { batch, useSelector } from 'react-redux';
import { matchPath, useHistory, useRouteMatch } from 'react-router';
import {
  selectActiveConfigurationJobUuid,
  selectActiveConfigurationUuid,
  selectCommunityAsset,
  selectConfigType,
  selectResultsEndTime,
  selectResultsStartTime,
  selectSelectedAssetUuid,
  selectSettingsData,
  selectSimulationStatus,
} from 'src/redux/configuration/configuration.selectors';
import { setIsAppLoading, setSCMhomeDetails } from 'src/redux/scm/scm.slice';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { NN } from 'src/typings/helpers';
import { SimulationAreaResultsPartial } from 'src/graphql/subscriptions/simAreaResultsPartial';
import { TAsset } from 'src/typings/configuration.types';
import { destructureConfigTree } from 'src/utils/configuration/destructureConfigTree';
import { getCommunityAsset } from 'src/utils/configuration/getCommunityAsset';
import { openToast } from 'src/redux/toast/toast.slice';
import { parseSimulationResults } from 'src/utils/parseSimulationResults';
import { routesConfig } from 'src/routes/routes.config';
import { selectIsEmbed } from 'src/redux/application/application.selectors';
import { setCommunityNotFound } from 'src/redux/application/application.slice';
import { useApolloClient } from '@apollo/client';
import { useAppDispatch } from 'src/redux/store';
import { useAppLocation } from 'src/hooks/useAppLocation';
import { useConfigurationUtils } from 'src/hooks/useConfigurationUtils';
import { useSubscribeToQuery } from 'src/hooks/useSubscribeToQuery';

export type TSimulationResultData = ReturnType<typeof parseSimulationResults> & {
  assetUuid: TAsset['uuid'];
};

/**
 *
 *  __useConfigurationEffects__
 *
 *  This hook is important part of app flow. It's used for:
 *  - Fetching configuration on activeConfigurationUuid change
 *  - Parsing configuration and storing in redux
 *  - Fetching simulation results
 *  - Simulation subscription handling
 *  - Parsing and passing configuration results to redux
 *  - Catching route change, matching and setting activeConfigurationUuid
 *
 */
export function useConfigurationEffects(): void {
  const dispatch = useAppDispatch();
  const client = useApolloClient();
  const history = useHistory();
  const location = useAppLocation();
  const isEmbed = useSelector(selectIsEmbed);
  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
  const activeConfigurationRef = useRef<string | undefined>(undefined);
  const activeJobUuid = useSelector(selectActiveConfigurationJobUuid);
  const communityAsset = useSelector(selectCommunityAsset);
  const selectedAssetUuid = useSelector(selectSelectedAssetUuid);
  const settingsData = useSelector(selectSettingsData);
  const resultsStartTimeState = useSelector(selectResultsStartTime);
  const configType = useSelector(selectConfigType);
  const simulationStatus = useSelector(selectSimulationStatus);
  const resultsEndTimeState = useSelector(selectResultsEndTime);

  const [readRawSimulationResults] = useReadSimulationResultsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted(data) {
      if (selectedAssetUuid && data.simulationResults) {
        dispatch(setRawCommunitySimulationResults(data.simulationResults as TSimulationResultData));
      }
    },
  });
  const { zoomIntoConfiguration, discardCurrentConfiguration } = useConfigurationUtils();

  const resultsStartTime = useMemo(() => {
    if (!resultsStartTimeState) return null;
    return UTCMoment.fromBackend(resultsStartTimeState).format(
      BACKEND_DATE_FORMATS.SIMULATION_RESULTS_START_END_TIME,
    );
  }, [resultsStartTimeState]);

  const resultsEndTime = useMemo(() => {
    return UTCMoment.fromBackend(resultsEndTimeState)
      .endOf('day')
      .format(BACKEND_DATE_FORMATS.SIMULATION_RESULTS_START_END_TIME);
  }, [resultsEndTimeState]);

  const settingsStartTime = useMemo(() => {
    return UTCMoment.fromBackend(settingsData.startDate).format(
      BACKEND_DATE_FORMATS.SIMULATION_RESULTS_START_END_TIME,
    );
  }, [settingsData]);

  const settingsEndTime = useMemo(() => {
    return UTCMoment.fromBackend(settingsData.endDate)
      .endOf('day')
      .format(BACKEND_DATE_FORMATS.SIMULATION_RESULTS_START_END_TIME);
  }, [settingsData]);

  /**
   *
   *  Use lazy query for simulation results fetch. Using onCompleted is safer than handling in useEffect
   *
   */
  const avoidLiveSubscriptionData = useMemo(() => {
    const noSimStatus = typeof simulationStatus === 'undefined';
    const simIsFinishedOrFailed = ['finished', 'failed'].includes(simulationStatus || '');
    const isCN = configType === 'CANARY_NETWORK';
    const indicatorMatchedWithEnd =
      UTCMoment.fromBackend(resultsEndTimeState).format('MMMM D') ===
      UTCMoment.utc().format('MMMM D');
    return noSimStatus ? true : isCN ? !indicatorMatchedWithEnd : simIsFinishedOrFailed;
  }, [configType, resultsEndTimeState, simulationStatus]);

  useSubscribeToQuery<ReadSimulationResultsQuery, ReadSimulationResultsQueryVariables>({
    queryDoc: ReadSimulationResultsDocument,
    subscriptionDoc: SimulationAreaResultsPartial,
    avoidLiveSubscriptionData: avoidLiveSubscriptionData,
    variables: {
      jobId: activeJobUuid || '',
      uuid: selectedAssetUuid || '',
      startTime: resultsStartTime,
      endTime: resultsEndTime,
    },
    onQueryCompleted({ simulationResults }) {
      if (selectedAssetUuid) {
        updateSimulationResults(
          simulationResults as SimulationResultsData,
          selectedAssetUuid,
          'set',
        );
        if (avoidLiveSubscriptionData) {
          readRawSimulationResults({
            variables: {
              jobId: activeJobUuid || '',
              uuid: selectedAssetUuid || '',
              startTime: settingsStartTime,
              endTime: settingsEndTime,
            },
          });
        }
      }
    },
    updateQuery(prev, { subscriptionData }) {
      const resultsPartial = subscriptionData?.data
        .simulationAreaResultsPartial[0] as SimulationResultsData;
      /*
      const weekEnd = UTCMoment.utc(resultsEndTimeState).endOf('day');
      const weekStart = UTCMoment.utc(resultsStartTimeState).startOf('day');

      // Don't update data while current market date is not a part of selected week (in WeeklyResultsIndicator)
      if (configType !== ConfigType.Collaboration && configType !== ConfigType.CanaryNetwork) {
        const currentMarketUTC = UTCMoment.fromBackend(
          resultsPartial.currentMarket,
          BACKEND_DATE_FORMATS.SIMULATION_RESULTS_START_END_TIME,
        );
        if (!currentMarketUTC.isBetween(weekStart, weekEnd)) {
          return prev;
        }
      }*/
      if (selectedAssetUuid) {
        updateSimulationResults(resultsPartial, selectedAssetUuid, 'append');
      }

      return prev;
    },
  });

  /**
   *
   * Match route with map results path. It helps us to get configuration and asset uuids
   *
   */
  const paramsMatch = useRouteMatch<{ configurationUuid: string; assetUuid: string }>({
    path: isEmbed
      ? routesConfig.embed('')
      : [
          routesConfig.singularityMapResults(undefined, undefined),
          routesConfig.singularityMapResults(undefined, ''),
          routesConfig.scmMapResults(undefined, ''),
          routesConfig.scmMapResults(undefined, undefined),
        ],
  });
  /**
   *
   *  __updateConfigurationStoreData__
   *
   *  Update configuration in redux store
   *  @param locationVisible Location visible param
   *  @param configurationData Parsed configuration data object
   *
   */
  const updateConfigurationStoreData = useCallback(
    (
      locationVisible,
      {
        jobUuid,
        configType,
        readOnly,
        name,
        user,
        description,
        timezone,
        project,
        assets,
        assetsValues,
        assetsTreeRelations,
        rootAssetUuid,
        selectedAssetUuid,
        settingsData,
        timestamp,
      }: ReturnType<typeof parseConfigurationData>,
      scmHomeDetails,
    ) => {
      batch(() => {
        dispatch(setActiveConfigurationJobUuid(jobUuid || undefined));
        dispatch(updateConfigType(configType || undefined));
        dispatch(setSCMhomeDetails(scmHomeDetails));
        dispatch(updateReadOnly(!!readOnly));
        dispatch(
          setConfiguration({
            name: name || '',
            user: user || '',
            description: description || '',
            timezone: timezone || '',
            projectUuid: project?.uuid || '',
            locationVisible,
            assets,
            assetsTreeRelations,
            assetsValues,
            rootAssetUuid,
            selectedAssetUuid,
            timestamp,
            // TODO: Type has to be fixed
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            settingsData: settingsData || (undefined as any),
          }),
        );
      });
    },
    [dispatch],
  );

  /**
   *
   *  __updateSimulationResults__
   *
   *  Update simulation results in redux store
   *  @param result Simulation result object from backend
   *  @param assetUuid asset uuid
   *
   */
  const updateSimulationResults = useCallback(
    (result: SimulationResultsData, assetUuid: string, type: 'set' | 'append') => {
      const results = parseSimulationResults(result);

      batch(() => {
        dispatch(setSimulationStatus(results.status));
        dispatch(setSimulationProgress(results.progressInfo));

        if (!results) {
          return;
        }

        const actions = {
          set: setSimulationResults,
          append: appendSimulationResults,
        };

        dispatch(
          actions[type]({
            assetUuid,
            ...results,
          }),
        );

        //communityAsset
        if (assetUuid === communityAsset?.uuid) {
          const communityActions = {
            set: setCommunitySimulationResults,
            append: appendSimulationResults,
          };

          dispatch(
            communityActions[type]({
              isCommunitySimulationResult: true,
              assetUuid,
              ...results,
            }),
          );
        }
      });
    },
    [dispatch, communityAsset?.uuid],
  );

  /**
   *
   *  __parseConfigurationData__
   *
   *  Parse configuration object
   *  @param configuration Configuration object from backend
   *  @returns Parsed configuration data with destructured tree and communityAssetUuid
   *
   */
  function parseConfigurationData(
    configuration: NN<NN<ReadConfigurationQueryResult['data']>['readConfiguration']>,
    assetUuidFromUrl?: string,
  ) {
    const { scenarioData, simulationResults } = configuration;
    const jobUuid = simulationResults;
    const configTree = destructureConfigTree(scenarioData?.latest?.serialized);
    const { assets, assetsTreeRelations, rootAssetUuid, assetsValues } = configTree;

    const communityAssetUuid = getCommunityAsset({
      assets,
      rootAssetUuid,
      assetsTreeRelations,
      assetsValues,
    })?.uuid;
    return {
      ...configuration,
      ...configTree,
      jobUuid,
      selectedAssetUuid: assetUuidFromUrl || communityAssetUuid,
    };
  }

  /**
   *
   *  __callForConfiguration__
   *
   *  Read configuration data from backend
   *  @param uuid Configuration uuid
   *  @returns Configuration object
   *
   */
  const callForConfiguration = useCallback(
    async (uuid: string) => {
      const configurationResult = await client.query<
        ReadConfigurationQuery,
        ReadConfigurationQueryVariables
      >({
        query: ReadConfigurationDocument,
        variables: { uuid },
        fetchPolicy: 'no-cache',
      });

      return configurationResult.data.readConfiguration;
    },
    [client],
  );

  /**
   *
   *  __callForProjectLocationVisibility__
   *
   *  Read project visibility param from backend
   *  @param projectUuid Project uuid
   *  @returns Boolean value of locationVisible param
   *
   */
  const callForProjectLocationVisibility = useCallback(
    async (projectUuid: string) => {
      const projectResult = await client.query<
        ReadProjectLocationVisibilityQuery,
        ReadProjectLocationVisibilityQueryVariables
      >({
        query: ReadProjectLocationVisibilityDocument,
        variables: { projectUuid },
        fetchPolicy: 'no-cache',
      });

      return projectResult?.data?.readProject?.locationVisible ?? true;
    },
    [client],
  );

  /**
   *
   *  __getConfiguration__
   *
   *  Main function for configuration reading.
   *  @param configurationUuid Uuid of configuration
   *
   */
  const getConfiguration = useCallback(
    async (configurationUuid) => {
      dispatch(setIsAppLoading(true));
      const configuration = await callForConfiguration(configurationUuid);

      if (configuration) {
        let startTime, endTime;
        const scmHomeDetails = configuration?.scenarioData?.homeInfo?.scmHomeDetails || [];

        const configurationData = parseConfigurationData(
          configuration,
          paramsMatch?.params.assetUuid,
        );
        const isCN =
          configuration.configType === 'CANARY_NETWORK' &&
          configuration.settingsData?.spotMarketType !== SpotMarketType.Coefficients;

        const isShorterThanWeek =
          UTCMoment.fromBackend(configuration.settingsData?.endDate).diff(
            UTCMoment.fromBackend(configuration.settingsData?.startDate),
            'days',
          ) < 7;

        if (isShorterThanWeek) {
          startTime = UTCMoment.fromBackend(configuration.settingsData?.startDate)
            .startOf('day')
            .toISOString();
          endTime = UTCMoment.fromBackend(configuration.settingsData?.endDate)
            .endOf('day')
            .toISOString();
        } else if (isCN) {
          startTime = UTCMoment.utc().subtract(6, 'days').startOf('day').toISOString();
          endTime = UTCMoment.utc().endOf('day').toISOString();
        } else {
          startTime = UTCMoment.utc(configuration.settingsData?.startDate)
            .startOf('day')
            .toISOString();
          endTime = UTCMoment.utc(configuration.settingsData?.startDate)
            .add(7, 'day')
            .endOf('day')
            .toISOString();
        }

        batch(() => {
          dispatch(setResultsStartTime(startTime));
          dispatch(setResultsEndTime(endTime));
        });

        const { project, selectedAssetUuid, assetsValues } = configurationData;

        if (project?.uuid && selectedAssetUuid) {
          let locationVisible = true;
          locationVisible = await callForProjectLocationVisibility(project.uuid);

          updateConfigurationStoreData(locationVisible, configurationData, scmHomeDetails);
          const routeMatch = matchPath(location.pathname, {
            path: isEmbed
              ? routesConfig.embed(activeConfigurationUuid)
              : routesConfig.singularityMapResults(activeConfigurationUuid, selectedAssetUuid),
          });

          if (!routeMatch?.isExact) {
            history.push(
              isEmbed
                ? routesConfig.embed(activeConfigurationUuid)
                : routesConfig.singularityMapResults(activeConfigurationUuid, selectedAssetUuid),
            );
          }
          try {
            zoomIntoConfiguration({ assetsValues });
          } catch (err) {
            discardCurrentConfiguration();
            dispatch(
              openToast({
                message: 'Something went wrong',
                type: 'error',
              }),
            );
          }
        }
        dispatch(setIsAppLoading(false));
      }
    },
    [
      activeConfigurationUuid,
      callForConfiguration,
      callForProjectLocationVisibility,
      discardCurrentConfiguration,
      dispatch,
      history,
      isEmbed,
      location.pathname,
      paramsMatch?.params.assetUuid,
      // settingsStartTime,
      updateConfigurationStoreData,
      zoomIntoConfiguration,
    ],
  );

  /**
   *
   *  Fetch configuration and results after uuid change. Reset if configuration fetching fails
   *
   */
  useEffect(() => {
    async function makeRequest() {
      if (activeConfigurationRef.current == activeConfigurationUuid) return;

      activeConfigurationRef.current = activeConfigurationUuid;

      if (activeConfigurationUuid) {
        try {
          await getConfiguration(activeConfigurationUuid);
        } catch (error) {
          if (!isEmbed) {
            discardCurrentConfiguration();
          } else {
            dispatch(setCommunityNotFound(true));
          }
        }
      }
    }

    makeRequest();
  }, [
    activeConfigurationUuid,
    discardCurrentConfiguration,
    dispatch,
    getConfiguration,
    isEmbed,
    activeConfigurationRef,
  ]);

  /**
   *
   *  Get and set configuration uuid from URL
   *
   *  Notes:
   *  - setActiveConfigurationUuid dispatch will trigger readConfigurationQuery
   *
   */
  useEffect(() => {
    if (paramsMatch?.params.configurationUuid) {
      dispatch(setActiveConfigurationUuid(paramsMatch?.params.configurationUuid));
    }
  }, [dispatch, history, paramsMatch]);

  /**
   *
   *  Zoom into community on select community asset
   *
   */
  // useEffect(() => {
  //   if (selectedAssetUuid && selectedAssetUuid === communityAsset?.uuid && assetsValues) {
  //     try {
  //       console.log({ selectedAssetUuid, u: communityAsset?.uuid, assetsValues });

  //       zoomIntoConfiguration({ assetsValues });
  //     } catch (err) {
  //       discardCurrentConfiguration();
  //       dispatch(
  //         openToast({
  //           message: 'Something went wrong',
  //           type: 'error',
  //         }),
  //       );
  //     }
  //   }
  // }, [
  //   selectedAssetUuid,
  //   assetsValues,
  //   communityAsset,
  //   zoomIntoConfiguration,
  //   discardCurrentConfiguration,
  //   dispatch,
  // ]);

  /**
   *
   *  On configuration quit
   *
   */
  useEffect(() => {
    if (!activeConfigurationUuid) {
      dispatch(configurationReset());
    }
  }, [activeConfigurationUuid, dispatch]);
}
