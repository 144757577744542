import { TLngLat, TLngLatArray } from 'src/typings/base-types';

import { Result } from '@mapbox/mapbox-gl-geocoder';
import { convertLngLat } from 'src/utils/worldMap/helpers';

type TStringifiedCoords = string;
type TPlace = Result;

const cache: { [key in TStringifiedCoords]: TPlace } = {};

function convertToKey(coords: TLngLatArray | TLngLat): TStringifiedCoords {
  if (Array.isArray(coords)) {
    return String(coords);
  }

  return String(convertLngLat(coords));
}

function set(key: TStringifiedCoords, place: TPlace | undefined | null): void {
  if (place) {
    cache[key] = place;
  }
}

function get(key: TStringifiedCoords): TPlace | undefined {
  return cache[key];
}

export const locationsCache = { set, get, convertToKey };
