export const mergedTimezones: Array<{ value: string; label: string }> = [
  {
    value: '(GMT-11:00)',
    label: '(GMT-11:00)',
  },
  {
    value: '(GMT-10:00)',
    label: '(GMT-10:00)',
  },
  {
    value: '(GMT-09:30)',
    label: '(GMT-09:30)',
  },
  {
    value: '(GMT-09:00)',
    label: '(GMT-09:00)',
  },
  {
    value: '(GMT-08:00)',
    label: '(GMT-08:00)',
  },
  {
    value: '(GMT-07:00)',
    label: '(GMT-07:00)',
  },
  {
    value: '(GMT-06:00)',
    label: '(GMT-06:00)',
  },
  {
    value: '(GMT-05:00)',
    label: '(GMT-05:00)',
  },
  {
    value: '(GMT-04:00)',
    label: '(GMT-04:00)',
  },
  {
    value: '(GMT-03:30)',
    label: '(GMT-03:30)',
  },
  {
    value: '(GMT-03:00)',
    label: '(GMT-03:00)',
  },
  {
    value: '(GMT-02:00)',
    label: '(GMT-02:00)',
  },
  {
    value: '(GMT-01:00)',
    label: '(GMT-01:00)',
  },
  {
    value: '(GMT+00:00)',
    label: '(GMT+00:00)',
  },
  {
    value: '(GMT+01:00)',
    label: '(GMT+01:00)',
  },
  {
    value: '(GMT+02:00)',
    label: '(GMT+02:00)',
  },
  {
    value: '(GMT+03:00)',
    label: '(GMT+03:00)',
  },
  {
    value: '(GMT+03:30)',
    label: '(GMT+03:30)',
  },
  {
    value: '(GMT+04:00)',
    label: '(GMT+04:00)',
  },
  {
    value: '(GMT+04:30)',
    label: '(GMT+04:30)',
  },
  {
    value: '(GMT+05:00)',
    label: '(GMT+05:00)',
  },
  {
    value: '(GMT+05:30)',
    label: '(GMT+05:30)',
  },
  {
    value: '(GMT+05:45)',
    label: '(GMT+05:45)',
  },
  {
    value: '(GMT+06:00)',
    label: '(GMT+06:00)',
  },
  {
    value: '(GMT+06:30)',
    label: '(GMT+06:30)',
  },
  {
    value: '(GMT+07:00)',
    label: '(GMT+07:00)',
  },
  {
    value: '(GMT+08:00)',
    label: '(GMT+08:00)',
  },
  {
    value: '(GMT+08:45)',
    label: '(GMT+08:45)',
  },
  {
    value: '(GMT+09:00)',
    label: '(GMT+09:00)',
  },
  {
    value: '(GMT+09:30)',
    label: '(GMT+09:30)',
  },
  {
    value: '(GMT+10:00)',
    label: '(GMT+10:00)',
  },
  {
    value: '(GMT+10:30)',
    label: '(GMT+10:30)',
  },
  {
    value: '(GMT+11:00)',
    label: '(GMT+11:00)',
  },
  {
    value: '(GMT+12:00)',
    label: '(GMT+12:00)',
  },
  {
    value: '(GMT+12:45)',
    label: '(GMT+12:45)',
  },
  {
    value: '(GMT+13:00)',
    label: '(GMT+13:00)',
  },
  {
    value: '(GMT+14:00)',
    label: '(GMT+14:00)',
  },
];
