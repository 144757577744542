import { BACKEND_DATE_FORMATS, UTCMoment } from 'src/utils/UTCMoment';
import {
  EActiveTabState,
  ECollaborationTab,
  ECommunityTab,
  TMapSidebarResultsProps,
  TPresentationNames,
} from './MapSidebarResults.types';
import { FormSettingsData, TFormSettingsDataProps } from '../../../FormSettingsData';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import {
  selectActiveConfigurationJobUuid,
  selectActiveConfigurationUuid,
  selectAssetByUuid,
  selectCommunityAsset,
  selectCommunityName,
  selectConfigType,
  selectConfigurationCharacteristic,
  selectGridMarketAsset,
  selectIsCNLaunching,
  selectReadOnly,
  selectRootAssetUuid,
  selectSettingsData,
  selectSimulationResults,
  selectSimulationStatus,
} from 'src/redux/configuration/configuration.selectors';
import {
  selectCommunityNotFound,
  selectIsEmbed,
  selectSidebarExpanded,
} from 'src/redux/application/application.selectors';
import { selectIsLoggedIn, selectUserRole } from 'src/redux/auth/auth.selectors';
import {
  setGuideStep,
  setModalAssetManagerActiveView,
  setOnBoardingStep,
  setSidebarExpanded,
} from 'src/redux/application/application.slice';

import { ApplicationContext } from 'src/contexts/ApplicationContext';
import { BaseButton } from '../../../BaseButton';
import { BaseButtonSquare } from 'src/components/BaseButtonSquare';
import { BaseIcon } from 'src/components/BaseIcon';
import { CollaborationFormFields } from 'src/components/MapSidebar/components/MapSidebarResults/MapSidebarResultsConstants';
import { ConfigType } from 'src/graphql';
import { EDomIds } from 'src/constants/domSelectors';
import { EModalAssetsManagerView } from 'src/components/ModalAssetsManager';
import { EPredefinedModalIds } from 'src/constants/modals';
import { EUserRoles } from 'src/typings/base-types';
import { MapSidebarCanary } from 'src/components/MapSidebar/components/MapSidebarCanary';
import { SidebarSubTab } from 'src/components/MapSidebar/components/MapSidebarResults/components/SidebarSubTab';
import { SimulationResultsPresentation } from 'src/components/SimulationResultsPresentation';
import { TSidebarSubTabItemList } from 'src/components/MapSidebar/components/MapSidebarResults/components/SidebarSubTab/SidebarSubTab.types';
import { TextBrick } from 'src/components/TextBrick';
import classnames from 'classnames';
import s from './MapSidebarResults.module.scss';
import { selectUserToAdminRequests } from 'src/redux/communities/communities.selectors';
import { setSelectedAssetUuid } from 'src/redux/configuration/configuration.slice';
import { useAppDispatch } from 'src/redux/store';
import { useConfigurationUtils } from 'src/hooks/useConfigurationUtils';
import useDownloadAPI from 'src/hooks/useDownloadAPI';
import { useLaunchToCanary } from 'src/hooks/useLaunchToCanary';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';

export const MapSidebarResults: React.FC<TMapSidebarResultsProps> = ({
  onSettingsDataSave,
  onCommunityRemove,
  showHeaderTabs = true,
}) => {
  const dispatch = useAppDispatch();
  const isEmbed = useSelector(selectIsEmbed);
  const loggedIn = useSelector(selectIsLoggedIn);
  const communityName = useSelector(selectCommunityName);
  const sidebarExpanded = useSelector(selectSidebarExpanded);
  const simulationResults = useSelector(selectSimulationResults);
  const configType = useSelector(selectConfigType);
  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
  const activeConfigurationJobUuid = useSelector(selectActiveConfigurationJobUuid);
  const communityNotFound = useSelector(selectCommunityNotFound);
  const readOnly = useSelector(selectReadOnly);
  const formHasErrorsRef = useRef(false);
  const formId = useRef(v4());
  const { triggerResultsLossAlert, triggerCommunityDeleteAlert, triggerDownloadAlert } = useContext(
    ApplicationContext,
  );
  const simulationStatus = useSelector(selectSimulationStatus);
  const settingsData = useSelector(selectSettingsData);
  const configurationCharacteristic = useSelector(selectConfigurationCharacteristic);
  const userToAdminRequests = useSelector(selectUserToAdminRequests);
  const userRole = useSelector(selectUserRole);
  const communityAsset = useSelector(selectCommunityAsset);
  const gridAgentAsset = useSelector(selectGridMarketAsset);
  const asset = useSelector(selectAssetByUuid(simulationResults?.assetUuid || ''));
  const rootAssetUuid = useSelector(selectRootAssetUuid);

  const { discardCurrentConfiguration } = useConfigurationUtils();

  const assetType =
    simulationResults?.assetUuid === rootAssetUuid ? 'MarketMaker' : asset?.type || 'Area';

  const isCommunity = communityAsset?.uuid === simulationResults?.assetUuid;
  const isCNLaunching = useSelector(selectIsCNLaunching);

  const resultsDownloadAPI = useDownloadAPI({
    type: 'simulation-results',
    resultsUuid: activeConfigurationJobUuid,
    startDate: UTCMoment.fromBackend(
      settingsData.startDate,
      BACKEND_DATE_FORMATS.SETTINGS_DATA,
    ).format(BACKEND_DATE_FORMATS.DOWNLOAD_SIM_RESULTS),
    endDate: UTCMoment.fromBackend(settingsData.endDate, BACKEND_DATE_FORMATS.SETTINGS_DATA).format(
      BACKEND_DATE_FORMATS.DOWNLOAD_SIM_RESULTS,
    ),
  });

  const [activeTab, setActiveTab] = useState<EActiveTabState>(EActiveTabState.Community);
  const [activeCommunityTab, setActiveCommunityTab] = useState<ECommunityTab>(
    ECommunityTab.GridMarket,
  );
  const [activeCollaborationTab, setActiveCollaborationTab] = useState<ECollaborationTab>(
    ECollaborationTab.Basic,
  );

  useEffect(() => {
    if (!simulationResults) return;

    if (simulationResults.assetUuid === rootAssetUuid) {
      setActiveCommunityTab(ECommunityTab.GridMarket);
      return;
    }
    setActiveCommunityTab(ECommunityTab.GridAgent);
  }, [simulationResults, rootAssetUuid]);

  const GridMarketAgentSubtabs: TSidebarSubTabItemList = useMemo(
    () => [
      {
        liTitle: 'Grid Market Results',
        onClick: () => {
          setActiveCommunityTab(ECommunityTab.GridMarket);
          dispatch(setSelectedAssetUuid(rootAssetUuid));
        },
        label: 'Grid Market',
        isActive: () => activeCommunityTab === ECommunityTab.GridMarket,
      },
      {
        liTitle: 'Grid Agent Results',
        onClick: () => {
          setActiveCommunityTab(ECommunityTab.GridAgent);
          if (gridAgentAsset) dispatch(setSelectedAssetUuid(gridAgentAsset.uuid));
        },
        label: 'Grid Agent',
        isActive: () => activeCommunityTab === ECommunityTab.GridAgent,
      },
    ],
    [setActiveCommunityTab, dispatch, activeCommunityTab, gridAgentAsset, rootAssetUuid],
  );

  const handleCommunityDiscard = () => {
    dispatch(setModalAssetManagerActiveView(EModalAssetsManagerView.AddCommunity));
    dispatch(setOnBoardingStep(0));
    dispatch(setGuideStep(0));
    dispatch(closeModal(EPredefinedModalIds.MODAL_ONBOARDING));
    discardCurrentConfiguration({ zoomOut: true });
  };

  const CollaborationSubtabs: TSidebarSubTabItemList = useMemo(
    () => [
      {
        liTitle: 'Collaboration Basic Settings',
        onClick: () => setActiveCollaborationTab(ECollaborationTab.Basic),
        label: 'Basic Settings',
        isActive: () => activeCollaborationTab === ECollaborationTab.Basic,
      },
      {
        liTitle: 'Collaboration Advanced Settings',
        onClick: () => setActiveCollaborationTab(ECollaborationTab.Advanced),
        label: 'Advanced Settings',
        isActive: () => activeCollaborationTab === ECollaborationTab.Advanced,
      },
    ],
    [setActiveCollaborationTab, activeCollaborationTab],
  );

  const showPresentationAreas: Array<TPresentationNames> = useMemo(() => {
    if (assetType === 'Area' && isCommunity) {
      return [
        'KeyResults',
        'AssetCount',
        'TableEnergyBillsAndNetTraded',
        'SS-SC',
        'ChartEnergyTradeProfile',
        'ChartDayProfile',
        'ChartPrices',
        'TradesBidsAndOffers',
      ];
    } else if (assetType === 'MarketMaker') {
      return [
        'SS-SC',
        'ChartEnergyTradeProfile',
        'ChartDayProfile',
        'ChartPrices',
        'TradesBidsAndOffers',
      ];
    } else if (assetType === 'InfiniteBus') {
      return ['ChartAssetAgent', 'ChartDeviceProfile'];
    } else if (assetType === 'Area') {
      return [
        'ChartSavings',
        'TableEnergyBillsAndNetTraded',
        'EnergyShare',
        'SS-SC',
        'ChartEnergyTradeProfile',
      ];
    } else if (
      assetType === 'Load' ||
      assetType === 'PV' ||
      assetType === 'Storage' ||
      assetType === 'HeatPump'
    ) {
      return ['ChartAsset', 'ChartDeviceProfile'];
    }
    return [];
  }, [assetType, isCommunity]);

  const toggleSidebarExpand = () => {
    dispatch(setSidebarExpanded(!sidebarExpanded));
  };

  const handleSettingsDataSave: TFormSettingsDataProps['onSubmit'] = async (payload) => {
    await triggerResultsLossAlert();

    onSettingsDataSave?.(payload);
  };

  const deleteCommunity = async () => {
    await triggerCommunityDeleteAlert();

    onCommunityRemove?.({ projectUuid: configurationCharacteristic.projectUuid });
  };

  const isRequestedForCn = useMemo(() => {
    return (
      activeConfigurationUuid &&
      userToAdminRequests[activeConfigurationUuid]?.find(
        (item) => item.type === 'CONVERT_SIM_TO_CN' && item.status === 'RECEIVED',
      )
    );
  }, [activeConfigurationUuid, userToAdminRequests]);

  const showDownloadMessage = async (message) => {
    await triggerDownloadAlert(message);
  };
  const { launchToCanary } = useLaunchToCanary();

  return (
    <>
      <div className={s.container} id={EDomIds.MODAL_MAP_SIDEBAR}>
        <>
          {showHeaderTabs && (
            <>
              {!loggedIn && (
                <div className={classnames(s.header, s.headerNonLogin)}>
                  <button
                    type="button"
                    className={classnames(s.backIcon)}
                    onClick={handleCommunityDiscard}>
                    <BaseIcon icon="arrow-left-full" size={16} />
                  </button>
                  <h3>{communityName}</h3>
                </div>
              )}
              {loggedIn && (
                <>
                  <div className={s.header}>
                    <ul className={s.headerNav}>
                      <li
                        className={classnames({
                          [s.active]: [
                            EActiveTabState.Settings,
                            EActiveTabState.CollaborationSettings,
                          ].includes(activeTab),
                        })}>
                        <button
                          type="button"
                          onClick={() =>
                            setActiveTab(
                              configType === ConfigType.Collaboration
                                ? EActiveTabState.CollaborationSettings
                                : EActiveTabState.Settings,
                            )
                          }
                          title="Settings">
                          Settings
                        </button>
                      </li>
                      {!isEmbed && (
                        <>
                          {configType &&
                            [ConfigType.CanaryNetwork, ConfigType.Collaboration].includes(
                              configType,
                            ) && (
                              <li
                                className={classnames({
                                  [s.active]: activeTab === EActiveTabState.Registry,
                                })}>
                                <button
                                  type="button"
                                  onClick={() => setActiveTab(EActiveTabState.Registry)}
                                  title="Registry">
                                  Registry
                                </button>
                              </li>
                            )}
                        </>
                      )}
                      <li
                        className={classnames(s.communityName, {
                          [s.active]: activeTab === EActiveTabState.Community,
                        })}
                        title="Results">
                        <button
                          type="button"
                          onClick={() => setActiveTab(EActiveTabState.Community)}>
                          Results
                        </button>
                      </li>
                    </ul>
                    <BaseButtonSquare
                      theme="flat-gray"
                      icon={sidebarExpanded ? 'collapse' : 'expand-2'}
                      size="2"
                      svgSize="3"
                      onClick={toggleSidebarExpand}
                      className={s.sidebarIconButton}
                    />
                  </div>

                  {['InfiniteBus', 'MarketMaker'].includes(assetType) && (
                    <SidebarSubTab tabs={GridMarketAgentSubtabs} />
                  )}
                  {activeTab === EActiveTabState.CollaborationSettings && (
                    <SidebarSubTab tabs={CollaborationSubtabs} />
                  )}
                </>
              )}
            </>
          )}
          {simulationStatus === 'finished' &&
            activeTab !== EActiveTabState.Registry &&
            !isRequestedForCn && (
              <div className={s.boxSidebarAlert}>
                <div className={s.iconCanary}>
                  <BaseButtonSquare
                    className={s.iconCanary}
                    style={{ maxWidth: 26, maxHeight: 25 }}
                    theme="flat-blue"
                    icon="canary"
                  />
                </div>
                <div className={s.boxText}>
                  <h3>Bring your simulation closer to reality</h3>
                  <p>Connect assets, use real data and trade</p>
                </div>
                <BaseButton
                  onClick={() => {
                    if (userRole !== EUserRoles.Admin) {
                      dispatch(openModal(EPredefinedModalIds.MODAL_CANARY_REQUEST));
                      return;
                    }
                    launchToCanary();
                  }}
                  type="submit"
                  className={s.buttonGo}
                  size="normal"
                  disabled={isCNLaunching}>
                  GO
                </BaseButton>
              </div>
            )}
          {/*
            Used conditional rendering instead of switching "display: none",
            because there is some issues with d3 charts when they are not visible.
            https://gridsingularity.atlassian.net/browse/PH-464
        */}
          {(() => {
            switch (activeTab) {
              case EActiveTabState.Community:
                return (
                  <div className={s.graphsList}>
                    {typeof simulationStatus === 'undefined' ? (
                      <div className={s.noResults}>
                        <TextBrick>
                          {communityNotFound ? 'Community not found' : 'No results'}
                        </TextBrick>
                      </div>
                    ) : (
                      <SimulationResultsPresentation
                        simulationResults={simulationResults}
                        jobId={activeConfigurationJobUuid}
                        settingsData={settingsData}
                        assetType={assetType}
                        showPresentationAreas={showPresentationAreas}
                      />
                    )}
                  </div>
                );
              case EActiveTabState.Registry:
                return (
                  <div className={s.registry}>
                    <MapSidebarCanary />
                  </div>
                );
              case EActiveTabState.CollaborationSettings:
                return (
                  <div className={s.settingsList}>
                    <FormSettingsData
                      className={s.formMaxHeight}
                      id={formId.current}
                      hasErrorsRef={formHasErrorsRef}
                      onSubmit={handleSettingsDataSave}
                      disableLocationField={false}
                      theme="dark"
                      visibleFieldsOrder={
                        CollaborationFormFields[activeCollaborationTab].visibleFieldsOrder
                      }
                      visibleFieldsInfo={
                        CollaborationFormFields[activeCollaborationTab].visibleFieldsInfo
                      }
                    />
                    <div className={s.formButtonsWrapper}>
                      <BaseButton
                        type="submit"
                        className={s.formButton}
                        form={formId.current}
                        disabled={readOnly}>
                        Save
                      </BaseButton>
                    </div>
                  </div>
                );
              case EActiveTabState.Settings:
                return (
                  <div className={s.settingsList}>
                    <FormSettingsData
                      className={s.formMaxHeight}
                      id={formId.current}
                      hasErrorsRef={formHasErrorsRef}
                      onSubmit={handleSettingsDataSave}
                      disableLocationField={false}
                      theme="dark"
                    />
                    <div className={s.formButtonsWrapper}>
                      <BaseButton
                        type="submit"
                        className={s.formButton}
                        form={formId.current}
                        disabled={readOnly}>
                        Save
                      </BaseButton>
                    </div>
                    <div>
                      <BaseButton
                        className={classnames(s.settingsActionButton, s.settingsActionButtonBorder)}
                        onClick={async () => {
                          await resultsDownloadAPI.handleDownload(showDownloadMessage);
                        }}
                        isLoading={resultsDownloadAPI.loading}
                        disabled={simulationStatus !== 'finished'}
                        icon={'download'}>
                        Download
                      </BaseButton>
                      <BaseButton
                        className={s.settingsActionButton}
                        onClick={deleteCommunity}
                        icon={'custom-trash'}
                        disabled={readOnly}>
                        Delete Community
                      </BaseButton>
                    </div>
                  </div>
                );
            }
          })()}
        </>
      </div>
    </>
  );
};
