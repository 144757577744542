import { Currencies } from 'src/graphql';
import { TSimulationResultData } from 'src/hooks/useConfigurationEffects';
import { TConfigurationState } from 'src/redux/configuration/configuration.slice';

export type TChartSavingsProps = {
  className?: string;
  style?: React.CSSProperties;
  currency: Currencies;
  kpi: NonNullable<TConfigurationState['simulationResults']>['kpi'] | undefined;
} & Pick<TSimulationResultData, 'latestSavingsKpi' | 'savingsKpiProfile'>;

export enum EViewType {
  SelfSufficiency = 'Self-Sufficiency',
  SelfConsumption = 'Self-Consumption',
  Savings = 'Savings',
}

export enum ESavingType {
  Earning = 'Earning',
  Neutral = 'Neutral',
  Saving = 'Saving',
  Losses = 'Losses',
}
