import { BaseTooltipTrigger } from 'src/components/BaseTooltip';
import { CanaryRegistryUserTile } from 'src/components/MapSidebar/components/MapSidebarCanary/components/CanaryRegistryList/CanaryRegistryUserTile';
import React from 'react';
import { TCanaryAggregatorInfoProps } from 'src/components/MapSidebar/components/MapSidebarCanary/components/CanaryRegistryList';
import s from './CanaryRegistryList.module.scss';

export const CanaryAggregatorInfo: React.FC<TCanaryAggregatorInfoProps> = ({ aggregator }) => {
  return (
    <div className={s.aggregatorInfo}>
      <header>
        <h4>Your Aggregator</h4>
        <BaseTooltipTrigger
          position="right"
          tooltipChildren="An aggregator is an entity that buys and sells energy for your assets on your behalf according to your preferences">
          <span>What is an aggregator?</span>
        </BaseTooltipTrigger>
      </header>
      {aggregator ? (
        <CanaryRegistryUserTile
          data={{
            name: 'string',
            avatarUrl: '',
            assignedAssets: {},
            isAggregator: true,
            isGridOperator: false,
            joined: true,
          }}
          homesList={[]}
          selected={false}
          onClick={() => true}
        />
      ) : (
        <p>No aggregator</p>
      )}
    </div>
  );
};
