import { ELsKey, ls } from 'src/utils/localStorage';

import { AuthRegisterForms } from 'src/components/AuthRegisterForms';
import { BaseIcon } from 'src/components/BaseIcon';
import React from 'react';
import { TLoginProps } from './Login.types';
import classNames from 'classnames';
import { routesConfig } from 'src/routes/routes.config';
import s from './Login.module.scss';
import { selectSCMFlow } from 'src/redux/scm/scm.selectors';
import { setOnBoardingStep } from 'src/redux/application/application.slice';
import { useAppDispatch } from 'src/redux/store';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

export const Login: React.FC<TLoginProps> = () => {
  const history = useHistory();
  //const mode = getAppMode();
  const dispatch = useAppDispatch();
  const isSCMFlow = useSelector(selectSCMFlow);
  const onSuccessRedirect = () => {
    //const isMobile = mode === 'mobile';
    // if (isMobile) {
    //   return history.push(routesConfig.myCommunities());
    // } else {
    const inviteLink = ls.get(ELsKey.INVITE_LINK);
    if (inviteLink) {
      dispatch(setOnBoardingStep(0)); // closes the modal map hero
      ls.remove(ELsKey.INVITE_LINK);
      history.push(inviteLink);
    }
    if (isSCMFlow) {
      return history.push(routesConfig.scmMap());
    } else {
      return history.push(routesConfig.singularityMap());
    }
    // }
  };

  // if (mode === EAppMode.Mobile) return <MobileWarning />;

  return (
    <div className={classNames(s.container, s.containerBg)}>
      <BaseIcon
        icon="gs-logo-white"
        size={80}
        className={s.logo}
        onClick={() => history.push(routesConfig.home())}
      />
      <AuthRegisterForms onSuccess={() => onSuccessRedirect()} />
    </div>
  );
};
