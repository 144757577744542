import { BaseIcon } from 'src/components/BaseIcon';
import React from 'react';
import { TBaseButtonRoundProps } from './BaseButtonRound.types';
import classnames from 'classnames';
import s from './BaseButtonRound.module.scss';

export const BaseButtonRound: React.FC<TBaseButtonRoundProps> = function BaseButtonRound({
  className,
  icon,
  mirrorContent,
  theme = 'gradient-light',
  color = 'primary',
  disabled,
  children,
  badge,
  onClick,
}) {
  function rootClassnames() {
    let output = `${s.button} ${className}`;

    if (mirrorContent) {
      output += ' ' + s.mirrorContent;
    }

    if (!disabled) {
      const themeClassNames = classnames({
        [`${s.themeBorder}`]: theme === 'border',
        [`${s.themeGradientLight}`]: theme === 'gradient-light',
        [`gradient-node-card`]: theme === 'gradient-node-card',
        [`gradient-dark-modal`]: theme === 'gradient-dark-modal',
      });

      output += ' ' + themeClassNames + ' ' + s[`color-${color}`];
    }

    return output;
  }

  return (
    <button type="button" className={rootClassnames()} disabled={disabled} onClick={onClick}>
      <div className={s.content}>
        {icon ? <BaseIcon icon={icon} className={s.icon} /> : children}
      </div>

      {badge && <div className={`${s.badge} ${s[badge]}`}></div>}
    </button>
  );
};
