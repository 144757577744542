import { Bounce, Fade, Zoom } from 'react-reveal';

import { BlueBox } from 'src/components/LandingPage/BlueBox';
import React from 'react';
import { TProps } from './Exchanges.types';
import { ToolTip } from 'src/pages/MarketDesign/components/ToolTip';
import barExchanges from 'src/assets/images/LandingPage/marketDesign/layerOne_exchanges/bar.png';
import batteryExchanges from 'src/assets/images/LandingPage/marketDesign/layerOne_exchanges/battery.png';
import carExchanges from 'src/assets/images/LandingPage/marketDesign/layerOne_exchanges/car.png';
import chartDayExchanges from 'src/assets/images/LandingPage/marketDesign/layerOne_exchanges/chart-day.png';
import chartTradesExchanges from 'src/assets/images/LandingPage/marketDesign/layerOne_exchanges/chart-trades.png';
import classnames from 'classnames';
import consumptionExchanges from 'src/assets/images/LandingPage/marketDesign/layerOne_exchanges/consumption.png';
import diamondExchange from 'src/assets/images/LandingPage/marketDesign/layerTwo_markets/diamond.png';
import houseExchanges from 'src/assets/images/LandingPage/marketDesign/layerOne_exchanges/house.png';
import lineDiamondsExchanges from 'src/assets/images/LandingPage/marketDesign/layerOne_exchanges/line-diamonds.png';
import linksExchanges from 'src/assets/images/LandingPage/marketDesign/layerOne_exchanges/links.png';
import marketsExchanges from 'src/assets/images/LandingPage/marketDesign/layerOne_exchanges/markets.png';
import ringExchanges from 'src/assets/images/LandingPage/marketDesign/layerOne_exchanges/ring.png';
import s from './Exchanges.module.scss';
//import shadownExchanges from 'src/assets/images/LandingPage/marketDesign/layerOne_exchanges/shadown.png';
import singleCommnunityExchanges from 'src/assets/images/LandingPage/marketDesign/layerOne_exchanges/single-community.png';
import singleDiamondExchanges from 'src/assets/images/LandingPage/marketDesign/layerOne_exchanges/single-diamond.png';
import smallDiamondExchanges from 'src/assets/images/LandingPage/marketDesign/layerOne_exchanges/small-diamond.svg';
import solarPanelExchanges from 'src/assets/images/LandingPage/marketDesign/layerOne_exchanges/solar-panel.png';

export const BlueBoxOne: React.FC<TProps> = ({ show }) => {
  return (
    <BlueBox
      title="Take control of your energy!"
      description="Grid Singularity exchange lets your energy assets trade directly. Your home becomes an
      energy trader in an inclusive, fair market, unlocking your economic, social and
      environmental benefits."
      show={show}
    />
  );
};

export const BlueBoxTwo: React.FC<TProps> = ({ show }) => {
  return (
    <BlueBox
      title="Set your energy preferences"
      description="Your digital agents powered by AI, carry out trades on your behalf, reflecting your
    choices and generating benefits."
      show={show}
    />
  );
};

export const BlueBoxThree: React.FC<TProps> = ({ show }) => {
  return (
    <BlueBox
      title="Build your energy community"
      description="Add a new dimension to your social interaction by choosing your energy trading partners.
    Buy, sell, store, gift, share."
      show={show}
    />
  );
};

export const BlueBoxFour: React.FC<TProps> = ({ show }) => {
  return (
    <BlueBox
      title="Reach sustainability"
      description="Understand energy flows, advance your community and actively contribute to the energy
      transition."
      show={show}
    />
  );
};

export const LightBarBlue: React.FC<TProps> = ({ show }) => {
  return (
    <div className={s.barWrapper}>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={1500}>
        <img src={barExchanges} className={classnames(s.imageFloating, s.lightBarBlue)} />
      </Fade>
    </div>
  );
};

export const ShadowBlue: React.FC<TProps> = ({ show }) => {
  return (
    <Fade opposite collapse={!show} when={show} duration={1000} delay={1500}>
      <div className={classnames(s.imageFloating, s.shadowBlue)} />
    </Fade>
  );
};

export const TooltipHouseBlue: React.FC<TProps> = ({ show }) => {
  return (
    <Fade top collapse={!show} when={show} duration={1000} delay={2500}>
      <div className={s.houseBlueTooltip}>
        <ToolTip position="top" title="Home Energy Assets" />
      </div>
    </Fade>
  );
};

export const HouseBlue: React.FC<TProps> = ({ show }) => {
  return (
    <Zoom opposite collapse={!show} when={show} delay={1500}>
      <div className={s.singleCommunityWrapper}>
        <img src={houseExchanges} className={classnames(s.imageFloating, s.houseBlue)} />
      </div>
    </Zoom>
  );
};

export const SolarPanel: React.FC<TProps> = ({ show }) => {
  return (
    <Bounce opposite collapse={!show} when={show} delay={2100}>
      <img
        src={solarPanelExchanges}
        className={classnames(s.imageFloating, s.solarPanel, s.itemsResize)}
      />
    </Bounce>
  );
};

export const Car: React.FC<TProps> = ({ show }) => {
  return (
    <Bounce opposite collapse={!show} when={show} delay={2100}>
      <img src={carExchanges} className={classnames(s.imageFloating, s.car, s.itemsResize)} />
    </Bounce>
  );
};

export const Battery: React.FC<TProps> = ({ show }) => {
  return (
    <Bounce opposite collapse={!show} when={show} delay={2100}>
      <img
        src={batteryExchanges}
        className={classnames(s.imageFloating, s.battery, s.itemsResize)}
      />
    </Bounce>
  );
};

export const Consumption: React.FC<TProps> = ({ show }) => {
  return (
    <Bounce opposite collapse={!show} when={show} delay={2100}>
      <img
        src={consumptionExchanges}
        className={classnames(s.imageFloating, s.consumption, s.itemsResize)}
      />
    </Bounce>
  );
};

export const TooltipDiamond: React.FC<TProps> = ({ show }) => {
  return (
    <Fade opposite collapse={!show} when={show} duration={1000} delay={1000}>
      <div className={s.diamondTooltip}>
        <ToolTip
          inline={true}
          position="left"
          title={
            <>
              <div>
                <img src={smallDiamondExchanges} />
                <span>Digital Twin</span>
              </div>
              <br />
              <div className={s.descriptionTooltip}>
                Digital representation of
                <br /> your home/community
                <br /> energy assets
              </div>
            </>
          }
        />
      </div>
    </Fade>
  );
};

export const WaveBg: React.FC<TProps> = ({ show }) =>
  show ? <div className={s.waveBg}></div> : <></>;

export const Diamond: React.FC<TProps> = ({ show }) => {
  return (
    <Fade top collapse={!show} when={show} duration={1000}>
      <div className={s.diamondWrapper}>
        <img src={diamondExchange} className={classnames(s.imageFloating, s.diamond)} />
      </div>
    </Fade>
  );
};

export const SingleCommunity: React.FC<TProps> = ({ show }) => (
  <>
    <div className={s.singleCommunityWrapper}>
      <Zoom opposite collapse={!show} when={show} duration={1000}>
        <img
          src={singleCommnunityExchanges}
          className={classnames(s.imageFloating, s.singleCommunity)}
        />
      </Zoom>
    </div>
    <div className={s.singleCommunityWrapper}>
      <Zoom opposite collapse={!show} when={show} duration={1000} delay={500}>
        <img src={linksExchanges} className={classnames(s.imageFloating, s.linksCommunity)} />
      </Zoom>
    </div>
  </>
);

export const TooltipDiamondCommunity: React.FC<TProps> = ({ show }) => {
  return (
    <Fade top opposite collapse={!show} when={show} duration={1000} delay={1500}>
      <div className={s.lineDiamondTooltip}>
        <ToolTip position="top" title="Community Members" />
      </div>
    </Fade>
  );
};

export const LineDiamondCommunity: React.FC<TProps> = ({ show }) => {
  return (
    <div className={s.singleCommunityWrapper}>
      <Zoom opposite collapse={!show} when={show} delay={1000}>
        <img
          src={singleDiamondExchanges}
          className={classnames(s.imageFloating, s.singleDiamondOne)}
        />
      </Zoom>
      <Zoom opposite collapse={!show} when={show} delay={1000}>
        <img
          src={singleDiamondExchanges}
          className={classnames(s.imageFloating, s.singleDiamondTwo)}
        />
      </Zoom>
      <Zoom opposite collapse={!show} when={show} delay={1000}>
        <img
          src={singleDiamondExchanges}
          className={classnames(s.imageFloating, s.singleDiamondThree)}
        />
      </Zoom>
      <Zoom opposite collapse={!show} when={show} delay={1000}>
        <img
          src={singleDiamondExchanges}
          className={classnames(s.imageFloating, s.singleDiamondFour)}
        />
      </Zoom>
      <Zoom opposite collapse={!show} when={show} delay={1000}>
        <img
          src={singleDiamondExchanges}
          className={classnames(s.imageFloating, s.singleDiamondFive)}
        />
      </Zoom>
      <Zoom opposite collapse={!show} when={show} delay={1000}>
        <img
          src={singleDiamondExchanges}
          className={classnames(s.imageFloating, s.singleDiamondSix)}
        />
      </Zoom>
      <Zoom opposite collapse={!show} when={show} delay={1000}>
        <img
          src={singleDiamondExchanges}
          className={classnames(s.imageFloating, s.singleDiamondSeven)}
        />
      </Zoom>
      <Zoom opposite collapse={!show} when={show} delay={1000}>
        <img
          src={singleDiamondExchanges}
          className={classnames(s.imageFloating, s.singleDiamondEight)}
        />
      </Zoom>
      <Zoom collapse={!show} when={show} opposite delay={1000}>
        <img
          src={singleDiamondExchanges}
          className={classnames(s.imageFloating, s.singleDiamondNine)}
        />
      </Zoom>
      <Zoom opposite collapse={!show} when={show} delay={1500}>
        <img src={lineDiamondsExchanges} className={classnames(s.imageFloating, s.lineDiamond)} />
      </Zoom>
    </div>
  );
};

export const TooltipCommunityAppears: React.FC<TProps> = ({ show }) => {
  return (
    <Fade top opposite collapse={!show} when={show} duration={1000} delay={1000}>
      <div className={s.ringTooltip}>
        <ToolTip
          inline={true}
          position="top"
          title={
            <>
              <img src={singleDiamondExchanges} />
              Local Energy Market
              <br />
              <div className={s.descriptionTooltip}>Homes/assets trade</div>
            </>
          }
        />
      </div>
    </Fade>
  );
};

export const CommunityAppears: React.FC<TProps> = ({ show }) => {
  return (
    <>
      <Fade opposite collapse={!show} when={show}>
        <img src={ringExchanges} className={classnames(s.imageFloating, s.ring)} />
      </Fade>
      <Fade opposite collapse={!show} when={show} delay={1500}>
        <img src={chartDayExchanges} className={classnames(s.imageFloating, s.chartDay)} />
      </Fade>
      <Fade opposite collapse={!show} when={show} delay={2000}>
        <img src={chartTradesExchanges} className={classnames(s.imageFloating, s.chartTrades)} />
      </Fade>
    </>
  );
};

export const Subtitle: React.FC<TProps> = ({ show }) => {
  return show ? (
    <div className={s.subtitleWrapper}>
      <div className={s.subtitle}>
        <img src={smallDiamondExchanges} />
        <span>Agents (Digital Twins)</span>
      </div>
      <div className={s.subtitle}>
        <img src={marketsExchanges} />
        <span>Markets (Exchange)</span>
      </div>
    </div>
  ) : (
    <></>
  );
};
