import { EParams, TemplateLandingPage } from 'src/templates/TemplateLandingPage';

import { GenderEqualityPlan } from 'src/pages/GenderEqualityPlan';
import { JoinUs } from 'src/pages/JoinUs';
import { OurApproach } from 'src/pages/OurApproach';
import { OurPrinciple } from 'src/pages/OurPrinciple';
import { OurTeam } from 'src/pages/OurTeam';
import React from 'react';
import { TeamBios } from 'src/pages/TeamBios';
import { useParams } from 'react-router-dom';

export const Company: React.FC = () => {
  const initialRoute = useParams<{
    page: string;
  }>()?.page;

  const pages = {
    [EParams.OUR_APPROACH]: OurApproach,
    [EParams.OUR_PRINCIPLES]: OurPrinciple,
    [EParams.OUR_TEAM]: OurTeam,
    [EParams.JOIN_US]: JoinUs,
    [EParams.GEP]: GenderEqualityPlan,
    [EParams.BIOS]: TeamBios,
  };

  function applyProps() {
    const props = {
      headerColorMode: 'white',
    };

    switch (initialRoute) {
      case EParams.OUR_TEAM:
        props['rightBgColor'] = '#1D191B';
        break;
      default:
        break;
    }

    return props;
  }

  const Component = pages?.[
    initialRoute && initialRoute.length > 0 ? initialRoute : Object.keys(pages)[0]
  ] || <></>;

  return (
    <TemplateLandingPage {...applyProps()}>
      <Component />
    </TemplateLandingPage>
  );
};
