import React from 'react';
import { TBasePillsProps } from './BasePills.types';
import classnames from 'classnames';
import s from './BasePills.module.scss';

export const BasePills: React.FC<TBasePillsProps> = function BasePills({
  className,
  items,
  name,
  value,
  onChange,
  size = 'normal',
  theme = 'green',
}) {
  const handleChange = (value: number) => {
    onChange({ value, name });
  };

  const rootClassNames = () => {
    let output = `${s.pillsWrapper} ${className}`;

    const generatedClassNames = classnames({
      [`${s.themeGreen}`]: theme === 'green',
      [`${s.themeGray}`]: theme === 'gray',
      [`${s.themeDarkGray}`]: theme === 'dark-gray',
      [`${s.sizeLarge}`]: size === 'large',
      [`${s.sizeNormal}`]: size === 'normal',
      [`${s.sizeMini}`]: size === 'mini',
      [`${s.sizeMicro}`]: size === 'micro',
    });

    output += ' ' + generatedClassNames;

    return output;
  };

  return (
    <div className={rootClassNames()}>
      {items.map((item, index) => (
        <button
          type="button"
          key={index}
          name={name}
          onClick={() => handleChange(index)}
          className={`${s.pillButton} ${value === index ? s.active : ''}`}>
          {item}
        </button>
      ))}
    </div>
  );
};
