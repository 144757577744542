import { TAllFieldNames } from 'src/utils/assetsFields/assetsFields.types';
import {
  TFieldValuesByName,
  TValuesByFieldName,
} from 'src/utils/assetsFields/valuesByFieldName.types';
import { findField } from 'src/utils/fieldUtils';
import { TValidators, TValidationObject } from 'src/utils/fieldValidation';
import { TAssetType } from 'src/typings/base-types';

import { TUpdateFields } from 'src/utils/assetsFields/assetsFields.types';
import { TUsedNames } from 'src/typings/configuration.types';

import {
  nameValidator,
  rangeValidator,
  finalSellingRateValidator,
  energyRateDecreasePerUpdateValidator,
  energyRateIncreaseValidator,
  finalBuyingRateValidator,
  buyingRateValidator,
} from './utils';
import { rules } from 'src/utils/fieldValidation';

type TFieldValidationPayload = Pick<
  TUpdateFields,
  'settingsData' | 'fields' | 'isLibrary' | 'configurationCharacteristic'
> & {
  usedAssetsNames: TUsedNames;
  currentValues?: TValuesByFieldName;
};

/*
import { TValidationObject, TValidators, rules } from './validation';

    Validation rules for all types of nodes.
    If given field is absent then it's always valid.
    Validation rules are objects with the following structure:
    {
      r: - validation function
      m: - error message
    }

    Validation function accepts an object argument with the following structure:
    {
      newValue: - any type, value of checked field,
      fields: - array of objects, all fields,
      settingsData: - object, relevant global settings,
      isLibrary: - boolean type, whether the validation is occurring within a library configuration or not
    }
    It should return true when given value is correct and false otherwise.

    Rules are processed in order in which they are declared and error message is set to the one
    corresponding to the first rule that failed (or to null if none of rules failed).

    Swappable fields can be marked as required becasue they are absent
    when they are not displayed (i.e. doesn't have null values).
  */

export const assetTypeToValidator: {
  [assetType in TAssetType]: (payload: TFieldValidationPayload) => TValidators<TAllFieldNames>;
} = {
  /* Area */
  Area: ({ usedAssetsNames, currentValues }) => ({
    name: nameValidator('Name', usedAssetsNames, currentValues?.name),
    timezone: [rules.required('Value')],
    gridFeePercentage: rangeValidator('Value', 0, 100),
    gridFeeConstant: rangeValidator('Value', 0, 100),
    importCapacityKva: rangeValidator('Value', 0, 2147483647),
    exportCapacityKva: rangeValidator('Value', 0, 2147483647),
    coefficientPercentage: rangeValidator('Value', 0, 1),
    baselinePeakEnergyImportKwh: rangeValidator('Value', 0, 2147483647),
    baselinePeakEnergyExportKwh: rangeValidator('Value', 0, 2147483647),
    geoTagLocation: [rules.isGeoTagSet('Location')],
  }),

  /* PV */
  PV: ({ fields, isLibrary, usedAssetsNames, currentValues, configurationCharacteristic }) => ({
    name: nameValidator('Name', usedAssetsNames, currentValues?.name),
    capacityKw: rangeValidator('Capacity', 0, 100000000),
    cloudCoverage: [rules.required('Solar profile')],
    powerProfile: [rules.required('Profile')],
    finalSellingRate: finalSellingRateValidator(
      'Final selling rate',
      isLibrary,
      configurationCharacteristic,
      fields,
    ),
    energyRateDecreasePerUpdate: energyRateDecreasePerUpdateValidator(
      'Energy rate decrease per update',
      isLibrary,
      configurationCharacteristic,
      fields,
    ),
    updateInterval: rangeValidator('Update interval', 1, 60),
    initialSellingRate: rangeValidator('Initial selling rate', 1, 60),
  }),

  /* Storage */
  Storage: ({ fields, isLibrary, usedAssetsNames, currentValues }) => ({
    name: nameValidator('Name', usedAssetsNames, currentValues?.name),
    batteryCapacityKwh: rangeValidator('Battery capacity', 0, 200000),
    initialSoc: [
      ...((n) => [
        rules.required(n),
        rules.integer(n),
        {
          r: ({ newValue }) => {
            const minAllowedSoc = findField(fields, 'minAllowedSoc');
            if (!minAllowedSoc || !minAllowedSoc.value || !newValue) return true;
            return (newValue as number) >= Number(minAllowedSoc.value) && newValue <= 100;
          },
          m: `${n} has to be larger than minimum state of charge and lower than 100.`,
        } as TValidationObject<TFieldValuesByName<'Storage'>['initialSoc']>,
      ])('Initial state of charge'),
    ],
    minAllowedSoc: rangeValidator('Minimum state of charge', 0, 99),
    maxAbsBatteryPowerKw: rangeValidator('Max power rating for battery', 0, 200000),
    initialSellingRate: rangeValidator('Initial selling rate', 0, 10000),
    finalSellingRate: finalSellingRateValidator('Final selling rate', isLibrary, undefined, fields),
    energyRateDecreasePerUpdate: energyRateDecreasePerUpdateValidator(
      'Energy rate decrease per update',
      isLibrary,
      undefined,
      fields,
    ),
    initialBuyingRate: rangeValidator('Initial buying rate', 0, 10000),
    finalBuyingRate: finalBuyingRateValidator(fields),
    energyRateIncreasePerUpdate: energyRateIncreaseValidator(
      'Energy rate increase per update',
      isLibrary,
      undefined,
      fields,
    ),
    updateInterval: rangeValidator('Update interval', 1, 60),
  }),

  /* HeatPump*/
  HeatPump: ({ fields, usedAssetsNames, currentValues }) => ({
    name: nameValidator('Name', usedAssetsNames, currentValues?.name),
    tankVolumeL: [rules.required('tankVolumeL')],
    sourceType: [rules.required('sourceType')],
    initialBuyingRate: rangeValidator('Initial buying rate', 1, 10000),
    updateInterval: rangeValidator('Update interval', 1, 60),
    finalBuyingRate: finalBuyingRateValidator(fields),
  }),

  /* Load */
  Load: ({ fields, isLibrary, usedAssetsNames, currentValues, configurationCharacteristic }) => ({
    name: nameValidator('Name', usedAssetsNames, currentValues?.name),
    avgPowerW: rangeValidator('Average power', 1, 10000000000),
    dailyLoadProfile: [rules.required('Profile')],
    initialBuyingRate: rangeValidator('Initial buying rate', 1, 10000),
    energyRateIncreasePerUpdate: energyRateIncreaseValidator(
      'Energy rate increase per update',
      isLibrary,
      configurationCharacteristic,
      fields,
    ),
    updateInterval: rangeValidator('Update interval', 1, 60),
    finalBuyingRate: finalBuyingRateValidator(fields),
  }),

  /* Finite Diesel Generator (Power Plant) */
  FiniteDieselGenerator: ({ usedAssetsNames, currentValues }) => ({
    name: nameValidator('Name', usedAssetsNames, currentValues?.name),
    energyRate: rangeValidator('Energy rate', 0, 10000),
    maxAvailablePowerKw: rangeValidator('Max available power', 0, 10000000),
  }),

  /* Wind Turbine */
  WindTurbine: ({ fields, isLibrary, usedAssetsNames, currentValues }) => ({
    name: nameValidator('Name', usedAssetsNames, currentValues?.name),
    powerProfile: [rules.required('Profile')],
    updateInterval: rangeValidator('Update interval', 1, 60),
    initialSellingRate: rangeValidator('Initial selling rate', 0, 10000),
    finalSellingRate: finalSellingRateValidator('Final selling rate', isLibrary, undefined, fields),
    energyRateDecreasePerUpdate: energyRateDecreasePerUpdateValidator(
      'Energy rate decrease per update',
      isLibrary,
      undefined,
      fields,
    ),
    capacityKw: rangeValidator('Capacity', 0, 100000000),
  }),

  /* Smart Meter */
  SmartMeter: ({ usedAssetsNames, currentValues }) => ({
    name: nameValidator('Name', usedAssetsNames, currentValues?.name),
    smartMeterProfile: [rules.required('Profile')],
    updateInterval: rangeValidator('Update interval', 1, 60),
  }),

  /* MarketMaker */
  MarketMaker: ({ fields, usedAssetsNames, currentValues }) => ({
    name: nameValidator('Name', usedAssetsNames, currentValues?.name),
    energyRate: rangeValidator('Market maker rate', 0, 10000),
    energyRateProfile: [rules.required('Profile')],
    energyBuyRate: buyingRateValidator('Buying rate', fields),
    buyingRateProfile: [rules.required('Profile')],
  }),

  /* InfiniteBus */
  InfiniteBus: ({ fields, usedAssetsNames, currentValues }) => ({
    name: nameValidator('Name', usedAssetsNames, currentValues?.name),
    energyRate: rangeValidator('Market maker rate', 0, 10000),
    energyRateProfile: [rules.required('Profile')],
    energyBuyRate: buyingRateValidator('Buying rate', fields),
    buyingRateProfile: [rules.required('Profile')],
  }),
};
