import { BACKEND_DATE_FORMATS, UTCMoment } from 'src/utils/UTCMoment';

import { ScatterDataPoint } from 'chart.js';
import { TUseParsedPriceEnergyDayProps } from 'src/components/_charts/ChartPrices/ChartPrices.types';
import { useMemo } from 'react';

export type TUseParsedPriceEnergyDayOutput = ReturnType<typeof useParsedPriceEnergyDay>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useParsedPriceEnergyDay({ data }: TUseParsedPriceEnergyDayProps) {
  const parsedPriceEnergyDay = useMemo(() => {
    const priceEnergyDay = data?.['price-energy-day'];

    if (!priceEnergyDay) return null;

    return priceEnergyDay.reduce(
      (acc, item) => {
        const x = UTCMoment.fromBackend(item.time, BACKEND_DATE_FORMATS.PRICE_ENERGY_DAY).valueOf();

        acc.averageData.push({ x, y: (item.min_price + item.max_price) / 2 });
        acc.minData.push({ x, y: item.min_price });
        acc.maxData.push({ x, y: item.max_price });
        acc.gridFee.push({ x, y: item.grid_fee_constant });

        return acc;
      },
      {
        averageData: [] as ScatterDataPoint[],
        minData: [] as ScatterDataPoint[],
        maxData: [] as ScatterDataPoint[],
        gridFee: [] as ScatterDataPoint[],
      },
    );
  }, [data]);

  return parsedPriceEnergyDay;
}
