function detectMob() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
    /IEMobile/i,
    /Opera Mini/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

export const getAppMode = (): EAppMode => {
  const isMobile = detectMob();
  if (isMobile) {
    return EAppMode.Mobile;
  }
  return EAppMode.Desktop;
};

export enum EAppMode {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop',
}
