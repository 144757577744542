import {
  TAssetFieldTemplatesArgs,
  TFieldsTemplateUnion,
  TNumberFieldTemplate,
  TSettingsData,
  TTextFieldTemplate,
} from 'src/utils/assetsFields/assetsFields.types';
import { TConfigurationCharacteristic } from 'src/typings/configuration.types';

import { EFormVariant } from 'src/typings/base-types';
import { SWITCHER_ICON_SIZES } from 'src/constants/application';

export type TWindTurbinePhoenixArgs = {
  isLibrary: boolean;
  settingsData: TSettingsData;
  values: TAssetFieldTemplatesArgs['values'];
  configurationCharacteristic: TConfigurationCharacteristic;
};

export const WindTurbinePhoenix = ({
  isLibrary,
  settingsData,
  configurationCharacteristic,
  values,
}: TWindTurbinePhoenixArgs): TFieldsTemplateUnion[] => {
  return [
    {
      name: 'libraryUUID',
      formView: EFormVariant.Express,
    },
    {
      name: 'name',
      type: 'text',
      label: 'Name',
      formView: EFormVariant.Express,
    },
    {
      name: 'geoTagLocation',
      type: 'location',
      label: 'Location',
      tooltipText: 'Parent market already has location',
      EXCLUDE: isLibrary,
      formView: EFormVariant.Express,
    },
    {
      name: 'capacityKw',
      type: 'number',
      label: 'Capacity',
      formView: EFormVariant.Advanced,
      unit: 'kWp',
    },
    {
      name: 'initialSellingRate',
      type: 'number',
      label: 'Initial Selling Rate',
      unit: 'cents / kWh',
      formView: EFormVariant.Advanced,
    },
    {
      name: 'finalSellingRate',
      type: 'number',
      label: 'Final Selling Rate',
      unit: 'cents / kWh',
      formView: EFormVariant.Advanced,
    },
    {
      name: 'energyRateDecreasePerUpdate',
      type:
        configurationCharacteristic.gridMakerHasUploadedProfile && values?.fitToLimit
          ? 'text'
          : 'number',
      label: 'Rate Decrease',
      unit: 'cents / kWh per update',
      disabled: configurationCharacteristic.gridMakerHasUploadedProfile && values?.fitToLimit,
      formView: EFormVariant.Advanced,
    } as TNumberFieldTemplate | TTextFieldTemplate,
    {
      name: 'powerProfile',
      type: 'file',
      label: 'Upload Profile',
      tooltipText: 'Please upload wind turbine profile here',
      formView: EFormVariant.Advanced,
      EXCLUDE: false,
    },
    {
      name: 'powerProfileUuid',
      formView: EFormVariant.Advanced,
    },
    {
      name: 'fitToLimit',
      type: 'checkbox',
      label: 'Linear Pricing',
      formView: EFormVariant.Advanced,
    },
    {
      name: 'updateInterval',
      type: 'slider',
      label: 'Update Interval',
      unit: 'min',
      showUnit: true,
      step: 1,
      minVal: 1,
      maxVal: settingsData.slotLengthMinutes - 1,
      formView: EFormVariant.Advanced,
    },
    {
      name: 'allowExternalConnection',
      type: 'switcher',
      options: [
        { icon: 'close', iconSize: SWITCHER_ICON_SIZES.close, value: false },
        { icon: 'check-mark', iconSize: SWITCHER_ICON_SIZES.tick, value: true },
      ],
      label: 'API open',
      formView: EFormVariant.CanaryNetwork,
      disabled: true,
    },
  ];
};
