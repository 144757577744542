import { $Keys } from 'utility-types';
import { TConfigurationState } from 'src/redux/configuration/configuration.slice';
import { TFormSettingsDataProps } from 'src/components/FormSettingsData';

export type TMapSidebarResultsProps = {
  className?: string;
  style?: React.CSSProperties;
  showHeaderTabs?: boolean;
  onSettingsDataSave?: TFormSettingsDataProps['onSubmit'];
  onCommunityRemove?({
    projectUuid,
  }: {
    projectUuid: NonNullable<TConfigurationState['projectUuid']>;
  }): Promise<void>;
};

export enum ECommunityTab {
  GridMarket = 'GRID_MARKET',
  GridAgent = 'GRID_AGENT',
}

export enum ECollaborationTab {
  Basic = 'Basic',
  Advanced = 'Advanced',
  Grid = 'Grid',
}

export type TPresentationNames =
  | 'KeyResults'
  | 'KeyResultsSCM'
  | 'AssetCount'
  | 'SS-SC'
  | 'ChartEnergyTradeProfile'
  | 'ChartDayProfile'
  | 'ChartPrices'
  | 'TradesBidsAndOffers'
  | 'TableEnergyBillsAndNetTraded'
  | 'AssetHighlight'
  | 'ChartSavings'
  | 'ChartAsset'
  | 'ChartAssetAgent'
  | 'ChartDeviceProfile'
  | 'EnergyShare';

export enum EActiveTabState {
  Community = 'community',
  Settings = 'settings',
  Registry = 'registry',
  CollaborationSettings = 'collaborationSettings',
}

export type TKeysCollaborationTab = $Keys<typeof ECollaborationTab>;

export type TCollaborationFields = Record<
  Extract<TKeysCollaborationTab, 'Basic' | 'Advanced'>,
  Pick<TFormSettingsDataProps, 'visibleFieldsInfo' | 'visibleFieldsOrder'>
>;
