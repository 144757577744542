import { Currencies } from 'src/graphql';
import { UTCMoment } from 'src/utils/UTCMoment';

export const formatter = {
  prefixWithZeros(value: number): string {
    if (value === 0) return '0';

    const base = 10;
    const absVal = Math.abs(value);

    if (absVal < base) {
      const sign = value < 0 ? '-' : '';

      return `${sign}0${absVal}`;
    }

    return String(value);
  },

  toOptionalFixed(value: number, digits = 3): number {
    if (!value) return value;
    let tmp = value;
    const res = value.toString().split('.');
    if (!res[1]) return tmp;

    if (res[1].length > digits) {
      tmp = +tmp.toFixed(digits);
    } else {
      tmp = +tmp.toFixed(res[1].length);
    }
    return tmp;
  },

  formatCents(value: number): string {
    return (value / 100).toFixed(2);
  },

  formatStartEndDate(
    timestamp: string | number,
    type: 'start' | 'end',
    format: 'iso' | 'timestamp' = 'iso',
  ): string | number {
    const momentDate = UTCMoment.fromUnix(timestamp);
    const requestedDate = type === 'start' ? momentDate : momentDate.add(15, 'minutes');

    if (format === 'timestamp') {
      return requestedDate.unix();
    }

    // remove timezone (BE does not support it - occurs a Graphql error)
    const momentToIso = requestedDate.format();
    const formatted = momentToIso.substring(0, momentToIso.length - 1);

    return formatted;
  },

  getCurrencySymbol(currency: Currencies): string {
    switch (currency) {
      case 'AUD':
      case 'CAD':
      case 'USD':
        return '$';
      case 'CHF':
        return 'CHF';
      case 'CNY':
      case 'JPY':
        return '¥';
      case 'EUR':
        return '€';
      case 'GBP':
        return '£';
      default:
        return '€';
    }
  },
};
