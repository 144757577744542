import { EScreenOrientation } from 'src/typings/configuration.types';

/*
  This function takes in the screen width and height and returns the screen orientation
  based on the screen width and height.
*/
export const calculateScreenOrientation = (
  screenWidth: number,
  screenHeight: number,
): EScreenOrientation => {
  if (screenWidth > screenHeight) {
    return EScreenOrientation.Landscape;
  }
  return EScreenOrientation.Portrait;
};
