export enum EMenuType {
  DEFAULT = 'default',
  RESOURCES = 'resources',
}
export type TMainMenuProps = {
  items: TMenuItems[];
  cbRoute: (routeName: string | null) => void;
  menuType: EMenuType;
};
export type TMenuItems = {
  route: string;
  title: string;
  initial?: boolean;
  hidden?: boolean;
};
