import { EAccordionType, TCustomPVProps } from './CustomPV.types';
import React, { useState } from 'react';

import { BaseIcon } from 'src/components/BaseIcon';
import { Orientation } from './components/Orientation';
import classnames from 'classnames';
import s from './CustomPV.module.scss';

export const CustomPV: React.FC<TCustomPVProps> = ({
  className,
  capacityFields,
  tradingFields,
  azimuthValuesForCustomPV,
  setAzimuthValuesForCustomPV,
  showOrientationSection,
  theme = 'dark',
}) => {
  const [openTabs, setOpenTabs] = useState<string[]>([]);

  const onTabClick = (tab: string) => {
    const tempTabs = [...openTabs];
    if (openTabs.includes(tab)) {
      const index = tempTabs.findIndex((x) => x === tab);
      tempTabs.splice(index, 1);
    } else {
      tempTabs.push(tab);
    }
    setOpenTabs([...tempTabs]);
  };

  const IsTabOpen = (tab: string) => openTabs.includes(tab);

  const Accordion = ({ name }) => (
    <div className={classnames(s.accordion)} onClick={() => onTabClick(name)}>
      <div className={s.title}>{name}</div>
      <BaseIcon
        className={classnames(s.arrowIcon, {
          [s.arrowOpen]: IsTabOpen(name),
        })}
        icon="chevron-down"
        size={12}
      />
    </div>
  );

  return (
    <div className={classnames(s.customPV, className, s[theme])}>
      <div className={s.container}>
        <Accordion name={EAccordionType.capacityAndProfile} />
        {IsTabOpen(EAccordionType.capacityAndProfile) && capacityFields}
      </div>
      {showOrientationSection && (
        <div className={s.container}>
          <Accordion name={EAccordionType.orientation} />
          {IsTabOpen(EAccordionType.orientation) && (
            <Orientation
              azimuthValuesForCustomPV={azimuthValuesForCustomPV}
              setAzimuthValuesForCustomPV={setAzimuthValuesForCustomPV}
              theme={theme}
            />
          )}
        </div>
      )}
      {tradingFields.length > 0 && (
        <div className={s.container}>
          <Accordion name={EAccordionType.tradingStrategy} />
          {IsTabOpen(EAccordionType.tradingStrategy) && tradingFields}
        </div>
      )}
    </div>
  );
};
