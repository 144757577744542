import { EButtonType } from './CollaborationArrowButtons.types';

import { BaseButtonRound } from 'src/components/BaseButtonRound';
import { BaseIcon } from 'src/components/BaseIcon';
import React, { useCallback } from 'react';
import s from './CollaborationArrowButtons.module.scss';
import { useAppDispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';
import { selectCollaborationStep } from 'src/redux/application/application.selectors';
import { MODALCOLLABORATIONSTEPCOUNT } from 'src/components/ModalCollaborationDeploy/ModalCollaborationDeployConstants';
import { setCollaborationStep } from 'src/redux/application/application.slice';

export const CollaborationArrowButons: React.FC = () => {
  const dispatch = useAppDispatch();
  const buttonStep = useSelector(selectCollaborationStep);

  const handleArrowButtonClick = useCallback<(buttonType: EButtonType) => void>(
    (buttonType) => {
      if (buttonType === EButtonType.next && buttonStep + 1 < MODALCOLLABORATIONSTEPCOUNT) {
        dispatch(setCollaborationStep(buttonStep + 1));
        return;
      }

      if (buttonType === EButtonType.prev && buttonStep > 0) {
        dispatch(setCollaborationStep(buttonStep - 1));
      }
    },
    [buttonStep, dispatch],
  );

  return (
    <div className={s.container}>
      <div className={s.simActionButtons}>
        <BaseButtonRound
          className={s.actionButton}
          theme="border"
          disabled={buttonStep === 0}
          onClick={() => handleArrowButtonClick(EButtonType.prev)}>
          <BaseIcon icon="arrow-modern-prev" size={10} />
        </BaseButtonRound>

        <BaseButtonRound
          className={s.actionButton}
          theme="border"
          disabled={buttonStep === MODALCOLLABORATIONSTEPCOUNT - 1}
          onClick={() => handleArrowButtonClick(EButtonType.next)}>
          <BaseIcon className={s.collabIcon} icon="arrow-modern-next" size={10} />
        </BaseButtonRound>
      </div>
    </div>
  );
};
