import React from 'react';
import s from './BurguerButton.module.scss';
interface IProps {
  open: boolean;
  onClick: (value: boolean) => void;
}

export function BurguerButton({ open, onClick }: IProps): JSX.Element {
  return (
    <div className={s.menu_btn} onClick={() => onClick(!open)}>
      <div className={`${s.menu_btn__burger} ${open ? s.active_menu_btn__burger : ''}`}></div>
    </div>
  );
}
