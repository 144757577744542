import {
  TAllFieldNames,
  TAssetFieldTemplatesArgs,
  TFieldsUnionWithValue,
} from 'src/utils/assetsFields/assetsFields.types';
import {
  getAllFieldTemplatesForAsset,
  getSingleFieldTemplateForAsset,
} from 'src/utils/assetsFields/fieldTemplates';

import { TConfigurationState } from 'src/redux/configuration/configuration.slice';
import { TFieldValue } from 'src/typings/base-types';
import { fieldValues } from 'src/utils/assetsFields/fieldValues';
import { isNull } from 'lodash';

/*
 *
 * This function returns all asset's templates with values injected
 *
 */
export function getAllFieldTemplatesWithValuesForAsset({
  type,
  settingsData,
  isLibrary,
  configType,
  values,
  configurationCharacteristic,
  includeAll = false,
  isSCM,
  isCustomLoad,
  isCustomPV,
}: TAssetFieldTemplatesArgs): TFieldsUnionWithValue[] {
  const computedValues = fieldValues.assetsFields({
    type,
    settingsData,
    isLibrary,
    configurationCharacteristic,
    values,
    configType,
    isCustomLoad,
    isCustomPV,
  });

  let fieldTemplates = getAllFieldTemplatesForAsset({
    type,
    settingsData,
    isLibrary,
    values: computedValues,
    configType,
    configurationCharacteristic,
    isSCM,
  });

  if (!includeAll) {
    fieldTemplates = fieldTemplates.filter((f) => !f.EXCLUDE);
  }

  const output: TFieldsUnionWithValue[] = [];
  fieldTemplates.forEach((field) => {
    if (computedValues && field.name in computedValues) {
      const value = computedValues[field.name] as TFieldValue;

      if (!isNull(value)) {
        // @ts-ignore
        output.push({
          ...field,
          value,
        });
      }
    }
  });

  return output;
}

/*
 *
 * This function returns single asset's templates with values injected
 *
 */
export function getSingleFieldTemplateWithValueForAsset({
  value,
  disabled,
  ...args
}: TAssetFieldTemplatesArgs & {
  name: TAllFieldNames;
  disabled?: boolean;
  value?: TFieldValue;
}): TFieldsUnionWithValue | undefined {
  const defaultValues = fieldValues.assetsFields({
    type: args.type,
    settingsData: args.settingsData,
    isLibrary: args.isLibrary,
    configurationCharacteristic: args.configurationCharacteristic,
  });

  const fieldTemplate = getSingleFieldTemplateForAsset(args);
  const finalValue = typeof value === 'undefined' ? defaultValues[args.name] : value;

  if (!fieldTemplate) return;

  // @ts-ignore
  return {
    ...fieldTemplate,
    disabled,
    value: finalValue,
  };
}

type TAssetValues = TConfigurationState['assetsValues']['assetType'];
export function getAssetValues(fields: TFieldsUnionWithValue[]): TAssetValues {
  return fields.reduce((acc, item) => {
    // @ts-ignore
    acc[item.name] = item.value;
    return acc;
  }, {} as TAssetValues);
}
