import { Chart, ChartDataset, ScatterDataPoint } from 'chart.js';
import { EChartTooltips, getChartTooltip } from 'src/constants/chartTooltips';
import React, { useMemo, useRef, useState } from 'react';

import { AxisXCustom } from 'src/components/_charts/AxisXCustom/AxisXCustom';
import { BaseTags } from '../../BaseTags';
import { ChartDataLoadingWrapper } from 'src/components/ChartDataLoadingWrapper';
import { ChartDayProfileColors } from 'src/constants/chart';
import { ChartWrapper } from 'src/components/ChartWrapper';
import { EChartName } from 'src/components/_charts/chartsData';
import { TChartDayProfileProps } from './ChartDayProfile.types';
import { UTCMoment } from 'src/utils/UTCMoment';
import { formatter } from 'src/utils/formatter';
import { selectSettingsData } from 'src/redux/configuration/configuration.selectors';
import { useChartJS } from 'src/hooks/useChartJS';
import { useSelector } from 'react-redux';

export const ChartDayProfile: React.FC<TChartDayProfileProps> = ({
  marketSummary: data,
  netEnergyData,
  gridFeeData,
  startUnix,
  endUnix,
}) => {
  const marketSummary = useMemo(() => data || [], [data]);
  const [inactiveItems, setInactiveItems] = useState<number[]>([2, 3]);
  const chartRef = useRef<Chart | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const { currency } = useSelector(selectSettingsData);

  const parsedMarketSummary = useMemo(() => {
    return marketSummary.reduce(
      (acc, item) => {
        const x = UTCMoment.fromBackend(item.timestamp).valueOf();

        acc.tradedVolumeData.push({ x, y: item.tradedVolume });
        acc.averageEnergyRateData.push({
          x,
          y: +formatter.formatCents(item.averageEnergyRate || 0),
        });

        return acc;
      },
      {
        tradedVolumeData: [] as ScatterDataPoint[],
        averageEnergyRateData: [] as ScatterDataPoint[],
      },
    );
  }, [marketSummary]);

  const datasets = useMemo(() => {
    const commonDatasetOptions: Partial<ChartDataset<'line'>> = {
      backgroundColor: 'transparent',
      borderWidth: 1,
      borderCapStyle: 'square',
      borderJoinStyle: 'miter',
    };

    return [
      {
        ...commonDatasetOptions,
        data: parsedMarketSummary.tradedVolumeData,
        borderColor: ChartDayProfileColors.volumeLine,
        backgroundColor: ChartDayProfileColors.volumeLine,
        // yAxisID: 'yVolumeTrades',
        hidden: inactiveItems.includes(0),
        label: JSON.stringify({
          title: 'Volume',
          unit: 'kWh',
        }),
      },
      {
        ...commonDatasetOptions,
        data: netEnergyData,
        borderColor: ChartDayProfileColors.tradesLine,
        backgroundColor: ChartDayProfileColors.tradesLine,
        // yAxisID: 'yVolumeTrades',
        hidden: inactiveItems.includes(1),
        label: JSON.stringify({
          title: 'Net Energy',
          unit: 'kWh',
        }),
      },
      {
        ...commonDatasetOptions,
        data: parsedMarketSummary.averageEnergyRateData,
        borderColor: ChartDayProfileColors.pricesLine,
        backgroundColor: ChartDayProfileColors.pricesLine,
        // yAxisID: 'yPricesAndFees',
        hidden: inactiveItems.includes(2),
        label: JSON.stringify({
          title: 'Price',
          unit: 'kWh',
          currency,
        }),
      },
      {
        ...commonDatasetOptions,
        data: gridFeeData || [],
        borderColor: ChartDayProfileColors.gridFeeLine,
        backgroundColor: ChartDayProfileColors.gridFeeLine,
        // yAxisID: 'yPricesAndFees',
        hidden: inactiveItems.includes(3),
        label: JSON.stringify({
          title: 'Grid Fee',
          unit: 'kWh',
          currency,
        }),
      },
    ];
  }, [
    parsedMarketSummary.tradedVolumeData,
    parsedMarketSummary.averageEnergyRateData,
    inactiveItems,
    netEnergyData,
    currency,
    gridFeeData,
  ]);

  useChartJS(
    EChartName.DayProfile,
    canvasRef,
    chartRef,
    {
      datasets,
    },
    {
      chartOptions: {
        scales: {
          x: {
            max: endUnix * 1000,
            min: startUnix * 1000,
          },
          y: {
            ticks: {
              display: true,
              font: {
                size: 11,
                weight: 'bold',
              },
              color: '#34363f',
            },
          },
        },
      },
      datasetsLengthWillChange: true,
    },
  );

  const chartDayProfileItems = [
    { name: 'Volume', color: ChartDayProfileColors.volumeLine },
    { name: 'Net Energy', color: ChartDayProfileColors.tradesLine },
    { name: 'Price', color: ChartDayProfileColors.pricesLine },
    { name: 'Grid Fee', color: ChartDayProfileColors.gridFeeLine },
  ];

  return (
    <ChartWrapper
      title="Summary"
      info={getChartTooltip(
        EChartTooltips.TOOLTIP_DAY_PROFILE,
        formatter.getCurrencySymbol(currency),
      )}
      footerComponent={
        <BaseTags
          items={chartDayProfileItems}
          name="baseTags1"
          value={inactiveItems}
          onChange={({ value }) => {
            setInactiveItems(value);
          }}
        />
      }>
      <ChartDataLoadingWrapper loading={!marketSummary.length}>
        <canvas ref={canvasRef} />

        <AxisXCustom startUnix={startUnix} endUnix={endUnix} style={{ marginLeft: 15 }} />
      </ChartDataLoadingWrapper>
    </ChartWrapper>
  );
};
