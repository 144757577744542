import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import React, { useState } from 'react';

import { BaseGenericModal } from 'src/components/BaseGenericModal';
import { TModalProfilePictureCropperProps } from './ModalProfilePictureCropper.types';
import { closeModal } from 'src/redux/modals/modals.slice';
import s from './ModalProfilePictureCropper.module.scss';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { useAppDispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';
import { BaseClose } from 'src/components/BaseClose';
import { BaseButton } from 'src/components/BaseButton';
import { ImageCropper } from 'src/components/ImageCropper';

export const ModalProfilePictureCropper: React.FC<TModalProfilePictureCropperProps> = ({
  originalFile,
  isSubmiting,
  handleSubmit,
}) => {
  const [triggerGetCroppedFile, setTriggerGetCroppedFile] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const isModalOpened = useSelector(
    selectIsModalOpened(EPredefinedModalIds.MODAL_PROFILE_PICTURE_CROPPER),
  );

  function closeAuthModal() {
    dispatch(closeModal(EPredefinedModalIds.MODAL_PROFILE_PICTURE_CROPPER));
  }

  const callback = (newFile) => {
    newFile.then((result) => {
      handleSubmit(result);
    });
    setTriggerGetCroppedFile(false);
  };

  if (!isModalOpened) return null;

  return (
    <>
      <div className={s.background} />
      <BaseGenericModal
        className={s.pictureCropperModal}
        modalId={EPredefinedModalIds.MODAL_PROFILE_PICTURE_CROPPER}
        size={EModalSize.M280}>
        <BaseClose
          className={s.closeBtn}
          onClick={() => {
            closeAuthModal();
          }}
        />
        <div className={s.wrapper}>
          <header className={s.header}>
            <h1>Please reframe</h1>
          </header>
          <div className={s.body}>
            <ImageCropper
              file={originalFile}
              triggerGetCroppedFile={triggerGetCroppedFile}
              parentCallback={callback}
              maxSizes={{ width: 300.0, height: 300.0 }}
            />
            <p className={s.desctiprion}>Make sure to place your face in the middle</p>
          </div>
          <footer className={s.footerBtns}>
            <BaseButton
              isLoading={isSubmiting}
              size="small"
              onClick={() => {
                setTriggerGetCroppedFile(true);
              }}>
              Save
            </BaseButton>
            <BaseButton
              theme="grey3"
              size="small"
              onClick={() => {
                closeAuthModal();
              }}>
              Cancel
            </BaseButton>
          </footer>
        </div>
      </BaseGenericModal>
    </>
  );
};
