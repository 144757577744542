import { EMenuType, TMenuItems } from 'src/components/LandingPage/MainMenu/MainMenu.types';

export type TTemplateLandingPageProps = {
  className?: string;
  headerColorMode: string;
  rightBgColor?: string;
  menuType?: EMenuType;
};

export type TMenu = {
  default: TMenuItems[];
  resources: TMenuItems[];
};

export enum EParams {
  OUR_APPROACH = 'our-approach',
  OUR_PRINCIPLES = 'our-principle',
  OUR_TEAM = 'our-team',
  JOIN_US = 'join-us',
  READ = 'read',
  TRENDING = 'trending',
  WATCH = 'watch',
  TALK_TO_US = 'talk-to-us',
  NEWS = 'news',
  GEP = 'gender-equality-plan',
  BIOS = 'bios',
}
