export enum EDataGridAsssetFilter {
  All = 'ALL',
  MyLibrary = 'MY_LIBRARY',
  GsyLibrary = 'GSY_LIBRARY',
  PublicLibrary = 'PUBLIC_LIBRARY',
}

export type TDashboardAssetsProps = {
  className?: string;
  style?: React.CSSProperties;
  //
  selectedItem?: string;
  onAssetSelect(uuid?: string): void;
};
