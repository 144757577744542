import { Login } from '../Login';
import React from 'react';
import { TLoginProps } from './DesktopLogin.types';
import s from './DesktopLogin.module.scss';

export const DesktopLogin: React.FC<TLoginProps> = () => {
  return (
    <div className={s.container}>
      <div className={s.loginContainer}>
        <Login />
      </div>
    </div>
  );
};
