import { InfoHelper } from '../InfoHelper';
import React from 'react';
import { TChartWrapperProps } from './ChartWrapper.types';
import classNames from 'classnames';
import s from './ChartWrapper.module.scss';

export const ChartWrapper: React.FC<TChartWrapperProps> = ({
  title,
  info,
  titleRightSlotComponent,
  headerComponent,
  footerComponent,
  className,
  headerClassName,
  contentClassName,
  children,
  onMouseLeave,
}) => {
  return (
    <div className={classNames(s.container, className)}>
      <div className={classNames(s.header, headerClassName)}>
        {title && (
          <div className={s.headerContent}>
            <h3>{title}</h3>
            {info && <InfoHelper info={info} />}
            <div className={s.mla}>{titleRightSlotComponent}</div>
          </div>
        )}
        {headerComponent}
      </div>
      <div className={classNames(s.content, contentClassName)} onMouseLeave={onMouseLeave}>
        {children}
      </div>
      {footerComponent && <div className={s.footer}>{footerComponent}</div>}
    </div>
  );
};
