import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import React, { useContext } from 'react';

import { BaseButton } from 'src/components/BaseButton';
import ContentfulContext from 'src/contexts/ContentfulContext';
import { ContentfulContextProvider } from 'src/contexts/ContentfulContext';
import { FadeAnimationHelper } from 'src/components/AnimationHelpers/FadeAnimationHelper/FadeAnimationHelper';
import { Header } from 'src/components/Header';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import s from './TermsofService.module.scss';
import { useHistory } from 'react-router';

export const TermsofService: React.FC = () => {
  const headerColorMode = 'white';
  const history = useHistory();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_, children) => <p>{children}</p>,
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a href={`${node.data.uri}`} rel="noreferrer" target="_blank">
            {children}
          </a>
        );
      },
    },
  };

  const RenderPrivacyPolicy = () => {
    const { termsOfService } = useContext(ContentfulContext);
    if (!termsOfService?.content || !termsOfService?.title) return null;

    return (
      <>
        <div className={s.heading}>{termsOfService?.title}</div>
        <div className={s.text}>
          {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            documentToReactComponents(termsOfService?.content as any)
          }
        </div>
      </>
    );
  };

  return (
    <ContentfulContextProvider>
      <Header isLandingPage headerColorMode={headerColorMode} />
      <div className={s.content}>
        <BaseButton
          className={s.buttonBack}
          theme="quaternary"
          icon="arrow-left-full"
          onClick={() => history.goBack()}>
          BACK
        </BaseButton>
        <FadeAnimationHelper key="terms-of-service" className={s.container} delay={0.5}>
          <div></div>
          <RenderPrivacyPolicy />
        </FadeAnimationHelper>
      </div>
    </ContentfulContextProvider>
  );
};
