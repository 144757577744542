import {
  selectInitialCommunity,
  selectIsLoggedIn,
  selectTokenAuth,
} from 'src/redux/auth/auth.selectors';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router';

import { EUserRoles } from 'src/typings/base-types';
import { routesConfig } from 'src/routes/routes.config';
import { selectSCMFlow } from 'src/redux/scm/scm.selectors';
import { setInitialCommunity } from 'src/redux/auth/auth.slice';
import { useAppDispatch } from 'src/redux/store';
import { useGetOperationalLazyQuery } from 'src/graphql';
import { useSelector } from 'react-redux';

export type TUseInitialSCMCommunityReturn = {
  checkInitialCommunity: () => string | null;
};

export const useInitialSCMCommunity = (): TUseInitialSCMCommunityReturn => {
  const dispatch = useAppDispatch();
  const isSCMFlow = useSelector(selectSCMFlow);
  const userInfo = useSelector(selectTokenAuth);
  const isResearcher = useMemo(() => userInfo?.userRole === EUserRoles.Researcher, [userInfo]);
  const initialCommunity = useSelector(selectInitialCommunity);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const history = useHistory();

  const [
    getScmMemberOperationalUuid,
    { data: ScmMemberOperationalUuid },
  ] = useGetOperationalLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {},
  });

  const scmResultsMatch = useRouteMatch({
    path: routesConfig.scmMapResults(''),
    strict: true,
  });

  useEffect(() => {
    if (isResearcher && isSCMFlow && !initialCommunity) {
      getScmMemberOperationalUuid();
    }
  }, [
    isResearcher,
    isSCMFlow,
    initialCommunity,
    getScmMemberOperationalUuid,
    ScmMemberOperationalUuid,
  ]);
  useEffect(() => {
    // const configurations = allOperationalSCMNetworks?.listScmCommunities?.configurations;
    // const lastCommunity = configurations?.[configurations.length - 1];
    if (
      ScmMemberOperationalUuid &&
      ScmMemberOperationalUuid?.getScmMemberOperationalUuid &&
      isLoggedIn
    ) {
      dispatch(setInitialCommunity(ScmMemberOperationalUuid?.getScmMemberOperationalUuid));
    }
  }, [isLoggedIn, dispatch, ScmMemberOperationalUuid]);

  const checkInitialCommunity = useCallback(() => {
    if (isResearcher && isSCMFlow && initialCommunity) {
      return initialCommunity;
    }
    return null;
  }, [isResearcher, isSCMFlow, initialCommunity]);

  useEffect(() => {
    if (isResearcher && isSCMFlow && initialCommunity && scmResultsMatch?.isExact !== true) {
      return history.push(routesConfig.scmMapResults(initialCommunity));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResearcher, isSCMFlow, initialCommunity, history, scmResultsMatch]);

  return { checkInitialCommunity };
};
