import { ApolloError } from '@apollo/client';
import { useMemo } from 'react';

type TUseGraphqlMessageProps = {
  error: ApolloError | undefined;
};

export function useGraphQLMessage({ error }: TUseGraphqlMessageProps): string {
  const formattedMessage = useMemo(() => {
    let output = '';
    const br = '<br/>';

    if (!error) return output;

    const { graphQLErrors } = error;

    if (!graphQLErrors) return output;

    graphQLErrors.forEach((error) => {
      try {
        const parsed = JSON.parse(error.message);
        const isMessageAnObject = typeof parsed === 'object' && parsed !== null;
        if (parsed && isMessageAnObject) {
          Object.values(parsed).forEach((val) => {
            output += val + br;
          });
        } else {
          output += error.message + br;
        }
      } catch (e) {
        // This means that error.message is not a JSON string.
        output += error.message + br;
      }
    });

    return output.slice(0, -br.length);
  }, [error]);

  return formattedMessage;
}
