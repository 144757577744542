import { EUserRoles, TRenamedUserRoles } from 'src/typings/base-types';

export const getFrontendUserRoleName = (userRole: EUserRoles | string): TRenamedUserRoles => {
  const renamingMap = {
    DSO: 'Grid Operator',
    Researcher: 'GSy User',
  };

  return renamingMap[userRole] || userRole;
};
