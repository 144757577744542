import { EUserRoles } from 'src/typings/base-types';
import { selectUserRole } from 'src/redux/auth/auth.selectors';
import { useSelector } from 'react-redux';

export const useIsUserACommunityMember = (): {
  isUserACommunityMember: boolean;
  isAdmin: boolean;
  userRole?: EUserRoles;
} => {
  const userRole = useSelector(selectUserRole);

  const isUserACommunityMember =
    userRole === EUserRoles.Aggregator ||
    userRole === EUserRoles.Researcher ||
    userRole === EUserRoles.DSO;

  const isAdmin = userRole === EUserRoles.Admin || userRole === EUserRoles.ExchangeOperator;

  return { isUserACommunityMember, isAdmin, userRole };
};
