import {
  useDuplicateCanaryNetworkMutation,
  useDuplicateCollaborationMutation,
  useDuplicateCommunityMutation,
} from 'src/graphql';

import { setActiveConfigurationUuid } from 'src/redux/configuration/configuration.slice';
import { useAppDispatch } from 'src/redux/store';

type TUseDuplicateCommunityReturn = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getDuplicationName: (configuration: any, items: any) => string;
  duplicateCanaryNetwork: (configurationName: string, configurationUuid: string) => Promise<void>;
  duplicateCommunity: (configurationName: string, configurationUuid: string) => Promise<void>;
  duplicateCollaboration: (configurationName: string, configurationUuid: string) => Promise<void>;
  duplicateConfigLoading: boolean;
  duplicateCNLoading: boolean;
  duplicateCollaborationLoading: boolean;
};

export const useDuplicateCommunity = (): TUseDuplicateCommunityReturn => {
  const dispatch = useAppDispatch();

  const [
    duplicateCanaryNetworkMutation,
    { loading: duplicateCNLoading },
  ] = useDuplicateCanaryNetworkMutation();
  const [
    duplicateCommunityMutation,
    { loading: duplicateConfigLoading },
  ] = useDuplicateCommunityMutation();

  const [
    duplicateCollaborationMutation,
    { loading: duplicateCollaborationLoading },
  ] = useDuplicateCollaborationMutation();

  //TODO: Configuration List state is an Array<any>
  //so, I made the type of the configuration parameter "any",
  //We have to fix the configuration List type
  const getDuplicationName = (configuration, items) => {
    const splited = (configuration.name as string).split(' ');
    const originalName =
      splited.indexOf('Duplication') > 0
        ? splited.slice(0, splited.indexOf('Duplication')).join(' ')
        : configuration.name;

    const itemDuplications = items.filter(
      (item) => item.name.includes(originalName) && item.name.includes('Duplication'),
    );

    return `${originalName} Duplication ${itemDuplications.length + 1}`;
  };

  const duplicateCanaryNetwork = async (configurationName: string, configurationUuid: string) => {
    const { data } = await duplicateCanaryNetworkMutation({
      variables: {
        name: configurationName,
        description: '',
        uuid: configurationUuid,
      },
    });
    if (data?.duplicateCanaryNetwork?.uuid) {
      redirectToDuplicated(data.duplicateCanaryNetwork.uuid);
    }
  };

  const duplicateCommunity = async (configurationName: string, configurationUuid: string) => {
    const { data } = await duplicateCommunityMutation({
      variables: {
        name: configurationName,
        description: '',
        configurationUuid: configurationUuid,
      },
    });
    if (data?.duplicateCommunity?.uuid) {
      redirectToDuplicated(data.duplicateCommunity.uuid);
    }
  };

  const duplicateCollaboration = async (configurationName: string, configurationUuid: string) => {
    const { data } = await duplicateCollaborationMutation({
      variables: {
        name: configurationName,
        description: '',
        uuid: configurationUuid,
      },
    });
    if (data?.duplicateCollaboration?.uuid) {
      redirectToDuplicated(data.duplicateCollaboration.uuid);
    }
  };

  const redirectToDuplicated = (uuid: string) => {
    dispatch(setActiveConfigurationUuid(uuid));
  };

  return {
    getDuplicationName,
    duplicateCanaryNetwork,
    duplicateCommunity,
    duplicateCollaboration,
    duplicateConfigLoading,
    duplicateCNLoading,
    duplicateCollaborationLoading,
  };
};
