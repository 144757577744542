import { Fade, Slide } from 'react-reveal';

import { Loader } from 'src/components/LoadingWrapper';
import React from 'react';
import { TProps } from './Simulation.types';
import addHouseSimulation from 'src/assets/images/LandingPage/marketDesign/layerFour_simulation/add-house.png';
import assetsSimulation from 'src/assets/images/LandingPage/marketDesign/layerFour_simulation/assets.svg';
import barSimulation from 'src/assets/images/LandingPage/marketDesign/layerFour_simulation/bar.png';
import boxCreateCommSimulation from 'src/assets/images/LandingPage/marketDesign/layerFour_simulation/box-create-community.svg';
import boxTitleCommSimulation from 'src/assets/images/LandingPage/marketDesign/layerFour_simulation/box-title-community.svg';
import chartEnergyTradeSimulation from 'src/assets/images/LandingPage/marketDesign/layerFour_simulation/chart-energy-trade.svg';
import classnames from 'classnames';
import communityAllHouseSimulation from 'src/assets/images/LandingPage/marketDesign/layerFour_simulation/community-all-house.png';
import communityOneHouseSimulation from 'src/assets/images/LandingPage/marketDesign/layerFour_simulation/community-one-house.png';
import grayCommunitySimulation from 'src/assets/images/LandingPage/marketDesign/layerFour_simulation/gray-community.png';
import houseSimulation from 'src/assets/images/LandingPage/marketDesign/layerFour_simulation/house.png';
import linksCommunitySimulation from 'src/assets/images/LandingPage/marketDesign/layerFour_simulation/links.png';
import mobileScreenSimulation from 'src/assets/images/LandingPage/marketDesign/layerFour_simulation/mobile-screen.png';
import netEnergySimulation from 'src/assets/images/LandingPage/marketDesign/layerFour_simulation/net-energy.png';
import s from './Simulation.module.scss';
import shadownSimulation from 'src/assets/images/LandingPage/marketDesign/layerFour_simulation/shadown.png';
import singleLinkSimulation from 'src/assets/images/LandingPage/marketDesign/layerFour_simulation/single-link.png';
import tooltipSimulation from 'src/assets/images/LandingPage/marketDesign/layerFour_simulation/tooltip-add.svg';
import triangleFutureSimulation from 'src/assets/images/LandingPage/marketDesign/layerFour_simulation/triangle-future.png';
import trianglePresentPastSimulation from 'src/assets/images/LandingPage/marketDesign/layerFour_simulation/triangle-present-past.png';
import useMarketDesignContext from 'src/hooks/useMarketDesignContext';

const ChartNetEnergySimulation = React.lazy(
  () => import('src/components/LandingPage/LazyImages/ChartNetEnergySimulation'),
);

export const BlueBoxOne: React.FC<TProps> = ({ show }) => {
  return (
    <Fade bottom collapse={!show} when={show} duration={2000} delay={3000}>
      <div className={s.boxContent}>
        <div className={s.boxText}>
          <h3>Simulate your energy future!</h3>
          <p>
            Create a digital representation of your home and community assets using historical or
            approximate template data.
          </p>
        </div>
      </div>
    </Fade>
  );
};

export const BlueBoxTwo: React.FC<TProps> = ({ show }) => {
  return (
    <Fade bottom collapse={!show} when={show} duration={2000} delay={3000}>
      <div className={s.boxContent}>
        <div className={s.boxText}>
          <h3>Simulate your energy future!</h3>
          <p>
            Create a digital representation of your home and community assets using historical or
            approximate template data.
          </p>
        </div>
      </div>
    </Fade>
  );
};

export const BlueBoxThree: React.FC<TProps> = ({ show }) => {
  return (
    <Fade bottom collapse={!show} when={show} duration={2000} delay={3000}>
      <div className={s.boxContent}>
        <div className={s.boxText}>
          <h3>Simulate your energy future!</h3>
          <p>
            Create a digital representation of your home and community assets using historical or
            approximate template data.
          </p>
        </div>
      </div>
    </Fade>
  );
};

export const BlueBoxFour: React.FC<TProps> = ({ show }) => {
  return (
    <Fade bottom collapse={!show} when={show} duration={2000} delay={3000}>
      <div className={s.boxContent}>
        <div className={s.boxText}>
          <h3>Simulate your energy future!</h3>
          <p>
            Create a digital representation of your home and community assets using historical or
            approximate template data.
          </p>
        </div>
      </div>
    </Fade>
  );
};

export const GrayBoxOne: React.FC<TProps> = ({ show }) => {
  const { isDocVisible } = useMarketDesignContext();
  return (
    <>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={500}>
        <div
          className={classnames(s.grayBox, {
            [s.grayBoxForDoc]: isDocVisible,
          })}>
          <div className={s.grayBoxText}>
            <p>Begin by choosing your location on the map.</p>
          </div>
        </div>
      </Fade>
    </>
  );
};

export const GrayBoxTwo: React.FC<TProps> = ({ show }) => {
  const { isDocVisible } = useMarketDesignContext();
  return (
    <>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={500}>
        <div
          className={classnames(s.grayBox, {
            [s.grayBoxForDoc]: isDocVisible,
          })}>
          <div className={s.grayBoxText}>
            <p>
              Add consumption profiles, solar panels and batteries to represent your home and
              community
            </p>
          </div>
        </div>
      </Fade>
    </>
  );
};

export const GrayBoxThree: React.FC<TProps> = ({ show }) => {
  const { isDocVisible } = useMarketDesignContext();
  return (
    <>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={500}>
        <div
          className={classnames(s.grayBox, {
            [s.grayBoxForDoc]: isDocVisible,
          })}>
          <div className={s.grayBoxText}>
            <p>
              Grow your community to 50 homes and 1000 assets to evaluate configuration and any
              future investments
            </p>
          </div>
        </div>
      </Fade>
    </>
  );
};

export const GrayBoxFour: React.FC<TProps> = ({ show }) => {
  const { isDocVisible } = useMarketDesignContext();
  return (
    <>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={500}>
        <div
          className={classnames(s.grayBox, {
            [s.grayBoxForDoc]: isDocVisible,
          })}>
          <div className={s.grayBoxText}>
            <p>
              Analyse the simulation results to understand your potential energy production, savings
              and other benefits of energy trading.
            </p>
          </div>
        </div>
      </Fade>
    </>
  );
};

export const SimulationOne: React.FC<TProps> = ({ show }) => {
  return (
    <>
      <Slide opposite collapse={!show} when={show} duration={1000}>
        <img
          src={grayCommunitySimulation}
          className={classnames(s.imageFloating, s.graycCommunity)}
        />
      </Slide>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={1500}>
        <img src={barSimulation} className={classnames(s.imageFloating, s.lightBarBlue)} />
      </Fade>
      <Fade opposite collapse={!show} when={show} duration={1000}>
        <img src={shadownSimulation} className={classnames(s.imageFloating, s.shadownBlue)} />
      </Fade>
      <Slide opposite collapse={!show} when={show} duration={1000}>
        <img src={houseSimulation} className={classnames(s.imageFloating, s.house)} />
      </Slide>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={1000}>
        <img
          src={boxTitleCommSimulation}
          className={classnames(s.imageFloating, s.boxTitleCommSimulation)}
        />
      </Fade>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={1000}>
        <img
          src={boxCreateCommSimulation}
          className={classnames(s.imageFloating, s.boxCreateCommSimulation)}
        />
      </Fade>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={2000}>
        <img src={tooltipSimulation} className={classnames(s.imageFloating, s.tooltipSimulation)} />
      </Fade>
      <Slide opposite collapse={!show} when={show} duration={1000}>
        <img src={mobileScreenSimulation} className={classnames(s.imageFloating, s.mobileScreen)} />
      </Slide>
    </>
  );
};

export const SimulationTwo: React.FC<TProps> = ({ show }) => {
  return (
    <>
      <Slide opposite collapse={!show} when={show} duration={1000}>
        <img
          src={communityOneHouseSimulation}
          className={classnames(s.imageFloating, s.graycCommunity)}
        />
      </Slide>
      <Slide opposite collapse={!show} when={show} duration={1000}>
        <img src={barSimulation} className={classnames(s.imageFloating, s.lightBarBlue)} />
      </Slide>
      <Slide opposite collapse={!show} when={show} duration={1000}>
        <img src={shadownSimulation} className={classnames(s.imageFloating, s.shadownBlue)} />
      </Slide>
      <Slide opposite collapse={!show} when={show} duration={1000}>
        <img src={houseSimulation} className={classnames(s.imageFloating, s.house)} />
      </Slide>
      <Slide opposite collapse={!show} when={show} duration={1000}>
        <img src={mobileScreenSimulation} className={classnames(s.imageFloating, s.mobileScreen)} />
      </Slide>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={500}>
        <img src={addHouseSimulation} className={classnames(s.imageFloating, s.addHouse)} />
      </Fade>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={1000}>
        <img src={singleLinkSimulation} className={classnames(s.imageFloating, s.singleLink)} />
      </Fade>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={1000}>
        <img src={barSimulation} className={classnames(s.imageFloating, s.lightBarBlueTwo)} />
      </Fade>
    </>
  );
};

export const SimulationThree: React.FC<TProps> = ({ show }) => {
  return (
    <>
      <Slide opposite collapse={!show} when={show} duration={1000}>
        <img
          src={communityAllHouseSimulation}
          className={classnames(s.imageFloating, s.graycCommunity)}
        />
      </Slide>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={1000}>
        <img src={barSimulation} className={classnames(s.imageFloating, s.lightBarBlue)} />
      </Fade>
      <Slide opposite collapse={!show} when={show} duration={1000}>
        <img src={shadownSimulation} className={classnames(s.imageFloating, s.shadownBlue)} />
      </Slide>
      <Slide opposite collapse={!show} when={show} duration={1000} delay={1000}>
        <img src={houseSimulation} className={classnames(s.imageFloating, s.house)} />
      </Slide>
      <Slide opposite collapse={!show} when={show} duration={1000}>
        <img src={mobileScreenSimulation} className={classnames(s.imageFloating, s.mobileScreen)} />
      </Slide>
      <Slide opposite collapse={!show} when={show} duration={1000}>
        <img src={linksCommunitySimulation} className={classnames(s.imageFloating, s.links)} />
      </Slide>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={1500}>
        <img src={barSimulation} className={classnames(s.imageFloating, s.lightBarBlueTwo)} />
      </Fade>
    </>
  );
};

export const SimulationFour: React.FC<TProps> = ({ show }) => {
  return (
    <>
      <Fade opposite collapse={!show} when={show} duration={1000}>
        <img
          src={communityAllHouseSimulation}
          className={classnames(s.imageFloating, s.graycCommunity)}
        />
      </Fade>
      <Slide opposite collapse={!show} when={show} duration={1000} delay={1500}>
        <img src={barSimulation} className={classnames(s.imageFloating, s.lightBarBlue)} />
      </Slide>
      <Slide opposite collapse={!show} when={show} duration={1000}>
        <img src={shadownSimulation} className={classnames(s.imageFloating, s.shadownBlue)} />
      </Slide>
      <Slide opposite collapse={!show} when={show} duration={1000}>
        <img src={houseSimulation} className={classnames(s.imageFloating, s.house)} />
      </Slide>
      <Slide opposite collapse={!show} when={show} duration={1000}>
        <img src={mobileScreenSimulation} className={classnames(s.imageFloating, s.mobileScreen)} />
      </Slide>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={2000}>
        <img src={linksCommunitySimulation} className={classnames(s.imageFloating, s.links)} />
      </Fade>
      <Slide opposite collapse={!show} when={show} duration={1000}>
        <img src={barSimulation} className={classnames(s.imageFloating, s.lightBarBlueTwo)} />
      </Slide>
      <div className={`${s.simulationWrapper} ${s.levelSimulation}`}>
        <Fade opposite collapse={!show} when={show} duration={1000} delay={1000}>
          <React.Suspense fallback={<Loader />}>
            <ChartNetEnergySimulation className={classnames(s.imageFloating, s.chartNetEnergy)} />
          </React.Suspense>
        </Fade>
        <Fade opposite collapse={!show} when={show} duration={1000} delay={1000}>
          <img src={netEnergySimulation} className={classnames(s.imageFloating, s.netEnergy)} />
        </Fade>
      </div>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={1500}>
        <img src={assetsSimulation} className={classnames(s.imageFloating, s.assets)} />
      </Fade>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={2000}>
        <img
          src={chartEnergyTradeSimulation}
          className={classnames(s.imageFloating, s.chartEnergyTrade)}
        />
      </Fade>
    </>
  );
};

export const SimulationBox: React.FC<TProps> = ({ show, scrollTo, currentLayer }) => {
  return (
    <div className={s.simulationBoxContent}>
      {[11, 12, 13, 14]?.includes(currentLayer) && (
        <Fade opposite collapse={!show} when={show} duration={1000}>
          <div className={s.triangle}>
            <img src={triangleFutureSimulation} />
          </div>
        </Fade>
      )}

      {[15, 16]?.includes(currentLayer) && (
        <Fade opposite collapse={!show} when={show} duration={1000}>
          <div className={s.triangle_2}>
            <img src={trianglePresentPastSimulation} />
          </div>
        </Fade>
      )}

      <Fade opposite collapse={!show} when={show} duration={1000}>
        <div className={s.boxSimulation}>
          {[11, 12, 13, 14]?.includes(currentLayer) && (
            <div className={s.boxTitle}>
              <span>Simulation</span>
            </div>
          )}

          {[15, 16]?.includes(currentLayer) && (
            <div className={s.boxTitle}>
              <span>Canary Test Network</span>
            </div>
          )}

          <div className={s.simulation}>
            <button
              type="button"
              className={`${
                [11, 12, 13, 14].includes(currentLayer) ? s.activedBtnSimulation_1 : ''
              } ${s.button}`}
              onClick={() => scrollTo(11)}>
              FORECAST
              <span>Future</span>
            </button>
            <button
              type="button"
              className={`${
                ![11, 12, 13, 14].includes(currentLayer) ? s.activedBtnSimulation_2 : ''
              } ${s.button}`}
              onClick={() => scrollTo(15)}>
              REAL-TIME
              <span>Present</span>
            </button>
            <button
              type="button"
              className={`${
                ![11, 12, 13, 14].includes(currentLayer) ? s.activedBtnSimulation_3 : ''
              } ${s.button}`}
              onClick={() => scrollTo(15)}>
              HISTORICAL
              <span>Past</span>
            </button>
          </div>
        </div>
      </Fade>
    </div>
  );
};
