import { BlueBox } from 'src/components/LandingPage/BlueBox';
import { Fade } from 'react-reveal';
import React from 'react';
import { TProps } from './ApiLayer.types';
import { ToolTip } from 'src/pages/MarketDesign/components/ToolTip';
import aggregatorApi from 'src/assets/images/LandingPage/marketDesign/layerThree_api/aggregator-api.png';
import apiCommunityApi from 'src/assets/images/LandingPage/marketDesign/layerThree_api/api-community.png';
import apiStyleOneApi from 'src/assets/images/LandingPage/marketDesign/layerThree_api/api-style-one.png';
import apiStyleTwoApi from 'src/assets/images/LandingPage/marketDesign/layerThree_api/api-style-two.png';
import basePlaqueApi from 'src/assets/images/LandingPage/marketDesign/layerThree_api/base-plaque.png';
import classnames from 'classnames';
import gridOperadorApi from 'src/assets/images/LandingPage/marketDesign/layerThree_api/grid-operador.png';
import linesPlaqueApi from 'src/assets/images/LandingPage/marketDesign/layerThree_api/lines-plaque.png';
import linkApi from 'src/assets/images/LandingPage/marketDesign/layerThree_api/link.png';
import linkBaseApi from 'src/assets/images/LandingPage/marketDesign/layerThree_api/links.png';
import linksPlaqueApi from 'src/assets/images/LandingPage/marketDesign/layerThree_api/links-plaque.png';
import marketIconMarket from 'src/assets/images/LandingPage/marketDesign/layerTwo_markets/market.png';
import marketsExchanges from 'src/assets/images/LandingPage/marketDesign/layerOne_exchanges/markets.png';
import middlePlaqueApi from 'src/assets/images/LandingPage/marketDesign/layerThree_api/middle-plaque.png';
import ringApi from 'src/assets/images/LandingPage/marketDesign/layerThree_api/ring-api.png';
import s from './ApiLayer.module.scss';
import singleCommunityApi from 'src/assets/images/LandingPage/marketDesign/layerThree_api/single-community.png';
import squarePlaqueApi from 'src/assets/images/LandingPage/marketDesign/layerThree_api/square-plaque.png';
import topPlaqueApi from 'src/assets/images/LandingPage/marketDesign/layerThree_api/top-plaque.png';

export const BlueBoxOne: React.FC<TProps> = ({ show }) => {
  return (
    <BlueBox
      title="Dynamic Grid Management"
      description="The <span>Grid Operator API</span> allows implementation of dynamic grid fees to reward
      local flexibility and effectively manage grid congestion."
      show={show}
    />
  );
};

export const BlueBoxTwo: React.FC<TProps> = ({ show }) => {
  return (
    <BlueBox
      title="Accessing Degrees of Freedom"
      description="<span>The Asset API</span> allows aggregators to connect energy assets to exchanges and
    activate peer-to-peer and community trading, enabling user choices."
      show={show}
    />
  );
};

export const WaveBackground: React.FC<TProps> = ({ show }) => {
  return (
    <Fade opposite collapse={!show} when={show} duration={3000}>
      <div className={s.waveBackground} />
    </Fade>
  );
};

export const ApiOne: React.FC<TProps> = ({ show }) => {
  return (
    <>
      <Fade opposite collapse={!show} when={show} duration={1000}>
        <img src={basePlaqueApi} className={classnames(s.imageFloating, s.basePlaqueApi)} />
      </Fade>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={1000}>
        <img src={linesPlaqueApi} className={classnames(s.imageFloating, s.linesPlaqueApi)} />
      </Fade>
      <Fade top opposite collapse={!show} when={show} duration={1000} delay={1000}>
        <img src={squarePlaqueApi} className={classnames(s.imageFloating, s.squareOnePlaqueApi)} />
      </Fade>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={1500}>
        <img src={apiStyleTwoApi} className={classnames(s.imageFloating, s.apiStyleOneApi)} />
      </Fade>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={1200}>
        <img src={middlePlaqueApi} className={classnames(s.imageFloating, s.middlePlaqueApi)} />
      </Fade>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={1000}>
        <img src={linksPlaqueApi} className={classnames(s.imageFloating, s.linksPlaqueApi)} />
      </Fade>
      <Fade top opposite collapse={!show} when={show} duration={1000} delay={1000}>
        <img src={squarePlaqueApi} className={classnames(s.imageFloating, s.squareTwoPlaqueApi)} />
      </Fade>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={1500}>
        <img src={apiStyleOneApi} className={classnames(s.imageFloating, s.apiStyleTwoApi)} />
      </Fade>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={1400}>
        <img src={topPlaqueApi} className={classnames(s.imageFloating, s.topPlaqueApi)} />
      </Fade>
      <Fade top opposite collapse={!show} when={show} duration={1000} delay={1000}>
        <img
          src={squarePlaqueApi}
          className={classnames(s.imageFloating, s.squareThreePlaqueApi)}
        />
      </Fade>
      <Fade opposite collapse={!show} when={show} delay={1500}>
        <img src={apiStyleOneApi} className={classnames(s.imageFloating, s.apiStyleThreeApi)} />
      </Fade>
    </>
  );
};

export const SubtitleApiOne: React.FC<TProps> = ({ show }) => {
  return show ? (
    <div className={s.subtitleWrapper}>
      <div className={s.subtitle}>
        <img src={gridOperadorApi} />
        <span>Grid Operator API</span>
      </div>
      <div className={s.subtitle}>
        <img src={marketsExchanges} />
        <span>Markets (Exchange)</span>
      </div>
    </div>
  ) : (
    <></>
  );
};

export const TooltipApiTwo: React.FC<TProps> = ({ show }) => {
  return (
    <Fade top opposite collapse={!show} when={show} duration={1000} delay={3000}>
      <div className={s.communityTooltip}>
        <ToolTip
          inline={true}
          position="top"
          title={
            <>
              <span>
                <img src={marketIconMarket} />
                Local Market
              </span>
            </>
          }
        />
      </div>
    </Fade>
  );
};

export const SubtitleApiTwo: React.FC<TProps> = ({ show }) => {
  return show ? (
    <div className={s.subtitleWrapper}>
      <div className={s.subtitle}>
        <img src={aggregatorApi} />
        <span>Aggregator API</span>
      </div>
      <div className={s.subtitle}>
        <img src={marketsExchanges} />
        <span>Markets (Exchange)</span>
      </div>
    </div>
  ) : (
    <></>
  );
};

export const ApiTwo: React.FC<TProps> = ({ show }) => {
  return (
    <div className={s.singleCommunityApiWrapper}>
      <Fade opposite collapse={!show} when={show} duration={1000}>
        <img
          src={singleCommunityApi}
          className={classnames(s.imageFloating, s.singleCommunityApi)}
        />
      </Fade>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={1500}>
        <img src={apiCommunityApi} className={classnames(s.imageFloating, s.apiCommunityApi)} />
      </Fade>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={1000}>
        <img src={ringApi} className={classnames(s.imageFloating, s.ringApi)} />
      </Fade>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={1200}>
        <img src={linkApi} className={classnames(s.imageFloating, s.linkApi)} />
      </Fade>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={1200}>
        <img src={linkBaseApi} className={classnames(s.imageFloating, s.linkBaseApi)} />
      </Fade>
    </div>
  );
};
