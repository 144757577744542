import {
  EConfigurationFlowState,
  useConfigurationFlowState,
} from 'src/hooks/useConfigurationFlowState';
import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Route, Switch, matchPath, useHistory } from 'react-router';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import {
  selectActiveConfigurationUuid,
  selectCommunityAsset,
  selectSimulationResults,
} from 'src/redux/configuration/configuration.selectors';
import {
  selectIsEmbed,
  selectModalMapHeroDismissed,
  selectSidebarExpanded,
} from 'src/redux/application/application.selectors';
import {
  setModalMapHeroDismissed,
  setSidebarExpanded,
} from 'src/redux/application/application.slice';

import { BaseGenericModal } from 'src/components/BaseGenericModal';
import { ConfigurationManagementCentral } from '../ConfigurationManagementCentral';
import { MapSidebarCommunitiesList } from 'src/components/MapSidebar/components/MapSidebarCommunitiesList';
import { MapSidebarControls } from 'src/components/MapSidebar/components/MapSidebarControls';
import { MapSidebarNavigationBar } from 'src/components/MapSidebar/components/MapSidebarNavigationBar';
import { MapSidebarResults } from 'src/components/MapSidebar/components/MapSidebarResults';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';
import { TMapSidebarProps } from './MapSidebar.types';
import classnames from 'classnames';
import { routesConfig } from 'src/routes/routes.config';
import s from './MapSidebar.module.scss';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { selectTutorialStep } from 'src/redux/application/application.selectors';
import { useAppDispatch } from 'src/redux/store';
import { useAppLocation } from 'src/hooks/useAppLocation';
import { useSelector } from 'react-redux';

export const MapSidebar: React.FC<TMapSidebarProps> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useAppLocation();
  const isEmbed = useSelector(selectIsEmbed);
  const communityAssetUuid = useSelector(selectCommunityAsset)?.uuid || '';
  const simulationResults = useSelector(selectSimulationResults);
  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
  const sidebarExpanded = useSelector(selectSidebarExpanded);
  const sidebarModalOpened = useSelector(
    selectIsModalOpened(EPredefinedModalIds.MODAL_MAP_SIDEBAR),
  );
  const { sidebarOpen, configurationFlowState } = useConfigurationFlowState();
  const modalMapHeroDismissed = useSelector(selectModalMapHeroDismissed);
  const tutorialStep = useSelector(selectTutorialStep);

  const toggleModal = useCallback(
    (value: boolean) => {
      if (value) {
        dispatch(openModal(EPredefinedModalIds.MODAL_MAP_SIDEBAR));
      } else {
        dispatch(closeModal(EPredefinedModalIds.MODAL_MAP_SIDEBAR));
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (sidebarModalOpened && !modalMapHeroDismissed) {
      dispatch(setModalMapHeroDismissed(true));
    }
  }, [dispatch, modalMapHeroDismissed, sidebarModalOpened]);

  const closeButtonControl = [
    {
      icon: 'arrow-left' as TIconNames,
      iconSize: 12,
      onClick: () => toggleModal(!sidebarModalOpened),
      disabled: configurationFlowState === EConfigurationFlowState.CreateNewCommunity,
    },
  ];

  const viewControls = useMemo(() => {
    if (!isEmbed) {
      return [
        {
          icon: 'list' as TIconNames,
          iconSize: 17,
          active: !!matchPath(location.pathname, {
            path: routesConfig.singularityMap(),
            exact: true,
          }),
          disabled: !!activeConfigurationUuid,
        },
        {
          icon: 'bar-chart' as TIconNames,
          iconSize: 17,
          onClick: () => {
            history.push(
              isEmbed
                ? routesConfig.embed(activeConfigurationUuid)
                : routesConfig.singularityMapResults(activeConfigurationUuid, communityAssetUuid),
            );
          },
          active: !!matchPath(location.pathname, {
            path: isEmbed
              ? routesConfig.embed(activeConfigurationUuid)
              : routesConfig.singularityMapResults(activeConfigurationUuid, communityAssetUuid),
          }),
          disabled: !activeConfigurationUuid && !simulationResults,
          disabledTooltip: 'Select a community to view results',
        },
      ];
    }

    return [];
  }, [
    isEmbed,
    location.pathname,
    activeConfigurationUuid,
    communityAssetUuid,
    simulationResults,
    history,
  ]);

  useEffect(() => {
    toggleModal(sidebarOpen);
  }, [sidebarOpen, toggleModal]);

  useEffect(() => {
    const isSimulationResultsPath = !!matchPath(location.pathname, {
      path: routesConfig.singularityMapResults('', ''),
    });

    if (!isSimulationResultsPath) {
      dispatch(setSidebarExpanded(false));
    }
  }, [dispatch, location.pathname]);

  return (
    <>
      <MapSidebarNavigationBar />

      <Switch>
        <Route path={routesConfig.singularityMap()}>
          <BaseGenericModal
            className={classnames(s.container, { [s.containerEmbed]: isEmbed })}
            closedClassName={s.closed}
            size={sidebarExpanded ? EModalSize.XL795 : EModalSize.L370}
            style={{
              zIndex: tutorialStep === 1 ? 99999 : 100,
              pointerEvents: tutorialStep === 1 ? 'none' : 'auto',
            }}
            modalId={EPredefinedModalIds.MODAL_MAP_SIDEBAR}>
            <MapSidebarControls className={s.closeControls} buttons={closeButtonControl} />
            {sidebarModalOpened && (
              <MapSidebarControls className={s.viewControls} buttons={viewControls} />
            )}

            <>
              <Switch>
                <Route
                  // Use simulations list also for /create route
                  path={`(${routesConfig.singularityMap()}|${routesConfig.singularityMapCreate()})`}
                  exact>
                  <MapSidebarCommunitiesList />
                </Route>
                <ConfigurationManagementCentral>
                  {({ handleSettingsDataSave, handleCommunityRemove }) => {
                    return (
                      <>
                        <Route path={routesConfig.singularityMapResults('', '')}>
                          <MapSidebarResults
                            onSettingsDataSave={handleSettingsDataSave}
                            onCommunityRemove={handleCommunityRemove}
                          />
                        </Route>
                        <Route path={routesConfig.embed('')}>
                          <MapSidebarResults
                            onSettingsDataSave={handleSettingsDataSave}
                            onCommunityRemove={handleCommunityRemove}
                          />
                        </Route>
                      </>
                    );
                  }}
                </ConfigurationManagementCentral>
              </Switch>
            </>
          </BaseGenericModal>
        </Route>
      </Switch>
    </>
  );
};
