import { ArrayElement, EUserRoles } from 'src/typings/base-types';
import React, { useContext, useMemo, useState } from 'react';
import { selectUserRole, selectUsername } from 'src/redux/auth/auth.selectors';

import { BaseIcon } from 'src/components/BaseIcon';
import { BaseSelect } from 'src/components/BaseSelect';
import { BaseTooltipTrigger } from 'src/components/BaseTooltip';
import { CanaryNetworkServiceContext } from 'src/components/CanaryNetworkServiceProvider/CanaryNetworkServiceProvider';
import { ProfilePictureIcon } from 'src/components/UserAvatar';
import { TCanaryRegistryAssetTileProps } from 'src/components/MapSidebar/components/MapSidebarCanary/components/CanaryRegistryList';
import classNames from 'classnames';
import s from './CanaryRegistryList.module.scss';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import { useSelector } from 'react-redux';

type TOwnerItem = ArrayElement<TCanaryRegistryAssetTileProps['data']['owners']>;

export const CanaryRegistryAssetTile: React.FC<TCanaryRegistryAssetTileProps> = ({
  data,
  usersList,
  onClick,
  selected,
  childrenCount,
  isChildren,
}) => {
  const [respondSent, setRespondSent] = useState(false);
  const userName = useSelector(selectUsername);
  const userRole = useSelector(selectUserRole);
  const configurationUuid = useSelector(selectActiveConfigurationUuid);
  const canaryNetworkServiceContext = useContext(CanaryNetworkServiceContext);

  const isAdmin = userRole === EUserRoles.Admin;
  const isAggregator = userRole === EUserRoles.Aggregator;
  const showSelectBox = data.owners.filter((item) => !item.isAggregator).length === 0 && isAdmin;

  const assignUserToAsset = ({ value }) => {
    const user = usersList.find((item) => item.uuid === value);
    if (user) {
      canaryNetworkServiceContext.approveExternalConnection(
        configurationUuid!,
        data.uuid,
        user.name,
        user.name,
      );
    }
  };

  const unregisterUser = (e, { name, isAggregator }: TOwnerItem) => {
    e.stopPropagation();
    canaryNetworkServiceContext.unregisterUser(
      configurationUuid!,
      data.uuid,
      name,
      isAggregator,
      name === userName,
    );
  };

  const onTileClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    onClick?.(data);
  };

  const respondToApplyRequest = async (apply: boolean) => {
    if (!data.appliedUserName || !configurationUuid) return;
    setRespondSent(true);
    await canaryNetworkServiceContext.respondExternalConnectionRequest(
      apply,
      data.uuid,
      configurationUuid!,
      data.appliedUserName,
    );
    setRespondSent(false);
  };

  const owners = useMemo(() => {
    return data.owners.sort((a, b) => (a.isAggregator !== b.isAggregator ? 1 : 0));
  }, [data.owners]);

  const isTriggerForAggregatorOrAdmin = (
    item: ArrayElement<TCanaryRegistryAssetTileProps['data']['owners']>,
  ) => !item.isGridOperator && (isAdmin || (isAggregator && item.name === userName));

  return (
    <div
      className={classNames(s.assetTile, {
        [s.isChildren]: isChildren,
        [s.clickable]: !!onClick,
        [s.selected]: selected,
      })}
      onClick={onTileClick}>
      <div className={s.icon}>
        <BaseIcon icon={data.icon} size={12} />
      </div>
      <p className={s.name}>{data.name}</p>
      {!!childrenCount && userRole === EUserRoles.Admin && data.status !== 'Applied' && (
        <div className={s.childAssetsCount}>
          <span>{childrenCount} Assets</span>
          <BaseIcon icon="eye" size={12} />
        </div>
      )}
      {owners && (
        <div
          className={classNames(s.owners, {
            [s.hoverable]: isAdmin || isAggregator,
          })}>
          {owners.map((item) =>
            isTriggerForAggregatorOrAdmin(item) ? (
              <BaseTooltipTrigger
                key={item.id}
                tooltipChildren={`Unregister ${item.name}`}
                position="right">
                <div
                  className={classNames(s.avatar, {
                    [s.hasAvatar]: !!item.avatarUrl,
                  })}>
                  <ProfilePictureIcon avatarUrl={item.avatarUrl} name={item.name} />
                  <button type="button" title="Unregister" onClick={(e) => unregisterUser(e, item)}>
                    <BaseIcon icon="close" size={10} />
                  </button>
                </div>
              </BaseTooltipTrigger>
            ) : (
              <ProfilePictureIcon
                avatarUrl={item.avatarUrl}
                name={item.name}
                className={{ nameInitials: s.tileAvatar, picture: s.tileAvatar }}
              />
            ),
          )}
        </div>
      )}
      {data.status === 'Applied' &&
        (userRole === EUserRoles.Aggregator ? (
          <BaseTooltipTrigger tooltipChildren={`Pending Approval`} position="right">
            <div className={s.owners}>
              <div className={s.appliedState}>
                <BaseIcon icon="time" size={12} />
              </div>
            </div>
          </BaseTooltipTrigger>
        ) : (
          <div className={s.actions}>
            <div className={s.appliedUserAvatarWrapper}>
              <ProfilePictureIcon
                avatarUrl={data.appliedUserAvatar}
                name={data.appliedUserName}
                className={{ nameInitials: s.tileAvatar, picture: s.tileAvatar }}
              />
            </div>
            {respondSent ? (
              <BaseIcon icon="spinner" size={12} />
            ) : (
              <>
                <BaseTooltipTrigger
                  tooltipChildren={`Approve ${data.appliedUserName}`}
                  position="right">
                  <button
                    className={s.actionButton}
                    type="button"
                    onClick={() => respondToApplyRequest(true)}>
                    <BaseIcon icon="check-mark" size={12} />
                  </button>
                </BaseTooltipTrigger>
                <BaseTooltipTrigger
                  tooltipChildren={`Decline ${data.appliedUserName}`}
                  position="right">
                  <button
                    className={classNames(s.actionButton, s.actionButtonDecline)}
                    type="button"
                    onClick={() => respondToApplyRequest(false)}>
                    <BaseIcon icon="close" size={8} />
                  </button>
                </BaseTooltipTrigger>
              </>
            )}
          </div>
        ))}
      {showSelectBox && (
        <BaseSelect
          inputHeight="2"
          theme="filled-white"
          name="userRole"
          value={undefined}
          options={usersList.map(({ name, uuid }) => ({ label: name.split('@')[0], value: uuid }))}
          className={s.selectbox}
          defaultLabel="Assign User"
          onChange={assignUserToAsset}
        />
      )}
    </div>
  );
};
