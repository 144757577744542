import React from 'react';

import s from './CollaborationSteps.module.scss';
import { useSelector } from 'react-redux';
import { selectCollaborationStep } from 'src/redux/application/application.selectors';
import { MODALCOLLABORATIONSTEPCOUNT } from 'src/components/ModalCollaborationDeploy/ModalCollaborationDeployConstants';

export const CollaborationSteps: React.FC = ({}) => {
  const step = useSelector(selectCollaborationStep);

  return (
    <p className={s.stepText}>
      {step + 1} of {MODALCOLLABORATIONSTEPCOUNT}
    </p>
  );
};
