import {
  selectActiveConfigurationJobUuid,
  selectCommunityAsset,
} from 'src/redux/configuration/configuration.selectors';
import { useCallback, useRef } from 'react';

import { ReadSimulationResultsStatusDocument } from 'src/graphql';
import { setSimulationStatus } from 'src/redux/configuration/configuration.slice';
import { useApolloClient } from '@apollo/client';
import { useAppDispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';

export type TUseLazySimulationStatusResponse = {
  runSubscription: (jobId?: string) => void;
  stopSubscription: () => void;
  isRunning: boolean;
};

export const useLazySimulationStatus = (): TUseLazySimulationStatusResponse => {
  const dispatch = useAppDispatch();
  const apolloClient = useApolloClient();

  const activeConfigurationJobUuid = useSelector(selectActiveConfigurationJobUuid);
  const communityUuid = useSelector(selectCommunityAsset)?.uuid;
  const runningSubscription = useRef<boolean>(false);
  const watchQueryRef = useRef<ZenObservable.Subscription | null>(null);

  // This function runs the SO-CALLED subscription to the simulation results status
  // It subscribes to the simulation results status and dispatches it to the store
  // It also checks if the subscription is running and sets it to true
  const runSubscription = useCallback(
    (jobId?: string) => {
      const tempJobId = activeConfigurationJobUuid || jobId;
      watchQueryRef.current = apolloClient
        .watchQuery({
          query: ReadSimulationResultsStatusDocument,
          variables: {
            jobId: tempJobId!,
            uuid: communityUuid,
          },
          fetchPolicy: 'no-cache',
          pollInterval: 5000,
          errorPolicy: 'ignore',
        })
        .subscribe({
          next: ({ data }) => {
            const result = data.simulationResults;

            if (result) {
              dispatch(setSimulationStatus(result.status));
              if (runningSubscription.current === false) {
                runningSubscription.current = true;
              }
              //dispatch(dispatchTarget(objectCamelCase(JSON.parse(data.scmAccumulatedKpiDifferences))));
            }
          },
          error: (error) => {
            if (error) {
              console.error('Error in subscription to simulation results status', error);
            }
          },
        });
    },
    [apolloClient, dispatch, runningSubscription, communityUuid, activeConfigurationJobUuid],
  );

  // This function is used to unsubscribe from a subscription.
  // It does so by calling the unsubscribe method on the watchQueryRef,
  // which is used to store the result of the subscription.
  const stopSubscription = useCallback(() => {
    watchQueryRef.current?.unsubscribe();
    runningSubscription.current = false;
  }, []);

  return {
    runSubscription,
    stopSubscription,
    isRunning: runningSubscription.current,
  };
};
