import {
  EExportStatus,
  TAssetsEnergyExportIndicatorProps,
} from './AssetsEnergyExportIndicator.types';
import React from 'react';
import s from './AssetsEnergyExportIndicator.module.scss';
import { BaseIcon } from 'src/components/BaseIcon';
import classNames from 'classnames';

export const AssetsEnergyExportIndicator: React.FC<TAssetsEnergyExportIndicatorProps> = ({
  exportStatus = EExportStatus.Default,
}) => {
  return (
    <div
      className={classNames(
        s.container,
        s.energyExportIndicatorWrapper,
        exportStatus === EExportStatus.Exporter
          ? s.energyExporter
          : exportStatus === EExportStatus.Importer
          ? s.energyImporter
          : '',
      )}>
      <BaseIcon icon="triangle" size={10} />
      <BaseIcon icon="triangle" size={10} />
      <BaseIcon icon="triangle" size={10} />
    </div>
  );
};
