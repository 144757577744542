import { CommunityAssets } from 'src/components/CommunityAssets';
import { ConfigurationManagementCentral } from 'src/components/ConfigurationManagementCentral';
import { MapAlertModal } from 'src/components/MapAlertModal';
import { ModalAddMore } from 'src/components/ModalAddMore';
import { ModalOnboarding } from 'src/components/ModalOnboarding';
import React from 'react';
import { TMapRightSidebarProps } from './MapRightSidebar.types';
import classnames from 'classnames';
import s from './MapRightSidebar.module.scss';
import { selectIsEmbed } from 'src/redux/application/application.selectors';
import { useAppFlow } from 'src/hooks/useAppFlow';
import { useSelector } from 'react-redux';

export const MapRightSidebar: React.FC<TMapRightSidebarProps> = () => {
  const isEmbed = useSelector(selectIsEmbed);
  const { isSCMFlow } = useAppFlow();

  return (
    <div className={classnames(s.container, { [s.containerEmbed]: isEmbed })}>
      <ModalAddMore inline />
      {!isSCMFlow && <ModalOnboarding inline />}
      <ConfigurationManagementCentral>
        {({ handleAssetRemove }) => <CommunityAssets onAssetRemove={handleAssetRemove} />}
      </ConfigurationManagementCentral>
      <MapAlertModal />
    </div>
  );
};
