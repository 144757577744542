import { BaseTags, TBaseTagsProps } from '../../BaseTags';
import { Chart, ChartDataset } from 'chart.js';
import React, { useMemo, useRef, useState } from 'react';

import { AxisXCustom } from 'src/components/_charts/AxisXCustom/AxisXCustom';
import { ChartDataLoadingWrapper } from 'src/components/ChartDataLoadingWrapper';
import { ChartPriceColors } from 'src/constants/chart';
import { ChartWrapper } from 'src/components/ChartWrapper';
import { EChartName } from 'src/components/_charts/chartsData';
import { TChartEnergyShareProps, TImportedExportedEnergyCommunity } from './ChartEnergyShare.types';
import {
  selectSelectedAssetUuid,
  selectSettingsData,
} from 'src/redux/configuration/configuration.selectors';
import { useChartJS } from 'src/hooks/useChartJS';
import { useSelector } from 'react-redux';
import { useImportedExportedEnergyCommunityQuery } from 'src/graphql';
import { selectActiveConfigurationJobUuid } from 'src/redux/configuration/configuration.selectors';
import { UTCMoment } from 'src/utils/UTCMoment';
import { BACKEND_DATE_FORMATS } from 'src/utils/UTCMoment';
import { selectCommunityAsset } from 'src/redux/configuration/configuration.selectors';
import { selectAssets } from 'src/redux/configuration/configuration.selectors';

export const ChartEnergyShare: React.FC<TChartEnergyShareProps> = ({ startUnix, endUnix }) => {
  const assetUuid = useSelector(selectSelectedAssetUuid);
  const jobId = useSelector(selectActiveConfigurationJobUuid);
  const settingsData = useSelector(selectSettingsData);
  const communityAsset = useSelector(selectCommunityAsset);
  const assets = useSelector(selectAssets);

  const assetType = useMemo(() => {
    return assetUuid ? assets[assetUuid]?.type : undefined;
  }, [assetUuid, assets]);

  const startTime = useMemo(() => {
    return UTCMoment.fromBackend(settingsData.startDate).format(
      BACKEND_DATE_FORMATS.SIMULATION_RESULTS_START_END_TIME,
    );
  }, [settingsData]);

  const endTime = useMemo(() => {
    return UTCMoment.fromBackend(settingsData.endDate)
      .endOf('day')
      .format(BACKEND_DATE_FORMATS.SIMULATION_RESULTS_START_END_TIME);
  }, [settingsData]);

  const { data } = useImportedExportedEnergyCommunityQuery({
    fetchPolicy: 'cache-first',
    skip: !assetUuid || !jobId || communityAsset?.uuid === assetUuid || assetType !== 'Area',
    variables: {
      areaUuid: assetUuid!,
      jobId: jobId!,
      startTime,
      endTime,
    },
  });

  const importedExportedEnergyCommunity: TImportedExportedEnergyCommunity[] = useMemo(() => {
    return data?.importedExportedEnergyCommunity
      ? JSON.parse(JSON.parse(data?.importedExportedEnergyCommunity))
      : [];
  }, [data]);

  const chartRef = useRef<Chart | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [disabledItems, setDisabledItems] = useState<number[]>([]);

  const setChartPricesVisibleItems: TBaseTagsProps['onChange'] = ({ value }) => {
    setDisabledItems(value);
  };

  const datasets = useMemo(() => {
    const commonDatasetOptions: Partial<ChartDataset<'line'>> = {
      backgroundColor: 'transparent',
      borderWidth: 1,
      borderCapStyle: 'square',
      borderJoinStyle: 'miter',
      tension: 1,
      cubicInterpolationMode: 'monotone',
    };

    return [
      {
        ...commonDatasetOptions,
        data:
          importedExportedEnergyCommunity.map((result) => ({
            x: result.simulation_time * 1000,
            y: result.imported_from_grid,
          })) || [],
        borderColor: ChartPriceColors.averageLine,
        backgroundColor: ChartPriceColors.averageLine,
        hidden: disabledItems.includes(0),
        label: JSON.stringify({
          title: 'Imported Energy (Utility)',
          unit: 'kWh',
        }),
      },
      {
        ...commonDatasetOptions,
        data:
          importedExportedEnergyCommunity.map((result) => ({
            x: result.simulation_time * 1000,
            y: result.imported_from_community,
          })) || [],
        borderColor: ChartPriceColors.minLine,
        backgroundColor: ChartPriceColors.minLine,
        hidden: disabledItems.includes(1),
        label: JSON.stringify({
          title: 'Community Energy',
          unit: 'kWh',
        }),
      },
    ];
  }, [disabledItems, importedExportedEnergyCommunity]);

  useChartJS(
    EChartName.Prices,
    canvasRef,
    chartRef,
    {
      datasets,
    },
    {
      chartOptions: {
        scales: {
          x: {
            max: endUnix * 1000,
            min: startUnix * 1000,
          },
          y: {
            ticks: {
              display: true,
              font: {
                size: 11,
                weight: 'bold',
              },
              color: '#34363f',
            },
          },
        },
      },
      datasetsLengthWillChange: true,
    },
  );

  const chartPricesItems = [
    { name: 'Imported Energy (Utility)', color: ChartPriceColors.averageLine },
    { name: 'Community Energy', color: ChartPriceColors.minLine },
  ];

  return (
    <ChartWrapper
      title="Energy Share"
      info={undefined}
      footerComponent={
        <BaseTags
          items={chartPricesItems}
          name="baseTags1"
          value={disabledItems}
          onChange={(value) => setChartPricesVisibleItems(value)}
        />
      }>
      <ChartDataLoadingWrapper loading={!importedExportedEnergyCommunity}>
        <canvas ref={canvasRef} height={180} />

        <AxisXCustom startUnix={startUnix} endUnix={endUnix} style={{ marginLeft: 30 }} />
      </ChartDataLoadingWrapper>
    </ChartWrapper>
  );
};
