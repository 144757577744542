import { TAsset } from 'src/typings/configuration.types';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';

export type TCommunityAssetsProps = {
  className?: string;
  style?: React.CSSProperties;
  onAssetRemove?(payload: { assetUuid: TAsset['uuid'] }): void;
};

export type TCommunityAssetsControl = {
  label: ECommunityControlLabels;
  icon: TIconNames;
};

export enum ECommunityControlLabels {
  ToggleExpand = 'toggle-expand',
  Close = 'close',
}
