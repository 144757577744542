import {
  ApiLayer,
  BlueBoxLayer,
  DeploymentLayer,
  ELayersGroup,
  EMenus,
  EcosystemLayer,
  ExchangeLayer,
  InitialLayer,
  MarketLayer,
  SimulationLayer,
  TLayers,
  layerPositions,
  menus,
  totalPages,
} from 'src/pages/MarketDesignMobile';
import { EMenuColor, Header } from 'src/components/Header';
import { IParallax, Parallax, ParallaxLayer } from '@react-spring/parallax';
import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { RootState, useAppDispatch } from 'src/redux/store';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';

import { BaseButton } from 'src/components/BaseButton';
import { BaseButtonSquare } from 'src/components/BaseButtonSquare';
import { BaseIcon } from 'src/components/BaseIcon';
import { Doc } from './components/Doc';
import { EPredefinedModalIds } from 'src/constants/modals';
import { Fade } from 'react-reveal';
import { FullBar } from 'src/components/LandingPage/MarketDesignMobile/StatusBar';
import { ModalMarketDesignOverlay } from 'src/components/ModalMarketDesignOverlay';
import { OverLayLayer } from 'src/components/LandingPage/MarketDesignMobile/InitialLayer';
import { SelectedTitle } from 'src/components/LandingPage/MarketDesignMobile/InitialLayer';
import { TMarketDesignContextValues } from './MarketDesign.types';
import bgLayerDeployment from '../../assets/images/LandingPage/marketDesign/layer_deployment/bg.png';
import bgLayerEcosystem from '../../assets/images/LandingPage/marketDesign/layer_ecosystem/bg.png';
import bgLayerOneExchanges from '../../assets/images/LandingPage/marketDesign/layerOne_exchanges/bg.png';
import bgWave from '../../assets/images/LandingPage/marketDesign/layerOne_exchanges/ripples.png';
import classnames from 'classnames';
import s from './MarketDesign.module.scss';
import { useSelector } from 'react-redux';

export function detectiOS(): boolean {
  const toMatch = [/iPhone/i, /iPad/i, /iPod/i];
  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

export type TMarketDesignContextState = {
  currentLayer: number;
  isDocVisible: boolean;
  isEcoSystemChildView: boolean;
  isLandscape: boolean;
};

const initialContextState = {
  currentLayer: 0,
  isDocVisible: true,
  isEcoSystemChildView: false,
  isLandscape: false,
  // setEcoSystemChildView: null,
};

export const MarketDesignContext = createContext<TMarketDesignContextState>(initialContextState);

export const MarketDesign: React.FC = () => {
  const dispatch = useAppDispatch();
  const [headerColorMode] = React.useState('white');
  const parallaxRef = useRef<IParallax>(null);
  const [currentLayer, setCurrentLayer] = useState(0);
  const [scrolling, setScrolling] = useState<boolean>(false);

  const [, setScrollDirection] = useState<null | string>(null);
  const [scaleScrolling, setScallingScrolling] = useState(0);
  //const scrollDirectionMemo = useMemo(() => scrollDirection, [scrollDirection]);
  const scrollingMemo = useMemo(() => scrolling, [scrolling]);
  const scaleScrollingMemo = useMemo(() => scaleScrolling, [scaleScrolling]);
  //const currentLayerMemo = useMemo(() => currentLayer, [currentLayer]);
  const [showSelectedTitle, setShowSelectedTitle] = useState<boolean>(true);
  const [isEcoSystemChildView, setIsEcoSystemChildView] = useState<boolean>(false);
  const [screenOrientation, setScreenOrientation] = useState<string>('');

  const isInfoModalOpen = useSelector((state: RootState) =>
    state?.modals.activeModals?.includes(EPredefinedModalIds.MODAL_MARKET_DESIGN),
  );

  const isOverlayModalOpen = useSelector((state: RootState) =>
    state?.modals.activeModals?.includes(EPredefinedModalIds.MODAL_MARKET_DESIGN_OVERLAY),
  );

  // ====================================================================================
  // Get page height based in the viewport
  // ====================================================================================
  const vh = useMemo(
    () => Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
    [],
  );

  // ====================================================================================
  // Get the layer of parallax and save in the state
  // ====================================================================================
  const scrollAnalyzer = useCallback(() => {
    if (parallaxRef.current) {
      setScallingScrolling((parallaxRef.current.current / vh) % 1);
      if (
        (parallaxRef.current.current / vh) % 1 >= 0.3 &&
        (parallaxRef.current.current / vh) % 1 <= 1 &&
        !scrollingMemo
      ) {
        setCurrentLayer(Math.round(parallaxRef.current.current / vh));
      }
    }
  }, [setScallingScrolling, vh, scrollingMemo, setCurrentLayer]);

  // ====================================================================================
  // Verify scroll direction and save in the state
  // ====================================================================================
  useEffect(() => {
    const scrollableElement = document.body;

    scrollableElement.addEventListener('wheel', checkScrollDirection);
    function checkScrollDirection(event) {
      if (checkScrollDirectionIsUp(event)) {
        setScrollDirection('up');
      } else {
        setScrollDirection('down');
      }
    }

    function checkScrollDirectionIsUp(event) {
      if (event.wheelDelta) {
        return event.wheelDelta > 0;
      }
      return event.deltaY < 0;
    }
  }, [setScrollDirection]);

  // ====================================================================================
  // Useeffect for listening scroll events
  // ====================================================================================
  useEffect(() => {
    const container = document.querySelector('.parallax-wrapper');
    container?.addEventListener('scroll', scrollAnalyzer);
    return () => {
      container?.removeEventListener('scroll', scrollAnalyzer);
    };
  }, [scrollAnalyzer]);

  // ====================================================================================
  // Function to select background (case needed)
  // ====================================================================================
  function selectBg(page) {
    let bg;

    switch (page) {
      case layerPositions[ELayersGroup.INITIAL]:
      case layerPositions[ELayersGroup.EXCHANGES]:
        bg = bgLayerOneExchanges;
        break;
      case layerPositions[ELayersGroup.MARKETS]:
      case layerPositions[ELayersGroup.MARKETS] + 1:
      case layerPositions[ELayersGroup.MARKETS] + 2:
      case layerPositions[ELayersGroup.MARKETS] + 3:
        bg = null;
        break;
      case layerPositions[ELayersGroup.APIS]:
        bg = null;
        break;
      case layerPositions[ELayersGroup.DEPLOYMENT]:
        bg = bgLayerDeployment;
        break;
      case layerPositions[ELayersGroup.ECOSYSTEM]:
        bg = bgLayerEcosystem;
        break;
      default:
        bg = bgLayerOneExchanges;
        break;
    }

    return bg ? `url(${bg})` : 'none';
  }

  // ====================================================================================
  // Function to select the second background as example the wave
  // ====================================================================================
  function selectBgPattern(page) {
    let bg;

    if (
      page >= layerPositions[ELayersGroup.EXCHANGES] &&
      page < layerPositions[ELayersGroup.MARKETS]
    ) {
      bg = bgWave;
    }
    if (
      page >= layerPositions[ELayersGroup.SIMULATION] &&
      page <= layerPositions[ELayersGroup.DEPLOYMENT]
    ) {
      bg = bgWave;
    }
    if (
      page === layerPositions[ELayersGroup.APIS] + 1 ||
      page === layerPositions[ELayersGroup.APIS] + 2
    ) {
      bg = bgWave;
    }

    return bg ? `url(${bg})` : 'none';
  }

  // ====================================================================================
  // Select the page to scroll
  // ====================================================================================
  const scrollTo = useCallback(
    (pageNumber) => {
      if (pageNumber <= layerPositions[ELayersGroup.ECOSYSTEM]) {
        setScrolling(true);
        parallaxRef?.current?.scrollTo(pageNumber);
        setCurrentLayer(pageNumber);
      }
    },
    [setScrolling, setCurrentLayer],
  );

  // ====================================================================================
  // Render all layers from configuration file
  // ====================================================================================
  function renderLayers(layers: TLayers[], paralax = false) {
    return layers?.map((layer, key) => {
      const { Component } = layer;
      const withLayers: number[] = [];

      if (layer.config?.offset) {
        withLayers.push(layer.config?.offset);
      }
      if (!layer.config?.offset && layer.config?.sticky) {
        for (let i = layer.config?.sticky?.start; i <= layer.config?.sticky?.end; i++) {
          withLayers.push(i);
        }
      }

      if (!paralax) {
        let valid = false;
        if (!withLayers?.includes(currentLayer) && scrollingMemo) {
          valid = false;
        }

        if (withLayers?.includes(currentLayer)) {
          valid = true;
        }

        return (
          <Component
            {...layer.props}
            scrolling={scrollingMemo}
            show={valid}
            withLayers={withLayers}
            currentLayer={currentLayer}
            scaleScrolling={scaleScrolling}
            scrollTo={scrollTo}
            isEcoSystemChildView={isEcoSystemChildView}
            setIsEcoSystemChildView={setIsEcoSystemChildView}
          />
        );
      }

      return (
        <ParallaxLayer
          speed={0.8}
          key={key.toString()}
          {...layer.config}
          className={
            layer.config?.className === s.parallaxLayer && isLandscape
              ? s.parallaxLayerLandscape
              : layer.config?.className
          }>
          <Component
            {...layer.props}
            withLayers={withLayers}
            currentLayer={currentLayer}
            scrolling={scrollingMemo}
            scaleScrolling={scaleScrolling}
            scrollTo={scrollTo}
          />
        </ParallaxLayer>
      );
    });
  }

  // ====================================================================================
  // Control scroll status
  // ====================================================================================
  useEffect(() => {
    if (scaleScrollingMemo % 1 === 0 && scaleScrollingMemo !== 1) {
      setScrolling(false);
      setScrollDirection(null);
    }
  }, [scaleScrollingMemo, setScrolling, setScrollDirection]);

  // =================================================================================================
  // Check the scroll direction and force that scroll ** don't remove scrollAnalyzer from dependencies
  // =================================================================================================
  // useEffect(() => {
  //   if (scrollDirectionMemo === 'up' && !scrollingMemo) {
  //     if (currentLayerMemo > 0) {
  //       setScrolling(true);
  //       setTimeout(() => {
  //         scrollTo(currentLayerMemo - 1);
  //       }, 1000);
  //     }
  //   }
  //   if (scrollDirectionMemo === 'down' && !scrollingMemo) {
  //     if (currentLayerMemo < totalPages) {
  //       setScrolling(true);
  //       setTimeout(() => {
  //         scrollTo(currentLayerMemo + 1);
  //       }, 1000);
  //     }
  //   }
  // }, [
  //   scrollDirectionMemo,
  //   setScrolling,
  //   currentLayerMemo,
  //   scrollTo,
  //   scrollingMemo,
  //   scrollAnalyzer,
  // ]);

  function isMenuActive(cLayer: number, itemLayer: number, key: number, menuName: EMenus) {
    const menuSelected = menus?.[menuName];
    const nextKey = key + 1;
    const layerMenuSelected = menuSelected?.[nextKey]?.layer;

    if (nextKey < menuSelected.length) {
      if (layerMenuSelected) {
        return cLayer >= itemLayer && cLayer < menuSelected?.[nextKey]?.layer;
      }
    }

    return cLayer >= itemLayer;
  }

  const OverLayComponent = () => (
    <div className={s.overlay}>
      <OverLayLayer
        currentLayer={currentLayer}
        scaleScrolling={scaleScrolling}
        scrollTo={scrollTo}
      />
    </div>
  );

  useEffect(() => {
    const autoOpenModalLayers = [1, 5, 9, 12];
    if (autoOpenModalLayers.includes(currentLayer) && !isOverlayModalOpen) {
      dispatch(openModal(EPredefinedModalIds.MODAL_MARKET_DESIGN));
    }
    if (isInfoModalOpen && !autoOpenModalLayers.includes(currentLayer)) {
      dispatch(closeModal(EPredefinedModalIds.MODAL_MARKET_DESIGN));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentLayer, isOverlayModalOpen]);

  const selectColorItemsMenu = (layer: number) => {
    if (layer === layerPositions[ELayersGroup.ECOSYSTEM]) {
      return EMenuColor.AlternativeOne;
    }
    if (layer === layerPositions[ELayersGroup.DEPLOYMENT]) {
      return EMenuColor.AlternativeTwo;
    }
    return null;
  };

  const isLandscape = useMemo(() => screenOrientation === 'landscape', [screenOrientation]);

  const value: TMarketDesignContextValues = useMemo(() => {
    return {
      currentLayer,
      isDocVisible: !showSelectedTitle,
      isEcoSystemChildView,
      isLandscape,
      // setEcoSystemChildView: setIsEcoSystemChildView,
    };
  }, [currentLayer, showSelectedTitle, isEcoSystemChildView, isLandscape]);

  // ====================================================================================
  // check screen orientation and save in the state
  // ====================================================================================
  useEffect(() => {
    if (window.innerWidth > window.innerHeight) {
      setScreenOrientation('landscape');
    } else {
      setScreenOrientation('portrait');
    }
    window.addEventListener('resize', checkScreenOrientation);
    function checkScreenOrientation() {
      if (window.matchMedia('(orientation: portrait)').matches) {
        setScreenOrientation('portrait');
      }

      if (window.matchMedia('(orientation: landscape)').matches) {
        setScreenOrientation('landscape');
      }
    }
  }, [setScrollDirection]);

  return (
    <>
      <MarketDesignContext.Provider value={value}>
        <div className={isLandscape ? s.landscape : ''}>
          <Header
            isLandingPage
            headerColorMode={headerColorMode}
            textColorLandingpage={selectColorItemsMenu(currentLayer)}
          />

          {/* <ModalMarketDesign modalId={EPredefinedModalIds.MODAL_MARKET_DESIGN}>
        <BlueBox currentLayer={currentLayer} />
      </ModalMarketDesign> */}

          <ModalMarketDesignOverlay modalId={EPredefinedModalIds.MODAL_MARKET_DESIGN_OVERLAY}>
            <OverLayComponent />
          </ModalMarketDesignOverlay>

          <div className={s.wrapper}>
            <div
              className={isLandscape ? s.backgroundLayerLandscape : s.backgroundLayer}
              style={{
                backgroundImage: selectBg(currentLayer),
              }}
            />

            <div
              className={s.waveBackground}
              style={{
                backgroundImage: selectBgPattern(currentLayer),
              }}
            />
            {currentLayer > layerPositions[ELayersGroup.INITIAL] && (
              <Doc currentLayer={currentLayer} onChange={(val) => setShowSelectedTitle(val)}>
                <SelectedTitle currentLayer={currentLayer} scaleScrolling={0} scrollTo={scrollTo} />
              </Doc>
            )}
            {currentLayer > layerPositions[ELayersGroup.INITIAL] && <FullBar page={currentLayer} />}
            <div
              style={
                isLandscape
                  ? { width: '100vh', height: '100vw', overflow: 'hidden' }
                  : { width: '100wh', height: '100vh', overflow: 'hidden' }
              }>
              {renderLayers([
                ...ExchangeLayer,
                ...MarketLayer,
                ...ApiLayer,
                ...SimulationLayer,
                ...DeploymentLayer,
              ])}
            </div>
            <div className={s.bottomToTopFade}>
              <BaseButton
                theme="tertiary"
                className={s.scrollBtn}
                onClick={() => {
                  scrollTo(currentLayer + 1);
                  setScallingScrolling(0);
                }}>
                {currentLayer === 0 && <span>Scroll</span>}
                <BaseIcon className={s.buttonScroll} icon="arrow-down" size={24} />
              </BaseButton>
            </div>

            {currentLayer >= layerPositions[ELayersGroup.EXCHANGES] &&
              currentLayer < layerPositions[ELayersGroup.SIMULATION] && (
                <div
                  className={classnames(s.boxActions, {
                    [s.boxActionsTop]: !showSelectedTitle,
                  })}>
                  {menus?.[EMenus.FIRST]?.map((item, key) => (
                    <Fade top delay={500 * (key + 1)} key={key.toString()}>
                      <div
                        className={`${s.action} ${
                          isMenuActive(currentLayer, item?.layer, key, EMenus.FIRST)
                            ? s.activeAction
                            : {}
                        }`}
                        onClick={() => (item?.layer ? scrollTo(item?.layer) : {})}>
                        <BaseButtonSquare
                          size="2"
                          theme={
                            isMenuActive(currentLayer, item?.layer, key, EMenus.FIRST)
                              ? 'flat-red'
                              : 'flat-dark-soft'
                          }
                          icon={item?.icon}
                          svgSize="2"
                        />
                        <span>{item?.title}</span>
                      </div>
                    </Fade>
                  ))}
                </div>
              )}

            {currentLayer >= layerPositions[ELayersGroup.SIMULATION] &&
              currentLayer < layerPositions[ELayersGroup.ECOSYSTEM] && (
                <div
                  className={classnames(s.boxActions, {
                    [s.boxActionsTop]: !showSelectedTitle,
                  })}>
                  {menus?.[EMenus.SECOND]?.map((item, key) => (
                    <Fade top delay={500 * (key + 1)} key={key.toString()}>
                      <div
                        className={`${s.action} ${
                          isMenuActive(currentLayer, item?.layer, key, EMenus.SECOND)
                            ? s.activeAction
                            : {}
                        }`}
                        onClick={() => (item?.layer ? scrollTo(item?.layer) : {})}>
                        <BaseButtonSquare
                          size="2"
                          theme={
                            isMenuActive(currentLayer, item?.layer, key, EMenus.SECOND)
                              ? 'flat-red'
                              : 'flat-dark-soft'
                          }
                          icon={item?.icon}
                          svgSize="2"
                        />
                        <span>{item?.title}</span>
                      </div>
                    </Fade>
                  ))}
                </div>
              )}

            <Parallax
              ref={parallaxRef}
              pages={totalPages + 1}
              style={{ top: '0', left: '0', zIndex: 20 }}
              className="parallax-wrapper">
              {/* Render the first layer inside of parallax */}
              {renderLayers(InitialLayer, true)}
              {renderLayers(BlueBoxLayer, true)}
              {renderLayers(EcosystemLayer)}
            </Parallax>
          </div>
        </div>
      </MarketDesignContext.Provider>
    </>
  );
};
