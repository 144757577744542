import { TAssetType, TUploadedProfile } from 'src/typings/base-types';
import {
  TCloudCoverageValues,
  TCommunitySettingsFields,
} from 'src/utils/assetsFields/assetsFields.types';

import { GRID_MARKET_MODE } from 'src/constants/application';
import { TLocationSearchValue } from 'src/components/LocationSearch';
import { UnionToIntersection } from 'utility-types';

export type TCommonFieldValues = {
  name: string;
  count: number;
  allowExternalConnection: boolean;
  powerProfile: TUploadedProfile;
  smartMeterProfile: TUploadedProfile;
  exchangeInformation: 'spot';
  transformerCapacityEnabled: boolean;
  baselinePeakEnergyEnabled: boolean;
  geoTagType: 'area' | 'location';
  geoTagLocation: TLocationSearchValue['coords'];
};

export type TAreaFieldValues = {
  gridFeeEnabled: boolean;
  gridFeeConstant: number;
  gridFeePercentage: number;
  fitAreaBoundary: boolean;
  importCapacityKva: number;
  exportCapacityKva: number;
  coefficientPercentage: number;
  baselinePeakEnergyImportKwh: number;
  baselinePeakEnergyExportKwh: number;
};

export type TPowerPlantFieldValues = {
  energyRate: number;
  maxAvailablePowerKw: number;
};

export type TPVFieldValues = {
  capacityKw: number;
  cloudCoverage: TCloudCoverageValues;
  powerProfileUuid: string;
  initialSellingRate: number;
  finalSellingRate: number;
  energyRateDecreasePerUpdate: 'Varying rate' | number;
  isCustomPV: boolean;
  azimuth: number;
  tilt: number;
  fitToLimit: boolean | null;
};

export type TStorageFieldValues = {
  batteryCapacityKwh: number;
  initialSoc: number;
  minAllowedSoc: number;
  maxAbsBatteryPowerKw: number;
  capPriceStrategy: boolean;
  initialkWh: number;
  energyRateIncreasePerUpdate: 'Varying rate' | number;
  energyRateDecreasePerUpdate: 'Varying rate' | number;
  finalBuyingRate: number;
  finalSellingRate: number;
  isCustomStorage: boolean;
};

export type TLoadFieldValues = {
  dailyLoadProfile: TUploadedProfile;
  dailyLoadProfileUuid: string;
  avgPowerW: number;
  //hrsPerDay: number;
  //hrsOfDay: [number, number];
  initialBuyingRate: number | null;
  useMarketMakerRate: boolean;
  finalBuyingRate: number;
  energyRateIncreasePerUpdate: 'Varying rate' | number;
  fitToLimit: boolean | null;
  updateInterval: number | null;
  forecastStreamEnabled: boolean;
  isCustomLoad?: boolean;
  loadProfileOption: 'userConfigure' | 'userUpload';
};

export enum EHeatPumpSourceType {
  Air = 0,
  Ground = 1,
}

export type THeatPumpFieldValues = {
  isCustomHeatPump?: boolean;
  avgPowerW: number;
  initialBuyingRate: number | null;
  preferredBuyingRate: number;
  finalBuyingRate: number;
  maximumPowerRatingKw: number;
  minTempC: number;
  maxTempC: number;
  tankVolumeL: number;
  initialTempC: number;
  updateInterval: number | null;
  externalTemperatureOption: 'userConfigure' | 'userUpload';
  sourceTempCProfile: TUploadedProfile;
  sourceTempCProfileUuid: string;
  consumptionProfileOption: 'userConfigure' | 'userUpload';
  sourceType: EHeatPumpSourceType;
  consumptionKw: number;
  consumptionKwhProfile: TUploadedProfile;
  consumptionKwhProfileUuid: string;
};

export type TSmartMeterFieldValues = {
  initialBuyingRate: number | null;
  finalBuyingRate: number;
  finalSellingRate: number;
  energyRateDecreasePerUpdate: 'Varying rate' | number;
  energyRateIncreasePerUpdate: 'Varying rate' | number;
  useMarketMakerRate: boolean;
  smartMeterProfile: TUploadedProfile;
  smartMeterProfileUuid: string;
};

export type TLibraryFieldValues = {
  libraryUUID: string;
  description: string;
  isPrivate: boolean;
};

export type TMarketMakerFieldValues = {
  gridConnected: boolean;
  gridFeeEnabled: boolean;
  gridFeeConstant: number;
  energyRateProfile: TUploadedProfile;
  energyRateProfileUuid: string;
  energyBuyRate: number;
  buyingRateProfile: TUploadedProfile;
  buyingRateProfileUuid: string;
  [GRID_MARKET_MODE]: 'MarketMakerMode' | 'InfiniteBusMode';
  energyRateType: 0 | 1;
  buyingRateType: 0 | 1;
};

export type TSCMCommonFieldValues = {
  language: number;
  marketplaceMonthlyFee: number;
  assistanceMonthlyFee: number;
  fixedMonthlyFee: number;
  taxesSurcharges: number;
  marketMakerRate: number;
  feedInTariff: number;
  gridFee: number;
};

type TBareAssetFieldValues =
  | TAreaFieldValues
  | TPowerPlantFieldValues
  | TPVFieldValues
  | TStorageFieldValues
  | TLoadFieldValues
  | TLibraryFieldValues
  | TMarketMakerFieldValues
  | THeatPumpFieldValues
  | TSmartMeterFieldValues;

type TFieldDecisionOptions = TAssetType | 'Community';

type TFieldValuesSwitcher<T extends TFieldDecisionOptions> = T extends 'Area'
  ? TAreaFieldValues
  : T extends 'FiniteDieselGenerator'
  ? TPowerPlantFieldValues
  : T extends 'Load'
  ? TLoadFieldValues
  : T extends 'PV'
  ? TPVFieldValues
  : T extends 'Storage'
  ? TStorageFieldValues
  : T extends 'HeatPump'
  ? TSmartMeterFieldValues
  : T extends 'SmartMeter'
  ? THeatPumpFieldValues
  : T extends 'MarketMaker'
  ? TMarketMakerFieldValues
  : T extends 'InfiniteBus'
  ? TMarketMakerFieldValues
  : T extends 'Community'
  ? TAreaFieldValues & TCommunitySettingsFields
  : never;

type TAllOfCommonFields = TCommonFieldValues & TSCMCommonFieldValues & TCommunitySettingsFields;

export type TFieldValuesByName<T extends TFieldDecisionOptions> = TFieldValuesSwitcher<T> &
  TAllOfCommonFields;

export type TValuesByFieldName = Partial<
  UnionToIntersection<TBareAssetFieldValues> & TAllOfCommonFields
>;
