import { CoreInteractionOptions, ScaleType } from 'chart.js';

export const commonChartOptions = {
  animation: false as const,
  parsing: false as const,
  responsive: true,
  elements: {
    point: {
      radius: 0,
    },
  },
};

export const commonXScale = {
  type: 'time' as ScaleType,
  grid: {
    display: false,
  },
  ticks: {
    display: false,
  },
};

export const dayProfileCommonYAxis = {
  ticks: {
    display: false,
  },
  grid: {
    drawBorder: false,
    drawTicks: false,
    display: false,
  },
};

export const commonTooltipSettings = {
  enabled: true,
  backgroundColor: '#211E21',
  bodyColor: '#FBF9F9',
  usePointStyle: true,
  padding: 8,
  bodySpacing: 4,
  titleMarginBottom: 4,
  boxWidth: 26,
};

export const commonInteraction: CoreInteractionOptions = {
  mode: 'nearest', // https://github.com/chartjs/Chart.js/issues/5231#issuecomment-711029322
  axis: 'x',
  intersect: false,
};
