import React, { useRef, useState } from 'react';

import { BaseGenericModal } from 'src/components/BaseGenericModal';
import { BaseSlider } from 'src/components/BaseSlider';
import { EModalSize } from 'src/constants/modals';
import { TemplateDefault } from 'src/templates/TemplateDefault';
import { useModal } from 'src/hooks/useModal';

function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const TestPage: React.FC = () => {
  const [value, setValue] = useState<[number, number]>([0, 10]);
  const modalTargetRef = useRef(null);
  const modalData = useModal();
  const [modalState, setModalState] = useState({
    side: 'bottom',
    anchorPosition: 50,
    initialOpen: true,
  });
  // WORKS
  // const { handleDownload, loading, error } = useDownloadAPI({
  //   type: 'user-profile',
  //   isLibrary: true,
  //   username: 'admin-canary@gridsingularity.com',
  // });
  // BROKEN ENDPOINT
  // const { handleDownload, loading, error } = useDownloadAPI({
  //   type: 'simulation-results',
  //   resultsUuid: '733fb9a4-febe-453e-b62f-f213d32c615f',
  // });
  // BROKEN ENDPOINT
  // const { handleDownload, loading, error } = useDownloadAPI({
  //   type: 'uploaded-file',
  //   configUuid: '0a102d4b-19b2-4bf7-b8dd-394e86093782',
  //   filename: 'test_profile_file.csv',
  //   isLibrary: true,
  // });
  // WORKS
  // const { handleDownload, loading, error } = useDownloadAPI({
  //   type: 'uploaded-file',
  //   configUuid: '0a102d4b-19b2-4bf7-b8dd-394e86093782',
  //   fileUuid: '859df843-e486-4717-b06e-6cce12fc869f',
  //   isLibrary: true,
  // });
  // WORKS
  // const { handleDownload, loading, error } = useDownloadAPI({
  //   type: 'api-summary',
  //   configUuid: '0a102d4b-19b2-4bf7-b8dd-394e86093782',
  // });

  return (
    <TemplateDefault>
      <div style={{ margin: 50 }}>
        {/* <div style={{ padding: 50 }}>
          <button type="button" onClick={handleDownload}>
            DOWNLOAD
          </button>
          {loading && <div>loading</div>}
          {error && <div>error: {error}</div>}
        </div> */}

        <button
          type="button"
          ref={modalTargetRef}
          style={{
            width: 40,
            height: 40,
            position: 'absolute',
            left: 500,
            top: 200,
            background: 'red',
          }}
          onClick={() =>
            setModalState((prev) => ({
              ...prev,
              side: prev.side === 'bottom' ? 'top' : 'bottom',
              anchorPosition: getRandomInt(0, 100),
            }))
          }></button>

        <BaseGenericModal
          modalId={modalData.id}
          size={EModalSize.S100}
          position={{
            side: modalState.side as 'bottom',
            anchorPosition: modalState.anchorPosition,
          }}
          initialOpen={modalState.initialOpen}
          relativeElement={modalTargetRef}>
          TEST
        </BaseGenericModal>

        <BaseSlider
          {...{
            name: 'hrsOfDay',
            value: value,
            min: 0,
            max: 23,
            step: 1,
            onChange: ({ value: v }) => {
              if (Array.isArray(v)) {
                setValue(v);
              }
            },
            label: 'Hours of day: 8,15 ',
          }}
        />
      </div>
    </TemplateDefault>
  );
};
